import { EventEmitter, Injectable } from '@angular/core';
import { BookmarkContentTypes, BookmarkElement, BookmarkUpdate, HistoryApiService } from '@atv-core/api/history';
import { CacheStorage } from '@atv-core/services/cache/cacheStorage';
import { ConfigService, SettingsKeys } from '@atv-bootstrap/services/config';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

import { AuthorizationService } from './../../authorization/authorization.service';
import { SessionService } from '@atv-core/services/session';
import { BookmarkModel, BookmarkQueryTypes } from './bookmark.model';

@Injectable({ providedIn: 'root' })
export class BookmarkCacheService {
  private reloadingCache = false;
  private cache = new CacheStorage();
  private cachePromise: Observable<any> = undefined;
  private expirationTime = 0;
  private defaultBookmarksMaximumPercent = 0.95;

  public bookmarkUpdateEvent = new EventEmitter();

  constructor(
    private config: ConfigService,
    private historyApi: HistoryApiService,
    private auth: AuthorizationService,
    private sessionService: SessionService
  ) {
    this.sessionService.clearAllCachesEvent.subscribe(() => {
      this.clearCache();
    });
  }

  private checkCache(): void {
    if (new Date().getTime() > this.expirationTime) {
      this.clearCache();
    }

    this.cachePromise = this.cachePromise || this.fillCache();
  }

  private fillCache(): Observable<any> {
    if (!this.auth.isAuthorized()) {
      return of([]);
    }

    this.expirationTime =
      new Date().getTime() +
      this.config.getSettingNumber(SettingsKeys.bookmarkCacheExpiration, 120000);

    return this.historyApi
      .getBookmarks(this.sessionService.getActiveProfileId(), this.sessionService.getCatalogId())
      .pipe(
        switchMap((result) => {
          result.forEach((element: BookmarkElement) =>
            this.cache.put(
              element.id + element.type,
              new BookmarkModel(
                element,
                this.config.getSettingNumber(
                  SettingsKeys.bookmarksMaximumPercent,
                  this.defaultBookmarksMaximumPercent
                )
              )
            )
          );

          this.reloadingCache = false;

          return of([]);
        }),
        catchError((e) => {
          this.reloadingCache = false;
          throw e;
        }),
        shareReplay()
      );
  }

  public getBookmark(
    type: BookmarkContentTypes,
    id: string,
    showAdult: boolean
  ): Observable<BookmarkModel> {
    this.checkCache();

    return this.cachePromise.pipe(
      map(() => {
        const item: BookmarkModel = this.cache.get(id + type);
        if (item && (!item.adult || showAdult)) {
          return item;
        }
        return undefined;
      })
    );
  }

  public getBookmarkNoCache(
    type: BookmarkContentTypes,
    id: string,
    showAdult: boolean
  ): Observable<BookmarkModel> {
    return this.historyApi.getBookmark(this.sessionService.getActiveProfileId(), type, id).pipe(
      map((result: BookmarkElement) => {
        if (!result.adult || showAdult) {
          return new BookmarkModel(
            result,
            this.config.getSettingNumber(
              SettingsKeys.bookmarksMaximumPercent,
              this.defaultBookmarksMaximumPercent,
            ),
          );
        }
        return undefined;
      })
    );
  }

  public getBookmarksForTypeForSeries(
    type: BookmarkContentTypes,
    seriesId: string,
    showAdult: boolean
  ): Observable<BookmarkModel[]> {
    this.checkCache();

    return this.cachePromise.pipe(
      map(() => {
        if (seriesId === undefined) {
          return [];
        }
        const bookmarks: BookmarkModel[] = this.cache.values();
        bookmarks.sort((a, b) => {
          if (parseInt(a.lastUpdate) < parseInt(b.lastUpdate)) {
            return 1;
          } else if (parseInt(a.lastUpdate) > parseInt(b.lastUpdate)) {
            return -1;
          } else {
            return 0;
          }
        });
        return bookmarks.filter(
          (bookmark) =>
            bookmark.type === type && bookmark.series === seriesId && (!bookmark.adult || showAdult)
        );
      })
    );
  }

  public getBookmarksForType(
    type: BookmarkContentTypes,
    query: BookmarkQueryTypes,
    showAdult: boolean,
  ): Observable<BookmarkModel[]> {
    this.checkCache();

    return this.cachePromise.pipe(
      map(() => {
        const bookmarks = this.cache.values();

        let result = bookmarks.filter((bookmark) => {
          if (query === BookmarkQueryTypes.ALL) {
            return bookmark.type === type && (showAdult || !bookmark.adult);
          } else if (query === BookmarkQueryTypes.PARTIAL) {
            return (
              bookmark.type === type && (showAdult || !bookmark.adult) && !bookmark.isFullyWatched()
            );
          }
        });

        if (type === BookmarkContentTypes.PROGRAM) {
          const now = new Date().getTime();
          result = result.filter((bookmark) => {
            if (!bookmark.schedules) {
              return false;
            }
            return bookmark.schedules.some((schedule) => {
              return SharedUtilityService.timeStringToMs(schedule.end) < now;
            });
          });
        }
        result.sort((a, b) => {
          if (parseInt(a.lastUpdate) < parseInt(b.lastUpdate)) {
            return 1;
          } else if (parseInt(a.lastUpdate) > parseInt(b.lastUpdate)) {
            return -1;
          } else {
            return 0;
          }
        });

        return result;
      })
    );
  }

  public setBookmark(bookmark: BookmarkElement) {
    if (!this.auth.isAuthorized()) {
      return;
    }
    bookmark = new BookmarkModel(
      bookmark,
      this.config.getSettingNumber(
        SettingsKeys.bookmarksMaximumPercent,
        this.defaultBookmarksMaximumPercent
      )
    );
    this.checkCache();

    this.cachePromise.subscribe(() => {
      this.cache.put(bookmark.id + bookmark.type, bookmark);
    });

    const bookmarkUpdate: BookmarkUpdate = {
      series: bookmark.series,
      position: bookmark.position,
      size: bookmark.size,
    };

    this.historyApi
      .setBookmark(
        this.sessionService.getActiveProfileId(),
        bookmark.type,
        bookmark.id,
        bookmarkUpdate
      )
      .subscribe(() => {
        this.bookmarkUpdateEvent.emit();
      });
  }

  public deleteBookmark(type: BookmarkContentTypes, id: string) {
    this.checkCache();

    this.cachePromise.subscribe(() => {
      this.cache.remove(id + type);
    });

    this.historyApi.deleteBookmark(this.sessionService.getActiveProfileId(), type, id).subscribe();
  }

  private clearCache() {
    if (this.reloadingCache) {
      return;
    }
    this.cache = new CacheStorage();
    this.expirationTime = 0;
    this.cachePromise = undefined;
    this.reloadingCache = true;
  }
}
