import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-item-smarttv',
  templateUrl: './menu-item-smarttv.component.html',
  styleUrls: ['./menu-item-smarttv.component.scss'],
})
export class MenuItemSmarttvComponent implements OnInit {
  @Input() text?: string;
  @Input() icon?: string;
  @Input() iconSelected?: string;
  @Input() isActive?: boolean;
  @Input() isMenuOpen?: boolean;
  @Input() route?: string;
  @Output() entered = new EventEmitter<void>();

  constructor(private router: Router, private zone: NgZone) {
  }

  ngOnInit(): void {
    if (!this.iconSelected) {
      this.iconSelected = this.icon;
    }
  }

  public navigate(event?: Event): void {
    if (event) {
      event.cancelBubble = true;
      event.preventDefault();
    }

    this.zone.run(() => {
      if (this.route) {
        this.router.navigate(['main/' + this.route]);
      }

      this.entered.emit();
    });
  }
}
