import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CardOrientation, CmsGrid, Properties } from '@atv-core/api/cms';
import { EpgApiService } from '@atv-core/api/epg';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { RecordingModel } from '@atv-core/services/cache/stb/recording.model';
import { StbCacheService } from '@atv-core/services/cache/stb/stb-cache.service';
import { ConfigService, DetailTranslationKeys, SettingsKeys } from '@atv-bootstrap/services/config';
import { TempRecordGrid } from '@atv-pages/recordings-page/recordings-page.component';
import { RecordingCardMode } from '@atv-shared/recording-card/recording-card.model';

import { SessionService } from '@atv-core/services/session';
import { ContentProviderFactoryService } from '@atv-shared/content-provider/content-provider-factory.service';
import { RecordingSeasonContentProvider } from '@atv-shared/content-provider/providers/recording-season-content-provider';

@Component({
  selector: 'app-search-recording-folder',
  templateUrl: './search-recording-folder.component.html',
  styles: [],
})
export class SearchRecordingFolderComponent {
  public allowedAdultMode: AdultMode = AdultMode.any;

  private readonly recordingCardMode: RecordingCardMode;

  grids: CmsGrid[] = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private stbCache: StbCacheService,
    private adultService: AdultService,
    private sessionService: SessionService,
    private config: ConfigService,
    private epgApi: EpgApiService,
    private contentProviderFactoryService: ContentProviderFactoryService,
  ) {
    const useOldCardStyling = this.config.getSettingBoolean(SettingsKeys.useOldEpgCards, false);

    this.recordingCardMode = useOldCardStyling
      ? RecordingCardMode.CHANNEL_EPISODE_OLD_STYLING
      : RecordingCardMode.EPISODE_SCHEDULE;

    const pathPrams = this.activatedRoute.snapshot.params;

    if (pathPrams.seriesId) {
      this.showRecordingForSeries(pathPrams.seriesId);

      this.stbCache.stbRecordingChange.subscribe(() => {
        this.showRecordingForSeries(pathPrams.seriesId);
      });
    }
  }

  public goBack(): void {
    this.sessionService.goToPreviousRoute();
  }

  private showRecordingForSeries(seriesId: string): void {
    this.grids = [];

    this.stbCache.getRecordingsForSeries(
      seriesId,
      this.adultService.showAdult(this.allowedAdultMode),
    ).subscribe(result => {
      const recordingModels = result;

      if (recordingModels && recordingModels.length !== 0) {
        this.epgApi.getSchedule(recordingModels[0].schedule).subscribe(
          (schedule) => {
            const seriesTitle = result && schedule.series ? schedule.series.title : '';
            this.createGrids(recordingModels, seriesId, seriesTitle);
          },
          () => {
            this.createGrids(recordingModels, seriesId, '');
          },
        );
      }

      // this.grid = new CmsGrid({
      //   title: '',
      //   contentUrl: '',
      //   contents: result[1].map(
      //     recording =>
      //       new RecordingCardModel(recording, this.adultService.showAdult(this.allowedAdultMode))
      //   )
      // });
    });
  }

  private createGrids(recordingModels: RecordingModel[], seriesId: string, seriesTitle: string): void {
    const tmpGrids = this.convertToSeasonGroupedRecordingCard(
      recordingModels,
      seriesId,
      seriesTitle,
    );
    tmpGrids.forEach((grid) => {
      this.grids.push(
        new CmsGrid({
          title: grid.title,
          contentProvider: new RecordingSeasonContentProvider(this.contentProviderFactoryService, grid.recordings),
          properties: [Properties.SHOWPROGRESS, Properties.USE_BEST_SCHEDULE, Properties.SHOW_SCHEDULE_INFO],
          orientation: CardOrientation.LANDSCAPE,
        }),
      );
    });
  }

  private convertToSeasonGroupedRecordingCard(
    recordings: RecordingModel[],
    seriesId: string,
    seriesTitle: string
  ): TempRecordGrid[] {
    const tmpGrid: TempRecordGrid[] = [];

    recordings.sort((a, b) => {
      if (a.seasonOrdinal < b.seasonOrdinal) {
        return -1;
      } else if (a.seasonOrdinal > b.seasonOrdinal) {
        return 1;
      } else {
        if (a.programOrdinal < b.programOrdinal) {
          return -1;
        } else if (a.programOrdinal > b.programOrdinal) {
          return 1;
        } else {
          if (parseInt(a.plannedStart, 10) > parseInt(b.plannedStart, 10)) {
            return 1;
          } else if (parseInt(a.plannedStart, 10) < parseInt(b.plannedStart, 10)) {
            return -1;
          } else {
            return 0;
          }
        }
      }
    });

    recordings.forEach((recording) => {
      if (
        seriesId !== undefined &&
        recording.series === seriesId
        // channelId !== undefined &&
        // recording.channel === channelId
      ) {
        const season = tmpGrid.find((grid) => grid.seasonId === recording.season);
        if (season) {
          season.recordings.push(recording);
        } else {
          const title =
            recording.seasonOrdinal && recording.seasonOrdinal !== 0
              ? (seriesTitle ? seriesTitle + ': ' : '') +
                this.config.getTranslation(DetailTranslationKeys.detail_season) +
                ' ' +
                recording.seasonOrdinal
              : seriesTitle
              ? seriesTitle
              : this.config.getTranslation(DetailTranslationKeys.detail_season);
          tmpGrid.push({
            title,
            seasonId: recording.season,
            recordings: [recording],
          });
        }
      }
    });

    return tmpGrid;
  }
}
