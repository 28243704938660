<div class="profile-selection-wrapper" *ngIf="showPopup">
  <div class="profile-selection-close" (click)="closePopup()"></div>
  <app-switch-profiles
    *ngIf="popupMode === ProfilePopupModes.SWITCH || popupMode === ProfilePopupModes.MANAGE_SELECT"
    (closePopup)="closePopup()"
    (createProfile)="createProfile()"
    (editProfile)="editProfile($event)"
    (manageProfiles)="manageProfiles($event)"
    [editMode]="popupMode === ProfilePopupModes.MANAGE_SELECT"
  ></app-switch-profiles>

  <app-manage-profiles
    *ngIf="popupMode === ProfilePopupModes.MANAGE"
    [createEditProfile]="createEditProfile"
    (backToSwitch)="backToSwitch()"
  ></app-manage-profiles>
</div>
