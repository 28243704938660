<div class="filter-wrapper" #filterWrapperRef>
  <div
    class="filter-current-state-wrapper"
    [class.filters-active]="filtersActive"
    [hidden]="!filtersActive"
    (smartTvKeyDownEnter)="clearPressed()"
    (click)="clearPressed()"
  >
    {{ clearFilters }}
  </div>
</div>
