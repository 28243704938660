<!-- TODO use same logic for generic and recording card -->
<app-recording-card
  *ngIf="recordingCardModel"
  [allowedAdultMode]="allowedAdultMode"
  [recordingCardInput]="recordingCardModel"
  [showProgress]="showProgress()"
  [scrollElement]="scrollElement"
></app-recording-card>
<a
  #card
  *ngIf="cardModel"
  class="card"
  [class.portrait-card]="cardModel.orientation !== CardOrientation.LANDSCAPE"
  [class.landscape-card]="cardModel.orientation === CardOrientation.LANDSCAPE"
  [class.extra-large]="cardSize === CardSize.EXTRA_LARGE"
  [class.large]="cardSize === CardSize.LARGE"
  [class.medium]="cardSize === CardSize.MEDIUM"
  [class.normal]="cardSize === CardSize.NORMAL"
  [class.small]="cardSize === CardSize.SMALL"
  (click)="doAction($event)"
  (smartTvKeyDownEnter)="doAction($event)"
  tabindex="-1"
  [appCenterOnFocus]="scrollElement"
  (focus)="onFocus()"
  (mouseenter)="onFocus()"
  (focusout)="onFocusOut()"
  (mouseleave)="onFocusOut()"
>
  <div class="card-info">
    <img
      *ngIf="channelCardMode === ChannelCardModes.TOGGLE"
      class="favorite-icon"
      alt="favorite"
      src="{{ cardModel.favoriteIcon }}"
    />

    <div
      *ngIf="cardModel.channelImage"
      [class.gradient]="!useOldEpgCardContent"
      class="channel-image-container"
    >
      <img *ngIf="!useOldEpgCardContent" alt="channel" src="{{ cardModel.channelImage }}" />
    </div>
    <div
      class="card-info-schedule"
      *ngIf="cardModel.scheduleText"
      [class.has-progress]="cardModel.progressPercentage !== undefined"
    >
      {{ cardModel.scheduleText }}
    </div>
    <div
      *ngIf="showImage"
      class="main-image"
      [class.background-contain]="isChannelBackgroundImg()"
      [class.background-cover]="!isChannelBackgroundImg()"
      [style.background-image]="cardModel.image ? 'url(' + cardModel.image + ')' : ''"
    ></div>
    <app-progress-bar
      class="card-progress"
      [class.hidden]="cardModel.progressPercentage === undefined"
      [progressPercentage]="cardModel.progressPercentage"
    ></app-progress-bar>
  </div>
  <div class="card-detail">
    <div class="card-detail-row-group"
         [class.card-detail-row-group2]="isRecordingFolder()">
      <div class="card-row1">
        <div class="card-row1-text" [ngClass]="cardModel.row1Styling">{{ cardModel.row1 }}</div>
        <div class="row-icons old-styling" *ngIf="useOldEpgCardContent && showFullDetail">
          <img *ngFor="let icon of cardModel.icons" alt="" src="{{ icon }}" />
        </div>
        <div
          *ngIf="useOldEpgCardContent && isRecordingFolder()"
          class="recording-card-folder old-styling"
        >
          <span>{{ getFolderNumber() }}</span>
        </div>
      </div>
      <div class="card-row2" *ngIf="showFullDetail">
        <div class="card-row2-text" [ngClass]="cardModel.row2Styling">{{ cardModel.row2 }}</div>
        <div class="row-icons" *ngIf="!useOldEpgCardContent">
          <img *ngFor="let icon of cardModel.icons" alt="" src="{{ icon }}" />
        </div>
      </div>
    </div>
    <div *ngIf="isRecordingFolder() && !useOldEpgCardContent" class="recording-card-folder">
      <span>{{ getFolderNumber() }}</span>
    </div>
  </div>
</a>
