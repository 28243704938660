import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CardOrientation, CmsGrid, PageTypes, Properties } from '@atv-core/api/cms';
import { EpgApiService } from '@atv-core/api/epg';
import { HistoryApiService } from '@atv-core/api/history';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { ChannelCacheService, ChannelModel } from '@atv-core/services/cache/channel';
import { RecordingPageTypes, StbCacheService } from '@atv-core/services/cache/stb';
import { RecordingModel } from '@atv-core/services/cache/stb/recording.model';
import {
  ConfigService,
  DetailTranslationKeys,
  ErrorTranslationKeys,
  MenuTranslationKeys,
  MyStuffTranslationKeys,
  RecordingTranslationKeys,
  SettingsKeys
} from '@atv-bootstrap/services/config';
import { DetailOverlayService } from '@atv-core/services/detail/detail-overlay.service';
import { PageContext, PageUrlTypes } from '@atv-core/services/log/log.model';
import { LogService } from '@atv-core/services/log/log.service';
import { PageData } from '@atv-core/services/menu-manager/menu-manager.service';
import { MessagesService } from '@atv-core/services/messages';
import { SessionService } from '@atv-core/services/session';
import { Stb } from '@atv-core/services/session/stb';
import {
  NavigationSections,
  SpatialNavigationService
} from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SessionStorageKeys } from '@atv-core/utility/constants/sessionStorageKeys';
import { CustomerSettings } from '@atv-core/utility/constants/shared';
import { EpgUtilityService } from '@atv-core/utility/epg-utility/epg-utility.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { ReloadablePageComponent } from '@atv-pages/reloadable-page.component';
import { TooltipPositions } from '@atv-shared/directives/tooltip/tooltip.directive';
import { RecordingCardMode } from '@atv-shared/recording-card/recording-card.model';
import { environment } from '@env/environment';
import { forkJoin, fromEvent, Observable, of, Subscription } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { RecordingFolderContentProvider } from '@atv-shared/content-provider/providers/recording-folder-content-provider';
import { ContentProviderFactoryService } from '@atv-shared/content-provider/content-provider-factory.service';
import { RecordingSeasonContentProvider } from '@atv-shared/content-provider/providers/recording-season-content-provider';

export interface TempRecordGrid {
  title: string;
  seasonId: string;
  recordings: RecordingModel[];
}

@Component({
  selector: 'app-recordings-page',
  templateUrl: './recordings-page.component.html',
  styleUrls: ['./recordings-page.component.scss'],
})
export class RecordingsPageComponent extends ReloadablePageComponent implements OnInit, OnDestroy {
  public recordingPageType: RecordingPageTypes;
  public allowedAdultMode: AdultMode = AdultMode.any;

  grids: CmsGrid[] = [];
  public serieRecordings: RecordingModel[];

  channels: ChannelModel[];

  usableStbs: Stb[];
  allStbs: Stb[];

  stbId: string;
  stbProgressPercent = undefined;
  stbProgressText = '';

  public showAdultRecordings = false;
  public showAdultToggleButton = false;

  public inFolder = false;

  public noResultText = '';
  public enableAdultText = '';
  public disableAdultText = '';

  public tooltipPosition = TooltipPositions.LEFT;
  public tooltipText1;
  public tooltipText2;

  private useOldEpgCardStyling = false;
  private recordingCardMode = RecordingCardMode.EPISODE_SCHEDULE;

  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnSubscription: Subscription = null;
  private closeDetailSubscription: Subscription;
  private scrollTopSubscription: Subscription;

  constructor(
    sessionService: SessionService,
    private historyApi: HistoryApiService,
    private channelCache: ChannelCacheService,
    private stbCache: StbCacheService,
    adultService: AdultService,
    config: ConfigService,
    private activatedRoute: ActivatedRoute,
    private epgApi: EpgApiService,
    private router: Router,
    private epgUtility: EpgUtilityService,
    messagesService: MessagesService,
    private log: LogService,
    authorizationService: AuthorizationService,
    private spatialNavigationService: SpatialNavigationService,
    private detailService: DetailOverlayService,
    private contentProviderFactoryService: ContentProviderFactoryService,
  ) {
    super(sessionService, authorizationService, adultService, messagesService, config);
    this.useOldEpgCardStyling = this.config.getSettingBoolean(SettingsKeys.useOldEpgCards);
    this.recordingCardMode = this.useOldEpgCardStyling
      ? RecordingCardMode.CHANNEL_PROGRAM_OLD_STYLING
      : RecordingCardMode.EPISODE_SCHEDULE;

    this.enableAdultText = this.config.getTranslation(
      MyStuffTranslationKeys.mystuff_adultmode_activate,
    );
    this.disableAdultText = this.config.getTranslation(
      MyStuffTranslationKeys.mystuff_adultmode_stop,
    );
  }

  ngOnInit(): void {
    this.getRecordingPageType();

    if (this.recordingPageType === undefined) {
      this.noResultText = this.config.getTranslation(
        ErrorTranslationKeys.error_recording_no_recordings,
      );
      return;
    }

    if (this.recordingPageType === RecordingPageTypes.conflict) {
      this.noResultText = this.config.getTranslation(
        ErrorTranslationKeys.error_recording_no_conflicts,
      );
    } else if (this.recordingPageType === RecordingPageTypes.recorded) {
      this.noResultText = this.config.getTranslation(
        ErrorTranslationKeys.error_recording_no_recordings,
      );
    } else if (this.recordingPageType === RecordingPageTypes.planned) {
      this.noResultText = this.config.getTranslation(
        MyStuffTranslationKeys.mystuff_rec_no_planned_recordings,
      );
    } else if (this.recordingPageType === RecordingPageTypes.expires) {
      this.noResultText = this.config.getTranslation(MyStuffTranslationKeys.mystuff_rec_no_expired);
    }

    this.showAdultToggleButton =
      environment.allowAdult &&
      (this.allowedAdultMode === AdultMode.any || this.allowedAdultMode === AdultMode.true);

    this.stbCache.stbRecordingChange.subscribe(() => {
      super.checkLoadPage();
    });
    this.doLog();

    super.start();

    if (this.isSmartTv) {
      this.closeDetailSubscription = this.detailService.closeDetailEvent.subscribe(() => {
        this.spatialNavigationService.setDefaultSection(NavigationSections.PAGE_CONTENT);
        this.spatialNavigationService.setFocus();
      });

      this.scrollTopSubscription = this.spatialNavigationService.scrollTopEvent.subscribe(() => {
        (document.querySelector('.smarttv-vertical-scroller') as HTMLDivElement).style.transform =
          'translateY(0px)';
      });
    }
  }

  private getRecordingPageType(): void {
    const pageData = this.activatedRoute.snapshot.data;
    switch (pageData.pageType) {
      case PageTypes.RECORDINGS_CONFLICT_PAGE:
        this.recordingPageType = RecordingPageTypes.conflict;
        return;
      case PageTypes.RECORDINGS_EXPIRE_PAGE:
        this.recordingPageType = RecordingPageTypes.expires;
        return;
      case PageTypes.RECORDINGS_PLANNED_PAGE:
        this.recordingPageType = RecordingPageTypes.planned;
        return;
      case PageTypes.RECORDINGS_RECORDED_PAGE:
        this.recordingPageType = RecordingPageTypes.recorded;
        return;
    }
  }

  toggleAdultMode(event?: Event): void {
    if (event) {
      event.cancelBubble = true;
      event.preventDefault();
    }
    if (this.showAdultToggleButton) {
      if (this.adultService.showAdult(this.allowedAdultMode)) {
        this.adultService.stopAdultMode();
        super.checkLoadPage();
      } else {
        this.adultService.checkAdultMode({
          successCallback: () => {
            super.checkLoadPage();
          },
          errorCallback: () => {
            this.messagesService.showErrorMessage(this.config.getTranslation(DetailTranslationKeys.detail_adult_warning));
          },
        });
      }
    }
    this.showAdultRecordings = this.adultService.showAdult(this.allowedAdultMode);
  }

  public loadPage(): void {
    this.showAdultRecordings = this.adultService.showAdult(this.allowedAdultMode);
    this.setTooltipTexts();

    const seriesId = this.activatedRoute.snapshot.params.seriesId;
    this.stbId = sessionStorage.getItem(SessionStorageKeys.last_selected_stb);
    if (seriesId) {
      this.inFolder = true;
    }

    const stbList = this.sessionService.getStbsList();
    this.allStbs = stbList.getStbs();
    if (this.recordingPageType === RecordingPageTypes.expires) {
      const npvrstb = stbList.getNpvrStb();
      this.usableStbs = npvrstb ? [npvrstb] : [];
    } else if (this.recordingPageType === RecordingPageTypes.conflict) {
      this.usableStbs = stbList.getRecordingStbs(false);
    } else {
      this.usableStbs = stbList.getRecordingStbs(true);
    }

    forkJoin([
      this.historyApi
        .getCustomerSetting(CustomerSettings.default_recording_device)
        .pipe(catchError(() => of(undefined))),
      this.channelCache.getChannels().pipe(catchError(() => of(undefined))),
    ]).subscribe((result) => {
      const custSetting = result[0] ? result[0].value : undefined;
      let stb: Stb = stbList.getDefaultStb(this.stbId || custSetting);

      if (!sessionStorage.getItem(SessionStorageKeys.last_selected_stb) && custSetting) {
        sessionStorage.setItem(SessionStorageKeys.last_selected_stb, custSetting);
      }

      const npvrStb = this.sessionService.getStbsList().getNpvrStb();
      if (this.recordingPageType === RecordingPageTypes.expires) {
        stb = npvrStb;
      } else if (this.recordingPageType === RecordingPageTypes.conflict) {
        if (npvrStb && stb.id === npvrStb.id && stbList.getRecordingStbs(false).length > 0) {
          stb = stbList.getRecordingStbs(false)[0];
        }
      }
      if (!stb || !stb.id) {
        this.grids = [
          new CmsGrid({
            title: '',
            contents: [],
            properties: [Properties.SHOWPROGRESS],
            orientation: CardOrientation.LANDSCAPE,
          }),
        ];
        return;
      }
      this.stbId = stb.id;
      this.channels = result[1] ? result[1] : [];

      if (seriesId) {
        this.initForStbSeriesAndChannel(stb.id, seriesId);
      } else {
        this.initForStb(stb.id);
      }
    });
  }

  getPageProperties(): Observable<Properties[]> {
    return of((this.activatedRoute.snapshot.data as PageData).properties);
  }

  private setTooltipTexts(): void {
    this.tooltipText1 = this.showAdultRecordings
      ? this.config.getTranslation(MyStuffTranslationKeys.mystuff_adultmode_stop)
      : this.config.getTranslation(MyStuffTranslationKeys.mystuff_adultmode_activate);

    this.tooltipText2 = this.config.getTranslation(RecordingTranslationKeys.recordings_delete_all);
  }

  stbFilterChanged(stbIndex: number): void {
    const stb = this.usableStbs[stbIndex];
    if (
      this.stbId === stb.id ||
      this.recordingPageType === RecordingPageTypes.expires ||
      this.recordingPageType === RecordingPageTypes.conflict
    ) {
      return;
    }
    sessionStorage.setItem(SessionStorageKeys.last_selected_stb, stb.id);
    this.router.navigate([SharedUtilityService.removePathParams(this.router.url, this.activatedRoute.snapshot.params)]);
    this.initForStb(stb.id);
    this.stbId = stb.id;
  }

  public registerElements(): void {
    if (!this.isSmartTv) {
      return;
    }
    this.unregisterElements();

    this.spatialNavigationService.register(
      NavigationSections.RECORDING_FILTERS,
      'app-stb-filter .filter-current-state-wrapper',
    );
    this.spatialNavigationService.register(NavigationSections.TOGGLE_ADULT, 'button#toggle-adult');
    this.spatialNavigationService.register(
      NavigationSections.DELETE_ALL_RECORDINGS,
      'div.delete-all-recordings',
    );
    window.setTimeout(() => {
      this.spatialNavigationService.unregister(NavigationSections.PAGE_CONTENT);
      this.spatialNavigationService.register(
        NavigationSections.PAGE_CONTENT,
        'app-grid .grid-item a',
        { leaveFor: { right: '', down: '' } },
      );
      this.spatialNavigationService.setDefaultSection(NavigationSections.PAGE_CONTENT);
    }, 0);

    this.returnSubscription = fromEvent(document, 'keydown')
      .pipe(
        filter((event: KeyboardEvent) =>
          this.spatialNavigationService.keyCodeIsReturn(event.keyCode),
        ),
      )
      .subscribe(() => {
        if (this.inFolder) {
          this.goBack();
        } else {
          this.spatialNavigationService.setFocus(NavigationSections.MENU);
        }
      });
  }

  private initForStb(stbId: string): void {
    this.serieRecordings = [];
    this.showAvailableSpace(stbId);
    this.grids = [];
    this.grids = [
      new CmsGrid({
        title: '',
        contentProvider: new RecordingFolderContentProvider(this.stbCache, this.contentProviderFactoryService,
          stbId, this.recordingPageType, this.showAdultRecordings),
        properties: [Properties.SHOWPROGRESS, Properties.SHOW_SCHEDULE_INFO, Properties.USE_BEST_SCHEDULE],
        orientation: CardOrientation.LANDSCAPE,
      }),
    ];

    if (this.isSmartTv) {
      this.registerElements();

      const prevRoute = this.sessionService.previousRoute;
      if (
        prevRoute.includes(this.recordingPageType) &&
        !prevRoute.endsWith(this.recordingPageType)
      ) {
        this.spatialNavigationService.setFocus(NavigationSections.PAGE_CONTENT);
      }
    }
  }

  private doLog(): void {
    let pageTitle;
    let pageURL;

    if (this.recordingPageType === RecordingPageTypes.conflict) {
      pageURL = PageUrlTypes.my_stuff_recordings_conflict;
      pageTitle = this.config.getTranslation(MenuTranslationKeys.menu_recordings_conflicts);
    } else if (this.recordingPageType === RecordingPageTypes.expires) {
      pageURL = PageUrlTypes.my_stuff_recordings_expired;
      pageTitle = this.config.getTranslation(MenuTranslationKeys.menu_recordings_expire);
    } else if (this.recordingPageType === RecordingPageTypes.planned) {
      pageURL = PageUrlTypes.my_stuff_recordings_planned;
      pageTitle = this.config.getTranslation(MenuTranslationKeys.menu_recordings_planned);
    } else if (this.recordingPageType === RecordingPageTypes.recorded) {
      pageURL = PageUrlTypes.my_stuff_recordings_recorded;
      pageTitle = this.config.getTranslation(MenuTranslationKeys.menu_recordings_recorded);
    }

    if (pageURL && pageTitle) {
      this.log.pageView(
        new PageContext({
          pageURL,
          pageTitle,
          pageLocale: this.config.getLocale(),
        }),
      );
    }
  }

  private initForStbSeriesAndChannel(stbId: string, seriesId: string): void {
    this.serieRecordings = [];
    this.showAvailableSpace(stbId);
    this.grids = [];
    const fromUntil = this.epgUtility.getEpgDaysFromUntil();
    forkJoin([
      this.epgApi.getSeries(seriesId, fromUntil.from, fromUntil.until),
      this.stbCache.getRecordingsForDeviceForTypeForSeries(
        stbId,
        seriesId,
        this.recordingPageType,
        this.showAdultRecordings,
      ),
    ]).subscribe((result) => {
      const seriesTitle = result[0] ? result[0].series.title : '';
      this.serieRecordings = result[1];
      const tmpGrids = this.convertToSeasonGroupedRecordingCard(
        result[1],
        seriesId,
        seriesTitle,
      );
      tmpGrids.forEach((grid) => {
        this.grids.push(
          new CmsGrid({
            title: grid.title,
            contentProvider: new RecordingSeasonContentProvider(this.contentProviderFactoryService, grid.recordings, this.recordingPageType),
            properties: [Properties.SHOWPROGRESS, Properties.SHOW_SCHEDULE_INFO, Properties.USE_BEST_SCHEDULE],
            orientation: CardOrientation.LANDSCAPE,
          }),
        );
      });

      if (this.isSmartTv) {
        this.registerElements();
        this.spatialNavigationService.setFocus(NavigationSections.PAGE_CONTENT);
      }
    });
  }

  private showAvailableSpace(stbId: string): void {
    this.stbCache.getAvailableUsedMaxSpace(stbId).subscribe(
      (result) => {
        this.stbProgressPercent = (result.used / result.max) * 100;
        this.stbProgressText = result.available;
      },
      () => {
        this.stbProgressPercent = undefined;
        this.stbProgressText = '';
      },
    );
  }

  private convertToSeasonGroupedRecordingCard(
    recordings: RecordingModel[],
    seriesId: string,
    seriesTitle: string,
  ): TempRecordGrid[] {
    const tmpGrid: TempRecordGrid[] = [];

    recordings.sort((a, b) => {
      if (a.seasonOrdinal < b.seasonOrdinal) {
        return -1;
      } else if (a.seasonOrdinal > b.seasonOrdinal) {
        return 1;
      } else {
        return 0;
      }
    });

    recordings.forEach((recording) => {
      if (
        seriesId !== undefined &&
        recording.series === seriesId
      ) {
        const season = tmpGrid.find((grid) => grid.seasonId === recording.season);
        if (season) {
          season.recordings.push(recording);
        } else {
          const title =
            recording.seasonOrdinal && recording.seasonOrdinal !== 0
              ? (seriesTitle ? seriesTitle + ': ' : '') +
                this.config.getTranslation(DetailTranslationKeys.detail_season) +
                ' ' +
                recording.seasonOrdinal
              : seriesTitle
              ? seriesTitle
              : this.config.getTranslation(DetailTranslationKeys.detail_season);
          tmpGrid.push({
            title,
            seasonId: recording.season,
            recordings: [recording],
          });
        }
      }
    });

    return tmpGrid;
  }

  public hideRecordingFilters(): boolean {
    // when client has multiple stbs
    return !(this.allStbs?.length > 1);
  }

  public deleteAllRecordings(): void {
    this.stbCache.showDeleteAllRecordingsPopup.emit({
      recordingPageType: this.recordingPageType,
      serieRecordings: this.serieRecordings,
      routerParams: this.activatedRoute.snapshot.params,
    });
  }

  public goBack(): void {
    this.router.navigate([
      SharedUtilityService.removePathParams(this.router.url, this.activatedRoute.snapshot.params),
    ]);
  }

  private unregisterElements(): void {
    this.spatialNavigationService.unregister(NavigationSections.RECORDING_FILTERS);
    this.spatialNavigationService.unregister(NavigationSections.TOGGLE_ADULT);
    this.spatialNavigationService.unregister(NavigationSections.DELETE_ALL_RECORDINGS);
    this.spatialNavigationService.unregister(NavigationSections.PAGE_CONTENT);
    this.returnSubscription?.unsubscribe();
  }

  ngOnDestroy(): void {
    super.stop();

    if (this.isSmartTv) {
      this.unregisterElements();
    }

    if (this.closeDetailSubscription) {
      this.closeDetailSubscription.unsubscribe();
    }

    if (this.scrollTopSubscription) {
      this.scrollTopSubscription.unsubscribe();
    }
  }
}
