import { Params } from '@angular/router';
import { PriceResponse, ValidQuotePriceResponse } from '@atv-core/api/pricing/pricing.model.service';
import { BookmarkModel } from '@atv-core/services/cache/bookmark';
import { ConfigService, DetailTranslationKeys, SettingsKeys, TimeTranslationKeys } from '@atv-bootstrap/services/config';
import { DetailRoutes } from '@atv-detail/atv-detail.model';
import { environment } from '@env/environment';
import moment from 'moment';

import { PlatformType, StreamResolutionType } from '../constants/shared';
import { ImageRecipe, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';

export class SharedUtilityService {
  static getMinimumAgeImage(minimumAge: number): string {
    switch (minimumAge) {
      case 1:
      case 2:
      case 3:
      case 4:
        return './assets/theme/svg/4_normal.svg';
      case 5:
      case 6:
        return './assets/theme/svg/6_normal.svg';
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
        return './assets/theme/svg/12_normal.svg';
      case 13:
      case 14:
        return './assets/theme/svg/14_normal.svg';
      case 15:
      case 16:
        return './assets/theme/svg/16_normal.svg';
      case 17:
      case 18:
        return './assets/theme/svg/18_normal.svg';
      default:
        return '';
    }
  }

  static getResolutionImage(resolution: StreamResolutionType): string {
    switch (resolution) {
      case StreamResolutionType.FOURK:
        return './assets/theme/svg/4K_normal.svg';
      case StreamResolutionType.UHD:
        return './assets/theme/svg/UHD_normal.svg';
      case StreamResolutionType.HD:
        return './assets/theme/svg/HD_normal.svg';
      default:
        return undefined;
    }
  }

  static getHighestResolution(
    streams: { resolution: StreamResolutionType }[],
  ): StreamResolutionType {
    streams = streams.sort(
      (a, b) =>
        SharedUtilityService.resolutionTypeToNumber(b.resolution) -
        SharedUtilityService.resolutionTypeToNumber(a.resolution),
    );

    return streams[0]?.resolution;
  }

  static generateAuthState(): string {
    return this.getCSPRN() || this.getPRN();
  }

  static secondsToHHMMSS(time: number): string {
    const negPrefix = time < 0 ? '-' : '';
    const d = Math.abs(time);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    return (
      negPrefix + ((h > 0 ? h + ':' + (m < 10 ? '0' : '') : '') + m + ':' + (s < 10 ? '0' : '') + s)
    );
  }

  static getCSPRN(): string {
    if (window && window.crypto) {
      const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let array = new Uint8Array(40);
      window.crypto.getRandomValues(array);
      array = array.map((x) => validChars.charCodeAt(x % validChars.length));
      return String.fromCharCode.apply(null, array);
    }

    return undefined;
  }

  static getPRN(): string {
    return (
      Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    );
  }

  static timeStringToMs(time: string): number {
    if (Number.isNaN(time)) {
      return new Date(time).getTime();
    } else {
      return (parseInt(time, 10) || 0) * 1000;
    }
  }

  static timeStringToMoment(time: string): moment.Moment {
    if (Number.isNaN(time)) {
      return moment(time);
    } else {
      return moment((parseInt(time, 10) || 0) * 1000);
    }
  }

  static timeStringToDate(time: string): Date {
    if (Number.isNaN(time)) {
      return new Date(time);
    } else {
      return new Date((parseInt(time, 10) || 0) * 1000);
    }
  }

  static resolutionTypeToNumber(res: StreamResolutionType): number {
    switch (res) {
      case StreamResolutionType.SD:
        return 0;
      case StreamResolutionType.HD:
        return 1;
      case StreamResolutionType.UHD:
        return 2;
      case StreamResolutionType.FOURK:
        return 3;
    }
  }

  static getFullDetailUrl(detailRoute: DetailRoutes): string {
    // const url = window.location.pathname;

    // if (url.includes(DetailRoutes.detailBaseRoute)) {
    //   return url.substring(0, url.indexOf(DetailRoutes.detailBaseRoute)) + detailRoute;
    // } else {
    //   return url + detailRoute;
    // }

    const baseUrl = window.location.origin;
    return baseUrl + '/' + detailRoute;
  }

  static getFavoriteIcon(isFavorite: boolean): string {
    if (isFavorite) {
      return './assets/theme/svg/favoritesicon_selected.svg';
    } else {
      return './assets/theme/svg/favoritesicon_normal.svg';
    }
  }

  static formatMoment(m: Date): string {
    return moment(m).utc().minute(0).format('YYYY-MM-DDTHH:mm[Z]');
  }

  static getDayInfo(time: number, dayShort: boolean, config: ConfigService): string {
    if (!time) {
      return '';
    }

    const now = moment().startOf('day');
    const then = moment(time);
    const diff = moment(then).startOf('day').diff(now, 'days');

    let shortDay = '';
    if (Math.abs(diff) > 1) {
      if (dayShort) {
        shortDay += this.getShortDayTranslation(parseInt(then.format('e'), 10), config); // day of week
      } else {
        shortDay += this.getDayTranslation(parseInt(then.format('e'), 10), config); // day of week
      }

      shortDay += ' ' + then.format('DD');
      shortDay += ' ' + this.getMonthTranslation(parseInt(then.format('MM'), 10), config);
    } else {
      shortDay += this.getYesterdayTodayTomorrowTranslation(diff, config);
    }

    return shortDay;
  }

  static getShortDayTranslation(day: number, config: ConfigService): string {
    switch (day) {
      case 0:
        return config.getTranslation(TimeTranslationKeys.time_day_short_sun);
      case 1:
        return config.getTranslation(TimeTranslationKeys.time_day_short_mon);
      case 2:
        return config.getTranslation(TimeTranslationKeys.time_day_short_tue);
      case 3:
        return config.getTranslation(TimeTranslationKeys.time_day_short_wed);
      case 4:
        return config.getTranslation(TimeTranslationKeys.time_day_short_thu);
      case 5:
        return config.getTranslation(TimeTranslationKeys.time_day_short_fri);
      case 6:
        return config.getTranslation(TimeTranslationKeys.time_day_short_sat);
    }
  }

  static sanitize(content: string): string {
    return content.replace(/<[^>]*>/g, '');
  }

  static getQualityStringForPriceAndResolution(
    price: PriceResponse,
    resolution: string,
    config: ConfigService,
    validity?: number,
  ): string {
    let priceString = '';
    if (price) {
      if (
        price.customerPrice !== undefined &&
        price.originalPrice !== undefined &&
        price.customerPrice === price.originalPrice
      ) {
        priceString = `<b>${SharedUtilityService.sanitize(
          resolution,
        )}</b>&nbsp;&nbsp;&nbsp;${SharedUtilityService.sanitize(
          this.getPriceText(price.originalPrice, config),
        )}`;
      } else {
        priceString = `<b>${SharedUtilityService.sanitize(
          resolution,
        )}</b>&nbsp;&nbsp;&nbsp;<strike>${SharedUtilityService.sanitize(
          this.getPriceText(price.originalPrice, config),
        )}</strike>&nbsp;&nbsp;&nbsp;${SharedUtilityService.sanitize(
          this.getPriceText(price.customerPrice, config),
        )}`;
      }
    } else {
      priceString = `<b>${SharedUtilityService.sanitize(
        resolution,
      )}</b>&nbsp;&nbsp;&nbsp${SharedUtilityService.sanitize(this.getPriceText(0, config))}`;
    }

    if (validity) {
      priceString +=
        '&nbsp;/&nbsp;' +
        SharedUtilityService.sanitize(
          config
            .getTranslation(TimeTranslationKeys.time_duration_hours)
            .replace('$1', validity.toString()),
        );
    }
    return priceString;
  }

  static getLastValidQuote(quotes: ValidQuotePriceResponse[]): ValidQuotePriceResponse {
    let latestValidQuote: ValidQuotePriceResponse;
    quotes.forEach((quote) => {
      if (!latestValidQuote || parseInt(quote.endTime, 10) > parseInt(latestValidQuote.endTime, 10)) {
        latestValidQuote = quote;
      }
    });

    return latestValidQuote;
  }

  static getAvailableUntilFromQuote(quote: ValidQuotePriceResponse, config: ConfigService): string {
    return (
      config.getTranslation(DetailTranslationKeys.detail_available_until) +
      ' ' +
      SharedUtilityService.getDayInfo(
        SharedUtilityService.timeStringToMs(quote.endTime),
        false,
        config,
      ) +
      ', ' +
      SharedUtilityService.timeStringToMoment(quote.endTime).format('HH:mm')
    );
  }

  static removePathParams(url: string, params: Params): string {
    if (params) {
      const values = Object.values(params);
      if (values.every((value) => url.includes(value))) {
        values.forEach((value) => (url = url.replace('/' + value, '')));
      }
    }
    return url;
  }

  static getLastBookmarkEpisode(bookmarks: BookmarkModel[]): BookmarkModel {
    let lastBookmark: BookmarkModel;
    bookmarks.forEach((bookmark) => {
      if (!lastBookmark || parseInt(lastBookmark.lastUpdate, 10) < parseInt(bookmark.lastUpdate, 10)) {
        lastBookmark = bookmark;
      }
    });

    return lastBookmark;
  }

  static s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  static guid(): string {
    return (
      this.s4() +
      this.s4() +
      '-' +
      this.s4() +
      '-' +
      this.s4() +
      '-' +
      this.s4() +
      '-' +
      this.s4() +
      this.s4() +
      this.s4()
    );
  }

  static getPosterImage(imageUrl: string, recipe: ImageRecipe, dontShowAdult: boolean): string {
    if (dontShowAdult) {
      return './assets/theme/images/maturity_defaultimage.png';
    } else if (imageUrl) {
      return ImageRecipeUtil.createImageUrl(imageUrl, recipe);
    } else {
      return '';
    }
  }

  private static getYesterdayTodayTomorrowTranslation(day: number, config: ConfigService): string {
    switch (day) {
      case -1:
        return config.getTranslation(TimeTranslationKeys.time_yesterday);
      case 0:
        return config.getTranslation(TimeTranslationKeys.time_today);
      case 1:
        return config.getTranslation(TimeTranslationKeys.time_tomorrow);
    }
  }

  private static getDayTranslation(day: number, config: ConfigService): string {
    switch (day) {
      case 0:
        return config.getTranslation(TimeTranslationKeys.time_day_sunday);
      case 1:
        return config.getTranslation(TimeTranslationKeys.time_day_monday);
      case 2:
        return config.getTranslation(TimeTranslationKeys.time_day_tuesday);
      case 3:
        return config.getTranslation(TimeTranslationKeys.time_day_wednesday);
      case 4:
        return config.getTranslation(TimeTranslationKeys.time_day_thursday);
      case 5:
        return config.getTranslation(TimeTranslationKeys.time_day_friday);
      case 6:
        return config.getTranslation(TimeTranslationKeys.time_day_saturday);
    }
  }

  private static getMonthTranslation(month: number, config: ConfigService): string {
    switch (month) {
      case 1:
        return config.getTranslation(TimeTranslationKeys.time_month_short_january);
      case 2:
        return config.getTranslation(TimeTranslationKeys.time_month_short_february);
      case 3:
        return config.getTranslation(TimeTranslationKeys.time_month_short_march);
      case 4:
        return config.getTranslation(TimeTranslationKeys.time_month_short_april);
      case 5:
        return config.getTranslation(TimeTranslationKeys.time_month_short_may);
      case 6:
        return config.getTranslation(TimeTranslationKeys.time_month_short_june);
      case 7:
        return config.getTranslation(TimeTranslationKeys.time_month_short_july);
      case 8:
        return config.getTranslation(TimeTranslationKeys.time_month_short_august);
      case 9:
        return config.getTranslation(TimeTranslationKeys.time_month_short_september);
      case 10:
        return config.getTranslation(TimeTranslationKeys.time_month_short_october);
      case 11:
        return config.getTranslation(TimeTranslationKeys.time_month_short_november);
      case 12:
        return config.getTranslation(TimeTranslationKeys.time_month_short_december);
    }
  }

  private static getPriceText(price: number, config: ConfigService): string {
    const decimalCount: number = config.getSettingNumber(SettingsKeys.decimalCount, 2);
    const decimalDivider: number = config.getSettingNumber(SettingsKeys.decimalDivider, 1);

    if (price === 0) {
      return config.getTranslation(DetailTranslationKeys.detail_gratis);
    } else {
      return config
        .getTranslation(DetailTranslationKeys.detail_price)
        .replace('$1', (price / decimalDivider).toFixed(decimalCount).toLocaleString());
    }
  }

  static isSmartTv(): boolean {
    return (
      environment.platform === PlatformType.SMARTTV_TIZEN ||
      environment.platform === PlatformType.SMARTTV_WEBOS
    );
  }
}
