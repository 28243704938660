export class CacheStorage {
  private cache = {};

  constructor() {}

  public clearCache() {
    delete this.cache;
    this.cache = {};
  }

  public put(key: string, cacheItem: any) {
    this.cache[key] = cacheItem;
  }

  public remove(key: string) {
    delete this.cache[key];
  }

  public get(key: string) {
    return this.cache[key];
  }

  public values() {
    return Object.keys(this.cache).map(key => {
      return this.cache[key];
    });
  }
}
