import { ContentProvider } from '@atv-shared/content-provider/content-provider';
import { RecordingPageTypes } from '@atv-core/services/cache/stb';
import { ContentProviderFactoryService } from '@atv-shared/content-provider/content-provider-factory.service';
import { RecordingModel } from '@atv-core/services/cache/stb/recording.model';
import { CardOrientation, Ribbon2Asset } from '@atv-core/api/cms';
import { Observable, forkJoin } from 'rxjs';

export class RecordingSeasonContentProvider extends ContentProvider {
  private hasMoreItems = true;

  constructor(private contentProviderFactoryService: ContentProviderFactoryService,
              private seasonRecordings: RecordingModel[],
              private pageType?: RecordingPageTypes) {
    super();

    this.orientation = CardOrientation.LANDSCAPE;
  }

  public hasNext(): boolean {
    return this.hasMoreItems;
  }

  public getItems(): Observable<Ribbon2Asset[]> {
    this.hasMoreItems = false;
    return forkJoin(
      this.seasonRecordings.map(recording => this.contentProviderFactoryService.recordingToRibbon2Asset(recording, this.pageType)));
  }

  public resetPaging(): void {
    this.hasMoreItems = true;
  }
}
