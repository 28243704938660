<div class="create-edit-profile-wrapper" *ngIf="createEditProfile?.ready">
  <div class="create-edit-profile-content">
    <div class="create-edit-title">
      {{ createEditProfile.isEdit ? profileEditTitle : profileAddTitle }}
    </div>
    <div class="create-edit-profile">
      <div class="create-edit-option">
        <div class="create-edit-option-title">{{ profilePropertyName }}</div>
        <input
          autocomplete="off"
          id="profname"
          name="profname"
          class="create-edit-option-input"
          type="text"
          maxlength="{{ maxProfileNameLength }}"
          [(ngModel)]="createEditProfile.name"
        />
      </div>
      <div class="create-edit-option">
        <div class="create-edit-option-title">{{ profilePropertyCatalog }}</div>
        <select class="catalog-selections" [(ngModel)]="createEditProfile.catalogId">
          <option
            class="catalog-selection"
            *ngFor="let catalog of createEditProfile?.availableCatalogs"
            [ngValue]="catalog.id"
          >
            {{ catalog.name }}
          </option>
        </select>
      </div>
      <div class="create-edit-option">
        <div class="create-edit-option-title">{{ profilePropertyAvatar }}</div>
        <div class="avatar-selections">
          <div
            [class.selected]="createEditProfile?.avatarId === avatar.id"
            class="avatar-selection"
            *ngFor="let avatar of createEditProfile?.availableAvatars"
            (click)="createEditProfile.avatarId = avatar.id"
          >
            <img [src]="getAvatarImage(avatar)" />
          </div>
        </div>
      </div>
      <div class="profile-actions">
        <div
          class="profile-action-flex"
          *ngIf="!createEditProfile?.isMaster && createEditProfile?.isEdit"
        >
          <app-delete-profile (confirmDelete)="delete()"></app-delete-profile>
        </div>
        <button class="profile-action" (click)="cancel()">
          {{ profileCancelButton }}
        </button>
        <button class="profile-action create-edit" (click)="createEdit()">
          {{ createEditProfile.isEdit ? profileSaveButton : profileCreateButton }}
        </button>
      </div>
    </div>
  </div>
</div>
