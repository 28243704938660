import { StreamResolutionType } from '@atv-core/utility/constants/shared';

import { RibbonAsset } from '../cms';

export interface VodDetail {
  id: string;
  type: VodAssetTypes;
  title: string;
  originalTitle?: string;
  shortSynopsis?: string;
  longSynopsis?: string;
  posterImage?: string;
  boxcoverImage?: string;
  wallpaperImage?: string;
  adult?: boolean;
  minimumAge?: number;
  parentalRatings?: string[];
  duration?: number;
  episode?: number;
  season?: number;
  seasonId?: string;
  seriesId?: string;
  credits?: CreditAsset[];
  originalLocale?: string;
  dubbedLocale?: string;
  subtitleLocale?: string;
  productionYear?: number;
  productionLocations?: string[];
  nextEpisode?: string;
  tags?: string[];
  genres?: string[];
  streams?: VodAssetStream[];
  seasons?: VodAssetSummary[];
  episodes?: VodAssetSummary[];
  validFrom?: string;
  validUntil?: string;
  score?: number;
  operatorScore?: number;

  detailOpen?: boolean;
}

export interface CreditAsset {
  role: VodCreditRoles;
  name: string;
  character?: string;
}

export interface VodAssetStream {
  id: string;
  type: VodAssetStreamType;
  resolution: StreamResolutionType;
}

export class VodAssetSummary {
  id: string;
  type: VodAssetTypes;
  title: string;
  posterImage?: string;
  episode?: number;
  season?: number;
  seasonId?: string;

  constructor(vod: VodAssetSummary) {
    Object.keys(vod).forEach((property) => (this[property] = vod[property]));
  }
}

export interface VodCategory {
  tag: string;
  title: string;
  defaultImage?: string;
  focusImage?: string;
  highlightImage?: string;
  adult?: boolean;
  count?: number;
  subcategories?: VodCategory[];
}

export enum VodAssetStreamType {
  FULL = 'FULL',
  TRAILER = 'TRAILER',
}

export enum VodAssetTypes {
  ASSET = 'ASSET',
  SEASON = 'SEASON',
  SERIES = 'SERIES',
}

export enum VodCreditRoles {
  ACTOR = 'ACTOR',
  DIRECTOR = 'DIRECTOR',
  ANCHOR = 'ANCHOR',
  ART_DIRECTOR = 'ART_DIRECTOR',
  PERFORMER = 'PERFORMER',
  REPORTER = 'REPORTER',
  PRODUCER = 'PRODUCER',
  SCENARIST = 'SCENARIST',
  VOICE_OVER_ARTIST = 'VOICE_OVER_ARTIST',
  COMMENTATOR = 'COMMENTATOR',
  CO_COMMENTATOR = 'CO_COMMENTATOR',
  LINE_REPORTERS = 'LINE_REPORTERS',
  WRITER = 'WRITER',
}

export interface BundleLocalizedResponse {
  id: string;
  image: string;
  minAge?: number;
  adult?: boolean;
  validFrom: string;
  validUntil: string;
  assets?: RibbonAsset[];
  resolutions?: SimpleBundleResolutionResponseType[];
  title: string;
  synopsis?: string;
}

export interface SimpleBundleResolutionResponseType {
  id: string;
  resolution: StreamResolutionType;
}
