import { StreamDrmTypes, StreamPackageTypes } from '@atv-core/api/api/api-api.model';
import { ClientDetails } from '@atv-core/utility/static-services/client-detail.static-service';
import { StreamType } from '@atv-core/utility/constants/shared';
import { environment } from '@env/environment';

const debug = false;

export class PlayerConfigInfo {
  config = {};
  packaging: StreamPackageTypes = undefined;
  encryption = undefined;
}

export class PlayerConfig {
  private static info = new PlayerConfigInfo();
  private static configDASH = {
    sendCustomData: true,
    initialSeekTime: 0,
    debug: debug,
    silverlightFile: './dashcs/dashcs.xap',
    merchant: 'cabovisao',
    playReadyLicenseServerURL: environment.drmStaging
      ? 'https://lic.staging.drmtoday.com/license-proxy-headerauth/drmtoday/RightsManager.asmx'
      : 'https://lic.drmtoday.com/license-proxy-headerauth/drmtoday/RightsManager.asmx',
    widevineLicenseServerURL: environment.drmStaging
      ? 'https://lic.staging.drmtoday.com/license-proxy-widevine/cenc/'
      : 'https://lic.drmtoday.com/license-proxy-widevine/cenc/',

    drm: 'auto',
    redirectedURLParameter: 'de-redirect-url',
    addQuerystringToManifestURL: data => {
      const qs = [];
      if (data.isIE11 && data.runCount === 0) {
        qs.push('response=200');
      }
      return qs;
    },
    techs: ['dashjs']
  };

  private static configMSS = {
    drm: 'auto',
    initialSeekTime: 0,
    sendCustomData: true,
    merchant: 'cabovisao',
    playReadyLicenseServerURL: environment.drmStaging
      ? 'https://lic.staging.drmtoday.com/license-proxy-headerauth/drmtoday/RightsManager.asmx'
      : 'https://lic.drmtoday.com/license-proxy-headerauth/drmtoday/RightsManager.asmx',
    widevineLicenseServerURL: environment.drmStaging
      ? 'https://lic.staging.drmtoday.com/license-proxy-widevine/cenc/'
      : 'https://lic.drmtoday.com/license-proxy-widevine/cenc/',

    isSmoothStreaming: true,
    debug: debug,
    silverlightFile: '/dash_everywhere/dashcs/dashcs.xap',
    techs: ['dashcs']
  };

  private static configHLS = {
    initialSeekTime: 0,
    fairplayLicenseServerURL: environment.drmStaging
      ? 'https://lic.staging.drmtoday.com/license-server-fairplay/'
      : 'https://lic.drmtoday.com/license-server-fairplay/',
    fairplayCertificateURL: environment.drmStaging
      ? 'https://lic.staging.drmtoday.com/license-server-fairplay/cert/'
      : 'https://lic.drmtoday.com/license-server-fairplay/cert/',

    variantId: '',
    sendCustomData: true,
    //  'authenticationToken': '',
    // 'generatePSSH': false,
    // 'enableSmoothStreamingCompatibility': false,
    // 'isSmoothStreaming': false,
    drm: 'auto',
    techs: ['html5hls'],
    debug: debug
  };

  public static getInfo(type) {
    if (this.info.packaging !== undefined) {
      return this.info;
    }

    const details = ClientDetails.getDetails();
    this.info.encryption = StreamDrmTypes.PLAYREADY;
    this.info.packaging =
      type === StreamType.LINEAR ? StreamPackageTypes.MSS : StreamPackageTypes.DASH;

    if (details.os === 'SMART-TV') {
      this.info.packaging = StreamPackageTypes.DASH;
      this.info.encryption = StreamDrmTypes.WIDEVINE;
    } else if (details.browser === 'Safari' && parseInt(details.browserMajorVersion) >= 9) {
      this.info.packaging = StreamPackageTypes.HLS;
      this.info.encryption = StreamDrmTypes.FAIRPLAY;
    } else if (details.browser === 'Chrome') {
      this.info.packaging = StreamPackageTypes.DASH;
      this.info.encryption = StreamDrmTypes.WIDEVINE;
    } else if (details.browser === 'Firefox') {
      if (parseInt(details.browserMajorVersion) >= 47) {
        this.info.packaging = StreamPackageTypes.DASH;
        this.info.encryption = StreamDrmTypes.WIDEVINE;
      }
    } else if (details.browser === 'Edge') {
      this.info.packaging = StreamPackageTypes.DASH;
      this.info.encryption = StreamDrmTypes.PLAYREADY;
    } else if (
      details.browser === 'Internet Explorer' &&
      (parseInt(details.browserMajorVersion) >= 11 &&
        details.os === 'Windows' &&
        parseFloat(details.osVersion) >= 8.1)
    ) {
      this.info.packaging = StreamPackageTypes.DASH;
      this.info.encryption = StreamDrmTypes.PLAYREADY;
    } else if (details.browser === 'Opera') {
      this.info.packaging = StreamPackageTypes.DASH;
      this.info.encryption = StreamDrmTypes.WIDEVINE;
    }

    if (this.info.packaging === StreamPackageTypes.HLS) {
      this.info.config = this.configHLS;
    } else if (this.info.packaging === StreamPackageTypes.DASH) {
      this.info.config = this.configDASH;
    } else if (this.info.packaging === StreamPackageTypes.MSS) {
      this.info.config = this.configMSS;
    }

    return this.info;
  }
}
