import { HttpErrorResponse } from '@angular/common/http';
import { CmsActionMethod, CmsContentTypes, PageTypes } from '@atv-core/api/cms/cms-api.model';
import { PlatformType, StreamType } from '@atv-core/utility/constants/shared';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { RecordingEpgInfo } from '@atv-shared/buttons/create-edit-recording/create-edit-recording-input.model';
import { PlayInfoModel } from '../../../atv-player/model/play-info-model';
import { SubscriptionSettingsModel, SubscriptionTypes } from '@atv-shared/settings/subscription-settings/subscription-settings.model';
import moment from 'moment';

import { RecordingModel } from '../cache/stb/recording.model';
import { Stb } from '../session/stb';
import {
  AdvertisementSubcategories,
  ArticleSubcategories,
  AuthenticateSubcategories,
  LogSubcategories,
  MainCategoryTypes,
  PlayerSubcategories,
  RecordingSubcategories,
  StartupSubcategories,
  SwipeSubcategories,
  TrackSubcategories,
} from './analytics-categories.model';

export interface ClientEvents {
  device: DeviceInfo;
  app: ApplicationInfo;
  events: ClientEventEntry[];
}

export interface DeviceInfo {
  deviceId: string;
  model: string;
  serial?: string;
  platform: PlatformType;
  os: string;
  macAddress?: string;
}

export interface ApplicationInfo {
  name: string;
  version: string;
  build: string;
}

export class ClientEventEntry {
  public readonly id: string;
  public readonly category: Category;
  public readonly timestamp: string;
  public readonly loginContext?: LoginContext;
  public readonly pageContext?: PageContext;
  public readonly properties: Property[];

  constructor(
    categorie: Category,
    loginContext: LoginContext,
    pageContext: PageContext,
    properties: Property[]
  ) {
    this.id = SharedUtilityService.guid();
    this.category = categorie;
    this.timestamp = moment().utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    if (loginContext) {
      this.loginContext = loginContext;
    }
    if (pageContext) {
      this.pageContext = pageContext;
    }

    this.properties = properties;
  }
}

export interface Category {
  readonly type: MainCategoryTypes;
  readonly subType:
    | TrackSubcategories
    | AdvertisementSubcategories
    | PlayerSubcategories
    | AuthenticateSubcategories
    | StartupSubcategories
    | SwipeSubcategories
    | RecordingSubcategories
    | ArticleSubcategories
    | LogSubcategories;
}

export interface LoginContext {
  readonly userId: string;
  readonly customerId: string;
  readonly deviceId: string;
  readonly countryCode?: string;
  readonly profileId: string;
  readonly catalogId: string;
  readonly entitlementId: string;
}

export class LogErrorInfo {
  code: string;
  errorId: string;
  message: string;

  constructor(errorResponse: HttpErrorResponse) {
    if (errorResponse) {
      if (errorResponse.error) {
        this.code = errorResponse.error.code;
        this.errorId = errorResponse.error.errorId;
        this.message = errorResponse.error.message || undefined;
        if (this.message && this.message.length > 255) {
          this.message = this.message.substring(0, 255) + '...';
        }
      }

      if (!errorResponse.error || !errorResponse.error.code) {
        this.code = `HTTP_ERROR_${errorResponse.status}`;
      }
    }
  }
}

export class PageContext {
  pageURL: PageUrlTypes | string;
  pageTitle?: string;
  pageLocale: string;

  constructor(obj: PageContext) {
    Object.keys(obj).map((key) => (this[key] = obj[key]));
  }
}

export enum PageUrlTypes {
  vod_favorites = 'vod_favorites',
  vod_recommended = 'vod_recommended',
  vod_new = 'vod_new',
  vod_movies = 'vod_movies',
  vod_series = 'vod_series',
  vod_adult = 'vod_adult',
  vod_search = 'vod_search',
  epg_recent = 'epg_recent',
  epg_favorites = 'epg_favorites',
  epg_recommended = 'epg_recommended',
  epg_guide = 'epg_guide',
  epg_lists = 'epg_lists',
  epg_search = 'epg_search',
  replay_epg_recent = 'replay_epg_recent',
  replay_epg_favorites = 'replay_epg_favorites',
  replay_epg_recommended = 'replay_epg_recommended',
  replay_epg_guide = 'replay_epg_guide',
  replay_epg_lists = 'replay_epg_lists',
  replay_epg_search = 'replay_epg_search',
  vod_detail = 'vod_detail',
  vod_series_detail = 'vod_series_detail',
  epg_detail = 'epg_detail',
  epg_series_detail = 'epg_series_detail',
  recording_detail = 'recording_detail',
  videoplayer = 'videoplayer',
  search = 'search',
  login = 'login',
  splash = 'splash',
  home_menu = 'home_menu',
  home_menu_epg = 'home_menu_epg',
  home_menu_replay_epg = 'home_menu_replay_epg',
  home_menu_my_stuff = 'home_menu_my_stuff',
  home_menu_apps = 'home_menu_apps',
  home_menu_vod = 'home_menu_vod',
  home_menu_search = 'home_menu_search',
  settings = 'settings',
  my_stuff = 'my_stuff',
  my_stuff_recordings_planned = 'my_stuff_recordings_planned',
  my_stuff_recordings_recorded = 'my_stuff_recordings_recorded',
  my_stuff_recordings_expired = 'my_stuff_recordings_expired',
  my_stuff_recordings_conflict = 'my_stuff_recordings_conflict',
  my_stuff_recordings_series = 'my_stuff_recordings_series',
  my_stuff_favorites = 'my_stuff_favorites',
  my_stuff_reminders = 'my_stuff_reminders',
  my_stuff_client_area = 'my_stuff_client_area',
  my_stuff_client_area_subscriptions = 'my_stuff_client_area_subscriptions',
  my_stuff_client_area_active_subscriptions = 'my_stuff_client_area_active_subscriptions',
  my_stuff_client_area_inbox = 'my_stuff_client_area_inbox',
  my_stuff_media_center = 'my_stuff_media_center',
  my_stuff_search = 'my_stuff_search',
  my_stuff_channels = 'my_stuff_channels',
  my_stuff_settings = 'my_stuff_settings',
  app_app = 'app_app',
  tuner_info = 'tuner_info',
  article_detail = 'article_detail',
  bundle_detail = 'bundle_detail',
}

export class Property {
  public readonly name: string;
  public readonly value: string;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}

export class PlayerLogInfo {
  public readonly id: string;
  public readonly type: StreamType;
  public readonly name: string;
  public readonly scheduleId: string;
  public readonly programId: string;
  public readonly streamId: string;
  public readonly ssId: string;
  public readonly manifest: string;
  public readonly channelId: string;
  public readonly channelName: string;
  public readonly seasonNumber: number;
  public readonly episodeNumber: number;

  constructor(obj: PlayerLogInfo) {
    Object.keys(obj).map((key) => (this[key] = obj[key]));
  }
}

export class SwipeLogInfo {
  public id: string;
  public readonly type: CmsContentTypes;
  public readonly name: string;
  public readonly scheduleId: string;
  public readonly channelId: string;
  public readonly channelName: string;
  public readonly bookmark: number;

  constructor(playInfo: PlayInfoModel, type: CmsContentTypes) {
    this.id = playInfo.streamAssetId;
    this.type = type;
    this.name = playInfo.streamIsVod()
      ? playInfo.vodInformation
        ? playInfo.vodInformation.title
        : ''
      : playInfo.programInformation
      ? playInfo.programInformation.title
      : '';
    this.scheduleId = playInfo.scheduleInformation ? playInfo.scheduleInformation.id : '';
    this.channelId = playInfo.channelInformation ? playInfo.channelInformation.id : '';
    this.channelName = playInfo.channelInformation ? playInfo.channelInformation.name : '';
    this.bookmark = playInfo.bookmarkPos;
  }
}

export class RecordingLogInfo {
  public readonly name: string;
  public readonly programId: string;
  public readonly seasonId: string;
  public readonly seriesId: string;
  public readonly scheduleId: string;
  public readonly channelId: string;
  public readonly channelName: string;

  public readonly type: 'PVR' | 'NPVR';
  public readonly deviceId: string;
  public readonly beginBuffer: string;
  public readonly endBuffer: string;
  public readonly deleteProtection: string;
  public readonly episodes: string;
  public recordingId: string;
  public subscriptionId: string;

  constructor(
    subscriptionSettings: SubscriptionSettingsModel,
    recordingEpgInfo: RecordingEpgInfo,
    recording: RecordingModel,
    npvrStb: Stb,
    editType?: SubscriptionTypes
  ) {
    if (recordingEpgInfo) {
      this.programId = recordingEpgInfo.programId;
      this.seasonId = recordingEpgInfo.seasonId;
      this.seriesId = recordingEpgInfo.seriesId;
      this.name = recordingEpgInfo.name;
      this.channelId = recordingEpgInfo.channel.id;
      this.channelName = recordingEpgInfo.channel.name;
    }

    if (subscriptionSettings) {
      this.deviceId = subscriptionSettings.deviceId;
      this.beginBuffer = subscriptionSettings.beginBuffer;
      this.endBuffer = subscriptionSettings.endBuffer;
      this.deleteProtection = subscriptionSettings.deleteProtected ? 'ON' : 'OFF';
      this.episodes = subscriptionSettings.type;
    }

    if (recording) {
      this.recordingId = recording.identifier;
      this.programId = recording.program;
      this.seasonId = recording.season;
      this.seriesId = recording.series;
      this.scheduleId = recording.schedule;
      this.episodes = SubscriptionTypes.EPISODE;
      this.deviceId = recording.device;
      this.channelId = recording.channel;
      this.type = 'PVR';
    }

    this.type = npvrStb && this.deviceId === npvrStb.id ? 'NPVR' : 'PVR';

    if (
      (subscriptionSettings && subscriptionSettings.type === SubscriptionTypes.EPISODE) ||
      editType === SubscriptionTypes.EPISODE
    ) {
      this.episodes = SubscriptionTypes.EPISODE;
    } else if (
      (subscriptionSettings && subscriptionSettings.type === SubscriptionTypes.SEASON) ||
      editType === SubscriptionTypes.SEASON
    ) {
      this.episodes = SubscriptionTypes.SEASON;
      this.programId = undefined;
      this.scheduleId = undefined;
      if (recordingEpgInfo && recordingEpgInfo.series && recordingEpgInfo.season) {
        this.name = recordingEpgInfo.series.title + ': Season ' + recordingEpgInfo.season.season;
      }
    } else if (
      (subscriptionSettings && subscriptionSettings.type === SubscriptionTypes.SERIES) ||
      editType === SubscriptionTypes.SERIES
    ) {
      this.episodes = SubscriptionTypes.SERIES;
      this.programId = undefined;
      this.scheduleId = undefined;
      this.seasonId = undefined;
    }
  }
}

export class ArticleLogInfo {
  public readonly articleId: string;
  public readonly title?: string;
  public readonly curPage?: string;
  public readonly numPages?: string;

  public readonly label?: string;
  public readonly actionType?: CmsContentTypes;
  public readonly actionId?: string;
  public readonly actionMethod?: CmsActionMethod;

  constructor(obj: ArticleLogInfo) {
    Object.keys(obj).map((key) => (this[key] = obj[key]));
  }
}
