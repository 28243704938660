import { EpgScheduleItem } from '@atv-core/api/epg';
import { ReminderContentTypes, ReminderElement } from '@atv-core/api/history';

export class ReminderModel implements ReminderElement {
  type: ReminderContentTypes;
  id: string;
  minutes?: number;

  // schedule
  public schedule: EpgScheduleItem;

  constructor(obj: ReminderElement, schedule: EpgScheduleItem) {
    Object.keys(obj).forEach((key) => (this[key] = obj[key]));
    this.schedule = schedule;
  }
}
