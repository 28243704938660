import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { ConfigService, PopupTranslationKeys } from '@atv-bootstrap/services/config';
import { CatalogTranslationKeys } from '@atv-bootstrap/services/config/config.model';

class ButtonVisualModel {
  buttonText: string;
  showConfig = false;

  confirmationText: string;

  confirmText: string;
  cancelText: string;
}

@Component({
  selector: 'app-delete-profile',
  templateUrl: './delete-profile.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteProfileComponent implements OnDestroy {
  @Output()
  confirmDelete = new EventEmitter();

  public buttonVisual = new ButtonVisualModel();

  private clickedInside = false;

  @ViewChild('buttonConfigRef', { static: true })
  buttonConfigRef: ElementRef;
  @ViewChild('buttonWrapperRef', { static: true })
  buttonWrapperRef: ElementRef;

  constructor(private config: ConfigService, private cdr: ChangeDetectorRef) {
    this.buttonVisual.confirmText = this.config.getTranslation(PopupTranslationKeys.popup_btn_ok);
    this.buttonVisual.cancelText = this.config.getTranslation(
      PopupTranslationKeys.popup_btn_cancel
    );

    this.buttonVisual.buttonText = this.config.getTranslation(
      CatalogTranslationKeys.profile_delete_btn
    );
    this.buttonVisual.confirmationText = this.config.getTranslation(
      CatalogTranslationKeys.profile_delete_confirm
    );
  }

  private detectChanges() {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  public hideOptions() {
    // setTimeout is necessary to detect changes (hack call stack)

    this.buttonVisual.showConfig = false;
    this.detectChanges();
    setTimeout(() => {
      this.detectWidthChange();
    }, 0);
  }

  ngOnDestroy(): void {
    this.hideOptions();
  }
  detectWidthChange() {
    if (this.buttonWrapperRef) {
      if (!this.buttonConfigRef) {
        this.buttonWrapperRef.nativeElement.style.width = 'auto';
      } else {
        const buttonConfigEl = this.buttonConfigRef.nativeElement;
        const width = buttonConfigEl.getBoundingClientRect().width;
        if (width) {
          this.buttonWrapperRef.nativeElement.style.width = width + 'px';
        } else {
          this.buttonWrapperRef.nativeElement.style.width = 'auto';
        }
      }
    }
  }

  public showOptions() {
    this.buttonVisual.showConfig = true;
    this.detectChanges();
    setTimeout(() => {
      this.detectWidthChange();
    }, 0);
  }

  public cancel() {
    this.hideOptions();
  }

  public confirm() {
    this.confirmDelete.emit();
  }

  @HostListener('click', ['$event'])
  onClickedInside($event: Event) {
    this.clickedInside = true;
  }

  @HostListener('document:click', ['$event'])
  onClickedOutside($event) {
    if (!this.clickedInside && this.buttonVisual.showConfig) {
      this.hideOptions();
    }
    this.clickedInside = false;
  }
}
