export enum Browsers {
  Opera = 'Opera',
  Safari = 'Safari',
  TV_Safari = 'TV Safari',
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  IE = 'Internet Explorer',
  Edge = 'Edge'
}

export abstract class ClientDetails {
  private static details = {
    //            cookies: '',
    flashVersion: '',
    browser: '',
    browserVersion: '',
    browserMajorVersion: '-1',
    mobile: false,
    os: '',
    osVersion: ''
  };

  public static getDetails() {
    if (this.details.os !== '') {
      return this.details;
    }

    this.retrieveDetails();

    return this.details;
  }

  private static retrieveDetails() {
    const unknown = '-';

    // browser
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browser = navigator.appName;
    let version = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset;
    let verOffset;
    let ix;

    // Opera
    if ((verOffset = nAgt.indexOf(Browsers.Opera)) !== -1) {
      browser = Browsers.Opera;
      version = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        version = nAgt.substring(verOffset + 8);
      }
    }
    // Opera Next
    if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
      browser = Browsers.Opera;
      version = nAgt.substring(verOffset + 4);
    } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
      // MSIE
      browser = Browsers.IE;
      version = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf('Edge/')) !== -1) {
      // Edge
      browser = Browsers.Edge;
      version = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf(Browsers.Chrome)) !== -1) {
      // Chrome
      browser = Browsers.Chrome;
      version = nAgt.substring(verOffset + 7);
    } else if ((verOffset = nAgt.indexOf(Browsers.Safari)) !== -1) {
      // Safari
      browser = Browsers.Safari;
      version = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        version = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf(Browsers.TV_Safari)) !== -1) {
      // TV Safari
      browser = Browsers.TV_Safari;
      version = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        version = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf(Browsers.Firefox)) !== -1) {
      // Firefox
      browser = Browsers.Firefox;
      version = nAgt.substring(verOffset + 8);
    } else if (nAgt.indexOf('Trident/') !== -1) {
      // MSIE 11+
      browser = Browsers.IE;
      version = nAgt.substring(nAgt.indexOf('rv:') + 3);
    } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
      // Other browsers
      browser = nAgt.substring(nameOffset, verOffset);
      version = nAgt.substring(verOffset + 1);
      if (browser.toLowerCase() === browser.toUpperCase()) {
        browser = navigator.appName;
      }
    }
    // trim the version string
    if ((ix = version.indexOf(';')) !== -1) {
      version = version.substring(0, ix);
    }
    if ((ix = version.indexOf(' ')) !== -1) {
      version = version.substring(0, ix);
    }
    if ((ix = version.indexOf(')')) !== -1) {
      version = version.substring(0, ix);
    }

    majorVersion = parseInt('' + version, 10);
    if (isNaN(majorVersion)) {
      version = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    // mobile version
    const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer) || (navigator.platform === 'MacIntel'
                                                                              && navigator.maxTouchPoints > 1);

    // cookie
    /*     var cookieEnabled = (navigator.cookieEnabled) ? true : false;

        if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
            document.cookie = 'testcookie';
            cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
        }
*/

    // system
    const clientStrings = [
      {
        s: 'Windows 10',
        r: /(Windows 10.0|Windows NT 10.0)/
      },
      {
        s: 'Windows 8.1',
        r: /(Windows 8.1|Windows NT 6.3)/
      },
      {
        s: 'Windows 8',
        r: /(Windows 8|Windows NT 6.2)/
      },
      {
        s: 'Windows 7',
        r: /(Windows 7|Windows NT 6.1)/
      },
      {
        s: 'Windows Vista',
        r: /Windows NT 6.0/
      },
      {
        s: 'Windows Server 2003',
        r: /Windows NT 5.2/
      },
      {
        s: 'Windows XP',
        r: /(Windows NT 5.1|Windows XP)/
      },
      {
        s: 'Windows 2000',
        r: /(Windows NT 5.0|Windows 2000)/
      },
      {
        s: 'Windows ME',
        r: /(Win 9x 4.90|Windows ME)/
      },
      {
        s: 'Windows 98',
        r: /(Windows 98|Win98)/
      },
      {
        s: 'Windows 95',
        r: /(Windows 95|Win95|Windows_95)/
      },
      {
        s: 'Windows NT 4.0',
        r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
      },
      {
        s: 'Windows CE',
        r: /Windows CE/
      },
      {
        s: 'Windows 3.11',
        r: /Win16/
      },
      {
        s: 'Android',
        r: /Android/
      },
      {
        s: 'Open BSD',
        r: /OpenBSD/
      },
      {
        s: 'Sun OS',
        r: /SunOS/
      },
      {
        s: 'SMART-TV',
        r: /(SMART-TV|LINUX|Tizen)/
      },
      {
        s: 'Linux',
        r: /(Linux|X11)/
      },
      {
        s: 'iOS',
        r: /(iPhone|iPad|iPod)/
      },
      {
        s: 'Mac OS X',
        r: /Mac OS X/
      },
      {
        s: 'Mac OS',
        r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
      },
      {
        s: 'QNX',
        r: /QNX/
      },
      {
        s: 'UNIX',
        r: /UNIX/
      },
      {
        s: 'BeOS',
        r: /BeOS/
      },
      {
        s: 'OS/2',
        r: /OS\/2/
      },
      {
        s: 'Search Bot',
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
      }
    ];

    const str = clientStrings.find(string => string.r.test(nAgt));
    let os = str ? str.s : undefined;

    let osVersion = unknown;

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
    }

    if (/MAC OS/.test(os)) {
      os = 'MAC OS';
    }

    switch (os) {
      case 'MAC OS':
        osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'Android':
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        const tmp = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = tmp[1] + '.' + tmp[2] + '.' + (tmp[3] || 0);
        break;
    }

    // flash (you'll need to include swfobject)
    /* script src="//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js" */
    /*var flashVersion = 'no check';
        if (typeof swfobject != 'undefined') {
            var fv = swfobject.getFlashPlayerVersion();
            if (fv.major > 0) {
                flashVersion = fv.major + '.' + fv.minor + ' r' + fv.release;
            } else {
                flashVersion = unknown;
            }
        }*/

    //         details.cookies = cookieEnabled;
    this.details.browser = browser;
    this.details.browserVersion = version;
    this.details.browserMajorVersion = '' + majorVersion;
    // this.details.flash = flashVersion;
    this.details.os = os;
    this.details.osVersion = osVersion;
    this.details.mobile = mobile;


    // 'Flash: ' + details.flashVersion + '\n');
    // +'Cookies: ' + details.cookies + '\n');
  }
}
