import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { PlayerService } from '../../../atv-core/services/player/player.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SpatialNavigationService } from './../../../atv-core/services/spatial-navigation/spatial-navigation.service';

@Directive({
  selector: '[smartTvKeyDownEnter]',
})
export class SmartTvKeyDownEnterDirective implements OnInit, OnDestroy {
  @Output() smartTvKeyDownEnter: EventEmitter<KeyboardEvent> = new EventEmitter();

  private isSmartTv = SharedUtilityService.isSmartTv();

  constructor(
    private elRef: ElementRef,
    private navigationService: SpatialNavigationService,
    private playerService: PlayerService
  ) {}

  ngOnInit(): void {
    if (this.isSmartTv) {
      fromEvent(this.elRef.nativeElement, 'keydown')
        .pipe(filter(() => !this.playerService.playerVisibilitySubject.value ||
                           this.playerService.controlsVisibility.controlsVisibleSubject.value))
        .subscribe((e: KeyboardEvent) => {
          // if key is held down (prevent spamming)
          if (!e.repeat && this.navigationService.keyCodeIsEnter(e.keyCode)) {
            this.smartTvKeyDownEnter.emit(e);
          }
        });
    }
  }

  ngOnDestroy(): void {}
}
