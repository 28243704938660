import { Component } from '@angular/core';
import moment from 'moment';

import { DaySelectorService } from './day-selector.service';

@Component({
  selector: 'app-day-selector',
  templateUrl: './day-selector.component.html',
  styleUrls: ['./day-selector.component.scss'],
})
export class DaySelectorComponent {
  constructor(private daySelectorService: DaySelectorService) {}

  public selectDate(value: moment.Moment): void {
    this.daySelectorService.setSelectedDate(value);
  }

  public getDays(): moment.Moment[] {
    return this.daySelectorService.getDays();
  }
}
