<div class="settings-page-wrapper left-right-page-padding" [class.hidden]="!isAuthorized">
  <div *ngFor="let pu of pincodeUpdates;let i = index" [id]="'pin' + i" class="setting">
    <div class="setting-title">{{ pu.title }}</div>

    <table>
      <tbody>
      <tr>
        <td class="setting-input-title">{{ oldPincodeText }}</td>
        <td>
          <input
            (keydown.enter)="changePincode(pu)"
            [(ngModel)]="pu.old"
            [appCenterOnFocus]="null"
            autocomplete="off"
            id="pwdold"
            name="pwdold"
            type="password"
          />
        </td>
      </tr>
      <tr>
        <td class="setting-input-title">{{ newPincodeText }}</td>
        <td>
          <input
            (keydown.enter)="changePincode(pu)"
            [(ngModel)]="pu.new"
            [appCenterOnFocus]="null"
            autocomplete="off"
            id="pwdnew"
            name="pwdnew"
            type="password"
          />
        </td>
      </tr>
      <tr>
        <td class="setting-input-title">{{ confirmPincodeText }}</td>
        <td>
          <input
            (keydown.enter)="changePincode(pu)"
            [(ngModel)]="pu.new_confirm"
            [appCenterOnFocus]="null"
            autocomplete="off"
            id="pwdconf"
            name="pwdconf"
            type="password"
          />
        </td>
      </tr>
      </tbody>
    </table>

    <button (click)="changePincode(pu)" [appCenterOnFocus]="null"
            [attr.data-sn-down]="i === pincodeUpdates.length - 1 ? '#client-area-adult-options .chip.selected' : undefined"
            class="setting-confirm button-title">
      {{ okText }}
    </button>
  </div>

  <div class="setting" id="adult">
    <div class="setting-title">{{ adultModeSettingTitleText }}</div>

    <table>
      <tbody>
      <tr>
        <td class="setting-input-title">{{ adultModeSettingText }}</td>
        <td *ngIf="!isSmartTv">
          <select [(ngModel)]="selectedNumberOfMinutesAdultMode">
            <option *ngFor="let number of adultOptions" [ngValue]="number.value">
              {{ number.text }}
            </option>
          </select>
        </td>
      </tr>
      <tr *ngIf="isSmartTv">
        <td>
          <div class="chip-container" id="client-area-adult-options">
            <div
              (focus)="selectedNumberOfMinutesAdultMode = number.value"
              (keyup.enter)="moveDown()"
              *ngFor="let number of adultOptions"
              [appCenterOnFocus]="null"
              [attr.data-sn-up]="'#pin' + (pincodeUpdates.length - 1) + ' .setting-confirm'"
              [class.selected]="selectedNumberOfMinutesAdultMode === number.value"
              class="chip"
              data-sn-down="#adult .setting-confirm"
            >
              {{ number.text }}
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <button (click)="changeAdultSetting()" class="setting-confirm button-title"
            data-sn-up="#client-area-adult-options .chip.selected">
      {{ saveText }}
    </button>

    <div *ngIf="!isSmartTv" class="setting-toggle-adult">
      <div class="setting-toggle-adult-title">{{ adultModeToggleTitle }}</div>
      <label class="switch">
        <input type="checkbox" [checked]="adultModeEnabled" (change)="toggleAdultMode($event)" />
        <span class="slider"></span>
      </label>

      <div>{{ adultModeEnabled ? adultModeActivated : adultModeDeactivated }}</div>
    </div>
  </div>

  <div class="setting" *ngIf="ottAdultSettingEnabled && !isSmartTv">
    <div class="setting-title">{{ ottAdultAllowedTitle }}</div>

    <div class="setting-toggle-adult">
      <label class="switch">
        <input
          type="checkbox"
          [checked]="ottAdultAllowed"
          (change)="toggleAllowAdultModeOnOtt($event)"
        />
        <span class="slider"></span>
      </label>

      <div>{{ ottAdultAllowed ? ottAdultAllowedOn : ottAdultAllowedOff }}</div>
    </div>
  </div>

  <div class="setting">
    <div class="setting-title">{{ versionTitle }}</div>

    <table>
      <tbody>
      <tr>
        <td class="setting-input-title version-info">{{ versionInfo }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
