import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { EpgCreditRoles, EpgProgramDetailAsset, EpgScheduleSummaryAsset, EpgSeasonSummaryAsset } from '@atv-core/api/epg';
import { EpgApiService } from '@atv-core/api/epg/epg-api.service';
import { AdultService } from '@atv-core/services/adult';
import { ChannelModel } from '@atv-core/services/cache/channel/channel.model';
import { DetailTranslationKeys, PlayerTranslationKeys } from '@atv-bootstrap/services/config/config.model';
import { ConfigService } from '@atv-bootstrap/services/config/config.service';
import { MessagesService } from '@atv-core/services/messages/messages.service';
import { CreditInfoItem, EpgUtilityService } from '@atv-core/utility/epg-utility/epg-utility.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { ImageRecipe, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';

class EpgDetailVisualModel {
  title: string;
  seasonEpisodeInfo: string;
  episodeTile: string;

  channelInfo: string;
  channelImage: string;

  scheduleInfo: string;
  progressPercentage: number;
  synopsisInfo: string;
  minAgeImage: string;
  programShortInfo: string;
  actorsInfo = new CreditInfoItem();
  directorsInfo = new CreditInfoItem();

  poster: string;
  backdrop: string;
  adult: boolean;

  canShowMoreDetail = false;
}

export class EpgInfoInputModel {
  program: EpgProgramDetailAsset;
  schedule: EpgScheduleSummaryAsset;
  season: EpgSeasonSummaryAsset;
  channel: ChannelModel;
  channelSchedules: EpgScheduleSummaryAsset[];

  detailOpen = false;
}

@Component({
  selector: 'app-epg-info',
  templateUrl: './epg-info.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EpgInfoComponent implements OnInit, OnDestroy {
  epgInfoInput: EpgInfoInputModel;
  public openDetailText: string;
  public epgDetailVisual = new EpgDetailVisualModel();
  private currentScheduleIndex = 0;

  constructor(
    private config: ConfigService,
    private epgUtility: EpgUtilityService,
    private epgApi: EpgApiService,
    private adultService: AdultService,
    private messagesService: MessagesService,
    private cdr: ChangeDetectorRef,
  ) {
    this.openDetailText = this.config.getTranslation(PlayerTranslationKeys.player_btn_more_details);
  }

  @Input()
  set epgInfoInputModel(value) {
    if (!value) {
      return;
    }
    this.epgInfoInput = value;
    if (this.epgInfoInput.channelSchedules && this.epgInfoInput.schedule) {
      this.currentScheduleIndex = this.epgInfoInput.channelSchedules.findIndex((schedule) =>
        this.epgUtility.scheduleIsLive(schedule),
      );
      if (this.currentScheduleIndex) {
        this.currentScheduleIndex = this.epgInfoInput.channelSchedules.findIndex(
          (schedule) => schedule.id === this.epgInfoInput.schedule.id,
        );
      }
    } else {
      this.currentScheduleIndex = undefined;
    }
    this.initMoreDetail();
  }

  ngOnInit(): void {
  }

  public getTitleText(): string {
    return this.epgInfoInput?.program ? this.epgInfoInput.program.title : '';
  }

  public getTimeText(): string {
    if (this.epgInfoInput?.schedule?.published) {
      return (
        (this.epgInfoInput.channel && this.epgInfoInput.channel.name
          ? this.epgInfoInput.channel.name + ' • '
          : '') +
        SharedUtilityService.timeStringToMoment(this.epgInfoInput.schedule.published.start).format(
          'HH:mm',
        ) +
        ' - ' +
        SharedUtilityService.timeStringToMoment(this.epgInfoInput.schedule.published.end).format(
          'HH:mm',
        )
      );
    }
  }

  public initMoreDetail(): void {
    this.epgDetailVisual = new EpgDetailVisualModel();

    if (!this.epgInfoInput || !(this.epgInfoInput.program && this.epgInfoInput.schedule)) {
      return;
    }

    this.epgDetailVisual.canShowMoreDetail = true;
    this.epgDetailVisual.title = this.getTitleText();

    this.epgDetailVisual.adult = this.epgInfoInput.program
      ? this.epgInfoInput.program.adult
      : undefined;

    this.epgDetailVisual.episodeTile =
      this.epgInfoInput && this.epgInfoInput.program && this.epgInfoInput.program.episodeTitle
        ? this.epgInfoInput.program.episodeTitle
        : '';

    this.epgDetailVisual.seasonEpisodeInfo = this.epgUtility.getSeasonEpisodeInfo(
      this.epgInfoInput.program?.episode,
      this.epgInfoInput.season?.season,
    );

    if (this.epgInfoInput.channel) {
      this.epgDetailVisual.channelInfo = this.epgInfoInput.channel.name || '';

      this.epgDetailVisual.channelImage = this.epgInfoInput.channel?.defaultLogo ?
        ImageRecipeUtil.createImageUrl(this.epgInfoInput.channel.defaultLogo, ImageRecipe.CHANNEL_1) : '';
    }

    this.epgDetailVisual.scheduleInfo = this.epgUtility.getScheduleInfo(
      this.epgInfoInput.schedule.published,
    );

    this.epgDetailVisual.progressPercentage = this.epgUtility.calculateProgressPercentage(
      this.epgInfoInput.schedule.published,
    );

    this.epgDetailVisual.backdrop = this.epgUtility.getDetailBackdropForProgram(
      this.epgInfoInput.program,
    );

    if (this.epgInfoInput.program) {
      this.epgDetailVisual.poster = this.epgInfoInput.program.posterImage
        ? ImageRecipeUtil.createImageUrl(this.epgInfoInput.program.posterImage, ImageRecipe.EPG_CARD_3) : undefined;
    }

    this.epgDetailVisual.synopsisInfo = this.epgUtility.getSynopsisInfo(this.epgInfoInput.program);

    if (this.epgInfoInput.program && this.epgInfoInput.program.minimumAge) {
      this.epgDetailVisual.minAgeImage = SharedUtilityService.getMinimumAgeImage(
        this.epgInfoInput.program.minimumAge,
      );
    }

    this.epgDetailVisual.programShortInfo = this.epgUtility.getProgramShortInfo(
      this.epgInfoInput.schedule,
      this.epgInfoInput.program,
    );

    this.epgDetailVisual.actorsInfo = this.epgUtility.getCreditInfo(
      this.epgInfoInput.program,
      DetailTranslationKeys.detail_actors,
      EpgCreditRoles.ACTOR,
    );
    this.epgDetailVisual.directorsInfo = this.epgUtility.getCreditInfo(
      this.epgInfoInput.program,
      DetailTranslationKeys.detail_directors,
      EpgCreditRoles.DIRECTOR,
    );

    this.detectChanges();
  }

  public noCredits(): boolean {
    return !(
      (this.epgDetailVisual.actorsInfo.content && this.epgDetailVisual.actorsInfo.creditTitle) ||
      (this.epgDetailVisual.directorsInfo.content && this.epgDetailVisual.directorsInfo.creditTitle)
    );
  }

  public showNextPrevProgramInfo(next: boolean): void {
    if (this.currentScheduleIndex === undefined) {
      return;
    }
    const tmpIndex = this.currentScheduleIndex + (next ? 1 : -1);
    if (
      !this.epgInfoInput ||
      !this.epgInfoInput.channelSchedules ||
      this.epgInfoInput.channelSchedules.length === 0 ||
      tmpIndex < 0 ||
      tmpIndex >= this.epgInfoInput.channelSchedules.length
    ) {
      return;
    }

    this.currentScheduleIndex = tmpIndex;
    this.showItemAtIndex();
  }

  public canPrevProgram(): boolean {
    return this.currentScheduleIndex !== undefined &&
           this.epgInfoInput &&
           this.epgInfoInput &&
           this.epgInfoInput.channelSchedules &&
           this.epgInfoInput.channelSchedules.length !== 0 &&
           this.currentScheduleIndex - 1 >= 0;
  }

  public canNextProgram(): boolean {
    return this.currentScheduleIndex !== undefined &&
           this.epgInfoInput &&
           this.epgInfoInput &&
           this.epgInfoInput.channelSchedules &&
           this.epgInfoInput.channelSchedules.length !== 0 &&
           this.currentScheduleIndex + 1 < this.epgInfoInput.channelSchedules.length;
  }

  public openDetail(): void {
    if (this.epgInfoInput.program && this.epgInfoInput.program.adult) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          if (this.epgInfoInput) {
            this.epgInfoInput.detailOpen = true;
            this.detectChanges();
          }
        },
        errorCallback: () => {
          this.messagesService.showErrorMessage(
            this.config.getTranslation(DetailTranslationKeys.detail_adult_warning),
          );
        },
      });
    } else {
      if (this.epgInfoInput) {
        this.epgInfoInput.detailOpen = true;
        this.detectChanges();
      }
    }
  }

  public hideDetail(): void {
    if (this.epgInfoInput) {
      this.epgInfoInput.detailOpen = false;
      this.detectChanges();
    }
  }

  public toggleDetail(): void {
    if (this.epgDetailVisual.canShowMoreDetail) {
      if (this.epgInfoInput.detailOpen) {
        this.hideDetail();
      } else {
        this.openDetail();
      }
    }
  }

  private detectChanges(): void {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  private showItemAtIndex(): void {
    if (this.currentScheduleIndex === undefined) {
      return;
    }
    const fromUntil = this.epgUtility.getTodayFromUntil();
    this.epgApi
      .getProgram(
        this.epgInfoInput.channelSchedules[this.currentScheduleIndex].program,
        fromUntil.from,
        fromUntil.until,
      )
      .subscribe((programAsset) => {
        this.epgInfoInput.schedule = this.epgInfoInput.channelSchedules[this.currentScheduleIndex];
        this.epgInfoInput.program = programAsset.program;
        this.epgInfoInput.season = programAsset.season;
        this.initMoreDetail();
      });
  }

  ngOnDestroy(): void {
  }
}
