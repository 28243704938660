<a [href]="refUrl" (click)="scheduleClicked($event)"
  ><div
    class="schedule"
    [style.width.px]="scheduleWidth"
    [style.left.px]="schedulePosition"
    [class.finishing-today]="finishingToday"
    [class.filter-match]="matchesActiveFilter"
    [class.smarttv]="isSmartTv"
    [class.entry]="isEntry"
    #schedule
    (smartTvKeyDownEnter)="scheduleClicked($event)"
    (focus)="onFocus()"
    (focusout)="focusOut()"
    [attr.channel]="channel.id"
    [appCenterOnFocus]="null"
  >
    <div
      class="schedule-title"
      [style.maxWidth.px]="scheduleWidth - 20 < 0 ? 0 : scheduleWidth - 20"
      [class.hidden]="scheduleWidth < 10"
      #title
    >
      {{ getProgramTitle() }}
    </div>

    <div class="schedule-info" [class.hidden]="scheduleWidth < 10">
      <div style="flex-grow: 1">
        <div
          class="schedule-time"
          #time
          [style.maxWidth.px]="scheduleWidth - 20 < 0 ? 0 : scheduleWidth - 20"
        >
          {{ startTime | date: 'HH:mm' }}
        </div>
      </div>

      <div
        *ngIf="recordingIcon || playIcon || showReminder"
        #icons
        class="schedule-icons"
        [style.maxWidth.px]="scheduleWidth - 20 < 0 ? 0 : scheduleWidth - 20"
      >
        <img #iconImg *ngIf="recordingIcon" [src]="recordingIcon" />
        <img #iconImg *ngIf="playIcon" [src]="playIcon" />
        <img #iconImg *ngIf="showReminder" src="./assets/theme/svg/reminders_selected.svg" />
      </div>
    </div>
  </div>
</a>
