import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CmsApiService,
  CmsGrid,
  CmsRibbon,
  CmsRow,
  CustomCmsGrid,
  CustomCmsRibbon,
  CustomCmsRow,
  CustomCmsRowTypes,
  Properties,
} from '@atv-core/api/cms';
import { VodApiService } from '@atv-core/api/vod';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { ChannelCacheService } from '@atv-core/services/cache/channel/channel-cache.service';
import { ConfigService, DetailTranslationKeys } from '@atv-bootstrap/services/config';
import { DetailOverlayService } from '@atv-core/services/detail/detail-overlay.service';
import { PageContext } from '@atv-core/services/log/log.model';
import { LogService } from '@atv-core/services/log/log.service';
import { PageData } from '@atv-core/services/menu-manager/menu-manager.service';
import { MessagesService } from '@atv-core/services/messages';
import { SessionService } from '@atv-core/services/session';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { JumpablePage } from '@atv-pages/jumpable-page';
import { CategoryFilterModes, ContentFilters, FilterModel } from '@atv-shared/filters/filters.model';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CmsPageFiltersComponent } from './cms-page-filters/cms-page-filters.component';
import { Location } from '@angular/common';
import { MiniDetailService } from '@atv-core/services/mini-detail/mini-detail.service';
import { ContentBackdropService } from '@atv-shared/content-backdrop/content-backdrop.service';
import { ContentProviderFactoryService } from '@atv-shared/content-provider/content-provider-factory.service';
import { PagePropertiesUtility } from '@atv-core/utility/page-properties-utility';
import { VodContentProvider } from '@atv-shared/content-provider/providers/vod-content-provider';

@Component({
  selector: 'app-cms-page',
  templateUrl: './cms-page.component.html',
  styles: [],
})
export class CmsPageComponent extends JumpablePage implements OnInit, OnDestroy {
  public cmsRows: CmsRow[];
  public filterResultGrid: CmsGrid;
  public fixedTopBanner: CmsRow;
  public allowedAdultMode: AdultMode = AdultMode.false;
  public CustomCmsRowTypes = CustomCmsRowTypes;
  public cmsFilterModel: FilterModel;
  public pageContext: PageContext;
  public pageProperties: Properties[];
  @ViewChild('cmsPageFilters')
  cmsPageFilters: CmsPageFiltersComponent;
  private cmsPageRows: CustomCmsRow[];
  private closeDetailSubscription: Subscription;
  private returnSubscription: Subscription;
  private hasBeenFocused = false;
  private readonly menuFocusSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cmsApi: CmsApiService,
    sessionService: SessionService,
    private adult: AdultService,
    private channelCache: ChannelCacheService,
    adultService: AdultService,
    private vodApi: VodApiService,
    private cdr: ChangeDetectorRef,
    authorizationService: AuthorizationService,
    config: ConfigService,
    messagesService: MessagesService,
    private spatialNavigationService: SpatialNavigationService,
    private detailService: DetailOverlayService,
    private log: LogService,
    private location: Location,
    private miniDetailService: MiniDetailService,
    private backdropService: ContentBackdropService,
    private contentProviderFactory: ContentProviderFactoryService,
  ) {
    super(sessionService, authorizationService, adultService, messagesService, config);
    this.pageId = (this.activatedRoute.snapshot.data as PageData).pageId;
    this.menuFocusSubscription = this.spatialNavigationService.isMenuFocusedEvent.subscribe(
      (isMenuFocused: boolean) => {
        if (!isMenuFocused) {
          this.hasBeenFocused = true;
          this.menuFocusSubscription.unsubscribe();
        }
      },
    );
  }

  ngOnInit(): void {
    super.start();

    if (SharedUtilityService.isSmartTv()) {
      this.closeDetailSubscription = this.detailService.closeDetailEvent.subscribe(() => {
        this.spatialNavigationService.setFocus(
          this.spatialNavigationService.getLastPageContentSection(),
        );
      });
    }
  }

  public loadPage(): void {
    if (!this.page) {
      console.error('page not loaded');
      return;
    }

    this.pageContext = new PageContext({
      pageURL: this.pageId,
      pageTitle: this.page.title,
      pageLocale: this.config.getLocale(),
    });
    this.log.pageView(this.pageContext);

    this.pageProperties = this.page.cmsPage.properties;

    if (this.pageProperties.includes(Properties.ADULT_REQUIRED)) {
      this.allowedAdultMode = AdultMode.true;
    }

    this.cmsPageRows = [];
    this.filterResultGrid = undefined;
    this.fixedTopBanner = undefined;

    if (this.page.cmsPage?.rows?.length > 0) {
      const cmsPage = this.page.cmsPage;

      // NEBINT-5337
      if (this.pageProperties.includes(Properties.ALLOW_ADULT) && this.allowedAdultMode !== AdultMode.true) {
        this.allowedAdultMode = AdultMode.any;
      }

      this.cmsFilterModel = new FilterModel();
      if (this.pageProperties) {
        this.cmsFilterModel.activeCmsFilters = this.getActiveFilters(this.pageProperties);

        this.cmsFilterModel.categoryFilterRoot = cmsPage.categoryTags;
        this.cmsFilterModel.categoryFilterMode = this.getCategoryFilterMode(this.pageProperties)
          ? CategoryFilterModes.VOD
          : CategoryFilterModes.EPG;
      }

      const rows = cmsPage.rows;
      if (rows[0].type === CustomCmsRowTypes.BANNER) {
        this.fixedTopBanner = { type: CustomCmsRowTypes.BANNER, banners: rows.splice(0, 1)[0].banners };
      }
      this.cmsPageRows = rows;

      if (this.cmsFilterModel?.activeCmsFilters?.length === 0) {
        this.cmsRows = this.convertRows(this.cmsPageRows);
      } else if (this.cmsPageFilters) {
        this.cmsPageFilters.clearAllFilters();
      }
    }

    if (this.page.backgroundImage) {
      this.backdropService.addCustomBackdropImage(this.page.backgroundImage);
    }

    this.page = undefined;

    if (SharedUtilityService.isSmartTv()) {
      this.registerElements();
      if (this.isOnCustomPage) {
        this.hasBeenFocused = true;
        setTimeout(() => {
          const visibleRows = this.getVisibleRows();
          const firstSection = this.fixedTopBanner ? `@${NavigationSections.CMS_TOP_BANNER}` :
            this.hasFilters() ? `@${NavigationSections.FILTERS}` :
              visibleRows.length > 0 ? `@${NavigationSections.PAGE_CONTENT}#${visibleRows[0]}` : undefined;
          this.spatialNavigationService.setFocus(firstSection);
        }, 500);
      }
    }
  }

  getPageProperties(): Observable<Properties[]> {
    if (this.page) {
      return of(this.page.cmsPage.properties);
    }

    return this.cmsApi
      .getDynamicPage(
        this.pageId,
        this.sessionService.getEntitlementId(),
        this.adult.getAdultMode(
          this.allowedAdultMode === AdultMode.true ? this.allowedAdultMode : AdultMode.any,
        ),
      )
      .pipe(
        tap((page) => {
          this.page = page;
        }),
        map((page) => page.cmsPage.properties),
      );
  }

  cmsFilterChange(): void {
    this.manageFilters();
  }

  manageFilters(): void {
    if (
      this.cmsFilterModel.categoryFilterOutputTags === undefined &&
      this.cmsFilterModel.scoreFilterOutputValue === undefined &&
      this.cmsFilterModel.yearFilterOutputValue === undefined
    ) {
      const callback = () => {
        this.cmsRows = this.convertRows(this.cmsPageRows);
        this.filterResultGrid = undefined;
      };

      if (this.allowedAdultMode === AdultMode.true) {
        this.adultService.checkAdultMode({
          successCallback: () => {
            callback();
          }, errorCallback: () => {
            this.messagesService.showErrorMessage(
              this.config.getTranslation(DetailTranslationKeys.detail_adult_warning),
            );
          },
        });
      } else {
        callback();
      }
    } else {
      const allowedAdultMode = this.adultService.getAdultMode(
        this.cmsFilterModel.currentCategoryFilterIsAdult ? AdultMode.true : this.allowedAdultMode,
      );
      let tags = this.cmsFilterModel.categoryFilterRoot;
      if (this.cmsFilterModel.categoryFilterOutputTags !== undefined) {
        tags = tags.concat(this.cmsFilterModel.categoryFilterOutputTags);
      }
      tags = Array.from(new Set(tags));
      this.cmsRows = [];
      this.filterResultGrid = new CmsGrid({
        title: '',
        contentProvider: new VodContentProvider(
          this.vodApi,
          this.sessionService.getCatalogId(),
          allowedAdultMode,
          tags,
          this.cmsFilterModel.yearFilterOutputValue,
          (this.cmsFilterModel.scoreFilterOutputValue - 1) * 25 + 1,
          this.cmsFilterModel.scoreFilterOutputValue * 25
        )
      });
    }
  }

  public hasFilters(): boolean {
    return this.cmsFilterModel?.activeCmsFilters?.length > 0;
  }

  public rowVisibilityChanged(): void {
    if (SharedUtilityService.isSmartTv()) {
      window.setTimeout(() => {
        this.registerElements();
      }, 0);
    }
  }

  private convertRows(rows: CustomCmsRow[]): CmsRow[] {
    return rows.map(r => {
      switch (r.type) {
        case CustomCmsRowTypes.BANNER:
          return { type: CustomCmsRowTypes.BANNER, banners: r.banners };
        case CustomCmsRowTypes.RIBBON:
          return { type: CustomCmsRowTypes.RIBBON, ribbon: this.convertRibbon(r.ribbon) };
        case CustomCmsRowTypes.GRID:
          return { type: CustomCmsRowTypes.GRID, grid: this.convertGrid(r.grid) };
      }
    });
  }

  private convertRibbon(ribbon: CustomCmsRibbon): CmsRibbon {
    return {
      title: ribbon.title,
      properties: ribbon.properties,
      cardSize: ribbon.cardSize,
      isOnDetailPage: false,
      contentMessage: ribbon.message,
      contentProvider: this.contentProviderFactory.createContentProvider(ribbon.url, this.allowedAdultMode,
        PagePropertiesUtility.getFocusType(ribbon.properties, this.pageProperties)),
    };
  }

  private convertGrid(grid: CustomCmsGrid): CmsGrid {
    return {
      title: grid.title,
      properties: grid.properties,
      contentUrl: grid.url,
      contentMessage: grid.message,
      contentProvider: this.contentProviderFactory.createContentProvider(grid.url, this.allowedAdultMode,
        PagePropertiesUtility.getFocusType(grid.properties, this.pageProperties)),
    };
  }

  private getCategoryFilterMode(properties: Properties[]): boolean {
    return properties.some(
      (property) =>
        property === Properties.VOD_FILTER_CATEGORY ||
        property === Properties.VOD_FILTER_PRODUCTIONYEAR ||
        property === Properties.VOD_FILTER_RATING,
    );
  }

  private getActiveFilters(properties: Properties[]): ContentFilters[] {
    const tmp: ContentFilters[] = [];
    properties.forEach((property) => {
      if (property === Properties.VOD_FILTER_CATEGORY) {
        tmp.push(ContentFilters.CATEGORY);
      } else if (property === Properties.VOD_FILTER_RATING) {
        tmp.push(ContentFilters.SCORE);
      } else if (property === Properties.VOD_FILTER_PRODUCTIONYEAR) {
        tmp.push(ContentFilters.YEAR);
      }
    });

    return tmp;
  }

  private registerElements(): void {
    this.unregisterElements();
    const visibleRows = this.getVisibleRows();

    this.spatialNavigationService.register(
      NavigationSections.CMS_TOP_BANNER,
      'app-banner.top-banner .banner',
      {
        straightOnly: true,
        restrict: 'self-only',
        leaveFor: {
          down: this.hasFilters()
            ? `@${NavigationSections.FILTERS}`
            : visibleRows.length > 0
              ? `@${NavigationSections.PAGE_CONTENT}#${visibleRows[0]}`
              : '',
        },
      },
    );

    this.spatialNavigationService.register(
      NavigationSections.FILTERS,
      `app-category-filter .filter-current-state-wrapper,
        app-clear-filter .filter-current-state-wrapper,
        app-score-filter .filter-current-state-wrapper,
        app-year-filter .filter-current-state-wrapper`,
      {
        restrict: 'self-only',
        leaveFor: {
          left: `@${NavigationSections.MENU}`,
          up: this.fixedTopBanner ? `@${NavigationSections.CMS_TOP_BANNER}` : '',
          down:
            visibleRows.length > 0 ? `@${NavigationSections.PAGE_CONTENT}#${visibleRows[0]}` : '',
        },
        navigableFilter: () => this.hasBeenFocused,
      },
    );

    visibleRows.forEach((i, index) => {
      this.spatialNavigationService.register(
        `${NavigationSections.PAGE_CONTENT}#${i}`,
        `.cms-page-wrapper .row-${i} app-banner .banner,
          .cms-page-wrapper .row-${i} app-ribbon .ribbon-item a,
          .cms-page-wrapper .row-${i} app-grid .grid-item a,
          .cms-page-wrapper .row-${i} .epg-card.channel-card-special`,
        {
          straightOnly: true,
          restrict: 'self-only',
          enterTo: 'last-focused',
          leaveFor: {
            left: `@${NavigationSections.MENU}`,
            up:
              index > 0
                ? `@${NavigationSections.PAGE_CONTENT}#${visibleRows[index - 1]}`
                : this.hasFilters()
                  ? `@${NavigationSections.FILTERS}`
                  : this.fixedTopBanner
                    ? `@${NavigationSections.CMS_TOP_BANNER}`
                    : '',
            down:
              index < visibleRows.length
                ? `@${NavigationSections.PAGE_CONTENT}#${visibleRows[index + 1]}`
                : '',
          },
          navigableFilter: () => this.hasBeenFocused || (!this.fixedTopBanner && index === 0),
        },
      );
    });

    this.returnSubscription = fromEvent(document, 'keyup')
      .pipe(
        filter(
          (event: KeyboardEvent) =>
            this.spatialNavigationService.keyCodeIsReturn(event.keyCode) &&
            !this.detailService.isOverlayOpen(),
        ),
      )
      .subscribe(() => {
        if (this.isOnCustomPage) {
          this.location.back();
          this.miniDetailService.clearDetail(); // TODO remove this, focus on previous element when returning back to "main" view
        } else {
          this.spatialNavigationService.setFocus(NavigationSections.MENU);
        }
      });
  }

  private unregisterElements(): void {
    this.spatialNavigationService.unregister(NavigationSections.CMS_TOP_BANNER);
    this.spatialNavigationService.unregister(NavigationSections.FILTERS);

    if (this.cmsRows) {
      for (let i = 0; i < this.cmsRows.length; ++i) {
        this.spatialNavigationService.unregister(`${NavigationSections.PAGE_CONTENT}#${i}`);
      }
    }

    if (this.filterResultGrid) {
      this.spatialNavigationService.unregister(
        `${NavigationSections.PAGE_CONTENT}#${this.cmsRows.length}`,
      );
    }

    this.returnSubscription?.unsubscribe();
  }

  private getVisibleRows(): number[] {
    const rows = [];

    for (let i = 0; i < this.cmsRows?.length; ++i) {
      if (
        !document.querySelector(
          `.cms-page-row.row-${i} .banner.hidden,
          .cms-page-row.row-${i} .ribbon-wrapper.hidden,
          .cms-page-row.row-${i} .grid-wrapper.hidden,
          .cms-page-row.row-${i} .ribbon.hidden`,
        )
      ) {
        rows.push(i);
      }
    }

    if (this.filterResultGrid) {
      rows.push(this.cmsRows.length);
    }

    return rows;
  }

  ngOnDestroy(): void {
    super.stop();

    if (SharedUtilityService.isSmartTv()) {
      this.unregisterElements();
    }

    if (this.closeDetailSubscription) {
      this.closeDetailSubscription.unsubscribe();
    }

    if (this.menuFocusSubscription) {
      this.menuFocusSubscription.unsubscribe();
    }
  }
}
