import { EventEmitter, Injectable } from '@angular/core';
import { MiniDetailModel } from '@atv-shared/mini-detail/mini-detail.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MiniDetailService {
  public miniDetailEvent = new EventEmitter<Observable<MiniDetailModel>>();

  constructor() {}

  public setDetail(miniDetail?: Observable<MiniDetailModel>): void {
    this.miniDetailEvent.emit(miniDetail);
  }

  public clearDetail(): void {
    this.miniDetailEvent.emit(undefined);
  }
}
