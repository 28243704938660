<div class="filter-wrapper" #filterWrapperRef>
  <div class="filter-current-state-wrapper" (smartTvKeyDownEnter)="showOptions()">
    <div
      *ngIf="!isSmartTv"
      [class.hidden]="!(breadCrumbStack && breadCrumbStack.length !== 0)"
      class="filter-remove"
      (click)="tryRemoveFilter($event)"
    ></div>

    <div class="filter-current-state" (click)="showOptions()">
      <div class="filter-current-state-title">{{ selectedDayTitle }}</div>
      <div class="filter-show-options" *ngIf="!isSmartTv"></div>
    </div>
  </div>
  <div class="filter-options-wrapper" *ngIf="!isSmartTv && !optionsHidden" #optionWrapperRef>
    <div class="filter-options-header" (click)="hideOptions()">
      <div class="filter-options-header-title">{{ filterTitle }}</div>
      <div class="filter-options-close"></div>
    </div>
    <div class="filter-categories-loading" [class.hidden]="!fetchingCategories">
      <img src="./assets/theme/images/loading.svg" />
    </div>
    <div [class.hidden]="fetchingCategories || getTempBreadCrumbs().length === 0" class="filter-options-breadcrumbs">
      <div class="filter-options-breadcrumb" (click)="clearTempBreadCrumbs()">{{ allText }}</div>
      <div
        class="filter-options-breadcrumb"
        (click)="goToTempBreadCrumb(i)"
        *ngFor="let breadCrumb of getTempBreadCrumbs(); index as i"
      >
        {{ breadCrumb }}
      </div>
    </div>
    <div class="filter-options" [class.hidden]="fetchingCategories">
      <div
        class="filter-option"
        [class.selected]="isActiveCategory(i)"
        (click)="changeSelectedCategoryIndex(i)"
        *ngFor="let category of showCategories; index as i"
      >
        <div class="filter-option-title">{{ category.title }}</div>
        <div
          class="filter-option-more"
          *ngIf="category.subcategories && category.subcategories.length > 0"
        ></div>
      </div>
    </div>
  </div>
</div>

<div #overlayDiv *ngIf="isSmartTv && !optionsHidden" class="overlay">
  <div class="overlay-dialog">
    <div
      class="filter-option-smarttv"
      *ngFor="let category of showCategories; index as i"
      [class.selected]="isActiveCategory(i)"
      (smartTvKeyDownEnter)="changeSelectedCategoryIndex(i)"
      (click)="changeSelectedCategoryIndex(i);$event.cancelBubble = true"
    >
      {{ category.title }}
    </div>
  </div>
</div>
