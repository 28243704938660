import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CardSize, CmsRibbon, Properties, RibbonTitleIcon } from '@atv-core/api/cms';
import { EpgApiService } from '@atv-core/api/epg';
import { FavoriteContentTypes } from '@atv-core/api/history';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { ChannelModel } from '@atv-core/services/cache/channel';
import { ChannelRightCacheService } from '@atv-core/services/cache/channelRights/channel-right-cache.service';
import { FavoriteCacheService } from '@atv-core/services/cache/favorite/favorite-cache.service';
import {
  DetailTranslationKeys,
  ErrorTranslationKeys,
  MenuTranslationKeys,
  RibbonTranslationKeys,
  SettingsKeys,
} from '@atv-bootstrap/services/config';
import { ConfigService } from '@atv-bootstrap/services/config/config.service';
import { DetailOverlayService } from '@atv-core/services/detail/detail-overlay.service';
import { PageContext, PageUrlTypes } from '@atv-core/services/log/log.model';
import { LogService } from '@atv-core/services/log/log.service';
import { PageData } from '@atv-core/services/menu-manager/menu-manager.service';
import { MessagesService } from '@atv-core/services/messages';
import { SessionService } from '@atv-core/services/session/session.service';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { EpgUtilityService, FromUntil } from '@atv-core/utility/epg-utility/epg-utility.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { ListPageFiltersComponent } from '@atv-pages/list-page/list-page-filters/list-page-filters.component';
import { ReloadablePageComponent } from '@atv-pages/reloadable-page.component';
import { ContentFilters, FilterContentTypes, FilterModel } from '@atv-shared/filters/filters.model';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, finalize, map } from 'rxjs/operators';
import { PagePropertiesUtility } from '@atv-core/utility/page-properties-utility';
import { ListContentProvider } from '@atv-shared/content-provider/providers/list-content-provider';
import { ContentProviderFactoryService } from '@atv-shared/content-provider/content-provider-factory.service';

@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListPageComponent extends ReloadablePageComponent implements OnInit, AfterViewInit, OnDestroy {
  public pageLoaded = false;
  public listFilterModel: FilterModel = new FilterModel();

  public pageProperties: Properties[];
  public allowedAdultMode = AdultMode.any;

  @ViewChild(ListPageFiltersComponent) listFilters: ListPageFiltersComponent;
  @ViewChild('placeholder') placeholderDiv: ElementRef<HTMLDivElement>;

  itemsLeftFetching = 0;

  allChannels: ChannelModel[];
  validChannels: ChannelModel[];

  ribbons: CmsRibbon[];

  shownListItemIndex = 0;
  maxItemLength = 0;

  epgFutureDays = 7;
  epgPastDays = 7;
  firstLoadAmount = 4;
  loadAmount = 2;

  noResultText = '';

  categoryFilterType = FilterContentTypes.EPG;

  public ready = false;
  ribbonIntersectionObserver: IntersectionObserver;
  private readonly useOldEpgCardStyling: boolean;
  private closeDetailSubscription: Subscription;
  private returnSubscription: Subscription;
  private useReplay: boolean;

  constructor(
    private channelRights: ChannelRightCacheService,
    private epgApi: EpgApiService,
    private epgUtility: EpgUtilityService,
    config: ConfigService,
    private favoriteCache: FavoriteCacheService,
    private log: LogService,
    private cdr: ChangeDetectorRef,
    sessionService: SessionService,
    private auth: AuthorizationService,
    private activatedRoute: ActivatedRoute,
    adultService: AdultService,
    messagesService: MessagesService,
    authorizationService: AuthorizationService,
    private spatialNavigationService: SpatialNavigationService,
    private detailService: DetailOverlayService,
    private contentProviderFactoryService: ContentProviderFactoryService,
  ) {
    super(sessionService, authorizationService, adultService, messagesService, config);

    this.useOldEpgCardStyling = this.config.getSettingBoolean(SettingsKeys.useOldEpgCards, false);

    this.ribbons = [];
    this.epgFutureDays = this.config.getSettingNumber(SettingsKeys.epgFutureDays);
    this.epgPastDays = this.config.getSettingNumber(SettingsKeys.epgPastDays);
    this.noResultText = this.config.getTranslation(ErrorTranslationKeys.error_filter_no_results);
  }

  public loadPage(): void {
    this.pageLoaded = true;
    const pageData = this.activatedRoute.snapshot.data as PageData;
    this.pageProperties = pageData.properties;
    this.useReplay = PagePropertiesUtility.isReplay(this.pageProperties);

    this.channelRights
      .getValidChannels(this.useReplay, this.sessionService.getCatalogId())
      .pipe(finalize(() => this.detectChanges()))
      .subscribe(
        (result) => {
          this.allChannels = result.allChannels;
          this.validChannels = result.validChannels;
          this.listFilterModel.filterInputChannels = this.validChannels;
        });

    this.log.pageView(
      new PageContext({
        pageURL: this.useReplay ? PageUrlTypes.replay_epg_lists : PageUrlTypes.epg_lists,
        pageTitle: this.config.getTranslation(MenuTranslationKeys.menu_lists),
        pageLocale: this.config.getLocale(),
      }),
    );
  }

  getPageProperties(): Observable<Properties[]> {
    return of((this.activatedRoute.snapshot.data as PageData).properties);
  }

  ngOnInit(): void {
    this.listFilterModel.filterDays = this.getFilterDates();
    this.listFilterModel.activeCmsFilters.push(
      ContentFilters.CATEGORY,
      ContentFilters.CHANNEL,
      ContentFilters.DAY,
    );

    super.start();

    if (SharedUtilityService.isSmartTv()) {
      this.closeDetailSubscription = this.detailService.closeDetailEvent.subscribe(() => {
        this.spatialNavigationService.setDefaultSection(NavigationSections.PAGE_CONTENT);
        this.spatialNavigationService.setFocus();
      });
    }
  }

  ngAfterViewInit(): void {
    this.ribbonIntersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.filter(entry => entry.target === this.placeholderDiv.nativeElement).forEach((entry) => {
          if (
            this.listFilterModel.channelFilterOutputIndex !== undefined &&
            this.listFilterModel.dayFilterOutputIndex !== undefined
          ) {
            return;
          } else if (entry.isIntersecting && this.itemsLeftFetching === 0) {
            this.prepareDataForRibbons();
          }
        });
      },
      {
        rootMargin: '100px',
      },
    );
  }

  public listFilterChange(): void {
    this.manageFilters();
  }

  hasResults(): boolean {
    if (this.itemsLeftFetching !== 0 || !this.ready) {
      return true;
    }
    if (!this.ribbons || this.ribbons.length === 0) {
      return false;
    }

    return !!this.ribbons.find((item) => item !== undefined);
  }

  private detectChanges(): void {
    // prevent change detection from firing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }

    if (SharedUtilityService.isSmartTv()) {
      this.registerElements();
    }
  }

  private registerElements(): void {
    this.unregisterElements();
    this.spatialNavigationService.register(
      NavigationSections.FILTERS,
      'app-day-filter .filter-current-state-wrapper, ' +
      'app-channel-filter .filter-current-state-wrapper, ' +
      'app-category-filter .filter-current-state-wrapper, ' +
      'app-clear-filter .filter-current-state-wrapper ',
      {
        leaveFor: {
          left: `@${NavigationSections.MENU}`,
          right: '',
          down: '@' + NavigationSections.PAGE_CONTENT,
        },
      },
    );
    this.spatialNavigationService.register(
      NavigationSections.PAGE_CONTENT,
      '.list-page-wrapper app-ribbon .ribbon-item a',
      {
        straightOnly: true,
        enterTo: 'last-focused',
        defaultElement: '.list-page-wrapper app-ribbon .ribbon-item a',
        leaveFor: {
          down: '',
          up: '@' + NavigationSections.FILTERS,
        },
      },
    );

    this.returnSubscription = fromEvent(document, 'keydown')
      .pipe(
        filter((event: KeyboardEvent) =>
          this.spatialNavigationService.keyCodeIsReturn(event.keyCode),
        ),
      )
      .subscribe(() => {
        this.spatialNavigationService.setFocus(NavigationSections.MENU);
      });
  }

  private unregisterElements(): void {
    this.spatialNavigationService.unregister(NavigationSections.PAGE_CONTENT);
    this.spatialNavigationService.unregister(NavigationSections.FILTERS);
    this.returnSubscription?.unsubscribe();
  }

  private manageFilters(): void {
    this.shownListItemIndex = 0;
    this.ribbons = [];
    this.itemsLeftFetching = 0;
    this.listFilterModel.filterDays = this.getFilterDates();
    this.channelRights
      .getValidChannels(this.useReplay, this.sessionService.getCatalogId())
      .pipe(finalize(() => this.detectChanges()))
      .subscribe(
        (result) => {
          this.allChannels = result.allChannels;
          this.validChannels = result.validChannels;
          this.listFilterModel.filterInputChannels = this.validChannels;

          this.prepareDataForRibbons();
        });
  }

  private getFilterDates(): Date[] {
    const res: Date[] = [];

    res.push(new Date());
    let dayAmount: number;
    if (this.useReplay) {
      dayAmount = this.epgPastDays;
    } else {
      dayAmount = this.epgFutureDays;
    }
    for (let i = 1; i <= dayAmount; i++) {
      const tmp = new Date();
      if (this.useReplay) {
        res.push(new Date(tmp.getFullYear(), tmp.getMonth(), tmp.getDate() - i, 0, 0, 0, 0));
      } else {
        res.push(new Date(tmp.getFullYear(), tmp.getMonth(), tmp.getDate() + i, 0, 0, 0, 0));
      }
    }
    res.sort((a, b) => a.getTime() - b.getTime());

    return res;
  }

  private prepareDataForRibbons(): void {
    this.ready = true;
    if (this.listFilterModel.channelFilterOutputIndex !== undefined) {
      this.makeDayRibbons();
    } else {
      this.makeChannelRibbons();
    }
  }

  private makeDayRibbons(): void {
    if (this.listFilterModel.dayFilterOutputIndex !== undefined) {
      this.itemsLeftFetching = 1;
      this.detectChanges();
      const fromUntil = this.epgUtility.getFromUntilFromTimeForDay(
        this.listFilterModel.filterDays[this.listFilterModel.dayFilterOutputIndex],
      );
      this.maxItemLength = 1;
      this.makeRibbon(
        false,
        this.listFilterModel.filterInputChannels[this.listFilterModel.channelFilterOutputIndex],
        this.shownListItemIndex,
        this.getDayString(
          this.listFilterModel.filterDays[this.listFilterModel.dayFilterOutputIndex].getTime(),
        ),
        fromUntil.from,
        fromUntil.until,
      );
    } else {
      let loadUntil: number;
      if (this.shownListItemIndex === 0) {
        loadUntil = this.shownListItemIndex + this.firstLoadAmount;
      } else {
        loadUntil = this.shownListItemIndex + this.loadAmount;
      }

      this.maxItemLength = this.listFilterModel.filterDays.length;

      while (
        this.shownListItemIndex < this.listFilterModel.filterDays.length &&
        this.shownListItemIndex <= loadUntil
        ) {
        this.itemsLeftFetching++;
        this.ribbons.push(undefined);
        const fromUntil = this.epgUtility.getFromUntilFromTimeForDay(
          this.listFilterModel.filterDays[this.shownListItemIndex],
        );

        this.makeRibbon(
          false,
          this.listFilterModel.filterInputChannels[this.listFilterModel.channelFilterOutputIndex],
          this.shownListItemIndex,
          this.getDayString(this.listFilterModel.filterDays[this.shownListItemIndex].getTime()),
          fromUntil.from,
          fromUntil.until,
        );
        this.shownListItemIndex++;
        this.detectChanges();
      }
    }
  }

  private getDayString(day: number): string {
    return SharedUtilityService.getDayInfo(day, false, this.config);
  }

  private makeChannelRibbons(): void {
    this.ribbonIntersectionObserver.unobserve(this.placeholderDiv.nativeElement);
    let loadUntil = 0;
    if (this.shownListItemIndex === 0) {
      loadUntil = this.shownListItemIndex + this.firstLoadAmount;
    } else {
      loadUntil = this.shownListItemIndex + this.loadAmount;
    }

    this.filterChannelsForTags().subscribe((channels) => {
      this.maxItemLength = channels.length;
      while (this.shownListItemIndex < channels.length && this.shownListItemIndex <= loadUntil) {
        this.itemsLeftFetching++;
        this.ribbons.push(undefined);
        let fromUntil;
        if (this.listFilterModel.dayFilterOutputIndex !== undefined) {
          fromUntil = this.epgUtility.getFromUntilFromTimeForDay(
            this.listFilterModel.filterDays[this.listFilterModel.dayFilterOutputIndex],
          );
        } else {
          fromUntil = this.epgUtility.getFromUntilFromTime(new Date(), this.useReplay);
        }

        this.makeRibbon(
          true,
          channels[this.shownListItemIndex],
          this.shownListItemIndex,
          channels[this.shownListItemIndex].name,
          fromUntil.from,
          fromUntil.until,
        );
        this.shownListItemIndex++;
        this.detectChanges();
        this.ribbonIntersectionObserver.observe(this.placeholderDiv.nativeElement);
      }
    });
  }

  private filterChannelsForTags(): Observable<ChannelModel[]> {
    if (
      this.listFilterModel.categoryFilterOutputTags &&
      this.listFilterModel.categoryFilterOutputTags.length > 0
    ) {
      let fromUntil: FromUntil;
      if (this.listFilterModel.dayFilterOutputIndex !== undefined) {
        fromUntil = this.epgUtility.getFromUntilFromTime(
          this.listFilterModel.filterDays[this.listFilterModel.dayFilterOutputIndex],
          this.useReplay,
        );
      } else {
        fromUntil = this.epgUtility.getFromUntilFromTime(new Date(), this.useReplay);
      }

      return this.epgApi
        .getChannelsWithTags(
          this.listFilterModel.categoryFilterOutputTags,
          fromUntil.from,
          fromUntil.until,
        )
        .pipe(
          map((channelIds) =>
            this.validChannels.filter((channel) =>
              channelIds.some((channelId) => channelId === channel.id),
            ),
          ),
          catchError(() => of(this.validChannels)),
        );
    } else {
      return of(this.validChannels);
    }
  }

  private makeRibbon(
    isChannelRibbon: boolean,
    channel: ChannelModel,
    index: number,
    ribbonTitle: string,
    from: Date,
    until: Date,
  ): void {
    this.ribbons[index] = {
      title: ribbonTitle,
      contentMessage: this.config.getTranslation(
        RibbonTranslationKeys.ribbon_no_content_ott,
      ),
      properties: [Properties.SHOWPROGRESS, Properties.USE_BEST_SCHEDULE, Properties.SHOW_SCHEDULE_INFO],
      cardSize: CardSize.NORMAL,
      isOnDetailPage: false,
      titleIcons: isChannelRibbon && this.auth.isAuthorized() ?
        [this.getFavoriteRibbonTitleIcon(channel), this.getRecordingRibbonTitleIcon(channel)] : undefined,
      contentProvider: new ListContentProvider(this.epgApi, this.epgUtility, this.contentProviderFactoryService, channel.id, from, until,
        this.useReplay, this.listFilterModel.categoryFilterOutputTags),
    };

    this.itemsLeftFetching--;
    this.detectChanges();
  }

  private getRecordingRibbonTitleIcon(channel: ChannelModel): RibbonTitleIcon {
    return {
      iconImage: () => {
        return channel.recordableIcon;
      },
      iconHeight: 17,
    };
  }

  private getFavoriteRibbonTitleIcon(channel: ChannelModel): RibbonTitleIcon {
    return {
      iconImage: () => {
        return channel.isFavorite()
          ? './assets/theme/svg/favoritesicon_selected.svg'
          : './assets/theme/svg/favoritesicon_normal.svg';
      },
      iconHeight: 23,
      iconClick: () => {
        if (channel.adult) {
          this.adultService.checkAdultMode({
            successCallback: () => {
              this.favoriteCache.setFavorite(
                FavoriteContentTypes.CHANNEL,
                channel.id,
                channel.adult,
              );
              channel.setFavorite(true);
            },
            errorCallback: () => {
              this.messagesService.showErrorMessage(this.config.getTranslation(DetailTranslationKeys.detail_adult_warning));
            },
          });
        } else {
          this.toggleChannelFavorite(channel);
        }
      },
    };
  }

  private toggleChannelFavorite(channel: ChannelModel): void {
    if (channel.isFavorite()) {
      this.favoriteCache.deleteFavorite(FavoriteContentTypes.CHANNEL, channel.id);

      channel.setFavorite(false);
    } else {
      this.favoriteCache.setFavorite(FavoriteContentTypes.CHANNEL, channel.id, channel.adult);

      channel.setFavorite(true);
    }
  }

  ngOnDestroy(): void {
    super.stop();
    if (SharedUtilityService.isSmartTv()) {
      this.unregisterElements();
    }

    this.closeDetailSubscription?.unsubscribe();
    this.ribbonIntersectionObserver?.disconnect();
  }
}
