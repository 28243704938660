<div
  @items
  class="message-item"
  [class.error-message]="message.type === MessageTypes.ERROR"
  [class.warning-message]="message.type === MessageTypes.WARNING"
  [class.info-message]="message.type === MessageTypes.INFO"
  [class.success-message]="message.type === MessageTypes.SUCCESS"
  *ngFor="let message of messages; let i = index"
>
  <div class="message-item-content">{{ message.message }}</div>
  <div *ngIf="!isSmartTv" (click)="removeMessage(i)" class="message-item-close"></div>
</div>
