import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';

import { MenuService } from '../main-menu/menu.service';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-top-bar-smarttv',
  templateUrl: './top-bar-smarttv.component.html',
  styleUrls: ['./top-bar-smarttv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarSmarttvComponent implements OnInit, OnDestroy {
  public now = new Date();
  private intervalSubscription?: Subscription;

  constructor(
    private menuService: MenuService,
    private zone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.intervalSubscription = interval(1000).subscribe(() => {
        this.now = new Date();
        this.cdr.detectChanges();
      });
    });
  }

  getBreadcrumb(): string {
    return this.menuService.getMobileBreadCrumb();
  }

  ngOnDestroy(): void {
    this.intervalSubscription?.unsubscribe();
  }
}
