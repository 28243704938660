import { Observable } from 'rxjs';
import { CardOrientation, Ribbon2Asset } from '@atv-core/api/cms';
import { ChannelCardModes } from '@atv-core/api/epg';

export abstract class ContentProvider {
  protected orientation: CardOrientation;

  abstract hasNext(): boolean;

  abstract resetPaging(): void;

  public getOrientation(): CardOrientation {
    if (!this.orientation) {
      throw new Error('orientation not initialized');
    }

    return this.orientation;
  }

  abstract getItems(): Observable<Ribbon2Asset[]>; // TODO add optional amount of items to fetch

  public getChannelCardMode(): ChannelCardModes {
    return ChannelCardModes.NONE;
  }
}
