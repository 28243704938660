import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { AdvertisementTypes } from '@atv-core/api/acs/acs-api.model';
import { StreamPackageTypes, StreamRequestGroups } from '@atv-core/api/api/api-api.model';
import { CmsAction, CmsActionMethod } from '@atv-core/api/cms';
import { EpgProgramAsset, EpgScheduleSummaryAsset, EpgScheduleTimeAsset } from '@atv-core/api/epg/epg-api.model';
import { EpgApiService } from '@atv-core/api/epg/epg-api.service';
import { BookmarkContentTypes, ScheduleTime } from '@atv-core/api/history';
import {
  Stream2Advertisement,
  Stream2AssetResponse,
  Stream2ManifestResponse,
  Stream2TerminateRequest,
} from '@atv-core/api/ss/streaming-api.model';
import { StreamingApiService } from '@atv-core/api/ss/streaming-api.service';
import { VodDetail } from '@atv-core/api/vod';
import { AdultService } from '@atv-core/services/adult';
import { BookmarkCacheService } from '@atv-core/services/cache/bookmark';
import { ChannelModel } from '@atv-core/services/cache/channel/channel.model';
import { ChannelRightCacheService } from '@atv-core/services/cache/channelRights/channel-right-cache.service';
import { CmsActionService } from '@atv-core/services/cms-action/cms-action.service';
import { ConfigService, DetailTranslationKeys, SettingsKeys } from '@atv-bootstrap/services/config';
import { ErrorTranslationKeys, PlayerTranslationKeys } from '@atv-bootstrap/services/config/config.model';
import { LogErrorInfo, PageContext, PageUrlTypes, PlayerLogInfo } from '@atv-core/services/log/log.model';
import { LogService } from '@atv-core/services/log/log.service';
import { MessagesService } from '@atv-core/services/messages/messages.service';
import { PlayerService } from '@atv-core/services/player/player.service';
import { SessionService } from '@atv-core/services/session';
import { SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { StreamManagerService } from '@atv-core/services/stream-manager-service/stream-manager.service';
import { LocalStorageKeys } from '@atv-core/utility/constants/localStorageKeys';
import { PlayOriginator, PlayState, StreamType } from '@atv-core/utility/constants/shared';
import { EpgUtilityService } from '@atv-core/utility/epg-utility/epg-utility.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { ClientDetails } from '@atv-core/utility/static-services/client-detail.static-service';
import { environment } from '@env/environment';
import { BehaviorSubject, fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, tap, throttleTime } from 'rxjs/operators';

import { NumberEntryComponent } from '@atv-shared/number-entry/number-entry.component';
import { AdvertisementElement } from '../advertisement/advertisement-element.model';
import { EpgInfoComponent, EpgInfoInputModel } from '../epg-info/epg-info.component';
import { PlayInfoModel } from '../model/play-info-model';
import { PlayerConfig, PlayerConfigInfo } from '../model/player-config.model';
import { ChannelbarComponent } from '../player-controls/channelbar/channelbar.component';
import { SeekbarComponent } from '../player-controls/seekbar/seekbar.component';
import { VodInfoComponent } from '../vod-info/vod-info.component';
import { BlackoutFactory } from '@atv-core/utility/epg-utility/blackout-factory';
import { ImageRecipe, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';
import { VideoEvent } from '../model/video-event';
import { PlayerEngine, PlayerEngines } from '@atv-player/engines/player-engine';
import { CastlabsPlayerEngine } from '@atv-player/engines/castlabs/castlabs-player-engine';
import { ShakaPlayerEngine } from '@atv-player/engines/shaka/shaka-player-engine';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('wrapper') wrapperDiv: ElementRef<HTMLDivElement>;

  @ViewChild('seekbar') seekbar?: SeekbarComponent;
  @ViewChild('channelbar') channelbar?: ChannelbarComponent;
  @ViewChild('epgInfo') epgInfo?: EpgInfoComponent;
  @ViewChild('vodInfo') vodInfo?: VodInfoComponent;
  public isInPipMode = false;
  public hasPlayed = false;
  public epgInfoInput: EpgInfoInputModel = new EpgInfoInputModel();
  public vodInfoInput: VodDetail;
  public clientPlatformDetails;
  public muted = false;
  public volume = 0;
  public isSmartTv = SharedUtilityService.isSmartTv();
  public isPlayerVisible = false;
  private pipModeSubscription: Subscription;
  private channelSchedules: EpgScheduleSummaryAsset[] = [];
  private convertLiveToReplayStream = false;
  private readonly playerLivePauseResumeCompensation: number;
  private isStartOver = false;
  private startStreamInProgress = false;
  private bookmarkOffset = 0;
  private playerEndTime: number;
  private playerStartTime: number;
  private scheduleEndTime: number;
  private scheduleStartTime: number;
  private playerConfig: PlayerConfigInfo;
  private readonly pageContext: PageContext;
  private refreshPlayerForBroadpeak = false;
  private readonly refreshPlayerForBroadpeakTimeout: number;
  private refreshPlayerForBroadpeakTimeoutSubscription = undefined;
  private readonly playingInterval: number;
  private playingIntervalSubscription = undefined;
  private readonly bookmarkInterval: number;
  private bookmarkIntervalSubscription = undefined;
  private playerInteractionSubscription: Subscription;
  private controlsVisibleSubscription: Subscription;
  private nextScheduleTimeoutSubscription = undefined;
  private readonly watchlistTimeout: number;
  private watchlistTimeoutSubscription = undefined;
  private channelListSubscription: Subscription;
  private adultPopupOpen = false;
  private playerVisibilitySubscription: Subscription;
  private readonly broadpeakAnalyticsEnabled: boolean;

  private playerEngine: PlayerEngine;
  private finishedLoadingSubject = new BehaviorSubject(false);

  constructor(
    private config: ConfigService,
    private playerService: PlayerService,
    public streamManagerService: StreamManagerService,
    private streamingApi: StreamingApiService,
    private epgApi: EpgApiService,
    private epgUtility: EpgUtilityService,
    private messagesService: MessagesService,
    private bookmarkCache: BookmarkCacheService,
    private adultService: AdultService,
    private channelRightCache: ChannelRightCacheService,
    private cmsAction: CmsActionService,
    private log: LogService,
    private cdr: ChangeDetectorRef,
    private sessionService: SessionService,
    private spatialNavigationService: SpatialNavigationService,
  ) {
    this.playerLivePauseResumeCompensation = this.config.getSettingNumber(SettingsKeys.playerLivePauseResumeCompensation, 20000);
    this.refreshPlayerForBroadpeakTimeout = this.config.getSettingNumber(SettingsKeys.playerPauseStopPlayerTime, 20000);
    this.playingInterval = this.config.getSettingNumber(SettingsKeys.playerStillPlayingTime, 5 * 60 * 1000);
    this.bookmarkInterval = this.config.getSettingNumber(SettingsKeys.playerBookmarkTime, 5 * 60 * 1000);
    this.watchlistTimeout = this.config.getSettingNumber(SettingsKeys.playerWatchlistTime, 5 * 60 * 1000);
    this.broadpeakAnalyticsEnabled = environment.broadpeakAnalyticsAllowed &&
                                     this.config.getSettingBoolean(SettingsKeys.broadpeakAnalyticsEnabled, false);

    this.pageContext = new PageContext({
      pageURL: PageUrlTypes.videoplayer,
      pageLocale: this.config.getLocale(),
    });

    // if back button is pressed when atv-player is open, close atv-player instead of going back
    window.onpopstate = () => {
      if (this.isPlayerVisible && !this.isInPipMode) {
        this.closePlayer();
        history.go(1);
      }
    };

    window.addEventListener('keyup', (e) => {
      // when space is pressed
      if (e.keyCode === 32 && this.isPlayerVisible && !this.isInPipMode && !this.isLinear()) {
        this.playerService.controlsVisibility.showControls();
        this.playPauseToggle(e);
      }
    });

    this.sessionService.reloadCurrentPageEvent.subscribe(() => {
      if (this.playerService.currentPlayInfo && this.playerService.currentPlayInfo.streamIsLinear()) {
        this.channelRightCache.reloadZappableChannels();
      }
    });

    this.playerService.closePlayerEvent.subscribe(() => {
      this.closePlayer();
    });

    this.streamManagerService.keepAliveErrorEvent.subscribe((errorResponse) => {
      this.log.playerKeepAliveError(this.getPlayerLogInfo(), new LogErrorInfo(errorResponse));
      this.handleError();
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(): void {
    this.terminate();
  }

  ngOnInit(): void {
    this.sessionService.catalogChangedEvent.subscribe(() => {
      if (this.playerService.currentPlayInfo?.channelInformation) {
        const newCatalogId = this.sessionService.getCatalogId();
        if (
          !this.playerService.currentPlayInfo.channelInformation.catalogs.some(
            (catalog) => catalog.id === newCatalogId,
          )
        ) {
          this.closePlayer();
        }
      }
    });

    this.playerVisibilitySubscription = this.playerService.playerVisibilitySubject.subscribe(
      (value) => {
        this.isPlayerVisible = value;
        this.checkPlayerIsOpen();
      },
    );

    if (!this.isSmartTv) {
      this.controlsVisibleSubscription = this.playerService.controlsVisibility.controlsVisibleSubject.subscribe(
        (value) => {
          if (!value) {
            this.hideControls();
          }

          this.detectChanges();
        },
      );
    }

    this.pipModeSubscription = this.playerService.pipModeSubject.subscribe((value) => {
      this.isInPipMode = value;
      this.checkPlayerIsOpen();
    });
  }

  ngAfterViewInit(): void {
    if (this.wrapperDiv) {
      this.playerInteractionSubscription = merge(
        fromEvent(this.wrapperDiv.nativeElement, 'click'),
        fromEvent(this.wrapperDiv.nativeElement, 'mousemove'),
        fromEvent(this.wrapperDiv.nativeElement, 'onmousewheel'),
      )
        .pipe(throttleTime(500))
        .subscribe(() => {
          this.playerService.controlsVisibility.showControls();
        });
    }

    this.finishedLoadingSubject.next(true);
  }

  public onFinishedLoading(): Observable<void> {
    return this.finishedLoadingSubject.pipe(filter(isLoaded => isLoaded), map(() => {
    }));
  }

  // TODO move this function
  public setAdProgressText(): void {
    if (
      !this.playerService.advertisements.currentAd ||
      this.playerService.advertisements.currentAd.type === AdvertisementTypes.BREAK
    ) {
      this.playerService.advertisements.progressText = '';
      return;
    }

    let currentAd: number;
    let totalAds: number;

    if (
      this.playerService.advertisements.currentAd.type === AdvertisementTypes.PREROLL &&
      this.playerService.advertisements.prerollAds.length > 1
    ) {
      currentAd = this.playerService.advertisements.prerollAdIndex;
      totalAds = this.playerService.advertisements.prerollAds.length;
    } else if (
      this.playerService.advertisements.currentAd.type === AdvertisementTypes.POSTROLL &&
      this.playerService.advertisements.postrollAds.length > 1
    ) {
      currentAd = this.playerService.advertisements.postrollAdIndex;
      totalAds = this.playerService.advertisements.postrollAds.length;
    }

    if (currentAd !== undefined && totalAds !== undefined) {
      let adCountString = this.config.getTranslation(
        PlayerTranslationKeys.player_ad_advertisement_count,
      );

      if (!adCountString) {
        this.playerService.advertisements.progressText = this.config.getTranslation(
          PlayerTranslationKeys.player_ad_title,
        );
        return;
      }
      adCountString = adCountString.replace('$1', `${currentAd}`);
      adCountString = adCountString.replace('$2', `${totalAds}`);
      this.playerService.advertisements.progressText = adCountString;
    } else {
      this.playerService.advertisements.progressText = this.config.getTranslation(
        PlayerTranslationKeys.player_ad_title,
      );
    }
  }

  public skipAd(): boolean {
    if (
      this.playerService.advertisements.currentAd &&
      this.playerService.seekbar.getSkipTime() !== undefined &&
      this.playerService.seekbar.getPosition() > this.playerService.seekbar.getSkipTime()
    ) {
      this.playerService.advertisements.currentAd.skippedAfter = this.getPlayerPosition() * 1000;
      this.playerService.advertisements.skipText = undefined;
      this.loadNextAd();
      return true;
    }

    return false;
  }

  public isSkipAdShown(): boolean {
    return (
      this.playerService.advertisements.currentAd &&
      this.playerService.advertisements.currentAd.type !== AdvertisementTypes.BREAK &&
      this.playerService.seekbar.isSkipShown() &&
      this.playerService.advertisements.skipText !== undefined
    );
  }

  public goToAdvertisement(e?: Event): void {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const currentAd = this.playerService.advertisements.currentAd;
    if (currentAd?.action) {
      const action: CmsAction = {
        actionId: currentAd.action.id,
        actionType: currentAd.action.type,
        actionMethod: currentAd.action.method,
      };
      if (action.actionMethod === CmsActionMethod.DETAIL) {
        // TODO move this logic to the invoking component
        if (!this.isSmartTv) {
          this.pausePlayer();
          this.playerService.enablePipMode();
          currentAd.actionPerformed = true;
        } else {
          this.closePlayer();
        }
      }

      this.cmsAction.doAction(action, false, true);
    }
  }

  public zapToChannel(channel: ChannelModel): void {
    if (channel.id === this.playerService.currentPlayInfo?.channelInformation?.id) {
      return;
    }

    if (this.startStreamInProgress) {
      return;
    }

    setTimeout(() => {
      this.zapAndPlayCurrentScheduleForChannel(channel);
    }, 200);
  }

  public volumeChange(value: number): void {
    this.playerEngine.setVolume(value);
    this.volume = this.playerEngine.getVolume();
    this.detectChanges();
    localStorage.setItem(LocalStorageKeys.player_volume, JSON.stringify(this.volume));
  }

  public mutedChange(value: boolean): void {
    this.playerEngine.setMuted(value);
    this.muted = this.playerEngine.isMuted();
    this.detectChanges();
    localStorage.setItem(LocalStorageKeys.player_muted, JSON.stringify(this.muted));
  }

  public showPauseButton(): boolean {
    if (this.isLinear()) {
      return this.liveToReplayAllowed();
    } else {
      return true;
    }
  }

  public liveToReplayAllowed(): boolean {
    const playInfo = this.playerService.currentPlayInfo;

    // allow if customer has replay rights + schedule is not blacked out for replay
    return playInfo?.channelInformation?.customerHasRight(StreamType.REPLAY) &&
           !this.epgUtility.scheduleHasBlackout(playInfo.scheduleInformation.blackouts,
             BlackoutFactory.create(environment.platform, StreamType.REPLAY));
  }

  public onPlayerClick(): void {
    if (this.isInPipMode) {
      this.playerService.disablePipMode();
    } else {
      if (ClientDetails.getDetails().mobile) {
        if (!this.areControlsVisible()) {
          this.playerService.controlsVisibility.showControls();
        } else {
          this.playerService.controlsVisibility.hideControls();
          this.updateEpgProgramInfoInputModel();
        }
      } else if (!this.isLinear()) {
        if (this.isPaused()) {
          if (this.playerService.isPlayerAttached()) {
            this.playerEngine.play().then();
          }
        } else {
          this.playerService.userInitiatedPause = true;
          if (this.playerService.isPlayerAttached()) {
            this.playerEngine.pause();
          }
        }
      }
    }
  }

  public onChannelSelect(channel: ChannelModel): void {
    this.zapToChannel(channel);
  }

  // TODO move to atv-player player, remove seekEvent from all instances
  public onSeek(value: number): void {
    value = (this.playerService.seekbar.getDuration() * value) / 100;
    value = Math.min(this.getPlayerDuration() - 1, value);

    const playInfo = this.playerService.currentPlayInfo;
    if (playInfo && !playInfo.streamIsVod()) {
      value = Math.min((new Date().getTime() - this.playerStartTime) / 1000, value);
    }

    if (this.isLinear() && !this.playerService.advertisements.currentAd) {
      playInfo.bookmarkPos = value * 1000;
      this.convertLiveToReplayStream = this.playerService.userInitiatedPause;
      this.doConversionOfLiveToReplayStream();
    } else {
      this.setPlayerPosition(value);
    }
  }

  public isPaused(): boolean {
    return this.playerService.isPaused();
  }

  public playPauseToggle(e?: Event): void {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.playerService.playPauseToggle();
  }

  public pausePlayer(): void {
    if (!this.isPaused() && this.playerService.isPlayerAttached()) {
      this.playerEngine.pause();
    }
  }

  public showPlayer(): void {
    this.playerService.playerVisibilitySubject.next(true);
    this.log.pageView(this.pageContext);
    this.detectChanges();
  }

  public hidePlayer(): void {
    this.channelbar?.hideChannelBar();
    this.playerService.playerVisibilitySubject.next(false);
    this.detectChanges();
  }

  public closePlayer(e?: Event): void {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.playerService.exitFullScreen();
    this.playerService.disablePipMode();
    this.cleanUpPlayer();
    this.hidePlayer();
    this.streamManagerService.deleteReservedStream(this.getPlayerLogInfo());
  }

  public isChromecastAvailable(): boolean {
    return this.playerService.isChromecastAvailable();
  }

  public isLinear(): boolean {
    return this.playerService.currentPlayInfo?.streamIsLinear() ?? false;
  }

  public isVod(): boolean {
    return this.playerService.currentPlayInfo?.streamIsVod() ?? false;
  }

  public getProgressTime(): string {
    return this.playerService.seekbar.getProgressTime();
  }

  public areControlsVisible(): boolean {
    return this.playerService.controlsVisibility.controlsVisibleSubject.value;
  }

  public getCurrentAd(): AdvertisementElement {
    return this.playerService.advertisements.currentAd;
  }

  public getAdProgressText(): string {
    return this.playerService.advertisements.progressText;
  }

  public getAdSkipText(): string {
    return this.playerService.advertisements.skipText;
  }

  public canAirplay(): boolean {
    return this.playerService.canAirplay();
  }

  public doAirplay(): void {
    this.playerService.doAirplay();
  }

  public isAirplayActive(): boolean {
    return this.playerService.isAirplayActive();
  }

  public getBreakvertisingImage(currentAd: AdvertisementElement): string {
    return ImageRecipeUtil.createImageUrl(currentAd.image, ImageRecipe.BREAKVERTISING_1);
  }

  public updateStreamOptions(isAirplayActive: boolean): Observable<void> {
    return this.streamManagerService.updateStreamOptions(this.streamManagerService.getActiveSid(),
      this.playerService.currentPlayInfo.streamAssetInfo.mid, isAirplayActive);
  }

  public playAsset(playInfo: PlayInfoModel): void {
    this.resetPlayer();
    this.playerService.currentPlayInfo = playInfo;

    this.convertLiveToReplayStream = false;
    this.epgInfoInput = new EpgInfoInputModel();
    this.vodInfoInput = undefined;

    if (this.isVod()) {
      this.setVodInfoInputModel();
    } else {
      if (this.isLinear()) {
        this.getChannelSchedules(playInfo.channelInformation).subscribe(
          () => {
            this.updateEpgProgramInfoInputModel();
          },
          () => {
            this.updateEpgProgramInfoInputModel();
          },
        );
      } else {
        this.updateEpgProgramInfoInputModel();
      }

      this.channelRightCache.reloadZappableChannels();
    }

    this.playerService.seekbar.reset();

    this.playerConfig = PlayerConfig.getInfo(playInfo.streamType);
    if (this.playerConfig.packaging === StreamPackageTypes.MSS) {
      this.handleError(
        this.config.getTranslation(
          ErrorTranslationKeys.error_streaming_notsupported_os_browser_combination,
        ),
      );
    } else {
      this.initPlayer().subscribe(() => {
        if (playInfo.isReservedStream) {
          this.startReservedStream();
        } else {
          this.startStream();
        }
      });
    }

    if (this.isSmartTv) {
      if (this.channelListSubscription) {
        this.channelListSubscription.unsubscribe();
      }

      // TODO move to zapbar
      if (this.isLinear() && NumberEntryComponent.numberEvent) {
        this.channelListSubscription = (fromEvent(document, 'keyup') as Observable<KeyboardEvent>)
          .pipe(
            filter((event) => this.spatialNavigationService.keyCodeIsChannelList(event.keyCode)),
          )
          .subscribe(() => {
            this.channelbar.toggleChannelBar();
          });
      }
    }
  }

  private terminate(): void {
    const playInfo = this.playerService.currentPlayInfo;
    if (this.streamManagerService.getActiveSid()) {
      const body: Stream2TerminateRequest = {};

      if (this.isLinear()) {
        body.watchlist = true;
      }

      if (playInfo.streamAssetInfo) {
        body.mid = playInfo.streamAssetInfo.mid;
      }

      if (
        this.playerEngine &&
        !playInfo.isTrailer &&
        !this.isLinear() &&
        this.hasPlayed &&
        !this.getCurrentAd()
      ) {
        let size: number;
        let position: number;

        if (playInfo.streamIsVod()) {
          size = this.getPlayerDuration() * 1000;
          position = this.getPlayerPosition() * 1000 - this.bookmarkOffset;
        } else {
          size = this.scheduleEndTime - this.scheduleStartTime;
          position = this.getPlayerPosition() * 1000 - this.bookmarkOffset;
        }

        if (position != null && size != null) {
          body.bookmark = {
            size,
            position: Math.floor(position),
          };
        }
      }

      this.streamingApi.sendTerminateBeacon(this.streamManagerService.getActiveSid(), body);
    }

    if (this.streamManagerService.getReservedSid()) {
      this.streamingApi.sendTerminateBeacon(this.streamManagerService.getReservedSid(), {});
    }
  }

  private detectChanges(): void {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  private initPlayer(): Observable<void> {
    // TODO move player creation to playerservice
    this.clientPlatformDetails = ClientDetails.getDetails();

    if (this.playerEngine === undefined) {
      let observable: Observable<PlayerEngine>;
      if (environment.playerEngine === PlayerEngines.SHAKA) {
        observable = ShakaPlayerEngine.create('video-player');
      } else {
        observable = CastlabsPlayerEngine.create('video-player');
      }

      return observable.pipe(map((engine) => {
        this.playerEngine = engine;
        this.playerService.setPlayer(this.playerEngine, this);

        this.playerEngine.on(VideoEvent.ENDED, this.onPlayerEnded);
        this.playerEngine.on(VideoEvent.PAUSE, this.onPlayerPause);
        this.playerEngine.on(VideoEvent.PLAYING, this.onPlayerPlay);
        this.playerEngine.on(VideoEvent.TIMEUPDATE, this.updateSeekbarPosition);
        this.playerEngine.on(VideoEvent.ERROR, this.onPlayerError);
      }));
    }

    return of(void 0);
  }

  private resetPlayer(): void {
    this.channelSchedules = [];

    this.cleanUpPlayer(false, false);

    this.hasPlayed = false;
    this.convertLiveToReplayStream = false;
    this.isStartOver = false;

    this.playerService.advertisements.reset();
  }

  private setVodInfoInputModel(): void {
    const playInfo = this.playerService.currentPlayInfo;
    if (playInfo && playInfo.vodInformation) {
      this.vodInfoInput = playInfo.vodInformation;
    }
  }

  private updateEpgProgramInfoInputModel(): void {
    const playInfo = this.playerService.currentPlayInfo;
    if (!playInfo) {
      return;
    }

    const temp: EpgInfoInputModel = new EpgInfoInputModel();
    temp.program = playInfo.programInformation;
    temp.schedule = playInfo.scheduleInformation;
    temp.season = playInfo.seasonInformation;
    temp.channel = playInfo.channelInformation;
    temp.channelSchedules = this.isLinear() ? this.channelSchedules : undefined;
    this.epgInfoInput = temp;
  }

  private startStream(): void {
    const playInfo = this.playerService.currentPlayInfo;

    this.showPlayer();

    this.startStreamInProgress = true;

    this.isStartOver = false;

    this.streamManagerService
      .newStreamAndAsset(this.getPlayerLogInfo(), playInfo.streamType, playInfo.streamAssetId, playInfo.isReservedStream,
        this.playerConfig.packaging, this.playerConfig.encryption, playInfo.quoteId, StreamRequestGroups.MAIN, false,
        this.isAirplayActive())
      .subscribe(
        (result) => {
          this.startStreamInProgress = false;

          playInfo.streamAssetInfo = result;

          this.setPlayerScheduleInfo(result.event);

          if (playInfo.streamAssetInfo.advertisements) {
            this.initAdvertisements(playInfo.streamAssetInfo.advertisements);
          } else {
            this.loadManifest();
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.handleError(
            this.config.getTranslation(
              errorResponse && errorResponse.error ? errorResponse.error.code : '',
              ErrorTranslationKeys.error_streaming,
            ),
          );
          this.startStreamInProgress = false;
        },
      );
  }

  private setPlayerScheduleInfo(event?: ScheduleTime): void {
    const playInfo = this.playerService.currentPlayInfo;
    if (!playInfo.streamIsVod()) {
      if (playInfo.scheduleInformation && playInfo.scheduleInformation.published) {
        this.scheduleStartTime = SharedUtilityService.timeStringToMs(
          playInfo.scheduleInformation.published.start,
        );

        this.scheduleEndTime = SharedUtilityService.timeStringToMs(
          playInfo.scheduleInformation.published.end,
        );
      } else if (playInfo.recordingInformation) {
        this.scheduleStartTime = SharedUtilityService.timeStringToMs(
          playInfo.recordingInformation.start,
        );

        this.scheduleEndTime = SharedUtilityService.timeStringToMs(
          playInfo.recordingInformation.end,
        );
      }
      this.playerStartTime = this.scheduleStartTime;
      this.playerEndTime = this.scheduleEndTime;

      if (event) {
        this.playerStartTime = SharedUtilityService.timeStringToMs(event.start);
        this.playerEndTime = SharedUtilityService.timeStringToMs(event.end);
      }

      if (
        (playInfo.streamType === StreamType.REPLAY || playInfo.streamType === StreamType.NPVR) &&
        new Date().getTime() < this.playerEndTime
      ) {
        this.isStartOver = true;
      }
    }
  }

  private getAssetForCurrentStream(resumeFromLive = false): void {
    this.showPlayer();
    this.startStreamInProgress = true;

    const playInfo = this.playerService.currentPlayInfo;

    this.isStartOver = false;
    this.streamManagerService
      .getAssetForCurrentStream(this.getPlayerLogInfo(), playInfo.streamType, playInfo.streamAssetId, playInfo.isReservedStream,
        playInfo.quoteId, resumeFromLive, this.isAirplayActive())
      .subscribe(
        (result) => {
          this.startStreamInProgress = false;

          playInfo.streamAssetInfo = result;

          this.setPlayerScheduleInfo(result.event);

          if (playInfo.streamAssetInfo.advertisements) {
            this.initAdvertisements(playInfo.streamAssetInfo.advertisements);
          } else {
            this.loadManifest();
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.handleError(
            this.config.getTranslation(
              errorResponse && errorResponse.error ? errorResponse.error.code : '',
              ErrorTranslationKeys.error_streaming,
            ),
          );
          this.startStreamInProgress = false;
        },
      );
  }

  private startReservedStream(): void {
    this.showPlayer();

    const playInfo = this.playerService.currentPlayInfo;

    this.isStartOver = false;
    this.startStreamInProgress = true;
    this.streamManagerService
      .startReservedStream(this.getPlayerLogInfo(), playInfo.quoteId)
      .subscribe(
        (result: Stream2AssetResponse) => {
          this.startStreamInProgress = false;

          playInfo.streamAssetInfo = result;

          if (playInfo.streamAssetInfo.advertisements) {
            this.initAdvertisements(playInfo.streamAssetInfo.advertisements);
          } else {
            this.loadManifest();
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.handleError(
            this.config.getTranslation(
              errorResponse && errorResponse.error ? errorResponse.error.code : '',
              ErrorTranslationKeys.error_streaming,
            ),
          );
          this.startStreamInProgress = false;
          this.hidePlayer();
        },
      );
  }

  // TODO move this function
  private initAdvertisements(advertisements: Stream2Advertisement[]): void {
    this.playerService.advertisements.prerollAds = advertisements.filter(
      (ad) => ad.type === AdvertisementTypes.PREROLL && ad.mid,
    );
    this.playerService.advertisements.postrollAds = advertisements.filter(
      (ad) => ad.type === AdvertisementTypes.POSTROLL && ad.mid,
    );

    if (this.playerService.advertisements.prerollAds.length === 0) {
      this.loadManifest();
    } else {
      this.loadAdvertisement(
        this.playerService.advertisements.prerollAds[
          this.playerService.advertisements.prerollAdIndex++
          ],
      );
    }
  }

  // TODO move this function
  private loadAdvertisement(advertisements: Stream2Advertisement): void {
    this.playerService.advertisements.currentAd = new AdvertisementElement(advertisements, new Date(), this.config);
    this.setAdProgressText();
    this.loadManifest();
  }

  private loadManifest(): void {
    const playInfo = this.playerService.currentPlayInfo;

    const mid = this.playerService.advertisements.currentAd
      ? this.playerService.advertisements.currentAd.mid
      : playInfo.streamAssetInfo.mid;

    this.streamManagerService.getManifest(mid).subscribe(
      (result) => {
        playInfo.activeManifest = result;
        if (this.playerService.advertisements.currentAd) {
          this.playerService.advertisements.currentAd.manifestUrl = result.url;
        }
        this.playManifest(result);
      },
      () => {
        if (this.playerService.advertisements.currentAd) {
          this.loadNextAd(this.playerService.advertisements.currentAd.type);
        } else {
          this.handleError();
        }
      },
    );
  }

  private playManifest(manifest: Stream2ManifestResponse): void {
    this.initPlayer().subscribe(() => {
      this.playerService.seekbar.reset();

      const playInfo = this.playerService.currentPlayInfo;
      if (playInfo.streamIsLinear()) {
        localStorage.setItem(LocalStorageKeys.player_last_channel, playInfo.channelInformation.id);
      }

      if (playInfo.streamType === StreamType.LINEAR && !this.liveToReplayAllowed()) {
        this.playerService.seekbar.disableSeek();
      }

      if (this.playerService.advertisements.currentAd) {
        // when ads are playing
        this.bookmarkOffset = 0;

        this.playerService.seekbar.enableAdMode(this.playerService.advertisements.currentAd.skipTime);

        this.detectChanges();

        // when real content is playing
      } else {
        this.bookmarkOffset = playInfo.streamAssetInfo.bookmarkOffset || 0;
        this.playerService.seekbar.disableAdMode();
      }


      this.playerService.seekbar.setBeginBuffer(this.bookmarkOffset);
      this.detectChanges();

      const broadpeakDomain = this.config.getSettingString(SettingsKeys.broadpeakAnalyticsDomain);
      const analyticsUrl = this.config.getSettingString(SettingsKeys.broadpeakAnalyticsServer, '');
      this.playerEngine.load([manifest.url], this.getStartPosition(), this.getInitialBitrate(),
        manifest.drm && environment.drm_protected_enabled,
        this.broadpeakAnalyticsEnabled, broadpeakDomain, analyticsUrl,
        {
          assetId: manifest.assetId,
          merchant: environment.drm_merchant,
          environment: environment.drm_environment,
          userId: this.sessionService.getCustomer(),
          sessionId: manifest.drmSessionId,
          authToken: manifest.drmAuthToken,
        }).then(
        () => this.onPlayerReady(),
        (e) => this.onPlayerError(e),
      );
    });
  }

  private getStartPosition(): number {
    // add bookmark offset to position
    const bookmarkPosition = ((this.playerService.currentPlayInfo.bookmarkPos ?? 0) + this.bookmarkOffset) / 1000;

    if (this.getCurrentAd()?.type === AdvertisementTypes.PREROLL || this.getCurrentAd()?.type === AdvertisementTypes.POSTROLL) {
      return 0;
    } else if (this.playerService.currentPlayInfo.streamIsVod()) {
      return bookmarkPosition;
    } else if (this.isStartOver) {
      // need to adjust the start time when source is loaded because it depends on the seek range
      this.playerEngine.one(VideoEvent.LOADEDMETADATA, () => {
        this.playerEngine.seekAbsolute(bookmarkPosition).then();
      });
      return undefined;
    } else if (this.playerService.currentPlayInfo.streamType === StreamType.REPLAY ||
               this.playerService.currentPlayInfo.streamType === StreamType.NPVR) {
      const duration = (this.playerEndTime - this.playerStartTime) / 1000;

      // if bookmark within last 20s or out of stream -> start 20s from end
      return bookmarkPosition + 20 > duration ? duration - 20 : bookmarkPosition;
    }

    return undefined;
  }

  private getInitialBitrate(): number {
    let bitrate = 3000000;
    if (screen.height > 2000) {
      bitrate = this.config.getSettingNumber(SettingsKeys.playerInitialBitrate4K, bitrate);
    } else {
      bitrate = this.config.getSettingNumber(SettingsKeys.playerInitialBitrate, bitrate);
    }
    return bitrate;
  }

  private onPlayerReady(): void {
    this.playerService.videoRenditionsSubject.next(this.playerEngine.getVideoQualities());
    this.playerService.audioTracksSubject.next(this.playerEngine.getAudioTracks());
    this.playerService.textTracksSubject.next(this.playerEngine.getSubtitles());

    if (!this.isSmartTv) {
      this.playerService.controlsVisibility.showControls();
    }

    // set ad duration
    if (this.playerService.advertisements?.currentAd?.type === AdvertisementTypes.PREROLL
        || this.playerService.advertisements?.currentAd?.type === AdvertisementTypes.POSTROLL) {
      this.playerService.advertisements.currentAd.adDuration = this.playerEngine.getDuration() * 1000;
    }

    this.safePlay();

    this.detectChanges();
  }

  private safePlay(): void {
    this.playerEngine.setMuted(this.muted);
    const promise = this.playerEngine.play();

    // detect if play is allowed unmuted (for safari play restrictions)
    promise
      .then(() => {
        this.volume = JSON.parse(localStorage.getItem(LocalStorageKeys.player_volume)) || 1;
        this.muted = JSON.parse(localStorage.getItem(LocalStorageKeys.player_muted)) || false;
        this.playerEngine.setVolume(this.volume);
        this.playerEngine.setMuted(this.muted);
      })
      .catch(() => {
        // when unmuted play is not allowed
        this.playerEngine.setMuted(true);
        this.muted = true;
        this.volume = JSON.parse(localStorage.getItem(LocalStorageKeys.player_volume)) || 1;
        this.playerEngine.setVolume(this.volume);
        this.playerEngine.play();
        this.detectChanges();
      });
  }

  private onPlayerPlay = () => {
    this.hasPlayed = true;
    this.playerService.userInitiatedPause = false;
    const playInfo = this.playerService.currentPlayInfo;

    if (
      this.playerService.advertisements.currentAd &&
      this.playerService.advertisements.currentAd.type === AdvertisementTypes.BREAK
    ) {
      this.cleanUpCurrentAd(false);
      this.playerService.seekbar.disableAdMode();
      this.detectChanges();
    }

    this.playerService.advertisements.clearBreakvertisingTimeout();

    if (this.refreshPlayerForBroadpeakTimeoutSubscription) {
      this.stopBroadpeakTimeout();
    }

    if (
      this.convertLiveToReplayStream &&
      !playInfo.streamIsVod() &&
      !this.playerService.advertisements.currentAd
    ) {
      playInfo.bookmarkPos -= this.playerLivePauseResumeCompensation;
      this.doConversionOfLiveToReplayStream();
    } else if (this.refreshPlayerForBroadpeak) {
      this.refreshPlayerForBroadpeak = false;
      this.loadManifest();
    } else {
      this.setNextScheduleTimeout();

      if (this.playerService.advertisements.currentAd) {
        return;
      }

      if (!this.bookmarkIntervalSubscription && !playInfo.isTrailer) {
        this.bookmarkIntervalSubscription = setInterval(() => {
          this.setBookmark();
        }, this.bookmarkInterval);
      }
      if (!this.playingIntervalSubscription) {
        this.playingIntervalSubscription = setInterval(() => {
          this.log.playPlaying(this.getPlayerLogInfo());
        }, this.playingInterval);
      }

      if (!this.watchlistTimeoutSubscription && playInfo && this.isLinear()) {
        this.watchlistTimeoutSubscription = setTimeout(() => {
          this.streamingApi
            .addToWatchList(this.streamManagerService.getActiveSid(), playInfo.streamAssetInfo.mid)
            .subscribe();
        }, this.watchlistTimeout);
      }

      this.log.playState(this.getPlayerLogInfo(), PlayState.playing, PlayOriginator.player);
    }

    this.detectChanges();
  }

  private doConversionOfLiveToReplayStream(): void {
    const playInfo = this.playerService.currentPlayInfo;
    if (playInfo) {
      this.log.playStop(this.getPlayerLogInfo());
    }
    this.convertLiveToReplayStream = false;
    this.stopIntervals();

    this.isStartOver = true;
    playInfo.streamType = StreamType.REPLAY;
    playInfo.streamAssetId = playInfo.scheduleInformation.id;
    this.updateEpgProgramInfoInputModel();
    this.getAssetForCurrentStream(true);
  }

  private setNextScheduleTimeout(): void {
    const playInfo = this.playerService.currentPlayInfo;
    if (this.isLinear() && playInfo) {
      const timout =
        SharedUtilityService.timeStringToMs(playInfo.scheduleInformation.published.end) -
        new Date().getTime();

      if (!this.nextScheduleTimeoutSubscription) {
        this.nextScheduleTimeoutSubscription = setTimeout(() => {
          this.showNextProgramInformation();
        }, timout);
      }
    }
  }

  // TODO split up in updatePosition and updateDuration

  private showNextProgramInformation(): void {
    if (!this.playerService.advertisements.currentAd) {
      this.log.playStop(this.getPlayerLogInfo());
    }

    const playInfo = this.playerService.currentPlayInfo;

    const fromUntil = this.epgUtility.getTodayFromUntil();
    this.getChannelSchedules(playInfo.channelInformation).subscribe(() => {
      const currentSchedule = this.epgUtility.findCurrentSchedule(this.channelSchedules);
      playInfo.scheduleInformation = currentSchedule;
      this.epgApi
        .getProgram(currentSchedule.program, fromUntil.from, fromUntil.until)
        .subscribe((programAsset) => {
          playInfo.programInformation = programAsset.program;
          playInfo.seasonInformation = programAsset.season;
          playInfo.seriesInformation = programAsset.series;
          this.setPlayerScheduleInfo();
          this.updateEpgProgramInfoInputModel();
          if (!this.playerService.advertisements.currentAd) {
            this.log.playStart(this.getPlayerLogInfo());
          }
        });

      this.stopNextScheduleTimeout();
      this.setNextScheduleTimeout();
    });
  }

  private onPlayerPause = () => {
    if (this.isLinear() && !this.playerService.advertisements.currentAd) {
      const playInfo = this.playerService.currentPlayInfo;
      playInfo.bookmarkPos = this.getPlayerPosition() * 1000;

      this.convertLiveToReplayStream = this.playerService.userInitiatedPause;

      this.stopNextScheduleTimeout();
    } else {
      this.stopBroadpeakTimeout();
      this.refreshPlayerForBroadpeakTimeoutSubscription = setTimeout(() => {
        this.refreshPlayerForBroadpeak = true;
      }, this.refreshPlayerForBroadpeakTimeout);
    }

    // do breakvertising
    if (!this.playerService.advertisements.currentAd && !this.playerService.seekbar.isSeeking()) {
      this.breakvertising();
      this.log.playState(this.getPlayerLogInfo(), PlayState.paused, PlayOriginator.player);
    }

    this.setBookmark();
    this.stopBookmarkInterval();
    this.stopPlayingInterval();
    this.stopWatchlistTimeout();
  }

  private breakvertising(): void {
    const playInfo = this.playerService.currentPlayInfo;
    const playerBreakvertisingTime = this.config.getSettingNumber(
      SettingsKeys.playerBreakvertisingTime,
      -1,
    );
    if (!playInfo || playerBreakvertisingTime < 0) {
      return;
    }

    const contentId = playInfo.streamIsVod()
      ? playInfo.streamAssetId
      : playInfo.channelInformation
        ? playInfo.channelInformation.id
        : undefined;

    if (contentId) {
      // TODO move this to advertisements, use RxJS timer
      this.playerService.advertisements.breakvertisingTimeout = window.setTimeout(() => {
        if (
          this.streamManagerService.getActiveSid() &&
          playInfo &&
          playInfo.streamAssetInfo &&
          playInfo.streamAssetInfo.mid
        ) {
          this.streamingApi
            .getBreakvertising(
              this.streamManagerService.getActiveSid(),
              playInfo.streamAssetInfo.mid,
            )
            .subscribe((result) => {
              if (
                result &&
                result[0] &&
                result[0].type === AdvertisementTypes.BREAK &&
                result[0].image
              ) {
                this.cleanUpCurrentAd(false);
                this.playerService.seekbar.enableAdMode(undefined);
                this.playerService.advertisements.currentAd = new AdvertisementElement(
                  result[0],
                  new Date(),
                  this.config,
                );
                this.setAdProgressText();
                this.detectChanges();
              }
            });
        }
      }, playerBreakvertisingTime);
    }
  }

  private onPlayerEnded = () => {
    if (this.playerService.advertisements.currentAd) {
      this.loadNextAd();
    } else {
      // when 'normal' stream ends, check if we can play postroll ad
      this.loadNextPostrollAd();
    }
  }

  private loadNextAd(failedAdType?: AdvertisementTypes): void {
    if (
      (this.playerService.advertisements.currentAd &&
       this.playerService.advertisements.currentAd.type === AdvertisementTypes.PREROLL) ||
      failedAdType === AdvertisementTypes.PREROLL
    ) {
      this.loadNextPrerollAd();
    } else if (
      (this.playerService.advertisements.currentAd &&
       this.playerService.advertisements.currentAd.type === AdvertisementTypes.POSTROLL) ||
      failedAdType === AdvertisementTypes.POSTROLL
    ) {
      this.loadNextPostrollAd();
    }
  }

  private loadNextPrerollAd(): void {
    this.cleanUpCurrentAd(true);
    if (
      this.playerService.advertisements.prerollAdIndex >
      this.playerService.advertisements.prerollAds.length - 1
    ) {
      this.playerService.advertisements.currentAd = undefined;
      this.loadManifest();
    } else {
      this.loadAdvertisement(
        this.playerService.advertisements.prerollAds[
          this.playerService.advertisements.prerollAdIndex++
          ],
      );
    }
  }

  private loadNextPostrollAd(): void {
    this.cleanUpCurrentAd(true);
    const playInfo = this.playerService.currentPlayInfo;

    if (
      this.playerService.advertisements.postrollAdIndex >
      this.playerService.advertisements.postrollAds.length - 1
    ) {
      if (this.playerService.isPlayerAttached()) {
        playInfo.bookmarkPos = this.playerEngine.getCurrentTime() * 1000;
        this.closePlayer();
      }
    } else {
      this.loadAdvertisement(
        this.playerService.advertisements.postrollAds[
          this.playerService.advertisements.postrollAdIndex++
          ],
      );
    }
  }

  private zapAndPlayCurrentScheduleForChannel(channel: ChannelModel): void {
    if (this.playerConfig.packaging === 'MSS') {
      this.handleError(
        this.config.getTranslation(
          ErrorTranslationKeys.error_streaming_notsupported_os_browser_combination,
        ),
      );
      return;
    }

    const fromUntil = this.epgUtility.getTodayFromUntil();
    this.getChannelSchedules(channel).subscribe(
      () => {
        const currentSchedule = this.epgUtility.findCurrentSchedule(this.channelSchedules);

        if (currentSchedule) {
          if (!channel.customerHasRight(StreamType.LINEAR)) {
            this.messagesService.showErrorMessage(
              this.config.getTranslation(
                ErrorTranslationKeys.error_no_rights,
                ErrorTranslationKeys.error_streaming,
              ),
            );
          } else if (
            this.epgUtility.scheduleHasBlackout(currentSchedule.blackouts, BlackoutFactory.create(environment.platform, StreamType.LINEAR))
          ) {
            this.messagesService.showErrorMessage(
              this.config.getTranslation(
                ErrorTranslationKeys.error_blackout,
                ErrorTranslationKeys.error_streaming,
              ),
            );
          } else {
            this.epgApi
              .getProgram(currentSchedule.program, fromUntil.from, fromUntil.until)
              .subscribe(
                (programAsset) => {
                  if (programAsset.program.adult || channel.adult) {
                    this.adultPopupOpen = true;
                    this.adultService.checkAdultMode({
                      successCallback: () => {
                        this.updateZapInfo(channel, currentSchedule, programAsset);
                        this.adultPopupOpen = false;
                        this.detectChanges();
                        if (!this.isSmartTv) {
                          this.playerService.controlsVisibility.showControls();
                        }
                      },
                      errorCallback: () => {
                        this.messagesService.showErrorMessage(this.config.getTranslation(DetailTranslationKeys.detail_adult_warning));
                        this.adultPopupOpen = false;
                        this.detectChanges();
                      },
                    });
                  } else {
                    this.updateZapInfo(channel, currentSchedule, programAsset);
                  }
                },
                () => {
                  this.messagesService.showErrorMessage(
                    this.config.getTranslation(ErrorTranslationKeys.error_streaming),
                  );
                },
              );
          }
        } else {
          this.checkAdultBeforeZapToChannel(channel);
        }
      },
      () => {
        this.checkAdultBeforeZapToChannel(channel);
      },
    );
  }

  private checkAdultBeforeZapToChannel(channel: ChannelModel): void {
    if (channel.adult) {
      this.adultPopupOpen = true;
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.zapToChannelWithoutSchedules(channel);
          this.adultPopupOpen = false;
          this.detectChanges();
          if (!this.isSmartTv) {
            this.playerService.controlsVisibility.showControls();
          }
        },
        errorCallback: () => {
          this.messagesService.showErrorMessage(this.config.getTranslation(DetailTranslationKeys.detail_adult_warning));
          this.adultPopupOpen = false;
          this.detectChanges();
        }
      });
    } else {
      this.zapToChannelWithoutSchedules(channel);
    }
  }

  private zapToChannelWithoutSchedules(channel: ChannelModel): void {
    // if ad in progress
    this.cleanUpCurrentAd(true);

    this.stopNextScheduleTimeout();
    this.log.playStop(this.getPlayerLogInfo());

    this.convertLiveToReplayStream = false;
    this.playerService.currentPlayInfo = new PlayInfoModel(StreamType.LINEAR, channel.id, 0);

    this.updateEpgProgramInfoInputModel();

    if (this.streamManagerService.getActiveSid()) {
      this.getAssetForCurrentStream();
    } else {
      this.startStream();
    }
  }

  private updateZapInfo(
    channel: ChannelModel,
    currentSchedule: EpgScheduleSummaryAsset,
    programAsset: EpgProgramAsset,
  ): void {
    // if ad in progress
    this.cleanUpCurrentAd(true);

    this.stopNextScheduleTimeout();
    this.log.playStop(this.getPlayerLogInfo());

    this.convertLiveToReplayStream = false;
    this.playerService.currentPlayInfo = new PlayInfoModel(StreamType.LINEAR, channel.id, 0);
    const playInfo = this.playerService.currentPlayInfo;
    playInfo.scheduleInformation = currentSchedule;
    playInfo.channelInformation = channel;

    playInfo.programInformation = programAsset.program;
    playInfo.seasonInformation = programAsset.season;
    playInfo.seriesInformation = programAsset.series;
    this.updateEpgProgramInfoInputModel();

    if (this.streamManagerService.getActiveSid()) {
      this.getAssetForCurrentStream();
    } else {
      this.startStream();
    }
  }

  private onPlayerError = (error) => {
    if (error?.detail?.message) {
      console.error(error?.detail?.message);
    }

    if (error && error.details) {
      error = error.details;
    }

    if (
      error.instanceof === 'CLPPError' ||
      error.severity === 'ERROR' ||
      error.severity === 'FATAL' ||
      this.playerService.advertisements.currentAd
    ) {
      if (this.playerService.advertisements.currentAd) {
        this.log.advertisementError(
          this.playerService.advertisements.currentAd,
          {
            code: error.name || 'PLAYER_ERROR ' + (error.errCode || ''),
            errorId: '',
            message: error.message || `${error.name}: ${error.url}`,
          },
          this.pageContext,
        );
      } else {
        this.log.playError(this.getPlayerLogInfo(), PlayOriginator.player, {
          code: error.name || 'PLAYER_ERROR ' + (error.errCode || ''),
          errorId: '',
          message: error.message,
        });
      }

      this.handleError();
    } else {
      if (this.playerService.advertisements.currentAd) {
        this.log.advertisementError(
          this.playerService.advertisements.currentAd,
          {
            code: error.name || 'PLAYER_WARNING ' + (error.errCode || ''),
            errorId: '',
            message: error.message || `${error.name}: ${error.url}`,
          },
          this.pageContext,
        );
      } else {
        this.log.playWarning(this.getPlayerLogInfo(), {
          code: error.name || 'PLAYER_WARNING ' + (error.errCode || ''),
          errorId: '',
          message: error.message,
        });
      }
    }
  }

  private handleError(errorMessage?): void {
    this.stopIntervals();

    if (this.playerService.advertisements.currentAd) {
      const addType = this.playerService.advertisements.currentAd.type;
      this.cleanUpPlayer(true);
      this.loadNextAd(addType);
    } else {
      this.cleanUpPlayer();
      const defaultMessage = this.config.getTranslation(ErrorTranslationKeys.error_streaming);
      this.messagesService.showErrorMessage(errorMessage || defaultMessage);
    }
  }

  private cleanUpPlayer(cleanUpAdsOnly = false, disposePlayer = true): void {
    if (this.playerService.isPlayerAttached()) {
      this.playerEngine.pause();
    }

    if (disposePlayer) {
      this.disposePlayer();
    }

    if (cleanUpAdsOnly) {
      this.cleanUpCurrentAd(true);
    } else {
      this.cleanUpCurrentAd(true);
      this.cleanActiveStream();
    }
  }

  private cleanActiveStream(): void {
    this.streamManagerService.deleteActiveStream(this.getPlayerLogInfo());
    this.playerService.seekbar.reset();
  }

  private cleanUpCurrentAd(resetSeekbar: boolean): void {
    if (this.playerService.advertisements.currentAd) {
      this.log.advertisementEnd(this.playerService.advertisements.currentAd, this.pageContext);

      this.playerService.advertisements.currentAd = undefined;
    }

    if (resetSeekbar) {
      this.playerService.seekbar.reset();
    }
  }

  private disposePlayer(): void {
    this.stopIntervals();
    this.playerService.controlsVisibility.hideControls();

    if (this.playerService.isPlayerAttached()) {
      if (
        !(
          this.playerService.advertisements.currentAd &&
          (this.playerService.advertisements.currentAd.type === AdvertisementTypes.PREROLL ||
           this.playerService.advertisements.currentAd.type === AdvertisementTypes.POSTROLL)
        )
      ) {
        this.setBookmark();
      }
      this.playerEngine.off(VideoEvent.ENDED, this.onPlayerEnded);
      this.playerEngine.off(VideoEvent.PAUSE, this.onPlayerPause);
      this.playerEngine.off(VideoEvent.PLAYING, this.onPlayerPlay);
      this.playerEngine.off(VideoEvent.TIMEUPDATE, this.updateSeekbarPosition);
      this.playerEngine.off(VideoEvent.ERROR, this.onPlayerError);

      this.playerEngine.destroy().then();
      this.playerEngine = undefined;
      this.playerService.setPlayer(undefined, this);
    }

    this.detectChanges();
  }

  private stopIntervals(): void {
    this.stopBroadpeakTimeout();
    this.stopNextScheduleTimeout();

    this.stopPlayingInterval();
    this.stopBookmarkInterval();
    this.stopWatchlistTimeout();
  }

  private stopBroadpeakTimeout(): void {
    if (this.refreshPlayerForBroadpeakTimeoutSubscription) {
      clearTimeout(this.refreshPlayerForBroadpeakTimeoutSubscription);
      this.refreshPlayerForBroadpeakTimeoutSubscription = undefined;
    }
  }

  private stopNextScheduleTimeout(): void {
    if (this.nextScheduleTimeoutSubscription) {
      clearInterval(this.nextScheduleTimeoutSubscription);
      this.nextScheduleTimeoutSubscription = undefined;
    }
  }

  private stopPlayingInterval(): void {
    if (this.playingIntervalSubscription) {
      clearInterval(this.playingIntervalSubscription);
      this.playingIntervalSubscription = undefined;
    }
  }

  private stopBookmarkInterval(): void {
    if (this.bookmarkIntervalSubscription) {
      clearInterval(this.bookmarkIntervalSubscription);
      this.bookmarkIntervalSubscription = undefined;
    }
  }

  private stopWatchlistTimeout(): void {
    if (this.watchlistTimeoutSubscription) {
      clearTimeout(this.watchlistTimeoutSubscription);
      this.watchlistTimeoutSubscription = undefined;
    }
  }

  private hideControls(): void {
    if (
      this.adultPopupOpen ||
      this.channelbar?.isOpen ||
      (this.epgInfoInput && this.epgInfoInput.detailOpen) ||
      (this.vodInfoInput && this.vodInfoInput.detailOpen) ||
      (this.playerService.isPlayerAttached() && this.isPaused())
    ) {
      // TODO use lock/unlock mechanism
      this.playerService.controlsVisibility.resetTimer();
    } else {
      this.updateEpgProgramInfoInputModel();
      this.detectChanges();
    }
  }

  private setBookmark(): void {
    // no bookmark for trailer or for live events
    const playInfo = this.playerService.currentPlayInfo;
    const currentAd = this.playerService.advertisements.currentAd;
    if (!this.playerEngine || playInfo.isTrailer || this.isLinear() || !this.hasPlayed || currentAd) {
      return;
    }

    const adult = playInfo.isAdult;
    playInfo.bookmarkPos = this.getPlayerPosition() * 1000 - this.bookmarkOffset;

    if (playInfo.streamIsVod()) {
      this.bookmarkCache.setBookmark({
        type: BookmarkContentTypes.VOD,
        id: playInfo.vodInformation?.id,
        series: playInfo.vodInformation?.seriesId,
        position: Math.floor(playInfo.bookmarkPos),
        size: this.getPlayerDuration() * 1000,
        adult,
        lastUpdate: (new Date().getTime() / 1000).toString(),
      });
    } else {
      let schedules: EpgScheduleTimeAsset[];
      if ((!playInfo.allSchedules || playInfo.allSchedules.length === 0) && playInfo.scheduleInformation) {
        schedules = [playInfo.scheduleInformation.published];
      } else if (playInfo.allSchedules && playInfo.allSchedules.length > 0) {
        schedules = playInfo.allSchedules.map((schedule) => schedule.published);
      }

      this.bookmarkCache.setBookmark({
        type: BookmarkContentTypes.PROGRAM,
        id: playInfo.scheduleInformation?.program ?? playInfo.recordingInformation?.program,
        series: playInfo.seriesInformation?.id,
        position: Math.floor(playInfo.bookmarkPos),
        schedules,
        size: this.scheduleEndTime - this.scheduleStartTime,
        adult,
        lastUpdate: (new Date().getTime() / 1000).toString(),
      });
    }
  }

  // TODO Use strategy pattern to retrieve correct values
  private updateSeekbarPosition = () => {
    const position = this.getPlayerPosition();
    const duration = this.isVod() || this.playerService.seekbar.isSkipShown() ? this.getPlayerDuration() :
      (this.playerEndTime - this.playerStartTime) / 1000;

    this.playerService.seekbar.setPosition(position);
    this.playerService.seekbar.setDuration(duration);

    if (this.playerService.advertisements.currentAd) {
      this.playerService.advertisements.skipText =
        this.playerService.seekbar.getRemainingSkipTime() === 0
          ? this.config.getTranslation(PlayerTranslationKeys.player_ad_skip_now_ott)
          : this.config
            .getTranslation(PlayerTranslationKeys.player_ad_skip_seconds_ott)
            .replace('$1', this.playerService.seekbar.getRemainingSkipTime().toString());
    }

    if (
      this.isStartOver &&
      this.playerService.seekbar.getPosition() >= this.playerService.seekbar.getDuration() &&
      new Date().getTime() > this.playerEndTime
    ) {
      this.closePlayer();
    }

    this.detectChanges();
  }

  // availabilityStartTime (from .mpd file) to relative current time since schedule start
  private getPlayerPosition(): number {
    if (!this.playerService.isPlayerAttached()) {
      return undefined;
    }

    return this.playerEngine.getCurrentTime(this.scheduleStartTime - this.bookmarkOffset);
  }

  private setPlayerPosition(absolutePosition: number): void {
    if (!this.playerService.isPlayerAttached()) {
      return;
    }

    this.playerEngine.seekAbsolute(absolutePosition).then();
  }

  private getPlayerDuration(): number {
    return !this.playerEngine || this.playerEngine.getDuration() === Infinity
      ? (this.playerEndTime - this.playerStartTime) / 1000
      : this.playerEngine.getDuration();
  }

  private checkPlayerIsOpen(): void {
    if (this.isPlayerVisible && !this.isInPipMode) {
      document.body.classList.add('atv-player-overlay-is-open');
    } else {
      document.body.classList.remove('atv-player-overlay-is-open');
    }
  }

  private getChannelSchedules(channel: ChannelModel): Observable<EpgScheduleSummaryAsset[]> {
    const currentChannelId = this.playerService.currentPlayInfo?.channelInformation?.id;
    if (channel.id === currentChannelId && this.channelSchedules?.length > 0) {
      return of(this.channelSchedules);
    }

    this.channelSchedules = undefined;

    const fromUntil = this.epgUtility.getTodayFromUntil();
    return this.epgApi
      .getScheduleForChannelFromUntil(channel.id, fromUntil.from, fromUntil.until)
      .pipe(
        map((result) =>
          result.schedules.sort((a, b) => {
            const aStart = parseInt(a.published.start, 10);
            const bStart = parseInt(b.published.start, 10);
            return aStart - bStart;
          }),
        ),
        tap((result) => (this.channelSchedules = result)),
        catchError(() => {
          this.channelSchedules = undefined;
          return of(undefined);
        }),
      );
  }

  private getPlayerLogInfo(): PlayerLogInfo {
    const playInfo = this.playerService.currentPlayInfo;
    if (playInfo) {
      const isVod = playInfo.streamIsVod();
      const logStreamId = isVod ? playInfo.streamAssetId : '';
      const logAssetId =
        isVod && playInfo.vodInformation ? playInfo.vodInformation.id : playInfo.streamAssetId;
      const logName =
        isVod && playInfo.vodInformation
          ? playInfo.vodInformation.title
          : playInfo.isSeriesDetail && playInfo.seriesInformation
            ? playInfo.seriesInformation.title
            : playInfo.programInformation
              ? playInfo.programInformation.title
              : undefined;

      return new PlayerLogInfo({
        id: logAssetId,
        type: playInfo.streamType,
        name: logName,
        scheduleId: playInfo.recordingInformation
          ? playInfo.recordingInformation.schedule
          : !isVod && playInfo.scheduleInformation
            ? playInfo.scheduleInformation.id
            : '',
        programId: playInfo.recordingInformation
          ? playInfo.recordingInformation.program
          : !isVod && playInfo.programInformation
            ? playInfo.programInformation.id
            : '',
        streamId: logStreamId,
        ssId: this.streamManagerService.getActiveSid(),
        manifest: playInfo.activeManifest ? playInfo.activeManifest.url : '',
        channelId: !isVod && playInfo.channelInformation ? playInfo.channelInformation.id : '',
        channelName: !isVod && playInfo.channelInformation ? playInfo.channelInformation.name : '',
        seasonNumber: !isVod
          ? playInfo.seasonInformation
            ? playInfo.seasonInformation.season
            : undefined
          : playInfo.vodInformation
            ? playInfo.vodInformation.season
            : undefined,
        episodeNumber: !isVod
          ? playInfo.programInformation
            ? playInfo.programInformation.episode
            : undefined
          : playInfo.vodInformation
            ? playInfo.vodInformation.episode
            : undefined,
      });
    }

    return undefined;
  }

  ngOnDestroy(): void {
    this.terminate();
    this.closePlayer();
    this.controlsVisibleSubscription?.unsubscribe();
    this.playerInteractionSubscription?.unsubscribe();
    this.pipModeSubscription.unsubscribe();
    this.playerVisibilitySubscription?.unsubscribe();
  }
}
