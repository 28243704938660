import { Injectable } from '@angular/core';
import { EpgApiService, EpgChannelRight, EpgScheduleSummaryAsset } from '@atv-core/api/epg';
import { ChannelCacheService, ChannelModel } from '@atv-core/services/cache/channel';
import { PlayerService } from '../../player/player.service';
import { SessionService } from '@atv-core/services/session';
import { Stb } from '@atv-core/services/session/stb';
import { StreamType } from '@atv-core/utility/constants/shared';
import { EpgUtilityService } from '@atv-core/utility/epg-utility/epg-utility.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { environment } from '@env/environment';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

import { AtvFeatures } from '@atv-core/utility/constants/atv_features';
import { AdultMode } from '@atv-core/services/adult';
import { RecordingModel } from './../stb/recording.model';
import { BlackoutFactory } from '@atv-core/utility/epg-utility/blackout-factory';

interface ValidChannelResult {
  allChannels: ChannelModel[];
  validChannels: ChannelModel[];
}

@Injectable({ providedIn: 'root' })
export class ChannelRightCacheService {
  private reloadingCache = false;
  private ottRights: EpgChannelRight[] = [];
  private stbsWithRights: Stb[] = [];
  private cachePromise: Observable<unknown>;
  private cacheExpirationTime = 120000; // set channel cache on 2minutes;
  private expirationTime = 0;

  private zappableChannelsSubject = new BehaviorSubject<ChannelModel[]>([]);

  constructor(
    private sessionService: SessionService,
    private epgApi: EpgApiService,
    private channelCache: ChannelCacheService,
    private epgUtility: EpgUtilityService,
    private playerService: PlayerService,
  ) {
    this.sessionService.clearAllCachesEvent.subscribe(() => {
      this.clearCache();
    });
  }

  public clearCache(): void {
    if (this.reloadingCache) {
      return;
    }
    this.ottRights = [];
    this.stbsWithRights.forEach((stb) => (stb.channelRights = undefined));
    this.stbsWithRights = [];
    this.expirationTime = 0;
    this.cachePromise = undefined;
    this.reloadingCache = true;
  }

  public getValidChannels(replay, catalogId: string): Observable<ValidChannelResult> {
    const requiredOttRights = replay
      ? environment.atv_replay_ott_rights
      : environment.atv_ott_rights;
    const requiredStbRights = replay
      ? environment.atv_replay_stb_rights
      : environment.atv_stb_rights;
    return this.reloadChannelRights().pipe(
      switchMap(() => {
        return this.channelCache.getChannels().pipe(
          map((channels) => {
            channels = channels.filter(
              (channel) =>
                !channel.catalogs || channel.catalogs.some((catalog) => catalog.id === catalogId),
            );
            channels.forEach((channel) => this.setRecordableIcon(channel));

            return {
              allChannels: channels,
              validChannels: channels.filter((channel) => {
                if (
                  !channel.enabled ||
                  channel.audioOnly ||
                  (!environment.allowAdult && channel.adult)
                ) {
                  return false;
                }

                if (this.sessionService.anonymousProfileIsActive()) {
                  return (
                    channel.hasOneOfOttRights(requiredOttRights) ||
                    channel.hasOneOfStbRights(requiredStbRights)
                  );
                }

                return (
                  channel.hasOneOfOttRights(requiredOttRights) ||
                  channel.channelHasRightsForStreamTypes(
                    this.getStbChannelRights(),
                    requiredStbRights,
                  )
                );
              }),
            };
          }),
        );
      }),
    );
  }

  public reloadChannelRights(): Observable<unknown> {
    this.clearCache();
    this.checkCache();

    return this.cachePromise;
  }

  public getStbs(): Observable<Stb[]> {
    this.checkCache();
    return this.cachePromise.pipe(
      map(() => {
        return this.stbsWithRights;
      }),
    );
  }

  public getAllChannelRights(reload = false): Observable<EpgChannelRight[]> {
    if (reload) {
      return this.reloadChannelRights().pipe(
        map(() => {
          return this.ottRights.concat(this.getStbChannelRights());
        }),
      );
    } else {
      this.checkCache();
      return this.cachePromise.pipe(
        map(() => {
          return this.ottRights.concat(this.getStbChannelRights());
        }),
      );
    }
  }

  public getOttChannelRights(reload = false): Observable<EpgChannelRight[]> {
    if (reload) {
      return this.reloadChannelRights().pipe(
        map(() => {
          return this.ottRights;
        }),
      );
    } else {
      this.checkCache();
      return this.cachePromise.pipe(
        map(() => {
          return this.ottRights;
        }),
      );
    }
  }

  public getRecordingStbChannelRights(): Observable<EpgChannelRight[]> {
    this.checkCache();
    return this.cachePromise.pipe(
      map(() => {
        // only local recording stbs their rights
        let recordingStbChannelRights: EpgChannelRight[] = [];
        this.stbsWithRights.forEach((stb) => {
          if (stb.recording === 'LOCAL') {
            recordingStbChannelRights = recordingStbChannelRights.concat(stb.channelRights);
          }
        });
        return recordingStbChannelRights;
      }),
    );
  }

  public getPlayIcon(
    schedule: EpgScheduleSummaryAsset,
    channel: ChannelModel,
    recording: RecordingModel,
    replayStart?,
    onlyRecording = false,
  ): Observable<string> {
    this.checkCache();
    return this.cachePromise.pipe(
      map(() => {
        if (this.sessionService.anonymousProfileIsActive()) {
          return this.playIconAnonymousLogic(
            schedule,
            channel,
            recording,
            replayStart,
            onlyRecording,
          );
        } else {
          return this.playIconLogic(schedule, channel, recording, replayStart, onlyRecording);
        }
      }),
    );
  }

  public reloadZappableChannels(): void {
    this.getValidChannels(false, this.sessionService.getCatalogId())
      .subscribe((validChannels) => {
        const result = validChannels.validChannels.filter((channel) => channel.enabled && !channel.audioOnly && channel.customerHasRight(
          StreamType.LINEAR));

        this.zappableChannelsSubject.next(result);
      });
  }

  public onZappableChannelsRefresh(callback: (channels: ChannelModel[]) => void): Subscription {
    return this.zappableChannelsSubject.subscribe(callback);
  }

  private checkCache(): void {
    // cache not expired
    if (new Date().getTime() > this.expirationTime) {
      this.clearCache();
    }
    this.cachePromise = this.cachePromise || this.fillCache();
  }

  private fillCache(): Observable<unknown> {
    const stbs = this.sessionService.getStbsList().getStbs(false);
    const requests = [];

    if (!this.playerService.remotePlayerConnected) {
      stbs.forEach(stb => {
        requests.push(
          this.epgApi
            .getChannelRights(
              stb.type,
              stb.entitlementId ?? '',
              environment.allowAdult ? AdultMode.any : AdultMode.false,
            )
            .pipe(
              map((result) => {
                stb.channelRights = result;
                this.stbsWithRights.push(stb);
              }),
            ),
        );
      });
    }

    requests.push(
      this.epgApi
        .getChannelRights(
          environment.platform,
          this.sessionService.getEntitlementId(),
          environment.allowAdult ? AdultMode.any : AdultMode.false,
        )
        .pipe(
          map((result) => {
            this.ottRights = result;
          }),
        ),
    );

    if (requests.length === 0) {
      return of([]);
    }

    return forkJoin(requests).pipe(
      map(() => {
        this.expirationTime = new Date().getTime() + this.cacheExpirationTime;
        this.reloadingCache = false;
      }),
      catchError((e) => {
        this.expirationTime = 0;
        this.reloadingCache = false;
        throw e;
      }),
      shareReplay(),
    );
  }

  private setRecordableIcon(channel: ChannelModel): void {
    channel.recordableIcon = '';
    if (environment.atv_feature_list.includes(AtvFeatures.SHOW_RECORDABLE_ICONS)) {
      if (channel.customerHasRight(StreamType.NPVR)) {
        channel.recordableIcon = './assets/theme/svg/recordable.png';
      } else if (
        channel.channelHasRightsForStreamTypes(this.getStbChannelRights(true), [
          StreamType.DVBC,
        ]) ||
        channel.channelHasRightsForStreamTypes(this.getStbChannelRights(false), [StreamType.NPVR])
      ) {
        channel.recordableIcon = './assets/theme/svg/recordable_swipe.png';
      }
    }
  }

  private getStbChannelRights(onlyLocalRecording = false): EpgChannelRight[] {
    let rights = [];
    this.stbsWithRights.forEach((stb) => {
      if (!onlyLocalRecording || stb.recording === 'LOCAL') {
        rights = rights.concat(stb.channelRights);
      }
    });
    return rights;
  }

  private playIconLogic(
    schedule: EpgScheduleSummaryAsset,
    channel: ChannelModel,
    recording: RecordingModel,
    replayStart?: Date,
    onlyRecording = false,
  ): string {
    if (channel === undefined) {
      return '';
    }

    if (replayStart === undefined) {
      replayStart = new Date();
      replayStart.setHours(replayStart.getHours() - channel.replayHours);
    }

    const now = new Date().getTime();
    const schedStart = SharedUtilityService.timeStringToMs(schedule.published.start);
    const schedEnd = SharedUtilityService.timeStringToMs(schedule.published.end);

    if (!onlyRecording
        && schedule
        && schedStart <= now
        && schedStart > replayStart.getTime()
        && channel.customerHasRight(StreamType.REPLAY)
        && !this.epgUtility.scheduleHasBlackout(schedule.blackouts, BlackoutFactory.create(environment.platform, StreamType.REPLAY))) {
      return './assets/theme/svg/flashback.png';
    }

    if (!onlyRecording
        && schedule
        && schedStart <= now
        && schedEnd >= now
        && channel.customerHasRight(StreamType.LINEAR)
        && !this.epgUtility.scheduleHasBlackout(schedule.blackouts, BlackoutFactory.create(environment.platform, StreamType.LINEAR))) {
      return './assets/theme/svg/playable.png';
    }

    if (this.epgUtility.canPlayRecording(recording, schedule, channel)) {
      return './assets/theme/svg/playable.png';
    }

    if (environment.atv_feature_list.includes(AtvFeatures.SHOW_SWIPABLE_ICONS)) {
      if (!onlyRecording && schedule && schedStart <= now) {
        // replay

        let playIcon = '';
        this.stbsWithRights.some((stb) => {
          const stbHasReplayRights = stb.channelRights.some((channelRight) => {
            if (channelRight.id === channel.id) {
              return channelRight.rights.includes(StreamType.REPLAY);
            }
          });

          if (!stbHasReplayRights) {
            return false;
          }

          replayStart = new Date();
          replayStart.setHours(replayStart.getHours() - channel.replayHours);
          if (
            schedStart > replayStart.getTime() &&
            !this.epgUtility.scheduleHasBlackout(schedule.blackouts, BlackoutFactory.create(stb.type, StreamType.REPLAY))
          ) {
            playIcon = './assets/theme/svg/flashback_swipe.png';
            return true;
          }
        });
        if (playIcon !== '') {
          return playIcon;
        }
      }

      if (!onlyRecording && schedule && schedStart <= now && schedEnd >= now) {
        // LIVE
        for (const stb of this.stbsWithRights) {
          const stbRightsForChannel = stb.channelRights.find(cr => cr.id === channel.id);

          if ((stbRightsForChannel?.rights.includes(StreamType.LINEAR) && !this.epgUtility.scheduleHasBlackout(
            schedule.blackouts, BlackoutFactory.create(stb.type, StreamType.LINEAR)))
              || (stbRightsForChannel?.rights.includes(StreamType.DVBC)
                  && !this.epgUtility.scheduleHasBlackout(schedule.blackouts, BlackoutFactory.create(stb.type, StreamType.DVBC)))) {
            // no linear blackout for stb
            return './assets/theme/svg/playable_swipe.png';
          }
        }
      }
      if (environment.atv_feature_list.includes(AtvFeatures.RECORDING) && recording) {
        // NPVR
        const npvr = this.sessionService.getStbsList().getNpvrStb();

        if (npvr && recording.device === npvr.id && (recording.isRecorded() || recording.isInProgress())) {
          for (const stb of this.stbsWithRights) {
            const stbRightsForChannel = stb.channelRights.find(cr => cr.id === channel.id);

            if (stbRightsForChannel?.rights.includes(StreamType.NPVR)
                && !this.epgUtility.scheduleHasBlackout(schedule.blackouts, BlackoutFactory.create(stb.type, StreamType.NPVR))) {
              // no npvr blackout for stb
              return './assets/theme/svg/playable_swipe.png';
            }
          }
        }


        // local
        let playIcon = '';
        this.stbsWithRights.some((stb) => {
          if (recording && recording.canWatchLocal(stb.id)) {
            if (!this.epgUtility.scheduleHasBlackout(schedule.blackouts, BlackoutFactory.create(stb.type, StreamType.DVBC))) {
              // no DVBC blackout for stb
              playIcon = './assets/theme/svg/playable_swipe.png';
              return true;
            }
          }
        });
        return playIcon;
      }
    }
    return '';
  }

  private playIconAnonymousLogic(
    schedule: EpgScheduleSummaryAsset,
    channel: ChannelModel,
    recording: RecordingModel,
    replayStart?: Date,
    onlyRecording = false,
  ): string {
    if (channel === undefined) {
      return '';
    }

    if (replayStart === undefined) {
      replayStart = new Date();
      replayStart.setHours(replayStart.getHours() - channel.replayHours);
    }

    const now = new Date().getTime();
    const schedStart = SharedUtilityService.timeStringToMs(schedule.published.start);
    const schedEnd = SharedUtilityService.timeStringToMs(schedule.published.end);
    if (!onlyRecording && schedule && schedStart <= now && schedStart > replayStart.getTime()) {
      if (channel.hasOneOfOttRights([StreamType.REPLAY])) {
        if (!this.epgUtility.scheduleHasBlackout(schedule.blackouts, BlackoutFactory.create(environment.platform, StreamType.REPLAY))) {
          //  no replay blackout for ott
          return './assets/theme/svg/flashback.png';
        }
      }
    }

    if (!onlyRecording && schedule && schedStart <= now && schedEnd >= now) {
      if (channel.hasOneOfOttRights([StreamType.LINEAR])) {
        if (!this.epgUtility.scheduleHasBlackout(schedule.blackouts, BlackoutFactory.create(environment.platform, StreamType.LINEAR))) {
          // no linear blackout for ott
          return './assets/theme/svg/playable.png';
        }
      }
    }

    return '';
  }
}
