import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { EpgProgramSummaryAsset, EpgScheduleAsset, EpgScheduleSummaryAsset } from '@atv-core/api/epg/epg-api.model';
import { EpgApiService } from '@atv-core/api/epg/epg-api.service';
import { AdultMode } from '@atv-core/services/adult';
import { ChannelModel } from '@atv-core/services/cache/channel/channel.model';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { GuideFocusEvent } from '@atv-pages/guide-page/guide-page.component';
import { environment } from '@env/environment';
import { BehaviorSubject, Subscription } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-channel-schedule',
  templateUrl: './channel-schedule.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./channel-schedule.component.scss'],
})
export class ChannelScheduleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedDate: Date = undefined;
  schedules: EpgScheduleSummaryAsset[] = undefined;
  programsMap: EpgProgramSummaryAsset[] = [];
  totalHours = 24;
  widthPerHour = environment.atv_guide_width_per_hour;
  @Input() channel: ChannelModel;
  @ViewChild('scheduleGrid', { static: true }) scheduleGrid: ElementRef<HTMLDivElement>;

  @Input() allowedAdultMode: AdultMode;
  @Input() scheduleItemIntersectionObserver: IntersectionObserver;
  @Input() channelSchedulesIntersectionObserver: IntersectionObserver;

  @Input() categoryTags: string[];
  @Input() guideFocusEvent?: EventEmitter<GuideFocusEvent>;
  @Input() guideScrollEvent?: BehaviorSubject<number>;

  private initialFetchDone = false;
  private scheduleSubscription: Subscription;

  public isSmartTv = SharedUtilityService.isSmartTv();
  private currentFocus: GuideFocusEvent;
  private focusAfterFetch = false;

  constructor(
    private epgApiService: EpgApiService,
    private cdr: ChangeDetectorRef,
    private spatialNavigationService: SpatialNavigationService,
  ) {
  }

  ngOnInit(): void {
    this.channelSchedulesIntersectionObserver.observe(this.scheduleGrid.nativeElement);

    if (this.guideFocusEvent && this.isSmartTv) {
      this.guideFocusEvent.subscribe((e: GuideFocusEvent) => {
        this.currentFocus = e;
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDate) {
      // total hours can change on DST day
      this.totalHours = moment.duration(moment(this.selectedDate).endOf('day').diff(moment(this.selectedDate).startOf('day'))).as('hours');
    }
  }

  ngOnDestroy(): void {
    this.channelSchedulesIntersectionObserver.unobserve(this.scheduleGrid.nativeElement);
  }

  update(result: EpgScheduleAsset): void {
    this.schedules = result.schedules;
    if (result.programs) {
      result.programs.forEach((program) => {
        this.programsMap[program.id] = program;
      });
    }

    this.cdr.detectChanges();

    if (this.focusAfterFetch) {
      this.focusCurrentSchedule();
      this.focusAfterFetch = false;
    }

    if (this.isSmartTv) {
      this.spatialNavigationService.unregister(NavigationSections.SCHEDULES);
      this.spatialNavigationService.register(
        NavigationSections.SCHEDULES,
        `app-schedule div.schedule`,
        {
          restrict: 'self-only',
          enterTo: 'default-element',
          defaultElement: `app-schedule div.schedule.last-active, app-schedule div.schedule.entry`,
          straightOnly: false,
          straightOverlapThreshold: 0,
          leaveFor: {
            left: `@${NavigationSections.MENU}`,
            right: '',
            up: `@${NavigationSections.FILTERS}`,
          },
          navigableFilter: (element: HTMLDivElement) => {
            if (
              this.currentFocus &&
              element.getAttribute('channel') === this.currentFocus.channel
            ) {
              // always allow same channel
              return true;
            }

            let offsetLeft = this.getCurrentOffsetLeft();
            if (this.currentFocus?.currentOffsetLeft) {
              offsetLeft = this.currentFocus.currentOffsetLeft;
            } else {
              const now = new Date();
              const hours = now.getHours() + now.getMinutes() / 60 + now.getSeconds() / 3600;

              offsetLeft = hours * environment.atv_guide_width_per_hour;
            }
            const elementLeft = element.offsetLeft;
            const elementWidth = element.offsetWidth;

            return offsetLeft >= elementLeft && offsetLeft < elementLeft + elementWidth;
          },
        },
      );
    }
  }

  private getCurrentOffsetLeft(): number {
    if (this.currentFocus && this.currentFocus.currentOffsetLeft) {
      return this.currentFocus.currentOffsetLeft;
    } else {
      const now = new Date();
      const hours = now.getHours() + now.getMinutes() / 60 + now.getSeconds() / 3600;

      return hours * environment.atv_guide_width_per_hour;
    }
  }

  fetchSchedulesIfInView(): void {
    if (
      !this.selectedDate ||
      !this.scheduleGrid.nativeElement.classList.contains('in-view') ||
      this.initialFetchDone
    ) {
      if (this.focusAfterFetch && this.schedules) {
        this.focusCurrentSchedule();
        this.focusAfterFetch = false;
      }

      return;
    }

    const f = this.selectedDate;
    const u = new Date(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth(),
      this.selectedDate.getDate() + 1,
    );
    this.initialFetchDone = true;

    if (this.scheduleSubscription) {
      this.scheduleSubscription.unsubscribe();
    }

    this.scheduleSubscription = this.epgApiService
      .getScheduleForChannelFromUntil(this.channel.id, f, u)
      .subscribe((data) => {
        this.update(data);
      });
  }

  dateChange(): void {
    this.clear();
    this.fetchSchedulesIfInView();
  }

  clear(): void {
    this.initialFetchDone = false;
    this.schedules = [];
    this.programsMap = [];
    this.cdr.detectChanges();
  }

  public zapTo(): void {
    if (!this.scheduleGrid.nativeElement.classList.contains('in-view')) {
      this.scheduleGrid.nativeElement.classList.add('in-view');
      this.focusAfterFetch = true;
      this.fetchSchedulesIfInView();
    } else {
      this.focusCurrentSchedule();
    }
  }

  private focusCurrentSchedule(): void {
    const offsetLeft = this.getCurrentOffsetLeft();
    const schedules = this.scheduleGrid.nativeElement.querySelectorAll('app-schedule div.schedule');
    schedules.forEach((s: HTMLDivElement) => {
      const elementLeft = s.offsetLeft;
      const elementWidth = s.offsetWidth;

      if (offsetLeft >= elementLeft && offsetLeft < elementLeft + elementWidth) {
        s.classList.add('in-view');

        if (!this.currentFocus) {
          this.currentFocus = { channel: this.channel.id };
        } else {
          this.currentFocus.channel = this.channel.id;
        }

        this.spatialNavigationService.setFocus(s);
      }
    });
  }
}
