import moment from 'moment';

export interface RecordingContainer {
  subscriptions: SubscriptionContainer;
  recordings?: Recording[];
  status: DeviceStatus;
}

export interface SubscriptionContainer {
  seriesSubscriptions?: SeriesSubscription[];
  seasonSubscriptions?: SeasonSubscription[];
  programSubscriptions?: ProgramSubscription[];
}

export class Recording {
  identifier: string = undefined;
  device: string = undefined;
  version?: number = undefined;
  schedule: string = undefined;
  program: string = undefined;
  programOrdinal?: number = undefined;
  season?: string = undefined;
  seasonOrdinal?: number = undefined;
  series?: string = undefined;
  channel: string = undefined;
  start: string = undefined;
  end: string = undefined;
  plannedStart: string = undefined;
  plannedEnd: string = undefined;
  effectiveStart?: string = undefined;
  effectiveEnd?: string = undefined;
  beginBuffer?: number = undefined;
  endBuffer?: number = undefined;
  deleteProtected?: boolean = undefined;
  infernoIdentifier?: string = undefined;
  infernoState?: string = undefined;
  infernoFailureReason?: string = undefined;
  infernoSize?: number = undefined;
  programSubscription?: string = undefined;
  seasonSubscription?: string = undefined;
  seriesSubscription?: string = undefined;
  conflicts?: string[] = undefined;
  adult?: boolean = undefined;
  expiration?: string = undefined;

  constructor(obj: Recording) {
    Object.keys(this).forEach((key) => {
      this[key] = obj[key];
    });

    this.effectiveEnd = this.effectiveEnd
      ? moment(parseInt(this.effectiveEnd) * 1000)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined;
    this.effectiveStart = this.effectiveStart
      ? moment(parseInt(this.effectiveStart) * 1000)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined;
    this.plannedEnd = this.plannedEnd
      ? moment(parseInt(this.plannedEnd) * 1000)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined;
    this.plannedStart = this.plannedStart
      ? moment(parseInt(this.plannedStart) * 1000)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined;
    this.start = this.start
      ? moment(parseInt(this.start) * 1000)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined;
    this.end = this.end
      ? moment(parseInt(this.end) * 1000)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined;
    this.expiration = this.expiration
      ? moment(parseInt(this.expiration) * 1000)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      : undefined;
  }
}

export interface DeviceStatus {
  npvr?: NpvrDeviceStatus;
  stb?: StbDeviceStatus;
}

export class SeriesSubscription implements Subscription {
  identifier: string;
  device: string;
  version?: number;
  channel: string;
  excludedSchedules?: string[];
  beginBuffer?: number;
  endBuffer?: number;
  deleteProtected?: boolean;
  series: string;
  excludedSeasons?: string[];
  excludedPrograms?: string[];

  constructor(sub) {
    Object.keys(sub).forEach((key) => (this[key] = sub[key]));
  }
}

export interface Subscription {
  identifier: string;
  device: string;
  version?: number;
  channel: string;
  excludedSchedules?: string[];
  beginBuffer?: number;
  endBuffer?: number;
  deleteProtected?: boolean;
}

export class SeasonSubscription implements Subscription {
  identifier: string;
  device: string;
  version?: number;
  season: string;
  channel: string;
  beginBuffer?: number;
  endBuffer?: number;
  deleteProtected?: boolean;
  excludedPrograms?: string[];
  excludedSchedules?: string[];
  constructor(sub) {
    Object.keys(sub).forEach((key) => (this[key] = sub[key]));
  }
}
export class ProgramSubscription implements Subscription {
  identifier: string;
  device: string;
  version?: number;
  program: string;
  channel: string;
  beginBuffer?: number;
  endBuffer?: number;
  deleteProtected?: boolean;
  excludedSchedules?: string[];

  constructor(sub) {
    Object.keys(sub).forEach((key) => (this[key] = sub[key]));
  }
}
interface NpvrDeviceStatus {
  maximumHours?: number;
  usedHours?: number;
}

interface StbDeviceStatus {
  maximumSize?: number;
  usedSize?: number;
}

export interface RecordingOptions {
  beginBuffer?: number;
  endBuffer?: number;
  deleteProtected?: boolean;
}

export interface DeleteRecordingsQueryParams {
  states: RecordingStates[];
  programs?: string[];
  seasons?: string[];
  series?: string[];
  channels?: string[];
}

export enum RecordingStates {
  scheduled = 'SCHEDULED',
  recording = 'RECORDING',
  recorded = 'RECORDED',
}
