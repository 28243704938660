import { Injectable } from '@angular/core';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { CmsContentProvider } from '@atv-shared/content-provider/providers/cms-content-provider';
import { ContentProvider } from '@atv-shared/content-provider/content-provider';
import {
  CmsActionMethod,
  CmsApiService,
  CmsContentTypes,
  Ribbon2Asset,
  Ribbon2Channel,
  Ribbon2Detail,
  Ribbon2Schedule,
} from '@atv-core/api/cms';
import { SessionService } from '@atv-core/services/session';
import { BookmarkCacheContentProvider } from '@atv-shared/content-provider/providers/bookmark-cache-content-provider';
import { BookmarkCacheService } from '@atv-core/services/cache/bookmark';
import { forkJoin, Observable, of } from 'rxjs';
import {
  EpgApiService,
  EpgProgramSummaryAsset,
  EpgScheduleAsset,
  EpgScheduleDetailAsset,
  EpgScheduleItem,
  EpgSeasonSummaryAsset,
} from '@atv-core/api/epg';
import { catchError, map } from 'rxjs/operators';
import { EpgUtilityService, FromUntil } from '@atv-core/utility/epg-utility/epg-utility.service';
import { FavoriteCacheService } from '@atv-core/services/cache/favorite';
import { FavoriteCacheContentProvider } from '@atv-shared/content-provider/providers/favorite-cache-content-provider';
import { ChannelCacheService } from '@atv-core/services/cache/channel';
import { VodApiService, VodAssetSummary, VodAssetTypes } from '@atv-core/api/vod';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { RecordingModel } from '@atv-core/services/cache/stb/recording.model';
import { RecordingPageTypes } from '@atv-core/services/cache/stb';
import { FocusType } from '@atv-core/utility/constants/shared';

export interface RecordingGroup {
  main: RecordingModel;
  others: RecordingModel[];
}

@Injectable({
  providedIn: 'root',
})
export class ContentProviderFactoryService {
  private readonly fromUntil: FromUntil;

  constructor(
    private cmsApiService: CmsApiService,
    private sessionService: SessionService,
    private adultService: AdultService,
    private bookmarkCacheService: BookmarkCacheService,
    private favoriteCacheService: FavoriteCacheService,
    private epgApiService: EpgApiService,
    private epgUtilityService: EpgUtilityService,
    private channelCacheService: ChannelCacheService,
    private vodApiService: VodApiService,
  ) {
    this.fromUntil = this.epgUtilityService.getEpgDaysFromUntil();
  }

  public createContentProvider(url: string, allowedAdultMode: AdultMode, focusType: FocusType): ContentProvider {
    if (!url) {
      // TODO backup content?
    } else if (url.startsWith('nebula-cache://bookmark/')) {
      return new BookmarkCacheContentProvider(url, this.bookmarkCacheService, allowedAdultMode, this.adultService, this);
    } else if (url.startsWith('nebula-cache://favorite/')) {
      return new FavoriteCacheContentProvider(url, this.favoriteCacheService, allowedAdultMode, this.adultService, this,
        this.channelCacheService);
    } else {
      return new CmsContentProvider(this.cmsApiService, url, focusType, this.sessionService, this.adultService, allowedAdultMode);
    }
  }

  public programToRibbon2Asset(id: string): Observable<Ribbon2Asset> {
    return this.epgApiService.getProgram(id, this.fromUntil.from, this.fromUntil.until).pipe(map(program => ({
      asset: {
        type: CmsContentTypes.PROGRAM,
        id: program.program.id,
        season: program.season?.season,
        episode: program.program.episode,
        image: program.program.posterImage,
        title: program.program.title,
        adult: program.program.adult,
        synopsis: program.program.longSynopsis,
        productionYear: program.program.productionYear,
        minimumAge: program.program.minimumAge,
        genres: program.program.genres,
        episodeTitle: program.program.episodeTitle,
      },
      method: CmsActionMethod.DETAIL,
      channels: this.createChannelsInfo(program.schedules),
    })));
  }

  public vodToRibbon2Asset(id: string): Observable<Ribbon2Asset> {
    return this.vodApiService.getVodDetail(id)
      .pipe(map(
        vod => ({
          asset: {
            type: CmsContentTypes.VOD,
            subType: vod.type,
            id: vod.id,
            season: vod.season,
            episode: vod.episode,
            image: vod.posterImage,
            title: vod.title,
            adult: vod.adult,
            synopsis: vod.longSynopsis,
            productionYear: vod.productionYear,
            minimumAge: vod.minimumAge,
            score: vod.score,
            operatorScore: vod.operatorScore,
            resolution: SharedUtilityService.getHighestResolution(vod.streams),
            genres: vod.genres,
            duration: vod.duration,
          },
          method: CmsActionMethod.DETAIL,
          resources: this.createVodResources(vod.seasons, vod.episodes),
        })));
  }

  public channelToRibbon2Asset(id: string, fetchSchedules: boolean): Observable<Ribbon2Asset> {
    return forkJoin([
      this.channelCacheService.getChannels(),
      fetchSchedules ? this.epgApiService.getScheduleForChannelFromUntil(id, this.fromUntil.from, this.fromUntil.until) :
        of(undefined as EpgScheduleAsset),
    ])
      .pipe(
        map(result => {
          const channel = result[0].find(c => c.id === id);
          return {
            asset: {
              type: CmsContentTypes.CHANNEL,
              id: channel.id,
              image: channel.defaultLogo,
              title: channel.name,
              adult: channel.adult,
              minimumAge: channel.minimumAge,
              resolution: channel.resolution,
              channelNumber: channel.getChannelNumberForCatalog(this.sessionService.getCatalogId()),
            },
            method: CmsActionMethod.DETAIL,
            channels: fetchSchedules ? this.createChannelsInfo(
              result[1]?.schedules?.map(s =>
                ({ id: s.id, program: s.program, channel: id, blackouts: s.blackouts, live: s.live, published: s.published }),
              ),
            ) : undefined,
            resources: fetchSchedules ? this.createEpgResources(result[1].programs, result[1].seasons) : undefined,
          };
        }),
      );
  }

  public seriesToRibbon2Asset(id: string): Observable<Ribbon2Asset> {
    return this.epgApiService.getSeries(id, this.fromUntil.from, this.fromUntil.until).pipe(map(series => ({
      asset: {
        type: CmsContentTypes.SERIES,
        id: series.series.id,
        image: series.series.posterImage,
        title: series.series.title,
        adult: series.series.adult,
        synopsis: series.series.longSynopsis,
      },
      method: CmsActionMethod.DETAIL,
      channels: this.createChannelsInfo(series.schedules),
      resources: this.createEpgResources(series.programs, series.seasons),
    })));
  }

  public createEpgResources(programs?: EpgProgramSummaryAsset[], seasons?: EpgSeasonSummaryAsset[]): Ribbon2Detail[] {
    const result: Ribbon2Detail[] = [];

    programs?.forEach(program =>
      result.push(this.programToRibbon2Detail(program, seasons)),
    );

    return result;
  }

  public programToRibbon2Detail(program: EpgProgramSummaryAsset, seasons?: EpgSeasonSummaryAsset[]): Ribbon2Detail {
    return {
      type: CmsContentTypes.PROGRAM,
      id: program.id,
      season: seasons?.find(s => s.id === program.season)?.season,
      episode: program.episode,
      image: program.posterImage,
      title: program.title,
      adult: program.adult,
      synopsis: program.shortSynopsis,
      genres: program.genres,
      episodeTitle: program.episodeTitle,
    };
  }

  public createChannelsInfo(schedules: EpgScheduleDetailAsset[]): Ribbon2Channel[] {
    const channels = new Set(schedules.map(s => s.channel));
    const result: Ribbon2Channel[] = [];

    channels.forEach(channel => {
      const s: Ribbon2Schedule[] = schedules.filter(schedule => schedule.channel === channel)
        .map(schedule => ({ id: schedule.id, programId: schedule.program, published: schedule.published, blackouts: schedule.blackouts }));
      result.push({ id: channel, schedules: s });
    });
    return result;
  }

  public scheduleToRibbon2Asset(schedule: EpgScheduleItem): Ribbon2Asset {
    return {
      asset: {
        type: CmsContentTypes.SCHEDULE,
        id: schedule.schedule.id,
        image: schedule.program.posterImage,
      },
      method: CmsActionMethod.DETAIL,
      channels: this.createChannelsInfo([schedule.schedule]),
      resources: [this.programToRibbon2Detail(schedule.program, [schedule.season])],
    };
  }

  public recordingFolderToRibbon2Asset(recording: RecordingModel, relatedRecordings: RecordingModel[]): Observable<Ribbon2Asset> {
    return this.epgApiService.getSchedule(recording.schedule).pipe(map(schedule => ({
        asset: {
          type: CmsContentTypes.RECORDING,
          id: schedule.series && relatedRecordings.length > 0 ? schedule.series.id : recording.identifier,
          image: schedule.program.posterImage,
          title: schedule.series?.title,
        },
        method: CmsActionMethod.DETAIL,
        channels: this.createChannelsInfo([schedule.schedule]),
        resources: this.createRecordingResources(relatedRecordings, schedule.program),
      }),
    ), catchError(() => this.recordingFallbackProgram(recording, relatedRecordings)));
  }

  public recordingToRibbon2Asset(recording: RecordingModel, pageType: RecordingPageTypes): Observable<Ribbon2Asset> {
    return this.epgApiService.getSchedule(recording.schedule).pipe(map(schedule => ({
        asset: {
          type: CmsContentTypes.RECORDING,
          id: recording.identifier,
          image: schedule.program.posterImage,
          title: pageType === RecordingPageTypes.expires ? schedule.series.title : '',
          episode: recording.programOrdinal,
        },
        method: CmsActionMethod.DETAIL,
        channels: this.createChannelsInfo([schedule.schedule]),
        resources: [this.programToRibbon2Detail(schedule.program, [schedule.season])],
      }),
    ), catchError(() => this.recordingFallbackProgram(recording)));
  }

  private createVodResources(seasons?: VodAssetSummary[], episodes?: VodAssetSummary[]): Ribbon2Detail[] {
    const result: Ribbon2Detail[] = [];

    seasons?.forEach(
      season => result.push(
        { type: CmsContentTypes.VOD, id: season.id, subType: VodAssetTypes.SEASON, title: season.title, image: season.posterImage }));

    episodes?.forEach(episode => result.push(
      { type: CmsContentTypes.VOD, subType: VodAssetTypes.ASSET, id: episode.id, title: episode.title, image: episode.posterImage }));

    return result;
  }

  private createRecordingResources(recordings: RecordingModel[], scheduleProgram?: EpgProgramSummaryAsset): Ribbon2Detail[] {
    const result: Ribbon2Detail[] = [];

    if (scheduleProgram) {
      result.push(this.programToRibbon2Detail(scheduleProgram));
    }
    if (recordings) {
      recordings.forEach(recording => result.push({
        type: CmsContentTypes.RECORDING,
        id: recording.identifier,
        image: '',
      }));
    }

    return result;
  }

  private recordingFallbackProgram(recording: RecordingModel, relatedRecordings?: RecordingModel[])
    : Observable<Ribbon2Asset> {
    const fromUntil = this.epgUtilityService.getEpgDaysFromUntil();
    return this.epgApiService.getProgram(recording.program, fromUntil.from, fromUntil.until)
      .pipe(map(result => {
          return {
            asset: {
              type: CmsContentTypes.RECORDING,
              id: relatedRecordings ? recording.series : recording.identifier,
              image: result.program.posterImage,
              title: relatedRecordings ? result.series?.title : result.program.title,
              episode: recording.programOrdinal,
            },
            method: CmsActionMethod.DETAIL,
            channels: [{
              id: recording.channel, schedules: [{
                programId: result.program.id,
                published: { start: recording.effectiveStart, end: recording.effectiveEnd },
              }],
            }],
            resources: this.createRecordingResources(relatedRecordings, result.program),
          };
        }),
      );
  }

  public groupRecordingsBySeries(recordings: RecordingModel[]): RecordingGroup[] {
    const result: RecordingGroup[] = [];

    recordings.forEach((recording) => {
      const topItem = result.find(
        (item) =>
          item.main.series !== undefined &&
          item.main.series === recording.series &&
          item.main.channel !== undefined &&
          item.main.channel === recording.channel,
      );
      if (topItem) {
        topItem.others.push(recording);
      } else {
        result.push({
            main: recording,
            others: []
          },
        );
      }
    });

    return result;
  }
}
