import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AvatarElement, Catalog, ProfileElement, ProfileElementData } from '@atv-core/api/aa/aa-api.model';
import { AaApiService } from '@atv-core/api/aa/aa-api.service';
import { ConfigService, ErrorTranslationKeys, SettingsKeys } from '@atv-bootstrap/services/config';
import { CatalogTranslationKeys } from '@atv-bootstrap/services/config/config.model';
import { MessagesService } from '@atv-core/services/messages/messages.service';
import { ProfileService } from '@atv-core/services/profile/profile.service';
import { SessionService } from '@atv-core/services/session';
import { ImageRecipe, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';

export class CreateEditProfileModel {
  name: string;
  avatarId: string;
  catalogId: string;

  availableAvatars: AvatarElement[];
  availableCatalogs: Catalog[];
  ready = false;

  isMaster = false;
  isEdit = false;
  editProfileId: string;

  constructor(avatars: AvatarElement[], catalogs: Catalog[], editProfile?: ProfileElement) {
    this.availableAvatars = avatars;
    this.availableCatalogs = catalogs;

    if (editProfile) {
      this.isEdit = true;
      this.isMaster = editProfile.master;
      this.editProfileId = editProfile.id;

      this.avatarId = editProfile.profile.avatar;
      this.name = editProfile.profile.name;
      this.catalogId = editProfile.profile.catalog;
    } else {
      this.avatarId = this.availableAvatars[0].id;
      this.catalogId = catalogs[0].id;
    }

    this.ready = true;
  }
}

@Component({
  selector: 'app-manage-profiles',
  templateUrl: './manage-profiles.component.html',
  styles: [],
})
export class ManageProfilesComponent implements OnInit {
  public maxProfileCount = 0;
  public maxProfileNameLength = 25;

  @Input()
  createEditProfile: CreateEditProfileModel;

  @Output()
  backToSwitch = new EventEmitter();

  public profileCreateButton = '';
  public profileSaveButton = '';
  public profileCancelButton = '';
  public profilePropertyAvatar = '';
  public profilePropertyCatalog = '';
  public profilePropertyName = '';
  public profileEditTitle = '';
  public profileAddTitle = '';

  constructor(
    private aaApi: AaApiService,
    private config: ConfigService,
    private messagesService: MessagesService,
    private sessionService: SessionService,
    private profileService: ProfileService
  ) {
    this.maxProfileCount = this.config.getSettingNumber(SettingsKeys.maxAllowedProfiles, 0);
    this.maxProfileNameLength = this.config.getSettingNumber(SettingsKeys.maxLengthProfileName, 25);

    this.profileAddTitle = this.config.getTranslation(CatalogTranslationKeys.profile_add_title);
    this.profileEditTitle = this.config.getTranslation(CatalogTranslationKeys.profile_edit_title);
    this.profilePropertyName = this.config.getTranslation(
      CatalogTranslationKeys.profile_property_name
    );
    this.profilePropertyAvatar = this.config.getTranslation(
      CatalogTranslationKeys.profile_property_avatar
    );
    this.profilePropertyCatalog = this.config.getTranslation(
      CatalogTranslationKeys.profile_property_catalog
    );
    this.profileCancelButton = this.config.getTranslation(
      CatalogTranslationKeys.profile_cancel_btn
    );
    this.profileCreateButton = this.config.getTranslation(
      CatalogTranslationKeys.profile_create_btn
    );
    this.profileSaveButton = this.config.getTranslation(CatalogTranslationKeys.profile_save_btn);
  }

  ngOnInit(): void {}

  public cancel(): void {
    this.backToSwitch.emit();
  }

  public createEdit(): void {
    if (this.createEditProfile) {
      if (!this.profileService.isNameValid(this.createEditProfile.name)) {
        return;
      }

      const profile: ProfileElementData = {
        name: this.createEditProfile.name,
        avatar: this.createEditProfile.avatarId,
        catalog: this.createEditProfile.catalogId,
      };

      let request;
      if (this.createEditProfile.isEdit) {
        request = this.aaApi.editProfile(this.createEditProfile.editProfileId, profile);

        const catalogChanged = this.sessionService.getCatalogId() !== profile.catalog;

        // IF CURRENT ACTIVE PROFILE IS EDITED IT SHOULD BE UPDATE
        if (this.sessionService.getActiveProfileId() === this.createEditProfile.editProfileId) {
          // if catalog of current active profile wa edited

          this.sessionService.updateActiveProfile(
            profile,
            this.createEditProfile.availableAvatars.find(
              (avatar) => avatar.id === this.createEditProfile.avatarId
            )
          );
          if (catalogChanged) {
            this.sessionService.reset(true);
            this.sessionService.catalogChangedEvent.emit();
          }
        }
      } else {
        request = this.aaApi.createProfile(profile);
      }

      this.createEditProfile = undefined;
      request.subscribe(
        () => {
          this.backToSwitch.emit();
        },
        (errorResponse: HttpErrorResponse) => {
          this.messagesService.showErrorMessage(
            this.config.getTranslation(
              errorResponse && errorResponse.error ? errorResponse.error.code : undefined,
              ErrorTranslationKeys.error_general
            )
          );

          this.backToSwitch.emit();
        }
      );
    }
  }

  public delete(): void {
    if (this.createEditProfile) {
      this.aaApi.deleteProfile(this.createEditProfile.editProfileId).subscribe(
        () => {
          this.createEditProfile = undefined;
          this.backToSwitch.emit();
        },
        (httpError: HttpErrorResponse) => {
          const message = this.config.getTranslation(
            httpError && httpError.error ? httpError.error.code : undefined,
            ErrorTranslationKeys.error_general,
          );

          this.messagesService.showErrorMessage(message);
          this.backToSwitch.emit();
        }
      );
    }
  }

  public getAvatarImage(avatar: AvatarElement): string {
    return ImageRecipeUtil.createImageUrl(avatar.image, ImageRecipe.AVATAR_2);
  }
}
