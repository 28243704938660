import { PlayerService } from '@atv-core/services/player/player.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { BehaviorSubject, Subscription, timer } from 'rxjs';

export class SeekbarModel {
  private beginBuffer = 0;

  private seekEnabled = true;
  private showSkip = false;
  private skipTime = 0;

  public positionSubject = new BehaviorSubject<number>(null);
  public durationSubject = new BehaviorSubject<number>(null);

  private seekDirection = 0; // rewind = -1, not seeking = 0, fast-forward = 1
  private seekRateIndex = -1;
  private seekRates = [2, 4, 8, 16, 32, 64];
  private interval: Subscription = null;
  private seekCursor = 0;

  constructor(private playerService: PlayerService) {}

  public reset(): void {
    this.beginBuffer = 0;
    this.seekEnabled = true;
    this.skipTime = 0;
    this.positionSubject.next(0);
    this.positionSubject.next(0);
  }

  public setBeginBuffer(buffer: number): void {
    this.beginBuffer = buffer;
  }

  public getBeginBuffer(): number {
    return this.beginBuffer;
  }

  public enableAdMode(skipTime: number): void {
    this.seekEnabled = false;
    if (skipTime !== undefined) {
      this.showSkip = true;
      this.skipTime = skipTime / 1000;
    }
  }

  public disableAdMode(): void {
    this.seekEnabled = true;
    this.showSkip = false;
    this.skipTime = 0;
  }

  public isSkipShown(): boolean {
    return this.showSkip;
  }

  public getProgressPercentWithDiff(percent: number, amount: number): number {
    return Math.max(
      Math.min(
        (((percent / 100) * this.durationSubject.value + amount) / this.durationSubject.value ||
          0) * 100,
        100
      ),
      0
    );
  }

  public disableSeek(): void {
    this.seekEnabled = false;
  }

  public getSkipTime(): number {
    return this.skipTime;
  }

  public getRemainingSkipTime(): number {
    return Math.round(Math.max(this.skipTime - this.positionSubject.value, 0));
  }

  public getProgressPercentage(): number {
    return Math.max(
      Math.min((this.positionSubject.value / this.durationSubject.value || 0) * 100, 100),
      0
    );
  }

  public getProgressTime(): string {
    return `${SharedUtilityService.secondsToHHMMSS(
      this.getPosition() - this.beginBuffer / 1000
    )} / ${SharedUtilityService.secondsToHHMMSS(
      Math.max(this.durationSubject.value - this.beginBuffer / 1000, 0)
    )}`;
  }

  public getPosition(): number {
    return this.positionSubject.value;
  }

  public setPosition(position: number): void {
    this.positionSubject.next(position);
  }

  public getDuration(): number {
    return this.durationSubject.value;
  }

  public setDuration(duration: number): void {
    this.durationSubject.next(duration);
  }

  public getTimeFromProgressPercentage(progress: number): string {
    return SharedUtilityService.secondsToHHMMSS(
      (this.durationSubject.value / 100) * progress - this.beginBuffer / 1000
    );
  }

  public isSeekEnabled(): boolean {
    return this.seekEnabled;
  }

  public fastForward(): void {
    if (!this.isSeekEnabled()) {
      return;
    }

    if (!this.playerService.isPaused()) {
      this.playerService.pause();
    }

    if (this.seekDirection < 1) {
      this.seekRateIndex = -1;
    }
    this.seekDirection = 1;
    this.seekRateIndex = (this.seekRateIndex + 1) % this.seekRates.length;

    if (!this.interval) {
      this.startSeeking();
    }
  }

  public rewind(): void {
    if (!this.playerService.seekbar.isSeekEnabled()) {
      return;
    }

    if (!this.playerService.isPaused()) {
      this.playerService.pause();
    }
    if (this.seekDirection > -1) {
      this.seekRateIndex = -1;
    }
    this.seekDirection = -1;
    this.seekRateIndex = (this.seekRateIndex + 1) % this.seekRates.length;

    if (!this.interval) {
      this.startSeeking();
    }
  }

  private startSeeking(): void {
    let previousTimestamp = new Date().getTime();
    this.seekCursor = this.getProgressPercentage();

    this.interval = timer(0, 300).subscribe(() => {
      const timestamp = new Date().getTime();
      const d =
        ((timestamp - previousTimestamp) *
          this.seekDirection *
          this.seekRates[this.seekRateIndex]) /
        1000;
      this.seekCursor = this.getProgressPercentWithDiff(this.seekCursor, d);

      this.positionSubject.next((this.seekCursor / 100) * this.getDuration());

      previousTimestamp = timestamp;
    });
  }

  public stopSeeking(): void {
    this.seekRateIndex = -1;
    this.seekDirection = 0;
    if (this.interval) {
      this.interval.unsubscribe();
      this.interval = null;

      this.playerService.seekAbsolute(this.seekCursor);
    }
    this.seekCursor = 0;
  }

  public isSeeking(): boolean {
    return this.seekDirection !== 0;
  }

  public getSeekDirection(): number {
    return this.seekDirection;
  }

  public getSeekrate(): number {
    return this.seekRates[this.seekRateIndex];
  }
}
