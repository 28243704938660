export enum VideoEvent {
  LOADEDMETADATA = 'loadedmetadata',
  ENDED = 'ended',
  PAUSE = 'pause',
  PLAYING = 'playing',
  TIMEUPDATE = 'timeupdate',
  ERROR = 'error',
  AIRPLAY_CASTING_STARTED = 'airplay-casting-started',
  AIRPLAY_CASTING_ENDED = 'airplay-casting-ended',
}
