<div class="settings-page-wrapper left-right-page-padding" [class.hidden]="!isAuthorized">
  <div [class.hidden]="!subscriptionSettings1 || !npvrStb" class="setting network">
    <div class="setting-title">{{ settingNetworkRecordingTitle }}</div>
    <app-subscription-settings
      #ssc1
      class="button-config-content"
      [settings]="subscriptionSettings1"
      [info]="subscriptionInfo1"
      leaveForDown=".setting.network .setting-confirm"
    ></app-subscription-settings>
    <button (click)="saveNetworkRecordingSettings()"
            [attr.data-sn-down]="ssc2?.getFirstSection() || '#reminder-times .chip.selected'"
            [attr.data-sn-up]="ssc1.getLastSection()"
            class="setting-confirm button-title">
      {{ saveText }}
    </button>
  </div>
  <div [class.hidden]="!subscriptionSettings2 || !stbs || stbs.length < 2" class="setting device">
    <div class="setting-title">{{ settingDefaultDeviceTitle }}</div>
    <app-subscription-settings
      #ssc2
      class="button-config-content"
      [settings]="subscriptionSettings2"
      [info]="subscriptionInfo2"
      leaveForDown=".setting.device .setting-confirm"
      leaveForUp=".setting.network .setting-confirm"
    ></app-subscription-settings>
    <button (click)="saveDefaultDeviceSetting()" [attr.data-sn-up]="ssc2.getLastSection()"
            class="setting-confirm button-title"
            data-sn-down="#reminder-times .chip.selected">
      {{ saveText }}
    </button>
  </div>
  <div class="setting" *ngIf="!isSmartTv">
    <div class="setting-title">{{ settingReminderTitle }}</div>
    <table>
      <tbody>
        <tr>
          <td class="setting-input-title">{{ settingReminderBeforeTitle }}</td>
          <td>
            <select [(ngModel)]="selectedReminderBeforeTime">
              <option *ngFor="let beforeTime of reminderBeforeTimes" [ngValue]="beforeTime.value">
                {{ beforeTime.name }}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
    <button class="setting-confirm button-title" (click)="saveReminderSetting()">
      {{ saveText }}
    </button>
  </div>

  <div *ngIf="isSmartTv" class="setting" id="reminder-times">
    <div class="setting-title">{{ settingReminderTitle }}</div>
    <label>{{ settingReminderBeforeTitle }}</label>
    <div class="chip-container">
      <div
        class="chip"
        (keyup)="inputKeyEvent($event)"
        *ngFor="let beforeTime of reminderBeforeTimes"
        [class.selected]="selectedReminderBeforeTime === beforeTime.value"
        (focus)="selectedReminderBeforeTime = beforeTime.value"
        [attr.data-sn-up]="subscriptionSettings2 && stbs?.length > 1 ? '.setting.device .setting-confirm' : '.setting.network .setting-confirm'"
        data-sn-down="#reminder-times .setting-confirm"
      >
        {{ beforeTime.name }}
      </div>
    </div>
    <button (click)="saveReminderSetting()" class="setting-confirm button-title"
            data-sn-up="#reminder-times .chip.selected">
      {{ saveText }}
    </button>
  </div>
</div>
