import { ContentProvider } from '@atv-shared/content-provider/content-provider';
import { forkJoin, Observable } from 'rxjs';
import { CardOrientation, Ribbon2Asset } from '@atv-core/api/cms';
import { BookmarkContentTypes } from '@atv-core/api/history';
import { BookmarkModel, BookmarkQueryTypes } from '@atv-core/services/cache/bookmark/bookmark.model';
import { BookmarkCacheService } from '@atv-core/services/cache/bookmark';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { switchMap, tap } from 'rxjs/operators';
import { ContentProviderFactoryService } from '@atv-shared/content-provider/content-provider-factory.service';

export class BookmarkCacheContentProvider extends ContentProvider {
  private readonly type: BookmarkContentTypes;
  private readonly queryType: BookmarkQueryTypes;
  private hasMoreItems = true;

  constructor(
    url: string,
    private bookmarkCacheService: BookmarkCacheService,
    private allowedAdultMode: AdultMode,
    private adultService: AdultService,
    private contentProviderFactory: ContentProviderFactoryService,
  ) {
    super();

    url = url.toLowerCase();
    for (const t in BookmarkContentTypes) {
      if (url.includes(t.toLowerCase())) {
        this.type = BookmarkContentTypes[t];
        break;
      }
    }

    for (const t in BookmarkQueryTypes) {
      if (url.includes(t.toLowerCase())) {
        this.queryType = BookmarkQueryTypes[t];
        break;
      }
    }

    if (!this.type || !this.queryType) {
      throw new Error(`invalid bookmark cache url: ${url}`);
    }

    this.orientation = this.type === BookmarkContentTypes.VOD ? CardOrientation.PORTRAIT : CardOrientation.LANDSCAPE;
  }

  public getItems(): Observable<Ribbon2Asset[]> {
    // load bookmarks, then convert to Ribbon2Asset
    return this.bookmarkCacheService.getBookmarksForType(this.type, this.queryType, this.adultService.showAdult(this.allowedAdultMode))
      .pipe(
        tap(() => this.hasMoreItems = false),
        switchMap(items => forkJoin([...items.map(i => this.bookmarkToAsset(i))])),
      );
  }

  public hasNext(): boolean {
    return this.hasMoreItems;
  }

  public resetPaging(): void {
    this.hasMoreItems = true;
  }

  private bookmarkToAsset(bookmark: BookmarkModel): Observable<Ribbon2Asset> {
    if (this.type === BookmarkContentTypes.PROGRAM) {
      return this.contentProviderFactory.programToRibbon2Asset(bookmark.id);
    } else if (this.type === BookmarkContentTypes.VOD) {
      return this.contentProviderFactory.vodToRibbon2Asset(bookmark.id);
    }

    throw new Error(`invalid bookmark type ${bookmark.type}`);
  }
}
