import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Properties } from '@atv-core/api/cms';
import { HistoryApiService } from '@atv-core/api/history';
import { AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { ReminderCacheService } from '@atv-core/services/cache/reminder/reminder-cache.service';
import {
  ConfigService,
  DetailTranslationKeys,
  MenuTranslationKeys,
  MyStuffTranslationKeys,
  SettingsKeys,
  SettingsTranslationKeys,
} from '@atv-bootstrap/services/config';
import { PageContext, PageUrlTypes } from '@atv-core/services/log/log.model';
import { LogService } from '@atv-core/services/log/log.service';
import { PageData } from '@atv-core/services/menu-manager/menu-manager.service';
import { MessagesService } from '@atv-core/services/messages';
import { SessionService } from '@atv-core/services/session';
import { Stb } from '@atv-core/services/session/stb';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { CustomerSettings, DeviceSettings } from '@atv-core/utility/constants/shared';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { ReloadablePageComponent } from '@atv-pages/reloadable-page.component';
import { SubscriptionInfoModel } from '@atv-shared/settings/subscription-settings/subscription-info.model';
import { SubscriptionSettingsModel } from '@atv-shared/settings/subscription-settings/subscription-settings.model';
import { forkJoin, fromEvent, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, flatMap, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AtvFeatures } from '@atv-core/utility/constants/atv_features';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styles: [],
})
export class SettingsPageComponent extends ReloadablePageComponent implements OnInit, OnDestroy {
  public subscriptionInfo1 = new SubscriptionInfoModel();
  public subscriptionSettings1 = new SubscriptionSettingsModel();

  public subscriptionInfo2 = new SubscriptionInfoModel();
  public subscriptionSettings2 = new SubscriptionSettingsModel();

  public npvrStb: Stb;
  public stbs: Stb[];
  public settingNetworkRecordingTitle = '';
  public settingDefaultDeviceTitle = '';
  public settingReminderTitle = '';
  public settingReminderBeforeTitle = '';
  public saveText = '';

  public reminderBeforeTimes = [];
  public selectedReminderBeforeTime = '0';

  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnSubscription: Subscription;
  public isAuthorized = false;

  constructor(
    config: ConfigService,
    private historyApi: HistoryApiService,
    sessionService: SessionService,
    messagesService: MessagesService,
    private log: LogService,
    private reminderCache: ReminderCacheService,
    private activatedRoute: ActivatedRoute,
    authorizationService: AuthorizationService,
    adultService: AdultService,
    private spatialNavigationService: SpatialNavigationService,
  ) {
    super(sessionService, authorizationService, adultService, messagesService, config);
    this.subscriptionInfo1.showSubscriptionTypes = true;
    this.subscriptionInfo1.showBeginBufferOptions = true;
    this.subscriptionInfo1.showEndBufferOptions = true;
    this.subscriptionInfo1.showDeleteProtected = true;

    this.settingNetworkRecordingTitle = this.config.getTranslation(
      SettingsTranslationKeys.settings_title_recording_npvr,
    );

    this.settingDefaultDeviceTitle = this.config.getTranslation(
      SettingsTranslationKeys.settings_title_recordingdevice,
    );

    this.settingReminderTitle = this.config.getTranslation(
      SettingsTranslationKeys.settings_title_reminder,
    );
    this.settingReminderBeforeTitle = this.config.getTranslation(
      SettingsTranslationKeys.settings_reminder_before_time,
    );

    const minutesStr = this.config.getTranslation(DetailTranslationKeys.detail_duration_min);
    const beforeTimes = this.config
      .getSettingString(SettingsKeys.reminderBeforeTimes, '0,5,10,15,20,30')
      .split(',');
    this.reminderBeforeTimes = beforeTimes.map((option) => {
      return { value: '' + parseInt(option, 10) * 60000, name: `${option} ${minutesStr}` };
    });

    this.saveText = this.config.getTranslation(MyStuffTranslationKeys.mystuff_adultmode_save_btn);
    this.isAuthorized = this.authorizationService.isAuthorized();
  }

  ngOnInit(): void {
    this.log.pageView(
      new PageContext({
        pageURL: PageUrlTypes.my_stuff_settings,
        pageTitle: this.config.getTranslation(MenuTranslationKeys.menu_settings),
        pageLocale: this.config.getLocale(),
      }),
    );

    super.start();
  }

  public loadPage(): void {
    if (SharedUtilityService.isSmartTv()) {
      this.registerElements();
    }
    this.historyApi
      .getSettingForDevice(this.sessionService.getDeviceId(), DeviceSettings.reminder_before_time)
      .subscribe((result) => {
        if (result && result.value) {
          this.selectedReminderBeforeTime = result.value;
        }
      });

    if (environment.atv_feature_list.includes(AtvFeatures.RECORDING)) {
      this.loadStbInfo();
    }
  }

  private loadStbInfo(): void {
    this.stbs = this.sessionService.getStbsList().getRecordingStbs(true);
    if (this.stbs.length > 1) {
      this.subscriptionInfo2.showStbs = true;
      this.subscriptionInfo2.stbs = this.stbs;

      this.historyApi.getCustomerSetting(CustomerSettings.default_recording_device)
        .pipe(map(result => result?.value ?? this.sessionService.getStbsList().getDefaultStb().id),
          catchError(() => of(this.sessionService.getStbsList().getDefaultStb().id)))
        .subscribe(
          (result) => {
            const settings = new SubscriptionSettingsModel();
            settings.initSettings(result, undefined, undefined, undefined, undefined);
            this.subscriptionSettings2 = settings;
            if (this.isSmartTv) {
              this.registerElements();
            }
          },
        );
    }

    this.npvrStb = this.sessionService.getStbsList().getNpvrStb();
    if (this.npvrStb) {
      this.loadRecordingCustomerSettings(this.npvrStb.id).subscribe((result) => {
        if (result) {
          this.subscriptionSettings1 = result;

          if (this.isSmartTv) {
            this.registerElements();
          }
        }
      });
    }
  }

  getPageProperties(): Observable<Properties[]> {
    return of((this.activatedRoute.snapshot.data as PageData).properties);
  }

  public saveReminderSetting(): void {
    this.historyApi
      .setSettingForDevice(
        this.sessionService.getDeviceId(),
        DeviceSettings.reminder_before_time,
        this.selectedReminderBeforeTime,
      )
      .subscribe(
        () => {
          this.messagesService.showSuccessMessage(
            this.config.getTranslation(SettingsTranslationKeys.settings_saved),
          );
          this.reminderCache.reset();
        },
        () => {
          this.messagesService.showErrorMessage(
            this.config.getTranslation(SettingsTranslationKeys.setting_save_failed),
          );
        },
      );
  }

  public saveNetworkRecordingSettings(): void {
    if (this.npvrStb && this.subscriptionSettings1) {
      if (this.subscriptionSettings1.type !== undefined) {
        this.historyApi
          .setSettingForDevice(
            this.npvrStb.id,
            DeviceSettings.recording_episodes,
            this.subscriptionSettings1.type,
          )
          .subscribe();
      }

      if (this.subscriptionSettings1.beginBuffer !== undefined) {
        this.historyApi
          .setSettingForDevice(
            this.npvrStb.id,
            DeviceSettings.recording_before_time,
            this.subscriptionSettings1.beginBuffer,
          )
          .subscribe();
      }
      if (this.subscriptionSettings1.endBuffer !== undefined) {
        this.historyApi
          .setSettingForDevice(
            this.npvrStb.id,
            DeviceSettings.recording_after_time,
            this.subscriptionSettings1.endBuffer,
          )
          .subscribe();
      }

      if (this.subscriptionSettings1.deleteProtected !== undefined) {
        this.historyApi
          .setSettingForDevice(
            this.npvrStb.id,
            DeviceSettings.recording_delete_protection,
            this.subscriptionSettings1.deleteProtected ? 'ON' : 'OFF',
          )
          .subscribe();
      }

      // catch which update fail and show error messages that says which settings failed

      this.messagesService.showSuccessMessage(
        this.config.getTranslation(SettingsTranslationKeys.settings_saved),
      );
    }
  }

  public saveDefaultDeviceSetting(): void {
    if (this.subscriptionSettings2) {
      this.historyApi
        .setCustomerSetting(
          CustomerSettings.default_recording_device,
          this.subscriptionSettings2.deviceId,
        )
        .subscribe(
          () => {
            this.messagesService.showSuccessMessage(
              this.config.getTranslation(SettingsTranslationKeys.settings_saved),
            );
          },
          () => {
            this.messagesService.showErrorMessage(
              this.config.getTranslation(SettingsTranslationKeys.setting_save_failed),
            );
          },
        );
    }
  }

  public inputKeyEvent(event: KeyboardEvent): void {
    // when 'done' on screen keyboard or enter is pressed
    if (this.spatialNavigationService.keyCodeIsEnter(event.keyCode)) {
      event.cancelBubble = true;
      event.preventDefault();
      this.spatialNavigationService.move('down');
    }
  }

  private loadRecordingCustomerSettings(deviceId): Observable<SubscriptionSettingsModel> {
    const requests = [
      this.historyApi
        .getSettingForDevice(deviceId, DeviceSettings.recording_episodes)
        .pipe(catchError(() => of(undefined))),
      this.historyApi
        .getSettingForDevice(deviceId, DeviceSettings.recording_before_time)
        .pipe(catchError(() => of(undefined))),
      this.historyApi
        .getSettingForDevice(deviceId, DeviceSettings.recording_after_time)
        .pipe(catchError(() => of(undefined))),
      this.historyApi
        .getSettingForDevice(deviceId, DeviceSettings.recording_delete_protection)
        .pipe(catchError(() => of(undefined))),
    ];

    if (requests.length === 0) {
      return of(undefined);
    }

    return forkJoin(requests).pipe(
      flatMap((result) => {
        const beginBuffer = this.retrieveValidBufferTime(
          result[1]?.value,
          this.config
            .getSettingString(SettingsKeys.recordingBeforeTimes, '0,5,10,15,20,30')
            .split(','),
          deviceId,
          DeviceSettings.recording_before_time,
        );
        const endBuffer = this.retrieveValidBufferTime(
          result[2] && result[2].value ? result[2].value : undefined,
          this.config
            .getSettingString(SettingsKeys.recordingAfterTimes, '0,5,10,15,20,30')
            .split(','),
          deviceId,
          DeviceSettings.recording_after_time,
        );
        const settings = new SubscriptionSettingsModel(
          this.config.getSettingNumber(SettingsKeys.recordingDefaultBeforeTime),
          this.config.getSettingNumber(SettingsKeys.recordingDefaultAfterTime),
          this.config.getSettingString(SettingsKeys.recordingDefaultDeleteProtection) !== 'OFF',
        );
        settings.initSettings(
          deviceId,
          result[0]?.value,
          beginBuffer,
          endBuffer,
          result[3]?.value !== 'OFF',
        );

        return of(settings);
      }),
    );
  }

  private retrieveValidBufferTime(time, possibleTimes, deviceId, settingsName): string {
    if (!time) {
      return undefined;
    }

    time = parseInt(time, 10);
    let value;

    possibleTimes.some((tmp, index) => {
      const possibleTime = parseInt(tmp, 10) * 60000;
      if (possibleTime === time) {
        value = time;
        return true;
      } else if (possibleTime > time && index >= 1) {
        value = possibleTimes[index - 1] * 60000;
        return true;
      }
      return false;
    });

    if (value !== time) {
      this.historyApi.setSettingForDevice(deviceId, settingsName, value).subscribe();
    }
    return '' + value;
  }

  private registerElements(): void {
    this.unregisterElements();

    this.returnSubscription = fromEvent(document, 'keyup')
      .pipe(
        filter((event: KeyboardEvent) =>
          this.spatialNavigationService.keyCodeIsReturn(event.keyCode),
        ),
      )
      .subscribe(() => {
        this.spatialNavigationService.setFocus(NavigationSections.MENU);
      });

    this.spatialNavigationService.register(NavigationSections.SETTINGS_PAGE, `.settings-page-wrapper app-subscription-settings .chip,
    .settings-page-wrapper .setting-confirm,
    .settings-page-wrapper #reminder-times .chip`, {
      defaultElement: '.settings-page-wrapper app-subscription-settings .chip.selected',
      enterTo: 'default-element',
      straightOnly: true,
    });
  }

  private unregisterElements(): void {
    if (this.returnSubscription) {
      this.returnSubscription.unsubscribe();
      this.returnSubscription = undefined;
    }

    this.spatialNavigationService.unregister(NavigationSections.SETTINGS_PAGE);
  }

  ngOnDestroy(): void {
    super.stop();

    if (this.isSmartTv) {
      this.unregisterElements();
    }
  }
}
