<div class="filter-wrapper">
  <div class="filter-current-state-wrapper" (smartTvKeyDownEnter)="showOptions()">
    <div class="filter-current-state" (click)="showOptions()">
      <div class="filter-current-state-title">{{ getDayString(getSelectedDay()) }}</div>
    </div>
  </div>
</div>

<div #overlayDiv *ngIf="isOptionsOpen" class="overlay">
  <div class="overlay-dialog">
    <div
      class="filter-option-smarttv"
      *ngFor="let day of getDays()"
      (smartTvKeyDownEnter)="selectDay(day)"
      (click)="selectDay(day)"
      [class.selected]="isDaySelected(day)"
    >
      {{ getDayString(day) }}
    </div>
  </div>
</div>
