import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @ViewChild('progressRef', { static: true })
  progressRef: ElementRef;

  @Input()
  progressPercentage: number;

  @Input()
  public isInMiniDetail = false;

  constructor() {}

  ngOnInit(): void {}

  calculateProgressStyleWidth(): string {
    this.progressPercentage = this.progressPercentage < 0 ? 0 : this.progressPercentage;
    this.progressPercentage = this.progressPercentage > 100 ? 100 : this.progressPercentage;
    if (!this.progressPercentage || this.progressPercentage === 0) {
      return '0%';
    }
    return this.progressPercentage + '%';
  }
}
