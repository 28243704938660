import { Injectable } from '@angular/core';
import { CmsAction, CmsActionMethod, CmsContentTypes } from '@atv-core/api/cms/cms-api.model';
import { EpgApiService, EpgProgramAsset } from '@atv-core/api/epg';
import { BookmarkContentTypes } from '@atv-core/api/history';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { BookmarkCacheService, BookmarkModel } from '@atv-core/services/cache/bookmark';
import { ChannelCacheService } from '@atv-core/services/cache/channel';
import { StbCacheService } from '@atv-core/services/cache/stb';
import { ConfigService, ErrorTranslationKeys } from '@atv-bootstrap/services/config';
import { MessagesService } from '@atv-core/services/messages';
import { PlayerService } from '../../player/player.service';
import { StreamType } from '@atv-core/utility/constants/shared';
import { EpgUtilityService } from '@atv-core/utility/epg-utility/epg-utility.service';
import { PlayInfoModel } from '../../../../atv-player/model/play-info-model';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CmsActionHandler } from '../cms-action.model';

@Injectable({ providedIn: 'root' })
export class PlayRecordingAction extends CmsActionHandler {
  constructor(
    private auth: AuthorizationService,
    messagesService: MessagesService,
    config: ConfigService,
    adultService: AdultService,
    playerService: PlayerService,
    private stbCache: StbCacheService,
    private epgApi: EpgApiService,
    private epgUtility: EpgUtilityService,
    private bookmarkCache: BookmarkCacheService,
    private channelCache: ChannelCacheService
  ) {
    super(adultService, playerService, messagesService, config);
  }

  handlesMethod(method: CmsActionMethod): boolean {
    return method === CmsActionMethod.PLAY;
  }

  handlesType(type: CmsContentTypes): boolean {
    return type === CmsContentTypes.RECORDING;
  }

  handleAction(action: CmsAction): void {
    if (!this.auth.isAuthorized()) {
      this.auth.showAuthPopup.emit();
      return;
    }

    this.stbCache
      .getRecordingForId(action.actionId, this.adultService.showAdult(AdultMode.any))
      .subscribe((recording) => {
        if (!recording) {
          this.messagesService.showErrorMessage(
            this.config.getTranslation(ErrorTranslationKeys.error_no_rights_detail_description)
          );
        }
        const fromUntil = this.epgUtility.getEpgDaysFromUntil();

        forkJoin([
          this.epgApi
            .getProgram(recording.program, fromUntil.from, fromUntil.until)
            .pipe(catchError(() => of(undefined as EpgProgramAsset))),
          this.bookmarkCache
            .getBookmarkNoCache(
              BookmarkContentTypes.PROGRAM,
              recording.program,
              this.adultService.showAdult(AdultMode.any)
            )
            .pipe(catchError(() => of(undefined as BookmarkModel))),
          this.channelCache.getChannels(),
        ]).subscribe((result) => {
          const progResult = result[0];
          const bookmark = result[1];
          const channels = result[2];

          if (!progResult.program) {
            this.messagesService.showErrorMessage(
              this.config.getTranslation(ErrorTranslationKeys.error_load_details)
            );
            return;
          }

          const playInfo = new PlayInfoModel(
            StreamType.NPVR,
            recording.identifier,
            bookmark && bookmark.position && !bookmark.isFullyWatched() ? bookmark.position : 0,
          );

          playInfo.setEpgInfo(
            channels.find((channel) => channel.id === recording.channel),
            progResult.program.adult,
            progResult.program,
            progResult.series,
            progResult.season,
            recording,
            progResult.schedules.find((schedule) => schedule.id === recording.schedule),
            progResult.schedules,
            false
          );
          this.checkRights(playInfo);
        });
      });
  }

  private checkRights(playInfo: PlayInfoModel): void {
    const channel = playInfo.channelInformation;
    const schedule = playInfo.scheduleInformation;
    if (!this.epgUtility.canPlayRecording(playInfo.recordingInformation, schedule, channel)) {
      this.messagesService.showErrorMessage(
        this.config.getTranslation(ErrorTranslationKeys.error_no_rights_detail_description)
      );
      return;
    }

    this.checkAdultAndPlay(playInfo);
  }
}
