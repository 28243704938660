import { Injectable } from '@angular/core';
import { CmsAction, CmsActionMethod, CmsContentTypes } from '@atv-core/api/cms/cms-api.model';

import { DetailOverlayService } from '../../detail/detail-overlay.service';
import { CmsActionHandler } from '../cms-action.model';

@Injectable({ providedIn: 'root' })
export class DetailEpgAction extends CmsActionHandler {
  constructor(private detailService: DetailOverlayService) {
    super(null, null, null, null);
  }

  handlesMethod(method: CmsActionMethod): boolean {
    return method === CmsActionMethod.DETAIL;
  }

  handlesType(type: CmsContentTypes): boolean {
    return (
      type === CmsContentTypes.SERIES ||
      type === CmsContentTypes.CHANNEL ||
      type === CmsContentTypes.PROGRAM ||
      type === CmsContentTypes.SCHEDULE ||
      type === CmsContentTypes.RECORDING
    );
  }

  handleAction(action: CmsAction): void {
    this.detailService.openEpgDetail(this.isRecordingPage(action.actionType), action.actionType.toLowerCase(), action.actionId);
  }

  private isRecordingPage(type: CmsContentTypes): number {
    return type === CmsContentTypes.RECORDING ? 1 : 0;
  }
}
