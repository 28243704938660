import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentBackdropService } from '@atv-shared/content-backdrop/content-backdrop.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-backdrop',
  templateUrl: './main-backdrop.component.html',
  styleUrls: ['./main-backdrop.component.scss'],
})
export class MainBackdropComponent implements OnInit, OnDestroy {
  public showGradient = true;
  private showGradientSubscription: Subscription;

  constructor(private backdropService: ContentBackdropService) {
  }

  public ngOnInit(): void {
    this.showGradientSubscription = this.backdropService.showGradientSubject.subscribe(value => this.showGradient = value);
  }

  public getBackdropImage(): string {
    return this.backdropService.getBackDropImage();
  }

  public isBackdropVisible(): boolean {
    return this.backdropService.isBackdropVisible();
  }

  public ngOnDestroy(): void {
    this.showGradientSubscription.unsubscribe();
  }
}
