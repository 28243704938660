import { CmsContentTypes } from '@atv-core/api/cms';
import { AnonymousSettings } from '@atv-bootstrap/services/config';
import { PlatformType } from '@atv-core/utility/constants/shared';

export interface LoginSessionV3 {
  customer: LoginSessionCustomerV3;
  device: LoginSessionDeviceV3;
  stbs: LoginSessionStbV3[];
  npvr?: LoginSessionNpvr3;
  analytics?: LoginSessionAnalyticsV3;
  pushTopics?: string[];
}

interface LoginSessionCustomerV3 {
  id: string;
  features: CustomerFeature[];
  displayName: string;
  loginName: string;
}

interface LoginSessionDeviceV3 {
  id: string;
  type: PlatformType;
  entitlementId: string;
  features: StbFeature[];
}

export interface LoginSessionStbV3 {
  id: string;
  type: PlatformType;
  entitlementId: string;
  serial: string;
}

interface LoginSessionNpvr3 {
  id: string;
}

interface LoginSessionAnalyticsV3 {
  category: string;
  subcategories?: string[];
}

export enum CustomerFeature {
  NO_ADULT = 'NO_ADULT',
  NO_RENT = 'NO_RENT',
  NO_UPSELL = 'NO_UPSELL'
}

enum StbFeature {
  WIFI = 'WIFI'
}

export enum StbSettingsKeys {
  stb_recording = 'stb_recording',
  alias = 'alias',
}

export enum StreamAssetRequestOptions {
  RESUME_FROM_LIVE = 'RESUME_FROM_LIVE',
  AIRPLAY_ACTIVE = 'AIRPLAY_ACTIVE'
}

export enum StreamRequestGroups {
  MAIN = 'MAIN',
  PIP = 'PIP',
  AD = 'AD',
}

export enum StreamPackageTypes {
  MSS = 'MSS',
  DASH = 'DASH',
  HLS = 'HLS',
}

export enum StreamDrmTypes {
  FAIRPLAY = 'FAIRPLAY',
  PLAYREADY = 'PLAYREADY',
  WIDEVINE = 'WIDEVINE',
}

export enum KeepAliveErrors {
  SS_STREAM_GONE = 'SS_STREAM_GONE',
}

export interface SwipeActionResponse {
  id: string;
}

export interface SwipeActionRequest {
  initiator: string;
  play?: SwipeActionPlay;
}

interface SwipeActionPlay {
  type: CmsContentTypes;
  id: string;
  bookmark?: number;
  catalogId?: string;
  profileId?: string;
}

export interface SwipeCommandResponse {
  status: SwipeCommandResponseStatus;
  errorCode?: string;
}

export enum SwipeCommandResponseStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface BootstrapConfig {
  locale: string;
  globalSettings: Property[];
  appTranslations: Property[];
  genreTranslations: Property[];
  parentalRatingTranslations: Property[];
  legalTexts: Property[];
  externalLinks: Property[];
  anonymousConfig?: AnonymousSettings;
}

export interface Property {
  name: string;
  value: string;
}

export interface TimeResponse {
  time: string;
}
