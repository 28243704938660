import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { BannerAspectRatios, CmsAction, CustomCmsBanner, CustomCmsBannerVideo, CustomCmsBannerVideoManifest } from '@atv-core/api/cms';
import { CmsActionService } from '@atv-core/services/cms-action/cms-action.service';
import { PageContext } from '@atv-core/services/log/log.model';
import { LogService } from '@atv-core/services/log/log.service';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { ClientDetails } from '@atv-core/utility/static-services/client-detail.static-service';
import { PlayerEngine, PlayerEngines } from '@atv-player/engines/player-engine';
import { environment } from '@env/environment';
import { ShakaPlayerEngine } from '@atv-player/engines/shaka/shaka-player-engine';
import { CastlabsPlayerEngine } from '@atv-player/engines/castlabs/castlabs-player-engine';
import { VideoEvent } from '@atv-player/model/video-event';
import { PlayerService } from '@atv-core/services/player/player.service';
import { ConfigService, SettingsKeys } from '@atv-bootstrap/services/config';
import { StreamManagerService } from '@atv-core/services/stream-manager-service/stream-manager.service';
import { SessionService } from '@atv-core/services/session';
import { BannerModel } from '@atv-shared/banner/banner.model';
import { DetailOverlayService } from '@atv-core/services/detail/detail-overlay.service';
import { ImageRecipeCategory, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent implements OnChanges, OnDestroy, AfterViewInit {
  private leftRightObservable: Observable<KeyboardEvent>;
  private leftRightSubscription: Subscription = null;
  private returnObservable: Observable<KeyboardEvent>;
  private returnSubscription: Subscription = null;
  public readonly isSmartTv = SharedUtilityService.isSmartTv();

  @Input() set customCmsBanners(banners: CustomCmsBanner[]) {
    this.bannerModel = new BannerModel(banners);
  }

  @Input()
  pageContext: PageContext;

  @Output()
  visibilityChanged = new EventEmitter();

  private currentTimeout;

  private erroredBanners = 0;

  public clientPlatformDetails;
  private playerEngine: PlayerEngine;
  public volume = 0;
  private transitionTimeount = 0;
  @ViewChild('banner') bannerRef: ElementRef<HTMLDivElement>;
  private intersectionObserver: IntersectionObserver;
  private pauseTime = 0;
  @ViewChild('videoPlayer') videoRef: ElementRef<HTMLVideoElement>;
  private closeDetailSubscription: Subscription;

  bannerModel: BannerModel;

  constructor(
    private cmsAction: CmsActionService,
    private log: LogService,
    private cdr: ChangeDetectorRef,
    private spatialNavigationService: SpatialNavigationService,
    private playerService: PlayerService,
    private config: ConfigService,
    public streamManagerService: StreamManagerService,
    private sessionService: SessionService,
    private detailService: DetailOverlayService,
  ) {
    if (SharedUtilityService.isSmartTv()) {
      this.leftRightObservable = fromEvent(document, 'keyup').pipe(
        filter(
          (event: KeyboardEvent) =>
            this.spatialNavigationService.keyCodeIsLeft(event.keyCode) ||
            this.spatialNavigationService.keyCodeIsRight(event.keyCode),
        ),
      );
      this.returnObservable = fromEvent(document, 'keyup').pipe(
        filter((event: KeyboardEvent) =>
          this.spatialNavigationService.keyCodeIsReturn(event.keyCode),
        ),
      );
    }

    this.closeDetailSubscription = this.detailService.closeDetailEvent.subscribe(() => {
      this.intersectionObserver.observe(this.bannerRef.nativeElement);
    });
  }

  private detectChanges(): void {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  ngOnChanges(): void {
    this.bannerModel.currentBannerIndex = -1;
    this.erroredBanners = 0;
  }

  ngAfterViewInit(): void {
    if (this.bannerRef) {
      const intersectionSubject = new Subject<boolean>();
      intersectionSubject
        .pipe(debounceTime(SharedUtilityService.isSmartTv() ? 500 : 0))
        .subscribe((isVisible) => {
          this.viewIntersection(isVisible);
        });

      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          if (entries.length > 0) {
            intersectionSubject.next(entries[0].isIntersecting);
          }
        },
        {
          root: this.bannerRef.nativeElement.closest('.horizontal-scroller')?.parentElement,
          rootMargin: '400px',
        },
      );

      this.intersectionObserver.observe(this.bannerRef.nativeElement);
    }
  }

  private viewIntersection(isVisible: boolean): void {
    if (isVisible) {
      this.bannerModel.visible = true;
      if (this.bannerModel.isNextBanner) {
        this.nextBanner();
      } else if (this.pauseTime) {
        this.continuePlay();
      } else {
        this.bannerModel.showCurrentBannerImage();
        this.detectChanges();
      }
    } else {
      this.bannerModel.visible = false;
      if (this.playerEngine && this.playerEngine.isPlayerAttached() && !this.playerEngine.isPaused()) {
        this.playerEngine.pause();
      }
    }
  }

  private showBannerAtCurrentIndex(): void {
    const currentBanner = this.bannerModel.getCurrentBanner();
    if (currentBanner && this.bannerModel.customCmsBanners[this.bannerModel.currentBannerIndex].time) {
      this.currentTimeout = setTimeout(() => {
        this.nextBanner();
      }, currentBanner.time);
    }

    this.prepareVisualModel();
  }

  public goToBanner(index: number): void {
    if (index < 0 || index >= this.bannerModel.customCmsBanners.length) {
      return;
    }

    this.clearTimeout();

    this.bannerModel.currentBannerIndex = index;
    this.showBannerAtCurrentIndex();
  }

  public nextBanner(): void {
    this.clearTimeout();

    if (this.bannerModel.visible) {
      this.bannerModel.currentBannerIndex++;

      if (this.bannerModel.currentBannerIndex >= this.bannerModel.customCmsBanners.length) {
        this.bannerModel.currentBannerIndex = 0;
      }

      this.showBannerAtCurrentIndex();
    }
  }

  public previousBanner(): void {
    this.clearTimeout();

    this.bannerModel.currentBannerIndex--;

    if (this.bannerModel.currentBannerIndex < 0) {
      this.bannerModel.currentBannerIndex = this.bannerModel.customCmsBanners.length - 1;
    }

    this.showBannerAtCurrentIndex();
  }

  private clearTimeout(): void {
    if (this.currentTimeout) {
      this.bannerModel.isNextBanner = true;
      this.pauseTime = 0;
      clearTimeout(this.currentTimeout);
      this.currentTimeout = undefined;
    }
  }

  private prepareVisualModel(): void {
    this.bannerModel.isNextBanner = false;
    const currentBanner = this.bannerModel.getCurrentBanner();
    if (!currentBanner) {
      return;
    }

    const imageSet = ImageRecipeUtil.getSourceSet(currentBanner.image, this.aspectRatioToImageRecipeCategory(currentBanner.size));

    this.bannerModel.prepareVisualModel();

    const img = new Image();
    if (imageSet) {
      img.srcset = imageSet;
    }

    img.onload = () => {
      this.visibilityChanged.emit();
      this.bannerModel.showNextBanner(imageSet);
      if (this.playerEngine?.isPlayerAttached()) {
        this.playerEngine.pause();
      }

      setTimeout(() => {
        if (this.playerEngine?.isPlayerAttached()) {
          this.playerEngine.release();
        }
        if (currentBanner.video && !this.bannerModel.bannersPlayed.includes(this.bannerModel.currentBannerIndex)) {
          this.loadManifest(currentBanner.video);
        }
      }, this.transitionTimeount);
      if (this.transitionTimeount === 0) {
        this.transitionTimeount = 850;
      }
      this.detectChanges();
    };

    img.onerror = () => {
      ++this.erroredBanners;
      if (this.erroredBanners >= this.bannerModel.bannerLength) {
        this.visibilityChanged.emit();
      } else {
        this.nextBanner();
      }
    };
  }

  private getInitialBitrate(): number {
    let bitrate = 3000000;
    if (screen.height > 2000) {
      bitrate = this.config.getSettingNumber(SettingsKeys.playerInitialBitrate4K, bitrate);
    } else {
      bitrate = this.config.getSettingNumber(SettingsKeys.playerInitialBitrate, bitrate);
    }
    return bitrate;
  }

  private playManifest(sourceUrls: string[]): void {
    this.initPlayer().subscribe(() => {
      this.playerEngine.load(sourceUrls, 0, this.config.getSettingNumber(SettingsKeys.playerInitialBitrate, this.getInitialBitrate()),
        false, false, undefined, undefined).then(
        () => this.onPlayerReady(),
        () => this.onPlayerError(),
      );
    });
  }

  private loadManifest(bannerVideo: CustomCmsBannerVideo): void {
    const sourceUrls = this.getSources(bannerVideo.manifests);
    this.bannerModel.bannersPlayed.push(this.bannerModel.currentBannerIndex);
    if (sourceUrls.length > 0) {
      this.playManifest(sourceUrls);
    }
  }

  public doAction(): void {
    if (this.bannerModel.customCmsBanners[this.bannerModel.currentBannerIndex].action) {
      if (this.playerEngine && !this.playerEngine.isPaused()) {
        this.playerEngine.pause();
      }
      this.bannerModel.visible = false;
      this.intersectionObserver.unobserve(this.bannerRef.nativeElement);
      const cmsAction: CmsAction = {
        actionType: this.bannerModel.customCmsBanners[this.bannerModel.currentBannerIndex].action.type,
        actionId: this.bannerModel.customCmsBanners[this.bannerModel.currentBannerIndex].action.id,
        actionMethod: this.bannerModel.customCmsBanners[this.bannerModel.currentBannerIndex].action.method,
      };
      this.log.bannerAction(cmsAction, this.pageContext);
      this.cmsAction.doAction(cmsAction, true);
    }
  }

  onFocus(): void {
    if (SharedUtilityService.isSmartTv()) {
      this.leftRightSubscription = this.leftRightObservable.subscribe((event) => {
        event.cancelBubble = true;
        if (this.spatialNavigationService.keyCodeIsLeft(event.keyCode)) {
          this.previousBanner();
        } else {
          this.nextBanner();
        }
      });

      this.returnSubscription = this.returnObservable.subscribe(() => {
        this.spatialNavigationService.setFocus(NavigationSections.MENU);
      });
    }
  }

  focusOut(): void {
    if (SharedUtilityService.isSmartTv() && this.leftRightSubscription) {
      this.leftRightSubscription.unsubscribe();
      this.leftRightSubscription = null;

      this.returnSubscription.unsubscribe();
      this.returnSubscription = null;
    }
  }

  ngOnDestroy(): void {
    this.disposePlayer();
    if (this.leftRightSubscription) {
      this.leftRightSubscription.unsubscribe();
    }

    if (this.returnSubscription) {
      this.returnSubscription.unsubscribe();
    }

    this.clearTimeout();
  }

  private initPlayer(): Observable<void> {
    this.clientPlatformDetails = ClientDetails.getDetails();

    if (this.playerEngine === undefined) {
      let observable: Observable<PlayerEngine>;
      if (environment.playerEngine === PlayerEngines.SHAKA) {
        observable = ShakaPlayerEngine.createWithElement(this.videoRef.nativeElement);
      } else {
        observable = CastlabsPlayerEngine.createWithElement(this.videoRef.nativeElement);
      }

      return observable.pipe(map((engine) => {
        this.playerEngine = engine;

        this.playerEngine.on(VideoEvent.ENDED, this.onPlayerEnded);
        this.playerEngine.on(VideoEvent.PAUSE, this.onPlayerPause);
        this.playerEngine.on(VideoEvent.PLAYING, this.onPlayerPlay);
        this.playerEngine.on(VideoEvent.ERROR, this.onPlayerError);
      }));
    }

    return of(void 0);
  }

  private safePlay(): void {
    this.playerEngine.setMuted(true);
    this.playerEngine.play();
  }

  private continuePlay(): void {
    const timeDiff = (new Date().getTime() - this.pauseTime) / 1000;
    this.playerEngine.seekRelative(timeDiff).then(() => {
      this.playerEngine.play();
    });
  }

  private onPlayerEnded = () => {
    if (this.bannerModel.lastWasStyle1) {
      this.bannerModel.imageStyle1.opacity = 1;
    } else {
      this.bannerModel.imageStyle2.opacity = 1;
    }
    this.detectChanges();
  }

  private onPlayerPause = () => {
    this.pauseTime = new Date().getTime();
  }

  private onPlayerPlay = () => {
    this.bannerModel.imageStyle1.opacity = 0;
    this.bannerModel.imageStyle2.opacity = 0;
    this.detectChanges();
  }

  private onPlayerError = () => {
    if (this.bannerModel.lastWasStyle1) {
      this.bannerModel.imageStyle1.opacity = 1;
    } else {
      this.bannerModel.imageStyle2.opacity = 1;
    }
    this.detectChanges();
  };

  private onPlayerReady(): void {
    this.playerService.videoRenditionsSubject.next(this.playerEngine.getVideoQualities());
    this.playerService.audioTracksSubject.next(this.playerEngine.getAudioTracks());
    this.playerService.textTracksSubject.next(this.playerEngine.getSubtitles());

    this.safePlay();

    this.detectChanges();
  }

  private getSources(manifests: CustomCmsBannerVideoManifest[]): string[] {
    return manifests.map(m => m.manifest);
  }

  private disposePlayer(): void {
    if (this.playerService.isPlayerAttached()) {
      this.playerEngine.pause();
      this.playerEngine.off(VideoEvent.ENDED, this.onPlayerEnded);
      this.playerEngine.off(VideoEvent.PAUSE, this.onPlayerPause);
      this.playerEngine.off(VideoEvent.PLAYING, this.onPlayerPlay);
      this.playerEngine.off(VideoEvent.ERROR, this.onPlayerError);

      this.playerEngine.destroy().then();
      this.playerEngine = undefined;
    }

    this.detectChanges();
  }

  private aspectRatioToImageRecipeCategory(aspectRatio: BannerAspectRatios): ImageRecipeCategory {
    switch (aspectRatio) {
      case BannerAspectRatios.ASPECT_RATIO_7_3:
        return ImageRecipeCategory.BANNER_7_3;
      case BannerAspectRatios.ASPECT_RATIO_7_2:
        return ImageRecipeCategory.BANNER_7_2;
      default:
        return ImageRecipeCategory.BANNER_7_1;
    }
  }
}
