<div [class.smarttv-vertical-scroller]="isOnCustomPage">
  <app-banner
    *ngIf="fixedTopBanner"
    [customCmsBanners]="fixedTopBanner.banners"
    [pageContext]="pageContext"
    class="top-banner"
  ></app-banner>

  <app-cms-page-filters
    #cmsPageFilters
    (cmsFilterChange)="cmsFilterChange()"
    *ngIf="hasFilters()"
    [allowedAdultMode]="allowedAdultMode"
    [cmsFilterModel]="cmsFilterModel"
  ></app-cms-page-filters>

  <div *ngIf="cmsFilterModel" class="cms-page-wrapper">
    <div *ngFor="let cmsRow of cmsRows; let i = index" class="cms-page-row row-{{ i }}">
      <app-banner
        (visibilityChanged)="rowVisibilityChanged()"
        *ngIf="cmsRow.type === CustomCmsRowTypes.BANNER"
        [customCmsBanners]="cmsRow.banners"
        [pageContext]="pageContext"
      ></app-banner>

      <app-ribbon
        (visibilityChanged)="rowVisibilityChanged()"
        *ngIf="cmsRow.type === CustomCmsRowTypes.RIBBON"
        [allowedAdultMode]="allowedAdultMode"
        [pageProperties]="pageProperties"
        [ribbon]="cmsRow.ribbon"
      ></app-ribbon>
      <app-grid
        (visibilityChanged)="rowVisibilityChanged()"
        *ngIf="cmsRow.type === CustomCmsRowTypes.GRID"
        [allowedAdultMode]="allowedAdultMode"
        [grid]="cmsRow.grid"
        [pageProperties]="pageProperties"
      ></app-grid>
    </div>

    <div *ngIf="filterResultGrid" class="cms-page-row row-{{ cmsRows.length }}">
      <app-grid
        (visibilityChanged)="rowVisibilityChanged()"
        [allowedAdultMode]="allowedAdultMode"
        [grid]="filterResultGrid"
        [pageProperties]="pageProperties"
      ></app-grid>
    </div>
  </div>
</div>
