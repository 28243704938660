<div class="popup-wrapper" *ngIf="!isSmartTv && showDeletePopup">
  <div class="popup">
    <div class="popup-header">
      <div class="popup-header-title">{{ popupTitle }}</div>
      <div class="popup-close" (click)="closePopup()"></div>
    </div>
    <div class="popup-content">
      <div class="delete-all-recordings-popup-content">
        {{ popupText }}
      </div>
      <div class="delete-all-recordings-popup-buttons">
        <button class="auth-popup-button" (click)="confirmDelete()">
          {{ confirmButtonTitle }}
        </button>
        <button class="auth-popup-button" (click)="closePopup()">
          {{ cancelButtonTitle }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="overlay" *ngIf="isSmartTv && showDeletePopup">
  <div id="confirm-dialog" class="overlay-dialog button-dialog">
    <span> {{ popupText }}</span>
    <div class="dialog-buttons">
      <button (smartTvKeyDownEnter)="closePopup()">{{ cancelButtonTitle }}</button>
      <button (smartTvKeyDownEnter)="confirmDelete()">{{ confirmButtonTitle }}</button>
    </div>
  </div>
</div>
