import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { fromEvent, Observable, Subscription, timer } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-number-entry',
  templateUrl: './number-entry.component.html',
  styleUrls: ['./number-entry.component.scss'],
})
export class NumberEntryComponent implements OnInit, OnDestroy {
  public static numberEvent = new EventEmitter<number>();
  public currentNumber: number = null;
  private keySubscription: Subscription;
  private timerSubscription: Subscription = null;

  constructor(private spatialNavigationService: SpatialNavigationService) {
  }

  ngOnInit(): void {
    this.keySubscription = (fromEvent(document, 'keypress') as Observable<KeyboardEvent>)
      .pipe(
        filter(
          (event) =>
            NumberEntryComponent.numberEvent.observers.length > 0 &&
            this.spatialNavigationService.keyCodeIsNumeric(event.keyCode),
        ),
      )
      .subscribe((event) => {
        const n = event.keyCode - 48;

        if (this.currentNumber === null) {
          this.currentNumber = n;
        } else {
          this.currentNumber = parseInt(`${this.currentNumber}${n}`, 10);
        }

        if (this.timerSubscription) {
          this.timerSubscription.unsubscribe();
        }
        this.timerSubscription = timer(2000).subscribe(() => this.timerFired());

        event.cancelBubble = true;
        event.preventDefault();
      });
  }

  private timerFired(): void {
    NumberEntryComponent.numberEvent.emit(this.currentNumber);
    this.currentNumber = null;
    this.timerSubscription = null;
  }

  ngOnDestroy(): void {
    if (this.keySubscription) {
      this.keySubscription.unsubscribe();
    }
  }
}
