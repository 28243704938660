import { Directive, ElementRef, Input } from '@angular/core';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';

@Directive({
  selector: '[appCenterOnFocus]',
})
export class CenterOnFocusDirective {
  @Input('appCenterOnFocus') scrollElement: HTMLElement;

  constructor(private elementRef: ElementRef<HTMLElement>) {
    if (!SharedUtilityService.isSmartTv()) {
      return;
    }

    this.elementRef.nativeElement.addEventListener('focus', () => {
      if (SharedUtilityService.isSmartTv()) {
        this.doScrollX();
        this.doScrollY();
      } else {
        this.elementRef.nativeElement.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        });
      }
    });
  }

  private doScrollX(): void {
    if (
      !this.scrollElement ||
      this.scrollElement.scrollWidth <= this.scrollElement.parentElement.clientWidth
    ) {
      return;
    }

    // calculate the center of the current card in the scrolling div
    // scroll to that position, minus the half of the width of the parent (scrollContainer) to center
    const elementOffsetLeft = this.elementRef.nativeElement.offsetLeft;
    const elementWidth = this.elementRef.nativeElement.clientWidth;
    const parentWidth = this.scrollElement.parentElement.clientWidth;

    // don't scroll to the right (positive scroll offset)
    const scrollX = Math.min(0, -(elementOffsetLeft + elementWidth / 2 - parentWidth / 2));
    this.scrollElement.style.transform = `translateX(${scrollX}px)`;
    this.scrollElement.parentElement.scrollLeft = 0;
  }

  private doScrollY(): void {
    // get vertical scroller
    const scroller = this.elementRef.nativeElement.closest('.smarttv-vertical-scroller') as HTMLDivElement;

    if (!scroller) {
      return;
    }

    // prevent scrolling
    if (scroller.parentElement.scrollTop > 0) {
      scroller.parentElement.scrollTop = 0;
    }

    const mainContent = document.querySelector('.main-content-smarttv') as HTMLDivElement;
    if (mainContent?.scrollTop > 0) {
      mainContent.scrollTop = 0;
    }

    const elementOffsetTop = this.elementRef.nativeElement.offsetTop;
    const elementHeight = this.elementRef.nativeElement.clientHeight;
    const parentHeight = window.innerHeight;
    let parentsOffsetTop = 0; // the vertical offset of the parent to the scroller
    let c = this.elementRef.nativeElement.offsetParent as HTMLElement;
    while (c && c !== document.scrollingElement) {
      parentsOffsetTop += c.offsetTop;

      // prevent scrolling
      if (c.scrollTop > 0) {
        c.scrollTop = 0;
      }

      c = c.offsetParent as HTMLElement;
    }

    // don't scroll above the top
    const scrollY = Math.min(
      0,
      -(parentsOffsetTop + elementOffsetTop + elementHeight / 2 - parentHeight / 2),
    );

    scroller.style.transform = `translateY(${scrollY}px)`;
  }
}
