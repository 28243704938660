import { BehaviorSubject, Subject, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export class ControlsVisibility {
  private playerInteractionTimeout: number;
  private hideControlsResetTimer: Subject<void> = new Subject();
  private isLocked = false;

  public controlsVisibleSubject = new BehaviorSubject<boolean>(false);

  constructor(playerInteractionTimeout: number) {
    this.playerInteractionTimeout = playerInteractionTimeout;

    this.hideControlsResetTimer
      .pipe(switchMap(() => timer(this.playerInteractionTimeout)))
      .subscribe(() => {
        if (!this.isLocked) {
          this.controlsVisibleSubject.next(false);
        }
      });
  }

  public resetTimer(): void {
    this.hideControlsResetTimer.next();
  }

  public showControls(): void {
    if (!this.controlsVisibleSubject.value) {
      this.controlsVisibleSubject.next(true);
    }

    // reset hide controls timer
    this.resetTimer();
  }

  public hideControls(): void {
    this.controlsVisibleSubject.next(false);
  }

  public lockControls(): void {
    if (this.isLocked) {
      return;
    }

    this.isLocked = true;
  }

  public unlockControls(): void {
    if (!this.isLocked) {
      return;
    }

    this.isLocked = false;
    this.hideControlsResetTimer.next();
  }
}
