import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { Observable } from 'rxjs';

@Injectable()
export class AddAuthorizationInterceptor implements HttpInterceptor {
  constructor(private auth: AuthorizationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers
      .append('Accept', 'application/vnd.nebula-v2+json')
      .append('Accept', 'application/json');

    const authHeader = this.auth.getAuthorization();
    if (authHeader) {
      headers = headers.append('Authorization', authHeader);
    }
    if (!req.headers.has('Content-Type')) {
      headers['Content-Type'] = 'application/json';
    }
    const newReq = req.clone({ headers: headers });
    return next.handle(newReq);
  }
}
