<div class="profile-selection">
  <div class="profile-selection-title">{{ profileSelectionTitle }}</div>
  <div class="available-profiles">
    <div
      class="profile"
      *ngFor="let profile of profiles; let i = index"
      (click)="switchEditProfile(i)"
      [class.active]="profile.id === getActiveProfileId()"
    >
      <div
        class="profile-avatar"
        [ngStyle]="{ 'background-image': 'url(' + getProfileImage(profile) + ')' }"
      >
        <div class="profile-avatar-edit-overlay" *ngIf="editMode"></div>
      </div>
      <div class="profile-name">{{ profile?.profile.name }}</div>
    </div>

    <div class="profile create-new" *ngIf="profiles && profiles.length < maxProfileCount">
      <div class="profile-avatar" (click)="newProfile()"></div>
      <div class="profile-name">{{ profileNew }}</div>
    </div>
  </div>

  <div class="profile-mode-toggle">
    <button class="profile-edit-toggle" (click)="enableDisableManageProfiles()" *ngIf="!editMode">
      {{ manageProfilesBtn }}
    </button>
    <button class="profile-edit-toggle" (click)="enableDisableManageProfiles()" *ngIf="editMode">
      {{ cancelBtn }}
    </button>
  </div>
</div>
