import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { environment } from '@env/environment';
import moment from 'moment';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  public isSmartTv = SharedUtilityService.isSmartTv();
  public widthPerHour = environment.atv_guide_width_per_hour;
  public totalHours = 24;

  timelineElements: moment.Moment[];

  @ViewChild('timeLine')
  timeline: ElementRef<HTMLDivElement>;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  public update(date: moment.Moment): void {
    // total hours can change on DST day
    this.totalHours = moment.duration(moment(date).endOf('day').diff(moment(date).startOf('day'))).as('hours');

    this.timelineElements = [];

    let iterator = moment(date).startOf('day');
    const endOfDay = moment(date).endOf('day');

    while (iterator.isBefore(endOfDay)) {
      this.timelineElements.push(moment(iterator));

      iterator = iterator.add(30, 'minutes');
    }

    this.cdr.detectChanges();
  }

  setTransform(value: string): void {
    this.timeline.nativeElement.style.transform = value;
  }
}
