import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeleteRecordingsQueryParams, RecordingStates } from '@atv-core/api/recording';
import { PopupEvent as DeleteAllPopupEvent, RecordingPageTypes, StbCacheService } from '@atv-core/services/cache/stb';
import { ConfigService, PopupTranslationKeys, RecordingTranslationKeys } from '@atv-bootstrap/services/config';
import { RecordingLogInfo } from '@atv-core/services/log/log.model';
import { SessionService } from '@atv-core/services/session/session.service';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { SubscriptionTypes } from '@atv-shared/settings/subscription-settings/subscription-settings.model';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-delete-all-recordings-popup',
  templateUrl: './delete-all-recordings-popup.component.html',
  styles: [],
})
export class DeleteAllRecordingsPopupComponent implements OnInit {
  public showDeletePopup = false;
  public popupTitle = '';
  public popupText = '';
  public confirmButtonTitle = '';
  public cancelButtonTitle = '';

  private currentEvent: DeleteAllPopupEvent;

  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnObservable = fromEvent(document, 'keyup').pipe(
    filter((event: KeyboardEvent) => this.spatialNavigationService.keyCodeIsReturn(event.keyCode))
  );
  private returnSubscription: Subscription = null;

  constructor(
    private config: ConfigService,
    private stbCache: StbCacheService,
    private sessionService: SessionService,
    private router: Router,
    private spatialNavigationService: SpatialNavigationService
  ) {
    this.confirmButtonTitle = this.config.getTranslation(PopupTranslationKeys.popup_btn_ok);
    this.cancelButtonTitle = this.config.getTranslation(PopupTranslationKeys.popup_btn_cancel);

    this.stbCache.showDeleteAllRecordingsPopup.subscribe((event: DeleteAllPopupEvent) => {
      this.currentEvent = event;
      this.handlePopupEvent();
    });
  }

  ngOnInit(): void {}

  private handlePopupEvent(): void {
    if (!this.currentEvent) {
      this.popupText = '';
      this.confirmButtonTitle = '';
      return;
    }
    if (this.currentEvent.recordingPageType === RecordingPageTypes.recorded) {
      this.popupTitle = this.config.getTranslation(RecordingTranslationKeys.recordings_delete_all);
      this.popupText = this.config.getTranslation(
        RecordingTranslationKeys.recordings_delete_all_confirm
      );
    } else if (this.currentEvent.recordingPageType === RecordingPageTypes.planned) {
      this.popupTitle = this.config.getTranslation(RecordingTranslationKeys.recordings_cancel_all);
      this.popupText = this.config.getTranslation(
        RecordingTranslationKeys.recordings_cancel_all_confirm
      );
    } else {
      this.popupText = '';
      this.popupTitle = '';
    }
    this.showDeletePopup = true;

    if (this.isSmartTv) {
      this.registerElements();
    }
  }

  public closePopup(): void {
    this.currentEvent = undefined;
    this.showDeletePopup = false;

    if (this.isSmartTv) {
      this.unregisterElements();
    }
  }

  public confirmDelete(): void {
    if (
      this.currentEvent &&
      this.currentEvent.serieRecordings &&
      this.currentEvent.serieRecordings.length > 0
    ) {
      let request;

      if (this.currentEvent.recordingPageType === RecordingPageTypes.recorded) {
        const recordingLogInfo = new RecordingLogInfo(
          undefined,
          undefined,
          this.currentEvent.serieRecordings[0],
          this.sessionService.getStbsList().getNpvrStb()
        );
        const params: DeleteRecordingsQueryParams = {
          states: [RecordingStates.recorded, RecordingStates.recording],
          series: [this.currentEvent.serieRecordings[0].series],
          channels: [this.currentEvent.serieRecordings[0].channel],
        };

        request = this.stbCache.deleteRecordings(
          this.currentEvent.serieRecordings[0],
          recordingLogInfo,
          params
        );
      } else if (this.currentEvent.recordingPageType === RecordingPageTypes.planned) {
        const recordingLogInfo = new RecordingLogInfo(
          undefined,
          undefined,
          this.currentEvent.serieRecordings[0],
          this.sessionService.getStbsList().getNpvrStb(),
          SubscriptionTypes.SERIES
        );

        request = this.stbCache.cancelSubscription(
          SubscriptionTypes.SERIES,
          this.currentEvent.serieRecordings[0],
          recordingLogInfo
        );
      }

      if (request) {
        request.subscribe(
          () => {
            this.router.navigate([
              SharedUtilityService.removePathParams(
                this.router.url,
                this.currentEvent.routerParams,
              ),
            ]);
            this.closePopup();
          },
          () => {
            this.closePopup();
          }
        );
      }
    }
  }

  private registerElements(): void {
    this.spatialNavigationService.register(
      NavigationSections.OVERLAY_OPTION_BUTTONS,
      '#confirm-dialog button',
      { restrict: 'self-only' }
    );
    this.spatialNavigationService.setFocus(NavigationSections.OVERLAY_OPTION_BUTTONS);

    if (this.returnSubscription) {
      this.returnSubscription.unsubscribe();
    }
    this.returnSubscription = this.returnObservable.subscribe((event: KeyboardEvent) => {
      event.cancelBubble = true;
      this.closePopup();
    });
  }

  private unregisterElements(): void {
    this.spatialNavigationService.unregister(NavigationSections.OVERLAY_OPTION_BUTTONS);

    if (this.returnSubscription) {
      this.returnSubscription.unsubscribe();
    }
  }
}
