<div class="grid-wrapper" [class.hidden]="hideGrid">
  <div class="grid-title">{{ gridVisualModel.title }}</div>
  <div class="grid-content-message" [class.hidden]="!showContentMessage">
    {{ gridVisualModel.contentMessage }}
  </div>
  <div class="grid" [class.hidden]="showContentMessage">
    <div
      #gridContentEl
      class="grid-content"
      [class.landscape]="cardOrientation === CardOrientation.LANDSCAPE"
      [class.portrait]="cardOrientation !== CardOrientation.LANDSCAPE"
    >
      <div class="grid-item" *ngFor="let item of gridContents">
        <app-generic-card
          [cardOrientation]="cardOrientation"
          [pageProperties]="pageProperties"
          [channelCardMode]="gridChannelCardMode"
          [ribbonProperties]="grid.properties"
          [allowedAdultMode]="allowedAdultMode"
          [scrollElement]="gridContentEl"
          [cardSize]="CardSize.NORMAL"
          [asset]="item"
          [isOnSearchPage]="isOnSearchPage"
        ></app-generic-card>
      </div>

      <div class="grid-item" #placeholder [ngClass]="getPlaceholderItemStyling()"></div>
    </div>
  </div>
  <div class="grid-loading" [class.hidden]="!gettingResults">
    <img alt="loading" src="./assets/theme/images/loading.svg" />
  </div>
</div>
<div class="list-no-results" [class.hidden]="hasResults">{{ noResultText }}</div>
