import { Component, OnInit } from '@angular/core';
import { ConfigService, DetailTranslationKeys } from '@atv-bootstrap/services/config';

import { SessionService } from '../../../atv-core/services/session/session.service';

class ShareModel {
  shareTypes: string[] = ['facebook', 'twitter', 'copy'];
  showText = true;

  url: string;
  image = './assets/theme/images/logo.png';
}

@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.component.html',
  styles: [],
})
export class SharePopupComponent implements OnInit {
  public showSharePopup = false;
  public sharePopupTitle = '';

  public shareModel = new ShareModel();

  constructor(private config: ConfigService, private sessionService: SessionService) {
    this.sharePopupTitle = this.config.getTranslation(DetailTranslationKeys.detail_share_using);

    this.sessionService.showSharePopup.subscribe(() => {
      this.shareModel.url = window.location.href;
      this.showSharePopup = true;
    });
  }

  ngOnInit(): void {}

  public closePopup(): void {
    this.showSharePopup = false;
  }
}
