export enum SubscriptionTypes {
  EPISODE = 'this_episode',
  SEASON = 'this_season',
  SERIES = 'this_series',
}

export class SubscriptionSettingsModel {
  deviceId: string;
  type: SubscriptionTypes;
  beginBuffer: string;
  endBuffer: string;
  deleteProtected: boolean;
  private defaultBeginBuffer = '300000';
  private defaultEndBuffer = '300000';
  private defaultProtected = false;

  constructor(defaultBeginBuffer?: number, defaultEndBuffer?: number, defaultProtected?: boolean) {
    this.defaultBeginBuffer = defaultBeginBuffer ? defaultBeginBuffer * 60000 + '' : '300000';
    this.defaultEndBuffer = defaultEndBuffer ? defaultEndBuffer * 60000 + '' : '900000';
    this.defaultProtected =
      defaultProtected === undefined ? this.defaultProtected : defaultProtected;
  }

  public initSettings(
    deviceId: string,
    type: SubscriptionTypes,
    beginBuffer: string,
    endBuffer: string,
    deleteProtected: boolean
  ): void {
    this.deviceId = deviceId;
    this.type = type || SubscriptionTypes.EPISODE;
    this.beginBuffer = beginBuffer || this.defaultBeginBuffer;
    this.endBuffer = endBuffer || this.defaultEndBuffer;
    this.deleteProtected = deleteProtected === undefined ? this.defaultProtected : deleteProtected;
  }
}
