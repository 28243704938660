import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService, SettingsKeys, TimeTranslationKeys } from '@atv-bootstrap/services/config';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DaySelectorService {
  private today = moment().startOf('day');
  private tomorrow = moment(this.today).add(1, 'day');
  private yesterday = moment(this.today).subtract(1, 'day');
  private fullDayKeys: string[] = [];
  private shortDayKeys: string[] = [];

  private days: moment.Moment[];
  private selectedDate: moment.Moment = undefined;
  public dateSelectedEvent: BehaviorSubject<moment.Moment> = new BehaviorSubject(this.today);

  constructor(private configService: ConfigService, private route: ActivatedRoute) {
    const pastDays = this.configService.getSettingNumber(SettingsKeys.epgPastDays, 7);
    const futureDays = this.configService.getSettingNumber(SettingsKeys.epgFutureDays, 7);

    this.days = Array.from(new Array(pastDays + futureDays + 1), (value, index) => {
      return moment()
        .startOf('day')
        .add(-pastDays + index, 'days');
    });

    const date = parseInt(this.route.snapshot.queryParams.date, 10);

    if (date) {
      const m = moment(date);

      if (m.isBetween(this.days[0], this.days[this.days.length - 1])) {
        this.selectedDate = m.startOf('day');
      }
    }

    if (!this.selectedDate) {
      this.selectedDate = this.today;
    }

    this.dateSelectedEvent.next(this.selectedDate);

    this.fullDayKeys[1] = TimeTranslationKeys.time_day_monday;
    this.fullDayKeys[2] = TimeTranslationKeys.time_day_tuesday;
    this.fullDayKeys[3] = TimeTranslationKeys.time_day_wednesday;
    this.fullDayKeys[4] = TimeTranslationKeys.time_day_thursday;
    this.fullDayKeys[5] = TimeTranslationKeys.time_day_friday;
    this.fullDayKeys[6] = TimeTranslationKeys.time_day_saturday;
    this.fullDayKeys[7] = TimeTranslationKeys.time_day_sunday;

    this.shortDayKeys[1] = TimeTranslationKeys.time_day_short_mon;
    this.shortDayKeys[2] = TimeTranslationKeys.time_day_short_tue;
    this.shortDayKeys[3] = TimeTranslationKeys.time_day_short_wed;
    this.shortDayKeys[4] = TimeTranslationKeys.time_day_short_thu;
    this.shortDayKeys[5] = TimeTranslationKeys.time_day_short_fri;
    this.shortDayKeys[6] = TimeTranslationKeys.time_day_short_sat;
    this.shortDayKeys[7] = TimeTranslationKeys.time_day_short_sun;
  }

  public getSelectedDate(): moment.Moment {
    return this.selectedDate;
  }

  public setSelectedDate(date?: moment.Moment): void {
    this.selectedDate = date ? date : this.today;
    this.dateSelectedEvent.next(this.selectedDate);
  }

  public getDays(): moment.Moment[] {
    return this.days;
  }

  public getFullDayString(value: moment.Moment, addDate = false): string {
    if (this.today.isSame(value)) {
      return this.configService.getTranslation(TimeTranslationKeys.time_today);
    } else if (this.tomorrow.isSame(value)) {
      return this.configService.getTranslation(TimeTranslationKeys.time_tomorrow);
    } else if (this.yesterday.isSame(value)) {
      return this.configService.getTranslation(TimeTranslationKeys.time_yesterday);
    } else {
      const weekday = moment(value).isoWeekday();
      return SharedUtilityService.isSmartTv()
        ? `${this.configService.getTranslation(this.fullDayKeys[weekday])}, ${this.getDateString(
            value
          )}`
        : this.configService.getTranslation(this.fullDayKeys[weekday]);
    }
  }

  public getShortDayString(value: moment.Moment): string {
    if (this.today.isSame(value)) {
      return this.configService.getTranslation(TimeTranslationKeys.time_today);
    } else if (this.tomorrow.isSame(value)) {
      return this.configService.getTranslation(TimeTranslationKeys.time_tomorrow);
    } else if (this.yesterday.isSame(value)) {
      return this.configService.getTranslation(TimeTranslationKeys.time_yesterday);
    } else {
      const weekday = moment(value).isoWeekday();
      return this.configService.getTranslation(this.shortDayKeys[weekday]);
    }
  }

  public getDateString(value: moment.Moment): string {
    return value.format('DD/M');
  }
}
