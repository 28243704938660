<div [class.smarttv]="isSmartTv" class="recordings-page-wrapper">
  <div class="recording-filters">
    <div *ngIf="!isSmartTv" class="detail-back" (click)="goBack()" [class.hidden]="!inFolder"></div>
    <app-stb-filter
      [class.hidden]="hideRecordingFilters()"
      [stbs]="usableStbs"
      [setSelectedStbWithId]="stbId"
      (filterChange)="stbFilterChanged($event)"
    ></app-stb-filter>

    <div class="recording-space">
      <div class="recording-space-text">{{ stbProgressText }}</div>
      <app-progress-bar
        class="recording-space-progress"
        [class.hidden]="stbProgressPercent === undefined"
        [progressPercentage]="stbProgressPercent"
      ></app-progress-bar>
    </div>
    <div class="right-recording-buttons-wrapper">
      <div
        *ngIf="!isSmartTv"
        [class.hidden]="!showAdultToggleButton"
        class="adult-toggle"
        [appTooltip]="tooltipText1"
        [appTooltipPosition]="tooltipPosition"
        (click)="toggleAdultMode()"
        [ngStyle]="{
          'background-image':
            'url(' +
            (showAdultRecordings
              ? './assets/theme/svg/recordings_adultmode_selected.svg'
              : './assets/theme/svg/recordings_adultmode_normal.svg') +
            ')'
        }"
      ></div>

      <button
        id="toggle-adult"
        class="button-title"
        *ngIf="isSmartTv && showAdultToggleButton"
        (click)="toggleAdultMode($event)"
        [class.hidden]="!showAdultToggleButton"
      >
        {{ showAdultRecordings ? disableAdultText : enableAdultText }}
      </button>

      <div
        *ngIf="inFolder"
        class="delete-all-recordings"
        [appTooltip]="tooltipText2"
        [appTooltipPosition]="tooltipPosition"
        (click)="deleteAllRecordings()"
        (smartTvKeyDownEnter)="deleteAllRecordings()"
        [ngStyle]="{
          'background-image': 'url(/assets/theme/svg/delete.svg)'
        }"
      ></div>
    </div>
  </div>
  <app-grid
    *ngFor="let grid of grids"
    [grid]="grid"
    [noResultText]="noResultText"
    [allowedAdultMode]="allowedAdultMode"
    (visibilityChanged)="registerElements()"
  ></app-grid>
</div>
