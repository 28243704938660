import { DrmInfo } from '../model/drm-info';
import { VideoEvent } from '@atv-player/model/video-event';
import { VideoQuality } from '@atv-player/model/video-quality';
import { AudioTrack } from '@atv-player/model/audio-track';
import { Subtitle } from '@atv-player/model/subtitle';
import { SeekRange } from '@atv-player/model/seek-range';

export interface PlayerEngine {
  on(event: VideoEvent, callback: EventListener): void;

  one(event: VideoEvent, callback: EventListener): void;

  off(event: VideoEvent, callback: EventListener): void;

  /**
   * Get current player position in seconds.
   * For VOD, this is relative to the start of the video.
   * For EPG, this is relative to the position of the start of the stream (NOT the start of the schedule)
   *  => scheduleStartTime - bookmarkOffset
   *
   *  @param streamStartTime The start time of the stream. This is necessary to calculate the time for live streams, where there is no info
   *  about the start time in the manifest.
   */
  getCurrentTime(streamStartTime?: number): number;

  getSeekRange(): SeekRange;

  /**
   * Seek to a position
   * @param time The time, relative to the start of the stream. For VOD, this is the start of the video. For EPG, this is the start of the
   * stream (NOT the start of the schedule) => scheduleStartTime - bookmarkOffset
   */
  seekAbsolute(time: number): Promise<unknown>;

  seekRelative(offset: number): Promise<unknown>;

  getDuration(): number;

  setMuted(muted: boolean): void;

  isMuted(): boolean;

  play(): Promise<unknown>;

  pause(): void;

  isPaused(): boolean;

  setVolume(volume: number): void;

  getVolume(): number;

  isPlayerAttached(): boolean;

  isLive(): boolean;

  getVideoQualities(): VideoQuality[];

  // use ABR if no quality selected
  selectVideoQuality(quality?: VideoQuality): void;

  getAudioTracks(): AudioTrack[];

  selectAudioTrack(track: AudioTrack): void;

  getSubtitles(): Subtitle[];

  selectSubtitle(subtitle?: Subtitle): void;

  destroy(): Promise<unknown>;

  load(
    sourceUrls: string[],
    startPosition: number,
    initialBitrate: number,
    isDrmProtected: boolean,
    useBroadpeakAnalytics: boolean,
    broadpeakAnalyticsDomain?: string,
    broadpeakAnalyticsServer?: string,
    drmInfo?: DrmInfo,
  ): Promise<unknown>;

  canAirplay(): boolean;

  showAirplayCastMenu(): void;

  release(): void;
}

export enum PlayerEngines {
  CASTLABS,
  SHAKA
}
