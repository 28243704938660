<div class="filter-wrapper" #filterWrapperRef>
  <div class="filter-current-state-wrapper" (smartTvKeyDownEnter)="showOptions()">
    <div
      *ngIf="!isSmartTv"
      [class.hidden]="selectedScoreIndex === undefined"
      class="filter-remove"
      (click)="tryRemoveFilter($event)"
    ></div>
    <div class="filter-current-state" (click)="showOptions()" [ngSwitch]="selectedScoreIndex">
      <div *ngSwitchCase="undefined" class="filter-current-state-title">{{ filterTitle }}</div>

      <div *ngSwitchDefault class="filter-current-state-score">
        <img
          class="filter-score-star"
          *ngFor="let star of ' '.repeat(selectedScoreIndex + 1).split(''); let x = index"
          src="./assets/theme/svg/rating_normal.svg"
        />
      </div>
      <div *ngIf="!isSmartTv" class="filter-show-options"></div>
    </div>
  </div>

  <div class="filter-options-wrapper" *ngIf="!isSmartTv && !optionsHidden" #optionWrapperRef>
    <div class="filter-options-header" (click)="hideOptions()">
      <div class="filter-options-header-title">{{ filterTitle }}</div>
      <div class="filter-options-close"></div>
    </div>
    <div class="filter-options">
      <div
        class="filter-option"
        [class.selected]="selectedScoreIndex === i"
        (click)="changeSelectedScoreIndex(i)"
        *ngFor="let score of filterModel?.filterScores; index as i"
      >
        <img
          class="filter-score-star"
          *ngFor="let star of ' '.repeat(i + 1).split(''); let x = index"
          src="./assets/theme/svg/rating_selected.svg"
        />
        <img
          class="filter-score-star"
          *ngFor="
            let star of ' '.repeat(filterModel?.filterScores.length - i - 1).split('');
            let x = index
          "
          src="./assets/theme/svg/rating_disabled.svg"
        />
      </div>
    </div>
  </div>
</div>

<div #overlayDiv *ngIf="isSmartTv && !optionsHidden" class="overlay">
  <div class="overlay-dialog">
    <div
      class="filter-option-smarttv"
      *ngFor="let score of filterModel?.filterScores; index as i"
      (smartTvKeyDownEnter)="changeSelectedScoreIndex(i)"
      (click)="changeSelectedScoreIndex(i)"
    >
      <img
        class="filter-score-star"
        *ngFor="let star of ' '.repeat(i + 1).split(''); let x = index"
        src="./assets/theme/svg/rating_selected.svg"
      />
      <img
        class="filter-score-star"
        *ngFor="
          let star of ' '.repeat(filterModel?.filterScores.length - i - 1).split('');
          let x = index
        "
        src="./assets/theme/svg/rating_disabled.svg"
      />
    </div>
  </div>
</div>
