import { Injectable } from '@angular/core';
import { CmsAction, CmsActionMethod, CmsContentTypes } from '@atv-core/api/cms/cms-api.model';
import { EpgApiService } from '@atv-core/api/epg';
import { AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { ChannelCacheService } from '@atv-core/services/cache/channel';
import { ConfigService, ErrorTranslationKeys } from '@atv-bootstrap/services/config';
import { MessagesService } from '@atv-core/services/messages';
import { PlayerService } from '../../player/player.service';
import { StreamType } from '@atv-core/utility/constants/shared';
import { EpgUtilityService } from '@atv-core/utility/epg-utility/epg-utility.service';
import { PlayInfoModel } from '../../../../atv-player/model/play-info-model';

import { CmsActionHandler } from '../cms-action.model';
import { BlackoutFactory } from '@atv-core/utility/epg-utility/blackout-factory';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class PlayChannelAction extends CmsActionHandler {
  constructor(
    private auth: AuthorizationService,
    private channelCache: ChannelCacheService,
    private epgUtility: EpgUtilityService,
    private epgApi: EpgApiService,
    messagesService: MessagesService,
    config: ConfigService,
    adultService: AdultService,
    playerService: PlayerService,
  ) {
    super(adultService, playerService, messagesService, config);
  }

  handlesMethod(method: CmsActionMethod): boolean {
    return method === CmsActionMethod.PLAY;
  }

  handlesType(type: CmsContentTypes): boolean {
    return type === CmsContentTypes.CHANNEL;
  }

  handleAction(action: CmsAction): void {
    if (!this.auth.isAuthorized()) {
      this.auth.showAuthPopup.emit();
      return;
    }

    this.channelCache.getChannels().subscribe((channels) => {
      const fromUntil = this.epgUtility.getEpgDaysFromUntil();
      const channel = channels.find((ch) => ch.id === action.actionId);

      if (!channel) {
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_load_details),
        );
        return;
      }

      this.epgApi
        .getScheduleForChannelFromUntil(channel.id, fromUntil.from, fromUntil.until)
        .subscribe((schedules) => {
          const currentSchedule = this.epgUtility.findCurrentSchedule(schedules.schedules);
          if (!currentSchedule) {
            this.messagesService.showErrorMessage(
              this.config.getTranslation(ErrorTranslationKeys.error_load_details),
            );
            return;
          }

          this.epgApi
            .getProgram(currentSchedule.program, fromUntil.from, fromUntil.until)
            .subscribe((program) => {
              if (!program.program) {
                this.messagesService.showErrorMessage(
                  this.config.getTranslation(ErrorTranslationKeys.error_load_details),
                );
                return;
              }

              const playInfo: PlayInfoModel = new PlayInfoModel(StreamType.LINEAR, channel.id, 0);
              playInfo.setEpgInfo(
                channel,
                program.program.adult,
                program.program,
                program.series,
                program.season,
                undefined,
                currentSchedule,
                program.schedules,
                false,
              );
              this.checkRights(playInfo);
            });
        });
    });
  }

  private checkRights(playInfo: PlayInfoModel): void {
    const channel = playInfo.channelInformation;
    const schedule = playInfo.scheduleInformation;

    if (playInfo.channelInformation && !channel.customerHasRight(StreamType.LINEAR)) {
      this.messagesService.showErrorMessage(
        this.config.getTranslation(ErrorTranslationKeys.error_no_rights),
      );
      return;
    }

    if (this.epgUtility.scheduleHasBlackout(schedule.blackouts, BlackoutFactory.create(environment.platform, StreamType.LINEAR))) {
      this.messagesService.showErrorMessage(
        this.config.getTranslation(ErrorTranslationKeys.error_blackout),
      );
      return;
    }

    this.checkAdultAndPlay(playInfo);
  }
}
