import { ContentProvider } from '@atv-shared/content-provider/content-provider';
import { Observable } from 'rxjs';
import { CmsApiService, Ribbon2Asset } from '@atv-core/api/cms';
import { SessionService } from '@atv-core/services/session';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { map } from 'rxjs/operators';
import { FocusType } from '@atv-core/utility/constants/shared';

export class CmsContentProvider extends ContentProvider {
  private nextPage = true;
  private currentPage = 0;

  constructor(
    private cmsApi: CmsApiService,
    private url: string,
    private focusType: FocusType,
    private sessionService: SessionService,
    private adultService: AdultService,
    private allowedAdultMode: AdultMode,
  ) {
    super();
  }

  public getItems(): Observable<Ribbon2Asset[]> {
    return this.cmsApi.getContent(this.url, this.currentPage, this.sessionService.anonymousProfileIsActive(),
      this.sessionService.getEntitlementId(), this.sessionService.getCatalogId(), this.sessionService.getActiveProfileId(),
      this.adultService.getAdultMode(this.allowedAdultMode), this.focusType)
      .pipe(
        map(result => {
          this.currentPage++;
          this.orientation = result.type;
          this.nextPage = result.nextPage;

          return result.items;
        }),
      );
  }

  public hasNext(): boolean {
    return this.nextPage;
  }

  public resetPaging(): void {
    this.nextPage = true;
    this.currentPage = 0;
  }
}
