import { Ribbon2Asset } from '@atv-core/api/cms';
import { CardConfig, CardModel } from '@atv-shared/generic-card/generic-card.model';
import { CardModelFactoryService } from '@atv-shared/card-model/card-model-factory-service';
import { CardModelFactory } from '@atv-shared/card-model/card-model-factory';

export class GenericCardModelFactory extends CardModelFactory {

  constructor(private cardModelFactory: CardModelFactoryService, private cardConfig: CardConfig) {
    super();
  }

  public createCard(item: Ribbon2Asset, cardModel: CardModel): void {
    cardModel.image = this.cardModelFactory.getImageUrl(item.asset,
      this.cardModelFactory.getImageRecipe(item.asset.type, cardModel.orientation, cardModel.size), cardModel.orientation,
      this.cardConfig.allowedAdultMode);

    if (this.cardModelFactory.showInfo(this.cardConfig)) {
      cardModel.row1 = item.asset.title;
    }

    cardModel.action = {
      actionId: item.asset.id,
      actionMethod: item.method,
      actionType: item.asset.type,
    };
  }
}
