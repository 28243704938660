import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import moment from 'moment';

@Component({
  selector: 'app-timeline-point',
  templateUrl: './timeline-point.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelinePointComponent implements OnInit {
  public timeStr: string = undefined;
  public position = 0;

  constructor() {}

  public ngOnInit(): void {}

  @Input()
  set time(value: moment.Moment) {
    this.timeStr = value.format('HH:mm');

    const hours = moment.duration(value.diff(moment(value).startOf('day'))).asHours();
    this.position = hours * environment.atv_guide_width_per_hour;
  }
}
