import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewRef } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileElement } from '@atv-core/api/aa/aa-api.model';
import { CatalogTranslationKeys, ConfigService, SettingsKeys } from '@atv-bootstrap/services/config';
import { ProfileService } from '@atv-core/services/profile/profile.service';
import { SessionService } from '@atv-core/services/session';
import { ImageRecipe, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';

@Component({
  selector: 'app-switch-profiles',
  templateUrl: './switch-profiles.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchProfilesComponent implements OnInit {
  public profiles: ProfileElement[];
  public profileSelectionTitle = '';

  public profileNew = '';
  public maxProfileCount = 5;

  public manageProfilesBtn = '';
  public cancelBtn = '';

  @Input()
  editMode: boolean;

  @Output()
  closePopup = new EventEmitter();

  @Output()
  createProfile = new EventEmitter();

  @Output()
  editProfile = new EventEmitter();

  @Output()
  manageProfiles = new EventEmitter();

  constructor(
    private sessionService: SessionService,
    private config: ConfigService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private profileService: ProfileService,
  ) {
    this.profileSelectionTitle = this.config.getTranslation(
      CatalogTranslationKeys.profile_select_title,
    );

    this.maxProfileCount = this.config.getSettingNumber(SettingsKeys.maxAllowedProfiles, 0);

    this.profileNew = this.config.getTranslation(CatalogTranslationKeys.profile_new);

    this.manageProfilesBtn = this.config.getTranslation(CatalogTranslationKeys.profiles_manage);
    this.cancelBtn = this.config.getTranslation(CatalogTranslationKeys.profile_cancel_btn);
  }

  ngOnInit(): void {
    this.showProfileSelection();
  }

  private detectChanges(): void {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  public switchEditProfile(index): void {
    if (this.editMode) {
      this.editUserProfile(index);
    } else {
      this.switchToProfile(index);
    }
  }

  public showProfileSelection(): void {
    this.profileService.loadProfiles().subscribe((result) => {
      this.profiles = result;
      this.detectChanges();
    });
  }

  private editUserProfile(index: number): void {
    const profile = this.profiles[index];
    this.editProfile.emit(profile);
  }

  public getActiveProfileId(): string {
    return this.sessionService.getActiveProfileId();
  }

  public getProfileImage(profile: ProfileElement): string {
    return ImageRecipeUtil.createImageUrl(profile.image, ImageRecipe.AVATAR_3);
  }

  newProfile(): void {
    this.createProfile.emit();
  }

  enableDisableManageProfiles(): void {
    this.manageProfiles.emit(!this.editMode);
  }

  private switchToProfile(index: number): void {
    const profile = this.profiles[index];
    if (profile.id !== this.getActiveProfileId()) {
      this.sessionService.switchProfile(profile).subscribe(
        () => {
        },
        () => {
          this.router.navigate(['']);
        },
      );
      this.closePopup.emit();
    } else {
      this.closePopup.emit();
    }
  }
}
