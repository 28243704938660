import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, QueryList, ViewChildren, ViewRef } from '@angular/core';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import moment from 'moment';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DaySelectorService } from '../day-selector.service';

@Component({
  selector: 'app-day-selector-smarttv',
  templateUrl: './day-selector-smarttv.component.html',
  styleUrls: ['./day-selector-smarttv.component.scss'],
})
export class DaySelectorSmarttvComponent implements AfterViewInit, OnDestroy {
  public isOptionsOpen = false;
  private returnSubscription: Subscription = null;
  @ViewChildren('overlayDiv') overlayDiv: QueryList<ElementRef<HTMLDivElement>>;
  private overlayDivSubscription: Subscription;

  constructor(
    private spatialNavigationService: SpatialNavigationService,
    private cdr: ChangeDetectorRef,
    private daySelectorService: DaySelectorService,
  ) {
  }

  ngAfterViewInit(): void {
    this.overlayDivSubscription = this.overlayDiv.changes.subscribe(changes => {
      if (changes.length === 1) {
        // move to body to avoid issues with parent divs who have a transformation set
        // TODO create overlay component, add it to body and use ng-template
        document.body.appendChild(changes.first.nativeElement);
      }

      this.returnSubscription?.unsubscribe();

      this.returnSubscription = fromEvent(changes.first.nativeElement, 'keyup')
        .pipe(filter((event: KeyboardEvent) => this.spatialNavigationService.keyCodeIsReturn(event.keyCode)))
        .subscribe((event: KeyboardEvent) => {
          event.cancelBubble = true;
          this.hideOptions();
        });
    });
  }

  public showOptions() {
    this.isOptionsOpen = true;

    window.setTimeout(() => {
      this.spatialNavigationService.register(
        NavigationSections.FILTER_OPTIONS,
        '.overlay-dialog .filter-option-smarttv',
        { restrict: 'self-only', defaultElement: '.overlay-dialog .filter-option-smarttv.selected' },
      );
      this.spatialNavigationService.setFocus(NavigationSections.FILTER_OPTIONS);
    }, 0);
  }

  public hideOptions() {
    this.isOptionsOpen = false;
    this.spatialNavigationService.unregister(NavigationSections.FILTER_OPTIONS);
    this.spatialNavigationService.setFocus(NavigationSections.FILTERS);

    this.returnSubscription?.unsubscribe();

    this.detectChanges();
  }

  private detectChanges() {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  public getDayString(value: moment.Moment): string {
    return this.daySelectorService.getFullDayString(value, true);
  }

  public getSelectedDay(): moment.Moment {
    return this.daySelectorService.getSelectedDate();
  }

  public getDays(): moment.Moment[] {
    return this.daySelectorService.getDays();
  }

  public selectDay(value: moment.Moment) {
    this.daySelectorService.setSelectedDate(value);
    this.hideOptions();
  }

  public isDaySelected(value: moment.Moment): boolean {
    return value.isSame(this.daySelectorService.getSelectedDate());
  }

  public ngOnDestroy(): void {
    this.returnSubscription?.unsubscribe();
    this.overlayDivSubscription?.unsubscribe();
  }
}
