import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DiscoveryService } from '@atv-bootstrap/services/discovery';
import { SessionService } from '@atv-core/services/session/session.service';
import { PlatformType } from '@atv-core/utility/constants/shared';
import { Observable } from 'rxjs';

import {
  BundlePriceResponse,
  CommitQuote,
  CreateBundleQuote3,
  CreateVodQuote3,
  QuotePriceResponse,
  ValidQuotePriceResponse,
  VodPriceResponse,
} from './pricing.model.service';

@Injectable({
  providedIn: 'root',
})
export class PricingApiService {
  constructor(
    private disc: DiscoveryService,
    private sessionService: SessionService,
    private http: HttpClient
  ) {}

  public getVodPrice(
    vodId: string,
    type: PlatformType,
    entitlementId: string,
  ): Observable<VodPriceResponse> {
    const pathParams = new HttpParams()
      .append('type', type)
      .append('entitlementId', entitlementId)
      .append('catalogId', this.sessionService.getCatalogId());
    return this.http.get<VodPriceResponse>(`${this.disc.location}/price/v4/vod/${encodeURIComponent(vodId)}`, {
      params: pathParams,
    });
  }

  public getBundlePrice(
    bundleId: string,
    type: PlatformType,
    entitlementId: string,
  ): Observable<BundlePriceResponse> {
    const pathParams = new HttpParams().append('type', type).append('entitlementId', entitlementId);

    return this.http.get<BundlePriceResponse>(`${this.disc.location}/price/v4/bundle/${encodeURIComponent(bundleId)}`, {
      params: pathParams,
    });
  }

  public createVodQuote(vodId: string, deviceId: string): Observable<QuotePriceResponse> {
    const body: CreateVodQuote3 = {
      deviceId,
    };
    return this.http.post<QuotePriceResponse>(`${this.disc.location}/price/v3/vod/${encodeURIComponent(vodId)}`, body);
  }

  public createBundleQuote(bundleId: string, deviceId: string): Observable<QuotePriceResponse> {
    const body: CreateBundleQuote3 = { deviceId };
    return this.http.post<QuotePriceResponse>(
      `${this.disc.location}/price/v3/bundle/${encodeURIComponent(bundleId)}`,
      body
    );
  }

  public getQuotes(): Observable<ValidQuotePriceResponse[]> {
    return this.http.get<ValidQuotePriceResponse[]>(`${this.disc.location}/price/v3/quote`);
  }

  public updateQuote(quoteId: string, code: string): Observable<QuotePriceResponse> {
    const body = {
      code,
    };
    return this.http.put<QuotePriceResponse>(
      `${this.disc.location}/price/v3/quote/${encodeURIComponent(quoteId)}/update`,
      body
    );
  }

  public commitQuote(
    quoteId: string,
    commitQuote: CommitQuote
  ): Observable<ValidQuotePriceResponse> {
    return this.http.put<ValidQuotePriceResponse>(
      `${this.disc.location}/price/v3/quote/${encodeURIComponent(quoteId)}/commit`,
      commitQuote
    );
  }

  public deleteQuote(quoteId: string): Observable<void> {
    return this.http.delete<void>(`${this.disc.location}/price/v3/quote/${encodeURIComponent(quoteId)}`);
  }
}
