import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '@atv-bootstrap/services/config';
import { ListTranslationKeys } from '@atv-bootstrap/services/config/config.model';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import moment from 'moment';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ContentFilters, FilterModel } from './../filters.model';

@Component({
  selector: 'app-day-filter',
  templateUrl: './day-filter.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayFilterComponent implements AfterViewInit, OnDestroy {
  @Input()
  filterModel: FilterModel;

  filterDays: Date[];

  @Input()
  set days(val: Date[]) {
    if (this.filterDays) {
      this.filterDays = val;
    } else if (!this.filterDays && val) {
      this.filterDays = val;
      this.tryInitFromQueryParams();
    }
  }

  @Output()
  filterReady = new EventEmitter();

  @Output()
  filterChange = new EventEmitter();

  private clickedInside = false;

  @ViewChild('filterWrapperRef', { static: true })
  filterWrapperRef: ElementRef;
  @ViewChild('optionWrapperRef')
  optionWrapperRef: ElementRef;

  selectedDayTitle = '';
  filterTitle = '';
  optionsHidden = true;

  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnObservable: Observable<KeyboardEvent>;
  @ViewChildren('overlayDiv') overlayDiv: QueryList<ElementRef<HTMLDivElement>>;
  private returnSubscription: Subscription;
  private overlayDivSubscription: Subscription;

  constructor(
    private config: ConfigService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private spatialNavigationService: SpatialNavigationService,
  ) {
    this.filterTitle = this.config.getTranslation(ListTranslationKeys.lists_filter_day);
    this.selectedDayTitle = this.filterTitle;

    if (this.isSmartTv) {
      this.returnObservable = fromEvent(document, 'keyup').pipe(
        filter((event: KeyboardEvent) =>
          this.spatialNavigationService.keyCodeIsReturn(event.keyCode),
        ),
      );
    }
  }

  public ngAfterViewInit(): void {
    this.overlayDivSubscription = this.overlayDiv.changes.subscribe(changes => {
      if (changes.length === 1) {
        // move to body to avoid issues with parent divs who have a transformation set
        // TODO create overlay component, add it to body and use ng-template
        document.body.appendChild(changes.first.nativeElement);
      }
    });
  }

  private detectChanges(): void {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  resetFilter(): void {
    this.removeFilter(undefined);
  }

  showOptions(): void {
    this.optionsHidden = false;
    this.detectChanges();
    setTimeout(() => {
      this.detectWidthChange();
    }, 0);

    if (this.isSmartTv) {
      window.setTimeout(() => {
        this.spatialNavigationService.register(
          NavigationSections.FILTER_OPTIONS,
          '.filter-option-smarttv',
          { restrict: 'self-only' },
        );
        this.spatialNavigationService.setFocus(NavigationSections.FILTER_OPTIONS);

        this.returnSubscription = this.returnObservable.subscribe((event) => {
          event.cancelBubble = true;
          this.hideOptions();
        });
      }, 100);
    }
  }

  hideOptions(): void {
    // setTimeout is necessary to detect changes (hack call stack)
    setTimeout(() => {
      this.optionsHidden = true;
      this.detectChanges();
      setTimeout(() => {
        this.detectWidthChange();
      }, 0);

      if (this.isSmartTv) {
        this.spatialNavigationService.unregister(NavigationSections.FILTER_OPTIONS);
        this.spatialNavigationService.setFocus(NavigationSections.FILTERS);
      }

      if (this.returnSubscription) {
        this.returnSubscription.unsubscribe();
        this.returnSubscription = null;
      }
    }, 0);
  }

  detectWidthChange(): void {
    if (this.filterWrapperRef) {
      if (!this.optionWrapperRef) {
        this.filterWrapperRef.nativeElement.style.width = 'auto';
      } else {
        const buttonConfigEl = this.optionWrapperRef.nativeElement;
        const width = buttonConfigEl.getBoundingClientRect().width;
        if (width) {
          this.filterWrapperRef.nativeElement.style.width = width + 'px';
        } else {
          this.filterWrapperRef.nativeElement.style.width = 'auto';
        }
      }
    }
  }

  private tryInitFromQueryParams(): void {
    const param = this.activeRoute.snapshot.queryParams[ContentFilters.DAY];
    if (param) {
      const dayIndex = this.filterDays.findIndex(
        (day) => moment(day).format('YYYYMMDDHHmm') === param
      );
      if (
        dayIndex !== undefined &&
        dayIndex >= 0 &&
        dayIndex !== this.filterModel.dayFilterOutputIndex
      ) {
        this.changeSelectedDayIndex(dayIndex, true);
        return;
      } else if (dayIndex === undefined || dayIndex < 0) {
        this.removeQueryParamForFilter();
      }
    }
    this.filterReady.emit();
  }

  changeSelectedDayIndex(index: number, firstRun = false): void {
    this.selectedDayTitle = this.getDayString(this.filterDays[index]);

    this.filterModel.dayFilterOutputIndex = index;
    this.addQueryParamForFilter(index);
    this.hideOptions();

    if (firstRun) {
      this.filterReady.emit();
    } else {
      this.filterChange.emit();
    }
  }

  private addQueryParamForFilter(index: number): void {
    const f = {};
    f[ContentFilters.DAY] = moment(this.filterDays[index]).format('YYYYMMDDHHmm');

    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: f,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  removeFilter(e: Event): void {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.selectedDayTitle = this.filterTitle;
    this.filterModel.dayFilterOutputIndex = undefined;
    this.removeQueryParamForFilter();
    this.filterChange.emit();
    this.detectChanges();
  }

  private removeQueryParamForFilter(): void {
    const f = {};
    f[ContentFilters.DAY] = undefined;
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: f,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  getDayString(date: Date): string {
    return SharedUtilityService.getDayInfo(date.valueOf(), false, this.config);
  }

  @HostListener('click', ['$event'])
  onClickedInside(): void {
    this.clickedInside = true;
  }

  @HostListener('document:click', ['$event'])
  onClickedOutside(): void {
    if (!this.clickedInside && !this.optionsHidden) {
      this.hideOptions();
    }
    this.clickedInside = false;
  }

  ngOnDestroy(): void {
    this.returnSubscription?.unsubscribe();
    this.overlayDivSubscription?.unsubscribe();
  }
}
