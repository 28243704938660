import { Injectable } from '@angular/core';
import { CmsAction, CmsActionMethod, CmsContentTypes } from '@atv-core/api/cms/cms-api.model';
import { DetailRoutes } from '@atv-detail/atv-detail.model';

import { DetailOverlayService } from '../../detail/detail-overlay.service';
import { CmsActionHandler } from '../cms-action.model';

@Injectable({ providedIn: 'root' })
export class DetailBundleAction extends CmsActionHandler {
  constructor(private detailService: DetailOverlayService) {
    super(null, null, null, null);
  }

  handlesMethod(method: CmsActionMethod): boolean {
    return method === CmsActionMethod.DETAIL;
  }

  handlesType(type: CmsContentTypes): boolean {
    return type === CmsContentTypes.BUNDLE;
  }

  handleAction(action: CmsAction): void {
    this.detailService.openDetail(DetailRoutes.bundleDetailRoute, action.actionId);
  }
}
