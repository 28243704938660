import { AdvertisementTypes } from '@atv-core/api/acs/acs-api.model';
import { AssetAction, CmsActionMethod } from '@atv-core/api/cms';
import {
  Stream2Advertisement,
  Stream2AdvertisementBase,
  Stream2BreakvertisementResponse,
} from '@atv-core/api/ss/streaming-api.model';
import { ConfigService, PlayerTranslationKeys } from '@atv-bootstrap/services/config';

export class AdvertisementElement implements Stream2Advertisement, Stream2BreakvertisementResponse {
  image: string;
  mid: string;
  type: AdvertisementTypes;
  campaignId: string;
  campaignReportingId: string;
  advertisementId: string;
  advertisementReportingId: string;
  skipTime?: number;
  action?: AssetAction;

  actionText: string;
  advertisementStartTime: Date;
  actionPerformed = false;

  // extra logging info
  adDuration: number;
  skippedAfter: number;
  manifestUrl: string;

  constructor(ad: Stream2AdvertisementBase, start: Date, config: ConfigService) {
    this.advertisementStartTime = start;

    Object.keys(ad).map((key) => (this[key] = ad[key]));
    if (this.action) {
      if (this.action.method === CmsActionMethod.DETAIL) {
        this.actionText = config.getTranslation(
          PlayerTranslationKeys.player_ad_action_method_detail_ott
        );
      } else if (this.action.method === CmsActionMethod.PLAY) {
        this.actionText = config.getTranslation(
          PlayerTranslationKeys.player_ad_action_method_play_ott
        );
      } else if (this.action.method === CmsActionMethod.PLAY_TRAILER) {
        this.actionText = config.getTranslation(
          PlayerTranslationKeys.player_ad_action_method_play_trailer_ott
        );
      }
    }
  }

  public isBreakvertising(): boolean {
    return this.type === AdvertisementTypes.BREAK;
  }
}
