import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { CmsAction, CmsActionMethod, CmsContentTypes, MenuItem } from '@atv-core/api/cms/cms-api.model';
import { MenuManagerService } from '@atv-core/services/menu-manager/menu-manager.service';

import { CmsActionHandler } from '../cms-action.model';

@Injectable({ providedIn: 'root' })
export class DetailPageAction extends CmsActionHandler {
  constructor(private injector: Injector, private router: Router) {
    super(null, null, null, null);
  }

  handlesMethod(method: CmsActionMethod): boolean {
    return method === CmsActionMethod.DETAIL;
  }

  handlesType(type: CmsContentTypes): boolean {
    return type === CmsContentTypes.PAGE;
  }

  handleAction(action: CmsAction, forceFullScreen = false): void {
    if (forceFullScreen || this.isCustomOpen()) {
      this.openFullScreen(action.actionId);
      return;
    }

    // check if page is known in menu
    const targetRoute = this.findPageInMenu(
      action.actionId,
      this.injector.get(MenuManagerService).currentMenu.items,
      '/main'
    );

    if (targetRoute) {
      this.openMain(targetRoute);
    } else {
      this.openFullScreen(action.actionId);
    }
  }

  private findPageInMenu(id: string, items: MenuItem[], route: string): string {
    for (const item of items) {
      if (item.pageId === id) {
        return `${route}/${item.tag}`;
      } else if (item.items?.length > 0) {
        const rec = this.findPageInMenu(id, item.items, `${route}/${item.tag}`);
        if (rec) {
          return rec;
        }
      }
    }
    return undefined;
  }

  private openFullScreen(id: string): void {
    this.router.navigate([{ outlets: { detailOverlay: null } }]);

    window.setTimeout(() => {
      this.router.navigate([`/custom/${id}`]);
    }, 0);
  }

  private openMain(route: string): void {
    this.router.navigate([{ outlets: { detailOverlay: null } }]);

    window.setTimeout(() => {
      this.router.navigate([route]);
    }, 0);
  }

  private isCustomOpen(): boolean {
    return this.router.url.startsWith('/custom/');
  }
}
