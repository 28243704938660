import { StreamAssetRequestOptions, StreamDrmTypes, StreamPackageTypes, StreamRequestGroups } from '@atv-core/api/api/api-api.model';
import { PlatformType, StreamResolutionType, StreamType } from '@atv-core/utility/constants/shared';

import { AdvertisementTypes } from '../acs/acs-api.model';
import { AssetAction } from '../cms';
import { ScheduleTime } from '@atv-core/api/history';

export enum KeepAliveStatus {
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
  IDLE = 'IDLE',
}

export interface Stream2StartResponse {
  sid: string;
  keepalive?: number;
  lifetime?: number;
  asset?: Stream2AssetResponse;
}

export interface Stream2AssetResponse {
  mid: string;
  bookmarkOffset?: number;
  advertisements?: Stream2Advertisement[];
  event?: ScheduleTime;
  streamEndTime?: string;
  sessionLifetime?: number;
}

export interface Stream2StartRequest {
  profileId: string;
  group: StreamRequestGroups;
  device?: string;
  deviceType?: PlatformType;
  packageType: StreamPackageTypes;
  drmType: StreamDrmTypes;
  asset?: Stream2AssetRequest;
  catalogId: string;
}
export interface Stream2AssetRequest {
  type: StreamType;
  reserve?: boolean;
  resource: string;
  quoteId?: string;
  maxResolution: StreamResolutionType;
  options?: StreamAssetRequestOptions[];
}

export interface Stream2ManifestResponse {
  url: string;
  drm?: boolean;
  assetId?: string;
  drmSessionId?: string;
  drmAuthToken?: string;
}

export interface Stream2KeepAliveRequest {
  status: KeepAliveStatus;
  mid?: string;
}

export interface Stream2Bookmark {
  position?: number;
  size?: number;
}

export interface Stream2AdvertisementBase {
  type: AdvertisementTypes;
  action?: AssetAction;
  campaignId: string;
  campaignReportingId: string;
  advertisementId: string;
  advertisementReportingId: string;
}

export class Stream2Advertisement implements Stream2AdvertisementBase {
  mid: string;
  type: AdvertisementTypes;
  campaignId: string;
  campaignReportingId: string;
  advertisementId: string;
  advertisementReportingId: string;
  skipTime?: number;
  action?: AssetAction;
}

export class Stream2BreakvertisementResponse implements Stream2AdvertisementBase {
  type: AdvertisementTypes;
  action?: AssetAction;
  campaignId: string;
  campaignReportingId: string;
  advertisementId: string;
  advertisementReportingId: string;
  image: string;
}

export interface Stream2TerminateRequest {
  mid?: string;
  bookmark?: Stream2Bookmark;
  watchlist?: boolean;
}

export interface Stream2Bookmark {
  position?: number;
  size?: number;
}

export interface Stream2Option {
  options: StreamAssetRequestOptions[];
}
