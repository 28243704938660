import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService, RibbonTranslationKeys, DetailTranslationKeys } from '@atv-bootstrap/services/config';
import {
  NavigationSections,
  SpatialNavigationService,
} from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ContentFilters, FilterModel } from '../filters.model';
import { AdultService, AdultMode } from '@atv-core/services/adult';
import { MessagesService } from '@atv-core/services/messages';

@Component({
  selector: 'app-score-filter',
  templateUrl: './score-filter.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreFilterComponent implements AfterViewInit, OnDestroy {
  @Input()
  filterModel: FilterModel;

  @Input()
  allowedAdultMode: AdultMode;

  @Output()
  filterReady = new EventEmitter();

  @Output()
  filterChange = new EventEmitter();

  private clickedInside = false;

  @ViewChild('filterWrapperRef', { static: true })
  filterWrapperRef: ElementRef;
  @ViewChild('optionWrapperRef')
  optionWrapperRef: ElementRef;

  selectedScoreIndex = undefined;
  filterTitle = '';
  optionsHidden = true;

  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnObservable: Observable<KeyboardEvent>;
  @ViewChildren('overlayDiv') overlayDiv: QueryList<ElementRef<HTMLDivElement>>;
  private returnSubscription: Subscription;
  private overlayDivSubscription: Subscription;

  constructor(
    private config: ConfigService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private spatialNavigationService: SpatialNavigationService,
    private adultService: AdultService,
    private messagesService: MessagesService,
  ) {
    this.filterTitle = this.config.getTranslation(RibbonTranslationKeys.ribbon_filter_rating);

    if (this.isSmartTv) {
      this.returnObservable = fromEvent(document, 'keyup').pipe(
        filter((event: KeyboardEvent) =>
          this.spatialNavigationService.keyCodeIsReturn(event.keyCode)
        )
      );
    }
  }

  ngAfterViewInit(): void {
    this.tryInitFromQueryParams();

    this.overlayDivSubscription = this.overlayDiv.changes.subscribe(changes => {
      if (changes.length === 1) {
        // move to body to avoid issues with parent divs who have a transformation set
        // TODO create overlay component, add it to body and use ng-template
        document.body.appendChild(changes.first.nativeElement);
      }
    });
  }

  resetFilter() {
    this.tryRemoveFilter(undefined);
    this.filterReady.emit();
  }

  private detectChanges() {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  showOptions() {
    if (this.allowedAdultMode === AdultMode.true) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.getOptions();
        }, errorCallback: () => {
          this.messagesService.showErrorMessage(
            this.config.getTranslation(DetailTranslationKeys.detail_adult_warning),
          );
        },
      });
    } else {
      this.getOptions();
    }
  }

  private getOptions(): void {
    this.optionsHidden = false;
    this.detectChanges();

    if (!this.isSmartTv) {
      setTimeout(() => {
        this.detectWidthChange();
      }, 0);
    } else {
      window.setTimeout(() => {
        this.spatialNavigationService.register(
          NavigationSections.FILTER_OPTIONS,
          '.filter-option-smarttv',
          { restrict: 'self-only' },
        );
        this.spatialNavigationService.setFocus(NavigationSections.FILTER_OPTIONS);

        this.returnSubscription = this.returnObservable.subscribe((event) => {
          event.cancelBubble = true;
          this.hideOptions();
        });
      }, 100);
    }
  }

  hideOptions() {
    this.optionsHidden = true;
    this.detectChanges();
    if (!this.isSmartTv) {
      setTimeout(() => {
        this.detectWidthChange();
      }, 0);
    } else {
      this.spatialNavigationService.unregister(NavigationSections.FILTER_OPTIONS);
      this.spatialNavigationService.setFocus(NavigationSections.FILTERS);
    }

    if (this.returnSubscription) {
      this.returnSubscription.unsubscribe();
      this.returnSubscription = null;
    }
  }

  detectWidthChange() {
    if (this.filterWrapperRef) {
      if (!this.optionWrapperRef) {
        this.filterWrapperRef.nativeElement.style.width = 'auto';
      } else {
        const buttonConfigEl = this.optionWrapperRef.nativeElement;
        const width = buttonConfigEl.getBoundingClientRect().width;
        if (width) {
          this.filterWrapperRef.nativeElement.style.width = width + 'px';
        } else {
          this.filterWrapperRef.nativeElement.style.width = 'auto';
        }
      }
    }
  }

  private tryInitFromQueryParams() {
    const param = this.activeRoute.snapshot.queryParams[ContentFilters.SCORE];
    if (param) {
      const scoreIndex = this.filterModel.filterScores.findIndex(
        (score) => score === parseInt(param)
      );
      if (scoreIndex !== undefined && scoreIndex >= 0 && scoreIndex !== this.selectedScoreIndex) {
        this.changeSelectedScoreIndex(scoreIndex, true);
        return;
      } else if (scoreIndex === undefined || scoreIndex < 0) {
        this.removeQueryParamForFilter();
      }
    }

    this.filterReady.emit();
  }

  changeSelectedScoreIndex(index, firstRun = false) {
    this.selectedScoreIndex = index;
    this.filterModel.scoreFilterOutputValue = this.filterModel.filterScores[
      this.selectedScoreIndex
    ];
    this.addQueryParamForFilter(index);

    this.hideOptions();

    if (firstRun) {
      this.filterReady.emit();
    } else {
      this.filterChange.emit();
    }
  }

  private addQueryParamForFilter(index) {
    const filter = {};
    filter[ContentFilters.SCORE] = this.filterModel.filterScores[index];
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: filter,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  tryRemoveFilter(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (this.allowedAdultMode === AdultMode.true) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.removeFilter();
        }, errorCallback: () => {
          this.messagesService.showErrorMessage(
            this.config.getTranslation(DetailTranslationKeys.detail_adult_warning),
          );
        },
      });
    } else {
      this.removeFilter();
    }
  }

  private removeFilter(): void {
    this.removeQueryParamForFilter();
    this.selectedScoreIndex = undefined;
    this.filterModel.scoreFilterOutputValue = undefined;
    this.filterChange.emit();
    this.detectChanges();
  }

  private removeQueryParamForFilter() {
    const filter = {};
    filter[ContentFilters.SCORE] = undefined;
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: filter,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  @HostListener('click', ['$event'])
  onClickedInside($event: Event) {
    this.clickedInside = true;
  }

  @HostListener('document:click', ['$event'])
  onClickedOutside($event) {
    if (!this.clickedInside && !this.optionsHidden) {
      this.hideOptions();
    }
    this.clickedInside = false;
  }

  ngOnDestroy(): void {
    this.returnSubscription?.unsubscribe();
    this.overlayDivSubscription?.unsubscribe();
  }
}
