<div class="filter-wrapper" #filterWrapperRef>
  <div class="filter-current-state-wrapper" (smartTvKeyDownEnter)="showOptions()">
    <div
      *ngIf="!isSmartTv"
      [class.hidden]="filterModel?.dayFilterOutputIndex === undefined"
      class="filter-remove"
      (click)="removeFilter($event)"
    ></div>
    <div class="filter-current-state" (click)="showOptions()">
      <div class="filter-current-state-title">{{ selectedDayTitle }}</div>
      <div class="filter-show-options" *ngIf="!isSmartTv"></div>
    </div>
  </div>
  <div class="filter-options-wrapper" *ngIf="!isSmartTv && !optionsHidden" #optionWrapperRef>
    <div class="filter-options-header" (click)="hideOptions()">
      <div class="filter-options-header-title">{{ filterTitle }}</div>
      <div class="filter-options-close"></div>
    </div>
    <div class="filter-options">
      <div
        class="filter-option"
        *ngFor="let day of filterDays; index as i"
        [class.selected]="filterModel?.dayFilterOutputIndex === i"
        (click)="changeSelectedDayIndex(i)"
      >
        <div class="filter-option-title">{{ getDayString(day) }}</div>
      </div>
    </div>
  </div>
</div>

<div #overlayDiv *ngIf="isSmartTv && !optionsHidden" class="overlay">
  <div class="overlay-dialog">
    <div
      class="filter-option-smarttv"
      *ngFor="let day of filterDays; index as i"
      (smartTvKeyDownEnter)="changeSelectedDayIndex(i)"
      (click)="changeSelectedDayIndex(i)"
    >
      {{ getDayString(day) }}
    </div>
  </div>
</div>
