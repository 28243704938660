import 'requestidlecallback-polyfill';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { CardOrientation, CardSize, CmsApiService, CmsRibbon, Properties, Ribbon2Asset, RibbonTitleIcon } from '@atv-core/api/cms';
import { ChannelCardModes } from '@atv-core/api/epg';
import { VodApiService } from '@atv-core/api/vod';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { BookmarkCacheService } from '@atv-core/services/cache/bookmark';
import { FavoriteCacheService } from '@atv-core/services/cache/favorite';
import { SessionService } from '@atv-core/services/session';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { finalize } from 'rxjs/operators';

enum SlideDirection {
  PREV = -1,
  NEXT = 1,
}

class RibbonVisualModel {
  title = '';
  titleIcons: RibbonTitleIcon[];
  contentMessage = '';
  canNext = false;
  canPrev = false;
}

@Component({
  selector: 'app-ribbon',
  templateUrl: './ribbon.component.html',
  styleUrls: ['./ribbon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RibbonComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('ribbonItems') ribbonItems?: ElementRef<HTMLDivElement>;
  @Input() pageProperties: Properties[];
  @Input() ribbon: CmsRibbon;
  @Input() hideTitle = false;
  @Input() allowedAdultMode: AdultMode;
  @Input() isOnSearchPage = false;
  @Output() visibilityChanged = new EventEmitter();
  ribbonObserver: IntersectionObserver;
  public cardOrientation: CardOrientation;
  public cardSize: CardSize;
  showingLastRibbonItem = false;
  hideRibbon = false;
  showContentMessage = false;
  ribbonContents = [];
  visibleRibbonContents = [];
  ribbonChannelCardMode: ChannelCardModes = ChannelCardModes.NONE;
  firstVisibleItemIndex = 0;
  ribbonVisualModel = new RibbonVisualModel();
  ribbonPage = 0;
  allDataRetrieved = false; // TODO is this necessary? Get from contentProvider?
  gettingResults = false;
  contentRequests = [];
  public isSmartTv = SharedUtilityService.isSmartTv();
  setInitialHeight = true;

  constructor(
    private cmsApi: CmsApiService,
    private favoriteCache: FavoriteCacheService,
    private cdr: ChangeDetectorRef,
    private bookmarkCache: BookmarkCacheService,
    private vodApi: VodApiService,
    private adultService: AdultService,
    private sessionService: SessionService,
    private ribbonElementRef: ElementRef,
  ) {
    // TODO add resize listener on ribbon element using ResizeObserver
    window.addEventListener('resize', () => this.onWindowResize());
  }

  @Input() set registerOnObserver(observer: IntersectionObserver) {
    if (observer) {
      observer.observe(this.ribbonElementRef.nativeElement);
      this.ribbonObserver = observer;
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.ribbon) {
      this.ribbon.contentProvider.resetPaging();
      this.cardSize = this.ribbon.cardSize;
      this.getRibbonContent();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ribbon?.currentValue) {
      this.resetRibbon();
      this.ribbonVisualModel.titleIcons = this.ribbon.titleIcons;
      this.ribbonVisualModel.title = this.ribbon.title;
    }


    if (this.ribbonItems) {
      this.getRibbonContent();
    }
  }

  prev(): void {
    this.slideRibbon(SlideDirection.PREV);
  }

  checkCanPrev(): boolean {
    return this.firstVisibleItemIndex > 0;
  }

  next(): void {
    this.checkVisibleRibbonContents();
    this.slideRibbon(SlideDirection.NEXT);
  }

  private detectChanges(): void {
    // prevent change detection from firing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }

    if (!this.isSmartTv) {
      this.ribbonVisualModel.canNext = this.checkCanNext();
      this.ribbonVisualModel.canPrev = this.checkCanPrev();
      if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    }
  }

  private resetRibbon(): void {
    this.showingLastRibbonItem = false;
    this.hideRibbon = false;
    this.visibilityChanged.emit();
    this.showContentMessage = false;
    this.ribbonContents = [];
    this.visibleRibbonContents = [];
    this.ribbonChannelCardMode = this.ribbon.contentProvider.getChannelCardMode();

    this.contentRequests.forEach((req) => req.unsubscribe());
    this.contentRequests = [];
    this.ribbonPage = 0;
    this.allDataRetrieved = false;
    this.gettingResults = false;

    this.firstVisibleItemIndex = 0;

    this.ribbonVisualModel = new RibbonVisualModel();
    if (this.ribbonItems) {
      this.ribbonItems.nativeElement.style.transform = 'translateX(0px)';
    }
  }

  private getRibbonContent(): void {
    if (!this.ribbon) {
      this.hideRibbon = true;
      this.visibilityChanged.emit();
      return;
    }

    if (this.allDataRetrieved || this.gettingResults) {
      return;
    }

    if (this.ribbon.contentProvider && this.ribbon.contentProvider.hasNext()) {
      this.gettingResults = true;

      const req = this.ribbon.contentProvider.getItems().pipe(finalize(() => {
        this.gettingResults = false;
        if (this.ribbonContents.length === 0) {
          // show backup content if no results at all
          this.ribbonBackUpContent();
        }
      })).subscribe(items => this.handleItems(items));
      this.contentRequests.push(req);
    } else if (!this.ribbon.contentProvider) {
      // TODO remove this, should always use the contentProvider
      this.ribbonBackUpContent();
    }
  }

  private handleItems(items?: Ribbon2Asset[]): void {
    this.setInitialHeight = false;
    if (!items || items.length === 0) {
      this.allDataRetrieved = true;
      return;
    }

    if (!this.cardOrientation) {
      this.cardOrientation = this.ribbon.contentProvider.getOrientation();
    }

    this.allDataRetrieved = !this.ribbon.contentProvider.hasNext();

    if (!this.ribbonContents) {
      this.ribbonContents = [];
    }
    this.ribbonContents = this.ribbonContents.concat(items);
    this.checkVisibleRibbonContents();
  }

  private ribbonBackUpContent(): void {
    this.setInitialHeight = false;
    if (!this.ribbon) {
      this.hideRibbon = true;
      this.visibilityChanged.emit();
      return;
    }

    this.cardOrientation = this.ribbon.orientation;

    this.ribbonVisualModel.title = this.ribbon.title;
    if (this.ribbon.contents?.length > 0) {
      this.ribbonContents = this.ribbon.contents;
      this.allDataRetrieved = true;
      this.checkVisibleRibbonContents();
    } else if (this.ribbon.contentMessage) {
      this.ribbonVisualModel.contentMessage = this.ribbon.contentMessage;
      this.showContentMessage = true;
      this.allDataRetrieved = true;
    } else {
      this.hideRibbon = true;
      this.visibilityChanged.emit();
      this.allDataRetrieved = true;
    }

    this.detectChanges();
  }

  private checkVisibleRibbonContents(): void {
    const itemWidth = this.getRibbonItemsWidth();
    const boundingRectParent = this.ribbonItems?.nativeElement.parentElement.getBoundingClientRect();
    // use default 200 px width on first run no items are visible so we don't know item width
    let itemsCompletelyVisible = Math.trunc(boundingRectParent.width / (itemWidth || 200));
    // minimum of 5 items visible
    itemsCompletelyVisible = itemsCompletelyVisible === 0 ? 5 : itemsCompletelyVisible;

    if (this.visibleRibbonContents.length === 0) {
      this.assignVisibleContent(itemsCompletelyVisible);
    } else if (
      this.visibleRibbonContents.length !== this.ribbonContents.length ||
      !this.allDataRetrieved
    ) {
      // 1) first check if it is needed to fetch or assign extra (prevent assign extra on window resize if not needed)
      // fetch nothing when there are enough items in visible buffer
      // should be 3 multiplier: to make sure there is data available after the visible ribbon you are scrolling to

      if (
        this.firstVisibleItemIndex + 3 * itemsCompletelyVisible >
        this.visibleRibbonContents.length
      ) {
        this.assignVisibleContent(itemsCompletelyVisible);
      }
    }

    this.detectChanges();
  }

  private assignVisibleContent(itemsCompletelyVisible: number): void {
    if (
      !this.allDataRetrieved &&
      this.visibleRibbonContents.length + 3 * itemsCompletelyVisible > this.ribbonContents.length
    ) {
      this.getRibbonContent();
    }

    this.visibleRibbonContents = this.visibleRibbonContents.concat(
      this.ribbonContents.slice(
        this.visibleRibbonContents.length,
        this.visibleRibbonContents.length +
        // on first run load 2x the visible items
        (this.visibleRibbonContents.length === 0 ? 2 : 1) * itemsCompletelyVisible +
        2,
      ),
    );

    this.detectChanges();
    this.visibilityChanged.emit();

    if (!this.isSmartTv) {
      this.ribbonVisualModel.canNext = this.checkCanNext();
      this.ribbonVisualModel.canPrev = this.checkCanPrev();
    }
  }

  private slideRibbon(direction: SlideDirection): void {
    this.showingLastRibbonItem = false;
    const totalItems = this.ribbonItems?.nativeElement.children.length;
    const itemWidth = this.getRibbonItemsWidth();
    const boundingRectParent = this.ribbonItems?.nativeElement.parentElement.getBoundingClientRect();
    let itemsCompletelyVisible = Math.trunc(boundingRectParent.width / itemWidth);

    // to fix bug scrolling not possible when window is so small that only part of one item is visible
    itemsCompletelyVisible = itemsCompletelyVisible === 0 ? 1 : itemsCompletelyVisible;
    let targetPosition: number;

    this.firstVisibleItemIndex += direction * itemsCompletelyVisible;
    if (direction === SlideDirection.PREV) {
      this.showingLastRibbonItem = false;
    }
    if (this.firstVisibleItemIndex < 0) {
      this.firstVisibleItemIndex = 0;
    } else if (totalItems - this.firstVisibleItemIndex <= itemsCompletelyVisible) {
      this.showingLastRibbonItem = true;
      this.firstVisibleItemIndex = totalItems - itemsCompletelyVisible;
    }

    if (this.firstVisibleItemIndex > totalItems) {
      this.showingLastRibbonItem = true;
      this.firstVisibleItemIndex = totalItems - itemsCompletelyVisible;
    }

    if (this.showingLastRibbonItem) {
      targetPosition = this.getRightAlignPosition();
    } else {
      targetPosition = this.getLeftAlignPosition();
    }

    // do the slide
    this.ribbonItems.nativeElement.style.transform = 'translateX(' + targetPosition + 'px)';
    this.detectChanges();
  }

  private getLeftAlignPosition(): number {
    if (!this.ribbonItems) {
      return undefined;
    }
    const firstVisibleItem = this.ribbonItems.nativeElement.children[this.firstVisibleItemIndex];
    if (!firstVisibleItem) {
      return undefined;
    }
    const firstVisibleItemsBoundingRect = firstVisibleItem.getBoundingClientRect();
    const itemsBoundingRect = this.ribbonItems.nativeElement.getBoundingClientRect();

    return -Math.floor(firstVisibleItemsBoundingRect.left - itemsBoundingRect.left);
  }

  private getRightAlignPosition(): number {
    if (!this.ribbonItems || !this.ribbonItems.nativeElement.parentElement) {
      return undefined;
    }
    const boundingRectParent = this.ribbonItems.nativeElement.parentElement.getBoundingClientRect();
    const AllItemsWidth =
      this.ribbonItems.nativeElement.getBoundingClientRect().width - this.getLastItemMarginRight();
    return Math.ceil(boundingRectParent.right - boundingRectParent.left - AllItemsWidth);
  }

  private onWindowResize(): void {
    if (this.ribbonItems.nativeElement.children.length === 0) {
      return;
    }

    this.checkVisibleRibbonContents();

    const itemWidth = this.getRibbonItemsWidth();
    const totalItems = this.ribbonItems.nativeElement.children.length;
    const boundingRectLastItem = this.ribbonItems.nativeElement.lastElementChild.getBoundingClientRect();
    const boundingRectParent = this.ribbonItems.nativeElement.parentElement.getBoundingClientRect();
    let itemsCompletelyVisible = Math.trunc(boundingRectParent.width / itemWidth);

    // to fix bug scrolling not possible when window is so small that only part of one item is visible
    itemsCompletelyVisible = itemsCompletelyVisible === 0 ? 1 : itemsCompletelyVisible;
    let targetPosition;

    // if all children fit on screen
    if (
      this.ribbonItems.nativeElement.getBoundingClientRect().width - this.getLastItemMarginRight() <
      boundingRectParent.width
    ) {
      this.firstVisibleItemIndex = 0;
      targetPosition = this.getLeftAlignPosition();
      this.showingLastRibbonItem = false;
      // if last items was being shown or last item is before right side of screen, snap last item to the right
    } else if (
      this.showingLastRibbonItem ||
      boundingRectLastItem.right < boundingRectParent.right
    ) {
      // snap most left visible item to the left of the screen
      this.firstVisibleItemIndex = totalItems - itemsCompletelyVisible;
      this.showingLastRibbonItem = true;
      targetPosition = this.getRightAlignPosition();
    } else {
      targetPosition = this.getLeftAlignPosition();
    }

    this.ribbonItems.nativeElement.style.transform = 'translateX(' + targetPosition + 'px)';
  }

  private checkCanNext(): boolean {
    if (this.getRightAlignPosition() === this.getLeftAlignPosition()) {
      return false;
    }

    if (
      !this.ribbonItems.nativeElement.children ||
      this.ribbonItems.nativeElement.children.length === 0
    ) {
      return false;
    }

    if (this.showingLastRibbonItem) {
      return false;
    }

    return Math.floor(
      this.ribbonItems.nativeElement.getBoundingClientRect().width - this.getLastItemMarginRight(),
    ) > this.ribbonItems.nativeElement.parentElement.getBoundingClientRect().width;

  }

  private getRibbonItemsWidth(): number {
    const item = this.ribbonItems.nativeElement.firstElementChild;

    if (!item) {
      return undefined;
    }
    const style = window.getComputedStyle(item, null);

    return this.getStylePropertyValueAsInt(style, 'margin-left') +
           this.getStylePropertyValueAsInt(style, 'margin-right') +
           this.getStylePropertyValueAsInt(style, 'width');
  }

  private getLastItemMarginRight(): number {
    const item = this.ribbonItems.nativeElement.lastElementChild;
    if (!item) {
      return undefined;
    }
    const style = window.getComputedStyle(item, null);

    return this.getStylePropertyValueAsInt(style, 'margin-right');
  }

  private getStylePropertyValueAsInt(style, property): number {
    return parseInt(style.getPropertyValue(property), 10);
  }

  ngOnDestroy(): void {
    this.contentRequests.forEach((req) => req.unsubscribe());

    if (this.ribbonObserver) {
      this.ribbonObserver.unobserve(this.ribbonElementRef.nativeElement);
      this.ribbonObserver.disconnect();
    }
  }
}
