import { Injectable } from '@angular/core';
import {
  EpgCreditRoles,
  EpgProgramDetailAsset,
  EpgScheduleDetailAsset,
  EpgScheduleSummaryAsset,
  EpgScheduleTimeAsset,
  EpgSeasonSummaryAsset,
  EpgSeriesDetailAsset,
} from '@atv-core/api/epg';
import { ChannelModel } from '@atv-core/services/cache/channel';
import { RecordingModel } from '@atv-core/services/cache/stb/recording.model';
import { ConfigService, DetailTranslationKeys, SettingsKeys } from '@atv-bootstrap/services/config';
import { SessionService } from '@atv-core/services/session/session.service';
import { environment } from '@env/environment';

import { AtvFeatures } from '../constants/atv_features';
import { StreamType } from '../constants/shared';
import { SharedUtilityService } from '../shared/shared-utility';
import { ScheduleTime } from '@atv-core/api/history';
import { BlackoutFactory } from '@atv-core/utility/epg-utility/blackout-factory';
import { ImageRecipe, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';

export interface FromUntil {
  from: Date;
  until: Date;
}

export class CreditInfoItem {
  creditTitle = '';
  content = '';
}

@Injectable({ providedIn: 'root' })
export class EpgUtilityService {
  constructor(private config: ConfigService, private sessionService: SessionService) {
  }

  public findClosestSchedule(
    schedules: EpgScheduleDetailAsset[],
    replay: boolean = false,
    channels: ChannelModel[],
  ): EpgScheduleDetailAsset {
    if (replay) {
      this.sortSchedulesDetailAssetByStartDateDESC(schedules);
    } else {
      this.sortSchedulesDetailAssetByStartDateASC(schedules);
    }

    let filteredSchedules: EpgScheduleDetailAsset = this.findFirstScheduledItem(
      schedules,
      replay,
      channels,
    );

    // try other way around NEBINT-2316 when nothing is found
    if (!filteredSchedules) {
      filteredSchedules = this.findFirstScheduledItem(schedules, !replay, channels);
    }

    // if no schedule is found take the last one
    if (!filteredSchedules) {
      filteredSchedules = this.findLastAvailableScheduleItems(schedules);
    }
    return filteredSchedules;
  }

  // select the first schedule that is playing or that is up next, for now and before for replay

  public findCurrentSchedule(schedules: EpgScheduleSummaryAsset[]): EpgScheduleSummaryAsset {
    if (!schedules || schedules.length === 0) {
      return undefined;
    }
    const now = new Date().getTime();

    return schedules.find((sched) => {
      const start = SharedUtilityService.timeStringToMs(sched.published.start);
      const end = SharedUtilityService.timeStringToMs(sched.published.end);
      return now >= start && now <= end;
    });
  }

  // Sort schedules descending by start date
  public sortSchedulesDetailAssetByStartDateDESC(schedules: EpgScheduleDetailAsset[]): void {
    this.sortSchedulesByStartDateDESC(schedules);
  }

  public sortSchedulesSummaryAssetByStartDateDESC(schedules: EpgScheduleSummaryAsset[]): void {
    this.sortSchedulesByStartDateDESC(schedules);
  }

  // Sort schedules ascending by start date
  public sortSchedulesDetailAssetByStartDateASC(schedules: EpgScheduleDetailAsset[]): void {
    this.sortSchedulesByStartDataASC(schedules);
  }

  public sortSchedulesSummaryAssetByStartDateASC(schedules: EpgScheduleSummaryAsset[]): void {
    this.sortSchedulesByStartDataASC(schedules);
  }

  // calculate the progress of the epg asset in percentage
  public calculateProgressPercentage(scheduleTime: ScheduleTime): number {
    if (!scheduleTime) {
      return;
    }

    const now = new Date().getTime();
    const start = SharedUtilityService.timeStringToMs(scheduleTime.start);

    if (now < start) {
      return undefined; // shortcut ASAP
    }

    const end = SharedUtilityService.timeStringToMs(scheduleTime.end);

    if (now > end) {
      return undefined;
    }

    const duration = end - start;
    const elapsed = now - start;
    return Math.round((elapsed / duration) * 100);
  }

  public scheduleHasBlackout(scheduleBlackouts: string[], blackout: string): boolean {
    return scheduleBlackouts?.some(b => b === blackout);
  }

  public scheduleIsLive(schedule: EpgScheduleSummaryAsset): boolean {
    if (!schedule) {
      return;
    }
    const now = new Date().getTime();
    return (
      SharedUtilityService.timeStringToMs(schedule.published.start) <= now &&
      SharedUtilityService.timeStringToMs(schedule.published.end) >= now
    );
  }

  public scheduleIsReplay(time: EpgScheduleTimeAsset, channel: ChannelModel): boolean {
    if (!channel.replayHours || !time) {
      return false;
    }
    const replayStart = new Date();
    replayStart.setHours(replayStart.getHours() - channel.replayHours);
    return (
      SharedUtilityService.timeStringToMs(time.start) >= replayStart.getTime() &&
      SharedUtilityService.timeStringToMs(time.start) < new Date().getTime()
    );
  }

  public getEpgDaysFromUntil(): FromUntil {
    const from = new Date();
    from.setDate(from.getDate() - this.config.getSettingNumber(SettingsKeys.epgPastDays, 7));

    const until = new Date();
    until.setDate(until.getDate() + this.config.getSettingNumber(SettingsKeys.epgPastDays, 7));

    return { from, until };
  }

  public getTodayFromUntil(): FromUntil {
    const now = new Date();
    const from: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    const until = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
    return { from, until };
  }

  public getFromUntilFromTimeForDay(time: Date): FromUntil {
    return {
      from: new Date(time.getFullYear(), time.getMonth(), time.getDate(), 0, 0, 0, 0),
      until: new Date(time.getFullYear(), time.getMonth(), time.getDate() + 1, 0, 0, 0, 0),
    };
  }

  public getFromUntilFromTime(time: Date, replay?): FromUntil {
    let from: Date;
    let until: Date;

    if (replay) {
      from = new Date(
        time.getFullYear(),
        time.getMonth(),
        time.getDate(),
        time.getHours() + 1 - 24,
        0,
        0,
        0,
      );
      until = new Date(
        time.getFullYear(),
        time.getMonth(),
        time.getDate(),
        time.getHours() + 1,
        0,
        0,
        0,
      );
    } else {
      from = new Date(
        time.getFullYear(),
        time.getMonth(),
        time.getDate(),
        time.getHours(),
        0,
        0,
        0,
      );
      until = new Date(
        time.getFullYear(),
        time.getMonth(),
        time.getDate() + 1,
        time.getHours(),
        0,
        0,
        0,
      );
    }

    return { from, until };
  }

  public getSeasonEpisodeInfo(episode?: number, season?: number): string {
    let result = '';
    if (season) {
      result += `${this.config.getTranslation(DetailTranslationKeys.detail_season)} ${season}`;
    }

    if (episode) {
      if (result) {
        result += ', ';
      }

      result += `${this.config.getTranslation(DetailTranslationKeys.detail_episode)} ${episode}`;
    }

    return result;
  }

  public getScheduleInfo(scheduleTime?: EpgScheduleTimeAsset, recording?: RecordingModel): string {
    if (!scheduleTime && !recording) {
      return '';
    }

    let start: string;
    let end: string;
    if (scheduleTime) {
      start = scheduleTime.start;
      end = scheduleTime.end;
    } else if (recording && recording.start && recording.end) {
      start = recording.start;
      end = recording.end;
    }

    if (!start || !end) {
      return '';
    }

    const startMoment = SharedUtilityService.timeStringToMoment(start);
    const endMoment = SharedUtilityService.timeStringToMoment(end);

    let scheduleInfo = SharedUtilityService.getDayInfo(
      SharedUtilityService.timeStringToMs(start),
      false,
      this.config,
    );

    scheduleInfo += startMoment.format(', HH:mm');
    scheduleInfo += endMoment.format(' - HH:mm');
    return scheduleInfo;
  }

  public getDetailBackdropForProgram(program: EpgProgramDetailAsset): string {
    if (!program || program.adult) {
      return;
    }
    let backdrop = '';
    if (program.wallpaperImage) {
      backdrop = ImageRecipeUtil.createImageUrl(program.wallpaperImage, ImageRecipe.DETAIL_BACKDROP_1);
    } else if (program.posterImage) {
      backdrop = ImageRecipeUtil.createImageUrl(program.posterImage, ImageRecipe.DETAIL_BACKDROP_1);
    }
    return backdrop;
  }

  public getDetailBackdropForSeries(series: EpgSeriesDetailAsset): string {
    if (!series || series.adult) {
      return;
    }

    let backdrop = '';
    if (series.wallpaperImage) {
      backdrop = ImageRecipeUtil.createImageUrl(series.wallpaperImage, ImageRecipe.DETAIL_BACKDROP_1);
    } else if (series.posterImage) {
      backdrop = ImageRecipeUtil.createImageUrl(series.posterImage, ImageRecipe.DETAIL_BACKDROP_1);
    }

    return backdrop;
  }

  public getSynopsisInfo(program: EpgProgramDetailAsset): string {
    if (!program) {
      return;
    }
    let synopsis = program.longSynopsis ? program.longSynopsis : '';
    if (!synopsis) {
      synopsis = program.shortSynopsis ? program.shortSynopsis : '';
    }

    return synopsis;
  }

  public getProgramShortInfo(
    schedule: EpgScheduleSummaryAsset,
    program: EpgProgramDetailAsset,
  ): string {
    let programShortInfo = [];
    programShortInfo.push(program ? program.productionYear : undefined);

    const duration = this.getDurationInfo(schedule);
    if (duration) {
      programShortInfo.push(duration);
    }

    programShortInfo.push(program ? this.getGenresInfo(program) : undefined);

    programShortInfo = programShortInfo.filter((item) => item !== undefined && item !== '');
    return programShortInfo.join('  •  ');
  }

  public getProgramShortInfoWithEpisode(
    schedule: EpgScheduleSummaryAsset,
    program: EpgProgramDetailAsset,
  ): string {
    const programShortInfo = [];
    programShortInfo.push(program ? program.productionYear : undefined);

    const duration = this.getDurationInfo(schedule);
    if (duration) {
      programShortInfo.push(duration);
    }

    if (program.episode) {
      programShortInfo.push(
        `${this.config.getTranslation(DetailTranslationKeys.detail_episode)} ${program.episode}`,
      );
    }

    programShortInfo.push(program ? this.getGenresInfo(program) : undefined);

    return programShortInfo.filter((item) => item !== undefined && item !== '').join('  •  ');
  }

  public getProgramMiniDetailInfo(
    program: EpgProgramDetailAsset,
    schedule: EpgScheduleSummaryAsset,
    season?: EpgSeasonSummaryAsset,
  ): string {
    const fields: string[] = [];

    const duration = this.getDurationInfo(schedule);
    if (duration) {
      fields.push(duration);
    }

    const seasonEpisode = this.getSeasonEpisodeInfo(program.episode, season?.season);
    if (seasonEpisode) {
      fields.push(seasonEpisode);
    }

    const genres = this.getGenresInfo(program);
    if (genres) {
      fields.push(genres);
    }

    return fields.filter((f) => f).join('  •  ');
  }

  public getChannelAndScheduleInfo(
    channel: ChannelModel,
    schedule: EpgScheduleSummaryAsset,
  ): string {
    const channelAndScheduleInfo = [];

    if (channel && channel.name) {
      channelAndScheduleInfo.push(channel.name);
    }

    if (schedule) {
      channelAndScheduleInfo.push(this.getScheduleInfo(schedule.published));
    }

    return channelAndScheduleInfo.join('  •  ');
  }

  public getCreditInfo(
    program: EpgProgramDetailAsset,
    detailKey: DetailTranslationKeys,
    creditRole: EpgCreditRoles,
  ): CreditInfoItem {
    const creditInfoItem = new CreditInfoItem();
    if (!program || !program.credits) {
      return creditInfoItem;
    }
    creditInfoItem.content = program.credits
      .filter((credit) => credit.role === creditRole)
      .map((item) => item.name)
      .join(', ');

    creditInfoItem.creditTitle = creditInfoItem.content
      ? this.config.getTranslation(detailKey)
      : '';
    return creditInfoItem;
  }

  public canPlayRecording(
    recording: RecordingModel,
    schedule: EpgScheduleSummaryAsset,
    channel: ChannelModel,
  ): boolean {
    if (environment.atv_feature_list.includes(AtvFeatures.RECORDING) && recording) {
      // NPVR
      const npvr = this.sessionService.getStbsList().getNpvrStb();
      if (npvr && recording.device === npvr.id) {
        if (
          (recording.isRecorded() || recording.isInProgress()) &&
          channel.customerHasRight(StreamType.NPVR)
        ) {
          if (!this.scheduleHasBlackout(schedule.blackouts, BlackoutFactory.create(environment.platform, StreamType.NPVR))) {
            // no npvr blackout for ott
            return true;
          }
        }
      }
    }

    return false;
  }

  public getChannelByNumber(
    channels: ChannelModel[],
    channelNumber: number,
    catalogId: string,
  ): ChannelModel {
    if (!channels || channels.length === 0) {
      return null;
    }

    const sorted = [...channels].sort((a, b) => {
      const numberA = a.getChannelNumberForCatalog(catalogId);
      const numberB = b.getChannelNumberForCatalog(catalogId);
      const distA = Math.abs(numberA - channelNumber);
      const distB = Math.abs(numberB - channelNumber);

      return distA < distB || (distA === distB && numberA < numberB) ? -1 : 1;
    });

    return sorted[0];
  }

  // + schedules with same start and end for the same program
  private findFirstScheduledItem(
    schedules: EpgScheduleDetailAsset[],
    replay: boolean,
    channels: ChannelModel[],
  ): EpgScheduleDetailAsset {
    if (schedules === undefined || schedules.length === 0) {
      return undefined;
    }

    let fallbackSchedule;
    const foundSchedule = schedules.find((schedule) => {
      const scheduleStart = SharedUtilityService.timeStringToMs(schedule.published.start);
      const scheduleEnd = SharedUtilityService.timeStringToMs(schedule.published.end);
      const now = new Date().getTime();
      const channel = channels.find((ch) => ch.id === schedule.channel);

      if (replay) {
        if (now > scheduleStart) {
          if (channel && channel.customerHasRight(StreamType.REPLAY)) {
            return true;
          } else if (fallbackSchedule === undefined) {
            fallbackSchedule = schedule;
          }
        } else if (channel && channel.customerHasRight(StreamType.REPLAY)) {
          fallbackSchedule = schedule;
        }
      } else {
        if ((now >= scheduleStart && now <= scheduleEnd) || now <= scheduleStart) {
          if (channel && channel.customerHasRight(StreamType.LINEAR)) {
            return true;
          } else if (fallbackSchedule === undefined) {
            fallbackSchedule = schedule;
          }
        } else if (channel && channel.customerHasRight(StreamType.LINEAR)) {
          fallbackSchedule = schedule;
        }
      }
    });

    return foundSchedule || fallbackSchedule;
  }

  // select the most recent available schedule + schedules with same start and end for the same program
  private findLastAvailableScheduleItems(
    schedules: EpgScheduleDetailAsset[],
  ): EpgScheduleDetailAsset {
    if (schedules === undefined) {
      return undefined;
    }

    return schedules[schedules.length - 1];
  }

  private sortSchedulesByStartDateDESC(schedules): void {
    if (!schedules) {
      return;
    }

    schedules.sort((obj1, obj2): number => {
      let time1;
      let time2;
      if (isNaN(obj1.published.end)) {
        time1 = new Date(obj1.published.end).getTime();
      } else {
        time1 = parseInt(obj1.published.end, 10);
      }

      if (isNaN(obj2.published.end)) {
        time2 = new Date(obj2.published.end).getTime();
      } else {
        time2 = parseInt(obj2.published.end, 10);
      }

      if (time1 < time2) {
        return 1;
      }
      if (time1 > time2) {
        return -1;
      }
      return 0;
    });
  }

  private sortSchedulesByStartDataASC(schedules: any[]): void {
    if (!schedules) {
      return;
    }

    // create map for stable sort. Stable sort is not guaranteed for Chrome <= (e.g. Tizen)
    const map = new Map<any, number>();
    schedules.forEach((v, i) => map.set(v, i));

    schedules.sort((obj1, obj2): number => {
        let time1;
        let time2;
        if (isNaN(obj1.published.end)) {
          time1 = new Date(obj1.published.end).getTime();
        } else {
          time1 = parseInt(obj1.published.end, 10);
        }

        if (isNaN(obj2.published.end)) {
          time2 = new Date(obj2.published.end).getTime();
        } else {
          time2 = parseInt(obj2.published.end, 10);
        }

        if (time1 < time2) {
          return -1;
        } else if (time1 > time2) {
          return 1;
        } else {
          return map.get(obj1) - map.get(obj2);
        }
      },
    );
  }

  private getGenresInfo(program: EpgProgramDetailAsset): string {
    const genres = [];
    if (!program || !program.genres) {
      return '';
    }
    program.genres.forEach((genre) => genres.push(this.config.getGenre(genre)));
    return genres.join(' | ');
  }

  private getDurationInfo(schedule?: EpgScheduleSummaryAsset): string {
    if (!schedule) {
      return null;
    }

    const start = SharedUtilityService.timeStringToMoment(schedule.published.start);
    const end = SharedUtilityService.timeStringToMoment(schedule.published.end);

    return `${end.diff(start, 'minutes')} ${this.config.getTranslation(
      DetailTranslationKeys.detail_duration_min,
    )}`;
  }
}
