import { Component, OnInit } from '@angular/core';
import { Catalog } from '@atv-core/api/aa/aa-api.model';
import { ConfigService } from '@atv-bootstrap/services/config';
import { MessagesService } from '@atv-core/services/messages/messages.service';
import { SessionService } from '@atv-core/services/session';
import { SessionStorageKeys } from '@atv-core/utility/constants/sessionStorageKeys';

import { CatalogTranslationKeys, ErrorTranslationKeys } from '../../../bootstrap/services/config/config.model';

@Component({
  selector: 'app-anonymous-catalog-select-popup',
  templateUrl: './anonymous-catalog-select-popup.component.html',
  styles: [],
})
export class AnonymousCatalogSelectPopupComponent implements OnInit {
  public showPopup = false;
  public popupTitle = '';

  public catalogId = '';
  private initialCatalogId = '';

  public availableCatalogs: Catalog[] = [];

  constructor(
    private config: ConfigService,
    private sessionService: SessionService,
    private messagesService: MessagesService
  ) {
    this.sessionService.showCatalogSelectionPopup.subscribe(() => {
      const anonymousSettings = this.config.getAnonymousSettings();
      if (
        anonymousSettings &&
        anonymousSettings.catalogs &&
        anonymousSettings.catalogs.length > 0
      ) {
        this.availableCatalogs = anonymousSettings.catalogs;

        this.catalogId =
          sessionStorage.getItem(SessionStorageKeys.anonymous_catalog) ||
          anonymousSettings.defaultCatalogId ||
          this.availableCatalogs[0].id;
        this.initialCatalogId = this.catalogId;

        this.showPopup = true;
      } else {
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_general)
        );
      }
    });

    this.popupTitle = this.config.getTranslation(CatalogTranslationKeys.catalog_select_title);
  }

  public switchCatalog(): void {
    if (this.catalogId !== this.initialCatalogId) {
      this.sessionService.switchAnonymousActiveCatalogId(this.catalogId);

      this.sessionService.reset(true);
      this.showPopup = false;
    }
  }

  public closePopup(): void {
    this.showPopup = false;
  }

  ngOnInit(): void {}
}
