import { Properties } from '@atv-core/api/cms';
import { FocusType } from '@atv-core/utility/constants/shared';

export class PagePropertiesUtility {
  static isReplay(properties: Properties[], fallbackProperties?: Properties[]): boolean {
    // use replay if set on ribbon or not set at ribbon, but set on page
    // replay: FOCUS_PAST && !FOCUS_FUTURE
    return (properties.includes(Properties.FOCUS_PAST) && !properties.includes(Properties.FOCUS_FUTURE)) ||
           (!properties.includes(Properties.FOCUS_FUTURE) && fallbackProperties?.includes(Properties.FOCUS_PAST)
            && !fallbackProperties?.includes(Properties.FOCUS_FUTURE));
  }

  static getFocusType(properties: Properties[], fallbackProperties?: Properties[]): FocusType {
    if (properties.includes(Properties.FOCUS_FUTURE) && properties.includes(Properties.FOCUS_PAST)) {
      return FocusType.ANY;
    } else if (properties.includes(Properties.FOCUS_FUTURE)) {
      return FocusType.FUTURE;
    } else if (properties.includes(Properties.FOCUS_PAST)) {
      return FocusType.PAST;
    } else if (fallbackProperties) {
      return this.getFocusType(fallbackProperties);
    } else {
      return FocusType.ANY;
    }
  }

  static containsProperty(target: Properties, properties?: Properties[], fallbackProperties?: Properties[]): boolean {
    return properties?.includes(target) || fallbackProperties?.includes(target);
  }
}
