import { PlatformType, StreamType } from './../../utility/constants/shared';
import { EpgChannelRight } from '@atv-core/api/epg';

export class Stb {
  public readonly id: string;
  public readonly entitlementId?: string;
  public readonly type: PlatformType;
  public readonly serial?: string;

  // added functionality
  recording: string;
  alias: string;
  channelRights: EpgChannelRight[];

  constructor(id: string, type?: PlatformType, entitlementId?: string, serial?: string) {
    this.id = id;
    this.entitlementId = entitlementId;
    this.type = type;
    this.serial = serial;
  }

  public hasRightForChannel(right: StreamType, channelId: string): boolean {
    if (this.channelRights) {
      const rightsForChannel = this.channelRights.find(
        channelRight => channelRight.id === channelId,
      );
      if (rightsForChannel && rightsForChannel.rights.includes(right)) {
        return true;
      }
    }
    return false;
  }
}
