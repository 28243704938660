import { Stream2Advertisement } from '@atv-core/api/ss/streaming-api.model';

import { AdvertisementElement } from './advertisement-element.model';

export class Advertisements {
  public prerollAds: Stream2Advertisement[];
  public prerollAdIndex: number;
  public postrollAds: Stream2Advertisement[];
  public postrollAdIndex: number;

  public currentAd: AdvertisementElement;
  public breakvertisingTimeout: number;
  public progressText: string;
  public skipText: string;

  constructor() {
    this.reset();
  }

  public reset(): void {
    this.currentAd = undefined;
    this.postrollAds = [];
    this.postrollAdIndex = 0;
    this.prerollAds = [];
    this.prerollAdIndex = 0;

    this.clearBreakvertisingTimeout();
  }

  public clearBreakvertisingTimeout(): void {
    if (this.breakvertisingTimeout) {
      clearTimeout(this.breakvertisingTimeout);
      this.breakvertisingTimeout = undefined;
    }
  }
}
