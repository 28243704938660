import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VodApiService } from '@atv-core/api/vod';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { ConfigService, RibbonTranslationKeys, DetailTranslationKeys } from '@atv-bootstrap/services/config';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ContentFilters, FilterModel } from '../filters.model';
import { MessagesService } from '@atv-core/services/messages';

@Component({
  selector: 'app-year-filter',
  templateUrl: './year-filter.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearFilterComponent implements AfterViewInit, OnDestroy {
  @Input()
  filterModel: FilterModel;

  @Input()
  allowedAdultMode: AdultMode;

  @Output()
  filterReady = new EventEmitter();

  @Output()
  filterChange = new EventEmitter();

  private clickedInside = false;

  @ViewChild('filterWrapperRef', { static: true })
  filterWrapperRef: ElementRef;
  @ViewChild('optionWrapperRef')
  optionWrapperRef: ElementRef;

  years = [];
  fetchingYears = true;

  selectedYearTitle = '';
  filterTitle = '';

  optionsHidden = true;

  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnObservable: Observable<KeyboardEvent>;
  @ViewChildren('overlayDiv') overlayDiv: QueryList<ElementRef<HTMLDivElement>>;
  private returnSubscription: Subscription;
  private overlayDivSubscription: Subscription;

  constructor(
    private vodApi: VodApiService,
    private adultService: AdultService,
    private config: ConfigService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private spatialNavigationService: SpatialNavigationService,
    private messagesService: MessagesService,
  ) {
    this.filterTitle = this.config.getTranslation(RibbonTranslationKeys.ribbon_filter_year);
    this.selectedYearTitle = this.filterTitle;

    if (this.isSmartTv) {
      this.returnObservable = fromEvent(document, 'keyup').pipe(
        filter((event: KeyboardEvent) =>
          this.spatialNavigationService.keyCodeIsReturn(event.keyCode),
        ),
      );
    }
  }

  ngAfterViewInit(): void {
    this.tryInitFromQueryParams();

    this.overlayDivSubscription = this.overlayDiv.changes.subscribe(changes => {
      if (changes.length === 1) {
        // move to body to avoid issues with parent divs who have a transformation set
        // TODO create overlay component, add it to body and use ng-template
        document.body.appendChild(changes.first.nativeElement);
      }
    });
  }

  resetFilter() {
    this.tryRemoveFilter(undefined);
    this.filterReady.emit();
  }

  private detectChanges() {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  showOptions() {
    if (this.allowedAdultMode === AdultMode.true) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.getOptions();
        }, errorCallback: () => {
          this.messagesService.showErrorMessage(
            this.config.getTranslation(DetailTranslationKeys.detail_adult_warning),
          );
        },
      });
    } else {
      this.getOptions();
    }
  }

  private getOptions(): void {
    this.optionsHidden = false;
    this.fetchProductionYears().subscribe();
    this.detectChanges();

    if (!this.isSmartTv) {
      setTimeout(() => {
        this.detectWidthChange();
      }, 0);
    } else {
      window.setTimeout(() => {
        this.spatialNavigationService.register(
          NavigationSections.FILTER_OPTIONS,
          '.filter-option-smarttv',
          { restrict: 'self-only' },
        );
        this.spatialNavigationService.setFocus(NavigationSections.FILTER_OPTIONS);

        this.returnSubscription = this.returnObservable.subscribe((event) => {
          event.cancelBubble = true;
          this.hideOptions();
        });
      }, 100);
    }
  }

  hideOptions() {
    this.optionsHidden = true;
    this.detectChanges();
    if (!this.isSmartTv) {
      setTimeout(() => {
        this.detectWidthChange();
      }, 0);
    } else {
      this.spatialNavigationService.unregister(NavigationSections.FILTER_OPTIONS);
      this.spatialNavigationService.setFocus(NavigationSections.FILTERS);
    }

    if (this.returnSubscription) {
      this.returnSubscription.unsubscribe();
      this.returnSubscription = null;
    }
  }

  detectWidthChange() {
    if (this.filterWrapperRef) {
      if (!this.optionWrapperRef) {
        this.filterWrapperRef.nativeElement.style.width = 'auto';
      } else {
        const buttonConfigEl = this.optionWrapperRef.nativeElement;
        const width = buttonConfigEl.getBoundingClientRect().width;
        if (width) {
          this.filterWrapperRef.nativeElement.style.width = width + 'px';
        } else {
          this.filterWrapperRef.nativeElement.style.width = 'auto';
        }
      }
    }
  }

  private tryInitFromQueryParams() {
    const param = this.activeRoute.snapshot.queryParams[ContentFilters.YEAR];
    if (param) {
      if (this.years.length === 0) {
        this.fetchProductionYears().subscribe((result) => {
          this.continueInitParamListener(param);
        });
      } else {
        this.continueInitParamListener(param);
      }
    } else {
      this.filterReady.emit();
    }
  }

  private continueInitParamListener(param) {
    const yearIndex = this.years.findIndex((year) => year === parseInt(param));
    if (yearIndex !== undefined && yearIndex >= 0) {
      this.changeselectedYear(parseInt(param), true);
      return;
    } else if (yearIndex === undefined || yearIndex < 0) {
      this.removeQueryParamForFilter();
    }
    this.filterReady.emit();
  }

  changeselectedYear(year, firstRun = false) {
    if (year) {
      this.selectedYearTitle = year;
      this.addQueryParamForFilter(year);
      this.filterModel.yearFilterOutputValue = year;
    }
    this.hideOptions();

    if (firstRun) {
      this.filterReady.emit();
    } else {
      this.filterChange.emit();
    }
  }

  private addQueryParamForFilter(year) {
    const filter = {};
    filter[ContentFilters.YEAR] = year;
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: filter,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  tryRemoveFilter(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (this.allowedAdultMode === AdultMode.true) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.removeFilter();
        }, errorCallback: () => {
          this.messagesService.showErrorMessage(
            this.config.getTranslation(DetailTranslationKeys.detail_adult_warning),
          );
        },
      });
    } else {
      this.removeFilter();
    }
  }

  private removeFilter(): void {
    this.selectedYearTitle = this.filterTitle;
    this.filterModel.yearFilterOutputValue = undefined;
    this.removeQueryParamForFilter();
    this.filterChange.emit();
    this.detectChanges();
  }

  private removeQueryParamForFilter() {
    const filter = {};
    filter[ContentFilters.YEAR] = undefined;
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: filter,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private fetchProductionYears(): Observable<any> {
    this.fetchingYears = true;

    let cats = this.filterModel.categoryFilterRoot || [];
    if (this.filterModel.categoryFilterOutputTags) {
      cats = cats.concat(this.filterModel.categoryFilterOutputTags);
    }

    this.detectChanges();
    return this.vodApi
      .getProductionYears(
        cats,
        this.adultService.getAdultMode(
          this.filterModel.currentCategoryFilterIsAdult ? AdultMode.true : this.allowedAdultMode,
        ),
      )
      .pipe(
        map((result) => {
          result.sort((a, b) => {
            return a < b ? 1 : -1;
          });
          this.years = result;
          this.fetchingYears = false;
          this.detectChanges();

          return result;
        }),
      );
  }

  @HostListener('click', ['$event'])
  onClickedInside($event: Event) {
    this.clickedInside = true;
  }

  @HostListener('document:click', ['$event'])
  onClickedOutside($event) {
    if (!this.clickedInside && !this.optionsHidden) {
      this.hideOptions();
    }
    this.clickedInside = false;
  }

  ngOnDestroy(): void {
    this.returnSubscription?.unsubscribe();
    this.overlayDivSubscription?.unsubscribe();
  }
}
