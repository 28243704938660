import { defer, from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PlayerEngine } from '@atv-player/engines/player-engine';
import { DrmInfo } from '@atv-player/model/drm-info';
import { environment } from '@env/environment';
import { VideoEvent } from '@atv-player/model/video-event';
import { VideoQuality } from '@atv-player/model/video-quality';
import { AudioTrack } from '@atv-player/model/audio-track';
import { Subtitle } from '@atv-player/model/subtitle';
import { SeekRange } from '@atv-player/model/seek-range';

export class CastlabsPlayerEngine implements PlayerEngine {
  private player: clpp.Player;
  private airplayPlugin?: clpp.airplay.AirPlayPlugin;

  static create(videoElementSelector: string): Observable<CastlabsPlayerEngine> {
    return CastlabsPlayerEngine.loadCastlabs().pipe(map(() => {
      const engine = new CastlabsPlayerEngine();
      engine.init(videoElementSelector);
      return engine;
    }));
  }

  static createWithElement(videoEl: HTMLMediaElement): Observable<CastlabsPlayerEngine> {
    return CastlabsPlayerEngine.loadCastlabs().pipe(map(() => {
      const engine = new CastlabsPlayerEngine();
      engine.init(videoEl, true);
      return engine;
    }));
  }

  private static loadCastlabs(): Observable<unknown> {
    return defer(() => from(import('./clpp'))).pipe(
      tap((c) => {
        // TODO do this in a cleaner way with typing support
        window.clpp = (c as any).default;
      }));
  }

  getCurrentTime(streamStartTime?: number): number {
    if (this.player.isLive()) {
      if (!streamStartTime) {
        throw new Error('Stream start time required for calculating position of live stream');
      }

      return this.player.getPosition() + this.player.getPresentationStartTime() - (streamStartTime / 1000);
    } else {
      return this.player.getPosition();
    }
  }

  public getSeekRange(): SeekRange {
    return this.player.getSeekRange();
  }

  seekAbsolute(time: number): Promise<unknown> {
    return this.player.seek(this.getSeekRange().start - this.player.getPresentationStartTime() + time);
  }

  public seekRelative(offset: number): Promise<unknown> {
    return this.player.seek(this.player.getPosition() + offset);
  }

  isLive(): boolean {
    return this.player.isLive();
  }

  isPlayerAttached(): boolean {
    return this.player?.getState() !== clpp.Player.State.IDLE ?? false;
  }

  load(
    sourceUrls: string[],
    startPosition: number,
    initialBitrate: number,
    isDrmProtected: boolean,
    useBroadpeakAnalytics: boolean,
    broadpeakAnalyticsDomain?: string,
    broadpeakAnalyticsServer?: string,
    drmInfo?: DrmInfo,
  ): Promise<unknown> {
    let drmTodayConfig: clpp.DrmConfiguration;

    if (isDrmProtected && drmInfo) {
      drmTodayConfig = {
        env: environment.drm_environment,
        customData: {
          userId: drmInfo.userId,
          sessionId: drmInfo.sessionId,
          merchant: drmInfo.merchant,
          assetId: drmInfo.assetId,
        },
      };

      if (drmInfo.authToken) {
        drmTodayConfig.customData.authToken = drmInfo.authToken;
      }
    }

    const broadpeakPlugin = useBroadpeakAnalytics && broadpeakAnalyticsDomain && broadpeakAnalyticsServer ?
      {
        analyticsAddress: broadpeakAnalyticsServer,
        broadpeakDomainNames: broadpeakAnalyticsDomain,
      } : undefined;

    if (broadpeakPlugin && clpp.broadpeak.BroadpeakPlugin.isSdkMissing()) {
      console.warn('Broadpeak plugin not loaded');
    }

    const sources = sourceUrls.map(url => ({ url, drmProtected: isDrmProtected }));

    return this.player
      .load({
        source: sources,
        startTime: startPosition,
        abr: {
          enabled: true,
          defaultBandwidthEstimate: initialBitrate,
        },
        drm: drmTodayConfig,
        broadpeak: broadpeakPlugin,
        suggestedPresentationDelay: environment.player_change_suggested_presentation_delay ?? 10,
      });
  }

  off(event: VideoEvent, callback: EventListener): void {
    this.player.off(event, callback);
  }

  on(event: VideoEvent, callback: EventListener): void {
    this.player.on(event, callback);
  }

  one(event: VideoEvent, callback: EventListener): void {
    this.player.one(event, callback);
  }

  pause(): void {
    this.player.pause();
  }

  play(): Promise<unknown> {
    return this.player.play();
  }

  getVideoQualities(): VideoQuality[] {
    const mainTrack = this.getMainVideoTrack();

    if (!mainTrack) {
      return [];
    }

    return mainTrack.renditions.map(r => ({ height: r.height, id: r.id }));
  }

  selectVideoQuality(quality?: VideoQuality): void {
    this.player.getTrackManager().setVideoRendition(quality ? this.getMainVideoTrack()?.renditions?.find(r => r.id === quality?.id) : null);
  }

  getAudioTracks(): AudioTrack[] {
    return this.getMainAudioTracks().map(t => ({ id: t.id, channelsCount: t.channelsCount, label: t.label, language: t.language }));
  }

  selectAudioTrack(track: AudioTrack): void {
    this.player.getTrackManager().setAudioTrack(this.getMainAudioTracks()?.find(t => t.id === track.id));
  }

  getSubtitles(): Subtitle[] {
    return this.player.getTrackManager()
      .getTextTracks()
      .map(t => ({ id: t.id, language: t.language, label: t.label, representationId: t.renditions?.[0]?.originalId }));
  }

  selectSubtitle(subtitle?: Subtitle): void {
    this.player.getTrackManager()
      .setTextTrack(subtitle ? this.player.getTrackManager().getTextTracks().find(t => t.id === subtitle.id) : null);
  }

  getDuration(): number {
    return this.player.getDuration();
  }

  getVolume(): number {
    return this.player.getVolume();
  }

  isMuted(): boolean {
    return this.player.isMuted();
  }

  isPaused(): boolean {
    return this.player.isPaused();
  }

  setMuted(muted: boolean): void {
    this.player.setMuted(muted);
  }

  setVolume(volume: number): void {
    this.player.setVolume(volume);
  }

  public destroy(): Promise<unknown> {
    return this.player.destroy();
  }

  public canAirplay(): boolean {
    return this.airplayPlugin?.canCast() ?? false;
  }

  public showAirplayCastMenu(): void {
    this.airplayPlugin?.showCastMenu();
  }

  public release(): void {
    this.player.release();
  }

  private getMainVideoTrack(): clpp.Track {
    return this.player.getTrackManager().getVideoTracks().find(t => t.roles.includes('main'))
           ?? this.player.getTrackManager().getVideoTracks()[0];
  }

  private getMainAudioTracks(): clpp.Track[] {
    return this.player.getTrackManager()
      .getAudioTracks()
      .filter((track) => track.roles.includes('main') || (track.roles.length === 0 && track.kind === 'main'));
  }

  private init(videoElementSelector: string | HTMLMediaElement, muted?: boolean): void {
    const playerConfig: clpp.PlayerConfiguration = {
      controls: false,
      license: environment.castLabsLicenseKey,
      autoplay: true,
      muted,
    };

    if (environment.player_change_buffer_setting !== undefined) {
      playerConfig.streaming = {
        bufferingGoal: environment.player_change_buffer_setting,
      };
    }

    this.player = new clpp.Player(videoElementSelector, playerConfig, { disableContainer: true });
    this.player.use(clpp.dash.DashComponent);

    this.player.on(clpp.events.AIRPLAY_STATUS_CHANGED, () => {
      this.airplayPlugin = this.player.getPlugin(clpp.airplay.AirPlayPlugin.Id) as clpp.airplay.AirPlayPlugin;
    });
  }
}
