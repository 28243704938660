import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsGrid, Properties } from '@atv-core/api/cms';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { ReminderCacheService } from '@atv-core/services/cache/reminder/reminder-cache.service';
import { ConfigService, ErrorTranslationKeys, MenuTranslationKeys } from '@atv-bootstrap/services/config';
import { DetailOverlayService } from '@atv-core/services/detail/detail-overlay.service';
import { PageContext, PageUrlTypes } from '@atv-core/services/log/log.model';
import { LogService } from '@atv-core/services/log/log.service';
import { PageData } from '@atv-core/services/menu-manager/menu-manager.service';
import { MessagesService } from '@atv-core/services/messages';
import { SessionService } from '@atv-core/services/session';
import {
  NavigationSections,
  SpatialNavigationService
} from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { ReloadablePageComponent } from '@atv-pages/reloadable-page.component';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ReminderCacheContentProvider } from '@atv-shared/content-provider/providers/reminder-cache-content-provider';
import { ContentProviderFactoryService } from '@atv-shared/content-provider/content-provider-factory.service';

@Component({
  selector: 'app-reminders-page',
  templateUrl: './reminders-page.component.html',
  styles: [],
})
export class RemindersPageComponent extends ReloadablePageComponent implements OnInit, OnDestroy {
  public grid: CmsGrid;

  public allowedAdultMode: AdultMode = AdultMode.any;

  public noResultText = '';

  private closeDetailSubscription: Subscription;
  private returnSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    config: ConfigService,
    private reminderCache: ReminderCacheService,
    private log: LogService,
    sessionService: SessionService,
    authorizationService: AuthorizationService,
    adultService: AdultService,
    messageService: MessagesService,
    private spatialNavigationService: SpatialNavigationService,
    private detailService: DetailOverlayService,
    private contentProviderFactoryService: ContentProviderFactoryService,
  ) {
    super(sessionService, authorizationService, adultService, messageService, config);
  }

  ngOnInit(): void {
    this.log.pageView(
      new PageContext({
        pageURL: PageUrlTypes.my_stuff_reminders,
        pageTitle: this.config.getTranslation(MenuTranslationKeys.menu_reminders),
        pageLocale: this.config.getLocale(),
      }),
    );

    super.start();

    if (SharedUtilityService.isSmartTv()) {
      this.closeDetailSubscription = this.detailService.closeDetailEvent.subscribe(() => {
        this.spatialNavigationService.setDefaultSection(NavigationSections.PAGE_CONTENT);
        this.spatialNavigationService.setFocus();
      });
    }
  }

  public loadPage(): void {
    this.grid = new CmsGrid({
      title: '',
      contentProvider: new ReminderCacheContentProvider(this.reminderCache, this.contentProviderFactoryService),
      properties: [Properties.SHOWPROGRESS, Properties.SHOW_SCHEDULE_INFO],
    });
    this.noResultText = this.config.getTranslation(ErrorTranslationKeys.error_no_reminders);

    if (SharedUtilityService.isSmartTv()) {
      this.registerElements();
    }
  }

  getPageProperties(): Observable<Properties[]> {
    return of((this.activatedRoute.snapshot.data as PageData).properties);
  }

  private registerElements(): void {
    this.unregisterElements();

    this.spatialNavigationService.register(
      NavigationSections.PAGE_CONTENT,
      'app-grid .grid-item a',
    );
    this.spatialNavigationService.setDefaultSection(NavigationSections.PAGE_CONTENT);

    this.returnSubscription = fromEvent(document, 'keydown')
      .pipe(
        filter((event: KeyboardEvent) =>
          this.spatialNavigationService.keyCodeIsReturn(event.keyCode),
        ),
      )
      .subscribe(() => {
        this.spatialNavigationService.setFocus(NavigationSections.MENU);
      });
  }

  private unregisterElements(): void {
    this.spatialNavigationService.unregister(NavigationSections.PAGE_CONTENT);
    this.closeDetailSubscription?.unsubscribe();
    this.returnSubscription?.unsubscribe();
  }

  ngOnDestroy(): void {
    super.stop();

    if (SharedUtilityService.isSmartTv()) {
      this.unregisterElements();
    }
  }
}
