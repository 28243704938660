<div class="cms-page-filters">
  <app-category-filter
    *ngIf="cmsFilterModel?.activeCmsFilters.includes(ContentFilters.CATEGORY)"
    #categoryFilter
    [filterModel]="cmsFilterModel"
    [allowedAdultMode]="allowedAdultMode"
    (filterReady)="filterReady()"
    (filterChange)="categoryFilterChanged()"
  ></app-category-filter>
  <app-year-filter
    *ngIf="cmsFilterModel?.activeCmsFilters.includes(ContentFilters.YEAR)"
    #yearFilter
    [filterModel]="cmsFilterModel"
    [allowedAdultMode]="allowedAdultMode"
    (filterReady)="filterReady()"
    (filterChange)="yearFilterChanged()"
  ></app-year-filter>
  <app-score-filter
    *ngIf="cmsFilterModel?.activeCmsFilters.includes(ContentFilters.SCORE)"
    #scoreFilter
    [filterModel]="cmsFilterModel"
    [allowedAdultMode]="allowedAdultMode"
    (filterReady)="filterReady()"
    (filterChange)="scoreFilterChanged()"
  >
  </app-score-filter>
  <app-clear-filter
    *ngIf="isSmartTv"
    [filtersActive]="cmsFilterModel && cmsFilterModel.areFiltersActive()"
    (clear)="clearAllFilters()"
  ></app-clear-filter>
</div>
