import { ComponentFactoryResolver, EventEmitter, Injectable, ViewContainerRef } from '@angular/core';
import { SessionCastDeviceRegistration } from '@atv-core/api/aa/aa-api.model';
import { PlatformType } from '@atv-core/utility/constants/shared';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

import { ConfigService, SettingsKeys } from '@atv-bootstrap/services/config';
import { AaApiService } from '@atv-core/api/aa/aa-api.service';
import { ControlsVisibility } from '@atv-player/model/controls-visibility';
import { ChannelModel } from '@atv-core/services/cache/channel';
import { MessagesService } from '@atv-core/services/messages';
import { PlayInfoModel } from '@atv-player/model/play-info-model';
import { SeekbarModel } from '@atv-player/player-controls/seekbar/seekbar.model';
import { Advertisements } from '@atv-player/advertisement/advertisements';
import { PlayerComponent } from '@atv-player/player/player.component';
import { fromPromise } from 'rxjs/internal-compatibility';
import { RemotePlayerComponent } from '@atv-player/remote-player/remote-player.component';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { environment } from '@env/environment';
import { PlayerEngine } from '@atv-player/engines/player-engine';
import { Subtitle } from '@atv-player/model/subtitle';
import { VideoQuality } from '@atv-player/model/video-quality';
import { AudioTrack } from '@atv-player/model/audio-track';
import { VideoEvent } from '@atv-player/model/video-event';
import { HttpErrorResponse } from '@angular/common/http';
import { Browsers, ClientDetails } from '@atv-core/utility/static-services/client-detail.static-service';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  public seekbar = new SeekbarModel(this);
  public currentPlayInfo: PlayInfoModel;
  public advertisements = new Advertisements();
  public playerVisibilitySubject = new BehaviorSubject(false);
  public playPauseSubject = new Subject();
  public closePlayerEvent = new EventEmitter<void>();
  public controlsVisibility: ControlsVisibility;
  public pipModeSubject = new BehaviorSubject<boolean>(false);
  public audioTracksSubject = new BehaviorSubject<AudioTrack[]>(undefined);
  public textTracksSubject = new BehaviorSubject<Subtitle[]>(undefined);
  public videoRenditionsSubject = new BehaviorSubject<VideoQuality[]>(undefined);
  public userInitiatedPause = false;
  public remotePlayerConnected = false;
  public remotePlayer: cast.framework.RemotePlayer;
  public remotePlayerController;
  public castSession;
  private remotePlayerContainerRef: ViewContainerRef;
  private remotePlayerComponent: RemotePlayerComponent;

  private playerContainerRef: ViewContainerRef;
  private playerEngine?: PlayerEngine;
  private playerComponent: PlayerComponent;
  private registeredDevice: SessionCastDeviceRegistration;

  private airplayActive = false; // keep status manually, Castlabs atv-player plugin was not reliable for this

  // TODO move chromecast logic to separate service

  constructor(
    private aaApi: AaApiService,
    private config: ConfigService,
    private messagesService: MessagesService,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {

    this.controlsVisibility = new ControlsVisibility(
      this.config.getSettingNumber(SettingsKeys.playerHideControlsPeriod, 5 * 1000),
    );

    const enableChromeCast = this.config.getSettingBoolean(SettingsKeys.chromeCastEnabled, false) && !SharedUtilityService.isSmartTv();
    if (enableChromeCast && ClientDetails.getDetails().browser === Browsers.Chrome) {
      window.__onGCastApiAvailable = (isAvailable) => {
        if (isAvailable) {
          this.initializeCastApi();
        }
      };

      const script: HTMLScriptElement = document.createElement('script');
      script.src = 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';
      document.head.appendChild(script);
    }
  }

  // TODO move atv-player creation to here and remove this function
  public setPlayer(playerEngine: PlayerEngine, component: PlayerComponent): void {
    this.playerEngine = playerEngine;
    this.playerComponent = component;

    if (playerEngine) {
      playerEngine.on(VideoEvent.AIRPLAY_CASTING_STARTED, () => {
        if (!this.airplayActive) {
          this.airplayActive = true;

          this.playerComponent.updateStreamOptions(true).pipe(catchError((error: HttpErrorResponse) => {
            this.closePlayerEvent.emit();
            this.airplayActive = false;

            if (error?.error?.message) {
              this.messagesService.showErrorMessage(error.error.message);
            }

            return of();
          })).subscribe();
        }
      });

      playerEngine.on(VideoEvent.AIRPLAY_CASTING_ENDED, () => {
        this.airplayActive = false;
        this.playerComponent.updateStreamOptions(false).subscribe();
      });
    }
  }

  public setPlayerComponentContainer(playerVcRef: ViewContainerRef): void {
    this.playerContainerRef = playerVcRef;
  }

  public setRemotePlayerComponentContainer(remotePlayerVcRef: ViewContainerRef): void {
    this.remotePlayerContainerRef = remotePlayerVcRef;
  }

  public seekRelative(offset: number): void {
    this.playerEngine.seekRelative(offset).then();
  }

  public seekAbsolute(percentage: number): void {
    // TODO refactor, move function to here
    this.playerComponent.onSeek(percentage);
  }

  public skipAd(): boolean {
    // TODO refactor, move function to here
    return this.playerComponent.skipAd();
  }

  public doAdvertisementAction(): void {
    // TODO refactor, move function to here
    this.playerComponent.goToAdvertisement();
  }

  public zapToChannel(channel: ChannelModel): void {
    // TODO refactor, move function to here
    this.playerComponent.zapToChannel(channel);
  }

  public playAsset(playInfo: PlayInfoModel, forceRemote: boolean): void {
    // if casting not active
    if (this.remotePlayerConnected || forceRemote) {
      this.getRemotePlayerComponent().pipe(first()).subscribe(() => {
        this.remotePlayerComponent.playAsset(playInfo);
      });
    } else {
      this.getPlayerComponent().pipe(first()).subscribe(() => {
        this.playerComponent.playAsset(playInfo);
      });
    }
  }

  public getRegisteredDeviceId(overwriteId?: string): Observable<string> {
    // alternative for label could be to use this.receiverContext.getApplicationData().launchingSenderId on receiver app
    // send this to sender everything before .3:sender === deviceId on other sender apis

    if (overwriteId) {
      return of(overwriteId);
    }

    const castDevice = this.castSession.getCastDevice();

    if (!this.registeredDevice || this.registeredDevice.identifier !== castDevice.label) {
      return this.aaApi
        .registerChromecastDevice({
          type: PlatformType.CHROMECAST,
          identifier: castDevice.label,
          name: castDevice.friendlyName,
        })
        .pipe(
          map(
            (result) => {
              this.registeredDevice = result;
              return this.registeredDevice.id;
            },
            catchError(() => {
              this.registeredDevice = undefined;
              return of(undefined);
            }),
          ),
        );
    } else {
      return of(this.registeredDevice.id);
    }
  }

  public getCastDeviceName(): string {
    if (this.castSession) {
      const castDevice = this.castSession.getCastDevice();
      if (castDevice && castDevice.friendlyName) {
        return castDevice.friendlyName;
      }
    }

    return '';
  }

  public isChromecastAvailable(): boolean {
    return !!this.remotePlayer;
  }

  public isPaused(): boolean {
    return this.playerEngine?.isPaused() ?? true;
  }

  public playPauseToggle(): void {
    if (this.isPaused()) {
      if (this.isPlayerAttached()) {
        this.playerEngine.play();
        this.playPauseSubject.next();
      }
    } else {
      this.userInitiatedPause = true;
      if (this.isPlayerAttached()) {
        this.playerEngine.pause();
        this.playPauseSubject.next();
      }
    }
  }

  public play(): void {
    this.playerEngine?.play();
    this.playPauseSubject.next();
  }

  public pause(): void {
    this.userInitiatedPause = true;
    this.playerEngine?.pause();
    this.playPauseSubject.next();
  }

  public enablePipMode(): void {
    this.exitFullScreen();
    this.pipModeSubject.next(true);
  }

  public disablePipMode(): void {
    this.pipModeSubject.next(false);
  }

  public enterFullScreen(element: HTMLElement): void {
    element.requestFullscreen();
  }

  public exitFullScreen(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }

  public selectSubtitle(subtitle: Subtitle): void {
    this.playerEngine.selectSubtitle(subtitle);
  }

  public selectVideoQuality(quality: VideoQuality): void {
    this.playerEngine.selectVideoQuality(quality);
  }

  public selectAudioTrack(track: AudioTrack): void {
    this.playerEngine.selectAudioTrack(track);
  }

  public isPlayerAttached(): boolean {
    return this.playerEngine?.isPlayerAttached();
  }

  public canAirplay(): boolean {
    return this.playerEngine?.canAirplay() ?? false;
  }

  public doAirplay(): void {
    this.playerEngine.showAirplayCastMenu();
  }

  public isAirplayActive(): boolean {
    return this.airplayActive;
  }

  private initializeCastApi(): void {
    cast.framework.CastContext.getInstance().setOptions({
      receiverApplicationId: environment.chromecast_receiver_app_id
        ? environment.chromecast_receiver_app_id
        : chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
      autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
    });

    this.remotePlayer = new cast.framework.RemotePlayer();
    this.remotePlayerConnected = this.remotePlayer.isConnected;
    this.remotePlayerController = new cast.framework.RemotePlayerController(this.remotePlayer);

    this.remotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.ANY_CHANGE, () => {
      this.getRemotePlayerComponent().pipe(first()).subscribe(() => {
        this.remotePlayerComponent.remotePlayerChange();
      });
    });

    // listen to connection change events
    this.remotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED, () => {
        this.getRemotePlayerComponent().pipe(first()).subscribe(() => {
          this.remotePlayerComponent.playerConnectionChange();
        });
      },
    );
  }

  private getPlayerComponent(): Observable<void> {
    return fromPromise(import('../../../atv-player/atv-player.module')).pipe(switchMap(() => {
      if (!this.playerComponent) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PlayerComponent);
        this.playerContainerRef.clear();
        this.playerComponent = this.playerContainerRef.createComponent(componentFactory).instance;
      }

      return this.playerComponent.onFinishedLoading();
    }));
  }

  private getRemotePlayerComponent(): Observable<void> {
    return fromPromise(import('../../../atv-player/atv-player.module')).pipe(map(() => {
      if (!this.remotePlayerComponent) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(RemotePlayerComponent);
        this.remotePlayerContainerRef.clear();
        this.remotePlayerComponent = this.remotePlayerContainerRef.createComponent(componentFactory).instance;
      }

      return;
    }));
  }
}
