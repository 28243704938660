import { Catalog } from '@atv-core/api/aa/aa-api.model';

export interface AnonymousSettings {
  catalogs?: Catalog[];
  defaultCatalogId: string;
  defaultProfileId: string;
  defaultEntitlementId: string;
}

export enum SettingsKeys {
  startupTimeDifference = 'startupTimeDifference',
  allowProfileSwitching = 'allowProfileSwitching',
  reminderBeforeTimes = 'reminderBeforeTimes',

  maxAllowedProfiles = 'maxAllowedProfiles',
  maxLengthProfileName = 'maxLengthProfileName',

  showAdultPage = 'showAdultPage',

  playerBreakvertisingTime = 'playerBreakvertisingTime',

  analyticsFlushPeriod = 'analyticsFlushPeriod',
  analyticsPostBatchSize = 'analyticsPostBatchSize',
  maxNrOfFailedAnalytics = 'maxNrOfFailedAnalytics',
  stackTraceMaximumLines = 'stackTraceMaximumLines',

  favoriteCacheExpiration = 'favoriteCacheExpiration',

  reminderCacheExpiration = 'reminderCacheExpiration',

  quoteCacheExpiration = 'quoteCacheExpiration',

  bookmarkCacheExpiration = 'bookmarkCacheExpiration',
  bookmarksMaximumPercent = 'bookmarksMaximumPercent',

  recordingCacheExpiration = 'recordingCacheExpiration',
  recordingAfterTimes = 'recordingAfterTimes',
  recordingBeforeTimes = 'recordingBeforeTimes',
  recordingDefaultBeforeTime = 'recordingDefaultBeforeTime',
  recordingDefaultAfterTime = 'recordingDefaultAfterTime',
  recordingDefaultDeleteProtection = 'recordingDefaultDeleteProtection',

  epgPastDays = 'epgPastDays',
  epgFutureDays = 'epgFutureDays',

  npvrRecordingAboutToExpireInterval = 'npvrRecordingAboutToExpireInterval',

  maxResolution = 'maxResolution',

  playerLivePauseResumeCompensation = 'playerLivePauseResumeCompensation',
  playerPauseStopPlayerTime = 'playerPauseStopPlayerTime',
  playerStillPlayingTime = 'playerStillPlayingTime',
  playerBookmarkTime = 'playerBookmarkTime',
  playerWatchlistTime = 'playerWatchlistTime',
  playerHideControlsPeriod = 'playerHideControlsPeriod',
  defaultKeepaliveTimeout = 'defaultKeepaliveTimeout',
  maxKeepAliveErrors = 'maxKeepAliveErrors',
  playerToLinearConfirmation = 'playerToLinearConfirmation',
  playerFastForwardVod = 'playerFastForwardVod',
  playerRewindVod = 'playerRewindVod',

  broadpeakAnalyticsEnabled = 'broadpeakAnalyticsEnabled',
  broadpeakAnalyticsServer = 'broadpeakAnalyticsServer',

  swipePollTimeout = 'swipePollTimeout',
  swipePollIncrement = 'swipePollIncrement',

  searchMinChars = 'searchMinChars',
  searchKeyTimeout = 'searchKeyTimeout',
  searchMaxSuggestionLength = 'searchMaxSuggestionLength',

  decimalCount = 'decimalCount',
  decimalDivider = 'decimalDivider',

  showSeriesWatchButton = 'showSeriesWatchButton',
  showVodTitles = 'showVodTitles',
  showMiniDetailInsideDetail = 'showMiniDetailInsideDetail',
  showParentalIcons = 'showParentalIcons',

  useOldEpgCards = 'useOldEpgCards',
  allowUnauthenticatedUse = 'allowUnauthenticatedUse',
  chromeCast4kDevices = 'chromeCast4kDevices',
  displaySubtitleTeletextId = 'displaySubtitleTeletextId',
  broadpeakAnalyticsDomain = 'broadpeakAnalyticsDomain',

  chromeCastEnabled = 'chromeCastEnabled',
  unauthorizedAnalytics = 'unauthorizedAnalytics',

  playerInitialBitrate = 'playerInitialBitrate',
  playerInitialBitrate4K = 'playerInitialBitrate4K',

  defaultMaxLinearStreamTime = 'defaultMaxLinearStreamTime',
  restartLinearStreams = 'restartLinearStreams',
  restartLinearStreamFraction = 'restartLinearStreamFraction',
}

export enum CatalogTranslationKeys {
  profile_new = 'profile_new',
  profile_add_title = 'profile_add_title',
  profile_edit_title = 'profile_edit_title',
  profile_property_name = 'profile_property_name',
  profile_property_avatar = 'profile_property_avatar',
  profile_property_catalog = 'profile_property_catalog',
  profile_delete_btn = 'profile_delete_btn',
  profile_cancel_btn = 'profile_cancel_btn',
  profile_create_btn = 'profile_create_btn',
  profile_save_btn = 'profile_save_btn',
  profile_select_title = 'profile_select_title',
  profile_delete_confirm = 'profile_delete_confirm',
  profile_error_name = 'profile_error_name',
  profile_error_name_length = 'profile_error_name_length',
  profile_switch = 'profile_switch',
  catalog_switch = 'catalog_switch',
  catalog_select_title = 'catalog_select_title',
  profiles_manage = 'profiles_manage',
  profile_login_request_popup_title = 'profile_login_request_popup_title',
  profile_logout_request_popup_title = 'profile_logout_request_popup_title',
}

export enum LoginTranslationKeys {
  login_login = 'login_login',
  login_name = 'login_name',
  login_password = 'login_password',
  login_reconnect = 'login_reconnect',
  login_showpassword = 'login_showpassword',
  login_forgotpassword = 'login_forgotpassword',
  login_register = 'login_register',
}

export enum SettingsTranslationKeys {
  settings_title_recording_npvr = 'settings_title_recording_npvr',
  settings_title_recordingdevice = 'settings_title_recordingdevice',
  settings_saved = 'settings_saved',
  settings_logout = 'settings_logout',
  settings_version_web = 'settings_version_web',
  settings_allow_adult_androidtv_title = 'settings_allow_adult_androidtv_title',
  setting_allow_adult_androidtv_on = 'setting_allow_adult_androidtv_on',
  setting_allow_adult_androidtv_off = 'setting_allow_adult_androidtv_off',
  setting_save_failed = 'setting_save_failed',
  settings_greeting_web = 'settings_greeting_web',
  settings_title_reminder = 'settings_title_reminder',
  settings_reminder_before_time = 'settings_reminder_before_time',
  settings_toggle_on = 'settings_toggle_on',
  settings_toggle_off = 'settings_toggle_off',
  settings_yes_stb = 'settings_yes_stb',
  settings_no_stb = 'settings_no_stb',
}

export enum SearchTranslationKeys {
  search_results_now = 'search_results_now',
  search_results_videoclub = 'search_results_videoclub',
  search_results_flashback = 'search_results_flashback',
  search_results_article = 'search_results_article',
  search_results_bundle = 'search_results_bundle',
  search_placeholder = 'search_placeholder',
  search_placeholder_stb = 'search_placeholder_stb',
}

export enum RecordingTranslationKeys {
  recordings_npvr_settopbox_name = 'recordings_npvr_settopbox_name',
  recordings_local_settopbox_name = 'recordings_local_settopbox_name',
  recordings_btn_filter_stb = 'recordings_btn_filter_stb',
  recordings_delete_all = 'recordings_delete_all',
  recordings_cancel_all = 'recordings_cancel_all',
  recordings_delete_all_confirm = 'recordings_delete_all_confirm',
  recordings_cancel_all_confirm = 'recordings_cancel_all_confirm',
  recordings_free_space_npvr = 'recordings_free_space_npvr',
  recordings_free_space_stb = 'recordings_free_space_stb',
  recordings_will_expire = 'recordings_will_expire',
  recordings_free_space_npvr_long = 'recordings_free_space_npvr_long',
  recordings_hours_available = 'recordings_hours_available',
  recordings_minutes_available = 'recordings_minutes_available',
  recordings_minutes_available_stb = 'recordings_minutes_available_stb',
}

export enum PlayerTranslationKeys {
  player_btn_more_details = 'player_btn_more_details',
  player_btn_share = 'player_btn_share',
  player_btn_close = 'player_btn_close',
  player_btn_pause = 'player_btn_pause',

  player_ad_skip_seconds_ott = 'player_ad_skip_seconds_ott',
  player_ad_title = 'player_ad_title',
  player_ad_advertisement_count = 'player_ad_advertisement_count',
  player_ad_action_method_play_trailer_stb = 'player_ad_action_method_play_trailer_stb',
  player_ad_skip_now_ott = 'player_ad_skip_now_ott',
  player_ad_action_method_play_ott = 'player_ad_action_method_play_ott',
  player_ad_action_method_play_trailer_ott = 'player_ad_action_method_play_trailer_ott',
  player_ad_action_method_detail_ott = 'player_ad_action_method_detail_ott',

  player_quality_unit = 'player_quality_unit',
  player_quality_auto = 'player_quality_auto',
  player_subtitle_none = 'player_subtitle_none',

  player_to_linear_confirmation_body = 'player_to_linear_confirmation_body'
}

export enum MyStuffTranslationKeys {
  mystuff_favorite_channels = 'mystuff_favorite_channels',
  mystuff_favorite_programs = 'mystuff_favorite_programs',
  mystuff_favorite_vod = 'mystuff_favorite_vod',
  mystuff_rec_no_recordings = 'mystuff_rec_no_recordings',
  mystuff_rec_no_planned_recordings = 'mystuff_rec_no_planned_recordings',
  mystuff_rec_no_conflicts = 'mystuff_rec_no_conflicts',
  mystuff_rec_no_expired = 'mystuff_rec_no_expired',
  mystuff_conflict_row_prefix = 'mystuff_conflict_row_prefix',
  mystuff_change_adult_pincode = 'mystuff_change_adult_pincode',
  mystuff_change_vod_pincode = 'mystuff_change_vod_pincode',
  mystuff_old_pincode = 'mystuff_old_pincode',
  mystuff_new_pincode = 'mystuff_new_pincode',
  mystuff_confirm_pincode = 'mystuff_confirm_pincode',
  mystuff_change_pincode = 'mystuff_change_pincode',
  mystuff_change_pincode_option_stb = 'mystuff_change_pincode_option_stb',
  mystuff_pincodes_must_match = 'mystuff_pincodes_must_match',
  mystuff_pincodechange_failed = 'mystuff_pincodechange_failed',
  mystuff_pincodechange_succes = 'mystuff_pincodechange_succes',
  mystuff_adult_settings = 'mystuff_adult_settings',
  mystuff_adultmode = 'mystuff_adultmode',
  mystuff_adultmode_save_btn = 'mystuff_adultmode_save_btn',
  mystuff_adultmode_successfully_saved = 'mystuff_adultmode_successfully_saved',
  mystuff_adultmode_stop = 'mystuff_adultmode_stop',
  mystuff_adultmode_activate = 'mystuff_adultmode_activate',
  mystuff_minutes = 'mystuff_minutes',
  mystuff_hour = 'mystuff_hour',
  mystuff_hours = 'mystuff_hours',
  mystuff_adultmode_never = 'mystuff_adultmode_never',
  mystuff_adultmode_activated = 'mystuff_adultmode_activated',
  mystuff_adultmode_stopped = 'mystuff_adultmode_stopped',
  mystuff_filter_recording = 'mystuff_filter_recording',
  mystuff_filter_recording_adult_title = 'mystuff_filter_recording_adult_title',
  mystuff_filter_recording_adult_off = 'mystuff_filter_recording_adult_off',
  mystuff_filter_recording_adult_on = 'mystuff_filter_recording_adult_on',
  mystuff_recording_disk_usage = 'mystuff_recording_disk_usage',
  mystuff_privacy = 'mystuff_privacy',
  mystuff_privacy_analytics = 'mystuff_privacy_analytics',
  mystuff_favorites = 'mystuff_favorites',
}

export enum AndroidTvTranslationKeys {
  android_tv_check_adult_mode_enabled = 'android_tv_check_adult_mode_enabled',
  android_tv_check_adult_mode_disabled = 'android_tv_check_adult_mode_disabled',
}

export enum ChromecastTranslationKeys {
  cast_general_failure = 'cast_general_failure',
  casting_to_title_web = 'casting_to_title_web',
}

export enum ErrorTranslationKeys {
  error_search_no_results = 'error_search_no_results',
  error_filter_no_results = 'error_filter_no_results',
  error_user = 'error_user',
  error_server = 'error_server',
  error_streaming = 'error_streaming',
  error_invalid_pin = 'error_invalid_pin',
  error_general = 'error_general',
  error_load_details = 'error_load_details',
  error_not_on_mobile = 'error_not_on_mobile',
  error_no_pricinginfo = 'error_no_pricinginfo',
  error_title = 'error_title',
  error_vod_expired = 'error_vod_expired',
  error_out_of_replay_window = 'error_out_of_replay_window',
  error_blackout = 'error_blackout',
  error_no_rights = 'error_no_rights',
  error_no_rights_on_this_stb = 'error_no_rights_on_this_stb',
  error_expenditure_limit_exceeded = 'error_expenditure_limit_exceeded',
  error_reconnect = 'error_reconnect',
  error_streaming_notsupported_os_browser_combination = 'error_streaming_notsupported_os_browser_combination',
  error_no_event_on_channel = 'error_no_event_on_channel',
  error_recording_start_failed = 'error_recording_start_failed',
  error_no_reminders = 'error_no_reminders',
  error_unsupported_app_version = 'error_unsupported_app_version',
  error_unsupported_os_version = 'error_unsupported_os_version',
  error_not_inhome = 'error_not_inhome',
  SS_LINEAR_BLACKOUT = 'SS_LINEAR_BLACKOUT',
  SS_REPLAY_BLACKOUT = 'SS_REPLAY_BLACKOUT',
  SS_ENTITLEMENTS_EMPTY = 'SS_ENTITLEMENTS_EMPTY',
  SS_REPLAY_WINDOW_PASSED = 'SS_REPLAY_WINDOW_PASSED',
  SS_ENTITLEMENTS_INHOME_FAILED = 'SS_ENTITLEMENTS_INHOME_FAILED',
  STB_STREAM_LIMIT_REACHED = 'STB_STREAM_LIMIT_REACHED',
  OTT_STREAM_LIMIT_REACHED = 'OTT_STREAM_LIMIT_REACHED',
  SPORTV_OTT_STREAM_LIMIT_REACHED = 'SPORTV_OTT_STREAM_LIMIT_REACHED',
  AA_INVALID_PINCODE = 'AA_INVALID_PINCODE',
  PRICING_EXPENDITURE_LIMIT_REACHED = 'PRICING_EXPENDITURE_LIMIT_REACHED',
  error_recording_no_recordings = 'error_recording_no_recordings',
  error_recording_box_unreachable = 'error_recording_box_unreachable',
  error_recording_no_conflicts = 'error_recording_no_conflicts',
  STREAM_LIMIT_EXCEEDED = 'STREAM_LIMIT_EXCEEDED',
  STB_STREAM_LIMIT_EXCEEDED = 'STB_STREAM_LIMIT_EXCEEDED',
  error_recording_edit_failed = 'error_recording_edit_failed',
  error_recording_delete_failed = 'error_recording_delete_failed',
  error_recording_cancel_failed = 'error_recording_cancel_failed',
  error_recording_playback = 'error_recording_playback',
  error_swipe = 'error_swipe',
  STREAM_VOD_3G_FORBIDDEN = 'STREAM_VOD_3G_FORBIDDEN',
  SS_NPVR_BLACKOUT = 'SS_NPVR_BLACKOUT',
  BTV_OTT_STREAM_LIMIT_REACHED = 'BTV_OTT_STREAM_LIMIT_REACHED',
  VOD_OTT_STREAM_LIMIT_REACHED = 'VOD_OTT_STREAM_LIMIT_REACHED',
  VOD_STB_STREAM_LIMIT_REACHED = 'VOD_STB_STREAM_LIMIT_REACHED',
  error_device_time = 'error_device_time',
  error_no_quote = 'error_no_quote',
  error_in_future = 'error_in_future',
  error_no_rentoptions = 'error_no_rentoptions',
  PRICING_COUPON_NOT_FOUND = 'PRICING_COUPON_NOT_FOUND',
  PRICING_COUPON_ALREADY_CLAIMED = 'PRICING_COUPON_ALREADY_CLAIMED',
  PRICING_INVALID_COUPON_CLAIM = 'PRICING_INVALID_COUPON_CLAIM',
  PRICING_INVALID_PIN_TYPE = 'PRICING_INVALID_PIN_TYPE',
  PRICING_COUPON_EMPTY = 'PRICING_COUPON_EMPTY',
  PRICING_QUOTE_STREAM_ALREADY_PURCHASED = 'PRICING_QUOTE_STREAM_ALREADY_PURCHASED',
  error_not_replayable_on_this_stb = 'error_not_replayable_on_this_stb',
  AA_CUSTOMER_USER_NOT_FOUND = 'AA_CUSTOMER_USER_NOT_FOUND',
  AA_INVALID_LOGIN = 'AA_INVALID_LOGIN',
  AA_CUSTOMER_USER_LOCKED = 'AA_CUSTOMER_USER_LOCKED',
  COUPON_EXPIRED = 'COUPON_EXPIRED',
  COUPON_NOT_VALID = 'COUPON_NOT_VALID',
  NOWO_INHOUSE_CHECK_ERROR = 'NOWO_INHOUSE_CHECK_ERROR',
  error_bundle_expired = 'error_bundle_expired',
  error_no_play_rights_vod = 'error_no_play_rights_vod',
  PRICING_COUPON_EXPIRED = 'PRICING_COUPON_EXPIRED',
  error_no_rights_detail_description = 'error_no_rights_detail_description',
  error_blackout_dvbc_ott = 'error_blackout_dvbc_ott',
  error_blackout_linear_ott = 'error_blackout_linear_ott',
  error_blackout_npvr_ott = 'error_blackout_npvr_ott',
  error_blackout_replay_ott = 'error_blackout_replay_ott',
  error_blackout_dvbc_stb = 'error_blackout_dvbc_stb',
  error_blackout_linear_stb = 'error_blackout_linear_stb',
  error_blackout_npvr_stb = 'error_blackout_npvr_stb',
  error_blackout_replay_stb = 'error_blackout_replay_stb',
  error_authentication_failed = 'error_authentication_failed',
  EPG_SCHEDULE_NOT_FOUND = 'EPG_SCHEDULE_NOT_FOUND',
  player_disable_add_block = 'player_disable_add_block',

  browser_not_supported_title = 'browser_not_supported_title',
  browser_not_supported_message = 'browser_not_supported_message',
  browser_not_supported_download = 'browser_not_supported_download',
}

export enum MenuTranslationKeys {
  menu_favorites = 'menu_favorites',
  menu_flashback = 'menu_flashback',
  menu_lists = 'menu_lists',
  menu_movies = 'menu_movies',
  menu_my_favorite_channels = 'menu_my_favorite_channels',
  menu_my_scenes = 'menu_my_scenes',
  menu_guide = 'menu_guide',
  menu_now = 'menu_now',
  menu_recommended = 'menu_recommended',
  menu_search = 'menu_search',
  menu_series = 'menu_series',
  menu_settings = 'menu_settings',
  menu_tv_guide = 'menu_tv_guide',
  menu_video_club = 'menu_video_club',
  menu_recordings = 'menu_recordings',
  menu_recordings_recorded = 'menu_recordings_recorded',
  menu_recordings_planned = 'menu_recordings_planned',
  menu_recordings_planned_ott = 'menu_recordings_planned_ott',
  menu_recordings_conflicts = 'menu_recordings_conflicts',
  menu_recordings_expire = 'menu_recordings_expire',
  menu_reminders = 'menu_reminders',
  menu_clientarea = 'menu_clientarea',
  menu_new = 'menu_new',
  menu_novo = 'menu_novo',
  menu_apps_stb = 'menu_apps_stb',
  menu_apps_radio = 'menu_apps_radio',
  menu_media_center_stb = 'menu_media_center_stb',
  menu_subscriptions = 'menu_subscriptions',
  menu_message_inbox = 'menu_message_inbox',
  menu_active_subscriptions = 'menu_active_subscriptions',
  menu_recent = 'menu_recent',
  menu_channels = 'menu_channels',
  menu_adult = 'menu_adult',
  menu_profiles = 'menu_profiles',
}

export enum RibbonTranslationKeys {
  ribbon_filter_category = 'ribbon_filter_category',
  ribbon_filter_year = 'ribbon_filter_year',
  ribbon_filter_title = 'ribbon_filter_title',
  ribbon_btn_filter_category = 'ribbon_btn_filter_category',
  ribbon_filter_year_stb = 'ribbon_filter_year_stb',
  ribbon_filter_rating_stb = 'ribbon_filter_rating_stb',
  ribbon_btn_filter_year = 'ribbon_btn_filter_year',
  ribbon_btn_filter_rating = 'ribbon_btn_filter_rating',
  ribbon_filter_rating = 'ribbon_filter_rating',
  ribbon_no_content_stb = 'ribbon_no_content_stb',
  ribbon_season_episode = 'ribbon_season_episode',
  ribbon_season = 'ribbon_season',
  ribbon_episode = 'ribbon_episode',
  ribbon_no_content_ott = 'ribbon_no_content_ott',
}

export enum ListTranslationKeys {
  lists_filter_day = 'lists_filter_day',
  lists_filter_channel = 'lists_filter_channel',
  lists_filter_category = 'lists_filter_category',
  lists_filter_title = 'lists_filter_title',
  lists_filter_year_rating_stb = 'lists_filter_year_rating_stb',
  lists_filter_clear = 'lists_filter_clear',
  lists_filter_close = 'lists_filter_close',
  lists_btn_filterday = 'lists_btn_filterday',
  lists_btn_filterchannel = 'lists_btn_filterchannel',
  lists_btn_filtercategory = 'lists_btn_filtercategory',
  lists_btn_clearfilters = 'lists_btn_clearfilters',
  lists_season_short = 'lists_season_short',
  lists_filter_all_value = 'lists_filter_all_value',
  lists_filter_group = 'lists_filter_group',
  lists_btn_filterchannelgroup = 'lists_btn_filterchannelgroup',
}

export enum GuideTranslationKeys {
  guide_filter_category = 'guide_filter_category',
  guide_now_bar = 'guide_now_bar',
  guide_filter_title = 'guide_filter_title',
  guide_filter_day = 'guide_filter_day',
  guide_btn_filtercategory = 'guide_btn_filtercategory',
  guide_btn_clearfilters = 'guide_btn_clearfilters',
  guide_adult_title = 'guide_adult_title',
  guide_no_information = 'guide_no_information',
}

export enum DetailTranslationKeys {
  detail_duration_min = 'detail_duration_min',
  detail_actors = 'detail_actors',
  detail_directors = 'detail_directors',
  detail_season = 'detail_season',
  detail_episode = 'detail_episode',
  detail_tab_episodes = 'detail_tab_episodes',
  detail_tab_related = 'detail_tab_related',
  detail_watch_cancel = 'detail_watch_cancel',
  detail_watch_from_beginning = 'detail_watch_from_beginning',
  detail_watch_from_beginning_stb = 'detail_watch_from_beginning_stb',
  detail_watch_from_bookmark = 'detail_watch_from_bookmark',
  detail_watch_from_bookmark_stb = 'detail_watch_from_bookmark_stb',
  detail_imdb_rating = 'detail_imdb_rating',
  detail_available_until = 'detail_available_until',
  detail_gratis = 'detail_gratis',
  detail_season_short = 'detail_season_short',
  detail_episode_short = 'detail_episode_short',
  detail_recording_footer = 'detail_recording_footer',
  detail_recording_lock_stb = 'detail_recording_lock_stb',
  detail_recording_unlock_stb = 'detail_recording_unlock_stb',
  detail_recording_delete_stb = 'detail_recording_delete_stb',
  detail_recording_stop_stb = 'detail_recording_stop_stb',
  detail_recording_cancel_stb = 'detail_recording_cancel_stb',
  detail_recording_start_stb = 'detail_recording_start_stb',
  detail_recording_change_rec_options_stb = 'detail_recording_change_rec_options_stb',
  detail_recording_change_rec_option_what_stb = 'detail_recording_change_rec_option_what_stb',
  detail_recording_change_rec_option_before_stb = 'detail_recording_change_rec_option_before_stb',
  detail_recording_change_rec_option_after_stb = 'detail_recording_change_rec_option_after_stb',
  detail_recording_change_rec_option_lock_stb = 'detail_recording_change_rec_option_lock_stb',
  detail_recording_other_rec_ep_stb = 'detail_recording_other_rec_ep_stb',
  detail_recording_other_planned_ep_stb = 'detail_recording_other_planned_ep_stb',
  detail_favorite_stb = 'detail_favorite_stb',
  detail_unfavorite_stb = 'detail_unfavorite_stb',
  detail_favorite_channel = 'detail_favorite_channel',
  detail_unfavorite_channel = 'detail_unfavorite_channel',
  detail_recommended_stb = 'detail_recommended_stb',
  detail_selecttrailer_trailer = 'detail_selecttrailer_trailer',
  detail_btn_subtitles = 'detail_btn_subtitles',
  detail_btn_watchnow = 'detail_btn_watchnow',
  detail_btn_watch_now = 'detail_btn_watch_now',
  detail_btn_rent_now = 'detail_btn_rent_now',
  detail_btn_series = 'detail_btn_series',
  detail_recommended_others_watched = 'detail_recommended_others_watched',
  detail_recommended_others_watched_stb = 'detail_recommended_others_watched_stb',
  detail_recommended_more_like_this = 'detail_recommended_more_like_this',
  detail_recommended_more_like_this_stb = 'detail_recommended_more_like_this_stb',
  detail_enter_pincode = 'detail_enter_pincode',
  detail_btn_trailer = 'detail_btn_trailer',
  detail_btn_trailer_stb = 'detail_btn_trailer_stb',
  detail_trailer_popup_title_stb = 'detail_trailer_popup_title_stb',
  detail_watch_popup_title_stb = 'detail_watch_popup_title_stb',
  detail_recording_record_popup_title_stb = 'detail_recording_record_popup_title_stb',
  detail_recording_record_current_program_stb = 'detail_recording_record_current_program_stb',
  detail_recording_record_current_season_stb = 'detail_recording_record_current_season_stb',
  detail_recording_record_serie_stb = 'detail_recording_record_serie_stb',
  detail_recording_cancel_popup_title_stb = 'detail_recording_cancel_popup_title_stb',
  detail_recording_cancel_current_program_stb = 'detail_recording_cancel_current_program_stb',
  detail_recording_cancel_current_season_stb = 'detail_recording_cancel_current_season_stb',
  detail_recording_cancel_serie_stb = 'detail_recording_cancel_serie_stb',
  detail_recording_delete_popup_title_stb = 'detail_recording_delete_popup_title_stb',
  detail_recording_delete_current_program_stb = 'detail_recording_delete_current_program_stb',
  detail_recording_delete_current_season_stb = 'detail_recording_delete_current_season_stb',
  detail_recording_delete_serie_stb = 'detail_recording_delete_serie_stb',
  detail_recording_change_rec_options_popup_title_stb = 'detail_recording_change_rec_options_popup_title_stb',
  detail_recording_before_after_template = 'detail_recording_before_after_template',
  detail_recording_program_stb = 'detail_recording_program_stb',
  detail_recording_season_stb = 'detail_recording_season_stb',
  detail_recording_serie_stb = 'detail_recording_serie_stb',
  detail_watch_live = 'detail_watch_live',
  detail_watch_live_stb = 'detail_watch_live_stb',
  detail_watch_pip_stb = 'detail_watch_pip_stb',
  detail_watch_pip_short_stb = 'detail_watch_pip_short_stb',
  detail_share_text = 'detail_share_text',
  detail_share_text_epg = 'detail_share_text_epg',
  detail_share_text_vod = 'detail_share_text_vod',
  detail_share_using = 'detail_share_using',
  detail_reminder_text = 'detail_reminder_text',
  detail_reminder_title = 'detail_reminder_title',
  detail_btn_all_episodes = 'detail_btn_all_episodes',
  detail_btn_all_episodes_stb = 'detail_btn_all_episodes_stb',
  detail_purchase_pincode = 'detail_purchase_pincode',
  detail_purchase_popup_title_stb = 'detail_purchase_popup_title_stb',
  detail_btn_purchase = 'detail_btn_purchase',
  detail_btn_serie_info_stb = 'detail_btn_serie_info_stb',
  detail_btn_set_reminder_stb = 'detail_btn_set_reminder_stb',
  detail_btn_remove_reminder_stb = 'detail_btn_remove_reminder_stb',
  detail_btn_other_episodes_stb = 'detail_btn_other_episodes_stb',
  detail_btn_sub_and_audio_stb = 'detail_btn_sub_and_audio_stb',
  detail_btn_serie_record = 'detail_btn_serie_record',
  detail_popup_sub_and_audio_stb = 'detail_popup_sub_and_audio_stb',
  detail_btn_change_audio_stb = 'detail_btn_change_audio_stb',
  detail_btn_change_subtitles_stb = 'detail_btn_change_subtitles_stb',
  detail_btn_teletext_on_stb = 'detail_btn_teletext_on_stb',
  detail_btn_teletext_off_stb = 'detail_btn_teletext_off_stb',
  detail_popup_sub_and_audio_no_items_stb = 'detail_popup_sub_and_audio_no_items_stb',
  detail_option_sub_dvb_stb = 'detail_option_sub_dvb_stb',
  detail_option_sub_txt_stb = 'detail_option_sub_txt_stb',
  detail_option_sub_no_subtitles_stb = 'detail_option_sub_no_subtitles_stb',
  detail_option_audio_and_codec_stb = 'detail_option_audio_and_codec_stb',
  detail_btn_show_details = 'detail_btn_show_details',
  detail_tab_recordings = 'detail_tab_recordings',
  detail_recording_start = 'detail_recording_start',
  detail_recording_delete = 'detail_recording_delete',
  detail_recording_stop = 'detail_recording_stop',
  detail_recording_cancel = 'detail_recording_cancel',
  detail_recording_delete_protected = 'detail_recording_delete_protected',
  detail_recording_this_episode = 'detail_recording_this_episode',
  detail_recording_this_season = 'detail_recording_this_season',
  detail_recording_this_series = 'detail_recording_this_series',
  detail_recording_episodes = 'detail_recording_episodes',
  detail_recording_record_popup_title = 'detail_recording_record_popup_title',
  detail_recording_stb = 'detail_recording_stb',
  detail_recording_change_rec_option_before = 'detail_recording_change_rec_option_before',
  detail_recording_change_rec_option_after = 'detail_recording_change_rec_option_after',
  detail_recording_edit_popup_title = 'detail_recording_edit_popup_title',
  detail_btn_recording_edit = 'detail_btn_recording_edit',
  detail_recording_delete_confirm = 'detail_recording_delete_confirm',
  detail_recording_cancel_program = 'detail_recording_cancel_program',
  detail_recording_cancel_season = 'detail_recording_cancel_season',
  detail_recording_cancel_series = 'detail_recording_cancel_series',
  detail_swipe_this_device = 'detail_swipe_this_device',
  detail_btn_record_record = 'detail_btn_record_record',
  detail_btn_record_cancel = 'detail_btn_record_cancel',
  detail_adult_warning = 'detail_adult_warning',
  detail_vouchercoupon = 'detail_vouchercoupon',
  detail_claimvoucher_title = 'detail_claimvoucher_title',
  detail_price = 'detail_price',
  detail_recorded_on = 'detail_recorded_on',
  detail_planned_on = 'detail_planned_on',
  detail_stb_fallback_name = 'detail_stb_fallback_name',
  detail_included_in_subscriptions = 'detail_included_in_subscriptions',
  detail_available_in_subscription = 'detail_available_in_subscription',
  detail_included_in_subscriptions_on_stb = 'detail_included_in_subscriptions_on_stb',
  detail_btn_show_subscriptions_stb = 'detail_btn_show_subscriptions_stb',
  detail_included_in_bundle = 'detail_included_in_bundle',
  detail_btn_bundle = 'detail_btn_bundle',
  detail_succes_swipe = 'detail_succes_swipe',
  detail_succes_rent_bundle = 'detail_succes_rent_bundle',
  detail_tab_included_in_bundle = 'detail_tab_included_in_bundle',
  detail_free_to_watch = 'detail_free_to_watch',
  detail_btn_more = 'detail_btn_more',
  detail_favorite = 'detail_favorite',
  detail_unfavorite = 'detail_unfavorite',
}

export enum SwipeTranslationKeys {
  swipe_title = 'swipe_title',
  swipe_device = 'swipe_device',
  swipe_playback_option = 'swipe_playback_option',
  swipe_this_device = 'swipe_this_device',
  swipe_title_device_selection = 'swipe_title_device_selection',
}

export enum PopupTranslationKeys {
  popup_btn_cancel = 'popup_btn_cancel',
  popup_btn_ok = 'popup_btn_ok',
  popup_btn_close = 'popup_btn_close',
  popup_btn_confirm = 'popup_btn_confirm',
  popup_btn_delete = 'popup_btn_delete',
  popup_title_choose_option = 'popup_title_choose_option',
  popup_reminder_text = 'popup_reminder_text',
  popup_reminder_text_stb = 'popup_reminder_text_stb',
  popup_reminder_title = 'popup_reminder_title',
  popup_reminder_option_zap_stb = 'popup_reminder_option_zap_stb',
  popup_reminder_option_details_stb = 'popup_reminder_option_details_stb',
  popup_pincode_stayinadultmode = 'popup_pincode_stayinadultmode',
  popup_pincode_stayinadultmode_stb = 'popup_pincode_stayinadultmode_stb',
  popup_adultmode_title_stb = 'popup_adultmode_title_stb',
  popup_adultmode_pin_prefix_stb = 'popup_adultmode_pin_prefix_stb',
  popup_adultmode_turned_off_title_stb = 'popup_adultmode_turned_off_title_stb',
  popup_adultmode_turned_off_body_stb = 'popup_adultmode_turned_off_body_stb',
  popup_conflict_added_title_stb = 'popup_conflict_added_title_stb',
  popup_conflict_added_body_stb = 'popup_conflict_added_body_stb',
  popup_conflict_added_option_show_stb = 'popup_conflict_added_option_show_stb',
  popup_swiped_stb = 'popup_swiped_stb',
  popup_exit_title = 'popup_exit_title',
  popup_still_watching_body = 'popup_still_watching_body',
  popup_still_watching_btn_continue = 'popup_still_watching_btn_continue',
}

export enum TimeTranslationKeys {
  time_day_short_mon = 'time_day_short_mon',
  time_day_short_tue = 'time_day_short_tue',
  time_day_short_wed = 'time_day_short_wed',
  time_day_short_thu = 'time_day_short_thu',
  time_day_short_fri = 'time_day_short_fri',
  time_day_short_sat = 'time_day_short_sat',
  time_day_short_sun = 'time_day_short_sun',
  time_month_short_january = 'time_month_short_january',
  time_month_short_february = 'time_month_short_february',
  time_month_short_march = 'time_month_short_march',
  time_month_short_april = 'time_month_short_april',
  time_month_short_may = 'time_month_short_may',
  time_month_short_june = 'time_month_short_june',
  time_month_short_july = 'time_month_short_july',
  time_month_short_august = 'time_month_short_august',
  time_month_short_september = 'time_month_short_september',
  time_month_short_october = 'time_month_short_october',
  time_month_short_november = 'time_month_short_november',
  time_month_short_december = 'time_month_short_december',
  time_today = 'time_today',
  time_tomorrow = 'time_tomorrow',
  time_yesterday = 'time_yesterday',
  time_day_monday = 'time_day_monday',
  time_day_tuesday = 'time_day_tuesday',
  time_day_wednesday = 'time_day_wednesday',
  time_day_thursday = 'time_day_thursday',
  time_day_friday = 'time_day_friday',
  time_day_saturday = 'time_day_saturday',
  time_day_sunday = 'time_day_sunday',
  time_hour_short = 'time_hour_short',
  time_minutes_short = 'time_minutes_short',
  time_duration_1_min = 'time_duration_1_min',
  time_duration_min = 'time_duration_min',
  time_duration_1_hour = 'time_duration_1_hour',
  time_duration_hours = 'time_duration_hours',
}
