import { Injectable, NgZone } from '@angular/core';
import { AuthResponse } from '@atv-core/services/authorization/authorization.service';
import { fromEvent, Observable, of, race, Subscriber, Subscription, timer } from 'rxjs';
import { LocalStorageKeys } from '@atv-core/utility/constants/localStorageKeys';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TokenCommunicationService {
  private logoutChannel: BroadcastChannel;
  private activeTokenChannel: BroadcastChannel;
  private tokenRequestChannel: BroadcastChannel;
  private activeTabSubscription?: Subscription;
  private activeTokenListener?: Subscriber<boolean>;

  private readonly activeTabBeaconInterval = 60000;
  private readonly uniqueId: string;

  constructor(private zone: NgZone) {
    this.createLogoutChannel();
    this.createActiveTokenChannel();
    this.createTokenRequestChannel();

    this.uniqueId = window.crypto.getRandomValues(new Uint8Array(16)).join('');

    fromEvent(window, 'beforeunload').subscribe(() => this.stopActiveTabBeacon());
  }

  public broadcastLogout(): void {
    this.logoutChannel.postMessage({});
  }

  public setLogoutAction(action: () => void): void {
    this.logoutChannel.onmessage = () => this.zone.run(action);
  }

  public broadcastActiveToken(token: AuthResponse): void {
    this.activeTokenChannel.postMessage(token);
  }

  public setActiveTokenAction(action: (token: AuthResponse) => void): void {
    this.activeTokenChannel.onmessage = (event) => {
      this.zone.run(() => action(event.data));

      if (this.activeTokenListener) {
        this.activeTokenListener.next(true);
        this.activeTokenListener = undefined;
      }
    };
  }

  public setTokenRequestAction(action: () => void): void {
    this.tokenRequestChannel.onmessage = () => this.zone.run(action);
  }

  public requestTokenBroadcast(): Observable<boolean> {
    if (!this.hasActiveTabBeacon()) {
      // no beacon found
      return of(false);
    }

    return race<boolean>(
      timer(1000).pipe(map(() => false)),
      new Observable(obs => {
        this.activeTokenListener = obs;
        // request token from other tabs
        this.tokenRequestChannel.postMessage({});
      })).pipe(tap(() => {
      // clear listener for active token
      this.activeTokenListener?.unsubscribe();
      this.activeTokenListener = undefined;
    }));
  }

  public startActiveTabBeacon(): void {
    this.activeTabSubscription?.unsubscribe();
    this.activeTabSubscription = timer(0, this.activeTabBeaconInterval).subscribe(() => {
      // log current timestamp to localStorage so other tabs can see there is an active tab
      localStorage.setItem(`${LocalStorageKeys.ACTIVE_TAB_BEACON}-${this.uniqueId}`, String(new Date().getTime()));
    });
  }

  public stopActiveTabBeacon(): void {
    this.activeTabSubscription?.unsubscribe();
    localStorage.removeItem(`${LocalStorageKeys.ACTIVE_TAB_BEACON}-${this.uniqueId}`);
  }

  private createLogoutChannel(): void {
    this.logoutChannel = new BroadcastChannel('atv_logout');
    this.logoutChannel.onmessage = () => {
      console.error('No logout action set');
    };
  }

  private createActiveTokenChannel(): void {
    this.activeTokenChannel = new BroadcastChannel('atv_active_token');
    this.activeTokenChannel.onmessage = () => {
      console.error('No active token action set');
    };
  }

  private createTokenRequestChannel(): void {
    this.tokenRequestChannel = new BroadcastChannel('atv_token_request');
    this.tokenRequestChannel.onmessage = () => {
      console.error('No token request action set');
    };
  }

  private hasActiveTabBeacon(): boolean {
    const allBeacons = Object.keys(localStorage).filter(key => key.startsWith(LocalStorageKeys.ACTIVE_TAB_BEACON));

    const limit = new Date().getTime() - 2 * this.activeTabBeaconInterval;

    return allBeacons.some(beacon => {
      const timestamp = parseInt(localStorage.getItem(beacon), 10);

      if (timestamp < limit) {
        // beacon is too old, remove
        localStorage.removeItem(beacon);
        return false;
      }

      return true;
    });
  }
}
