import { BookmarkContentTypes, BookmarkElement, ScheduleTime } from '@atv-core/api/history';

export enum BookmarkQueryTypes {
  ALL = 'all',
  PARTIAL = 'partial',
}

export class BookmarkModel implements BookmarkElement {
  type: BookmarkContentTypes;
  id: string;
  series?: string;
  position?: number;
  size?: number;
  adult?: boolean;
  schedules?: ScheduleTime[];
  lastUpdate: string;

  // added functionality
  private bookmarksMaximumPercent: number;

  constructor(bookmark: BookmarkElement, bookmarksMaximumPercent: number) {
    this.bookmarksMaximumPercent = bookmarksMaximumPercent;
    Object.keys(bookmark).map((key) => (this[key] = bookmark[key]));
  }

  public isFullyWatched() {
    return this.position && this.size && this.position >= this.size * this.bookmarksMaximumPercent;
  }
}
