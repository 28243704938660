import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

export enum TooltipPositions {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy, OnInit {
  private tooltipText: string;
  @Input() set appTooltip(value) {
    this.tooltipText = value;

    if (this.tooltipChild) {
      this.onMouseLeave();
      this.onMouseEnter();
    }
  }
  @Input() appTooltipPosition: TooltipPositions;

  private tooltipChild;
  constructor(private render: Renderer2, private elRef: ElementRef) {}

  ngOnInit(): void {}

  private getTooltipPositionClass() {
    switch (this.appTooltipPosition) {
      case TooltipPositions.TOP:
        return 'tooltip-top';
      case TooltipPositions.BOTTOM:
        return 'tooltip-bottom';
      case TooltipPositions.LEFT:
        return 'tooltip-left';
      case TooltipPositions.RIGHT:
        return 'tooltip-right';
      default:
        return 'tooltip-top';
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.tooltipChild = this.render.createElement('span');
    this.render.addClass(this.elRef.nativeElement, 'tooltip-parent');
    this.render.addClass(this.tooltipChild, 'tooltip');
    this.render.addClass(this.tooltipChild, this.getTooltipPositionClass());
    const text = this.render.createText(this.tooltipText);
    this.render.appendChild(this.tooltipChild, text);
    this.render.appendChild(this.elRef.nativeElement, this.tooltipChild);
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    // hide tooltip
    this.render.removeChild(this.elRef.nativeElement, this.tooltipChild);
    this.tooltipChild = undefined;
  }

  ngOnDestroy(): void {
    // hide tooltip
  }
}
