import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { AuthorizationGuard } from './core/guards/authorization.guard';
import { FeatureGuard } from './core/guards/feature.guard';
import { SessionGuard } from './core/guards/session.guard';
import { AddAuthorizationInterceptor } from './core/interceptors/add-authorization.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { VersionCheckInterceptor } from './core/interceptors/version-check.interceptor';
import { CMS_ACTION_HANDLER } from './services/cms-action/cms-action.model';
import { DetailArticleAction } from './services/cms-action/handlers/detail-article-action';
import { DetailBundleAction } from './services/cms-action/handlers/detail-bundle-action';
import { DetailEpgAction } from './services/cms-action/handlers/detail-epg-action';
import { DetailPageAction } from './services/cms-action/handlers/detail-page-action';
import { DetailVodAction } from './services/cms-action/handlers/detail-vod-action';
import { PlayChannelAction } from './services/cms-action/handlers/play-channel-action';
import { PlayProgramAction } from './services/cms-action/handlers/play-program-action';
import { PlayRecordingAction } from './services/cms-action/handlers/play-recording-action';
import { PlayScheduleAction } from './services/cms-action/handlers/play-schedule-action';
import { PlayTrailerVodAction } from './services/cms-action/handlers/play-trailer-vod-action';
import { PlayVodAction } from './services/cms-action/handlers/play-vod-action';
import { BootstrapModule } from '../bootstrap/bootstrap.module';

@NgModule({
  imports: [CommonModule, BootstrapModule],
  declarations: [],
  providers: [
    FeatureGuard,
    SessionGuard,
    AuthorizationGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VersionCheckInterceptor,
      multi: true,
    },
    {
      provide: CMS_ACTION_HANDLER,
      useClass: DetailEpgAction,
      multi: true,
    },
    {
      provide: CMS_ACTION_HANDLER,
      useClass: DetailVodAction,
      multi: true,
    },
    {
      provide: CMS_ACTION_HANDLER,
      useClass: DetailBundleAction,
      multi: true,
    },
    {
      provide: CMS_ACTION_HANDLER,
      useClass: DetailArticleAction,
      multi: true,
    },
    {
      provide: CMS_ACTION_HANDLER,
      useClass: PlayTrailerVodAction,
      multi: true,
    },
    {
      provide: CMS_ACTION_HANDLER,
      useClass: PlayVodAction,
      multi: true,
    },
    {
      provide: CMS_ACTION_HANDLER,
      useClass: PlayProgramAction,
      multi: true,
    },
    {
      provide: CMS_ACTION_HANDLER,
      useClass: PlayScheduleAction,
      multi: true,
    },
    {
      provide: CMS_ACTION_HANDLER,
      useClass: PlayChannelAction,
      multi: true,
    },
    {
      provide: CMS_ACTION_HANDLER,
      useClass: PlayRecordingAction,
      multi: true,
    },
    {
      provide: CMS_ACTION_HANDLER,
      useClass: DetailPageAction,
      multi: true,
    },
  ],
})
export class AtvCoreModule {
  constructor(
    @Optional()
    @SkipSelf()
      parentModule: AtvCoreModule,
  ) {
    if (parentModule) {
      throw new Error('AtvCoreModule is already loaded. Import it in the AppModule only!');
    }
  }
}
