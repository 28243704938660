import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { environment } from '@env/environment';

export enum NavigationSections {
  MENU = 'menu',
  LOGIN = 'login',
  CMS_TOP_BANNER = 'cms-top-banner',
  FILTERS = 'filter',
  RECORDING_FILTERS = 'recording-filters',
  PAGE_CONTENT = 'page-content',
  SCHEDULES = 'schedules',
  SEARCH_BAR = 'search-bar',
  TOGGLE_ADULT = 'toggle-adult',
  DELETE_ALL_RECORDINGS = 'delete-all-recordings',
  FILTER_OPTIONS = 'filter-options',
  SELECT_CATALOG = 'select-catalog',
  SELECT_PROFILE = 'select-profile',
  PROFILE_EDITOR_NAME = 'profile-editor-name',
  PROFILE_EDITOR_BUTTONS = 'profile-editor-buttons',
  PROFILE_EDITOR_CATALOGS = 'profile-editor-catalogs',
  PROFILE_EDITOR_AVATARS = 'profile-editor-avatars',
  PROFILE_BUTTONS = 'profile-buttons',
  DETAIL_PAGE_BUTTONS = 'detail-page-buttons',
  DETAIL_PAGE_RIBBONS = 'detail-page-ribbons',
  DETAIL_PAGE_SEASON_SELECTION = 'detail-page-season-selection',
  OVERLAY_OPTIONS = 'overlay-options',
  OVERLAY_OPTION_BUTTONS = 'overlay-options-buttons',
  PLAYER_CONTROLS = 'player-controls',
  PLAYER_ADVERTISEMENT_CONTROLS = 'player-advertisement-controls',
  PLAYER_OPTIONS = 'player-options',
  MINI_GUIDE_OPTIONS = 'mini-guide-options',
  ADULT_POPUP_OPTIONS = 'adult-popup-options',
  ADULT_POPUP_PINCODE = 'adult-popup-pincode',
  POPUP_BUTTONS = 'popup-buttons',
  SETTINGS_PAGE = 'settings-page'
}

@Injectable({
  providedIn: 'root',
})
export class SpatialNavigationService {
  public isMenuFocusedEvent = new EventEmitter<boolean>();
  public scrollTopEvent = new EventEmitter<void>();
  private currentSection: NavigationSections;
  private lastPageContentSection = null;
  private lastScheduleElement: HTMLDivElement = null;

  constructor(private zone: NgZone) {
    if (SharedUtilityService.isSmartTv()) {
      SpatialNavigation.init(this.zone);
      SpatialNavigation.set({
        tabIndexIgnoreList: 'input, select, textarea, button, iframe, [contentEditable=true]',
      });

      document.addEventListener('sn:focused', (event: SpatialNavigation.FocusedEvent) => {
        const newSection = event.detail.sectionId as NavigationSections;
        this.checkMenuSectionChange(newSection);
        this.checkScrollTopChange(newSection);

        this.currentSection = newSection;

        if (this.currentSection.startsWith(NavigationSections.PAGE_CONTENT)) {
          this.lastPageContentSection = this.currentSection;
        }
      });
    }
  }

  public getCurrentSection(): NavigationSections | string {
    return this.currentSection;
  }

  public register(
    section: NavigationSections | string,
    selector: string,
    options: SpatialNavigation.Options = {},
  ): void {
    options.selector = selector;
    SpatialNavigation.add(section, options);
    SpatialNavigation.makeFocusable();
  }

  public unregister(section: NavigationSections | string): void {
    SpatialNavigation.remove(section);
  }

  public setFocus(section?: NavigationSections | string | HTMLElement): void {
    window.setTimeout(() => {
      SpatialNavigation.makeFocusable();
      SpatialNavigation.focus(section);
    }, 0);
  }

  public setDefaultSection(section: NavigationSections | string): void {
    SpatialNavigation.setDefaultSection(section);
  }

  public move(direction: 'up' | 'down' | 'left' | 'right'): void {
    SpatialNavigation.move(direction);
  }

  public resume(): void {
    SpatialNavigation.resume();
  }

  public pause(): void {
    SpatialNavigation.pause();
  }

  public keyCodeIsReturn(keyCode: number): boolean {
    return (
      keyCode === 461 ||
      // Tizen back button
      keyCode === 10009 ||
      (keyCode === 8 && !environment.production) // allow using backspace as "return" button when developing
    );
  }

  public keyCodeIsCancel(keyCode: number): boolean {
    // Tizen cancel button
    return keyCode === 65385;
  }

  public keyCodeIsEnter(keyCode: number): boolean {
    return (
      keyCode === 13 ||
      // Tizen keyboard Done button
      keyCode === 65376
    );
  }

  public keyCodeIsLeft(keyCode: number): boolean {
    return keyCode === 37;
  }

  public keyCodeIsRight(keyCode: number): boolean {
    return keyCode === 39;
  }

  public keyCodeIsUp(keyCode: number): boolean {
    return keyCode === 38;
  }

  public keyCodeIsDown(keyCode: number): boolean {
    return keyCode === 40;
  }

  public keyCodeIsMediaPlay(keyCode: number): boolean {
    // Tizen play button
    return keyCode === 415;
  }

  public keyCodeIsMediaPause(keyCode: number): boolean {
    // Tizen pause button
    return keyCode === 19;
  }

  public keyCodeIsMediaStop(keyCode: number): boolean {
    // Tizen stop button
    return keyCode === 413;
  }

  public keyCodeIsMediaFastForward(keyCode: number): boolean {
    // Tizen fast forward button
    return keyCode === 417;
  }

  public keyCodeIsMediaRewind(keyCode: number): boolean {
    // Tizen rewind button
    return keyCode === 412;
  }

  public keyCodeIsNumeric(keyCode: number): boolean {
    return keyCode >= 48 && keyCode <= 57;
  }

  public keyCodeIsChannelUp(keyCode: number): boolean {
    // Tizen channel up button
    return keyCode === 427;
  }

  public keyCodeIsChannelDown(keyCode: number): boolean {
    // Tizen channel down button
    return keyCode === 428;
  }

  public keyCodeIsGuide(keyCode: number): boolean {
    // Tizen guide button
    return keyCode === 458;
  }

  public keyCodeIsChannelList(keyCode: number): boolean {
    // Tizen channel list button
    return keyCode === 10073;
  }

  public keyCodeIsInfo(keyCode: number): boolean {
    // Tizen info button
    return keyCode === 457;
  }

  public getLastPageContentSection(): string {
    return this.lastPageContentSection;
  }

  public setLastScheduleElement(e: HTMLDivElement): void {
    this.lastScheduleElement = e;
  }

  public getLastScheduleElement(): HTMLDivElement {
    return this.lastScheduleElement;
  }

  private checkMenuSectionChange(newSection: NavigationSections): void {
    if (this.isMenuSection(newSection) !== this.isMenuSection(this.currentSection)) {
      if (this.isMenuSection(this.currentSection)) {
        // lost focus on menu
        this.isMenuFocusedEvent.emit(false);
      } else if (this.isMenuSection(newSection)) {
        // focus on menu
        this.isMenuFocusedEvent.emit(true);
      }
    }
  }

  private checkScrollTopChange(newSection: NavigationSections): void {
    if (this.isScrollTopSection(newSection) && !this.isScrollTopSection(this.currentSection)) {
      this.scrollTopEvent.emit();
    }
  }

  private isMenuSection(section: NavigationSections): boolean {
    return section === NavigationSections.MENU;
  }

  private isScrollTopSection(section: NavigationSections): boolean {
    return (
      section === NavigationSections.DETAIL_PAGE_BUTTONS ||
      section === NavigationSections.SEARCH_BAR ||
      section === NavigationSections.RECORDING_FILTERS ||
      section === NavigationSections.TOGGLE_ADULT
    );
  }
}
