import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { ProfileElement } from '@atv-core/api/aa/aa-api.model';
import { AaApiService } from '@atv-core/api/aa/aa-api.service';
import { ConfigService, ErrorTranslationKeys, SettingsKeys } from '@atv-bootstrap/services/config';
import { MessagesService } from '@atv-core/services/messages';
import { SessionService } from '@atv-core/services/session';
import { forkJoin } from 'rxjs';

import { CreateEditProfileModel } from './manage-profiles/manage-profiles.component';

export enum ProfilePopupModes {
  SWITCH,
  MANAGE_SELECT,
  MANAGE,
}

@Component({
  selector: 'app-profile-selection',
  templateUrl: './profile-selection.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSelectionComponent implements OnInit {
  public ProfilePopupModes = ProfilePopupModes;
  public popupMode: ProfilePopupModes;

  public showPopup = false;

  public createEditProfile: CreateEditProfileModel = undefined;

  public allowProfileSwitching = false;

  constructor(
    private sessionService: SessionService,
    private aaApi: AaApiService,
    private messagesService: MessagesService,
    private config: ConfigService,
    private cdr: ChangeDetectorRef
  ) {
    this.allowProfileSwitching = this.config.getSettingBoolean(
      SettingsKeys.allowProfileSwitching,
      false
    );
    this.sessionService.showProfileSelectionPopup.subscribe((mode: ProfilePopupModes) => {
      if (!this.allowProfileSwitching) {
        return;
      }

      this.showPopup = true;
      this.popupMode = mode;
      this.detectChanges();
    });
  }

  private detectChanges(): void {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {}

  public closePopup(): void {
    this.showPopup = false;
    this.popupMode = undefined;
    this.detectChanges();
  }

  public createProfile(): void {
    forkJoin([this.aaApi.getAvatars(), this.aaApi.getAllCatalogs()]).subscribe(
      (result) => {
        this.createEditProfile = new CreateEditProfileModel(result[0], result[1]);
        this.popupMode = ProfilePopupModes.MANAGE;
        this.detectChanges();
      },
      () => {
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_general)
        );
      }
    );
  }

  public editProfile(profile: ProfileElement): void {
    forkJoin([this.aaApi.getAvatars(), this.aaApi.getAllCatalogs()]).subscribe(
      (result) => {
        this.createEditProfile = new CreateEditProfileModel(result[0], result[1], profile);
        this.popupMode = ProfilePopupModes.MANAGE;
        this.detectChanges();
      },
      () => {
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_general)
        );
      }
    );
  }

  public manageProfiles(editMode): void {
    this.popupMode = editMode ? ProfilePopupModes.MANAGE_SELECT : ProfilePopupModes.SWITCH;
    this.detectChanges();
  }

  public backToSwitch(): void {
    this.popupMode = ProfilePopupModes.SWITCH;
    this.detectChanges();
  }
}
