import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorTranslationKeys, SettingsKeys } from '@atv-bootstrap/services/config/config.model';
import { SessionService } from '@atv-core/services/session/session.service';
import { LocalStorageKeys } from '@atv-core/utility/constants/localStorageKeys';
import { SessionStorageKeys } from '@atv-core/utility/constants/sessionStorageKeys';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { environment } from '@env/environment';
import moment from 'moment';
import { from, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';

import { LogErrorInfo, PageContext, PageUrlTypes } from '../log/log.model';
import { MessagesService } from '@atv-core/services/messages';
import { GrantTypes, TokenResponse } from '../oauth/oauth-api.model';
import { OauthApiService } from '../oauth/oauth-api.service';
import { ConfigService } from '@atv-bootstrap/services/config';
import { LogService } from './../log/log.service';
import { PlayerService } from '../player/player.service';
import { TokenCommunicationService } from '../../../home/token-communication.service';

export interface AuthResponse {
  token_type?;
  access_token?;
  state?;
  error?;
}

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {

  public showAuthPopup = new EventEmitter();
  public confirmLogoutPopup = new EventEmitter<() => void>();
  private tokenType: string;
  private accessToken: string;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private messagesService: MessagesService,
    private log: LogService,
    private config: ConfigService,
    private activatedRoute: ActivatedRoute,
    private oauthApiService: OauthApiService,
    private playerService: PlayerService,
    private tokenCommunicationService: TokenCommunicationService,
  ) {
    this.tokenCommunicationService.setLogoutAction(() => {
      this.logout();
    });

    this.tokenCommunicationService.setActiveTokenAction(token => {
      //  ignore message if auth is same as current auth
      if (this.getAuthToken() !== token.access_token || this.getTokenType()?.toLowerCase() !== (token.token_type as string).toLowerCase()) {
        this.setActiveOauthFromOtherTab(token);
      }
    });

    this.tokenCommunicationService.setTokenRequestAction(() => {
      // broadcast token if requested by other tab
      if (this.isAuthorized()) {
        this.tokenCommunicationService.broadcastActiveToken({ access_token: this.accessToken, token_type: this.tokenType });
      }
    });
  }

  private static saveRefreshToken(tokenResponse: TokenResponse): void {
    if (tokenResponse.refresh_token && tokenResponse.refresh_token_expires_in) {
      localStorage.setItem(LocalStorageKeys.oauth_refresh_token, tokenResponse.refresh_token);
      localStorage.setItem(
        LocalStorageKeys.oauth_refresh_token_expiration,
        moment().add(tokenResponse.refresh_token_expires_in, 'seconds').toJSON(),
      );
    }
  }

  private static checkRefreshToken(): string {
    const refreshToken = localStorage.getItem(LocalStorageKeys.oauth_refresh_token);
    if (!refreshToken) {
      return null;
    }

    const expiration = localStorage.getItem(LocalStorageKeys.oauth_refresh_token_expiration);
    if (expiration && moment(expiration).isBefore()) {
      console.log('refresh token has expired');
      localStorage.removeItem(LocalStorageKeys.oauth_refresh_token);
      localStorage.removeItem(LocalStorageKeys.oauth_refresh_token_expiration);
      return null;
    }

    return refreshToken;
  }

  private static clearRefreshToken(): void {
    localStorage.removeItem(LocalStorageKeys.oauth_refresh_token);
    localStorage.removeItem(LocalStorageKeys.oauth_refresh_token_expiration);
  }

  public getAuthToken(): string {
    return this.accessToken;
  }

  public getTokenType(): string {
    return this.tokenType;
  }

  public getAuthorization(): string {
    if (this.tokenType && this.accessToken) {
      return this.tokenType + ' ' + this.accessToken;
    } else {
      return undefined;
    }
  }

  public setUnauthorized(): void {
    this.tokenType = undefined;
    this.accessToken = undefined;
  }

  public isAuthorized(): boolean {
    return this.tokenType !== undefined && this.accessToken !== undefined;
  }

  public handleNormalResult(params: AuthResponse): void {
    if (
      params.token_type &&
      params.access_token &&
      params.state === sessionStorage.getItem(SessionStorageKeys.auth_state)
    ) {
      this.setActiveOauth(params).subscribe();
    } else {
      this.messagesService.showErrorMessage(
        this.config.getTranslation(ErrorTranslationKeys.error_authentication_failed),
      );
      sessionStorage.removeItem(SessionStorageKeys.auth_state);

      this.setUnauthorized();
      if (this.allowUnauthenticatedUse()) {
        this.sessionService.setAnonymousActive();
        this.router.navigate(['/main']);
      } else {
        this.showLogin();
      }
    }
  }

  public setActiveOauthFromOtherTab(params: AuthResponse): void {
    this.setActiveOauth(params).subscribe(() => {
      this.sessionService.reset(true);
    });
  }

  public setActiveOauth(params: AuthResponse): Observable<void> {
    this.setAuthorization(params.token_type, params.access_token);
    sessionStorage.removeItem(SessionStorageKeys.auth_state);
    return this.sessionService.setSession().pipe(map(sessionStatus => {
      this.log.authenticateState(true, sessionStatus.toString());

      const pageContext: PageContext = new PageContext({
        pageURL: PageUrlTypes.splash,
        pageLocale: this.config.getLocale(),
      });

      this.log.appOpen(pageContext);
      return;
    }), catchError(error => {
      this.setUnauthorized();

      if (this.allowUnauthenticatedUse()) {
        this.sessionService.setAnonymousActive();
      } else {
        this.showLogin();
      }
      this.log.authenticateState(
        false,
        error && error.status ? error.status.toString() : error.message.toString(),
        new LogErrorInfo(error),
      );

      return of(void 0);
    }));
  }

  showLogin(): void {
    if (this.activatedRoute.snapshot.queryParams.error !== 'login_failed') {
      this.messagesService.showErrorMessage(
        this.config.getTranslation(ErrorTranslationKeys.error_authentication_failed),
      );
    }

    this.gotoOauthLogin();
  }

  logoutClick(): void {
    // notify other tabs of logout
    this.tokenCommunicationService.broadcastLogout();
    this.logout();
  }

  public tryHeadlessLogin(): Observable<unknown> {
    return from(
      new Promise<void>((resolve, reject) => {
        let headlessIframe: HTMLIFrameElement = document.getElementById(
          'headlessIframe',
        ) as HTMLIFrameElement;

        if (!headlessIframe) {
          headlessIframe = document.createElement('iframe');
          headlessIframe.id = 'headlessIframe';
          headlessIframe.style.display = 'none';
          headlessIframe.setAttribute('sandbox', 'allow-same-origin allow-scripts');
          document.body.appendChild(headlessIframe);
        }
        headlessIframe.setAttribute('target', '_parent');

        const rejectTimeout = setTimeout(() => {
          headlessIframe.onload = () => {
          };
          reject('timeout');
        }, 20000);

        // after  seconds auto reject

        headlessIframe.onload = () => {
          const loc = headlessIframe.contentWindow.location;

          try {
            const authResponse = this.getParamsFromFragment(loc.hash.replace('#', ''));

            if (loc.origin === window.origin && loc.pathname.includes('oauth-headless')) {
              this.isHeadlessLoginSuccess(authResponse).subscribe((loginSuccess) => {
                clearTimeout(rejectTimeout);

                if (loginSuccess) {
                  resolve();
                } else {
                  reject(authResponse.error);
                }
              });
            }
          } catch (e) {
          }
        };

        this.log.login(
          new PageContext({
            pageURL: PageUrlTypes.login,
            pageLocale: this.config.getLocale(),
          }),
        );

        const state = SharedUtilityService.generateAuthState();
        sessionStorage.setItem(SessionStorageKeys.auth_state, state);
        // set timout prevent race condition for local storage
        setTimeout(() => {
          headlessIframe.src = `${environment.oauth_auth_uri}?client_id=${environment.oauth_client_id}&response_type=token&state=${state}&redirect_uri=${environment.oauth_headless_redirect_uri}&prompt=none`;
        }, 0);
      }),
    );
  }

  gotoOauthLogin(): void {
    this.log.login(
      new PageContext({
        pageURL: PageUrlTypes.login,
        pageLocale: this.config.getLocale(),
      }),
    );

    const temp = new HttpParams({ fromString: location.pathname.split('?')[1] });
    const params = {};
    temp.keys().forEach((key) => (params[key] = temp.get(key)));
    sessionStorage.setItem(SessionStorageKeys.login_succes_redirect_params, JSON.stringify(params));
    sessionStorage.setItem(SessionStorageKeys.login_succes_redirect_url, location.pathname);

    const state = SharedUtilityService.generateAuthState();

    sessionStorage.setItem(SessionStorageKeys.auth_state, state);
    // set timout prevent race condition for local storage
    setTimeout(() => {
      window.location.href = `${environment.oauth_auth_uri}?client_id=${environment.oauth_client_id}&response_type=token&state=${state}&redirect_uri=${environment.oauth_redirect_uri}&prompt=login`;
    }, 0);
  }

  public getParamsFromFragment(fragment: string): AuthResponse {
    const params: AuthResponse = {};
    if (!fragment) {
      return params;
    }
    const tmp = fragment.split('&');

    tmp.forEach((item) => {
      const keyValue = item.split('=');
      params[keyValue[0]] = keyValue[1];
    });
    return params;
  }

  logout(): void {
    AuthorizationService.clearRefreshToken();

    this.log.logout(
      new PageContext({
        pageURL: PageUrlTypes.login,
        pageLocale: this.config.getLocale(),
      }),
    );

    const state = SharedUtilityService.generateAuthState();

    if (this.allowUnauthenticatedUse()) {
      if (!SharedUtilityService.isSmartTv()) {
        let headlessIframe: HTMLIFrameElement = document.getElementById(
          'headlessIframe',
        ) as HTMLIFrameElement;

        if (!headlessIframe) {
          headlessIframe = document.createElement('iframe');
          headlessIframe.id = 'headlessIframe';
          headlessIframe.style.display = 'none';
          headlessIframe.setAttribute('sandbox', 'allow-same-origin allow-scripts');
          document.body.appendChild(headlessIframe);
        }
        headlessIframe.setAttribute('target', '_parent');
        headlessIframe.src = `${environment.oauth_auth_uri}?client_id=${environment.oauth_client_id}&response_type=token&state=${state}&redirect_uri=${environment.oauth_headless_redirect_uri}&prompt=login`;
      }

      this.playerService.closePlayerEvent.emit();
      this.setUnauthorized();
      this.sessionService.setAnonymousActive();
    } else {
      if (!SharedUtilityService.isSmartTv()) {
        sessionStorage.setItem(SessionStorageKeys.auth_state, state);

        setTimeout(() => {
          window.location.href = `${environment.oauth_auth_uri}?client_id=${environment.oauth_client_id}&response_type=token&state=${state}&redirect_uri=${environment.oauth_redirect_uri}&prompt=login`;
        }, 0);
      } else {
        this.setUnauthorized();
        this.router.navigate(['account/login']);
      }
    }
  }

  // used for tv web app login
  public doTokenLogin(username: string, password: string): Observable<boolean> {
    return this.oauthApiService
      .requestToken({
        client_id: environment.smartTvOauthClientId,
        client_secret: environment.smartTvOauthClientSecret,
        grant_type: GrantTypes.PASSWORD,
        username,
        password,
        authorization: btoa(
          `${environment.smartTvOauthClientId}:${environment.smartTvOauthClientSecret}`,
        ),
      })
      .pipe(
        map((result) => {
          this.setAuthorization(result.token_type, result.access_token);
          AuthorizationService.saveRefreshToken(result);

          this.sessionService.setSession().subscribe(
            () => {
            },
            (error: HttpErrorResponse) => {
              this.setUnauthorized();
              if (this.allowUnauthenticatedUse()) {
                this.sessionService.setAnonymousActive();
              } else {
                this.messagesService.showErrorMessage(
                  this.config.getTranslation(ErrorTranslationKeys.error_user),
                );
              }
              this.log.authenticateState(
                false,
                error && error.status ? error.status.toString() : undefined,
                new LogErrorInfo(error),
              );
              this.log.authenticateState(false, error.status.toString(), new LogErrorInfo(error));
            },
          );
          sessionStorage.removeItem(SessionStorageKeys.auth_state);
          return true;
        }),
        catchError(() => {
          this.messagesService.showErrorMessage(
            this.config.getTranslation(ErrorTranslationKeys.error_user),
          );
          return of(false);
        }),
      );
  }

  public tryRefreshTokenLogin(): Observable<unknown> {
    const refreshToken = AuthorizationService.checkRefreshToken();
    if (!refreshToken) {
      return of(false).pipe(tap(() => {
        if (this.allowUnauthenticatedUse()) {
          this.sessionService.setAnonymousActive();
        } else {
          this.router.navigate(['/account/login']);
        }
      }));
    }

    return this.oauthApiService
      .requestToken({
        client_id: environment.smartTvOauthClientId,
        client_secret: environment.smartTvOauthClientSecret,
        grant_type: GrantTypes.REFRESH_TOKEN,
        refresh_token: refreshToken,
        authorization: btoa(
          `${environment.smartTvOauthClientId}:${environment.smartTvOauthClientSecret}`,
        ),
      })
      .pipe(
        catchError((e) => {
          AuthorizationService.clearRefreshToken();
          throw e;
        }),
        switchMap((result) => {
          this.setAuthorization(result.token_type, result.access_token);
          AuthorizationService.saveRefreshToken(result);

          return this.sessionService.setSession();
        }), catchError(error => {
          this.log.authenticateState(false, error.status.toString(), new LogErrorInfo(error));
          throw error;
        }), tap(sessionStatus => {
          this.log.authenticateState(true, sessionStatus.toString());

          const pageContext: PageContext = new PageContext({
            pageURL: PageUrlTypes.splash,
            pageLocale: this.config.getLocale(),
          });

          this.log.appOpen(pageContext);
        }),
        finalize(() => {
          sessionStorage.removeItem(SessionStorageKeys.auth_state);

          if (!this.isAuthorized()) {
            if (this.allowUnauthenticatedUse()) {
              this.sessionService.setAnonymousActive();
            } else {
              this.router.navigate(['/account/login']);
            }
          }
        }),
      );
  }

  private allowUnauthenticatedUse(): boolean {
    return this.config.getSettingBoolean(SettingsKeys.allowUnauthenticatedUse, false);
  }

  private isHeadlessLoginSuccess(authResult: AuthResponse): Observable<boolean> {
    const headlessIframe = document.getElementById('headlessIframe') as HTMLIFrameElement;
    if (headlessIframe) {
      document.body.removeChild(headlessIframe);
    }

    if (
      authResult.token_type &&
      authResult.access_token &&
      authResult.state === sessionStorage.getItem(SessionStorageKeys.auth_state)
    ) {
      // let other tabs know refresh
      this.tokenCommunicationService.broadcastActiveToken(authResult);

      this.setAuthorization(authResult.token_type, authResult.access_token);
      return this.sessionService.setSession().pipe(
        map((sessionStatus) => {
          this.log.authenticateState(true, sessionStatus.toString());

          sessionStorage.removeItem(SessionStorageKeys.auth_state);
          return true;
        }),
        catchError((error: HttpErrorResponse) => {
          this.setUnauthorized();
          if (this.allowUnauthenticatedUse()) {
            this.sessionService.setAnonymousActive();
          } else {
            this.showLogin();
          }
          this.log.authenticateState(
            false,
            error && error.status ? error.status.toString() : undefined,
            new LogErrorInfo(error),
          );
          sessionStorage.removeItem(SessionStorageKeys.auth_state);
          return of(false);
        }),
      );
    } else {
      this.setUnauthorized();

      if (this.allowUnauthenticatedUse()) {
        this.sessionService.setAnonymousActive();
      } else {
        this.gotoOauthLogin();
      }

      sessionStorage.removeItem(SessionStorageKeys.auth_state);
      return of(false);
    }
  }

  private setAuthorization(tokenType: string, accessToken: string): void {
    this.tokenType = 'Bearer';
    this.accessToken = accessToken;

    this.tokenCommunicationService.broadcastActiveToken({ access_token: accessToken, token_type: tokenType });
  }
}
