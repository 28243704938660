import { FavoriteContentTypes, FavoriteElement, ScheduleTime } from '@atv-core/api/history';
import { ChannelModel } from '@atv-core/services/cache/channel';

export enum FavoriteQueryTypes {
  FUTURE = 'future',
  PAST = 'past'
}

export class FavoriteModel implements FavoriteElement {
  id?: string;
  type?: FavoriteContentTypes;
  adult?: boolean;
  schedules?: ScheduleTime[];
  lastUpdate: string;

  // added functionality
  channel: ChannelModel;

  constructor(favorite: FavoriteElement) {
    this.id = favorite.id;
    this.type = favorite.type;
    this.adult = favorite.adult;
    this.schedules = favorite.schedules;
    this.lastUpdate = favorite.lastUpdate;
  }

}
