import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DiscoveryService } from '@atv-bootstrap/services/discovery';
import { SessionService } from '@atv-core/services/session';
import { ExtraData, FocusType } from '@atv-core/utility/constants/shared';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BundleLocalizedResponse, VodCategory, VodDetail } from '.';
import { Ribbon2Container } from '../cms';
import { AdultMode } from '@atv-core/services/adult';

@Injectable({ providedIn: 'root' })
export class VodApiService {
  constructor(
    private disc: DiscoveryService,
    private sessionService: SessionService,
    private http: HttpClient
  ) {}

  public getVodDetail(vodId: string): Observable<VodDetail> {
    return this.http.get<VodDetail>(`${this.disc.location}/vod/v1/vod/${encodeURIComponent(vodId)}`);
  }

  public getBundleDetail(bundleId: string): Observable<BundleLocalizedResponse> {
    const queryParams = new HttpParams().append('catalogId', this.sessionService.getCatalogId());

    return this.http.get<BundleLocalizedResponse>(
      `${this.disc.location}/vod/v1/bundle/${encodeURIComponent(bundleId)}`,
      { params: queryParams }
    );
  }

  public getCategories(): Observable<VodCategory[]> {
    let queryParams = {};
    const catalog = this.sessionService.getCatalogId();
    if (catalog) {
      queryParams = new HttpParams().append('catalogId', catalog);
    }
    return this.http
      .get<VodCategory[]>(`${this.disc.location}/vod/v1/category`, {
        params: queryParams,
      })
      .pipe(
        map((result) => {
          return result.filter((item) => {
            return this.filterCounts(item);
          });
        })
      );
  }

  private filterCounts(vodCat: VodCategory): boolean {
    if (vodCat.subcategories && vodCat.subcategories.length > 0) {
      vodCat.subcategories = vodCat.subcategories.filter((item) => {
        return this.filterCounts(item);
      });
    }

    return !!vodCat.count;
  }

  public getProductionYears(tags: string[], adultMode: AdultMode): Observable<number[]> {
    let queryParams = new HttpParams()
      .append('adult', adultMode)
      .append('catalogId', this.sessionService.getCatalogId());
    tags.forEach((tag) => (queryParams = queryParams.append('tag', tag)));

    return this.http.get<number[]>(`${this.disc.location}/vod/v1/vod/productionyears`, {
      params: queryParams,
    });
  }

  public getContent(
    contentUrl: string,
    page: number = 0,
    adultMode: AdultMode,
    focusType: FocusType
  ): Observable<Ribbon2Container> {
    contentUrl = this.expandUrl(contentUrl, page, adultMode, focusType);
    if (contentUrl) {
      return this.http.get<Ribbon2Container>(contentUrl);
    } else {
      return of(undefined);
    }
  }

  public getCmsEpisodes(
    id: string,
    catalogId: string,
    adultMode: AdultMode,
    sortBy: 'TITLE' | 'CREATED' | 'EPISODE' = 'TITLE',
    sortDir: 'ASC' | 'DESC' = 'ASC',
    page = 0
  ): Observable<Ribbon2Container> {
    const queryParams = new HttpParams()
      .append('page', `${page}`)
      .append('catalogId', catalogId)
      .append('platform', environment.platform)
      .append('adult', adultMode)
      .append('id', id)
      .append('sortBy', sortBy)
      .append('sortDir', sortDir)
      .append('extraData', SharedUtilityService.isSmartTv() ? 'MINI' : 'NONE');

    return this.http.get<Ribbon2Container>(`${this.disc.location}/vod/v2/cms/episode`, {
      params: queryParams,
    });
  }

  public getCmsVods(
    catalogId: string,
    adultMode: AdultMode,
    tags: string[],
    page = 0,
    productionYear?: number,
    minOperatorScore?: number,
    maxOperatorScore?: number
  ): Observable<Ribbon2Container> {
    let queryParams = new HttpParams()
      .append('page', `${page}`)
      .append('catalogId', catalogId)
      .append('platform', environment.platform)
      .append('adult', adultMode)
      .append('extraData', SharedUtilityService.isSmartTv() ? 'MINI' : 'NONE');;
    tags.forEach((tag) => (queryParams = queryParams.append('tag', tag)));
    if (productionYear) {
      queryParams = queryParams.append('productionYear', productionYear);
    }
    if (minOperatorScore) {
      queryParams = queryParams.append('minOperatorScore', minOperatorScore);
    }
    if (maxOperatorScore) {
      queryParams = queryParams.append('maxOperatorScore', maxOperatorScore);
    }

    return this.http.get<Ribbon2Container>(`${this.disc.location}/vod/v2/cms/vod`, {
      params: queryParams,
    });
  }

  private expandUrl(url: string, page, adultMode: AdultMode, focusType: FocusType): string {
    const dp = new DatePipe('en-US');

    if (url.includes('${now:M1}')) {
      const date = dp.transform(new Date(), 'yyyy-MM-ddTHH:mm\'Z\'', 'UTC');
      url = url.replace(/\${now:M1}/g, date);
    }
    if (url.includes('${now:M5}')) {
      const trunc = 1000 * 60 * 5;
      const truncedDate = new Date(Math.floor(new Date().getTime() / trunc) * trunc);
      const date = dp.transform(truncedDate, 'yyyy-MM-ddTHH:mm\'Z\'', 'UTC');
      url = url.replace(/\${now:M5}/g, date);
    }
    if (url.includes('${now:M10}')) {
      const trunc = 1000 * 60 * 10;
      const truncedDate = new Date(Math.floor(new Date().getTime() / trunc) * trunc);
      const date = dp.transform(truncedDate, 'yyyy-MM-ddTHH:mm\'Z\'', 'UTC');
      url = url.replace(/\${now:M10}/g, date);
    }
    if (url.includes('${adult}')) {
      url = url.replace(/\${adult}/g, adultMode);
    }
    if (url.includes('${page}')) {
      url = url.replace(/\${page}/g, page.toString());
    } else if (page !== 0) {
      return undefined;
    }
    if (url.includes('${loggedIn}')) {
      const loggedIn = this.sessionService.anonymousProfileIsActive() ? 'false' : 'true';
      url = url.replace(/\${loggedIn}/g, loggedIn);
    }
    if (url.includes('${entitlementId}')) {
      const id = this.sessionService.getEntitlementId() || '';
      url = url.replace(/\${entitlementId}/g, id);
    }
    if (url.includes('${catalogId}')) {
      const id = this.sessionService.getCatalogId() || '';
      url = url.replace(/\${catalogId}/g, id);
    }
    if (url.includes('${profileId}')) {
      const id = this.sessionService.getActiveProfileId() || '';
      url = url.replace(/\${profileId}/g, id);
    }
    if (url.includes('${deviceType}')) {
      url = url.replace(/\${deviceType}/g, environment.device || '');
    }
    if (url.includes('${platformType}')) {
      url = url.replace(/\${platformType}/g, environment.platform || '');
    }
    if (url.includes('${extraData}')) {
      url = url.replace(
        /\${extraData}/g,
        SharedUtilityService.isSmartTv() ? ExtraData.MINI : ExtraData.NONE
      );
    }
    if (url.includes('${actionFilter}')) {
      // see confluence for explenation 127
      // https://confluence.in.androme.com/pages/viewpage.action?spaceKey=NEBULABACKEND&title=New+pages
      url = url.replace(/\${actionFilter}/g, environment.actionFilter);
    }
    if (url.includes('${focusType}')) {
      url = url.replace(/\${focusType}/g, focusType);
    }
    return url;
  }
}
