<div *ngIf="!isSmartTv" class="popup-wrapper" [class.hidden]="!showAdultPopup">
  <div class="popup">
    <div class="popup-header">
      <div class="popup-header-title">{{ adultPopupTitle }}</div>
      <div class="popup-close" (click)="closePopup()"></div>
    </div>
    <div class="popup-content">
      <div class="adult-popup-options">
        <div class="adult-popup-options-text">{{ adultOptionsText }}</div>
        <select [(ngModel)]="selectedNumberOfMinutesAdultMode">
          <option *ngFor="let number of adultOptions" [ngValue]="number.value">{{
            number.text
            }}</option>
        </select>
      </div>

      <div class="adult-popup-pincode-input">
        <form>
          <input
            #pincodeInput
            (keydown.enter)="tryPincode(pincodeInput.value); pincodeInput.value = ''"
            id="pwd"
            name="pwd"
            placeholder="{{ pincodePlaceholder }}"
            type="password"
          />
        </form>
        <button
          class="adult-popup-pincode-input-confirm"
          (click)="tryPincode(pincodeInput.value); pincodeInput.value = ''"
        >
          {{ confirmText }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="overlay" *ngIf="isSmartTv && showAdultPopup">
  <div class="overlay-dialog button-dialog">
    <div class="overlay-dialog-title" style="margin-bottom: 10px;">{{ adultPopupTitle }}</div>
    <div class="subscription-setting-field">
      <label>{{ adultOptionsText }}</label>
      <div class="chip-container">
        <div
          class="chip"
          (keypress)="inputKeyEvent($event)"
          *ngFor="let number of adultOptions"
          [class.selected]="selectedNumberOfMinutesAdultMode === number.value"
          (focus)="selectedNumberOfMinutesAdultMode = number.value"
        >
          {{ number.text }}
        </div>
      </div>
    </div>
    <input
      #pincodeInput
      type="password"
      id="pwd"
      name="pwd"
      autocomplete="off"
      (keypress)="pinInputKeyEvent($event)"
      placeholder="{{ pincodePlaceholder }}"
    />
  </div>
</div>
