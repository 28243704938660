import { EventEmitter, Injectable } from '@angular/core';
import { HistoryApiService } from '@atv-core/api/history/history-api.service';
import { SessionService } from '@atv-core/services/session/session.service';
import { DeviceSettings } from '@atv-core/utility/constants/shared';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthorizationService } from './../authorization/authorization.service';

export enum AdultMode {
  any = 'any',
  true = 'true',
  false = 'false',
}

export interface AdultPopupCallbacks {
  successCallback: () => void;
  errorCallback: () => void;
}

@Injectable({ providedIn: 'root' })
export class AdultService {
  private adultModeActive = false;

  public openAdultPopupEvent: EventEmitter<AdultPopupCallbacks> = new EventEmitter();
  private adultPopupSubject = new BehaviorSubject<boolean>(false);

  public savedNumberOfAdultMinutes: number;

  private timer;

  public deviceId: string; // used in variable to prevent circular dependency in session

  constructor(
    private historyApi: HistoryApiService,
    private auth: AuthorizationService,
    private sessionService: SessionService,
  ) {
    this.sessionService.sessionResetEvent.subscribe(() => this.stopAdultMode());
  }

  public showAdult(allowedAdultMode: AdultMode): boolean {
    return (
      environment.allowAdult &&
      (allowedAdultMode === AdultMode.any || allowedAdultMode === AdultMode.true) &&
      this.adultModeActive
    );
  }

  public getAdultMode(allowedAdultMode: AdultMode): AdultMode {
    if (
      environment.allowAdult &&
      (allowedAdultMode === AdultMode.any || allowedAdultMode === AdultMode.true) &&
      this.adultModeActive
    ) {
      return allowedAdultMode;
    } else {
      return AdultMode.false;
    }
  }

  public setAdultModeForMinutes(minutes: number): void {
    this.adultModeActive = true;

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }

    this.timer = setTimeout(() => {
      this.adultModeActive = false;
    }, (minutes || 0.25) * 60 * 1000);
  }

  public stopAdultMode(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }

    this.adultModeActive = false;
  }

  public checkAdultMode(callbacks: AdultPopupCallbacks): void {
    if (!this.auth.isAuthorized()) {
      this.auth.showAuthPopup.emit();
    } else if (!environment.allowAdult) {
      callbacks.errorCallback();
    } else if (!this.adultModeActive) {
      this.openAdultPopupEvent.emit(callbacks);
    } else if (callbacks?.successCallback) {
      callbacks.successCallback();
    }
  }

  public getNumberOfMinutesAdultMode(reload: boolean = false): Observable<number> {
    if (reload) {
      this.savedNumberOfAdultMinutes = undefined;
    }
    if (this.savedNumberOfAdultMinutes !== undefined) {
      return of(this.savedNumberOfAdultMinutes);
    } else {
      return this.historyApi
        .getSettingForDevice(this.sessionService.getDeviceId(), DeviceSettings.adult_mode)
        .pipe(
          map((result) => {
            this.savedNumberOfAdultMinutes = parseInt(result.value, 10);
            return this.savedNumberOfAdultMinutes;
          }),
          catchError((e) => {
            this.savedNumberOfAdultMinutes = 5;
            return of(this.savedNumberOfAdultMinutes);
          }),
        );
    }
  }

  public setAdultPopupStatus(status: boolean): void {
    if (status !== this.adultPopupSubject.value) {
      this.adultPopupSubject.next(status);
    }
  }

  public onAdultPopupStatusChange(callback: (status: boolean) => void): Subscription {
    return this.adultPopupSubject.subscribe(callback);
  }
}
