import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AtvSharedModule } from '@atv-shared/atv-shared.module';

import { BootstrapModule } from './bootstrap/bootstrap.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AtvCoreModule } from '@atv-core/atv-core.module';
import { MainModule } from './main/main.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    BootstrapModule,
    AppRoutingModule,
    AtvCoreModule,
    MainModule,
    AtvSharedModule,
  ],
  bootstrap: [AppComponent],
  providers: [{
    provide: 'virtual-scroller-default-options',
    useValue: {
      checkResizeInterval: 0,
      modifyOverflowStyleOfParentScroll: true,
      resizeBypassRefreshThreshold: 5,
      scrollAnimationTime: 750,
      scrollDebounceTime: 0,
      scrollThrottlingTime: 0,
      stripedTable: false,
    },
  }],
})
export class AppModule {
  constructor() {
  }
}
