<div class="menu">
  <div class="side-bar">
    <div class="user-profile">
      <div class="user-profile-icon-border">
        <div
          class="user-profile-icon"
          [ngStyle]="{ 'background-image': 'url(' + getAvatar() + ')' }"
        ></div>
      </div>
      <div class="user-profile-greeting">{{ getGreeting() }}</div>
      <div class="user-profile-options-wrapper">
        <div class="user-profile-options">
          <div
            class="user-profile-option"
            (click)="switchCatalog()"
            *ngIf="isAnonymousProfileActive() && anonymousHasMultipleCatalogs()"
          >
            {{ switchCatalogText }}
          </div>
          <div
            class="user-profile-option"
            (click)="switchProfile()"
            *ngIf="!isAnonymousProfileActive() && isProfileSwitchingAllowed()"
          >
            {{ switchProfileText }}
          </div>
          <div
            class="user-profile-option"
            (click)="manageProfiles()"
            *ngIf="!isAnonymousProfileActive() && isProfileSwitchingAllowed()"
          >
            {{ manageProfileText }}
          </div>
          <div class="user-profile-option" (click)="login()" *ngIf="!isLoggedIn()">
            {{ loginText }}
          </div>
          <div class="user-profile-option" (click)="logout()" *ngIf="isLoggedIn()">
            {{ logoutText }}
          </div>
        </div>
      </div>
    </div>
    <div class="side-nav">
      <div class="menu-top-level">
        <div
          *ngFor="let item of getTopLevelMenu()"
          class="menu-top-level-item"
          routerLinkActive="active"
          routerLink="{{ item.route }}"
        >
          <img class="active-icon" src="{{ item.icon_selected }}" />
          <img class="normal-icon" src="{{ item.icon }}" />
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
    <div class="build-info-wrapper">
      <p class="build-info">{{ getNightly() }}</p>
      <p class="build-info">{{ getBuildTime() }}</p>
      <p class="build-info">{{ getBuildVersion() }}</p>
    </div>
  </div>
</div>

<div class="mobile-menu" (click)="clickedOnMenu()">
  <div class="mobile-menu-header" [class.mobile-menu-open]="menuOpen">
    <div
      class="mobile-menu-open-close"
      [class.mobile-menu-open]="menuOpen"
      (click)="menuOpen = !menuOpen"
    ></div>
    <div class="mobile-menu-header-title">{{ getMobileBreadCrumb() }}</div>
  </div>
  <div class="mobile-menu-side-wrapper">
    <div class="mobile-menu-side" [class.mobile-menu-open]="menuOpen">
      <div class="user-profile">
        <div class="user-profile-icon-border">
          <div
            class="user-profile-icon"
            [ngStyle]="{ 'background-image': 'url(' + getAvatar() + ')' }"
          ></div>
        </div>
        <div class="user-profile-greeting">{{ getGreeting() }}</div>
        <div class="user-profile-options-wrapper">
          <div class="user-profile-options">
            <div
              class="user-profile-option"
              (click)="switchCatalog()"
              *ngIf="isAnonymousProfileActive() && anonymousHasMultipleCatalogs()"
            >
              {{ switchCatalogText }}
            </div>
            <div
              class="user-profile-option"
              (click)="switchProfile()"
              *ngIf="!isAnonymousProfileActive() && isProfileSwitchingAllowed()"
            >
              {{ switchProfileText }}
            </div>
            <div
              class="user-profile-option"
              (click)="manageProfiles()"
              *ngIf="!isAnonymousProfileActive() && isProfileSwitchingAllowed()"
            >
              {{ manageProfileText }}
            </div>
            <div class="user-profile-option" (click)="login()" *ngIf="!isLoggedIn()">
              {{ loginText }}
            </div>
            <div class="user-profile-option" (click)="logout()" *ngIf="isLoggedIn()">
              {{ logoutText }}
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-menu-items">
        <div class="mobile-menu-top-level">
          <div
            *ngFor="let item of getTopLevelMenu()"
            class="mobile-menu-top-level-item mobile-menu-item"
            routerLinkActive="active"
            routerLink="{{ item.route }}"
          >
            <img class="active-icon" src="{{ item.icon_selected }}" />
            <img class="normal-icon" src="{{ item.icon }}" />
          </div>
        </div>
        <div
          class="mobile-menu-sub-level"
          *ngFor="let subLevel of getSubLevelMenu()"
          [class.hidden]="!isActiveRoute(subLevel.forTopLevel)"
        >
          <div>
            <div class="mobile-menu-top-level-title">
              {{ subLevel.forTopLevelTitle }}
            </div>
            <div
              *ngFor="let item of subLevel.subLevelMenuItems"
              class="mobile-menu-sub-level-item mobile-menu-item"
              routerLinkActive="active"
              routerLink="{{ item.route }}"
            >
              <span>{{ item.text }}</span>
            </div>
            <div
              *ngIf="subLevel.forTopLevel === TopLevelMenuTypes.mytv && isLoggedIn()"
              class="mobile-menu-sub-level-item mobile-menu-item"
              (click)="logout()"
            >
              <span>{{ logoutText }}</span>
            </div>
            <div
              *ngIf="subLevel.forTopLevel === TopLevelMenuTypes.mytv && !isLoggedIn()"
              class="mobile-menu-sub-level-item mobile-menu-item"
              (click)="login()"
            >
              <a>{{ loginText }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
