import { CustomPage } from '@atv-core/api/cms';
import { ReloadablePageComponent } from '@atv-pages/reloadable-page.component';

export abstract class JumpablePage extends ReloadablePageComponent {
  protected page: CustomPage;
  protected pageId: string;
  public isOnCustomPage = false;

  public setPage(page: CustomPage, pageId: string): void {
    this.page = page;
    this.pageId = pageId;
    this.isOnCustomPage = true;
  }
}
