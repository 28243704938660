import { Component } from '@angular/core';
import { SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styles: [],
})
export class MainComponent {
  public isSmartTv = SharedUtilityService.isSmartTv();
  public isMenuFocused = false;

  constructor(private spatialNavigationService: SpatialNavigationService) {
    if (this.isSmartTv) {
      this.spatialNavigationService.isMenuFocusedEvent.subscribe((status) => {
        this.isMenuFocused = status;
      });
    }
  }
}
