<div id="container" [class.menu-open]="isMenuFocused">
  <div id="wrapper">
    <div id="profile-info">
      <img [src]="avatar" />
      <h2 id="greeting">{{ greeting }}</h2>
      <div id="profile-menu">
        <app-menu-item-smarttv
          *ngIf="isAnonymousProfileActive() && anonymousHasMultipleCatalogs()"
          [text]="switchCatalogText"
          icon="assets/theme/svg/switch_catalog.svg"
          [isMenuOpen]="isMenuFocused"
          (entered)="switchCatalog()"
        ></app-menu-item-smarttv>
        <app-menu-item-smarttv
          *ngIf="!isAnonymousProfileActive() && isProfileSwitchingAllowed()"
          [text]="switchProfileText"
          icon="assets/theme/svg/switch_profile.svg"
          [isMenuOpen]="isMenuFocused"
          (entered)="switchProfile()"
        ></app-menu-item-smarttv>
        <app-menu-item-smarttv
          *ngIf="!isLoggedIn()"
          [text]="loginText"
          icon="assets/theme/svg/login_smarttv.svg"
          [isMenuOpen]="isMenuFocused"
          (entered)="login()"
        ></app-menu-item-smarttv>
        <app-menu-item-smarttv
          *ngIf="isLoggedIn()"
          [text]="logoutText"
          icon="assets/theme/svg/logout_smarttv.svg"
          [isMenuOpen]="isMenuFocused"
          (entered)="logout()"
        ></app-menu-item-smarttv>
      </div>
    </div>
    <hr />
    <div id="menu" #menu [class.submenu-open]="isSubMenuOpen">
      <div id="menu-wrapper">
        <div id="main-menu">
          <div
            *ngFor="let item of getTopLevelMenu()"
            #rla="routerLinkActive"
            routerLinkActive
            routerLink="{{ item.route }}"
          >
            <app-menu-item-smarttv
              [text]="item.text"
              [icon]="item.icon"
              [iconSelected]="item.icon_selected"
              [isActive]="rla.isActive"
              [route]="item.route"
              (entered)="openSubMenu(item.submenu)"
              [isMenuOpen]="isMenuFocused"
            ></app-menu-item-smarttv>
          </div>
        </div>
        <div id="sub-menu">
          <div
            *ngFor="let item of currentSubLevelMenu"
            #rla="routerLinkActive"
            routerLinkActive
            routerLink="{{ item.route }}"
          >
            <app-menu-item-smarttv
              [text]="item.text"
              [icon]="item.icon"
              [iconSelected]="item.icon_selected"
              [isActive]="rla.isActive"
              [route]="item.route"
              [isMenuOpen]="isMenuFocused"
            ></app-menu-item-smarttv>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
