<div *ngIf="!isSmartTv && showLoginConfirmPopup" class="popup-wrapper">
  <div class="popup">
    <div class="popup-header">
      <div class="popup-header-title">{{ popupTitle }}</div>
      <div class="popup-close" (click)="closePopup()"></div>
    </div>
    <div class="popup-content">
      <div class="auth-popup-content">
        <button class="auth-popup-button" (click)="logout()">
          {{ confirmButtonTitle }}
        </button>
        <button class="auth-popup-button" (click)="closePopup()">
          {{ cancelButtonTitle }}
        </button>
      </div>
    </div>
  </div>
</div>

<div #overlay *ngIf="isSmartTv && showLoginConfirmPopup" class="overlay">
  <div class="overlay-dialog button-dialog">
    {{ popupTitle }}

    <div class="dialog-buttons">
      <div (click)="closePopup()" (smartTvKeyDownEnter)="closePopup()" class="cancel button-title">
        {{ cancelButtonTitle }}
      </div>
      <div (click)="logout()" (smartTvKeyDownEnter)="logout()" class="button-title">{{ confirmButtonTitle }}</div>
    </div>
  </div>
</div>
