<div class="popup-wrapper" *ngIf="!isSmartTv && showAuthPopup">
  <div class="popup">
    <div class="popup-header">
      <div class="popup-header-title">{{ popupTitle }}</div>
      <div class="popup-close" (click)="closePopup()"></div>
    </div>
    <div class="popup-content">
      <div class="auth-popup-content">
        <button class="auth-popup-button" (click)="goToOauth()">
          {{ signInButtonTitle }}
        </button>
        <button class="auth-popup-button" (click)="closePopup()">
          {{ cancelButtonTitle }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="overlay" *ngIf="isSmartTv && showAuthPopup">
  <div class="overlay-dialog button-dialog">
    {{ popupTitle }}

    <div class="dialog-buttons">
      <div class="cancel button-title" (smartTvKeyDownEnter)="closePopup()">{{ cancelButtonTitle }}</div>
      <div class="button-title" (smartTvKeyDownEnter)="goToOauth()">{{ signInButtonTitle }}</div>
    </div>
  </div>
</div>
