import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { CardOrientation, CardSize, CmsApiService, CmsGrid, Properties, Ribbon2Asset } from '@atv-core/api/cms';
import { ChannelCardModes } from '@atv-core/api/epg';
import { VodApiService } from '@atv-core/api/vod';
import { AdultMode, AdultService } from '@atv-core/services/adult/adult.service';
import { BookmarkCacheService } from '@atv-core/services/cache/bookmark';
import { FavoriteCacheService } from '@atv-core/services/cache/favorite';
import { finalize } from 'rxjs/operators';


class GridVisualModel {
  title = '';
  contentMessage = '';
}

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input()
  noResultText = '';

  @Input() grid: CmsGrid;
  @Input() pageProperties: Properties[];

  @Input() allowedAdultMode: AdultMode;
  @Input() isOnSearchPage = false;

  @Output() visibilityChanged = new EventEmitter();

  @ViewChild('gridContentEl') gridContentElRef: ElementRef<HTMLDivElement>;
  @ViewChild('placeholder') placeholder: ElementRef<HTMLDivElement>;

  gridVisualModel = new GridVisualModel();

  public CardSize = CardSize;
  CardOrientation = CardOrientation;

  gridPage = 0;
  gridContents = [];
  allDataRetrieved = false;
  gettingResults = false;

  hideGrid = false;
  showContentMessage = false;
  public cardOrientation: CardOrientation;

  contentRequests = [];

  hasResults = true;

  public gridChannelCardMode: ChannelCardModes = ChannelCardModes.NONE;
  private intersectionObserver: IntersectionObserver;

  constructor(
    private cmsApi: CmsApiService,
    private vodApi: VodApiService,
    private favoriteCache: FavoriteCacheService,
    private adultService: AdultService,
    private bookmarkCache: BookmarkCacheService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.gridVisualModel.title = this.grid?.title;
    this.detectChanges();
    this.intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0]?.isIntersecting) {
        if (!this.gettingResults && !this.allDataRetrieved) {
          this.getGridContent();
        }
      }
    });
    this.intersectionObserver.observe(this.placeholder.nativeElement);
  }

  ngOnChanges(): void {
    this.resetGrid();
    this.grid.contentProvider.resetPaging();
    this.detectChanges();
    this.getGridContent();
  }

  public getPlaceholderItemStyling(): string {
    return this.grid.orientation === CardOrientation.LANDSCAPE
      ? 'landscape-card-placeholder'
      : 'portrait-card-placeholder';
  }

  checkHasResults(): void {
    if (this.gettingResults) {
      this.hasResults = true;
      return;
    }
    if (!this.gridContents || this.gridContents.length === 0) {
      this.hasResults = false;
      return;
    }

    this.hasResults = true;
  }

  private resetGrid(): void {
    this.gridPage = 0;
    this.contentRequests.forEach((req) => req.unsubscribe());
    this.contentRequests = [];
    this.gridContents = [];
    this.allDataRetrieved = false;
    this.gettingResults = false;

    this.hideGrid = false;
    this.visibilityChanged.emit();
    this.showContentMessage = false;
  }

  private detectChanges(): void {
    this.checkHasResults();
    // prevent change detection from firing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  private getGridContent(): void {
    if (!this.grid) {
      this.hideGrid = true;
      this.visibilityChanged.emit();
      this.detectChanges();
      return;
    }

    if (this.allDataRetrieved) {
      this.detectChanges();
      return;
    }

    // TODO page/ribbon properties
    if (this.grid.contentProvider && this.grid.contentProvider.hasNext()) {
      this.gettingResults = true;
      const req = this.grid.contentProvider.getItems().pipe(finalize(() => {
        if (this.gridContents.length === 0) {
          // show backup content if no results at all
          this.gridBackupContent();
        }
      })).subscribe(items => {
        this.gettingResults = false;
        return this.handleItems(items);
      });
      this.contentRequests.push(req);
    }
    else if (!this.grid.contentProvider) {
      // TODO remove this, should always use the contentProvider
      this.gridBackupContent();
    }
  }

  private handleItems(items?: Ribbon2Asset[]): void {
    if (!items || items.length === 0) {
      this.allDataRetrieved = true;
      return;
    }

    if (!this.cardOrientation) {
      this.cardOrientation = this.grid.contentProvider.getOrientation();
    }

    this.allDataRetrieved = !this.grid.contentProvider.hasNext();

    if (!this.gridContents) {
      this.gridContents = [];
    }
    this.gridContents = this.gridContents.concat(items);
    this.visibilityChanged.emit();
    this.detectChanges();
  }

  private gridBackupContent(): void {
    if (!this.grid) {
      this.hideGrid = true;
      this.visibilityChanged.emit();
      this.detectChanges();
      return;
    }

    if (this.grid.contents && this.grid.contents.length !== 0) {
      this.gridContents = this.grid.contents;
      this.allDataRetrieved = true;
    } else if (this.grid.contentMessage && this.grid.contentMessage !== '') {
      this.gridVisualModel.contentMessage = this.grid.contentMessage;
      this.showContentMessage = true;
      this.allDataRetrieved = true;
    } else {
      this.hideGrid = true;
      this.visibilityChanged.emit();
      this.allDataRetrieved = true;
    }
    this.detectChanges();
  }

  ngOnDestroy(): void {
    this.intersectionObserver?.disconnect();
  }
}
