import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService, SettingsKeys } from '@atv-bootstrap/services/config';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { PlatformType } from '@atv-core/utility/constants/shared';
import { environment } from '@env/environment';

export interface SearchAction {
  term: string;
  fromCompletion: boolean;
}

export interface SearchCompletion {
  fullValue: string;
  displayValue: string;
}

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent implements AfterViewInit, OnDestroy {
  public completions: SearchCompletion[];
  public isSmartTv = SharedUtilityService.isSmartTv();

  @ViewChild('searchBarInput')
  private searchBarInput: ElementRef<HTMLInputElement>;

  @Output() search = new EventEmitter<SearchAction>();
  @Output() cancelSearch = new EventEmitter<void>();
  @Output() clearSearchResults = new EventEmitter<void>();

  public minSearchChars: number;
  private searchKeyTimeout: number;
  private maxSuggestionLength: number;
  private searchFieldSubscription?: Subscription;
  private searchFieldNavigationSubscription?: Subscription;

  private inputBlurSubscription?: Subscription;

  constructor(
    private config: ConfigService,
    private activatedRoute: ActivatedRoute,
    private spatialNavigationService: SpatialNavigationService,
  ) {
  }

  ngAfterViewInit(): void {
    if (!SharedUtilityService.isSmartTv()) {
      this.searchBarInput.nativeElement.focus();
    }

    const initialTerm = this.getInitialTerm();
    if (initialTerm) {
      this.searchBarInput.nativeElement.value = initialTerm;
    }

    this.minSearchChars = this.config.getSettingNumber(SettingsKeys.searchMinChars, 3);
    this.searchKeyTimeout = this.config.getSettingNumber(SettingsKeys.searchKeyTimeout, 500);
    this.maxSuggestionLength = this.config.getSettingNumber(SettingsKeys.searchMaxSuggestionLength, 20);

    this.searchFieldSubscription = fromEvent<KeyboardEvent>(
      this.searchBarInput.nativeElement,
      environment.platform === PlatformType.SMARTTV_WEBOS ? 'input' : 'keyup',
    )
      .pipe(
        tap(() => {
          this.cancelSearch.emit();
        }),
        debounceTime(this.searchKeyTimeout),
        map(() => this.searchBarInput.nativeElement.value),
        distinctUntilChanged(),
        filter((searchValue) => {
          if (searchValue.length >= this.minSearchChars) {
            return true;
          } else {
            this.completions = [];
            this.clearSearchResults.emit();
            return false;
          }
        }),
      )
      .subscribe((searchValue) => {
        this.search.emit({ term: searchValue, fromCompletion: false });
      });
  }

  private getInitialTerm(): string {
    const initialTerm = this.activatedRoute.snapshot.queryParams.q;

    return initialTerm ? decodeURI(initialTerm) : undefined;
  }

  public completionSelected(completion: string): void {
    this.searchBarInput.nativeElement.value = completion;
    this.search.emit({ term: completion, fromCompletion: true });
  }

  public checkSearch(): void {
    const value = this.searchBarInput.nativeElement.value;
    if (value.length >= this.minSearchChars) {
      this.search.emit({ term: value, fromCompletion: false });
    }
  }

  public setCompletions(completions?: string[]): void {
    this.completions = completions?.map(data => ({
      fullValue: data,
      displayValue: data.length > this.maxSuggestionLength ? data.slice(0, this.maxSuggestionLength).concat('...') : data,
    }));
  }

  public onFocus(): void {
    if (SharedUtilityService.isSmartTv()) {
      this.spatialNavigationService.pause();

      this.inputBlurSubscription = fromEvent<KeyboardEvent>(this.searchBarInput.nativeElement, 'keyup')
        .pipe(filter(e => this.spatialNavigationService.keyCodeIsCancel(e.keyCode)
                          || this.spatialNavigationService.keyCodeIsEnter(e.keyCode)))
        .subscribe(() => {
          this.onBlur();
        });
    }
  }

  public onBlur(): void {
    if (SharedUtilityService.isSmartTv()) {
      this.spatialNavigationService.resume();

      this.inputBlurSubscription?.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.searchFieldSubscription?.unsubscribe();
    this.searchFieldNavigationSubscription?.unsubscribe();
    this.inputBlurSubscription?.unsubscribe();
  }
}
