import { CmsContentTypes, Ribbon2Asset, Ribbon2Container } from '@atv-core/api/cms';

export interface SearchResponse {
  completions?: string[];
  results?: SearchElementList[];
}

export class SearchElementList {
  type: SearchTypes;
  elements?: Ribbon2Container;
}

// TODO remove this class
export class SearchRecordingElement {
  type: CmsContentTypes;
  searchElement: Ribbon2Asset;
  inFolderPage: boolean;

  constructor(type: CmsContentTypes, searchElement: Ribbon2Asset, inFolderPage: boolean) {
    this.type = type;
    this.searchElement = searchElement;
    this.inFolderPage = inFolderPage;
  }
}

export enum SearchTypes {
  ARTICLE = 'ARTICLE',
  LINEAR = 'LINEAR',
  REPLAY = 'REPLAY',
  VOD = 'VOD',
  BUNDLE = 'BUNDLE',
  RECORDING = 'RECORDING',
}
