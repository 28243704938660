import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Menu, PageTypes } from '@atv-core/api/cms';

@Injectable({
  providedIn: 'root',
})
export class ListPageResolverService {
  private currentMenu: Menu;

  constructor(private router: Router) {}

  public setCurrentMenu(menu: Menu): void {
    this.currentMenu = menu;
  }

  public getListRouteForCurrentTopRoute(): string {
    let url = this.router.url;
    // remove leading /
    if (url.startsWith('/')) {
      url = url.substring(1);
    }

    // get path fragments
    const pathsFragments = url.split('/');

    // first fragment has to be main and must have a least one top fragment
    if (!this.currentMenu?.items || pathsFragments?.length < 2 || pathsFragments?.[0] !== 'main') {
      return undefined;
    }

    const subLevelItem = this.currentMenu.items
      .find(
        (topItem) =>
          topItem.tag === pathsFragments[1] && !topItem.pageType && topItem.items?.length > 0
      )
      ?.items?.find((subItem) => subItem.pageType === PageTypes.LIST_PAGE);

    return subLevelItem
      ? `${pathsFragments[0]}/${pathsFragments[1]}/${subLevelItem.tag}`
      : undefined;
  }
}
