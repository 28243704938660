<router-outlet></router-outlet>

<div #detailOverlay class="detail-overlay-wrapper" id="detail-overlay-wrapper">
  <router-outlet name="detailOverlay"></router-outlet>
</div>

<app-messages></app-messages>
<app-profile-selection></app-profile-selection>
<ng-template #playerTemplate></ng-template>
<ng-template #remotePlayerTemplate></ng-template>
<app-adult-popup></app-adult-popup>
<app-delete-all-recordings-popup></app-delete-all-recordings-popup>
<app-auth-popup></app-auth-popup>
<app-logout-confirm-popup></app-logout-confirm-popup>
<app-anonymous-catalog-select-popup></app-anonymous-catalog-select-popup>
<app-share-popup></app-share-popup>
<app-number-entry *ngIf="isSmartTv"></app-number-entry>
<app-mini-detail *ngIf="isSmartTv"></app-mini-detail>
<app-exit-popup *ngIf="isTizen"></app-exit-popup>
