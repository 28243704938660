<table *ngIf="!isSmartTv">
  <tr *ngIf="info.showStbs">
    <td class="subscription-setting-title">{{ subscriptionVisual.stbTitle }}</td>
    <td class="subscription-setting-options">
      <select
        class="subscription-stb-options"
        [(ngModel)]="settings.deviceId"
        (change)="deviceChange.emit()"
      >
        <option *ngFor="let stb of info.stbs" [ngValue]="stb.id">{{ stb.alias }}</option>
      </select>
    </td>
  </tr>
  <tr *ngIf="info.showSubscriptionTypes">
    <td class="subscription-setting-title">{{ subscriptionVisual.typeTitle }}</td>
    <td class="subscription-setting-options">
      <select class="subscription-type-options" [(ngModel)]="settings.type">
        <option *ngFor="let type of subscriptionTypes" [ngValue]="type.value">{{
          type.name
        }}</option>
      </select>
    </td>
  </tr>
  <tr *ngIf="info.showBeginBufferOptions">
    <td class="subscription-setting-title">{{ subscriptionVisual.beginBufferTitle }}</td>
    <td class="subscription-setting-options">
      <select class="subscription-beginbuffer-options" [(ngModel)]="settings.beginBuffer">
        <option *ngFor="let option of beginBufferOptions" [ngValue]="option.value">{{
          option.name
        }}</option>
      </select>
    </td>
  </tr>

  <tr *ngIf="info.showEndBufferOptions">
    <td class="subscription-setting-title">{{ subscriptionVisual.endBufferTitle }}</td>
    <td class="subscription-setting-options">
      <select class="subscription-endbuffer-options" [(ngModel)]="settings.endBuffer">
        <option *ngFor="let option of endBufferOptions" [ngValue]="option.value">{{
          option.name
        }}</option>
      </select>
    </td>
  </tr>

  <tr *ngIf="info.showDeleteProtected">
    <td class="subscription-setting-title">{{ subscriptionVisual.deleteProtectTitle }}</td>
    <td class="subscription-setting-options">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="settings.deleteProtected" />
        <span class="slider"></span>
      </label>
    </td>
  </tr>
</table>

<div *ngIf="isSmartTv">
  <div *ngIf="info.showStbs" class="subscription-setting-field">
    <label>{{ subscriptionVisual.stbTitle }}</label>
    <div id="subscription-stb-container" class="chip-container">
      <div
        class="stb chip"
        (keyup)="inputKeyEvent($event)"
        *ngFor="let stb of info.stbs"
        [class.selected]="stb.id === settings.deviceId"
        (focus)="settings.deviceId = stb.id; deviceChange.emit()"
        [attr.data-sn-down]="getNextSection('stb')"
        [attr.data-sn-up]="getPreviousSection('stb')"
        tabindex="-1"
      >
        {{ stb.alias }}
      </div>
    </div>
  </div>
  <div *ngIf="info.showSubscriptionTypes" class="subscription-setting-field">
    <label>{{ subscriptionVisual.typeTitle }}</label>
    <div id="subscription-type-container" class="chip-container">
      <div
        class="type chip"
        (keyup)="inputKeyEvent($event)"
        *ngFor="let type of subscriptionTypes"
        [class.selected]="settings.type === type.value"
        (focus)="settings.type = type.value"
        [attr.data-sn-down]="getNextSection('type')"
        [attr.data-sn-up]="getPreviousSection('type')"
        data-sn-up=".stb.chip.selected"
      >
        {{ type.name }}
      </div>
    </div>
  </div>
  <div *ngIf="info.showBeginBufferOptions" class="subscription-setting-field">
    <label>{{ subscriptionVisual.beginBufferTitle }}</label>
    <div id="subscription-begin-buffer-container" class="chip-container">
      <div
        class="begin-buffer chip"
        (keyup)="inputKeyEvent($event)"
        *ngFor="let option of beginBufferOptions"
        [class.selected]="settings.beginBuffer === option.value"
        (focus)="settings.beginBuffer = option.value"
        [attr.data-sn-down]="getNextSection('begin-buffer')"
        [attr.data-sn-up]="getPreviousSection('begin-buffer')"
        data-sn-up=".type.chip.selected, .stb.chip.selected"
      >
        {{ option.name }}
      </div>
    </div>
  </div>
  <div *ngIf="info.showEndBufferOptions" class="subscription-setting-field">
    <label>{{ subscriptionVisual.endBufferTitle }}</label>
    <div id="subscription-end-buffer-container" class="chip-container">
      <div
        class="end-buffer chip"
        (keyup)="inputKeyEvent($event)"
        *ngFor="let option of endBufferOptions"
        [class.selected]="settings.endBuffer === option.value"
        (focus)="settings.endBuffer = option.value"
        [attr.data-sn-down]="getNextSection('end-buffer')"
        [attr.data-sn-up]="getPreviousSection('end-buffer')"
        data-sn-up=".begin-buffer.chip.selected, .type.chip.selected, .stb.chip.selected"
      >
        {{ option.name }}
      </div>
    </div>
  </div>
  <div *ngIf="info.showDeleteProtected" class="subscription-setting-field">
    <label>{{ subscriptionVisual.deleteProtectTitle }}</label>
    <div id="subscription-delete-protect-container" class="chip-container">
      <div
        class="delete-protect chip"
        (keyup)="inputKeyEvent($event)"
        [class.selected]="settings.deleteProtected"
        (focus)="settings.deleteProtected = true"
        [attr.data-sn-down]="getNextSection('delete-protect')"
        [attr.data-sn-up]="getPreviousSection('delete-protect')"
      >
        {{ onText }}
      </div>
      <div
        class="delete-protect chip"
        (keyup)="inputKeyEvent($event)"
        [class.selected]="!settings.deleteProtected"
        (focus)="settings.deleteProtected = false"
        [attr.data-sn-down]="getNextSection('delete-protect')"
        [attr.data-sn-up]="getPreviousSection('delete-protect')"
      >
        {{ offText }}
      </div>
    </div>
  </div>
</div>
