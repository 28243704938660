import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AdultMode } from '@atv-core/services/adult';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { CategoryFilterComponent } from '@atv-shared/filters/category-filter/category-filter.component';
import { ChannelFilterComponent } from '@atv-shared/filters/channel-filter/channel-filter.component';
import { DayFilterComponent } from '@atv-shared/filters/day-filter/day-filter.component';
import { FilterContentTypes, FilterModel } from '@atv-shared/filters/filters.model';

@Component({
  selector: 'app-list-page-filters',
  templateUrl: './list-page-filters.component.html',
  styles: [],
})
export class ListPageFiltersComponent implements OnInit {
  @Input()
  listFilterModel: FilterModel;

  @Output()
  listFilterChange = new EventEmitter();

  @Input()
  categoryFilterType: FilterContentTypes;

  @Input()
  allowedAdultMode: AdultMode;

  // match # filter in list filters
  private readysNeeded = 3;

  @ViewChild('channelFilter', { static: true })
  channelFilter: ChannelFilterComponent;
  @ViewChild('dayFilter', { static: true })
  dayFilter: DayFilterComponent;
  @ViewChild('categoryFilter', { static: true })
  categoryFilter: CategoryFilterComponent;

  public isSmartTv = SharedUtilityService.isSmartTv();

  constructor() {}

  ngOnInit(): void {}

  public clearAllFilters() {
    this.readysNeeded = 3;

    // timeout needed to give router time to remove query params from url

    if (this.channelFilter) {
      setTimeout(() => {
        this.channelFilter.resetFilter();
      }, 0);
    }
    if (this.dayFilter) {
      setTimeout(() => {
        this.dayFilter.resetFilter();
      }, 0);
    }

    if (this.categoryFilter) {
      setTimeout(() => {
        this.categoryFilter.resetFilter();
      }, 0);
    }
  }

  filterReady() {
    this.readysNeeded--;

    if (this.readysNeeded <= 0) {
      this.listFilterChange.emit();
    }
  }

  channelFilterChanged() {
    this.listFilterChange.emit();
  }

  dayFilterChanged() {
    this.listFilterChange.emit();
  }

  categoryFilterChanged() {
    this.listFilterChange.emit();
  }
}
