import { ContentProvider } from '@atv-shared/content-provider/content-provider';
import { Ribbon2Container, Ribbon2Asset, CmsContentTypes } from '@atv-core/api/cms';
import { Observable, forkJoin } from 'rxjs';
import { StbCacheService } from '@atv-core/services/cache/stb';
import { ContentProviderFactoryService } from '@atv-shared/content-provider/content-provider-factory.service';
import { RecordingModel } from '@atv-core/services/cache/stb/recording.model';
import { switchMap } from 'rxjs/operators';

export class SearchRecordingContentProvider extends ContentProvider {
  private hasMore = true;
  private programIds: string[] = [];
  private seriesIds: string[] = [];

  constructor(
    private stbCache: StbCacheService,
    private allowAdult: boolean,
    private contentProviderFactoryService: ContentProviderFactoryService,
    private container: Ribbon2Container,
  ) {
    super();

    this.orientation = this.container.type;
    this.container.items?.forEach((item) => {
      if (item.asset.type === CmsContentTypes.SERIES) {
        this.seriesIds.push(item.asset.id);
      } else if (item.asset.type === CmsContentTypes.PROGRAM) {
        this.programIds.push(item.asset.id);
      }
    });
  }

  public getItems(): Observable<Ribbon2Asset[]> {
    this.hasMore = false;
    return this.stbCache.getRecordingsForAllDevices(this.allowAdult).pipe(switchMap(
      results => this.getFilteredResults(results)
    ));
  }

  public hasNext(): boolean {
    return this.hasMore;
  }

  public resetPaging(): void {
    this.hasMore = true;
  }

  private getFilteredResults(recordings: RecordingModel[]): Observable<Ribbon2Asset[]> {
    const filteredRecordings = recordings.filter((rec) => (this.programIds.includes(rec.program) || this.seriesIds.includes(rec.series)));
    return forkJoin(this.contentProviderFactoryService.groupRecordingsBySeries(filteredRecordings).map(recordingGroup =>
      this.contentProviderFactoryService.recordingFolderToRibbon2Asset(recordingGroup.main, recordingGroup.others)));
  }
}
