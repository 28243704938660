import { EventEmitter, Injectable, NgZone, Type } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { CmsAction, CmsApiService, Menu, MenuItem, PageTypes, Properties } from '@atv-core/api/cms';
import { AuthorizationGuard } from '@atv-core/core/guards/authorization.guard';
import { CmsActionService } from '@atv-core/services/cms-action/cms-action.service';
import { SessionService } from '@atv-core/services/session';
import { AtvFeatures } from '@atv-core/utility/constants/atv_features';
import { SessionStorageKeys } from '@atv-core/utility/constants/sessionStorageKeys';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { ClientAreaPageComponent } from '@atv-pages//client-area-page/client-area-page.component';
import { CmsPageComponent } from '@atv-pages/cms-page/cms-page.component';
import { CustomPageComponent } from '@atv-pages/custom-page/custom-page.component';
import { GuidePageComponent } from '@atv-pages/guide-page/guide-page.component';
import { RecordingsPageComponent } from '@atv-pages/recordings-page/recordings-page.component';
import { RemindersPageComponent } from '@atv-pages/reminders-page/reminders-page.component';
import { SearchPageComponent } from '@atv-pages/search-page/search-page.component';
import { SettingsPageComponent } from '@atv-pages/settings-page/settings-page.component';
import { environment } from '@env/environment';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MainComponent } from 'src/app/main/main.component';

import { ReloadablePageComponent } from '@atv-pages/reloadable-page.component';
import { SettingsKeys } from '../../../bootstrap/services/config';
import { DetailOverlayService } from '../detail/detail-overlay.service';
import { ListPageResolverService } from '../list-page-resolver/list-page-resolver.service';
import { PlayerService } from '../player/player.service';
import { SpatialNavigationService } from '../spatial-navigation/spatial-navigation.service';
import { ListPageComponent } from '@atv-pages/list-page/list-page.component';
import { SearchRecordingFolderComponent } from '@atv-pages/search-page/search-recording-folder/search-recording-folder.component';
import { CmsActionMethod } from './../../api/cms';
import { ConfigService } from '@atv-bootstrap/services/config';

export interface PageData {
  pageType: PageTypes;
  pageId: string;
  properties: Properties[];
}

@Injectable({
  providedIn: 'root',
})
export class MenuManagerService {
  public currentMenu: Menu;
  public updateMenuEvent = new EventEmitter();

  private guideKeySubscription: Subscription;

  constructor(
    private router: Router,
    private config: ConfigService,
    private cmsApi: CmsApiService,
    private sessionService: SessionService,
    private cmsAction: CmsActionService,
    private zone: NgZone,
    private spatialNavigationService: SpatialNavigationService,
    private detailOverlayService: DetailOverlayService,
    private playerService: PlayerService,
    private listPageResolver: ListPageResolverService,
  ) {
    this.sessionService.catalogChangedEvent.subscribe(() => {
      this.loadNewMenu();
    });
  }

  private loadNewMenu(): void {
    this.cmsApi.getMenu(this.sessionService.getCatalogId()).subscribe(
      (menu) => {
        this.filterMenu(menu);

        this.defineRoutesForMenu();
        this.updateMenuEvent.emit();

        if (this.guideKeySubscription) {
          this.guideKeySubscription.unsubscribe();
          this.guideKeySubscription = null;
        }

        if (SharedUtilityService.isSmartTv()) {
          const guidePath = this.findGuidePath();
          if (guidePath) {
            this.guideKeySubscription = (fromEvent(document, 'keyup') as Observable<KeyboardEvent>)
              .pipe(filter((event) => this.spatialNavigationService.keyCodeIsGuide(event.keyCode)))
              .subscribe(() => {
                this.playerService.closePlayerEvent.emit();
                if (this.detailOverlayService.isOverlayOpen()) {
                  this.detailOverlayService.closeDetail();
                }

                window.setTimeout(() => {
                  this.router.navigate([guidePath]);
                }, 0);
              });
          }
        }

        if (
          !this.sessionService.anonymousProfileIsActive() &&
          this.currentMenu &&
          menu.id === this.currentMenu.id
        ) {
          const redirect = sessionStorage.getItem(SessionStorageKeys.login_succes_redirect_url);
          if (redirect) {
            this.redirect();
          } else {
            this.router.navigate(['/main']);
          }

          return;
        }

        this.redirect();
      },
      () => {
        this.currentMenu = undefined;
        this.listPageResolver.setCurrentMenu(this.currentMenu);
        this.defineRoutesForMenu();
        this.updateMenuEvent.emit();

        this.redirect();
      },
    );
  }

  private redirect(): void {
    let redirect = sessionStorage.getItem(SessionStorageKeys.login_succes_redirect_url);

    if (redirect?.startsWith('/redirect/')) {
      this.mapUrl(`${window.location.origin}${redirect}`);
      return;
    }

    sessionStorage.removeItem(SessionStorageKeys.login_succes_redirect_url);
    const redirectParams = sessionStorage.getItem(SessionStorageKeys.login_succes_redirect_params);
    sessionStorage.removeItem(SessionStorageKeys.login_succes_redirect_params);

    redirect =
      !redirect || redirect === '/' || redirect === '/account/login' ? '/main' : redirect;

    // if cannot redirect
    this.zone.run(() => {
      redirect
        ? redirectParams
        ? this.router.navigate([redirect], { queryParams: JSON.parse(redirectParams) })
        : this.router.navigate([redirect])
        : this.router.navigate(['/main']);
    });
  }

  private mapUrl(fullUrl: string): void {
    this.cmsApi.getUrlMapping(fullUrl, this.sessionService.getCatalogId()).subscribe(
      (result) => {
        if (result && result.action) {
          const action: CmsAction = {
            actionType: result.action.type,
            actionId: result.action.id,
            actionMethod: result.action.method,
          };
          if (result.action.method !== CmsActionMethod.DETAIL) {
            this.router.navigate(['/main']);
          }

          this.cmsAction.doAction(action);
        } else {
          this.router.navigate(['/main']);
        }
      },
      () => {
        this.router.navigate(['/main']);
      },
    );

    sessionStorage.removeItem(SessionStorageKeys.login_succes_redirect_url);
    sessionStorage.removeItem(SessionStorageKeys.login_succes_redirect_params);
  }

  private filterMenu(menu: Menu): void {
    this.currentMenu = {
      id: menu.id,
      items: [],
    };
    this.listPageResolver.setCurrentMenu(this.currentMenu);
    if (menu && menu.items) {
      menu.items.forEach((topLevelMenuItem) => {
        if (this.mayAddMenuItem(topLevelMenuItem.pageType, topLevelMenuItem.properties)) {
          if (topLevelMenuItem.pageType) {
            this.currentMenu.items.push(topLevelMenuItem);
          } else if (topLevelMenuItem.items && topLevelMenuItem.items.length > 0) {
            const filteredSubLevelItems = this.filterValidSubLevels(topLevelMenuItem.items);
            if (filteredSubLevelItems.length !== 0) {
              topLevelMenuItem.items = filteredSubLevelItems;
              this.currentMenu.items.push(topLevelMenuItem);
            }
          } else if (!topLevelMenuItem.pageType) {
            return;
          }
        }
      });
    }
  }

  private filterValidSubLevels(subLevelItems: MenuItem[]): MenuItem[] {
    const items = [];

    subLevelItems.forEach((subLevelItem) => {
      // only get items that has no children
      if (
        subLevelItem.pageType &&
        (subLevelItem.items === undefined || subLevelItem.items.length <= 0) &&
        this.mayAddMenuItem(subLevelItem.pageType, subLevelItem.properties)
      ) {
        items.push(subLevelItem);
      }
    });

    return items;
  }

  private mayAddMenuItem(pageType: PageTypes, properties: Properties[]): boolean {
    // if hase page type but no component is defined for page type
    if (pageType && this.convertPageTypeToComponent(pageType) === undefined) {
      return false;
    }

    // when no recording feature skip all recording pages
    if (
      (pageType === PageTypes.RECORDINGS_CONFLICT_PAGE ||
       pageType === PageTypes.RECORDINGS_EXPIRE_PAGE ||
       pageType === PageTypes.RECORDINGS_PLANNED_PAGE ||
       pageType === PageTypes.RECORDINGS_RECORDED_PAGE) &&
      !environment.atv_feature_list.includes(AtvFeatures.RECORDING)
    ) {
      return false;
    }

    if (
      properties &&
      properties.includes(Properties.NPVR_REQUIRED) &&
      properties.includes(Properties.LOCAL_RECORDER_REQUIRED)
    ) {
      // When NPVR_REQUIRED and LOCAL_RECORDER_REQUIRED are set, only hide the page when there is no NPVR *and* no LOCAL_RECORDER
      if (
        !this.sessionService.getStbsList().hasNpvrBox() &&
        this.sessionService.getStbsList().getRecordingStbs(false).length === 0
      ) {
        return false;
      }
    } else {
      if (
        properties &&
        properties.includes(Properties.NPVR_REQUIRED) &&
        !this.sessionService.getStbsList().hasNpvrBox()
      ) {
        return false;
      }

      if (
        properties &&
        properties.includes(Properties.LOCAL_RECORDER_REQUIRED) &&
        this.sessionService.getStbsList().getRecordingStbs(false).length === 0
      ) {
        return false;
      }
    }

    // if page requires adult, but adult is not allowed in settings
    if (
      properties?.includes(Properties.ADULT_REQUIRED) &&
      (this.config.getSettingString(SettingsKeys.showAdultPage) !== 'ON' || !environment.allowAdult)
    ) {
      return false;
    }

    return !properties?.some((property) => !Object.keys(Properties).includes(property));
  }

  private defineRoutesForMenu(): void {
    const mainChildren: Route[] = [];

    if (this.currentMenu && this.currentMenu.items && this.currentMenu.items.length !== 0) {
      this.currentMenu.items.forEach((topLevelItem) => {
        let tmpRoute: Route;

        if (topLevelItem.pageType) {
          tmpRoute = {
            path: topLevelItem.tag,
            component: this.convertPageTypeToComponent(topLevelItem.pageType),
            data: {
              pageType: topLevelItem.pageType,
              pageId: topLevelItem.pageId,
              properties: topLevelItem.properties,
            } as PageData,
          };

          if (tmpRoute && this.isSearchPage(topLevelItem.pageType)) {
            mainChildren.unshift({
              path: `${topLevelItem.tag}/recordings/:seriesId`,
              component: SearchRecordingFolderComponent,
            });
          }

          if (tmpRoute && this.isRecordingPage(topLevelItem.pageType)) {
            mainChildren.unshift({
              path: `${topLevelItem.tag}/:seriesId`,
              component: this.convertPageTypeToComponent(topLevelItem.pageType),
              data: {
                pageType: topLevelItem.pageType,
                pageId: topLevelItem.pageId,
                properties: topLevelItem.properties,
              } as PageData,
            });
          }
        } else if (topLevelItem.items && topLevelItem.items.length > 0) {
          const topLevelChildren: Route[] = this.getTopLevelChildren(topLevelItem);

          if (topLevelChildren.length !== 0) {
            tmpRoute = {
              path: topLevelItem.tag,

              children: topLevelChildren,
            };
          }
        } else {
          return;
        }

        if (tmpRoute) {
          mainChildren.unshift(tmpRoute);

          if (topLevelItem.isDefault) {
            mainChildren.push({
              path: '**',
              redirectTo: topLevelItem.tag,
            });
          }
        }
      });

      // if no default set, set first page without path parameters as default
      if (!mainChildren.some((c) => c.path === '**')) {
        const index = mainChildren.findIndex(c => c.path.indexOf(':') === -1);
        if (index >= 0) {
          mainChildren.push({ path: '**', redirectTo: mainChildren[index].path });
        }
      }
    }

    this.router.resetConfig([
      {
        path: '',
        loadChildren: () => import('src/app/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'main',
        component: MainComponent,
        children: mainChildren,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'detail',
        loadChildren: () =>
          import('src/app/atv-detail/atv-detail.module').then((m) => m.AtvDetailModule),
        canActivate: [AuthorizationGuard],
        outlet: 'detailOverlay',
      },
      {
        path: 'detail',
        loadChildren: () =>
          import('src/app/atv-detail/atv-detail.module').then((m) => m.AtvDetailModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'custom/:pageId',
        component: CustomPageComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'recordings/:seriesId',
        component: SearchRecordingFolderComponent,
      },
      {
        path: 'account',
        loadChildren: () =>
          import('src/app/account-smarttv/account-smarttv.module').then(
            (m) => m.AccountSmarttvModule,
          ),
      },
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ]);
  }

  private convertPageTypeToComponent(pageType: PageTypes): Type<ReloadablePageComponent> {
    switch (pageType) {
      case PageTypes.LIST_PAGE:
        return ListPageComponent;
      case PageTypes.TV_GUIDE_PAGE:
        return GuidePageComponent;
      case PageTypes.SEARCH_PAGE:
        return SearchPageComponent;
      case PageTypes.SETTINGS_PAGE:
        return SettingsPageComponent;
      case PageTypes.CLIENT_AREA_PAGE:
        return ClientAreaPageComponent;
      case PageTypes.REMINDERS_PAGE:
        return RemindersPageComponent;
      case PageTypes.RECORDINGS_CONFLICT_PAGE:
      case PageTypes.RECORDINGS_EXPIRE_PAGE:
      case PageTypes.RECORDINGS_PLANNED_PAGE:
      case PageTypes.RECORDINGS_RECORDED_PAGE:
        return RecordingsPageComponent;
      case PageTypes.CMS_PAGE:
        return CmsPageComponent;
      default:
        return undefined; // should return undefined
    }
  }

  private getTopLevelChildren(topLevel: MenuItem): Route[] {
    const routes: Route[] = [];
    topLevel.items.forEach((subLevelItem) => {
      if (subLevelItem.pageType) {
        routes.unshift({
          path: subLevelItem.tag,
          component: this.convertPageTypeToComponent(subLevelItem.pageType),
          data: {
            pageType: subLevelItem.pageType,
            pageId: subLevelItem.pageId,
            properties: this.mergeProperties(subLevelItem.properties, topLevel.properties),
          } as PageData,
        });

        if (this.isSearchPage(subLevelItem.pageType)) {
          routes.unshift({
            path: `${subLevelItem.tag}/recordings/:seriesId`,
            component: SearchRecordingFolderComponent,
          });
        }

        if (this.isRecordingPage(subLevelItem.pageType)) {
          routes.unshift({
            path: `${subLevelItem.tag}/:seriesId`,
            component: this.convertPageTypeToComponent(subLevelItem.pageType),
            data: {
              pageType: subLevelItem.pageType,
              pageId: subLevelItem.pageId,
              properties: this.mergeProperties(subLevelItem.properties, topLevel.properties),
            } as PageData,
          });
        }

        if (subLevelItem.isDefault) {
          routes.push({
            path: '**',
            redirectTo: subLevelItem.tag,
          });
        }
      }
    });

    // if no default set, set last as default
    if (!routes.some((c) => c.path === '**')) {
      routes.push({ path: '**', redirectTo: routes[routes.length - 1].path });
    }

    return routes;
  }

  private isRecordingPage(pageType: PageTypes): boolean {
    return (
      pageType === PageTypes.RECORDINGS_CONFLICT_PAGE ||
      pageType === PageTypes.RECORDINGS_EXPIRE_PAGE ||
      pageType === PageTypes.RECORDINGS_PLANNED_PAGE ||
      pageType === PageTypes.RECORDINGS_RECORDED_PAGE
    );
  }

  private isSearchPage(pageType: PageTypes): boolean {
    return pageType === PageTypes.SEARCH_PAGE;
  }

  private mergeProperties(...properties: Properties[][]): Properties[] {
    return [...new Set<Properties>(properties.flat())];
  }

  private findGuidePath(current?: Routes, currentPath?: string): string {
    if (!current) {
      // find main and search recursively
      const main = this.router.config.find((route) => route.path === 'main');

      if (!main) {
        return null;
      }

      return this.findGuidePath(main.children, '/main');
    } else {
      for (const r of current) {
        if (
          r.data &&
          r.data.pageType === PageTypes.TV_GUIDE_PAGE &&
          (r.data.properties as Properties[]).includes(Properties.FOCUS_FUTURE)
        ) {
          return `${currentPath}/${r.path}`;
        } else if (r.children && r.children.length > 0) {
          // check recursively
          const recursive = this.findGuidePath(r.children, `${currentPath}/${r.path}`);
          if (recursive) {
            return recursive;
          }
        }
      }

      return null;
    }
  }
}
