import { Location } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LoginSessionV3, StbSettingsKeys } from '@atv-core/api/api/api-api.model';
import { ApiApiService } from '@atv-core/api/api/api-api.service';
import { DeviceSettingResult, HistoryApiService } from '@atv-core/api/history';
import {
  DetailTranslationKeys,
  ErrorTranslationKeys,
  RecordingTranslationKeys,
  SettingsKeys,
} from '@atv-bootstrap/services/config/config.model';
import { MessagesService } from '@atv-core/services/messages/messages.service';
import { LocalStorageKeys } from '@atv-core/utility/constants/localStorageKeys';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { ConfigService } from '@atv-bootstrap/services/config';
import { MainCategoryTypes } from '../log/analytics-categories.model';
import { Category, LoginContext } from '../log/log.model';
import { AvatarElement, ProfileElement, ProfileElementData } from './../../api/aa/aa-api.model';
import { AaApiService } from './../../api/aa/aa-api.service';
import { AuthenticateSubcategories } from './../log/analytics-categories.model';
import { Stb } from './stb';
import { StbList } from './stb-list';
import { AnonymousUserProfile, UserProfile } from './user-profile.model';
import { TokenCommunicationService } from '../../../home/token-communication.service';

@Injectable({ providedIn: 'root' })
export class SessionService {
  private session: LoginSessionV3;
  private stbList: StbList = new StbList();

  private activeUserProfile: UserProfile;
  private anonymousUserProfile: AnonymousUserProfile;

  public catalogChangedEvent = new EventEmitter();

  public clearAllCachesEvent = new EventEmitter();
  public reloadCurrentPageEvent = new EventEmitter();
  public sessionResetEvent = new EventEmitter();

  public showCatalogSelectionPopup = new EventEmitter();
  public showProfileSelectionPopup = new EventEmitter();

  public showSharePopup = new EventEmitter();

  public previousRoute: string = undefined;

  public maxLengthProfileName: number;

  constructor(
    private config: ConfigService,
    private apiApi: ApiApiService,
    private historyApi: HistoryApiService,
    private messagesService: MessagesService,
    private aaApi: AaApiService,
    private router: Router,
    private location: Location,
    private tokenCommunicationService: TokenCommunicationService,
  ) {
    this.maxLengthProfileName = this.config.getSettingNumber(SettingsKeys.maxLengthProfileName, 25);

    if (!SessionService.isLoginFromIframe()) {
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationStart) {
          this.previousRoute = this.router.url;
        }
      });
    }
  }

  private static isLoginFromIframe(): boolean {
    return !!window.frameElement;
  }

  public goToPreviousRoute(): void {
    // if no previous page go to main
    if (
      !this.previousRoute ||
      this.previousRoute === '/' ||
      this.previousRoute === '/account/login' ||
      this.previousRoute.includes('#') || this.previousRoute === this.router.url
    ) {
      this.router.navigate(['/main']);
    } else {
      this.location.back();
    }
  }

  public setSession(): Observable<number> {
    return this.apiApi.getSession().pipe(
      switchMap((response: HttpResponse<LoginSessionV3>) => {
        const result = response.body;
        this.session = result;
        this.tokenCommunicationService.startActiveTabBeacon();

        this.checkServerTime();

        if (result.stbs) {
          const stbs = result.stbs.map(stb => new Stb(stb.id, stb.type, stb.entitlementId, stb.serial));
          return this.getStbRecordingSetting(stbs).pipe(
            switchMap(() => {
              let npvrStb: Stb;
              if (result.npvr?.id) {
                npvrStb = new Stb(result.npvr.id);
                npvrStb.alias = this.config.getTranslation(
                  RecordingTranslationKeys.recordings_npvr_settopbox_name,
                );
              }

              this.stbList = new StbList(stbs, npvrStb);
              return this.getProfile(result.device.id).pipe(
                switchMap(() => {
                  return of(response.status);
                }),
                catchError((e) => {
                  this.session = undefined;
                  this.tokenCommunicationService.stopActiveTabBeacon();
                  this.messagesService.showErrorMessage(
                    this.config.getTranslation(ErrorTranslationKeys.error_authentication_failed),
                  );
                  throw e;
                }),
              );
            }),
          );
        } else {
          return this.getProfile(result.device.id).pipe(
            switchMap(() => {
              return of(response.status);
            }),
            catchError((e) => {
              this.session = undefined;
              this.tokenCommunicationService.stopActiveTabBeacon();
              this.messagesService.showErrorMessage(
                this.config.getTranslation(ErrorTranslationKeys.error_authentication_failed),
              );
              throw e;
            }),
          );
        }
      }),
      catchError((error: HttpErrorResponse) => {

        throw error;
      }),
    );
  }

  public reset(clearCache = true, reload = true): void {
    if (clearCache) {
      this.clearAllCachesEvent.emit();
    }

    setTimeout(() => {
      this.sessionResetEvent.emit();
    }, 0);

    if (reload) {
      setTimeout(() => {
        this.reloadCurrentPageEvent.emit();
      }, 0);
    }
  }

  public switchProfile(profile: ProfileElement): Observable<void> {
    return this.aaApi.switchProfile(this.session.device.id, profile.id).pipe(
      switchMap((result) => {
        this.activeUserProfile = new UserProfile(result, this.maxLengthProfileName);
        this.reset(true);
        this.catalogChangedEvent.emit();
        return of(undefined);
      }),
    );
  }

  private getProfile(deviceId: string): Observable<void> {
    return this.aaApi.getBaseProfileForDevice(deviceId).pipe(
      switchMap((result) => {
        this.activeUserProfile = new UserProfile(result, this.maxLengthProfileName);
        this.reset(true);
        this.catalogChangedEvent.emit();
        return of(undefined);
      }),
    );
  }

  public getCustomer(): string {
    return this.session?.customer.id ?? '';
  }

  public getEntitlementId(): string {
    return this.anonymousProfileIsActive() ? this.anonymousUserProfile.defaultEntitlementId : this.session?.device?.entitlementId;
  }

  private getStbRecordingSetting(stbs: Stb[]): Observable<void> {
    const requests = [];
    stbs.forEach((stb) => {
      requests.push(this.historyApi.getAllDeviceSettings(stb.id).pipe(catchError(() => of([]))));
    });

    if (requests.length === 0) {
      return of(undefined);
    }

    return forkJoin(requests).pipe(
      tap((results: DeviceSettingResult[][]) => {
        results.forEach((deviceSettings, index) => {
          const recording = deviceSettings.find(
            (deviceSetting) => deviceSetting.name === StbSettingsKeys.stb_recording,
          );
          const deviceAlias = deviceSettings.find(
            (deviceSetting) => deviceSetting.name === StbSettingsKeys.alias,
          );

          if (recording) {
            stbs[index].recording = recording.value;
          }
          if (deviceAlias) {
            stbs[index].alias = deviceAlias.value;
          } else {
            this.getFallBackDeviceAlias(stbs[index]);
          }
        });
      }),
      catchError(() => of(undefined)),
    );
  }

  public getDeviceId(): string {
    if (this.anonymousProfileIsActive()) {
      let deviceId = localStorage.getItem(LocalStorageKeys.anonymous_device_id);
      if (deviceId) {
        return deviceId;
      } else {
        deviceId = SharedUtilityService.guid();
        localStorage.setItem(LocalStorageKeys.anonymous_device_id, deviceId);
        return deviceId;
      }
    }

    return this.session?.device?.id;
  }

  public anonymousProfileIsActive(): boolean {
    return this.activeUserProfile === undefined && this.anonymousUserProfile !== undefined;
  }

  public setAnonymousActive(): void {
    this.anonymousUserProfile = new AnonymousUserProfile(this.config.getAnonymousSettings());
    this.activeUserProfile = undefined;
    this.session = undefined;
    this.tokenCommunicationService.stopActiveTabBeacon();
    this.stbList = new StbList([]);
    this.reset(true, false);
    this.catalogChangedEvent.emit();
  }

  public activeUserInUse(): boolean {
    return this.activeUserProfile !== undefined;
  }

  public getDisplayName(allowProfileSwitching = false): string {
    if (allowProfileSwitching) {
      if (this.activeUserProfile) {
        return this.activeUserProfile.getProfileName();
      }
    } else {
      return this.session?.customer.displayName;
    }
  }

  public switchAnonymousActiveCatalogId(catalogId: string): void {
    if (this.anonymousUserProfile) {
      this.anonymousUserProfile.setActiveCatalogId(catalogId);
      this.reset(true);
      this.catalogChangedEvent.emit();
    }
  }

  public getSession(): LoginSessionV3 {
    return this.session;
  }

  public getActiveProfileId(): string {
    if (this.activeUserProfile) {
      return this.activeUserProfile.id;
    } else if (this.anonymousUserProfile) {
      return this.anonymousUserProfile.defaultProfileId;
    }

    return undefined;
  }

  public getCatalogId(): string {
    if (this.activeUserProfile) {
      return this.activeUserProfile.profile.catalog;
    } else if (this.anonymousUserProfile) {
      return this.anonymousUserProfile.activeCatalogId;
    }

    return undefined;
  }

  public updateActiveProfile(profile: ProfileElementData, avatar: AvatarElement): void {
    if (this.activeUserProfile) {
      this.activeUserProfile.updateProfile(profile, avatar);
    }
  }

  private checkServerTime(): void {
    this.apiApi.getServerTime().subscribe(serverTime => {
      const maxTimeDiff = this.config.getSettingNumber(SettingsKeys.startupTimeDifference, 120000);
      const difference = SharedUtilityService.timeStringToMs(serverTime.time) - new Date().getTime();

      if (Math.abs(difference) > maxTimeDiff) {
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_device_time),
        );
      }
    });
  }

  public getAvatar(): string {
    let avatar = '';
    if (this.activeUserProfile) {
      avatar = this.activeUserProfile.getAvatar();
    } else if (this.anonymousUserProfile) {
      avatar = this.anonymousUserProfile.getAvatar();
    }

    return avatar || 'assets/default-profile.svg';
  }

  private getFallBackDeviceAlias(device: Stb): void {
    device.alias = this.config
      .getTranslation(DetailTranslationKeys.detail_stb_fallback_name)
      .replace('$1', device.serial.slice(-4));
  }

  public getStbsList(): StbList {
    return this.stbList;
  }

  // check if category is enabled
  public analyticsCategoryEnabled(categorie: Category): boolean {
    if (categorie.type === undefined) {
      return;
    }

    let availableAnalytics;
    if (this.anonymousProfileIsActive()) {
      const analytics = this.config.getSettingString(SettingsKeys.unauthorizedAnalytics);
      if (analytics) {
        availableAnalytics = JSON.parse(analytics);
      }
    } else if (this.session && this.session.analytics) {
      availableAnalytics = this.session.analytics;
    }

    if (availableAnalytics) {
      const mainCat = availableAnalytics.find((analytics) => analytics.category === categorie.type);
      if (mainCat && categorie.subType !== undefined) {
        return mainCat.subcategories.some((subcat) => subcat === categorie.subType.toString());
      }
    }

    // always allow login log
    return categorie.type === MainCategoryTypes.AUTHENTICATE &&
           categorie.subType === AuthenticateSubcategories.LOGIN;
  }

  public getLoginContext(): LoginContext {
    return {
      userId: this.session?.customer?.loginName,
      customerId: this.getCustomer(),
      deviceId: this.getDeviceId(),
      profileId: this.getActiveProfileId(),
      catalogId: this.getCatalogId(),
      entitlementId: this.getEntitlementId(),
    };
  }
}
