import { DeviceProfileElement, ProfileElementData } from '@atv-core/api/aa/aa-api.model';
import { SessionStorageKeys } from '@atv-core/utility/constants/sessionStorageKeys';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';

import { AvatarElement, Catalog } from './../../api/aa/aa-api.model';
import { AnonymousSettings } from '@atv-bootstrap/services/config';
import { ImageRecipe, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';

export class UserProfile implements DeviceProfileElement {
  id: string;
  profile: ProfileElementData;
  image: string;
  master?: boolean;
  fallback?: boolean;

  maxLengthProfileName: number;

  constructor(profile: DeviceProfileElement, maxLengthProfileName: number) {
    Object.keys(profile).forEach((key) => (this[key] = profile[key]));
    this.maxLengthProfileName = maxLengthProfileName;
  }

  public getProfileName(): string {
    if (this.profile.name.length > this.maxLengthProfileName) {
      return this.profile.name.substring(0, this.maxLengthProfileName) + '...';
    }

    return this.profile.name;
  }

  public getAvatar(): string {
    return ImageRecipeUtil.createImageUrl(this.image, SharedUtilityService.isSmartTv() ? ImageRecipe.AVATAR_3 : ImageRecipe.AVATAR_1);
  }

  public updateProfile(profileUpdate: ProfileElementData, avatar: AvatarElement): void {
    this.profile.avatar = profileUpdate.avatar;
    this.profile.name = profileUpdate.name;
    this.profile.catalog = profileUpdate.catalog;
    this.image = avatar.image;
  }
}

export class AnonymousUserProfile implements AnonymousSettings {
  catalogs?: Catalog[];
  defaultCatalogId: string;
  defaultProfileId: string;
  defaultEntitlementId: string;

  activeCatalogId: string;

  constructor(profile: AnonymousSettings) {
    Object.keys(profile).forEach((key) => (this[key] = profile[key]));

    this.activeCatalogId =
      sessionStorage.getItem(SessionStorageKeys.anonymous_catalog) || this.defaultCatalogId;
  }

  public setActiveCatalogId(catalogId: string): void {
    sessionStorage.setItem(SessionStorageKeys.anonymous_catalog, catalogId);
    this.activeCatalogId = catalogId;
  }

  public getAvatar(): string {
    const activeCatalog = this.catalogs.find((catalog) => catalog.id === this.activeCatalogId);

    return activeCatalog ?
      ImageRecipeUtil.createImageUrl(activeCatalog.avatar, SharedUtilityService.isSmartTv() ? ImageRecipe.AVATAR_3 : ImageRecipe.AVATAR_1) :
      '';
  }
}
