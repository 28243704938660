<div id="container" *ngIf="!isSmartTv">
  <div id="guide-container" #guideContainer>
    <div id="channel-container">
      <div class="guide-category-filter-wrapper" *ngIf="guideFilterModel">
        <app-guide-category-filter
          (filterChange)="guideFilterChange()"
          [categoryFilterType]="categoryFilterType"
          [allowedAdultMode]="allowedAdultMode"
          [filterModel]="guideFilterModel"
        ></app-guide-category-filter>
      </div>
      <app-channel *ngFor="let channel of guideChannels" [channel]="channel"></app-channel>
    </div>
    <div id="grid-container">
      <div id="current-time" #currentTime [class.hidden]="!isTodaySelected()"></div>
      <app-timeline #timeline></app-timeline>
      <div id="channel-schedule-container">
        <div style="position: relative">
          <app-channel-schedule
            style="display: block"
            *ngFor="let channel of guideChannels; index as i"
            [channel]="channel"
            [selectedDate]="selectedDate"
            [allowedAdultMode]="allowedAdultMode"
            [scheduleItemIntersectionObserver]="scheduleItemIntersectionObserver"
            [channelSchedulesIntersectionObserver]="channelSchedulesIntersectionObserver"
            [categoryTags]="categoryTags"
          ></app-channel-schedule>
        </div>
      </div>
    </div>
  </div>
  <app-day-selector id="day-selector" *ngIf="guideChannels" #daySelector></app-day-selector>
</div>

<div id="container" class="smarttv" *ngIf="isSmartTv">
  <div *ngIf="isSmartTv" id="guide-filters">
    <app-guide-category-filter
      #categoryFilter
      (filterChange)="guideFilterChange()"
      [categoryFilterType]="categoryFilterType"
      [filterModel]="guideFilterModel"
      [allowedAdultMode]="allowedAdultMode"
    ></app-guide-category-filter>
    <app-day-selector-smarttv></app-day-selector-smarttv>
    <app-clear-filter
      [filtersActive]="
        !isTodaySelected() || (guideFilterModel && guideFilterModel.areFiltersActive())
      "
      (clear)="clearAllFilters()"
    ></app-clear-filter>
  </div>
  <div id="guide-container" #guideContainer>
    <div id="channel-container">
      <div #channelContainer>
        <app-channel *ngFor="let channel of guideChannels" [channel]="channel"></app-channel>
      </div>
    </div>
    <div id="timeline-schedule-container" #timelineScheduleContainer>
      <app-timeline #timeline></app-timeline>
      <div id="schedule-container" #scheduleContainer class="smarttv-vertical-scroller">
        <div class="horizontal-scroller" #horizontalScroller>
          <div id="current-time" #currentTime [class.hidden]="!isTodaySelected()"></div>

          <app-channel-schedule
            style="display: block"
            *ngFor="let channel of guideChannels; index as i"
            [channel]="channel"
            [selectedDate]="selectedDate"
            [allowedAdultMode]="allowedAdultMode"
            [scheduleItemIntersectionObserver]="scheduleItemIntersectionObserver"
            [channelSchedulesIntersectionObserver]="channelSchedulesIntersectionObserver"
            [categoryTags]="categoryTags"
            [guideFocusEvent]="guideFocusEvent"
            [guideScrollEvent]="guideScrollEvent"
          ></app-channel-schedule>
        </div>
      </div>
    </div>
  </div>
</div>
