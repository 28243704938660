import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MenuService } from '../main-menu/menu.service';
import { SublevelMenuModel } from '../main-menu/menuDefinitions.model';
import { PlayerService } from '../../atv-core/services/player/player.service';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnInit {
  constructor(
    private menuService: MenuService,
    private router: Router,
    private playerService: PlayerService
  ) {}

  ngOnInit(): void {}

  getSubLevelMenus(): SublevelMenuModel[] {
    return this.menuService.getSubLevelMenus();
  }

  isActiveRoute(route: string): boolean {
    return this.router.url.startsWith(`/main/${route}`);
  }

  public isChromecastAvailable(): boolean {
    return this.playerService.isChromecastAvailable();
  }
}
