import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EpgApiService, EpgCategory } from '@atv-core/api/epg';
import { VodApiService } from '@atv-core/api/vod';
import { VodCategory } from '@atv-core/api/vod/vod-api.model';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { ConfigService, DetailTranslationKeys, ListTranslationKeys } from '@atv-bootstrap/services/config';
import { MessagesService } from '@atv-core/services/messages';
import {
  NavigationSections,
  SpatialNavigationService,
} from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { CategoryFilterModes, ContentFilters, FilterModel } from '@atv-shared/filters/filters.model';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';

interface BreadCrumbStackItem {
  tag: string;
  title: string;
  adult: boolean;
  hasSubCategories: boolean;
}

@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryFilterComponent implements AfterViewInit, OnDestroy {
  @Input()
  filterModel: FilterModel;

  @Input()
  allowedAdultMode: AdultMode;

  @Output()
  filterReady = new EventEmitter();

  @Output()
  filterChange = new EventEmitter();

  @ViewChild('filterWrapperRef')
  filterWrapperRef: ElementRef;

  @ViewChild('optionWrapperRef')
  optionWrapperRef: ElementRef;

  private clickedInside = false;

  selectedDayTitle = '';
  filterTitle = '';
  allText = '';
  optionsHidden = true;

  fetchingCategories = false;
  tempBreadCrumbStack: BreadCrumbStackItem[] = new Array<BreadCrumbStackItem>();
  breadCrumbStack: BreadCrumbStackItem[] = new Array<BreadCrumbStackItem>();

  showCategories: EpgCategory[];
  categories: EpgCategory[] = [];

  @ViewChildren('overlayDiv')
  overlayDiv: QueryList<ElementRef<HTMLDivElement>>;

  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnObservable: Observable<KeyboardEvent>;
  private returnSubscription: Subscription;
  private overlayDivSubscription: Subscription;

  constructor(
    private config: ConfigService,
    private epgApi: EpgApiService,
    private vodApi: VodApiService,
    private adultService: AdultService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private messagesService: MessagesService,
    private cdr: ChangeDetectorRef,
    private spatialNavigationService: SpatialNavigationService,
    private elRef: ElementRef,
  ) {
    this.filterTitle = this.config.getTranslation(ListTranslationKeys.lists_filter_category);
    this.allText = this.config.getTranslation(ListTranslationKeys.lists_filter_all_value);
    this.selectedDayTitle = this.filterTitle;

    if (this.isSmartTv) {
      this.returnObservable = fromEvent(document, 'keyup').pipe(
        filter((event: KeyboardEvent) =>
          this.spatialNavigationService.keyCodeIsReturn(event.keyCode),
        ),
      );
    }
  }

  resetFilter(): void {
    this.tryRemoveFilter(undefined);
    this.filterReady.emit();
  }

  ngAfterViewInit(): void {
    this.fetchCategorysAndTryInitFromQueryParams();

    this.overlayDivSubscription = this.overlayDiv.changes.subscribe(changes => {
      if (changes.length === 1) {
        // move to body to avoid issues with parent divs who have a transformation set
        // TODO create overlay component, add it to body and use ng-template
        document.body.appendChild(changes.first.nativeElement);
      }
    });
  }

  showOptions(): void {
    if (this.allowedAdultMode === AdultMode.true) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.getOptions();
        }, errorCallback: () => {
          this.messagesService.showErrorMessage(
            this.config.getTranslation(DetailTranslationKeys.detail_adult_warning),
          );
        },
      });
    } else {
      this.getOptions();
    }
  }

  private getOptions(): void {
    this.fetchCategories()
      .pipe(
        finalize(() => {
          this.checkBreadCrumbAndTempStack();

          this.optionsHidden = false;
          this.detectChanges();

          if (!this.isSmartTv) {
            setTimeout(() => {
              this.detectWidthChange();
            }, 0);
          } else {
            window.setTimeout(() => {
              this.spatialNavigationService.register(
                NavigationSections.FILTER_OPTIONS,
                '.filter-option-smarttv',
                { restrict: 'self-only' },
              );
              this.spatialNavigationService.setFocus(NavigationSections.FILTER_OPTIONS);

              this.returnSubscription = this.returnObservable.subscribe((event) => {
                event.cancelBubble = true;
                event.preventDefault();

                if (this.tempBreadCrumbStack.length === 0) {
                  this.hideOptions();
                } else {
                  const lastIndexWithSubcats = this.getLastIndexWithSubcats();
                  if (lastIndexWithSubcats === 0) {
                    this.tempBreadCrumbStack = new Array<BreadCrumbStackItem>();
                    this.setShowCategories();
                  } else {
                    this.goToTempBreadCrumb(lastIndexWithSubcats - 1);
                  }

                  this.spatialNavigationService.unregister(NavigationSections.FILTER_OPTIONS);
                  this.spatialNavigationService.register(
                    NavigationSections.FILTER_OPTIONS,
                    '.filter-option-smarttv',
                    { restrict: 'self-only' },
                  );
                  this.spatialNavigationService.setFocus(NavigationSections.FILTER_OPTIONS);
                }
              });
            }, 100);
          }
        }),
      )
      .subscribe();
  }

  hideOptions(): void {
    this.checkBreadCrumbAndTempStack();
    this.optionsHidden = true;
    this.detectChanges();

    if (!this.isSmartTv) {
      setTimeout(() => {
        this.detectWidthChange();
      }, 0);
    } else {
      this.spatialNavigationService.unregister(NavigationSections.FILTER_OPTIONS);
      this.spatialNavigationService.setFocus(NavigationSections.FILTERS);
    }

    if (this.returnSubscription) {
      this.returnSubscription.unsubscribe();
      this.returnSubscription = null;
    }
  }

  changeSelectedCategoryIndex(index): void {
    const item = this.showCategories[index];
    if (item.adult) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.doChangeSelectedCategoryIndex(item);
        },
        errorCallback: () => {
          this.messagesService.showErrorMessage(this.config.getTranslation(DetailTranslationKeys.detail_adult_warning));
        },
      });
    } else {
      this.doChangeSelectedCategoryIndex(item);
    }
  }

  tryRemoveFilter(e): void {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (this.allowedAdultMode === AdultMode.true) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.removeFilter();
        }, errorCallback: () => {
          this.messagesService.showErrorMessage(
            this.config.getTranslation(DetailTranslationKeys.detail_adult_warning),
          );
        },
      });
    } else {
      this.removeFilter();
    }
  }

  private removeFilter(): void {
    this.removeQueryParamForFilter();
    this.clearTempBreadCrumbs();
    this.selectedDayTitle = this.filterTitle;
    this.breadCrumbStack = this.tempBreadCrumbStack = new Array<BreadCrumbStackItem>();
    this.filterModel.categoryFilterOutputTags = undefined;
    this.filterModel.currentCategoryFilterIsAdult = false;
    this.filterChange.emit();
    this.detectChanges();
  }

  goToTempBreadCrumb(index): void {
    this.tempBreadCrumbStack = this.tempBreadCrumbStack.slice(0, index + 1);

    if (this.tempBreadCrumbStack && this.tempBreadCrumbStack.length > 0) {
      const item = this.tempBreadCrumbStack[this.tempBreadCrumbStack.length - 1];
      if (item.adult) {
        this.adultService.checkAdultMode({
          successCallback: () => {
            this.setShowCategories();
          },
          errorCallback: () => {
            this.removeQueryParamForFilter();
            this.addQueryParamForFilter(this.getTagsFromBreadCrumbStack());
            this.messagesService.showErrorMessage(this.config.getTranslation(DetailTranslationKeys.detail_adult_warning));
          },
        });
      } else {
        this.setShowCategories();
      }
    }
  }

  clearTempBreadCrumbs() {
    this.tempBreadCrumbStack = new Array<BreadCrumbStackItem>();
    this.setShowCategories();
  }

  getTempBreadCrumbs() {
    const crumbs = [];

    this.tempBreadCrumbStack.forEach((item) => {
      if (item.hasSubCategories) {
        crumbs.push(item.title);
      }
    });

    return crumbs;
  }

  checkBreadCrumbAndTempStack() {
    this.tempBreadCrumbStack = this.breadCrumbStack;

    if (
      this.tempBreadCrumbStack.length > 0 &&
      this.tempBreadCrumbStack[this.tempBreadCrumbStack.length - 1].hasSubCategories
    ) {
      this.tempBreadCrumbStack = this.breadCrumbStack = new Array<BreadCrumbStackItem>();
    }
  }

  isActiveCategory(index): boolean {
    if (
      this.breadCrumbStack.length > 0 &&
      !this.breadCrumbStack[this.breadCrumbStack.length - 1].hasSubCategories &&
      this.breadCrumbStack[this.breadCrumbStack.length - 1].tag === this.showCategories[index].tag
    ) {
      return true;
    }
    return false;
  }

  @HostListener('click', ['$event'])
  onClickedInside($event: Event): void {
    this.clickedInside = true;
  }

  @HostListener('document:click', ['$event'])
  onClickedOutside($event): void {
    if (!this.clickedInside && !this.optionsHidden) {
      this.hideOptions();
    }
    this.clickedInside = false;
  }

  private detectChanges(): void {
    // prevent change detection from firing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  private getLastIndexWithSubcats(): number {
    for (let i = this.tempBreadCrumbStack.length - 1; i >= 0; --i) {
      if (this.tempBreadCrumbStack[i].hasSubCategories) {
        return i;
      }
    }

    return null;
  }

  private fetchCategorysAndTryInitFromQueryParams(): void {
    const param = this.activeRoute.snapshot.queryParams[ContentFilters.CATEGORY];
    if (param) {
      if (this.categories.length === 0) {
        this.fetchCategories().subscribe((res) => {
          this.tryInitFromQueryParams(param);
        });
      } else {
        this.tryInitFromQueryParams(param);
      }
    } else {
      this.filterReady.emit();
    }
  }

  private tryInitFromQueryParams(param): void {
    const tagString = decodeURI(param);
    const tags = tagString.split('||');

    if (this.tagsSameAsBreadCrumb(tags)) {
      return;
    }

    if (this.queryParamsAreAdult(tags)) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.continueTryInitFromQueryParams(tags);
        },
        errorCallback: () => {
          this.messagesService.showErrorMessage(this.config.getTranslation(DetailTranslationKeys.detail_adult_warning));
          this.removeQueryParamForFilter();
          this.filterReady.emit();
        },
      });
    } else {
      this.continueTryInitFromQueryParams(tags);
    }
  }

  private queryParamsAreAdult(tags): boolean {
    let currentCategorys: EpgCategory[] = this.categories;
    let foundAdult = false;
    for (let i = 0; i < tags.length && !foundAdult; i++) {
      const category = currentCategorys.find((it) => it.tag === tags[i]);
      foundAdult = category?.adult;
      if (foundAdult) {
        return true;
      }
      if (category?.subcategories) {
        currentCategorys = category.subcategories;
      }
    }

    return foundAdult;
  }

  private continueTryInitFromQueryParams(tags): void {
    for (let i = 0; i < tags.length; i++) {
      const index = this.showCategories.findIndex((it) => it.tag === tags[i]);
      if (index === undefined || index < 0) {
        this.removeQueryParamForFilter();
        this.clearTempBreadCrumbs();
        this.selectedDayTitle = this.filterTitle;
        this.breadCrumbStack = this.tempBreadCrumbStack = new Array<BreadCrumbStackItem>();
        this.filterModel.categoryFilterOutputTags = undefined;
        this.filterModel.currentCategoryFilterIsAdult = false;
        this.filterReady.emit();
        return;
      }
      this.changeSelectedCategoryIndex(index);
    }
    this.filterModel.categoryFilterOutputTags = tags;
    this.filterModel.currentCategoryFilterIsAdult = this.queryParamsAreAdult(tags);
    this.filterReady.emit();
  }

  private tagsSameAsBreadCrumb(items: string[]): boolean {
    return !items.some(
      (item) => this.tempBreadCrumbStack.find((bcs) => bcs.tag === item) === undefined,
    );
  }

  private doChangeSelectedCategoryIndex(item: EpgCategory): void {
    this.addToBreadCrumbStack(item);
    if (
      this.tempBreadCrumbStack.length > 0 &&
      !this.tempBreadCrumbStack[this.tempBreadCrumbStack.length - 1].hasSubCategories
    ) {
      this.breadCrumbStack = this.tempBreadCrumbStack;
      this.selectedDayTitle = this.getSelectedTitle();
      this.hideOptions();
      this.addQueryParamForFilter(this.getTagsFromBreadCrumbStack());
      this.filterModel.categoryFilterOutputTags = this.getTagsFromBreadCrumbStack();
      this.filterModel.currentCategoryFilterIsAdult = this.queryParamsAreAdult(
        this.filterModel.categoryFilterOutputTags,
      );
      this.filterChange.emit();
    } else {
      this.showCategories = item.subcategories;
      if (!this.isSmartTv) {
        setTimeout(() => {
          this.detectWidthChange();
        }, 0);
      } else if (!this.optionsHidden) {
        this.spatialNavigationService.unregister(NavigationSections.FILTER_OPTIONS);
        this.spatialNavigationService.register(
          NavigationSections.FILTER_OPTIONS,
          '.filter-option-smarttv',
          { restrict: 'self-only' },
        );
        this.spatialNavigationService.setFocus(NavigationSections.FILTER_OPTIONS);
      }
    }

    this.detectChanges();
  }

  private addQueryParamForFilter(breadCrumbs): void {
    const filter = {};
    filter[ContentFilters.CATEGORY] = undefined;

    if (breadCrumbs && breadCrumbs.length > 0) {
      filter[ContentFilters.CATEGORY] = encodeURI(breadCrumbs.join('||'));
    }

    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: filter,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  detectWidthChange() {
    if (this.filterWrapperRef) {
      if (!this.optionWrapperRef) {
        this.filterWrapperRef.nativeElement.style.width = 'auto';
      } else {
        const buttonConfigEl = this.optionWrapperRef.nativeElement;
        const width = buttonConfigEl.getBoundingClientRect().width;
        if (width) {
          this.filterWrapperRef.nativeElement.style.width = width + 'px';
        } else {
          this.filterWrapperRef.nativeElement.style.width = 'auto';
        }
      }
    }
  }

  private getTagsFromBreadCrumbStack(): string[] {
    const tags = [];
    this.breadCrumbStack.forEach((item) => tags.push(item.tag));
    return tags;
  }

  private fetchCategories(): Observable<any> {
    this.categories = [];
    this.fetchingCategories = true;
    if (this.filterModel.categoryFilterMode === CategoryFilterModes.EPG) {
      return this.fetchEpgCategories();
    } else if (this.filterModel.categoryFilterMode === CategoryFilterModes.VOD) {
      return this.fetchVodCategories();
    }
  }

  private removeQueryParamForFilter(): void {
    const filter = {};
    filter[ContentFilters.CATEGORY] = undefined;
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: filter,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private getSelectedTitle(): string {
    const title = [];

    this.breadCrumbStack.forEach((item) => title.push(item.title));
    return title.join(' / ');
  }

  private addToBreadCrumbStack(item: EpgCategory): void {
    let hasSubCats = false;

    if (item.subcategories && item.subcategories.length > 0) {
      hasSubCats = true;
    }

    if (
      this.tempBreadCrumbStack.length > 0 &&
      !this.tempBreadCrumbStack[this.tempBreadCrumbStack.length - 1].hasSubCategories
    ) {
      this.tempBreadCrumbStack.pop();
    }

    this.tempBreadCrumbStack.push({
      tag: item.tag,
      title: item.title,
      adult: item.adult,
      hasSubCategories: hasSubCats,
    });
  }

  private fetchVodCategories(): Observable<any> {
    return this.vodApi.getCategories().pipe(
      map((result) => {
        this.fetchingCategories = false;

        let cats: VodCategory[] = result;

        if (this.filterModel.categoryFilterRoot && this.filterModel.categoryFilterRoot.length > 0) {
          this.filterModel.categoryFilterRoot.forEach((filterLevel, index) => {
            if (cats) {
              const tmpCat = cats.find((cat) => cat.tag === filterLevel);
              if (tmpCat.subcategories && tmpCat.subcategories.length > 0) {
                cats = tmpCat.subcategories;
              } else {
                cats = [tmpCat];
              }
            }
          });
        }


        if (cats) {
          if (
            this.filterModel.categoryFilterRoot[0] !== 'adult' ||
            (this.filterModel.categoryFilterRoot[0] === 'adult' &&
             this.adultService.showAdult(this.allowedAdultMode))
          ) {
            this.categories = cats;
          }
        }

        this.tempBreadCrumbStack = this.breadCrumbStack;
        this.setShowCategories();
        return;
      }),
    );
  }

  private fetchEpgCategories(): Observable<any> {
    return this.epgApi.getCategories().pipe(
      map((result) => {
        this.fetchingCategories = false;
        this.categories = result;
        this.tempBreadCrumbStack = this.breadCrumbStack;
        this.setShowCategories();
        return;
      }),
    );
  }

  private setShowCategories(): void {
    const showAdult = this.adultService.showAdult(this.allowedAdultMode);

    if (!showAdult) {
      this.showCategories = this.filterAdult(this.categories);
    } else {
      this.showCategories = this.categories;
    }

    for (let i = 0; i < this.tempBreadCrumbStack.length; i++) {
      if (this.tempBreadCrumbStack[i].hasSubCategories) {
        const category = this.showCategories.find(
          (item) => item.tag === this.tempBreadCrumbStack[i].tag,
        );
        if (category) {
          this.showCategories = category.subcategories.filter(c => showAdult || !c.adult);

        }
      } else {
        return;
      }
    }
    this.detectChanges();
    setTimeout(() => {
      this.detectWidthChange();
    }, 0);
  }

  private filterAdult(categories: EpgCategory[]): EpgCategory[] {
    const result = categories.filter(c => !c.adult);

    result.forEach(c => c.subcategories = this.filterAdult(c.subcategories));

    return result;
  }

  ngOnDestroy(): void {
    this.returnSubscription?.unsubscribe();
    this.overlayDivSubscription?.unsubscribe();
  }
}
