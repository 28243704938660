<div style="display: flex; height: 100%">
  <app-main-menu style="z-index: 11" *ngIf="!isSmartTv"></app-main-menu>
  <app-main-menu-smarttv *ngIf="isSmartTv"></app-main-menu-smarttv>

  <div class="main-content" [class.menu-open]="isMenuFocused">
    <ng-container *ngIf="!isSmartTv">
      <app-top-menu></app-top-menu>
      <router-outlet></router-outlet>
    </ng-container>

    <ng-container *ngIf="isSmartTv">
      <app-top-bar-smarttv *ngIf="isSmartTv" style="flex: 0"></app-top-bar-smarttv>
      <div class="main-content-smarttv" style="overflow: hidden">
        <div class="smarttv-vertical-scroller">
          <router-outlet></router-outlet>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<app-main-backdrop></app-main-backdrop>
