import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '@atv-core/services/session/session.service';

@Injectable()
export class AuthorizationGuard implements CanActivate {
  constructor(private sessionService: SessionService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.sessionService.anonymousProfileIsActive() || this.sessionService.activeUserInUse()) {
      return true;
    }

    this.router.navigate(['']);
    return false;
  }
}
