import { Injectable } from '@angular/core';
import { ConfigService, SettingsKeys } from '@atv-bootstrap/services/config';
import { PopupTranslationKeys } from '@atv-bootstrap/services/config/config.model';
import { EpgApiService } from '@atv-core/api/epg';
import { HistoryApiService, ReminderContentTypes, ReminderElement } from '@atv-core/api/history';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { CacheStorage } from '@atv-core/services/cache/cacheStorage';
import { SessionService } from '@atv-core/services/session';
import { DeviceSettings } from '@atv-core/utility/constants/shared';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import {
  ReminderWorkerMessage,
  ReminderWorkerMessageItem,
  ReminderWorkerMessageTypes,
} from '@atv-core/services/cache/reminder/reminder.model-shared';
import { ReminderModel } from './reminder.model';

@Injectable({
  providedIn: 'root',
})
export class ReminderCacheService {
  private reloadingCache = false;
  private cache = new CacheStorage();
  private cachePromise: Observable<unknown> = undefined;
  private expirationTime = 0;

  private beforeReminderTime = 0;

  constructor(
    private historyApi: HistoryApiService,
    private config: ConfigService,
    private epgApi: EpgApiService,
    private auth: AuthorizationService,
    private sessionService: SessionService,
  ) {
    this.sessionService.clearAllCachesEvent.subscribe(() => {
      this.reset();
    });

    if (worker) {
      worker.onmessage = (e) => {
        const message: ReminderWorkerMessage = e.data;
        if (message.type === ReminderWorkerMessageTypes.NOTIFY && message.items) {
          if (Notification.permission === 'granted') {
            this.showNotifications(message.items);
          } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
              if (permission === 'granted') {
                this.showNotifications(message.items);
              }
            });
          }
        }
      };
    }
  }

  public reset(): void {
    const deviceId = this.sessionService.getDeviceId();
    if (deviceId && this.auth.isAuthorized()) {
      this.historyApi
        .getSettingForDevice(deviceId, DeviceSettings.reminder_before_time)
        .pipe(
          tap((result) => {
            if (result && result.value) {
              this.beforeReminderTime = parseInt(result.value, 10);
            }
          }),
          finalize(() => {
            this.clearCache();
            this.checkCache();
            this.cachePromise.subscribe();
          }),
        )
        .subscribe();
    } else {
      this.clearCache();
      this.checkCache();
      this.cachePromise.subscribe();
    }
  }

  public renewReminderWorkerValues(): void {
    if (!worker) {
      return;
    }
    let values = this.cache.values();
    if (values) {
      values = values.map((value: ReminderModel) => {
        return this.createReminderWorkerItem(value);
      });
      worker.postMessage({ type: ReminderWorkerMessageTypes.RENEW, items: values });
    }
  }

  public addReminderWorkerValues(value: ReminderModel): void {
    if (!worker) {
      return;
    }

    if (value) {
      const item = this.createReminderWorkerItem(value);
      worker.postMessage({ type: ReminderWorkerMessageTypes.RENEW, items: [item] });
    }
  }

  public clearReminderWorkerValues(): void {
    if (!worker) {
      return;
    }
    worker.postMessage({ type: ReminderWorkerMessageTypes.CLEAR, items: undefined });
  }

  public getReminders(): Observable<ReminderModel[]> {
    this.checkCache();

    return this.cachePromise.pipe(
      map(() => {
        let result: ReminderModel[] = this.cache.values();

        result = result.filter((reminder) => this.reminderIsValid(reminder));

        result.sort((a, b) => {
          const aStart = SharedUtilityService.timeStringToMs(a.schedule.schedule.published.start);
          const bStart = SharedUtilityService.timeStringToMs(b.schedule.schedule.published.start);
          const aEnd = SharedUtilityService.timeStringToMs(a.schedule.schedule.published.end);
          const bEnd = SharedUtilityService.timeStringToMs(b.schedule.schedule.published.end);
          if (aStart < bStart) {
            return -1;
          } else if (aStart > bStart) {
            return 1;
          } else {
            if (aEnd < bEnd) {
              return -1;
            } else if (aEnd > bEnd) {
              return 1;
            } else {
              return 0;
            }
          }
        });

        return result;
      }),
    );
  }

  public getReminder(type: ReminderContentTypes, id: string): Observable<ReminderModel> {
    if (type !== ReminderContentTypes.SCHEDULE) {
      return of(undefined);
    }

    this.checkCache();
    return this.cachePromise.pipe(
      map(() => {
        const reminder = this.cache.get(id);
        return this.reminderIsValid(reminder) ? reminder : undefined;
      }),
    );
  }

  public toggleReminder(type: ReminderContentTypes, id: string): Observable<boolean> {
    if (type !== ReminderContentTypes.SCHEDULE) {
      return of(undefined);
    }

    this.checkCache();

    return this.cachePromise.pipe(
      map(() => {
        if (this.cache.get(id)) {
          this.deleteReminder(id);
          return false;
        } else {
          this.setReminder(id);
          return true;
        }
      }),
    );
  }

  private showNotifications(reminderWorkerItems: ReminderWorkerMessageItem[]): void {
    reminderWorkerItems.forEach(item => {
      const notificationOptions: NotificationOptions = {
        body: item.body,
        badge: `${window.location.origin}/assets/theme/images/logo_notification.png`,
        icon: `${window.location.origin}/assets/theme/images/logo_notification.png`,
      };
      new Notification(item.title, notificationOptions);
    });
  }

  private checkCache(): void {
    if (new Date().getTime() > this.expirationTime) {
      this.clearCache();
    }

    this.cachePromise = this.cachePromise || this.fillCache();
  }

  private fillCache(): Observable<unknown> {
    if (!this.auth.isAuthorized()) {
      return of([]);
    }

    return this.historyApi.getReminders(this.sessionService.getActiveProfileId()).pipe(
      switchMap((result) => {
        if (!result) {
          return of(undefined);
        }
        this.expirationTime =
          new Date().getTime() +
          this.config.getSettingNumber(SettingsKeys.reminderCacheExpiration, 120000);

        const scheduleRequests = [];

        // get schedules for all elements and only add elements to cache that have a schedule in the future
        result.forEach((element) => {
          if (element.type === ReminderContentTypes.SCHEDULE) {
            scheduleRequests.push(
              this.epgApi.getSchedule(element.id).pipe(
                map((res) => {
                  if (
                    res &&
                    res.schedule &&
                    res.schedule.published &&
                    SharedUtilityService.timeStringToMs(res.schedule.published.start) >
                    new Date().getTime()
                  ) {
                    this.cache.put(element.id, new ReminderModel(element, res));
                  }
                }),

                // prevent erroring call to abort all other calls
                catchError(() => of(undefined)),
              ),
            );
          }
        });

        this.reloadingCache = false;
        if (scheduleRequests.length === 0) {
          return of(undefined);
        }
        return forkJoin(scheduleRequests).pipe(
          map((rest) => {
            this.renewReminderWorkerValues();
          }),
        );
      }),
      catchError((e) => {
        this.expirationTime = 0;
        this.reloadingCache = false;
        throw e;
      }),
      shareReplay(),
    );
  }

  private createReminderWorkerItem(value: ReminderModel): ReminderWorkerMessageItem {
    let popupReminderBody = this.config.getTranslation(PopupTranslationKeys.popup_reminder_text);
    if (popupReminderBody && value.schedule && value.schedule.program) {
      popupReminderBody = popupReminderBody.replace('$1', value.schedule.program.title);
    } else {
      popupReminderBody =
        value.schedule && value.schedule.program ? value.schedule.program.title : '';
    }
    let time =
      value.schedule &&
      value.schedule.schedule &&
      value.schedule.schedule.published &&
      value.schedule.schedule.published.start
        ? parseInt(value.schedule.schedule.published.start, 10) * 1000 // seconds to ms
        : 0;
    if (value.minutes) {
      time -= value.minutes * 1000 * 60;
      time = Math.max(time, 0);
    }

    return {
      body: popupReminderBody,
      title: this.config.getTranslation(PopupTranslationKeys.popup_reminder_title),
      time,
    };
  }

  private setReminder(id: string): void {
    if (!this.auth.isAuthorized()) {
      return;
    }
    this.epgApi.getSchedule(id).subscribe((result) => {
      const reminder: ReminderElement = { id, type: ReminderContentTypes.SCHEDULE };
      this.cache.put(id, new ReminderModel(reminder, result));
      this.addReminderWorkerValues(new ReminderModel(reminder, result));
    });

    this.historyApi
      .setReminder(
        this.sessionService.getActiveProfileId(),
        id,
        (this.beforeReminderTime || 0) / 1000 / 60,
      )
      .subscribe();
  }

  private deleteReminder(id: string): void {
    this.checkCache();

    this.cachePromise.subscribe(() => {
      this.cache.remove(id);
      this.renewReminderWorkerValues();
    });

    this.historyApi.deleteReminder(this.sessionService.getActiveProfileId(), id).subscribe();
  }

  private reminderIsValid(reminder: ReminderModel): boolean {
    if (
      reminder &&
      reminder.schedule &&
      reminder.schedule.schedule &&
      reminder.schedule.schedule.published
    ) {
      return (
        SharedUtilityService.timeStringToMs(reminder.schedule.schedule.published.start) >
        new Date().getTime()
      );
    }

    return false;
  }

  private clearCache(): void {
    if (this.reloadingCache) {
      return;
    }
    this.cache = new CacheStorage();
    this.expirationTime = 0;
    this.cachePromise = undefined;
    this.reloadingCache = true;

    this.clearReminderWorkerValues();
  }
}

let worker: Worker;
if (typeof Worker !== 'undefined' && 'Notification' in window) {
  worker = new Worker(new URL('./reminder.worker.ts', import .meta.url), { type: 'module' });
}
