import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { HistoryApiService } from '@atv-core/api/history/history-api.service';
import { RecordingApiService } from '@atv-core/api/recording';
import { DeleteRecordingsQueryParams, Recording, RecordingOptions, Subscription } from '@atv-core/api/recording/recording-api.model';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { CacheStorage } from '@atv-core/services/cache/cacheStorage';
import { ErrorTranslationKeys, RecordingTranslationKeys, SettingsKeys } from '@atv-bootstrap/services/config';
import { ConfigService } from '@atv-bootstrap/services/config/config.service';
import { LogErrorInfo, RecordingLogInfo } from '@atv-core/services/log/log.model';
import { MessagesService } from '@atv-core/services/messages/messages.service';
import { SessionService } from '@atv-core/services/session';
import { AtvFeatures } from '@atv-core/utility/constants/atv_features';
import { CustomerSettings, PlatformType } from '@atv-core/utility/constants/shared';
import { RecordingEpgInfo } from '@atv-shared/buttons/create-edit-recording/create-edit-recording-input.model';
import { SubscriptionSettingsModel, SubscriptionTypes } from '@atv-shared/settings/subscription-settings/subscription-settings.model';
import { environment } from '@env/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { LogService } from './../../log/log.service';
import { RecordingModel } from './recording.model';
import { RecordingAvailableSpace, RecordingPageTypes, StbCacheModel } from './stb-cache.model';

export class PopupEvent {
  recordingPageType: RecordingPageTypes;
  serieRecordings: RecordingModel[];
  routerParams: Params;
}

@Injectable({ providedIn: 'root' })
export class StbCacheService {
  // TODO rewrite with BehaviorSubject
  stbRecordingChange = new EventEmitter();
  showDeleteAllRecordingsPopup = new EventEmitter<PopupEvent>();
  private cache = new CacheStorage();
  private defaultDeviceId: string;
  private neededTranslations = {};

  constructor(
    private config: ConfigService,
    private recordingApi: RecordingApiService,
    private historyApi: HistoryApiService,
    private sessionService: SessionService,
    private messagesService: MessagesService,
    private log: LogService,
    private auth: AuthorizationService,
  ) {
    this.sessionService.clearAllCachesEvent.subscribe(() => {
      this.clearCacheForAllDevices();
    });
  }

  getRecordingsForDevice(deviceId: string, allowAdult: boolean): Observable<RecordingModel[]> {
    if (this.stbHasRecording(deviceId)) {
      const stb = this.checkCache(deviceId);

      return stb.cachePromise.pipe(
        map((e) => {
          return stb.getRecordings(allowAdult);
        }),
      );
    }
    return of([]);
  }

  getRecordingsForDeviceForType(
    deviceId: string,
    type: RecordingPageTypes,
    adultAllowed: boolean,
  ): Observable<RecordingModel[]> {
    if (this.stbHasRecording(deviceId)) {
      const stb = this.checkCache(deviceId);

      return stb.cachePromise.pipe(
        map((e) => {
          return stb.getRecordingsForType(type, adultAllowed);
        }),
      );
    }
    return of([]);
  }

  getRecordingsForDeviceForTypeForSeries(
    deviceId: string,
    seriesId: string,
    type: RecordingPageTypes,
    adultAllowed: boolean,
  ): Observable<RecordingModel[]> {
    if (this.stbHasRecording(deviceId)) {
      const stb = this.checkCache(deviceId);

      return stb.cachePromise.pipe(
        map((e) => {
          return stb
            .getRecordingsForType(type, adultAllowed)
            .filter((recording) => recording.series === seriesId);
        }),
      );
    }
    return of([]);
  }

  private stbHasRecording(deviceId: string): boolean {
    const stb = this.sessionService.getStbsList().getStbs(true).find((s) => s.id === deviceId);
    return stb ? stb.recording !== 'NONE' : false;
  }

  getRecordingForProgram(programId, channelId, allowAdult: boolean): Observable<RecordingModel> {
    if (!environment.atv_feature_list.includes(AtvFeatures.RECORDING)) {
      return of(undefined);
    }

    const npvrBox = this.sessionService.getStbsList().getNpvrStb();
    return this.getRecordingsForAllDevices(allowAdult).pipe(
      map((recordings) => {
        let recording;
        recordings.some((rec) => {
          if (rec.program === programId && (channelId === undefined || rec.channel === channelId)) {
            recording = rec;
            return npvrBox !== undefined && recording.device === npvrBox.id;
          }
        });

        if (
          recording &&
          recording.device !== npvrBox.id &&
          recording.device !== this.defaultDeviceId
        ) {
          recordings.some((rec) => {
            if (
              rec.program === programId &&
              (channelId === undefined || rec.channel === channelId)
            ) {
              recording = rec;
              if (rec.device === this.defaultDeviceId) {
                return true;
              }
            }
          });
        }
        return recording;
      }),
    );
  }

  getRecordingsForSeries(seriesId, allowAdult: boolean): Observable<RecordingModel[]> {
    if (!environment.atv_feature_list.includes(AtvFeatures.RECORDING)) {
      return of(undefined);
    }

    return this.getRecordingsForAllDevices(allowAdult).pipe(
      map((recordings) => {
        return recordings.filter((rec) => {
          return rec.series === seriesId;
        });
      }),
    );
  }

  getRecordingForId(recordingId: string, adultAllowed: boolean): Observable<RecordingModel> {
    if (!environment.atv_feature_list.includes(AtvFeatures.RECORDING)) {
      return of(undefined);
    }

    return this.getRecordingsForAllDevices(adultAllowed).pipe(
      map((recordings) => {
        return recordings.find((r) => r.identifier === recordingId);
      }),
    );
  }

  getRecordingNoCache(device: string, identifier: string): Observable<RecordingModel> {
    if (!environment.atv_feature_list.includes(AtvFeatures.RECORDING)) {
      return of(undefined);
    }

    return this.recordingApi.getRecording(device, identifier).pipe(
      map((recording) => {
        return new RecordingModel(
          recording,
          this.config.getSettingNumber(SettingsKeys.npvrRecordingAboutToExpireInterval, 7),
        );
      }),
    );
  }

  getAvailableUsedMaxSpace(deviceId): Observable<RecordingAvailableSpace> {
    const stb = this.checkCache(deviceId);
    return stb.cachePromise.pipe(
      map(() => {
        return stb.getAvailableUsedMaxSpace();
      }),
    );
  }

  getRecordingsForAllDevices(allowAdult: boolean): Observable<RecordingModel[]> {
    let recordings: RecordingModel[] = [];
    const requests = [];

    this.sessionService
      .getStbsList()
      .getStbs()
      .forEach((stb) => {
        if (stb.type !== PlatformType.ANDROIDTV_STB) {
          requests.push(
            this.getRecordingsForDevice(stb.id, allowAdult).pipe(
              catchError((e) => of([])),
              map((recs) => {
                recordings = recordings.concat(recs);
              }),
            ),
          );
        }
      });

    if (requests.length === 0) {
      return of([]);
    }

    return forkJoin(requests).pipe(
      map(() => {
        return recordings;
      }),
    );
  }

  getSubscription(recording: RecordingModel): Observable<Subscription> {
    const stb = this.checkCache(recording.device);
    return stb.cachePromise.pipe(
      map(() => {
        if (recording.programSubscription !== undefined) {
          return stb.getProgramSubscription(recording.programSubscription, recording.channel);
        } else if (recording.seasonSubscription !== undefined) {
          return stb.getSeasonSubscription(recording.seasonSubscription, recording.channel);
        } else if (recording.seriesSubscription !== undefined) {
          return stb.getSeriesSubscription(recording.seriesSubscription, recording.channel);
        }
      }),
    );
  }

  public clearCacheForDevice(deviceId: string): StbCacheModel {
    let stb: StbCacheModel = this.cache.get(deviceId);
    if (stb && stb.reloadingCache) {
      return stb;
    }
    this.cache.put(deviceId, new StbCacheModel(this.neededTranslations));
    stb = this.cache.get(deviceId);
    stb.expirationTime = 0;
    stb.cachePromise = undefined;
    stb.reloadingCache = true;
    return stb;
  }

  public createSubscription(
    subscriptionSettings: SubscriptionSettingsModel,
    recordingEpgInfo: RecordingEpgInfo,
    recordingLogInfo: RecordingLogInfo,
  ): Observable<unknown> {
    const recordingOptions: RecordingOptions = {
      beginBuffer: parseInt(subscriptionSettings.beginBuffer, 10),
      endBuffer: parseInt(subscriptionSettings.endBuffer, 10),
      deleteProtected: subscriptionSettings.deleteProtected,
    };
    let request;
    if (subscriptionSettings.type === SubscriptionTypes.EPISODE) {
      request = this.recordingApi.createProgramSubscription(
        subscriptionSettings.deviceId,
        recordingEpgInfo.programId,
        recordingEpgInfo.channel.id,
        recordingOptions,
      );
    } else if (subscriptionSettings.type === SubscriptionTypes.SEASON) {
      request = this.recordingApi.createSeasonSubscription(
        subscriptionSettings.deviceId,
        recordingEpgInfo.seasonId,
        recordingEpgInfo.channel.id,
        recordingOptions,
      );
    } else if (subscriptionSettings.type === SubscriptionTypes.SERIES) {
      request = this.recordingApi.createSeriesSubscription(
        subscriptionSettings.deviceId,
        recordingEpgInfo.seriesId,
        recordingEpgInfo.channel.id,
        recordingOptions,
      );
    }

    return request.pipe(
      map(() => {
        this.log.recPlanned(recordingLogInfo, undefined);
        this.clearCacheForDevice(subscriptionSettings.deviceId);
        this.stbRecordingChange.emit();
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.log.recPlanned(recordingLogInfo, new LogErrorInfo(errorResponse));
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_recording_start_failed),
        );
        throw errorResponse;
      }),
    );
  }

  public deleteRecording(
    recording: RecordingModel,
    recordingLoginInfo: RecordingLogInfo,
  ): Observable<void> {
    return this.recordingApi.deleteRecording(recording).pipe(
      map(() => {
        this.log.recRemoved(recordingLoginInfo, undefined);
        this.clearCacheForDevice(recording.device);
        this.stbRecordingChange.emit();
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.log.recRemoved(recordingLoginInfo, new LogErrorInfo(errorResponse));
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_recording_delete_failed),
        );
        throw errorResponse;
      }),
    );
  }

  public deleteRecordings(
    recording: RecordingModel,
    recordingLoginInfo: RecordingLogInfo,
    params: DeleteRecordingsQueryParams,
  ): Observable<unknown> {
    return this.recordingApi.deleteRecordings(recording, params).pipe(
      map(() => {
        this.log.recRemoved(recordingLoginInfo, undefined);
        this.clearCacheForDevice(recording.device);
        this.stbRecordingChange.emit();
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.log.recRemoved(recordingLoginInfo, new LogErrorInfo(errorResponse));
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_recording_delete_failed),
        );
        throw errorResponse;
      }),
    );
  }

  public editRecording(recording: Recording, recordingLogInfo: RecordingLogInfo): Observable<void> {
    return this.recordingApi.editRecording(recording).pipe(
      map(() => {
        this.log.recChanged(recordingLogInfo, undefined);
        this.clearCacheForDevice(recording.device);

        this.stbRecordingChange.emit();
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.log.recChanged(recordingLogInfo, new LogErrorInfo(errorResponse));
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_recording_edit_failed),
        );
        throw errorResponse;
      }),
    );
  }

  public cancelSubscription(
    subType: SubscriptionTypes,
    recording: RecordingModel,
    recordingLogInfo: RecordingLogInfo,
  ): Observable<void> {
    const requests = [];

    if (recording.isInProgress()) {
      requests.push(
        this.recordingApi.deleteRecording(recording).pipe(
          map(() => {
            this.log.recRemoved(recordingLogInfo, undefined);
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.log.recRemoved(recordingLogInfo, new LogErrorInfo(errorResponse));

            throw errorResponse;
          }),
        ),
      );
    }

    if (subType === SubscriptionTypes.EPISODE) {
      requests.push(this.recordingApi.deleteProgramSubscription(recording));
    } else if (subType === SubscriptionTypes.SEASON) {
      requests.push(this.recordingApi.deleteSeasonSubscription(recording));
    } else if (subType === SubscriptionTypes.SERIES) {
      requests.push(this.recordingApi.deleteSeriesSubscription(recording));
    }

    return forkJoin(requests).pipe(
      map(() => {
        this.log.recCancel(recordingLogInfo, undefined);

        this.clearCacheForDevice(recording.device);
        this.stbRecordingChange.emit();
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.log.recCancel(recordingLogInfo, new LogErrorInfo(errorResponse));

        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_recording_cancel_failed),
        );
        this.clearCacheForDevice(recording.device);
        this.stbRecordingChange.emit();
        throw errorResponse;
      }),
    );
  }

  public editSubscription(
    oldDevice: string,
    type: SubscriptionTypes,
    subscription: Subscription,
    recordingLogInfo: RecordingLogInfo,
  ): Observable<void> {
    let request;
    if (type === SubscriptionTypes.EPISODE) {
      request = this.recordingApi.editProgramSubscription(oldDevice, subscription);
    } else if (type === SubscriptionTypes.SEASON) {
      request = this.recordingApi.editSeasonSubscription(oldDevice, subscription);
    } else if (type === SubscriptionTypes.SERIES) {
      request = this.recordingApi.editSeriesSubscription(oldDevice, subscription);
    }

    if (!request) {
      return of(undefined);
    }

    return request.pipe(
      map(() => {
        this.log.recChanged(recordingLogInfo, undefined);
        this.clearCacheForDevice(oldDevice);
        this.clearCacheForDevice(subscription.device);
        this.stbRecordingChange.emit();
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.log.recChanged(recordingLogInfo, new LogErrorInfo(errorResponse));
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_recording_edit_failed),
        );
        throw errorResponse;
      }),
    );
  }

  public clearCacheForAllDevices(): void {
    this.cache.clearCache();
  }

  private checkCache(deviceId: string): StbCacheModel {
    if (Object.keys(this.neededTranslations).length === 0) {
      this.neededTranslations[
        RecordingTranslationKeys.recordings_minutes_available
        ] = this.config.getTranslation(RecordingTranslationKeys.recordings_minutes_available);
      this.neededTranslations[
        RecordingTranslationKeys.recordings_hours_available
        ] = this.config.getTranslation(RecordingTranslationKeys.recordings_hours_available);
      this.neededTranslations[
        RecordingTranslationKeys.recordings_free_space_stb
        ] = this.config.getTranslation(RecordingTranslationKeys.recordings_free_space_stb);
    }

    let stb: StbCacheModel = this.cache.get(deviceId);

    // cache  expired
    if (stb === undefined || new Date().getTime() > stb.expirationTime) {
      stb = this.clearCacheForDevice(deviceId);
    }
    stb.cachePromise = stb.cachePromise || this.fillCache(deviceId);
    return stb;
  }

  private fillCache(deviceId): Observable<unknown> {
    const stb: StbCacheModel = this.cache.get(deviceId);

    return this.recordingApi.getDeviceRecordingsAndSubScriptions(deviceId).pipe(
      switchMap((result) => {
        stb.expirationTime =
          new Date().getTime() +
          this.config.getSettingNumber(SettingsKeys.recordingCacheExpiration, 120000);

        stb.setRecordings(
          result.recordings.map(
            (recording) =>
              (new RecordingModel(
                recording,
                this.config.getSettingNumber(SettingsKeys.npvrRecordingAboutToExpireInterval, 7),
              )),
          ),
        );
        stb.setSubcriptions(result.subscriptions);
        stb.setStatus(result.status);

        stb.reloadingCache = false;

        return this.historyApi.getCustomerSetting(CustomerSettings.default_recording_device).pipe(
          map((setting) => {
            this.defaultDeviceId =
              (setting && setting.value) || this.sessionService.getStbsList().getDefaultStb().id;
          }),
          catchError(() => {
            this.defaultDeviceId = this.sessionService.getStbsList().getDefaultStb().id;
            return of(undefined);
          }),
        );
      }),
      catchError((e) => {
        stb.expirationTime = 0;
        stb.reloadingCache = false;
        throw e;
      }),
      shareReplay(),
    );
  }
}
