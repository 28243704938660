<div class="overlay" [hidden]="!showExitPopup" #container>
  <div class="overlay-dialog button-dialog">
    {{ exitPopupTitle }}

    <div class="dialog-buttons">
      <div class="button-title" (smartTvKeyDownEnter)="closeExitPopup()">
        {{ noButtonText }}
      </div>
      <div class="button-title" (smartTvKeyDownEnter)="exitApp()">{{ yesButtonText }}</div>
    </div>
  </div>
</div>
