import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '@atv-bootstrap/services/config/config.service';
import moment from 'moment';

import { DaySelectorService } from '../day-selector.service';

@Component({
  selector: 'app-day-selector-element',
  templateUrl: './day-selector-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaySelectorElementComponent implements OnInit {
  private _date: moment.Moment;
  fullDayStr: string;
  shortDayStr: string;
  dateStr: string;
  isToday = false;
  private today = moment().startOf('day');

  constructor(
    private configService: ConfigService,
    private daySelectorService: DaySelectorService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.daySelectorService.dateSelectedEvent.subscribe(() => this.cdr.detectChanges());
  }

  @Input()
  set date(value: moment.Moment) {
    this._date = value;
    this.dateStr = this.daySelectorService.getDateString(value);
    if (this.today.isSame(value)) {
      this.isToday = true;
    }

    this.fullDayStr = this.daySelectorService.getFullDayString(value);
    this.shortDayStr = this.daySelectorService.getShortDayString(value);
  }

  isDaySelected(): boolean {
    return (
      this.daySelectorService.getSelectedDate() &&
      this.daySelectorService.getSelectedDate().isSame(this._date)
    );
  }
}
