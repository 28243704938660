<div class="top-bar">
  <div class="top-nav">
    <div
      *ngFor="let subLevel of getSubLevelMenus()"
      [class.hidden]="!isActiveRoute(subLevel.forTopLevel)"
      class="menu-sub-level"
    >
      <div
        *ngFor="let item of subLevel.subLevelMenuItems"
        class="menu-sub-level-item"
        routerLinkActive="active"
        routerLink="{{ item.route }}"
      >
        <span>{{ item.text }}</span>
      </div>
    </div>
  </div>
  <google-cast-launcher
    id="google-cast-launcher"
    *ngIf="isChromecastAvailable()"
  ></google-cast-launcher>
  <div class="logo" routerLink="/main"><img src="./assets/theme/images/logo.png" /></div>
</div>
