import { Component, OnInit } from '@angular/core';
import { DetailOverlayService } from '@atv-core/services/detail/detail-overlay.service';
import { MiniDetailService } from '@atv-core/services/mini-detail/mini-detail.service';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

export interface MiniDetailModel {
  favoriteIcon?: string;
  title?: string;
  icons: string[];
  row1?: string;
  row2?: string;
  progressPercentage?: number;
  description?: string;
  episodeTitle?: string;
}

@Component({
  selector: 'app-mini-detail',
  templateUrl: './mini-detail.component.html',
  styleUrls: ['./mini-detail.component.scss'],
})
export class MiniDetailComponent implements OnInit {
  public currentMiniDetail: MiniDetailModel;

  constructor(
    private miniDetailService: MiniDetailService,
    private detailService: DetailOverlayService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.miniDetailService.miniDetailEvent
      .pipe(debounceTime(100))
      .subscribe((miniDetail: Observable<MiniDetailModel>) => {
        if (!miniDetail) {
          this.currentMiniDetail = undefined;
        } else {
          miniDetail.subscribe((m) => {
            this.currentMiniDetail = m;

            // TODO remove this, empty icons should not be passed
            this.currentMiniDetail.icons = this.currentMiniDetail.icons.filter((icon) => icon);
          });
        }
      });
  }

  public isFullPage(): boolean {
    return this.detailService.isOverlayOpen() || this.router.url.includes('custom');
  }
}
