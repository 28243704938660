import {
  CreditAsset,
  VodAssetStream,
  VodAssetSummary as VodRibbonEpisode,
  VodAssetTypes,
  VodCreditRoles,
  VodDetail,
} from '@atv-core/api/vod';
import { ConfigService, DetailTranslationKeys, TimeTranslationKeys } from '@atv-bootstrap/services/config';
import { CreditInfoItem } from '@atv-core/utility/epg-utility/epg-utility.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { VodStreamRightsModel } from '@atv-detail/vod-detail/vod-stream-rights.model';
import { ImageRecipe, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';

export class VodDetailModel implements VodDetail {
  id: string;
  type: VodAssetTypes;
  title: string;
  originalTitle?: string;
  shortSynopsis?: string;
  longSynopsis?: string;
  posterImage?: string;
  boxcoverImage?: string;
  wallpaperImage?: string;
  adult?: boolean;
  minimumAge?: number;
  parentalRatings?: string[];
  duration?: number;
  episode?: number;
  season?: number;
  seasonId?: string;
  seriesId?: string;
  credits?: CreditAsset[];
  originalLocale?: string;
  dubbedLocale?: string;
  subtitleLocale?: string;
  productionYear?: number;
  productionLocations?: string[];
  nextEpisode?: string;
  tags?: string[];
  genres?: string[];
  streams?: VodAssetStream[];
  seasons?: VodRibbonEpisode[];
  episodes?: VodRibbonEpisode[];
  validFrom?: string;
  validUntil?: string;
  score?: number;
  operatorScore?: number;

  vodStreamRights: VodStreamRightsModel;

  detailOpen = false;

  constructor(obj: VodDetail) {
    if (obj) {
      Object.keys(obj).map((key) => (this[key] = obj[key]));
    }

    // sort series episodes
    if (this.episodes) {
      this.episodes.sort((a, b) => {
        if (a.season < b.season) {
          return -1;
        } else if (a.season > b.season) {
          return 1;
        } else {
          if (a.episode < b.episode) {
            return -1;
          } else if (a.episode > b.episode) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    }
  }

  public isExpired(): boolean {
    const now = new Date().getTime();
    const start = SharedUtilityService.timeStringToMs(this.validFrom);
    const end = SharedUtilityService.timeStringToMs(this.validUntil);

    return start > now || end < now;
  }

  public getBackdrop(): string {
    if (this.wallpaperImage) {
      return ImageRecipeUtil.createImageUrl(this.wallpaperImage, ImageRecipe.DETAIL_BACKDROP_1);
    } else if (this.posterImage) {
      return ImageRecipeUtil.createImageUrl(this.posterImage, ImageRecipe.DETAIL_BACKDROP_1);
    }

    return '';
  }

  public getPoster(): string {
    return this.posterImage ? ImageRecipeUtil.createImageUrl(this.posterImage, ImageRecipe.VOD_CARD_3) : '';
  }

  public getProgramShortInfo(config: ConfigService): string {
    let programShortInfo = [];

    programShortInfo.push(this.productionYear);
    programShortInfo.push(this.getDurationString(config));
    programShortInfo.push(this.getGenresInfo(config));

    programShortInfo = programShortInfo.filter((item) => item !== undefined && item !== '');
    return programShortInfo.join('  •  ');
  }

  private getDurationString(config: ConfigService): string {
    return this.duration
      ? Math.trunc(this.duration / 60) +
        ' ' +
        config.getTranslation(TimeTranslationKeys.time_minutes_short)
      : undefined;
  }

  public getSeasonEpisodeInfo(config: ConfigService): string {
    let seasonEpisodeInfo = '';
    if (this.season) {
      seasonEpisodeInfo =
        config.getTranslation(DetailTranslationKeys.detail_season) + ' ' + this.season;
    }
    if (this.episode) {
      seasonEpisodeInfo += seasonEpisodeInfo ? ', ' : '';
      seasonEpisodeInfo +=
        config.getTranslation(DetailTranslationKeys.detail_episode) + ' ' + this.episode;
    }

    return seasonEpisodeInfo;
  }

  public getCreditInfo(
    config: ConfigService,
    detailKey: DetailTranslationKeys,
    creditRole: VodCreditRoles
  ): CreditInfoItem {
    const creditInfoItem = new CreditInfoItem();
    if (!this.credits) {
      return creditInfoItem;
    }
    const creditItems = this.credits
      .filter((credit) => credit.role === creditRole)
      .map((item) => item.name);

    creditInfoItem.content =
      creditItems.length > 15
        ? creditItems.slice(0, 15).join(', ') + ', ...'
        : creditItems.join(', ');

    creditInfoItem.creditTitle = creditInfoItem.content ? config.getTranslation(detailKey) : '';
    return creditInfoItem;
  }

  public getSynopsisInfo(): string {
    let synopsisInfo = this.longSynopsis ? this.longSynopsis : '';
    if (!synopsisInfo) {
      synopsisInfo = this.shortSynopsis ? this.shortSynopsis : '';
    }

    return synopsisInfo;
  }

  public getMinimumAgeImage(): string {
    if (this.minimumAge) {
      return SharedUtilityService.getMinimumAgeImage(this.minimumAge);
    }

    return '';
  }

  public getImdbScore(config: ConfigService): string {
    let imdbScore = '';
    if (this.score) {
      imdbScore =
        config.getTranslation(DetailTranslationKeys.detail_imdb_rating) +
        ' ' +
        this.score / 10 +
        '/10';

      if (this.operatorScore) {
        imdbScore += '  •  ';
      }
    }

    return imdbScore;
  }

  public getOperatorScore(): number {
    if (this.operatorScore) {
      return Math.min(Math.floor(Math.max(this.operatorScore - 1, 0) / 25) + 1, 4);
    }
    return undefined;
  }

  private getGenresInfo(config: ConfigService): string {
    let genres = [];
    if (!this.genres) {
      return '';
    }
    this.genres.forEach((genre) => genres.push(config.getGenre(genre)));
    genres = genres.filter((genre) => genre !== undefined && genre !== '');
    return genres.join('  •  ');
  }
}
