import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AdultMode } from '@atv-core/services/adult';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { CategoryFilterComponent } from '@atv-shared/filters/category-filter/category-filter.component';
import { ContentFilters, FilterModel } from '@atv-shared/filters/filters.model';
import { ScoreFilterComponent } from '@atv-shared/filters/score-filter/score-filter.component';
import { YearFilterComponent } from '@atv-shared/filters/year-filter/year-filter.component';

@Component({
  selector: 'app-cms-page-filters',
  templateUrl: './cms-page-filters.component.html',
  styles: [],
})
export class CmsPageFiltersComponent implements OnInit {
  @Input()
  allowedAdultMode: AdultMode;

  @Input()
  cmsFilterModel: FilterModel;

  @Output()
  cmsFilterChange = new EventEmitter();

  // match # filter in list filters
  private readysNeeded;
  public isSmartTv = SharedUtilityService.isSmartTv();

  @ViewChild('scoreFilter', { static: false })
  scoreFilter: ScoreFilterComponent;
  @ViewChild('yearFilter', { static: false })
  yearFilter: YearFilterComponent;
  @ViewChild('categoryFilter', { static: false })
  categoryFilter: CategoryFilterComponent;

  public ContentFilters = ContentFilters;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.readysNeeded = this.cmsFilterModel.activeCmsFilters.length;
  }

  public clearAllFilters() {
    if (!this.cmsFilterModel) {
      return;
    }

    // timeout needed to give router time to remove query params from url
    if (this.scoreFilter && this.cmsFilterModel.activeCmsFilters.includes(ContentFilters.SCORE)) {
      setTimeout(() => {
        this.scoreFilter.resetFilter();
      }, 0);
    }
    if (this.yearFilter && this.cmsFilterModel.activeCmsFilters.includes(ContentFilters.YEAR)) {
      setTimeout(() => {
        this.yearFilter.resetFilter();
      }, 0);
    }
    if (
      this.categoryFilter &&
      this.cmsFilterModel.activeCmsFilters.includes(ContentFilters.CATEGORY)
    ) {
      setTimeout(() => {
        this.categoryFilter.resetFilter();
      }, 0);
    }
  }

  filterReady() {
    this.readysNeeded--;

    if (this.readysNeeded <= 0) {
      this.cmsFilterChange.emit();
    }
  }

  categoryFilterChanged() {
    this.cmsFilterChange.emit();
  }

  scoreFilterChanged() {
    this.cmsFilterChange.emit();
  }

  yearFilterChanged() {
    this.cmsFilterChange.emit();
  }
}
