import { Injectable } from '@angular/core';
import { FavoriteContentTypes, FavoriteElement, HistoryApiService } from '@atv-core/api/history';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { CacheStorage } from '@atv-core/services/cache/cacheStorage';
import { ChannelModel } from '@atv-core/services/cache/channel';
import { ConfigService, SettingsKeys } from '@atv-bootstrap/services/config';
import { SessionService } from '@atv-core/services/session/session.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

import { FavoriteModel, FavoriteQueryTypes } from './favorite.model';
import { FavoriteCacheType } from '@atv-core/api/history/history-api.model';

@Injectable({ providedIn: 'root' })
export class FavoriteCacheService {
  private reloadingCache = false;
  private cache = new CacheStorage();
  private cachePromise: Observable<any> = undefined;
  private expirationTime = 0;

  constructor(
    private config: ConfigService,
    private historyApi: HistoryApiService,
    private auth: AuthorizationService,
    private sessionService: SessionService,
  ) {
    this.sessionService.clearAllCachesEvent.subscribe(() => {
      this.clearCache();
    });
  }

  public getFavoritesForType(
    type: FavoriteCacheType,
    query: FavoriteQueryTypes,
    channels: ChannelModel[],
    showAdult: boolean,
  ): Observable<FavoriteModel[]> {
    this.checkCache();

    return this.cachePromise.pipe(
      map(() => {
        const favorites: FavoriteModel[] = this.cache.values();

        favorites.sort((a, b) => {
          if (parseInt(a.lastUpdate) < parseInt(b.lastUpdate)) {
            return 1;
          } else if (parseInt(a.lastUpdate) > parseInt(b.lastUpdate)) {
            return -1;
          } else {
            return 0;
          }
        });
        let result: FavoriteModel[] = [];

        if (type === FavoriteCacheType.CHANNEL) {
          result = favorites.filter(
            (favorite) => favorite.type === FavoriteContentTypes.CHANNEL && (showAdult || !favorite.adult),
          );

          result.forEach((favorite) => {
            favorite.channel = channels.find((channel) => channel.id === favorite.id);
          });

          result.sort((a, b) => {
            // NEBINT-5582 Web - Channel sorting on guide
            const numberA =
              a.channel.getChannelNumberForCatalog(this.sessionService.getCatalogId()) ||
              Number.MAX_SAFE_INTEGER;
            const numberB =
              b.channel.getChannelNumberForCatalog(this.sessionService.getCatalogId()) ||
              Number.MAX_SAFE_INTEGER;
            if (numberA < numberB) {
              return -1;
            } else if (numberA > numberB) {
              return 1;
            } else {
              return 0;
            }
          });
          return result;
        } else if (type === FavoriteCacheType.VOD) {
          result = favorites.filter(
            (favorite) => favorite.type === FavoriteContentTypes.VOD && (showAdult || !favorite.adult),
          );
        } else if (type === FavoriteCacheType.EPG) {
          result = favorites.filter((favorite) => {
            return (
              (favorite.type === FavoriteContentTypes.PROGRAM ||
               favorite.type === FavoriteContentTypes.SERIES) &&
              favorite.schedules &&
              favorite.schedules.length > 0 &&
              (showAdult || !favorite.adult)
            );
          });

          const now = new Date().getTime();
          if (query === FavoriteQueryTypes.FUTURE) {
            result = result.filter((fav) => {
              return fav.schedules.some((schedule) => {
                return now < SharedUtilityService.timeStringToMs(schedule.end);
              });
            });
          } else if (query === FavoriteQueryTypes.PAST) {
            result = result.filter((fav) => {
              return fav.schedules.some((schedule) => {
                return SharedUtilityService.timeStringToMs(schedule.end) < now;
              });
            });
          }
        }

        return result;
      }),
    );
  }

  public getFavoritesForChannel(): Observable<FavoriteElement[]> {
    this.checkCache();

    return this.cachePromise.pipe(
      map(() => {
        const favorites = this.cache.values();
        const result = favorites.filter(
          (favorite) => favorite.type === FavoriteContentTypes.CHANNEL,
        );

        result.sort(function(a, b) {
          if (parseInt(a.lastUpdate) < parseInt(b.lastUpdate)) {
            return 1;
          } else if (parseInt(a.lastUpdate) > parseInt(b.lastUpdate)) {
            return -1;
          } else {
            return 0;
          }
        });
        return result;
      }),
    );
  }

  public getFavorite(type: FavoriteContentTypes, id: string): Observable<FavoriteModel> {
    this.checkCache();
    return this.cachePromise.pipe(
      map(() => {
        return this.cache.get(id + type);
      }),
    );
  }

  // TODO create behaviorSubject so observers (e.g. card) can be notified on changes
  public isFavorite(type: FavoriteContentTypes, id: string): Observable<boolean> {
    this.checkCache();
    return this.cachePromise.pipe(
      map(() => {
        return this.cache.get(id + type) !== undefined;
      }),
    );
  }

  public setFavorite(type: FavoriteContentTypes, id: string, adult: boolean, schedules?): void {
    if (!this.auth.isAuthorized()) {
      return;
    }
    if (schedules) {
      schedules = schedules.map((schedule) => {
        return schedule.published;
      });
    }
    this.checkCache();

    this.cachePromise.subscribe(() => {
      this.cache.put(
        id + type,
        new FavoriteModel({
          type,
          id,
          adult,
          schedules: schedules ? schedules : [],
          lastUpdate: (new Date().getTime() / 1000).toString(),
        }),
      );

      this.historyApi.setFavorite(this.sessionService.getActiveProfileId(), type, id).subscribe();
    });
  }

  public deleteFavorite(type: FavoriteContentTypes, id: string): void {
    this.checkCache();

    this.cachePromise.subscribe(() => {
      this.cache.remove(id + type);
    });
    this.historyApi.deleteFavorite(this.sessionService.getActiveProfileId(), type, id).subscribe();
  }

  public toggleFavorite(type: FavoriteContentTypes, id: string, adult: boolean, schedules?) {
    this.isFavorite(type, id).subscribe((result) => {
      if (result) {
        this.deleteFavorite(type, id);
      } else {
        this.setFavorite(type, id, adult, schedules);
      }
    });
  }

  private checkCache(): void {
    // cache  expired
    if (new Date().getTime() > this.expirationTime) {
      this.clearCache();
    }

    this.cachePromise = this.cachePromise || this.fillCache();
  }

  private fillCache(): Observable<any> {
    if (!this.auth.isAuthorized()) {
      return of([]);
    }

    return this.historyApi
      .getFavorites(this.sessionService.getActiveProfileId(), this.sessionService.getCatalogId())
      .pipe(
        switchMap((result) => {
          this.expirationTime =
            new Date().getTime() +
            this.config.getSettingNumber(SettingsKeys.favoriteCacheExpiration, 120000);

          result.forEach((element: FavoriteElement) =>
            this.cache.put(element.id + element.type, new FavoriteModel(element)),
          );
          this.reloadingCache = false;
          return of([]);
        }),
        catchError((e) => {
          this.expirationTime = 0;
          this.reloadingCache = false;
          throw e;
        }),
        shareReplay(),
      );
  }

  private clearCache() {
    if (this.reloadingCache) {
      return;
    }
    this.cache = new CacheStorage();
    this.expirationTime = 0;
    this.cachePromise = undefined;
    this.reloadingCache = true;
  }
}
