<div
  id="channel-{{ channel.id }}"
  class="schedule-grid"
  [class.smarttv]="isSmartTv"
  [style.width.px]="totalHours * widthPerHour"
  #scheduleGrid
>
  <app-schedule
    *ngFor="let schedule of schedules"
    [program]="programsMap[schedule.program]"
    [selectedDate]="selectedDate"
    [widthPerHour]="widthPerHour"
    [channel]="channel"
    [allowedAdultMode]="allowedAdultMode"
    [categoryTags]="categoryTags"
    [scheduleItemIntersectionObserver]="scheduleItemIntersectionObserver"
    [schedule]="schedule"
    [guideFocusEvent]="guideFocusEvent"
    [guideScrollEvent]="guideScrollEvent"
  ></app-schedule>
</div>
