import { Injectable } from '@angular/core';
import { CmsAction, CmsActionMethod, CmsContentTypes } from '@atv-core/api/cms/cms-api.model';
import { PricingApiService } from '@atv-core/api/pricing/pricing-api.service';
import { VodApiService } from '@atv-core/api/vod';
import { AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { QuoteCacheService } from '@atv-core/services/cache/quote/quote-cache.service';
import { ConfigService } from '@atv-bootstrap/services/config';
import { MessagesService } from '@atv-core/services/messages';
import { PlayerService } from '../../player/player.service';
import { SessionService } from '@atv-core/services/session';
import { StreamType } from '@atv-core/utility/constants/shared';
import { VodDetailModel } from '@atv-detail/vod-detail/vod-detail.model';
import { VodStreamRightsModel } from '@atv-detail/vod-detail/vod-stream-rights.model';
import { PlayInfoModel } from '@atv-player/model/play-info-model';
import { environment } from '@env/environment';
import { forkJoin } from 'rxjs';

import { CmsActionHandler } from '../cms-action.model';

@Injectable({ providedIn: 'root' })
export class PlayTrailerVodAction extends CmsActionHandler {
  constructor(
    private vodApi: VodApiService,
    private quoteCache: QuoteCacheService,
    private pricingApi: PricingApiService,
    private sessionService: SessionService,
    private auth: AuthorizationService,
    messagesService: MessagesService,
    config: ConfigService,
    adultService: AdultService,
    playerService: PlayerService
  ) {
    super(adultService, playerService, messagesService, config);
  }

  handlesMethod(method: CmsActionMethod): boolean {
    return method === CmsActionMethod.PLAY_TRAILER;
  }

  handlesType(type: CmsContentTypes): boolean {
    return type === CmsContentTypes.VOD;
  }

  handleAction(action: CmsAction): void {
    forkJoin([
      this.vodApi.getVodDetail(action.actionId),
      this.quoteCache.getQuotes(true),
      this.pricingApi.getVodPrice(
        action.actionId,
        environment.platform,
        this.sessionService.getEntitlementId()
      ),
    ]).subscribe((result) => {
      const vodDetail = new VodDetailModel(result[0]);
      const vodStreamRights = new VodStreamRightsModel(vodDetail.id, result[1], result[2]);

      if (!(vodStreamRights.trailerStreams?.length > 0)) {
        console.warn('vod has no trailer');
        return;
      }

      const trailerStream = vodStreamRights.trailerStreams?.find(
        (trailer) =>
          trailer.type === StreamType.FVOD ||
          (trailer.type === StreamType.SVOD && this.auth.isAuthorized()),
      );

      if (!trailerStream) {
        if (vodStreamRights.trailerStreams?.some((trailer) => trailer.type === StreamType.SVOD)) {
          this.auth.showAuthPopup.emit();
        }
        console.warn('no trailer found');
        return;
      }

      const playInfo = new PlayInfoModel(trailerStream.type, trailerStream.streamId, 0);
      playInfo.setVodInfo(vodDetail, '', false, true, vodDetail.adult);
      this.checkAdultAndPlay(playInfo);
    });
  }
}
