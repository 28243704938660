import { Injectable, EventEmitter } from '@angular/core';

import { MessageModel, MessageTypes } from './messages.model';
@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  newMessageEvent = new EventEmitter<MessageModel>();

  public showErrorMessage(message: string) {
    this.newMessageEvent.emit(new MessageModel(MessageTypes.ERROR, message));
  }

  public showWarningMessage(message: string) {
    this.newMessageEvent.emit(new MessageModel(MessageTypes.WARNING, message));
  }

  public showInfoMessage(message: string, dissipate: boolean) {
    this.newMessageEvent.emit(new MessageModel(MessageTypes.INFO, message, dissipate));
  }

  public showSuccessMessage(message: string) {
    this.newMessageEvent.emit(new MessageModel(MessageTypes.SUCCESS, message));
  }

  constructor() {}
}
