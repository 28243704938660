import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EpgApiService, EpgCategory } from '@atv-core/api/epg';
import { VodApiService } from '@atv-core/api/vod';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { ConfigService, DetailTranslationKeys, ListTranslationKeys } from '@atv-bootstrap/services/config';
import { MessagesService } from '@atv-core/services/messages';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { ContentFilters, FilterContentTypes, FilterModel } from '@atv-shared/filters/filters.model';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';

import { CategoryFilterModes } from '../filters.model';

interface BreadCumbStackItem {
  tag: string;
  title: string;
  adult: boolean;
  hasSubCategories: boolean;
}

@Component({
  selector: 'app-guide-category-filter',
  templateUrl: './guide-category-filter.component.html',
  styles: [],
})
export class GuideCategoryFilterComponent implements AfterViewInit, OnDestroy {
  @Input()
  filterModel: FilterModel;

  @Input()
  categoryFilterType: FilterContentTypes;

  @Input()
  allowedAdultMode: AdultMode;

  @Output()
  filterReady = new EventEmitter();

  @Output()
  filterChange = new EventEmitter();

  @ViewChild('filterWrapperRef')
  filterWrapperRef: ElementRef;

  @ViewChild('optionWrapperRef')
  optionWrapperRef: ElementRef;

  private clickedInside = false;

  selectedDayTitle = '';
  filterTitle = '';
  allText = '';
  optionsHidden = true;

  fetchingCategories = false;
  tempBreadCumbStack: BreadCumbStackItem[] = new Array<BreadCumbStackItem>();
  breadCumbStack: BreadCumbStackItem[] = new Array<BreadCumbStackItem>();

  showCategories: EpgCategory[];
  categories: EpgCategory[] = [];

  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnObservable: Observable<KeyboardEvent>;
  @ViewChildren('overlayDiv') overlayDiv: QueryList<ElementRef<HTMLDivElement>>;
  private returnSubscription: Subscription;
  private overlayDivSubscription: Subscription;

  constructor(
    private config: ConfigService,
    private epgApi: EpgApiService,
    private vodApi: VodApiService,
    private adultService: AdultService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private messagesService: MessagesService,
    private cdr: ChangeDetectorRef,
    private spatialNavigationService: SpatialNavigationService,
  ) {
    this.filterTitle = this.config.getTranslation(ListTranslationKeys.lists_filter_category);
    this.allText = this.config.getTranslation(ListTranslationKeys.lists_filter_all_value);
    this.selectedDayTitle = this.filterTitle;

    if (this.isSmartTv) {
      this.returnObservable = fromEvent(document, 'keyup').pipe(
        filter((event: KeyboardEvent) =>
          this.spatialNavigationService.keyCodeIsReturn(event.keyCode),
        ),
      );
    }
  }

  private detectChanges(): void {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this.fetchCategorysAndTryInitFromQueryParams();

    this.overlayDivSubscription = this.overlayDiv.changes.subscribe(changes => {
      if (changes.length === 1) {
        // move to body to avoid issues with parent divs who have a transformation set
        // TODO create overlay component, add it to body and use ng-template
        document.body.appendChild(changes.first.nativeElement);
      }
    });
  }

  showOptions(): void {
    this.fetchCategories()
      .pipe(
        finalize(() => {
          this.checkBreadCrumbAndTempStack();

          this.optionsHidden = false;
          this.detectChanges();
          setTimeout(() => {
            this.detectWidthChange();
          }, 0);
          if (this.isSmartTv) {
            window.setTimeout(() => {
              this.spatialNavigationService.register(
                NavigationSections.FILTER_OPTIONS,
                '.filter-option-smarttv',
                { restrict: 'self-only' },
              );
              this.spatialNavigationService.setFocus(NavigationSections.FILTER_OPTIONS);
              this.spatialNavigationService.setDefaultSection(NavigationSections.FILTER_OPTIONS);

              this.returnSubscription = this.returnObservable.subscribe((event) => {
                event.cancelBubble = true;

                if (this.tempBreadCumbStack.length === 0) {
                  this.hideOptions();
                } else {
                  const lastIndexWithSubcats = this.getLastIndexWithSubcats();
                  if (lastIndexWithSubcats === 0) {
                    this.tempBreadCumbStack = new Array<BreadCumbStackItem>();
                    this.setShowCategories();
                  } else {
                    this.goToTempBreadCrumb(lastIndexWithSubcats - 1);
                  }

                  this.spatialNavigationService.unregister(NavigationSections.FILTER_OPTIONS);
                  this.spatialNavigationService.register(
                    NavigationSections.FILTER_OPTIONS,
                    '.filter-option-smarttv',
                    { restrict: 'self-only' },
                  );
                  this.spatialNavigationService.setFocus(NavigationSections.FILTER_OPTIONS);
                  this.spatialNavigationService.setDefaultSection(NavigationSections.FILTER_OPTIONS);
                }
              });
            }, 100);
          }
        }),
      )
      .subscribe();
  }

  private getLastIndexWithSubcats(): number {
    for (let i = this.tempBreadCumbStack.length - 1; i >= 0; --i) {
      if (this.tempBreadCumbStack[i].hasSubCategories) {
        return i;
      }
    }

    return null;
  }

  hideOptions(): void {
    this.checkBreadCrumbAndTempStack();
    this.optionsHidden = true;
    this.detectChanges();
    if (!this.isSmartTv) {
      setTimeout(() => {
        this.detectWidthChange();
      }, 0);
    } else {
      this.spatialNavigationService.unregister(NavigationSections.FILTER_OPTIONS);
      this.spatialNavigationService.setFocus(NavigationSections.FILTERS);
    }

    if (this.returnSubscription) {
      this.returnSubscription.unsubscribe();
      this.returnSubscription = null;
    }
  }

  private fetchCategorysAndTryInitFromQueryParams(): void {
    const param = this.activeRoute.snapshot.queryParams[ContentFilters.CATEGORY];
    if (param) {
      if (this.categories.length === 0) {
        this.fetchCategories().subscribe((res) => {
          this.tryInitFromQueryParams(param);
        });
      } else {
        this.tryInitFromQueryParams(param);
      }
    } else {
      this.filterReady.emit();
    }
  }

  changeSelectedCategoryIndex(index: number): void {
    const item = this.showCategories[index];
    if (item.adult) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.doChangeSelectedCategoryIndex(item);
        },
        errorCallback: () => {
          this.messagesService.showErrorMessage(this.config.getTranslation(DetailTranslationKeys.detail_adult_warning));
        },
      });
    } else {
      this.doChangeSelectedCategoryIndex(item);
    }
  }

  private queryParamsAreAdult(tags: string[]): boolean {
    let currentCategorys: EpgCategory[] = this.categories;
    let foundAdult = false;
    for (let i = 0; i < tags.length && !foundAdult; i++) {
      const category = currentCategorys.find((it) => it.tag === tags[i]);
      foundAdult = category.adult;
      if (foundAdult) {
        break;
      }
      if (category.subcategories) {
        currentCategorys = category.subcategories;
      } else {
        break;
      }
    }

    return foundAdult;
  }

  private continueTryInitFromQueryParams(tags: string[]): void {
    for (const tag of tags) {
      const index = this.showCategories.findIndex((it) => it.tag === tag);
      if (index === undefined || index < 0) {
        this.removeQueryParamForFilter();
        this.clearTempBreadCrumbs();
        this.selectedDayTitle = this.filterTitle;
        this.breadCumbStack = this.tempBreadCumbStack = new Array<BreadCumbStackItem>();
        this.filterModel.categoryFilterOutputTags = undefined;
        this.filterReady.emit();
        return;
      }
      this.changeSelectedCategoryIndex(index);
    }
    this.filterModel.categoryFilterOutputTags = tags;
    this.filterReady.emit();
  }

  goToTempBreadCrumb(index: number): void {
    this.tempBreadCumbStack = this.tempBreadCumbStack.slice(0, index + 1);

    if (this.tempBreadCumbStack && this.tempBreadCumbStack.length > 0) {
      const item = this.tempBreadCumbStack[this.tempBreadCumbStack.length - 1];
      if (item.adult) {
        this.adultService.checkAdultMode({
          successCallback: () => {
            this.setShowCategories();
          },
          errorCallback: () => {
            this.removeQueryParamForFilter();
            this.addQueryParamForFilter(this.getTagsFromBreadCrumbStack());
            this.messagesService.showErrorMessage(this.config.getTranslation(DetailTranslationKeys.detail_adult_warning));
          },
        });
      } else {
        this.setShowCategories();
      }
    }
  }

  private tryInitFromQueryParams(param: string): void {
    const tagString = decodeURI(param);
    const tags = tagString.split('||');

    if (this.tagsSameAsBreadCrumb(tags)) {
      return;
    }

    if (this.queryParamsAreAdult(tags)) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.continueTryInitFromQueryParams(tags);
        },
        errorCallback: () => {
          this.messagesService.showErrorMessage(this.config.getTranslation(DetailTranslationKeys.detail_adult_warning));
          this.removeQueryParamForFilter();
          this.filterReady.emit();
        },
      });
    } else {
      this.continueTryInitFromQueryParams(tags);
    }
  }

  private tagsSameAsBreadCrumb(items: string[]): boolean {
    return !items.some(
      (item) => this.tempBreadCumbStack.find((bcs) => bcs.tag === item) === undefined,
    );
  }

  private addQueryParamForFilter(breadCrumbs: string[]): void {
    const f = {};
    f[ContentFilters.CATEGORY] = undefined;

    if (breadCrumbs && breadCrumbs.length > 0) {
      f[ContentFilters.CATEGORY] = encodeURI(breadCrumbs.join('||'));
    }

    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: f,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private getTagsFromBreadCrumbStack(): string[] {
    const tags = [];
    this.breadCumbStack.forEach((item) => tags.push(item.tag));
    return tags;
  }

  removeFilter(e?: Event): void {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.removeQueryParamForFilter();
    this.clearTempBreadCrumbs();
    this.selectedDayTitle = this.filterTitle;
    this.breadCumbStack = this.tempBreadCumbStack = new Array<BreadCumbStackItem>();
    this.filterModel.categoryFilterOutputTags = undefined;
    this.filterChange.emit();
    this.detectChanges();
  }

  private removeQueryParamForFilter(): void {
    const f = {};
    f[ContentFilters.CATEGORY] = undefined;
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: f,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private getSelectedTitle(): string {
    return this.breadCumbStack?.length > 0
      ? this.breadCumbStack[this.breadCumbStack.length - 1].title
      : undefined;
  }

  private addToBreadCrumbStack(item: EpgCategory): void {
    let hasSubCats = false;

    if (item.subcategories && item.subcategories.length > 0) {
      hasSubCats = true;
    }

    if (
      this.tempBreadCumbStack.length > 0 &&
      !this.tempBreadCumbStack[this.tempBreadCumbStack.length - 1].hasSubCategories
    ) {
      this.tempBreadCumbStack.pop();
    }

    this.tempBreadCumbStack.push({
      tag: item.tag,
      title: item.title,
      adult: item.adult,
      hasSubCategories: hasSubCats,
    });
  }

  private doChangeSelectedCategoryIndex(item: EpgCategory): void {
    this.addToBreadCrumbStack(item);
    if (
      this.tempBreadCumbStack.length > 0 &&
      !this.tempBreadCumbStack[this.tempBreadCumbStack.length - 1].hasSubCategories
    ) {
      this.breadCumbStack = this.tempBreadCumbStack;
      this.selectedDayTitle = this.getSelectedTitle();
      this.hideOptions();
      this.addQueryParamForFilter(this.getTagsFromBreadCrumbStack());
      this.filterModel.categoryFilterOutputTags = this.getTagsFromBreadCrumbStack();
      this.filterChange.emit();
    } else {
      this.showCategories = item.subcategories;
      if (!this.isSmartTv) {
        setTimeout(() => {
          this.detectWidthChange();
        }, 0);
      } else if (!this.optionsHidden) {
        this.spatialNavigationService.unregister(NavigationSections.FILTER_OPTIONS);
        this.spatialNavigationService.register(
          NavigationSections.FILTER_OPTIONS,
          '.filter-option-smarttv',
          { restrict: 'self-only' },
        );
        this.spatialNavigationService.setFocus(NavigationSections.FILTER_OPTIONS);
      }
    }
  }

  clearTempBreadCrumbs(): void {
    this.tempBreadCumbStack = new Array<BreadCumbStackItem>();
    this.setShowCategories();
  }

  getTempBreadCrumbs(): string[] {
    const crumbs = [];

    this.tempBreadCumbStack.forEach((item) => {
      if (item.hasSubCategories) {
        crumbs.push(item.title);
      }
    });

    return crumbs;
  }

  detectWidthChange(): void {
    if (this.filterWrapperRef) {
      if (!this.optionWrapperRef) {
        this.filterWrapperRef.nativeElement.style.width = 'auto';
      } else {
        const buttonConfigEl = this.optionWrapperRef.nativeElement;
        const width = buttonConfigEl.getBoundingClientRect().width;
        if (width) {
          this.filterWrapperRef.nativeElement.style.width = width + 'px';
        } else {
          this.filterWrapperRef.nativeElement.style.width = 'auto';
        }
      }
    }
  }

  checkBreadCrumbAndTempStack(): void {
    this.tempBreadCumbStack = this.breadCumbStack;

    if (
      this.tempBreadCumbStack.length > 0 &&
      this.tempBreadCumbStack[this.tempBreadCumbStack.length - 1].hasSubCategories
    ) {
      this.tempBreadCumbStack = this.breadCumbStack = new Array<BreadCumbStackItem>();
    }
  }

  private fetchCategories(): Observable<void> {
    this.categories = [];
    this.fetchingCategories = true;
    if (this.filterModel.categoryFilterMode === CategoryFilterModes.EPG) {
      return this.fetchEpgCategories();
    } else {
      return this.fetchVodCategories();
    }
  }

  private fetchVodCategories(): Observable<void> {
    return this.vodApi.getCategories().pipe(
      map((result) => {
        this.fetchingCategories = false;
        const cats = result.find((item) => item.tag === this.categoryFilterType);

        if (cats) {
          if (
            this.categoryFilterType !== FilterContentTypes.ADULT ||
            (this.categoryFilterType === FilterContentTypes.ADULT &&
             this.adultService.showAdult(this.allowedAdultMode))
          ) {
            this.categories = cats.subcategories;
          }
        }

        this.tempBreadCumbStack = this.breadCumbStack;
        this.setShowCategories();
        return;
      }),
    );
  }

  private fetchEpgCategories(): Observable<void> {
    return this.epgApi.getCategories().pipe(
      map((result) => {
        this.fetchingCategories = false;
        this.categories = result;
        this.tempBreadCumbStack = this.breadCumbStack;
        this.setShowCategories();
        return;
      }),
    );
  }

  private setShowCategories(): void {
    const showAdult = this.adultService.showAdult(this.allowedAdultMode);

    if (!showAdult) {
      this.showCategories = this.filterAdult(this.categories);
    } else {
      this.showCategories = this.categories;
    }

    for (const b of this.tempBreadCumbStack) {
      if (b.hasSubCategories) {
        const category = this.showCategories.find((item) => item.tag === b.tag);
        if (category) {
          this.showCategories = category.subcategories.filter(c => showAdult || !c.adult);

        }
      } else {
        return;
      }
    }
    this.detectChanges();
    setTimeout(() => {
      this.detectWidthChange();
    }, 0);
  }

  isActiveCategory(index: number): boolean {
    if (
      this.breadCumbStack.length > 0 &&
      !this.breadCumbStack[this.breadCumbStack.length - 1].hasSubCategories &&
      this.breadCumbStack[this.breadCumbStack.length - 1].tag === this.showCategories[index].tag
    ) {
      return true;
    }
    return false;
  }

  @HostListener('click', ['$event'])
  onClickedInside(): void {
    this.clickedInside = true;
  }

  @HostListener('document:click', ['$event'])
  onClickedOutside(): void {
    if (!this.clickedInside && !this.optionsHidden) {
      this.hideOptions();
    }
    this.clickedInside = false;
  }

  resetFilter(): void {
    this.removeFilter(undefined);
    this.filterReady.emit();
  }

  private filterAdult(categories: EpgCategory[]): EpgCategory[] {
    const result = categories.filter(c => !c.adult);

    result.forEach(c => c.subcategories = this.filterAdult(c.subcategories));

    return result;
  }

  ngOnDestroy(): void {
    this.returnSubscription?.unsubscribe();
    this.overlayDivSubscription?.unsubscribe();
  }
}
