<div
  #wrapper
  (click)="onPlayerClick()"
  (touchstart)="onPlayerClick(); $event.preventDefault(); $event.stopPropagation()"
  *ngIf="!isSmartTv"
  [class.hidden]="!isPlayerVisible"
  [class.hide-cursor]="!areControlsVisible()"
  [class.player-pip-mode]="isInPipMode"
  class="player-wrapper"
  id="video-player-wrapper"
>
  <video id="video-player"></video>

  <div
    [class.hidden]="!getCurrentAd()?.isBreakvertising()"
    [ngStyle]="
      getCurrentAd()?.isBreakvertising() && {
        'background-image': 'url(' + getBreakvertisingImage(getCurrentAd())+ ')'
      }
    "
    class="player-breakvertising"
  ></div>

  <div [class.hidden]="!areControlsVisible() && !getCurrentAd()" class="player-controls-wrapper">
    <app-close-player></app-close-player>

    <app-channelbar
      #channelbar
      (channelSelectEvent)="onChannelSelect($event)"
      [class.hidden]="!isLinear() || isInPipMode || !areControlsVisible()"
    >
    </app-channelbar>

    <div [class.hidden]="isInPipMode" class="player-controls">
      <div class="player-controls-top-wrapper">
        <app-epg-info
          #epgInfo
          (click)="$event.stopPropagation()"
          (touchstart)="$event.preventDefault(); $event.stopPropagation()"
          [class.hidden]="isVod() || getCurrentAd()"
          [epgInfoInputModel]="epgInfoInput"
        ></app-epg-info>
        <app-vod-info
          #vodInfo
          (click)="$event.stopPropagation()"
          (touchstart)="$event.preventDefault(); $event.stopPropagation()"
          [class.hidden]="!isVod() || getCurrentAd()"
          [vodInfoInputModel]="vodInfoInput"
        ></app-vod-info>
        <div
          (click)="goToAdvertisement($event); $event.stopPropagation()"
          (touchstart)="
            goToAdvertisement($event); $event.preventDefault(); $event.stopPropagation()
          "
          [class.hidden]="!getCurrentAd() || !hasPlayed || !getCurrentAd()?.actionText"
          class="player-controls-go-to-ad player-action-button button-title"
        >
          {{ getCurrentAd()?.actionText }}
        </div>
      </div>

      <div class="player-controls-bottom-wrapper">
        <div class="player-controls-ad-wrapper">
          <div *ngIf="getCurrentAd()" class="player-controls-ad-index">
            {{ getAdProgressText() }}
          </div>
          <div
            (click)="skipAd(); $event.stopPropagation()"
            (touchstart)="skipAd(); $event.preventDefault(); $event.stopPropagation()"
            [class.hidden]="!isSkipAdShown()"
            class="player-controls-skip-ad"
          >
            {{ getAdSkipText() }}
          </div>
        </div>

        <app-seekbar
          #seekbar
          (click)="$event.stopPropagation()"
          (seekEvent)="onSeek($event)"
          (touchstart)="$event.preventDefault(); $event.stopPropagation()"
        ></app-seekbar>

        <div
          (click)="$event.stopPropagation()"
          (touchstart)="$event.preventDefault(); $event.stopPropagation()"
          class="player-controls-group"
        >
          <div>
            <div
              (click)="playPauseToggle($event)"
              (touchstart)="
                playPauseToggle($event); $event.preventDefault(); $event.stopPropagation()
              "
              [class.hidden]="!showPauseButton()"
              [class.show-play]="isPaused()"
              class="player-controls-play-pause player-control"
            ></div>
            <div [class.hidden]="!isLinear() || getCurrentAd()" class="player-controls-live">
              LIVE
            </div>
            <div class="player-progress">
              {{ getProgressTime() }}
            </div>
            <app-volume
              (mutedChange)="mutedChange($event)"
              (volumeChange)="volumeChange($event)"
              [muted]="muted"
              [volume]="volume"
            >
            </app-volume>
          </div>

          <div>
            <app-audio></app-audio>
            <app-text></app-text>
            <app-quality></app-quality>
            <google-cast-launcher
              *ngIf="isChromecastAvailable()"
              class="player-control"
              id="player-google-cast-launcher"
            ></google-cast-launcher>
            <button (click)="doAirplay();$event.preventDefault(); $event.stopPropagation()"
                    (touchstart)="doAirplay(); $event.preventDefault(); $event.stopPropagation()"
                    *ngIf="canAirplay()"
                    [class.active]="isAirplayActive()" class="player-control" id="airplay"></button>
            <app-pip-button></app-pip-button>
            <app-fullscreen-button [fullscreenElement]="wrapper"></app-fullscreen-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-still-watching-popup></app-still-watching-popup>
</div>

<div
  *ngIf="isSmartTv"
  [class.hidden]="!isPlayerVisible"
  class="player-wrapper"
  id="video-player-wrapper"
>
  <video id="video-player"></video>
  <div
    [class.hidden]="!getCurrentAd()?.isBreakvertising()"
    [ngStyle]="
      getCurrentAd()?.isBreakvertising() && {
        'background-image': 'url(' + getBreakvertisingImage(getCurrentAd()) + ')'
      }
    "
    class="player-breakvertising"
  ></div>
  <app-player-controls-smarttv></app-player-controls-smarttv>
</div>
