import { StreamResolutionType } from '@atv-core/utility/constants/shared';

import { ScheduleTime } from '@atv-core/api/history';
import { SearchTypes } from './../search/search-api.model';
import { VodAssetTypes } from '@atv-core/api/vod';
import { ContentProvider } from '@atv-shared/content-provider/content-provider';

export interface CustomCmsRow {
  readonly type: CustomCmsRowTypes;
  readonly banners?: CustomCmsBanner[];
  readonly ribbon?: CustomCmsRibbon;
  readonly grid?: CustomCmsGrid;
}

export enum CustomCmsRowTypes {
  BANNER = 'BANNER',
  RIBBON = 'RIBBON',
  GRID = 'GRID',
}

export class CmsRow {
  readonly type: CustomCmsRowTypes;
  readonly banners?: CustomCmsBanner[];
  readonly ribbon?: CmsRibbon;
  readonly grid?: CmsGrid;
}

export interface CmsRibbon {
  title: string;
  properties?: Properties[];
  cardSize: CardSize;
  contents?: any; // TODO remove, use contentProvider
  contentMessage?: string;
  contentProvider?: ContentProvider; // TODO make required

  titleIcons?: RibbonTitleIcon[]; // used on list page to show favorite and recording info
  orientation?: CardOrientation; // TODO remove, retrieve from contentProvider
  isOnDetailPage: boolean;
}

export interface RibbonTitleIcon {
  iconImage: () => string;
  iconHeight: number;
  iconClick?: () => void;
}

// TODO use interface iso class
export class CmsGrid {
  title: string;
  properties?: Properties[];
  contentUrl?: string; // TODO remove, use contentProvider
  contents?: any; // TODO remove, use contentProvider
  contentProvider?: ContentProvider; // TODO make required
  contentMessage?: string;
  orientation?: CardOrientation; // TODO remove, retrieve from contentProvider

  constructor(grid: CmsGrid) {
    Object.keys(grid).forEach((property) => (this[property] = grid[property]));
  }
}

export enum CmsContentTypes {
  VOD = 'VOD',
  PROGRAM = 'PROGRAM',
  SERIES = 'SERIES',
  CHANNEL = 'CHANNEL',
  SCHEDULE = 'SCHEDULE',
  RECORDING = 'RECORDING',
  ARTICLE = 'ARTICLE',
  BUNDLE = 'BUNDLE',
  PAGE = 'PAGE',
}

export class Article {
  id: string;
  poster: string;
  title: string;
  elements: ArticleElement[];
  adult?: string;
}

export class ArticleElement {
  image: string;
  title: string;
  synopsis: string;
  actions: ArticleElementAction[];
}

export class ArticleElementAction implements CmsAction {
  actionType: CmsContentTypes;
  actionId: string;
  actionMethod: CmsActionMethod;
  label: string;
}

export interface CmsAction {
  actionType: CmsContentTypes;
  actionId: string;
  actionMethod: CmsActionMethod;
}

export enum CmsActionMethod {
  DETAIL = 'DETAIL',
  PLAY = 'PLAY',
  PLAY_TRAILER = 'PLAY_TRAILER',
}

export interface Menu {
  id: string;
  items?: MenuItem[];
}

export interface MenuItem {
  tag: string;
  icon?: MenuIcons;
  pageType?: PageTypes;
  pageId?: string;
  isDefault?: boolean;
  title: string;
  properties?: Properties[];
  items?: MenuItem[];
}

export enum PageTypes {
  SEARCH_PAGE = 'SEARCH_PAGE',
  TV_GUIDE_PAGE = 'TV_GUIDE_PAGE',
  LIST_PAGE = 'LIST_PAGE',
  CMS_PAGE = 'CMS_PAGE',
  SETTINGS_PAGE = 'SETTINGS_PAGE',
  REMINDERS_PAGE = 'REMINDERS_PAGE',
  RECORDINGS_RECORDED_PAGE = 'RECORDINGS_RECORDED_PAGE',
  RECORDINGS_PLANNED_PAGE = 'RECORDINGS_PLANNED_PAGE',
  RECORDINGS_CONFLICT_PAGE = 'RECORDINGS_CONFLICT_PAGE',
  RECORDINGS_EXPIRE_PAGE = 'RECORDINGS_EXPIRE_PAGE',
  CLIENT_AREA_PAGE = 'CLIENT_AREA_PAGE',
}

export enum MenuIcons {
  MY_WORLD_ICON = 'MY_WORLD_ICON',
  CATCHUP_ICON = 'CATCHUP_ICON',
  NOW_ICON = 'NOW_ICON',
  ON_DEMAND_ICON = 'ON_DEMAND_ICON',
  SEARCH_ICON = 'SEARCH_ICON',
  STAR_ICON = 'STAR_ICON',
  HEART_ICON = 'HEART_ICON',
  TV_ICON = 'TV_ICON',
  RECENT_ICON = 'RECENT_ICON',
  PLAY_TV_ICON = 'PLAY_TV_ICON',
  LIST_ICON = 'LIST_ICON',
  MOVIE_ICON = 'MOVIE_ICON',
  SERIES_ICON = 'SERIES_ICON',
  RECORDED_ICON = 'RECORDED_ICON',
  PLANNED_ICON = 'PLANNED_ICON',
  EXPIRE_ICON = 'EXPIRE_ICON',
  REMINDERS_ICON = 'REMINDERS_ICON',
  LOGOUT_ICON = 'LOGOUT_ICON',
  CONFLICTS_ICON = 'CONFLICTS_ICON',
  CLIENTAREA_ICON = 'CLIENTAREA_ICON',
  ADULT_ICON = 'ADULT_ICON',
  NEW_ICON = 'NEW_ICON',
  SETTINGS_ICON = 'SETTINGS_ICON',
}

export enum Properties {
  AUTH_REQUIRED = 'AUTH_REQUIRED',
  ADULT_REQUIRED = 'ADULT_REQUIRED',
  ALLOW_ADULT = 'ALLOW_ADULT',
  NPVR_REQUIRED = 'NPVR_REQUIRED',
  LOCAL_RECORDER_REQUIRED = 'LOCAL_RECORDER_REQUIRED',
  FOCUS_PAST = 'FOCUS_PAST',
  FOCUS_FUTURE = 'FOCUS_FUTURE',
  SHOWPROGRESS = 'SHOWPROGRESS',
  VOD_FILTER_RATING = 'VOD_FILTER_RATING',
  VOD_FILTER_PRODUCTIONYEAR = 'VOD_FILTER_PRODUCTIONYEAR',
  VOD_FILTER_CATEGORY = 'VOD_FILTER_CATEGORY',
  USE_BEST_SCHEDULE = 'USE_BEST_SCHEDULE',
  SHOW_SCHEDULE_INFO = 'SHOW_SCHEDULE_INFO',
  SHOW_EPISODE_INFO = 'SHOW_EPISODE_INFO',
  HIDE_INFO = 'HIDE_INFO',
  HIDE_MINI_DETAIL = 'HIDE_MINI_DETAIL',
}

export interface CustomPage {
  readonly type: PageTypes;
  readonly title: string;
  readonly backgroundImage?: string;
  readonly cmsPage?: CustomCmsPage;
  readonly searchPage?: CustomSearchPage;
}

interface CustomCmsPage {
  readonly properties?: Properties[];
  readonly categoryTags?: string[];
  readonly rows?: CustomCmsRow[];
}

export interface CustomSearchPage {
  readonly properties: Properties[];
  readonly rows: CustomSearchRow[];
}

export class SearchPage implements CustomSearchPage {
  readonly properties: Properties[];
  readonly rows: CustomSearchRow[];
  private readonly uniqueSearchTypes: Set<SearchTypes>;

  constructor(customSearchPage: CustomSearchPage) {
    this.rows = customSearchPage.rows;
    this.properties = customSearchPage.properties;

    this.uniqueSearchTypes = new Set(this.rows.map((row) => row.type));
  }

  public getUniqueSearchTypes(): Set<SearchTypes> {
    return this.uniqueSearchTypes;
  }
}

export interface CustomSearchRow {
  readonly type?: SearchTypes;
  readonly title: string;
  readonly properties?: Properties[];
}

export interface CustomCmsBanner {
  readonly image: string;
  readonly time?: number;
  readonly action: AssetAction;
  readonly size: BannerAspectRatios;
  readonly video?: CustomCmsBannerVideo;
  readonly overlay?: CustomCmsBannerOverlay;
}

export enum BannerAspectRatios {
  ASPECT_RATIO_7_1 = 'ASPECT_RATIO_7_1',
  ASPECT_RATIO_7_2 = 'ASPECT_RATIO_7_2',
  ASPECT_RATIO_7_3 = 'ASPECT_RATIO_7_3',
}

export interface CustomCmsBannerVideo {
  manifests?: CustomCmsBannerVideoManifest[];
}

export interface CustomCmsBannerVideoManifest {
  type: BannerVideoPackages;
  manifest: string;
}

export interface CustomCmsBannerOverlay {
  line1: string;
  line2?: string;
  line3?: string;
}

export enum BannerVideoPackages {
  RAW = 'RAW',
  DASH = 'DASH',
  HLS = 'HLS',
}

export interface CustomCmsRibbon {
  readonly title?: string;
  readonly properties?: Properties[];
  readonly url: string;
  readonly message?: string;
  readonly cardSize: CardSize;
}

export interface CustomCmsGrid {
  readonly title?: string;
  readonly properties?: Properties[];
  readonly url: string;
  readonly message?: string;
}

export interface UrlMapping {
  url: string;
  action: AssetAction;
}

export interface AssetAction {
  type?: CmsContentTypes;
  id?: string;
  method: CmsActionMethod;
}

export enum CardOrientation {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
}

export enum CardSize {
  'SMALL' = 'SMALL',
  'MEDIUM' = 'MEDIUM',
  'NORMAL' = 'NORMAL',
  'LARGE' = 'LARGE',
  'EXTRA_LARGE' = 'EXTRA_LARGE',
}

export class Ribbon2Container {
  type: CardOrientation;

  nextPage?: boolean;
  items?: Ribbon2Asset[] = [];

  constructor(obj?: Ribbon2Container) {
    if (obj) {
      this.type = obj.type;
      this.nextPage = obj.nextPage;
      if (obj.items) {
        this.items = obj.items.map((item) => new Ribbon2Asset(item));
      }
    }
  }
}

// TODO convert to interface
export class Ribbon2Asset {
  asset: Ribbon2Detail;
  method: CmsActionMethod;
  channels?: Ribbon2Channel[];
  resources?: Ribbon2Detail[];

  constructor(obj?: Ribbon2Asset) {
    if (obj) {
      Object.keys(obj).forEach((property) => (this[property] = obj[property]));
    }
  }
}

export interface Ribbon2Detail {
  type: CmsContentTypes;
  subType?: VodAssetTypes;
  id: string;
  season?: number;
  episode?: number;
  image: string;
  title?: string;
  adult?: boolean;
  synopsis?: string;
  productionYear?: number;
  minimumAge?: number;
  score?: number;
  operatorScore?: number;
  resolution?: StreamResolutionType;
  genres?: string[];
  episodeTitle?: string;
  duration?: number;
  channelNumber?: number;
  detailImage?: string;
}

export interface Ribbon2Channel {
  id: string;
  schedules?: Ribbon2Schedule[];
}

export interface Ribbon2Schedule {
  id?: string;
  programId?: string;
  published: ScheduleTime;
  blackouts?: string[];
}

export interface RibbonAsset {
  type: CmsContentTypes;
  subType?: VodAssetTypes;
  id: string;
  title: string;
  image: string;
  season?: number;
  episode?: number;
  adult?: boolean;
}
