import { ContentProvider } from '@atv-shared/content-provider/content-provider';
import { Observable } from 'rxjs';
import { CardOrientation, CmsActionMethod, CmsContentTypes, Ribbon2Asset } from '@atv-core/api/cms';
import { EpgApiService, EpgScheduleAsset, EpgScheduleSummaryAsset } from '@atv-core/api/epg';
import { finalize, map } from 'rxjs/operators';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { EpgUtilityService } from '@atv-core/utility/epg-utility/epg-utility.service';
import { ContentProviderFactoryService } from '@atv-shared/content-provider/content-provider-factory.service';

export class ListContentProvider extends ContentProvider {
  private hasMore = true;

  constructor(
    private epgApiService: EpgApiService,
    private epgUtilityService: EpgUtilityService,
    private contentProviderFactoryService: ContentProviderFactoryService,
    private channel: string,
    private from: Date,
    private until: Date,
    private useReplay: boolean,
    private tags: string[],
  ) {
    super();

    this.orientation = CardOrientation.LANDSCAPE;
  }

  public getItems(): Observable<Ribbon2Asset[]> {
    return this.epgApiService.getScheduleForChannelFromUntil(this.channel, this.from, this.until, this.tags).pipe(
      finalize(() => this.hasMore = false),
      map(result => {
        if (this.useReplay) {
          this.epgUtilityService.sortSchedulesSummaryAssetByStartDateDESC(result.schedules);
          const now = new Date().getTime();
          result.schedules = result.schedules.filter(s => SharedUtilityService.timeStringToMs(s.published.end) < now);
        } else {
          this.epgUtilityService.sortSchedulesSummaryAssetByStartDateASC(result.schedules);
          result.schedules = this.removeSchedulesBeforeNow(result.schedules);
        }

        return result.schedules.map(schedule => this.scheduleToRibbon2Asset(schedule, result)).filter(schedule => schedule);
      }),
    );
  }

  public hasNext(): boolean {
    return this.hasMore;
  }

  public resetPaging(): void {
    this.hasMore = true;
  }

  private scheduleToRibbon2Asset(schedule: EpgScheduleSummaryAsset, allData: EpgScheduleAsset): Ribbon2Asset {
    const program = allData.programs.find(p => p.id === schedule.program);

    if (!program) {
      return undefined;
    }

    return {
      asset: {
        type: CmsContentTypes.SCHEDULE,
        id: schedule.id,
        image: program.posterImage,
      },
      method: CmsActionMethod.DETAIL,
      channels: [{
        id: this.channel,
        schedules: [{ id: schedule.id, programId: schedule.program, published: schedule.published, blackouts: schedule.blackouts }],
      }],
      resources: this.contentProviderFactoryService.createEpgResources(allData.programs, allData.seasons),
    };
  }

  private removeSchedulesBeforeNow(
    schedules: EpgScheduleSummaryAsset[],
  ): EpgScheduleSummaryAsset[] {
    const now = new Date().getTime();

    return schedules.filter((schedule) => {
      const scheduleEnd = SharedUtilityService.timeStringToMs(schedule.published.end);

      return now < scheduleEnd;
    });
  }
}
