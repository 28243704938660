<div class="filter-wrapper" #filterWrapperRef>
  <div class="filter-current-state-wrapper" (smartTvKeyDownEnter)="showOptions()">
    <div class="filter-current-state" (click)="showOptions()">
      <div class="filter-current-state-title">{{ selectedStbTitle }}</div>
      <div class="filter-show-options" *ngIf="!isSmartTv"></div>
    </div>
  </div>
  <div class="filter-options-wrapper" *ngIf="!isSmartTv && !optionsHidden" #optionWrapperRef>
    <div class="filter-options-header" (click)="hideOptions()">
      <div class="filter-options-header-title">{{ filterTitle }}</div>
      <div class="filter-options-close"></div>
    </div>
    <div class="filter-options">
      <div
        class="filter-option"
        [class.selected]="selectedStbIndex === i"
        (click)="changeSelectedStbIndex(i)"
        *ngFor="let stb of filterStbs; index as i"
      >
        <div class="filter-option-title">{{ stb?.alias }}</div>
      </div>
    </div>
  </div>
</div>

<div #overlayDiv *ngIf="isSmartTv && !optionsHidden" class="overlay">
  <div class="overlay-dialog">
    <div
      class="filter-option-smarttv"
      *ngFor="let stb of filterStbs; index as i"
      (smartTvKeyDownEnter)="changeSelectedStbIndex(i)"
      (click)="changeSelectedStbIndex(i)"
      [class.selected]="selectedStbIndex === i"
    >
      {{ stb?.alias }}
    </div>
  </div>
</div>
