import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu } from '@atv-core/api/cms';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { MenuManagerService } from '@atv-core/services/menu-manager/menu-manager.service';
import { SessionService } from '@atv-core/services/session';
import { ProfilePopupModes } from '@atv-shared/popups/profile-popup/profile-selection.component';
import { environment } from '@env/environment';
import { versionInfo } from 'src/app/version-info';

import { MenuService } from './menu.service';
import { SublevelMenuModel, TopLevelMenuModel, TopLevelMenuTypes } from './menuDefinitions.model';
import {
  CatalogTranslationKeys,
  ConfigService,
  LoginTranslationKeys,
  MenuTranslationKeys,
  SettingsTranslationKeys,
} from '@atv-bootstrap/services/config';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuComponent implements OnInit {
  menuOpen = false;

  public loginText = '';
  public logoutText = '';
  public switchProfileText = '';
  public switchCatalogText = '';
  public manageProfileText = '';

  public TopLevelMenuTypes = TopLevelMenuTypes;

  private clickedInside = false;

  constructor(
    private config: ConfigService,
    private router: Router,
    private auth: AuthorizationService,
    private sessionService: SessionService,
    private cdr: ChangeDetectorRef,
    private menuManager: MenuManagerService,
    private menuService: MenuService
  ) {
    document.title = environment.applicationName;

    this.logoutText = this.config.getTranslation(SettingsTranslationKeys.settings_logout);
    this.loginText = this.config.getTranslation(LoginTranslationKeys.login_login);
    this.switchProfileText = this.config.getTranslation(CatalogTranslationKeys.profile_switch);
    this.switchCatalogText = this.config.getTranslation(CatalogTranslationKeys.catalog_switch);
    this.manageProfileText = this.config.getTranslation(CatalogTranslationKeys.profiles_manage);
    this.sessionService.reloadCurrentPageEvent.subscribe(() => {
      this.detectChanges();
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.detectChanges();
      }
    });

    const menu = this.menuManager.currentMenu;
    if (menu) {
      this.initMenu(menu);
    } else if (!this.sessionService.getCatalogId()) {
      this.switchCatalog();
    }

    this.menuManager.updateMenuEvent.subscribe(() => {
      const curMenu = this.menuManager.currentMenu;
      if (curMenu) {
        this.initMenu(curMenu);
      }
    });
  }

  private detectChanges(): void {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  private initMenu(menu: Menu): void {
    this.menuService.createMenu(menu);
    this.detectChanges();
  }

  public switchCatalog(): void {
    this.sessionService.showCatalogSelectionPopup.emit();
  }

  public switchProfile(): void {
    this.sessionService.showProfileSelectionPopup.emit(ProfilePopupModes.SWITCH);
  }

  public manageProfiles(): void {
    this.sessionService.showProfileSelectionPopup.emit(ProfilePopupModes.MANAGE_SELECT);
  }

  public logout(): void {
    this.auth.confirmLogoutPopup.emit(() => {
      this.menuService.setLoggedIn(this.auth.isAuthorized());
    });

    this.detectChanges();
  }

  public login(): void {
    this.auth.gotoOauthLogin();
  }

  public getTopLevelMenu(): TopLevelMenuModel[] {
    return this.menuService.getTopLevelMenus();
  }

  public getSubLevelMenu(): SublevelMenuModel[] {
    return this.menuService.getSubLevelMenus();
  }

  getGreeting(): string {
    return this.menuService.getGreeting();
  }

  getAvatar(): string {
    return this.sessionService.getAvatar();
  }

  getMobileBreadCrumb(): string {
    return this.menuService.getMobileBreadCrumb();
  }

  translate(translationKey: MenuTranslationKeys): string {
    return this.config.getTranslation(translationKey);
  }

  isActiveRoute(route): boolean {
    return this.router.url.startsWith(`/main/${route}`);
  }

  getNightly(): string {
    return environment.nightlyBuild ? 'Androme Nightly Build' : undefined;
  }

  getBuildTime(): string {
    return environment.showBuildTime ? versionInfo.buildTime : undefined;
  }

  getBuildVersion(): string {
    return environment.showBuildVersion ? versionInfo.version : undefined;
  }

  public isAnonymousProfileActive(): boolean {
    return this.menuService.getIsAnonymousProfileActive();
  }

  public anonymousHasMultipleCatalogs(): boolean {
    return this.menuService.getAnonymousHasMultipleCatalogs();
  }

  public isProfileSwitchingAllowed(): boolean {
    return this.menuService.getIsProfileSwitchingAllowed();
  }

  public isLoggedIn(): boolean {
    return this.menuService.getIsLoggedIn();
  }

  public clickedOnMenu(): void {
    this.clickedInside = true;
  }

  @HostListener('document:click', ['$event'])
  onClickedOutside(): void {
    if (!this.clickedInside && this.menuOpen) {
      this.menuOpen = false;
    }
    this.clickedInside = false;
  }
}
