export interface TokenRequestQueryParams {
  client_id: string;
  client_secret: string;
  grant_type: GrantTypes;
  scope?: string;
  code?: string;
  refresh_token?: string;
  username?: string;
  password?: string;
  device_code?: string;
  authorization: string;
}

export enum GrantTypes {
  PASSWORD = 'password',
  REFRESH_TOKEN = 'refresh_token',
  AUTHORIZATION_CODE = 'authorization_code'
}

export interface TokenResponse {
  token_type?: string;
  access_token?: string;
  expires_in?: number;
  refresh_token?: string;
  refresh_token_expires_in?: number;
  device_code?: string;
  user_code?: string;
  verification_uri?: string;
  verification_uri_complete?: string;
  interval?: number;
}
