import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DiscoveryService } from '@atv-bootstrap/services/discovery';
import { Observable } from 'rxjs';

import { TokenRequestQueryParams, TokenResponse } from './oauth-api.model';

@Injectable({
  providedIn: 'root',
})
export class OauthApiService {
  constructor(private disc: DiscoveryService, private http: HttpClient) {}

  public requestToken(token: TokenRequestQueryParams): Observable<TokenResponse> {
    let params = '';
    Object.keys(token).forEach((key) => {
      params += (params ? '&' : '') + key + '=' + encodeURIComponent(token[key]);
    });
    return this.http.post<TokenResponse>(`${this.disc.location}/oauth/v1/auth/token`, params, {
      headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded'),
    });
  }
}
