import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { ConfigService } from '@atv-bootstrap/services/config';
import { CatalogTranslationKeys, LoginTranslationKeys } from '@atv-bootstrap/services/config/config.model';
import { MessagesService } from '@atv-core/services/messages/messages.service';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SessionStorageKeys } from '@atv-core/utility/constants/sessionStorageKeys';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-auth-popup',
  templateUrl: './auth-popup.component.html',
  styles: [],
})
export class AuthPopupComponent implements OnInit, OnDestroy {
  public showAuthPopup = false;
  public popupTitle = '';
  public signInButtonTitle = '';
  public cancelButtonTitle = '';

  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnSubscription: Subscription = null;

  constructor(
    private config: ConfigService,
    private auth: AuthorizationService,
    private messagesService: MessagesService,
    private spatialNavigationService: SpatialNavigationService,
    private router: Router,
    private zone: NgZone
  ) {
    this.popupTitle = this.config.getTranslation(
      CatalogTranslationKeys.profile_login_request_popup_title
    );
    this.signInButtonTitle = this.config.getTranslation(LoginTranslationKeys.login_login);
    this.cancelButtonTitle = this.config.getTranslation(CatalogTranslationKeys.profile_cancel_btn);

    this.auth.showAuthPopup.subscribe(() => {
      this.showAuthPopup = true;

      if (this.isSmartTv) {
        window.setTimeout(() => {
          this.spatialNavigationService.unregister(NavigationSections.POPUP_BUTTONS);
          this.spatialNavigationService.register(
            NavigationSections.POPUP_BUTTONS,
            '.button-dialog .button-title',
            {
              restrict: 'self-only',
            }
          );
          this.spatialNavigationService.setFocus(NavigationSections.POPUP_BUTTONS);
          this.returnSubscription = fromEvent(
            document.getElementsByTagName('app-auth-popup')[0],
            'keyup'
          )
            .pipe(
              filter((event: KeyboardEvent) =>
                this.spatialNavigationService.keyCodeIsReturn(event.keyCode)
              )
            )
            .subscribe((event: KeyboardEvent) => {
              event.cancelBubble = true;
              this.closePopup();
            });
        }, 100);
      }
    });
  }

  ngOnInit(): void {}

  closePopup(): void {
    this.showAuthPopup = false;
    this.clearSpatialNavigation();

    if (window.location.pathname === '/') {
      this.messagesService.showErrorMessage(
        this.config.getTranslation(CatalogTranslationKeys.profile_login_request_popup_title)
      );
      this.router.navigateByUrl('/?error=login_failed');
    }
  }

  goToOauth(): void {
    sessionStorage.setItem(SessionStorageKeys.login_succes_redirect_url, location.pathname);

    if (!this.isSmartTv) {
      this.auth.gotoOauthLogin();
    } else {
      this.closePopup();
      this.zone.run(() => {
        this.router.navigate([{ outlets: { detailOverlay: null } }]);
        window.setTimeout(() => {
          this.router.navigate(['/account/login']);
        }, 0);
      });
    }
  }

  private clearSpatialNavigation(): void {
    if (this.isSmartTv) {
      this.spatialNavigationService.unregister(NavigationSections.POPUP_BUTTONS);
      this.spatialNavigationService.setFocus();
    }

    if (this.returnSubscription) {
      this.returnSubscription.unsubscribe();
      this.returnSubscription = null;
    }
  }

  ngOnDestroy(): void {
    this.clearSpatialNavigation();
  }
}
