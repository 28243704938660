import { InjectionToken } from '@angular/core';
import { CmsAction, CmsActionMethod, CmsContentTypes } from '@atv-core/api/cms';
import { PlayInfoModel } from '@atv-player/model/play-info-model';

import { AdultService } from '../adult';
import { ConfigService, DetailTranslationKeys } from '../../../bootstrap/services/config';
import { MessagesService } from '../messages';
import { PlayerService } from '../player/player.service';

export const CMS_ACTION_HANDLER = new InjectionToken<CmsActionHandler[]>('cms-action-handler');

export abstract class CmsActionHandler {
  constructor(
    protected adultService?: AdultService,
    protected playerService?: PlayerService,
    protected messagesService?: MessagesService,
    protected config?: ConfigService
  ) {}

  abstract handlesMethod(method: CmsActionMethod): boolean;
  abstract handlesType(type: CmsContentTypes): boolean;
  abstract handleAction(action: CmsAction, forceFullscreen?: boolean): void;

  protected checkAdultAndPlay(playInfo: PlayInfoModel): void {
    if (playInfo.isAdult) {
      this.adultService?.checkAdultMode({
        successCallback: () => {
          this.playerService?.playAsset(playInfo, false);
        },
        errorCallback: () => {
          this.messagesService?.showErrorMessage(
            this.config?.getTranslation(DetailTranslationKeys.detail_adult_warning),
          );
        },
      });
    } else {
      this.playerService?.playAsset(playInfo, false);
    }
  }
}
