<div
  class="player-asset-info-detail-wrapper"
  [class.player-asset-info-detail-open]="epgInfoInput?.detailOpen"
>
  <div class="detail-backdrop">
    <div class="detail-backdrop-gradient-left-right"></div>
    <div class="detail-backdrop-gradient-top-bottom"></div>
    <app-content-backdrop [image]="epgDetailVisual.backdrop"></app-content-backdrop>
  </div>
  <div class="detail-info-wrapper">
    <div class="detail-info">
      <div class="detail-info-title">{{ epgDetailVisual.title }}</div>
      <div class="detail-info-episode-title" *ngIf="epgDetailVisual.episodeTile">
        {{ epgDetailVisual.episodeTile }}
      </div>
      <div class="detail-info-season-episode">{{ epgDetailVisual.seasonEpisodeInfo }}</div>
      <div class="detail-info-channel-schedule-info">
        <span class="detail-channel">{{ epgDetailVisual.channelInfo }}</span>
        <span class="detail-schedule">{{ epgDetailVisual.scheduleInfo }}</span>
      </div>
      <app-progress-bar
        class="detail-info-progress"
        [class.hidden]="epgDetailVisual.progressPercentage === undefined"
        [progressPercentage]="epgDetailVisual.progressPercentage"
      ></app-progress-bar>
      <div class="detail-info-synopsis">{{ epgDetailVisual.synopsisInfo }}</div>
      <div class="detail-info-asset-short">
        <div class="detail-info-asset-short-info">{{ epgDetailVisual.programShortInfo }}</div>
        <img
          *ngIf="epgDetailVisual.minAgeImage"
          class="detail-info-asset-minage"
          src="{{ epgDetailVisual.minAgeImage }}"
        />
      </div>
      <div class="detail-info-credits" [class.hidden]="noCredits()">
        <div class="detail-credit-item">
          <span class="detail-credit-title">{{ epgDetailVisual.directorsInfo.creditTitle }}</span
          ><span class="detail-credit-content">{{ epgDetailVisual.directorsInfo.content }}</span>
        </div>

        <div class="detail-credit-item">
          <span class="detail-credit-title">{{ epgDetailVisual.actorsInfo.creditTitle }}</span
          ><span class="detail-credit-content">{{ epgDetailVisual.actorsInfo.content }}</span>
        </div>
      </div>
    </div>
    <div class="detail-info-right">
      <div
        class="detail-epg-poster"
        [class.hidden]="!epgDetailVisual.poster"
        [ngStyle]="{
          'background-image': epgDetailVisual.poster ? 'url(' + epgDetailVisual.poster + ')' : ''
        }"
      >
        <div class="card-detail-channel">
          <div class="card-detail-channel-image">
            <img src="{{ epgDetailVisual.channelImage }}" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="player-asset-info-wrapper">
  <div
    class="player-asset-info-prev"
    (click)="showNextPrevProgramInfo(false)"
    (touchstart)="showNextPrevProgramInfo(false); $event.preventDefault(); $event.stopPropagation()"
    [class.hidden]="!canPrevProgram()"
  ></div>
  <div class="player-asset-info">
    <div class="player-asset-info-title">{{ getTitleText() }}</div>
    <div class="player-asset-info-time">{{ getTimeText() }}</div>
    <button
      *ngIf="openDetailText && epgDetailVisual?.canShowMoreDetail"
      class="player-action-button button-title show-more-detail"
      (click)="openDetail()"
      (touchstart)="openDetail(); $event.preventDefault(); $event.stopPropagation()"
    >
      {{ openDetailText }}
    </button>
  </div>
  <div
    class="player-asset-info-next"
    (click)="showNextPrevProgramInfo(true)"
    (touchstart)="showNextPrevProgramInfo(true); $event.preventDefault(); $event.stopPropagation()"
    [class.hidden]="!canNextProgram()"
  ></div>
</div>
