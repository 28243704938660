import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { DiscoveryService } from '@atv-bootstrap/services/discovery';
import { ConfigService } from '@atv-bootstrap/services/config';
import { concatMap } from 'rxjs/operators';

function initApp(discoveryService: DiscoveryService, configService: ConfigService): () => Promise<unknown> {
  return () => discoveryService.discover().pipe(concatMap(() => configService.loadConfig())).toPromise();
}

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    DiscoveryService,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [DiscoveryService, ConfigService],
      multi: true,
    },
  ],
})
export class BootstrapModule {
}
