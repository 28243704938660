export enum RecordingIcons {
  folder = './assets/theme/svg/recording_folder.svg',
  planned = './assets/theme/svg/recording_planned.svg',
  inProgress = './assets/theme/svg/recording_inprogress.svg',
  recorded = './assets/theme/svg/recording_recorded.svg',
  error = './assets/theme/svg/recording_error.svg',
  inProgressError = './assets/theme/svg/recording_error.svg',
  inProgressConflict = './assets/theme/svg/recording_inprogress_conflict.svg',
  plannedConflict = './assets/theme/svg/recording_conflict.svg'
}
