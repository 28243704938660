import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExitPopupService {
  private showExitPopupSubject = new Subject<boolean>();

  constructor() {
  }

  public showExitPopup(): void {
    this.showExitPopupSubject.next(true);
  }

  public observe(callback: (status: boolean) => void): Subscription {
    return this.showExitPopupSubject.subscribe(callback);
  }
}
