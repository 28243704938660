import { Injectable } from '@angular/core';
import { ProfileElement } from '@atv-core/api/aa/aa-api.model';
import { AaApiService } from '@atv-core/api/aa/aa-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CatalogTranslationKeys, ConfigService, SettingsKeys } from '../../../bootstrap/services/config';
import { MessagesService } from '../messages';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private maxLengthProfileName: number;

  constructor(
    private aaApi: AaApiService,
    private config: ConfigService,
    private messagesService: MessagesService
  ) {
    this.maxLengthProfileName = this.config.getSettingNumber(SettingsKeys.maxLengthProfileName, 25);
  }

  public loadProfiles(): Observable<ProfileElement[]> {
    return this.aaApi.getAllCustomerProfiles().pipe(
      map((result) => {
        const masterIndex = result.findIndex((item) => item.master);
        // put master element in front
        result.unshift(result.splice(masterIndex, 1)[0]);
        result.forEach((res) => {
          if (res.profile.name.length > this.maxLengthProfileName) {
            res.profile.name = res.profile.name.substring(0, this.maxLengthProfileName) + '...';
          }
        });

        return result;
      })
    );
  }

  public isNameValid(name: string): boolean {
    if (!name) {
      this.messagesService.showErrorMessage(
        this.config.getTranslation(CatalogTranslationKeys.profile_error_name)
      );
      return false;
    }

    if (name.length > this.maxLengthProfileName) {
      let message = this.config.getTranslation(CatalogTranslationKeys.profile_error_name_length);

      if (message) {
        message = message.replace('$1', this.maxLengthProfileName.toString());
      }

      this.messagesService.showErrorMessage(message);

      return false;
    }

    return true;
  }
}
