import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Properties } from '@atv-core/api/cms';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { ChannelModel } from '@atv-core/services/cache/channel';
import { ChannelRightCacheService } from '@atv-core/services/cache/channelRights';
import { ConfigService, MenuTranslationKeys } from '@atv-bootstrap/services/config';
import { DetailOverlayService } from '@atv-core/services/detail/detail-overlay.service';
import { PageContext, PageUrlTypes } from '@atv-core/services/log/log.model';
import { LogService } from '@atv-core/services/log/log.service';
import { PageData } from '@atv-core/services/menu-manager/menu-manager.service';
import { MessagesService } from '@atv-core/services/messages';
import { SessionService } from '@atv-core/services/session/session.service';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { EpgUtilityService } from '@atv-core/utility/epg-utility/epg-utility.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { ReloadablePageComponent } from '@atv-pages/reloadable-page.component';
import { ContentFilters, FilterContentTypes, FilterModel } from '@atv-shared/filters/filters.model';
import { GuideCategoryFilterComponent } from '@atv-shared/filters/guide-category-filter/guide-category-filter.component';
import { ChannelScheduleComponent } from '@atv-shared/guide/channel-schedule/channel-schedule.component';
import { DaySelectorService } from '@atv-shared/guide/day-selector/day-selector.service';
import { TimelineComponent } from '@atv-shared/guide/timeline/timeline.component';
import { NumberEntryComponent } from '@atv-shared/number-entry/number-entry.component';
import { environment } from '@env/environment';
import moment from 'moment';
import { BehaviorSubject, fromEvent as observableFromEvent, fromEvent, interval, Observable, of, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { PlayerService } from '../../atv-core/services/player/player.service';

export interface GuideFocusEvent {
  channel: string;
  currentOffsetLeft?: number;
}

@Component({
  selector: 'app-guide-page',
  templateUrl: './guide-page.component.html',
  styleUrls: ['./guide-page.component.scss'],
})
export class GuidePageComponent extends ReloadablePageComponent implements OnInit, AfterViewInit, OnDestroy {
  public guideChannels: ChannelModel[];

  public replay = false;
  public allowedAdultMode: AdultMode = AdultMode.any;

  selectedDate: Date;
  currentTimePosition = 0;
  private scrollSubscription: Subscription;
  private timerSubscription: Subscription;
  private channelChildrenSubscription: Subscription;
  private initialized = false;

  @ViewChildren(ChannelScheduleComponent) channelChildren: QueryList<ChannelScheduleComponent>;
  @ViewChild('guideContainer') guideContainer: ElementRef<HTMLDivElement>;
  @ViewChild('categoryFilter') categoryFilter: GuideCategoryFilterComponent;
  @ViewChild('currentTime') currentTime: ElementRef<HTMLDivElement>;
  @ViewChild('scheduleContainer') scheduleContainer: ElementRef<HTMLDivElement>;
  @ViewChild('horizontalScroller') horizontalScroller: ElementRef<HTMLDivElement>;
  @ViewChild('channelContainer') channelContainer: ElementRef<HTMLDivElement>;
  @ViewChild('timeline') timeline: TimelineComponent;
  @ViewChild('timelineScheduleContainer') timelineScheduleContainer: ElementRef<HTMLDivElement>;

  scheduleItemIntersectionObserver: IntersectionObserver;
  channelSchedulesIntersectionObserver: IntersectionObserver;

  public guideFilterModel: FilterModel;
  public categoryFilterType = FilterContentTypes.EPG;
  public categoryTags: string[] = [];

  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnSubscription: Subscription = null;
  private numberEventSubscription: Subscription = null;
  public guideFocusEvent = new EventEmitter<GuideFocusEvent>();
  public guideScrollEvent = new BehaviorSubject<number>(0);
  private mutationObserver: MutationObserver;

  constructor(
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
    private log: LogService,
    config: ConfigService,
    sessionService: SessionService,
    private channelCacheService: ChannelRightCacheService,
    authorizationService: AuthorizationService,
    adultService: AdultService,
    messageService: MessagesService,
    private spatialNavigationService: SpatialNavigationService,
    private daySelectorService: DaySelectorService,
    private detailService: DetailOverlayService,
    private epgUtility: EpgUtilityService,
    private playerService: PlayerService,
  ) {
    super(sessionService, authorizationService, adultService, messageService, config);

    const pageData = this.activatedRoute.snapshot.data;
    if (pageData.properties.includes(Properties.FOCUS_FUTURE)) {
      this.replay = false;
    } else if (pageData.properties.includes(Properties.FOCUS_PAST)) {
      this.replay = true;
    }
  }

  ngOnInit(): void {
    if (!this.scheduleItemIntersectionObserver) {
      this.scheduleItemIntersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !entry.target.classList.contains('in-view')) {
            entry.target.classList.add('in-view');
          } else if (!entry.isIntersecting && entry.target.classList.contains('in-view')) {
            entry.target.classList.remove('in-view');
          }
        });
      });
    }

    if (!this.channelSchedulesIntersectionObserver) {
      this.channelSchedulesIntersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !entry.target.classList.contains('in-view')) {
              entry.target.classList.add('in-view');
            } else if (!entry.isIntersecting && entry.target.classList.contains('in-view')) {
              entry.target.classList.remove('in-view');
            }

            if (
              entry.target.classList.contains('schedule-grid') &&
              entry.target.classList.contains('in-view')
            ) {
              this.channelChildren.forEach((channelSchedules) =>
                channelSchedules.fetchSchedulesIfInView(),
              );
            }
          });
        },
        {
          rootMargin: '100px',
        },
      );
    }

    super.start();
  }

  ngAfterViewInit(): void {
    this.updateCurrentTimePosition();
    this.detectChanges();

    if (this.channelChildrenSubscription) {
      this.channelChildrenSubscription.unsubscribe();
    }

    if (this.channelChildren.length > 0) {
      this.addEventListeners();
    } else {
      this.channelChildrenSubscription = this.channelChildren.changes.subscribe(() => {
        this.addEventListeners();
      });
    }
    this.daySelectorService.dateSelectedEvent.subscribe((date) => this.dateSelected(date));

    if (this.isSmartTv) {
      // translate channel div when schedule div is transformed (scrolled)
      this.mutationObserver = new MutationObserver((records) => {
        records.forEach((record) => {
          if (record.target === this.scheduleContainer.nativeElement) {
            // replace comma by dot, this gives issues on Tizen
            this.channelContainer.nativeElement.style.transform = this.scheduleContainer.nativeElement.style.transform.replace(',', '.');
          } else if (record.target === this.horizontalScroller.nativeElement) {
            this.updateHorizontalScroll();
          }
        });
      });
      this.mutationObserver.observe(this.scheduleContainer.nativeElement, {
        attributes: true,
        attributeFilter: ['style'],
      });

      this.mutationObserver.observe(this.horizontalScroller.nativeElement, {
        attributes: true,
        attributeFilter: ['offsetleft'],
      });
      this.updateHorizontalScroll(); // set in case scrollLeft has already been done
    }

    if (this.timelineScheduleContainer) {
      this.timelineScheduleContainer.nativeElement.onscroll = () => {
        this.timelineScheduleContainer.nativeElement.scrollLeft = 0;
      };
    }
  }

  public loadPage(): void {
    this.channelCacheService
      .getValidChannels(this.replay, this.sessionService.getCatalogId())
      .subscribe(
        (data) => {
          this.initialized = false;
          this.guideChannels = data.validChannels;
          this.loadGuideWrapper();
        },
        () => {
          console.error('Could not load channels');
        },
      );

    if (this.isSmartTv) {
      this.zone.runOutsideAngular(() => {
        this.returnSubscription = fromEvent(document, 'keydown')
          .pipe(
            filter((event: KeyboardEvent) =>
              this.spatialNavigationService.keyCodeIsReturn(event.keyCode),
            ),
          )
          .subscribe((event: KeyboardEvent) => {
            if (
              this.spatialNavigationService.getCurrentSection() === NavigationSections.SCHEDULES
            ) {
              this.spatialNavigationService.setFocus(NavigationSections.FILTERS);
              event.cancelBubble = true;
              event.preventDefault();
            } else if (
              this.spatialNavigationService.getCurrentSection() === NavigationSections.FILTERS
            ) {
              this.spatialNavigationService.setFocus(NavigationSections.MENU);
              event.cancelBubble = true;
              event.preventDefault();
            }
          });
      });

      this.detailService.closeDetailEvent.subscribe(() => {
        this.spatialNavigationService.setFocus(
          this.spatialNavigationService.getLastScheduleElement(),
        );
      });
    }
  }

  getPageProperties(): Observable<Properties[]> {
    return of((this.activatedRoute.snapshot.data as PageData).properties);
  }

  loadGuideWrapper(): void {
    this.guideFilterModel = new FilterModel();
    this.guideFilterModel.activeCmsFilters.push(ContentFilters.CATEGORY);

    this.log.pageView(
      new PageContext({
        pageURL: this.replay ? PageUrlTypes.replay_epg_guide : PageUrlTypes.epg_guide,
        pageTitle: this.config.getTranslation(MenuTranslationKeys.menu_guide),
        pageLocale: this.config.getLocale(),
      }),
    );

    this.detectChanges();

    if (this.isSmartTv) {
      this.registerElements();
    }
  }

  private detectChanges(): void {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  private updateHorizontalScroll(): void {
    let offsetLeft = parseInt(this.horizontalScroller.nativeElement.getAttribute('offsetleft'), 10);
    offsetLeft = Math.max(0, offsetLeft); // clip left
    offsetLeft = Math.min(
      this.horizontalScroller.nativeElement.scrollWidth -
      this.horizontalScroller.nativeElement.clientWidth,
      offsetLeft,
    ); // clip right

    this.horizontalScroller.nativeElement.style.transform = `translateX(-${offsetLeft}px)`;
    this.timeline.setTransform(this.horizontalScroller.nativeElement.style.transform);
    this.guideScrollEvent.next(offsetLeft);
  }

  private unregisterElements(): void {
    this.spatialNavigationService.unregister(NavigationSections.FILTERS);

    if (this.numberEventSubscription) {
      this.numberEventSubscription.unsubscribe();
      this.numberEventSubscription = null;
    }
  }

  private registerElements(): void {
    this.unregisterElements();
    this.spatialNavigationService.register(
      NavigationSections.FILTERS,
      'app-guide-category-filter .filter-current-state-wrapper, ' +
      'app-day-selector-smarttv .filter-current-state-wrapper, ' +
      'app-clear-filter .filter-current-state-wrapper',
      { leaveFor: { left: '@' + NavigationSections.MENU, right: '' } },
    );
    this.spatialNavigationService.setDefaultSection(NavigationSections.FILTERS);

    if (NumberEntryComponent.numberEvent) {
      this.numberEventSubscription = NumberEntryComponent.numberEvent.subscribe((n: number) => {
        if (this.playerService.playerVisibilitySubject.getValue()) {
          return;
        }

        const channel = this.epgUtility.getChannelByNumber(
          this.guideChannels,
          n,
          this.sessionService.getCatalogId(),
        );

        if (channel) {
          const channelSchedule = this.channelChildren.find((c) => c.channel.id === channel.id);
          if (channelSchedule) {
            channelSchedule.zapTo();
          }
        }
      });
    }
  }

  guideFilterChange(): void {
    this.categoryTags = this.guideFilterModel.categoryFilterOutputTags;
  }

  private addEventListeners(): void {
    const date = parseInt(this.activatedRoute.snapshot.queryParams.date, 10);
    const y = parseInt(this.activatedRoute.snapshot.queryParams.y, 10);

    const scrollLeft = date
      ? moment(date).diff(moment(date).startOf('day'), 'hours') *
        environment.atv_guide_width_per_hour
      : this.currentTimePosition - environment.atv_guide_width_per_hour / 2;
    if (!this.isSmartTv) {
      this.guideContainer.nativeElement.scrollLeft = scrollLeft;
    } else {
      this.horizontalScroller.nativeElement.setAttribute('offsetleft', `${scrollLeft}`);
    }

    this.guideContainer.nativeElement.scrollTop = y ?? 0;

    this.scrollSubscription = observableFromEvent(this.guideContainer.nativeElement, 'scroll')
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.updateCoords();
      });

    this.initialized = true;
    if (this.channelChildrenSubscription) {
      this.channelChildrenSubscription.unsubscribe();
    }
  }

  dateSelected(date: moment.Moment): void {
    const previous = this.selectedDate || date.toDate();
    this.selectedDate = date.toDate();
    this.detectChanges();

    if (this.initialized && this.selectedDate.getTime() !== previous.getTime()) {
      this.channelChildren.forEach((channelSchedules) => channelSchedules.dateChange());
    }

    if (this.isTodaySelected()) {
      if (!this.timerSubscription) {
        this.zone.runOutsideAngular(() => {
          this.timerSubscription = interval(5000).subscribe(() => this.updateCurrentTimePosition());
        });
      }

      this.updateCurrentTimePosition();

      const scrollLeft = this.currentTimePosition - environment.atv_guide_width_per_hour / 2;
      if (!this.isSmartTv) {
        this.guideContainer.nativeElement.scrollLeft = scrollLeft;
      } else {
        this.horizontalScroller.nativeElement.setAttribute('offsetleft', `${scrollLeft}`);
      }
    } else if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }

    this.timeline.update(date);
    this.updateCoords();
  }

  private updateCoords(): void {
    if (!this.initialized) {
      return;
    }

    const current = moment(this.selectedDate).add(
      this.guideContainer.nativeElement.scrollLeft / environment.atv_guide_width_per_hour,
      'hours',
    );

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        date: current.valueOf(),
        y: this.guideContainer.nativeElement.scrollTop,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private updateCurrentTimePosition(): void {
    // no need to trigger change detection for the complete app
    this.zone.runOutsideAngular((time: DOMHighResTimeStamp) => {
      const hours = moment.duration(moment().diff(moment().startOf('day'))).as('hours');
      this.currentTimePosition = hours * environment.atv_guide_width_per_hour;
      this.currentTime.nativeElement.style.left = this.currentTimePosition + 'px';
    });
  }

  isTodaySelected(): boolean {
    return moment().startOf('day').isSame(this.selectedDate);
  }

  public clearAllFilters(): void {
    this.daySelectorService.setSelectedDate();
    this.categoryFilter.resetFilter();
  }

  ngOnDestroy(): void {
    if (this.scheduleItemIntersectionObserver) {
      this.scheduleItemIntersectionObserver.disconnect();
    }

    if (this.channelSchedulesIntersectionObserver) {
      this.channelSchedulesIntersectionObserver.disconnect();
    }

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }

    if (this.channelChildrenSubscription) {
      this.channelChildrenSubscription.unsubscribe();
    }

    if (this.returnSubscription) {
      this.returnSubscription.unsubscribe();
    }

    super.stop();

    if (this.isSmartTv) {
      this.unregisterElements();
      this.mutationObserver?.disconnect();
    }
  }
}
