import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DiscoveryService } from '@atv-bootstrap/services/discovery';
import { Observable } from 'rxjs';

import { CustomerSettings, DeviceSettings } from './../../utility/constants/shared';
import {
  BookmarkContentTypes,
  BookmarkElement,
  BookmarkUpdate,
  CustomerSettingResult,
  DeviceSettingResult,
  FavoriteContentTypes,
  FavoriteElement,
  ReminderContentTypes,
  ReminderElement,
  ReminderUpdate,
} from './history-api.model';

@Injectable({ providedIn: 'root' })
export class HistoryApiService {
  constructor(private disc: DiscoveryService, private http: HttpClient) {}

  public getFavorites(profileId: string, catalogId: string): Observable<FavoriteElement[]> {
    const queryParams = new HttpParams()
      .append('profileId', profileId)
      .append('catalogId', catalogId);
    return this.http.get<FavoriteElement[]>(`${this.disc.location}/history/v1/favorite`, {
      params: queryParams,
    });
  }

  public getFavorite(
    profileId: string,
    type: FavoriteContentTypes,
    id: string
  ): Observable<FavoriteElement> {
    const queryParams = new HttpParams().append('profileId', profileId);
    return this.http.get<FavoriteElement>(
      `${this.disc.location}/history/v1/favorite/${type}/${id}`,
      { params: queryParams }
    );
  }

  public setFavorite(profileId: string, type: FavoriteContentTypes, id: string): Observable<void> {
    const queryParams = new HttpParams().append('profileId', profileId);
    const body = {};
    return this.http.put<void>(`${this.disc.location}/history/v1/favorite/${encodeURIComponent(type)}/${encodeURIComponent(id)}`, body, {
      params: queryParams,
    });
  }

  public deleteFavorite(
    profileId: string,
    type: FavoriteContentTypes,
    id: string
  ): Observable<void> {
    const queryParams = new HttpParams().append('profileId', profileId);
    return this.http.delete<void>(`${this.disc.location}/history/v1/favorite/${encodeURIComponent(type)}/${encodeURIComponent(id)}`, {
      params: queryParams,
    });
  }

  public getBookmarks(profileId: string, catalogId: string): Observable<BookmarkElement[]> {
    const queryParams = new HttpParams()
      .append('profileId', profileId)
      .append('catalogId', catalogId);
    return this.http.get<BookmarkElement[]>(`${this.disc.location}/history/v1/bookmark`, {
      params: queryParams,
    });
  }

  public setBookmark(
    profileId: string,
    type: BookmarkContentTypes,
    id: string,
    update: BookmarkUpdate
  ): Observable<BookmarkElement[]> {
    const queryParams = new HttpParams().append('profileId', profileId);
    return this.http.put<BookmarkElement[]>(
      `${this.disc.location}/history/v1/bookmark/${encodeURIComponent(type)}/${encodeURIComponent(id)}`,
      update,
      {
        params: queryParams,
      }
    );
  }

  public getBookmark(
    profileId: string,
    type: BookmarkContentTypes,
    id: string
  ): Observable<BookmarkElement> {
    const queryParams = new HttpParams().append('profileId', profileId);
    return this.http.get<BookmarkElement>(
      `${this.disc.location}/history/v1/bookmark/${encodeURIComponent(type)}/${encodeURIComponent(id)}`,
      { params: queryParams }
    );
  }

  public deleteBookmark(
    profileId: string,
    type: BookmarkContentTypes,
    id: string
  ): Observable<void> {
    const queryParams = new HttpParams().append('profileId', profileId);
    return this.http.delete<void>(`${this.disc.location}/history/v1/bookmark/${encodeURIComponent(type)}/${encodeURIComponent(id)}`, {
      params: queryParams,
    });
  }

  public getSettingForDevice(
    deviceId: string,
    setting: DeviceSettings,
  ): Observable<DeviceSettingResult> {
    return this.http.get<DeviceSettingResult>(
      `${this.disc.location}/history/v1/settings/${encodeURIComponent(deviceId)}/${encodeURIComponent(setting)}`
    );
  }
  public setSettingForDevice(
    deviceId: string,
    setting: DeviceSettings,
    val: string,
  ): Observable<DeviceSettingResult> {
    const body = { value: val };
    return this.http.put<DeviceSettingResult>(
      `${this.disc.location}/history/v1/settings/${encodeURIComponent(deviceId)}/${encodeURIComponent(setting)}`,
      body
    );
  }

  public getAllDeviceSettings(deviceId: string): Observable<DeviceSettingResult[]> {
    return this.http.get<DeviceSettingResult[]>(
      `${this.disc.location}/history/v1/settings/${encodeURIComponent(deviceId)}`
    );
  }

  public getCustomerSetting(setting: CustomerSettings): Observable<CustomerSettingResult> {
    return this.http.get<CustomerSettingResult>(
      `${this.disc.location}/history/v1/customer/settings/${encodeURIComponent(setting)}`
    );
  }

  public setCustomerSetting(
    setting: CustomerSettings,
    value: string
  ): Observable<CustomerSettingResult> {
    const body = { value };
    return this.http.put<CustomerSettingResult>(
      `${this.disc.location}/history/v1/customer/settings/${encodeURIComponent(setting)}`,
      body
    );
  }

  public getReminders(profileId: string): Observable<ReminderElement[]> {
    const queryParams = new HttpParams().append('profileId', profileId);
    return this.http.get<ReminderElement[]>(`${this.disc.location}/history/v1/reminder`, {
      params: queryParams,
    });
  }

  public deleteReminder(profileId: string, id: string): Observable<void> {
    const queryParams = new HttpParams().append('profileId', profileId);
    return this.http.delete<void>(
      `${this.disc.location}/history/v1/reminder/${encodeURIComponent(ReminderContentTypes.SCHEDULE)}/${encodeURIComponent(id)}`,
      { params: queryParams }
    );
  }

  public setReminder(profileId: string, id: string, beforeTime: number): Observable<void> {
    const queryParams = new HttpParams().append('profileId', profileId);
    const body: ReminderUpdate = {
      minutes: beforeTime || 0,
    };
    return this.http.put<void>(
      `${this.disc.location}/history/v1/reminder/${encodeURIComponent(ReminderContentTypes.SCHEDULE)}/${encodeURIComponent(id)}`,
      body,
      { params: queryParams }
    );
  }
}
