import { Recording } from '@atv-core/api/recording/recording-api.model';
import { AtvFeatures } from '@atv-core/utility/constants/atv_features';
import { RecordingIcons } from '@atv-core/utility/constants/icon_translations';
import { InfernoFailureReason, InfernoState, StreamType } from '@atv-core/utility/constants/shared';
import { environment } from '@env/environment';

import { StbList } from '../../session/stb-list';
import { SharedUtilityService } from './../../../utility/shared/shared-utility';
import { ChannelModel } from './../channel/channel.model';

export class RecordingModel implements Recording {
  public identifier: string;
  public device: string;
  public version?: number;
  public schedule: string;
  public program: string;
  public programOrdinal?: number;
  public season?: string;
  public seasonOrdinal?: number;
  public series?: string;
  public channel: string;
  public start: string;
  public end: string;
  public plannedStart: string;
  public plannedEnd: string;
  public effectiveStart?: string;
  public effectiveEnd?: string;
  public beginBuffer?: number;
  public endBuffer?: number;
  public deleteProtected?: boolean;
  public infernoIdentifier?: string;
  public infernoState?: string;
  public infernoFailureReason?: string;
  public infernoSize?: number;
  public programSubscription?: string;
  public seasonSubscription?: string;
  public seriesSubscription?: string;
  public conflicts?: string[];
  public adult?: boolean;
  public expiration?: string;

  public aboutToExpireSetting: number;

  constructor(recording: Recording, aboutToExpireSetting: number) {
    Object.keys(recording).forEach((key) => (this[key] = recording[key]));

    this.aboutToExpireSetting = aboutToExpireSetting;
  }

  public isPlanned() {
    const now = new Date().getTime();

    const start = SharedUtilityService.timeStringToMs(this.plannedStart);
    const begin = this.beginBuffer;

    return start - begin >= now;
  }

  public isInProgress(): boolean {
    const now = new Date().getTime();

    const end = SharedUtilityService.timeStringToMs(this.plannedEnd);
    const start = SharedUtilityService.timeStringToMs(this.plannedStart);

    return end + this.endBuffer > now && start - this.beginBuffer < now;
  }

  public isRecorded(): boolean {
    const now = new Date().getTime();

    const end = SharedUtilityService.timeStringToMs(this.plannedEnd);

    return end + this.endBuffer <= now;
  }

  public hasConflict() {
    if (this.infernoState === InfernoState.pendingWithConflict) {
      return true;
    } else if (
      this.infernoState === InfernoState.valueInProgressWithError &&
      this.infernoFailureReason === InfernoFailureReason.insufficient_resources
    ) {
      return true;
    }
    return false;
  }

  public isAboutToExpire(): boolean {
    const expires = new Date();
    const interval = this.aboutToExpireSetting;
    expires.setDate(expires.getDate() + interval);
    return SharedUtilityService.timeStringToMs(this.expiration) < expires.getTime();
  }

  public duration(): number {
    return (
      SharedUtilityService.timeStringToMs(this.plannedEnd) -
      SharedUtilityService.timeStringToMs(this.plannedStart)
    );
  }

  public totalDuration(): number {
    return this.duration() + this.beginBuffer + this.endBuffer;
  }

  public hasError() {
    if (
      this.infernoState === InfernoState.failed ||
      this.infernoState === InfernoState.contentDeleted ||
      this.infernoState === InfernoState.unresolved
    ) {
      return true;
    }
    return this.inProgressWithError();
  }

  public inProgressWithError() {
    return (
      this.infernoState === InfernoState.inProgressWithErrorNoSignal ||
      this.infernoState === InfernoState.inProgressWithErrorWasBeingRecorded ||
      this.infernoState === InfernoState.inProgressWithErrorWasBeingRecordedNoSignal
    );
  }

  public getIcon() {
    if (this.isRecorded()) {
      if (this.hasConflict() || this.hasError()) {
        return RecordingIcons.error;
      } else {
        return RecordingIcons.recorded;
      }
    } else if (this.isInProgress()) {
      if (this.hasConflict()) {
        return RecordingIcons.inProgressConflict;
      } else if (this.hasError()) {
        return RecordingIcons.inProgressError;
      } else {
        return RecordingIcons.inProgress;
      }
    } else {
      if (this.hasConflict()) {
        return RecordingIcons.plannedConflict;
      } else {
        return RecordingIcons.planned;
      }
    }
  }

  canWatchLocal(stbId): boolean {
    if (environment.atv_feature_list.includes(AtvFeatures.RECORDING)) {
      return this.device === stbId && (this.isRecorded() || this.isInProgress());
    }
    return false;
  }

  canWatchNpvrWithRights(channel: ChannelModel, neededRights, stbList: StbList) {
    if (
      !environment.atv_feature_list.includes(AtvFeatures.RECORDING) ||
      stbList.getNpvrStb() === undefined ||
      this.device !== stbList.getNpvrStb().id
    ) {
      return false;
    }

    return (
      channel.channelHasRightsForStreamTypes(neededRights, [StreamType.NPVR]) &&
      (this.isRecorded() || this.isInProgress())
    );
  }
}
