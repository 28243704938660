import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AtvSharedModule } from '@atv-shared/atv-shared.module';

import { AtvPagesModule } from './../atv-pages/atv-pages.module';
import { MainBackdropComponent } from './main-backdrop/main-backdrop.component';
import { MainMenuSmarttvComponent } from './main-menu-smarttv/main-menu-smarttv.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { MenuItemSmarttvComponent } from './menu-item-smarttv/menu-item-smarttv.component';
import { TopBarSmarttvComponent } from './top-bar-smarttv/top-bar-smarttv.component';
import { TopMenuComponent } from './top-menu/top-menu.component';

@NgModule({
  imports: [CommonModule, MainRoutingModule, AtvSharedModule, AtvPagesModule],
  declarations: [
    MainComponent,
    MainBackdropComponent,
    MainMenuComponent,
    MainMenuSmarttvComponent,
    TopMenuComponent,
    MenuItemSmarttvComponent,
    TopBarSmarttvComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainModule {}
