export enum MessageTypes {
  ERROR,
  WARNING,
  INFO,
  SUCCESS,
}

export class MessageModel {
  type: MessageTypes;
  message: string;
  dissipateTime: number;
  dissipate: boolean;

  constructor(type: MessageTypes, message: string, dissipate: boolean = true) {
    this.type = type;
    this.message = message;
    this.dissipate = dissipate;
  }
}
