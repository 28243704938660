<div
  class="chromecast-controller-wrapper"
  *ngIf="showRemotePlayer()"
  [class.detail-open]="detailPageOpen"
>
  <div class="chromecast-controller">
    <div class="chromecast-controller-left">
      <img class="casting-image" *ngIf="visualModel.image" [src]="visualModel.image" />
      <div class="casting-info">
        <div class="casting-title">{{ visualModel.title }}</div>
        <div class="casting-subtitle">{{ visualModel.subtitle }}</div>

        <div class="casting-stream-info">
          <div *ngIf="visualModel.isLive" class="player-controls-live">
            LIVE
          </div>
          <div *ngIf="!visualModel.isLive" class="player-controls-progress">
            {{ visualModel.progressTime }}
          </div>
        </div>
      </div>
    </div>

    <div class="chromecast-controller-middle">
      <div class="chromecast-controls">
        <div
          class="chromecast-control backward"
          *ngIf="visualModel.allowSeeking"
          (click)="skip(false)"
        ></div>
        <div
          *ngIf="visualModel.playingState !== PlayingStates.NONE"
          class="chromecast-control chromecast-playing-state"
          [class.paused]="!visualModel.isLive && visualModel.playingState === PlayingStates.PAUSED"
          [class.playing]="
            !visualModel.isLive && visualModel.playingState === PlayingStates.PLAYING
          "
          [class.live]="visualModel.isLive && visualModel.playingState === PlayingStates.PLAYING"
          [class.buffering]="visualModel.playingState === PlayingStates.BUFFERING"
          (click)="playPauseToggle()"
        ></div>
        <div
          class="chromecast-control forward"
          *ngIf="visualModel.allowSeeking"
          (click)="skip(true)"
        ></div>
      </div>
      <div class="casting-to" *ngIf="visualModel.castingToText">
        {{ visualModel.castingToText }}
      </div>
    </div>

    <div class="chromecast-controller-right">
      <div id="advertisement" *ngIf="currentAdvertisement && currentAdvertisement.whenSkippable">
        <button
          id="skip-ad"
          [disabled]="currentAdvertisement.whenSkippable > currentAdvertisement.currentTime"
          (click)="skipAd()"
        >
          {{ getSkipAdText() }}
        </button>
      </div>
      <div class="cast-stop" (click)="stopCasting()"></div>
    </div>
  </div>

  <app-seekbar
    *ngIf="!visualModel.isLive && visualModel"
    (seekEvent)="seek($event)"
  ></app-seekbar>
</div>
