import { Inject, Injectable } from '@angular/core';
import { CmsAction, CmsActionMethod, CmsContentTypes } from '@atv-core/api/cms';

import { CMS_ACTION_HANDLER, CmsActionHandler } from './cms-action.model';

@Injectable({
  providedIn: 'root',
})
export class CmsActionService {
  constructor(@Inject(CMS_ACTION_HANDLER) private actionHandlers: CmsActionHandler[]) {}

  public doAction(
    action: CmsAction,
    showDetailSecondAction = false,
    forceFullscreen = false
  ): void {
    for (const handler of this.actionHandlers) {
      if (handler.handlesMethod(action.actionMethod) && handler.handlesType(action.actionType)) {
        handler.handleAction(action, forceFullscreen);

        if (showDetailSecondAction && action.actionType !== CmsContentTypes.PAGE) {
          const detailHandler = this.actionHandlers.find(
            (h) => h.handlesMethod(CmsActionMethod.DETAIL) && h.handlesType(action.actionType)
          );

          detailHandler?.handleAction({
            actionId: action.actionId,
            actionType: action.actionType,
            actionMethod: CmsActionMethod.DETAIL,
          });
        }
        return;
      }
    }

    console.error('action not implemented', action);
  }
}
