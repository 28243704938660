import { PlatformType, StreamResolutionType } from '@atv-core/utility/constants/shared';

export interface EpgChannelRight {
  id: string;
  rights: string[];
}

export interface EpgChannel {
  id: string;
  enabled?: boolean;
  dvbId?: string;
  name: string;
  shortDescription?: string;
  longDescription?: string;
  defaultLogo?: string;
  squareLogo?: string;
  audioOnly?: boolean;
  resolution: StreamResolutionType;
  minimumAge?: number;
  adult?: boolean;
  replayHours?: number;
  npvrRecordHours?: number;
  rights: EpgChannelRights[];
  catalogs?: ChannelCatalog[];
}

export interface ChannelCatalog {
  id: string;
  channelNumber?: number;
}

export interface EpgChannelRights {
  deviceType: PlatformType;
  rights?: string[];
}

export interface EpgProgramAsset {
  program: EpgProgramDetailAsset;
  season?: EpgSeasonSummaryAsset;
  series?: EpgSeriesSummaryAsset;
  schedules?: EpgScheduleDetailAsset[];
}

export interface EpgSeasonAsset {
  season: EpgSeasonDetailAsset;
  series?: EpgSeriesSummaryAsset;
  programs?: EpgProgramSummaryAsset[];
  schedules?: EpgScheduleDetailAsset[];
}

export interface EpgSeriesAsset {
  series: EpgSeriesDetailAsset;
  seasons?: EpgSeasonSummaryAsset[];
  programs?: EpgProgramSummaryAsset[];
  schedules?: EpgScheduleDetailAsset[];
}

export interface EpgScheduleAsset {
  schedules?: EpgScheduleSummaryAsset[];
  programs?: EpgProgramSummaryAsset[];
  seasons?: EpgSeasonSummaryAsset[];
  series?: EpgSeriesSummaryAsset[];
}

export interface EpgScheduleItem {
  schedule: EpgScheduleDetailAsset;
  program: EpgProgramSummaryAsset;
  season?: EpgSeasonSummaryAsset;
  series?: EpgSeriesSummaryAsset;
}

export interface EpgProgramDetailAsset extends EpgProgramSummaryAsset {
  id: string;
  title: string;
  originalTitle?: string;
  episodeTitle?: string;
  shortSynopsis?: string;
  longSynopsis?: string;
  posterImage?: string;
  boxcoverImage?: string;
  wallpaperImage?: string;
  episode?: number;
  season?: string;
  credits?: EpgCreditAsset[];
  minimumAge?: number;
  adult?: boolean;
  productionYear?: number;
  genres?: string[];
  tags?: string[];
}

interface EpgSeasonDetailAsset extends EpgSeasonSummaryAsset {
  id: string;
  title: string;
  shortSynopsis?: string;
  longSynopsis?: string;
  posterImage?: string;
  boxcoverImage?: string;
  wallpaperImage?: string;
  adult?: boolean;
  season?: number;
  series?: string;
}

export interface EpgSeriesDetailAsset extends EpgSeriesSummaryAsset {
  id: string;
  title: string;
  shortSynopsis?: string;
  longSynopsis?: string;
  posterImage?: string;
  boxcoverImage?: string;
  wallpaperImage?: string;
  adult?: boolean;
}

export interface EpgScheduleDetailAsset extends EpgScheduleSummaryAsset {
  id: string;
  channel: string;
  published: EpgScheduleTimeAsset;
  actual?: EpgScheduleTimeAsset;
  program: string;
  live?: boolean;
  blackouts?: string[];
}

export interface EpgSeasonSummaryAsset {
  id: string;
  title?: string;
  adult?: boolean;
  season?: number;
  series?: string;
}

export class EpgSeriesSummaryAsset {
  id: string;
  title?: string;
  adult?: boolean;
}

export interface EpgScheduleSummaryAsset {
  id: string;
  published: EpgScheduleTimeAsset;
  program: string;
  live?: boolean;
  blackouts?: string[];
}

export interface EpgProgramSummaryAsset {
  id: string;
  title: string;
  shortSynopsis?: string;
  posterImage?: string;
  episode?: number;
  episodeTitle?: string;
  season?: string;
  genres?: string[];
  tags?: string[];
  adult?: boolean;
}

interface EpgCreditAsset {
  role: EpgCreditRoles;
  name: string;
  character?: string;
}

export interface EpgScheduleTimeAsset {
  start: string;
  end: string;
}

export enum EpgCreditRoles {
  ACTOR = 'ACTOR',
  DIRECTOR = 'DIRECTOR',
  ANCHOR = 'ANCHOR',
  ART_DIRECTOR = 'ART_DIRECTOR',
  PERFORMER = 'PERFORMER',
  REPORTER = 'REPORTER',
  PRODUCER = 'PRODUCER',
  SCENARIST = 'SCENARIST',
  VOICE_OVER_ARTIST = 'VOICE_OVER_ARTIST',
  COMMENTATOR = 'COMMENTATOR',
  CO_COMMENTATOR = 'CO_COMMENTATOR',
  LINE_REPORTERS = 'LINE_REPORTERS',
  WRITER = 'WRITER',
}

export enum ChannelCardModes {
  NONE = 'none',
  TOGGLE = 'toggle',
  LOGO = 'logo',
}

export interface EpgCategory {
  tag: string;
  title: string;
  defaultImage?: string;
  focusImage?: string;
  highlightImage?: string;
  adult?: boolean;
  count?: number;
  subcategories?: EpgCategory[];
}
