import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfigService, PopupTranslationKeys, SettingsTranslationKeys } from '@atv-bootstrap/services/config';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { ExitPopupService } from '@atv-shared/exit-popup/exit-popup.service';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-exit-popup',
  templateUrl: './exit-popup.component.html',
  styleUrls: ['./exit-popup.component.scss'],
})
export class ExitPopupComponent implements OnInit, OnDestroy {
  @ViewChild('container') container: ElementRef<HTMLDivElement>;

  public showExitPopup = false;
  public exitPopupTitle = '';
  public noButtonText = '';
  public yesButtonText = '';

  private statusSubscription: Subscription;
  private returnSubscription?: Subscription;

  constructor(
    private config: ConfigService,
    private spatialNavigationService: SpatialNavigationService,
    private exitPopupService: ExitPopupService,
  ) {
    this.exitPopupTitle = this.config.getTranslation(PopupTranslationKeys.popup_exit_title);
    this.noButtonText = this.config.getTranslation(SettingsTranslationKeys.settings_no_stb);
    this.yesButtonText = this.config.getTranslation(SettingsTranslationKeys.settings_yes_stb);
  }

  ngOnInit(): void {
    this.statusSubscription = this.exitPopupService.observe(status => {
      if (status) {
        this.openExitPopup();
      } else {
        this.closeExitPopup();
      }
    });
  }

  private openExitPopup(): void {
    this.showExitPopup = true;

    this.spatialNavigationService.register(
      NavigationSections.POPUP_BUTTONS,
      `.dialog-buttons .button-title`,
      { restrict: 'self-only' },
    );
    setTimeout(() => {
      this.spatialNavigationService.setFocus(NavigationSections.POPUP_BUTTONS);
    }, 0);

    this.returnSubscription = fromEvent<KeyboardEvent>(this.container.nativeElement, 'keyup')
      .pipe(filter((event) => this.spatialNavigationService.keyCodeIsReturn(event.keyCode)),
      )
      .subscribe(() => {
        this.closeExitPopup();
      });
  }

  public closeExitPopup(): void {
    this.showExitPopup = false;

    this.spatialNavigationService.unregister(NavigationSections.POPUP_BUTTONS);
    this.spatialNavigationService.setFocus();
    this.returnSubscription?.unsubscribe();
  }

  public exitApp(): void {
    tizen.application.getCurrentApplication().exit();
  }

  ngOnDestroy(): void {
    this.statusSubscription.unsubscribe();
    this.returnSubscription?.unsubscribe();
  }

}
