import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiApiService } from '@atv-core/api/api/api-api.service';
import { Properties } from '@atv-core/api/cms';
import { HistoryApiService } from '@atv-core/api/history';
import { SessionChangePinRequest, SessionPinRequestTypes } from '@atv-core/api/pricing/pricing.model.service';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import {
  AndroidTvTranslationKeys,
  ConfigService,
  ErrorTranslationKeys,
  MenuTranslationKeys,
  MyStuffTranslationKeys,
  SettingsTranslationKeys
} from '@atv-bootstrap/services/config';
import { DiscoveryService } from '@atv-bootstrap/services/discovery';
import { PageContext, PageUrlTypes } from '@atv-core/services/log/log.model';
import { LogService } from '@atv-core/services/log/log.service';
import { PageData } from '@atv-core/services/menu-manager/menu-manager.service';
import { MessagesService } from '@atv-core/services/messages';
import { SessionService } from '@atv-core/services/session';
import {
  NavigationSections,
  SpatialNavigationService
} from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { CustomerSettings, DeviceSettings } from '@atv-core/utility/constants/shared';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { ReloadablePageComponent } from '@atv-pages/reloadable-page.component';
import { AdultOptionsModel } from '@atv-shared/popups/adult-popup/adult-popup.component';
import { environment } from '@env/environment';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as buildInfo from 'src/app/version-info';
import { CustomerFeature } from '@atv-core/api/api/api-api.model';

interface PincodeUpdate {
  title: string;
  type: SessionPinRequestTypes;
  old: string;
  new: string;
  new_confirm: string;
}

@Component({
  selector: 'app-client-area-page',
  templateUrl: './client-area-page.component.html',
  styles: [],
})
export class ClientAreaPageComponent extends ReloadablePageComponent implements OnInit, OnDestroy {
  public oldPincodeText: string;
  public newPincodeText: string;
  public confirmPincodeText: string;
  public adultModeSettingTitleText: string;
  public adultModeSettingText: string;
  public okText: string;
  public saveText: string;

  public adultModeToggleTitle: string;
  public adultModeActivated: string;
  public adultModeDeactivated: string;
  public activateAdultText = '';
  public deactivateAdultText = '';

  public pincodeUpdates: PincodeUpdate[];
  public adultOptions: AdultOptionsModel[];
  public selectedNumberOfMinutesAdultMode = 5;

  public adultModeEnabled = false;

  public ottAdultSettingEnabled: boolean;
  public ottAdultAllowedTitle: string;
  public ottAdultAllowed: boolean;
  public ottAdultAllowedOn: string;
  public ottAdultAllowedOff: string;

  public versionTitle = '';
  public versionInfo: string;
  public buildTime: string;

  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnSubscription: Subscription;
  public isAuthorized = false;

  constructor(
    config: ConfigService,
    private apiApi: ApiApiService,
    messagesService: MessagesService,
    adultService: AdultService,
    sessionService: SessionService,
    private historyApi: HistoryApiService,
    private log: LogService,
    private disc: DiscoveryService,
    private activatedRoute: ActivatedRoute,
    authorizationService: AuthorizationService,
    private spatialNavigationService: SpatialNavigationService
  ) {
    super(sessionService, authorizationService, adultService, messagesService, config);
    this.adultModeEnabled = this.adultService.showAdult(AdultMode.true);
    this.ottAdultSettingEnabled = environment.ottAdultSettingEnabled && !this.containsNoAdultFeature();

    this.ottAdultAllowedTitle = this.config.getTranslation(
      SettingsTranslationKeys.settings_allow_adult_androidtv_title
    );

    this.ottAdultAllowedOn = this.config.getTranslation(
      SettingsTranslationKeys.setting_allow_adult_androidtv_on
    );
    this.ottAdultAllowedOff = this.config.getTranslation(
      SettingsTranslationKeys.setting_allow_adult_androidtv_off
    );

    this.oldPincodeText = this.config.getTranslation(MyStuffTranslationKeys.mystuff_old_pincode);
    this.newPincodeText = this.config.getTranslation(MyStuffTranslationKeys.mystuff_new_pincode);
    this.confirmPincodeText = this.config.getTranslation(
      MyStuffTranslationKeys.mystuff_confirm_pincode
    );
    this.adultModeSettingTitleText = this.config.getTranslation(
      MyStuffTranslationKeys.mystuff_adult_settings
    );
    this.adultModeSettingText = this.config.getTranslation(
      MyStuffTranslationKeys.mystuff_adultmode
    );
    this.okText = this.config.getTranslation(MyStuffTranslationKeys.mystuff_change_pincode);
    this.saveText = this.config.getTranslation(MyStuffTranslationKeys.mystuff_adultmode_save_btn);

    this.adultModeToggleTitle = this.config.getTranslation(
      MyStuffTranslationKeys.mystuff_adultmode_activate
    );
    this.adultModeActivated = this.config.getTranslation(
      MyStuffTranslationKeys.mystuff_adultmode_activated
    );
    this.adultModeDeactivated = this.config.getTranslation(
      MyStuffTranslationKeys.mystuff_adultmode_stopped
    );
    this.activateAdultText = this.config.getTranslation(
      MyStuffTranslationKeys.mystuff_adultmode_activate
    );
    this.deactivateAdultText = this.config.getTranslation(
      MyStuffTranslationKeys.mystuff_adultmode_stop
    );

    this.versionTitle = this.config.getTranslation(SettingsTranslationKeys.settings_version_web);

    this.pincodeUpdates = [
      {
        title: this.config.getTranslation(MyStuffTranslationKeys.mystuff_change_adult_pincode),
        type: SessionPinRequestTypes.ADULT,
        old: '',
        new: '',
        new_confirm: '',
      },
      {
        title: this.config.getTranslation(MyStuffTranslationKeys.mystuff_change_vod_pincode),
        type: SessionPinRequestTypes.VOD,
        old: '',
        new: '',
        new_confirm: '',
      },
    ];

    this.adultOptions = [
      {
        value: 0,
        text: this.config.getTranslation(MyStuffTranslationKeys.mystuff_adultmode_never),
      },
      {
        value: 5,
        text: 5 + ' ' + this.config.getTranslation(MyStuffTranslationKeys.mystuff_minutes),
      },
      {
        value: 10,
        text: 10 + ' ' + this.config.getTranslation(MyStuffTranslationKeys.mystuff_minutes),
      },
      {
        value: 60,
        text: 1 + ' ' + this.config.getTranslation(MyStuffTranslationKeys.mystuff_hour),
      },
      {
        value: 120,
        text: 2 + ' ' + this.config.getTranslation(MyStuffTranslationKeys.mystuff_hours),
      },
    ];

    this.versionInfo = `${buildInfo.versionInfo.version} - ${this.disc.environment}`;
    this.isAuthorized = this.authorizationService.isAuthorized();
  }

  public ngOnInit(): void {
    this.log.pageView(
      new PageContext({
        pageURL: PageUrlTypes.my_stuff_client_area,
        pageTitle: this.config.getTranslation(MenuTranslationKeys.menu_clientarea),
        pageLocale: this.config.getLocale(),
      })
    );

    if (this.isSmartTv) {
      this.registerElements();
    }

    super.start();
  }

  public loadPage(): void {
    this.adultService.getNumberOfMinutesAdultMode(true).subscribe((result) => {
      const tmp = this.adultOptions.find((item) => item.value === result);
      if (tmp) {
        this.selectedNumberOfMinutesAdultMode = tmp.value;
      } else {
        this.selectedNumberOfMinutesAdultMode = undefined;
      }

      if (this.isSmartTv) {
        this.registerElements();
      }
    });

    this.historyApi.getCustomerSetting(CustomerSettings.ott_adult_mode_allowed).subscribe(
      (result) => {
        if (result && (result.value === 'true' || result.value === 'TRUE')) {
          this.ottAdultAllowed = true;
        } else {
          this.ottAdultAllowed = false;
        }
      },
      () => {
        this.ottAdultAllowed = false;
      }
    );

    this.versionInfo = `${buildInfo.versionInfo.version} - ${this.disc.environment}`;
    if (environment.nightlyBuild) {
      this.versionInfo += ` - ${buildInfo.versionInfo.buildTime}`;
    }
  }

  public getPageProperties(): Observable<Properties[]> {
    return of((this.activatedRoute.snapshot.data as PageData).properties);
  }

  public changePincode(pincodeUpdate: PincodeUpdate): void {
    if (
      pincodeUpdate.new !== pincodeUpdate.new_confirm ||
      pincodeUpdate.old === '' ||
      pincodeUpdate.new === '' ||
      pincodeUpdate.new_confirm === ''
    ) {
      this.messagesService.showErrorMessage(
        this.config.getTranslation(MyStuffTranslationKeys.mystuff_pincodes_must_match)
      );
      return;
    }

    const pincodeChange: SessionChangePinRequest = {
      type: pincodeUpdate.type,
      oldPincode: pincodeUpdate.old,
      newPincode: pincodeUpdate.new,
    };
    pincodeUpdate.old = '';
    pincodeUpdate.new = '';
    pincodeUpdate.new_confirm = '';

    this.apiApi.changePincode(pincodeChange).subscribe(
      (result) => {
        this.messagesService.showSuccessMessage(
          this.config.getTranslation(MyStuffTranslationKeys.mystuff_pincodechange_succes)
        );
      },
      (e) => {
        this.messagesService.showErrorMessage(
          this.config.getTranslation(MyStuffTranslationKeys.mystuff_pincodechange_failed)
        );
      }
    );
  }

  private containsNoAdultFeature(): boolean {
    return this.sessionService.getSession()?.customer?.features.includes(CustomerFeature.NO_ADULT);
  }

  public changeAdultSetting(): void {
    this.historyApi
      .setSettingForDevice(
        this.sessionService.getDeviceId(),
        DeviceSettings.adult_mode,
        this.selectedNumberOfMinutesAdultMode.toString(),
      )
      .subscribe(
        () => {
          this.messagesService.showSuccessMessage(
            this.config.getTranslation(MyStuffTranslationKeys.mystuff_adultmode_successfully_saved)
          );
          this.adultService.getNumberOfMinutesAdultMode(true).subscribe();
        },
        () => {
          this.messagesService.showErrorMessage(
            this.config.getTranslation(ErrorTranslationKeys.error_general)
          );
        }
      );
  }

  public toggleAdultMode(event?): void {
    let isChecked: boolean;
    if (event) {
      isChecked = event.target.checked;
      event.target.checked = this.adultModeEnabled;
    } else {
      isChecked = !this.adultModeEnabled;
    }

    if (this.adultService.showAdult(AdultMode.true) && !isChecked) {
      this.adultService.stopAdultMode();
      this.adultModeEnabled = this.adultService.showAdult(AdultMode.true);
    } else if (!this.adultService.showAdult(AdultMode.true) && isChecked) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.adultModeEnabled = this.adultService.showAdult(AdultMode.true);
        },
        errorCallback: () => {},
      });
    }
  }

  public toggleAllowAdultModeOnOtt(event): void {
    const isChecked = event.target.checked;
    event.target.checked = this.ottAdultAllowed;

    this.historyApi
      .setCustomerSetting(CustomerSettings.ott_adult_mode_allowed, isChecked ? 'true' : 'false')
      .subscribe(
        () => {
          this.ottAdultAllowed = isChecked;
          const message = isChecked
            ? this.config.getTranslation(
                AndroidTvTranslationKeys.android_tv_check_adult_mode_enabled
              )
            : this.config.getTranslation(
                AndroidTvTranslationKeys.android_tv_check_adult_mode_disabled
              );
          if (message) {
            this.messagesService.showInfoMessage(message, false);
          }
        },
        () => {
          this.messagesService.showErrorMessage(
            this.config.getTranslation(ErrorTranslationKeys.error_general)
          );
        }
      );
  }

  public moveDown(): void {
    this.spatialNavigationService.move('down');
  }

  private registerElements(): void {
    this.unregisterElements();

    this.returnSubscription = fromEvent(document, 'keyup')
      .pipe(
        filter((event: KeyboardEvent) =>
          this.spatialNavigationService.keyCodeIsReturn(event.keyCode)
        )
      )
      .subscribe(() => {
        this.spatialNavigationService.setFocus(NavigationSections.MENU);
      });

    this.spatialNavigationService.register(
      NavigationSections.PAGE_CONTENT,
      '#pwdold, #pwdnew, #pwdconf, button.setting-confirm, #client-area-adult-options .chip, .version-info',
      {
        leaveFor: { down: '', left: `@${NavigationSections.MENU}` },
        straightOnly: true,
        defaultElement: '#pwdold',
        enterTo: 'last-focused',
      }
    );
    this.spatialNavigationService.setDefaultSection(NavigationSections.PAGE_CONTENT);
  }

  private unregisterElements(): void {
    this.spatialNavigationService.unregister(NavigationSections.PAGE_CONTENT);
    this.returnSubscription?.unsubscribe();
  }

  public ngOnDestroy(): void {
    super.stop();

    if (this.isSmartTv) {
      this.unregisterElements();
    }
  }
}
