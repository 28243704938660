import { ContentProvider } from '@atv-shared/content-provider/content-provider';
import { RecordingPageTypes, StbCacheService } from '@atv-core/services/cache/stb';
import { forkJoin, Observable } from 'rxjs';
import { CardOrientation, Ribbon2Asset } from '@atv-core/api/cms';
import { switchMap, tap } from 'rxjs/operators';
import { ContentProviderFactoryService } from '@atv-shared/content-provider/content-provider-factory.service';

export class RecordingFolderContentProvider extends ContentProvider {
  private hasMoreItems = true;

  constructor(private stbCache: StbCacheService,
              private contentProviderFactoryService: ContentProviderFactoryService,
              private deviceId: string,
              private pageType: RecordingPageTypes,
              private showAdult: boolean) {
    super();

    this.orientation = CardOrientation.LANDSCAPE;
  }

  public hasNext(): boolean {
    return this.hasMoreItems;
  }

  public getItems(): Observable<Ribbon2Asset[]> {
    return this.stbCache.getRecordingsForDeviceForType(this.deviceId, this.pageType, this.showAdult).pipe(
      tap(() => this.hasMoreItems = false),
      switchMap(result =>
      forkJoin(this.pageType !== RecordingPageTypes.expires
        ? this.contentProviderFactoryService.groupRecordingsBySeries(result).map(recordingGroup =>
          this.contentProviderFactoryService.recordingFolderToRibbon2Asset(recordingGroup.main, recordingGroup.others))
        : result.map(recording => this.contentProviderFactoryService.recordingToRibbon2Asset(recording, this.pageType)),
      )));
  }

  public resetPaging(): void {
    this.hasMoreItems = true;
  }
}
