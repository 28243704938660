import { Injectable } from '@angular/core';
import { CmsAction, CmsActionMethod, CmsContentTypes } from '@atv-core/api/cms/cms-api.model';
import { EpgApiService } from '@atv-core/api/epg';
import { BookmarkContentTypes } from '@atv-core/api/history';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { BookmarkCacheService, BookmarkModel } from '@atv-core/services/cache/bookmark';
import { ChannelCacheService } from '@atv-core/services/cache/channel';
import { ConfigService, ErrorTranslationKeys } from '@atv-bootstrap/services/config';
import { MessagesService } from '@atv-core/services/messages';
import { PlayerService } from '../../player/player.service';
import { StreamType } from '@atv-core/utility/constants/shared';
import { EpgUtilityService } from '@atv-core/utility/epg-utility/epg-utility.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { PlayInfoModel } from '../../../../atv-player/model/play-info-model';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CmsActionHandler } from '../cms-action.model';
import { BlackoutFactory } from '@atv-core/utility/epg-utility/blackout-factory';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class PlayProgramAction extends CmsActionHandler {
  constructor(
    private auth: AuthorizationService,
    private channelCache: ChannelCacheService,
    private epgUtility: EpgUtilityService,
    private epgApi: EpgApiService,
    messagesService: MessagesService,
    config: ConfigService,
    private bookmarkCache: BookmarkCacheService,
    adultService: AdultService,
    playerService: PlayerService,
  ) {
    super(adultService, playerService, messagesService, config);
  }

  handlesMethod(method: CmsActionMethod): boolean {
    return method === CmsActionMethod.PLAY;
  }

  handlesType(type: CmsContentTypes): boolean {
    return type === CmsContentTypes.PROGRAM;
  }

  handleAction(action: CmsAction): void {
    if (!this.auth.isAuthorized()) {
      this.auth.showAuthPopup.emit();
      return;
    }

    const fromUntil = this.epgUtility.getEpgDaysFromUntil();

    forkJoin([
      this.channelCache.getChannels(),
      this.epgApi.getProgram(action.actionId, fromUntil.from, fromUntil.until),
    ]).subscribe((result) => {
      const channels = result[0];
      const prog = result[1];

      const schedule = this.epgUtility.findClosestSchedule(prog.schedules, true, channels);

      if (!schedule) {
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_general),
        );
        return;
      }

      if (SharedUtilityService.timeStringToMs(schedule.published.start) > new Date().getTime()) {
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_in_future),
        );
        return;
      }

      this.bookmarkCache
        .getBookmarkNoCache(
          BookmarkContentTypes.PROGRAM,
          prog.program.id,
          this.adultService.showAdult(AdultMode.any),
        )
        .pipe(catchError(() => of(undefined as BookmarkModel)))
        .subscribe((bookmark) => {
          const bookmarkPosition =
            bookmark && bookmark.position && !bookmark.isFullyWatched() ? bookmark.position : 0;
          const playInfo = new PlayInfoModel(StreamType.REPLAY, schedule.id, bookmarkPosition);

          const channel = channels.find((ch) => ch.id === schedule.channel);

          playInfo.setEpgInfo(
            channel,
            prog.program.adult,
            prog.program,
            prog.series,
            prog.season,
            undefined,
            schedule,
            prog.schedules,
            false,
          );

          this.checkRights(playInfo);
        });
    });
  }

  private checkRights(playInfo: PlayInfoModel): void {
    const channel = playInfo.channelInformation;
    const schedule = playInfo.scheduleInformation;

    if (playInfo.channelInformation && !channel.customerHasRight(StreamType.REPLAY)) {
      this.messagesService.showErrorMessage(
        this.config.getTranslation(ErrorTranslationKeys.error_no_rights),
      );
      return;
    }

    if (this.epgUtility.scheduleHasBlackout(schedule.blackouts, BlackoutFactory.create(environment.platform, StreamType.REPLAY))) {
      this.messagesService.showErrorMessage(
        this.config.getTranslation(ErrorTranslationKeys.error_blackout),
      );
      return;
    }

    this.checkAdultAndPlay(playInfo);
  }
}
