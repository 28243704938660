import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu } from '@atv-core/api/cms';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { MenuManagerService } from '@atv-core/services/menu-manager/menu-manager.service';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { MenuService } from '../main-menu/menu.service';
import { SubLevelMenuItem, TopLevelMenuModel } from '../main-menu/menuDefinitions.model';
import { ExitPopupService } from '@atv-shared/exit-popup/exit-popup.service';
import { environment } from '@env/environment';
import { PlatformType } from '@atv-core/utility/constants/shared';
import { CatalogTranslationKeys, ConfigService, LoginTranslationKeys, SettingsTranslationKeys } from '@atv-bootstrap/services/config';

@Component({
  selector: 'app-main-menu-smarttv',
  templateUrl: './main-menu-smarttv.component.html',
  styleUrls: ['./main-menu-smarttv.component.scss'],
})
export class MainMenuSmarttvComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('menu') menuDiv: ElementRef<HTMLDivElement>;

  public switchCatalogText = '';
  public switchProfileText = '';
  public loginText = '';
  public logoutText = '';
  public isMenuFocused = true;
  public isSubMenuOpen = false;
  public avatar: string;
  public greeting: string;

  public currentSubLevelMenu: SubLevelMenuItem[] = [];

  private returnSubscription: Subscription = null;
  private returnObservable: Observable<Event>;

  private routerSubscription?: Subscription;
  private updateMenuSubscription?: Subscription;

  constructor(
    private menuService: MenuService,
    private menuManager: MenuManagerService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private config: ConfigService,
    private spatialNavigationService: SpatialNavigationService,
    private auth: AuthorizationService,
    private exitPopupService: ExitPopupService,
  ) {
    this.logoutText = this.config.getTranslation(SettingsTranslationKeys.settings_logout);
    this.loginText = this.config.getTranslation(LoginTranslationKeys.login_login);
    this.switchProfileText = this.config.getTranslation(CatalogTranslationKeys.profile_switch);
    this.switchCatalogText = this.config.getTranslation(CatalogTranslationKeys.catalog_switch);
  }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.detectChanges();
      }
    });

    this.registerMenuElements();

    const menu = this.menuManager.currentMenu;
    if (menu) {
      this.initMenu(menu);
    }

    this.updateMenuSubscription = this.menuManager.updateMenuEvent.subscribe(() => {
      const curMenu = this.menuManager.currentMenu;
      if (curMenu) {
        this.initMenu(curMenu);
      }
    });

    this.openMainMenu();
  }

  ngAfterViewInit(): void {
    // prevent scrolling the menu div
    this.menuDiv.nativeElement.addEventListener('scroll', () => {
      this.menuDiv.nativeElement.scrollLeft = 0;
    });

    this.returnObservable = fromEvent<KeyboardEvent>(document, 'keyup').pipe(
      filter((event) => this.spatialNavigationService.keyCodeIsReturn(event.keyCode)),
      tap((event) => {
        event.cancelBubble = true;
      }),
    );

    this.spatialNavigationService.isMenuFocusedEvent.subscribe((isMenuFocused: boolean) => {
      this.isMenuFocused = isMenuFocused;

      if (this.isMenuFocused && !this.returnSubscription) {
        this.returnSubscription = this.returnObservable.subscribe(() => {
          if (this.isSubMenuOpen) {
            this.openMainMenu();
          } else if (environment.platform === PlatformType.SMARTTV_TIZEN) {
            this.exitPopupService.showExitPopup();
          }
        });
      } else if (!this.isMenuFocused && this.returnSubscription) {
        this.returnSubscription.unsubscribe();
        this.returnSubscription = null;
      }
    });
  }

  private detectChanges(): void {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  private initMenu(menu: Menu): void {
    this.menuService.createMenu(menu);
    this.detectChanges();
    this.spatialNavigationService.setFocus(NavigationSections.MENU);

    this.avatar = this.menuService.getAvatar();
    this.greeting = this.menuService.getGreeting();
  }

  public isAnonymousProfileActive(): boolean {
    return this.menuService.getIsAnonymousProfileActive();
  }

  public anonymousHasMultipleCatalogs(): boolean {
    return this.menuService.getAnonymousHasMultipleCatalogs();
  }

  public isProfileSwitchingAllowed(): boolean {
    return this.menuService.getIsProfileSwitchingAllowed();
  }

  public isLoggedIn(): boolean {
    return this.menuService.getIsLoggedIn();
  }

  public getTopLevelMenu(): TopLevelMenuModel[] {
    return this.menuService.getTopLevelMenus();
  }

  public openSubMenu(items: SubLevelMenuItem[]): void {
    if (items && items.length > 0) {
      this.currentSubLevelMenu = items;

      this.isSubMenuOpen = true;
      this.registerMenuElements();
      this.spatialNavigationService.setFocus(NavigationSections.MENU);
    }
  }

  public openMainMenu(): void {
    this.isSubMenuOpen = false;
    this.registerMenuElements();
    this.spatialNavigationService.setFocus(NavigationSections.MENU);
  }

  public login(): void {
    this.auth.tryRefreshTokenLogin().subscribe(
      () => {
        if (this.auth.isAuthorized()) {
          this.detectChanges();
        } else {
          this.router.navigate(['/account/login']);
        }
      },
      () => {
        this.router.navigate(['/account/login']);
      },
    );
  }

  public logout(): void {
    this.auth.confirmLogoutPopup.emit(() => {
      this.menuService.setLoggedIn(this.auth.isAuthorized());
    });

    this.detectChanges();
  }

  public switchCatalog(): void {
    this.router.navigate(['/account/switch-catalog']);
  }

  public switchProfile(): void {
    this.router.navigate(['/account/switch-profile']);
  }

  private registerMenuElements(): void {
    this.spatialNavigationService.unregister(NavigationSections.MENU);
    this.spatialNavigationService.register(
      NavigationSections.MENU,
      '#profile-menu app-menu-item-smarttv #menu-item, ' +
      `${this.isSubMenuOpen ? '#sub-menu' : '#main-menu'} app-menu-item-smarttv #menu-item`,
      {
        leaveFor: { up: '', down: '', left: '' },
        defaultElement: `${
          this.isSubMenuOpen ? '#sub-menu' : '#main-menu'
        } app-menu-item-smarttv #menu-item`,
        enterTo: 'last-focused',
      },
    );
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
    this.updateMenuSubscription?.unsubscribe();

    this.returnSubscription?.unsubscribe();
    this.returnSubscription = null;

    this.spatialNavigationService.unregister(NavigationSections.MENU);
  }
}
