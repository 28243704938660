import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, MenuIcons, MenuItem } from '@atv-core/api/cms';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { ConfigService, SettingsKeys, SettingsTranslationKeys } from '@atv-bootstrap/services/config';
import { SessionService } from '@atv-core/services/session';

import { SubLevelMenuItem, SublevelMenuModel, TopLevelMenuModel } from './menuDefinitions.model';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private isProfileSwitchingAllowed = false;
  private isLoggedIn = false;
  private isAnonymousProfileActive = false;
  private anonymousHasMultipleCatalogs = false;

  private topLevelMenu: TopLevelMenuModel[] = [];
  private subLevelMenu: SublevelMenuModel[] = [];

  private mobileMenuTranslations = {};
  private mobileBreadCrumb = '';

  constructor(
    private sessionService: SessionService,
    private config: ConfigService,
    private router: Router,
    private auth: AuthorizationService,
  ) {
    this.isProfileSwitchingAllowed = this.config.getSettingBoolean(
      SettingsKeys.allowProfileSwitching,
      false
    );

    this.sessionService.catalogChangedEvent.subscribe(() => {
      this.checkProfileOptions();
    });
    this.checkProfileOptions();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setCurrentMenuLevels(this.router.url);
      }
    });
  }

  public getAvatar(): string {
    if (this.isProfileSwitchingAllowed) {
      return this.sessionService.getAvatar();
    } else {
      return 'assets/default-profile.png';
    }
  }

  public getGreeting(): string {
    let greeting =
      this.config.getTranslation(SettingsTranslationKeys.settings_greeting_web) || 'Hi';
    const name = this.sessionService.getDisplayName(this.isProfileSwitchingAllowed);

    if (name) {
      greeting += ', ' + name;
    }
    return greeting;
  }

  public createMenu(menu: Menu): void {
    this.topLevelMenu = [];
    this.subLevelMenu = [];

    if (menu.items) {
      menu.items.forEach((topLevelMenuItem) => {
        let subLevelItems: SubLevelMenuItem[] = [];
        if (topLevelMenuItem.items && topLevelMenuItem.items.length > 0) {
          subLevelItems = this.createSubLeveMenuItems(topLevelMenuItem);
        } else if (topLevelMenuItem.pageType) {
          this.mobileMenuTranslations[topLevelMenuItem.tag] = topLevelMenuItem.title;
        } else {
          return;
        }

        this.topLevelMenu.push({
          text: topLevelMenuItem.title,
          route: topLevelMenuItem.tag,
          icon: this.getIconString(topLevelMenuItem.icon, false),
          icon_selected: this.getIconString(topLevelMenuItem.icon, true),
          submenu: subLevelItems,
        });
      });
    }
    this.setCurrentMenuLevels(this.router.url);
  }

  private createSubLeveMenuItems(topLevel: MenuItem): SubLevelMenuItem[] {
    const tmpSubLevel: SublevelMenuModel = {
      forTopLevel: topLevel.tag,
      forTopLevelTitle: topLevel.title,
      subLevelMenuItems: [],
    };

    topLevel.items.forEach((subLevelItem) => {
      tmpSubLevel.subLevelMenuItems.push({
        text: subLevelItem.title,
        route: `${topLevel.tag}/${subLevelItem.tag}`,
        icon: this.getIconString(subLevelItem.icon, false),
        icon_selected: this.getIconString(subLevelItem.icon, true),
      });
      this.mobileMenuTranslations[
        `${topLevel.tag};${subLevelItem.tag}`
      ] = `${topLevel.title} > ${subLevelItem.title}`;
    });

    this.subLevelMenu.push(tmpSubLevel);

    return tmpSubLevel.subLevelMenuItems;
  }

  private setCurrentMenuLevels(currentUrl: string): void {
    currentUrl =
      currentUrl.indexOf('?') >= 0 ? currentUrl.slice(0, currentUrl.indexOf('?')) : currentUrl;
    let currentUrlSegments = currentUrl.split('/');

    const mainIndex = currentUrlSegments.indexOf('main');

    if (currentUrlSegments && mainIndex > 0) {
      currentUrlSegments = currentUrlSegments.slice(mainIndex + 1, mainIndex + 3);
      const currentTopLevel = currentUrlSegments.length >= 1 ? currentUrlSegments[0] : '';
      const currentSubLevel = currentUrlSegments.length >= 2 ? currentUrlSegments[1] : '';

      if (currentTopLevel && currentSubLevel) {
        this.mobileBreadCrumb = this.mobileMenuTranslations[
          `${currentTopLevel};${currentSubLevel}`
        ];
      } else if (currentTopLevel) {
        this.mobileBreadCrumb = this.mobileMenuTranslations[currentSubLevel];
      } else {
        this.mobileBreadCrumb = '';
      }
    }
  }

  private getIconString(icon: MenuIcons | undefined, selected: boolean): string {
    switch (icon) {
      case MenuIcons.ADULT_ICON:
        return selected ? './assets/theme/svg/18_selected.svg' : './assets/theme/svg/18_normal.svg';
      case MenuIcons.CATCHUP_ICON:
        return selected
          ? './assets/theme/svg/flashback_selected.svg'
          : './assets/theme/svg/flashback_normal.svg';
      case MenuIcons.CLIENTAREA_ICON:
        return selected
          ? './assets/theme/svg/clientarea_selected.svg'
          : './assets/theme/svg/clientarea_normal.svg';
      case MenuIcons.CONFLICTS_ICON:
        return selected
          ? './assets/theme/svg/conflicts_selected.svg'
          : './assets/theme/svg/conflicts_normal.svg';
      case MenuIcons.EXPIRE_ICON:
        return selected
          ? './assets/theme/svg/expired_selected.svg'
          : './assets/theme/svg/expired_normal.svg';
      case MenuIcons.HEART_ICON:
        return selected
          ? './assets/theme/svg/recommended_selected.svg'
          : './assets/theme/svg/recommended_normal.svg';
      case MenuIcons.LIST_ICON:
        return selected
          ? './assets/theme/svg/lists_selected.svg'
          : './assets/theme/svg/lists_normal.svg';
      case MenuIcons.LOGOUT_ICON:
        return selected
          ? './assets/theme/svg/logout_selected.svg'
          : './assets/theme/svg/logout_normal.svg';
      case MenuIcons.MOVIE_ICON:
        return selected
          ? './assets/theme/svg/movies_selected.svg'
          : './assets/theme/svg/movies_normal.svg';
      case MenuIcons.MY_WORLD_ICON:
        return selected
          ? './assets/theme/svg/mystuff_selected.svg'
          : './assets/theme/svg/mystuff.svg';
      case MenuIcons.NEW_ICON:
        return selected
          ? './assets/theme/svg/new_selected.svg'
          : './assets/theme/svg/new_normal.svg';
      case MenuIcons.NOW_ICON:
        return selected ? './assets/theme/svg/now_selected.svg' : './assets/theme/svg/now.svg';
      case MenuIcons.ON_DEMAND_ICON:
        return selected
          ? './assets/theme/svg/videoclub_selected.svg'
          : './assets/theme/svg/videoclub.svg';
      case MenuIcons.PLAY_TV_ICON:
        return selected
          ? './assets/theme/svg/guide_selected.svg'
          : './assets/theme/svg/guide_normal.svg';
      case MenuIcons.RECORDED_ICON:
        return selected
          ? './assets/theme/svg/recorded_selected.svg'
          : './assets/theme/svg/recorded_normal.svg';
      case MenuIcons.PLANNED_ICON:
        return selected
          ? './assets/theme/svg/planned_selected.svg'
          : './assets/theme/svg/planned_normal.svg';
      case MenuIcons.REMINDERS_ICON:
        return selected
          ? './assets/theme/svg/reminders_selected.svg'
          : './assets/theme/svg/reminders_normal.svg';
      case MenuIcons.SEARCH_ICON:
        return selected
          ? './assets/theme/svg/search_selected.svg'
          : './assets/theme/svg/search_normal.svg';

      case MenuIcons.SERIES_ICON:
        return selected
          ? './assets/theme/svg/series_selected.svg'
          : './assets/theme/svg/series_normal.svg';

      case MenuIcons.STAR_ICON:
        return selected
          ? './assets/theme/svg/star_selected.svg'
          : './assets/theme/svg/star_normal.svg';
      case MenuIcons.RECENT_ICON:
        return selected
          ? './assets/theme/svg/recent_selected.svg'
          : './assets/theme/svg/recent_normal.svg';
      case MenuIcons.TV_ICON:
        return selected
          ? './assets/theme/svg/recent_selected.svg'
          : './assets/theme/svg/recent_normal.svg';
      case MenuIcons.SETTINGS_ICON:
        return selected
          ? './assets/theme/svg/settings_selected.svg'
          : './assets/theme/svg/settings_normal.svg';
      default:
        return '';
    }
  }

  public getTopLevelMenus(): TopLevelMenuModel[] {
    return this.topLevelMenu;
  }

  public getSubLevelMenus(): SublevelMenuModel[] {
    return this.subLevelMenu;
  }

  public getMobileBreadCrumb(): string {
    return this.mobileBreadCrumb;
  }

  private checkProfileOptions(): void {
    this.isLoggedIn = this.auth.isAuthorized();
    this.isAnonymousProfileActive = this.sessionService.anonymousProfileIsActive();

    this.anonymousHasMultipleCatalogs = (this.config.getAnonymousSettings().catalogs?.length ?? 0) > 1;
  }

  public setLoggedIn(isLoggedIn: boolean): void {
    this.isLoggedIn = isLoggedIn;
  }

  public getIsLoggedIn(): boolean {
    return this.isLoggedIn;
  }

  public getIsAnonymousProfileActive(): boolean {
    return this.isAnonymousProfileActive;
  }

  public getAnonymousHasMultipleCatalogs(): boolean {
    return this.anonymousHasMultipleCatalogs;
  }

  public getIsProfileSwitchingAllowed(): boolean {
    return this.isProfileSwitchingAllowed;
  }
}
