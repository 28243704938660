import { Stb } from '@atv-core/services/session/stb';

export class SubscriptionInfoModel {
  stbs: Stb[] = [];
  showStbs = false;

  showSubscriptionTypes = false;
  showBeginBufferOptions = false;
  showEndBufferOptions = false;
  showDeleteProtected = false;
}
