<div *ngIf="pageLoaded" class="list-page-wrapper left-right-page-padding">
  <app-list-page-filters
    [listFilterModel]="listFilterModel"
    (listFilterChange)="listFilterChange()"
    [allowedAdultMode]="allowedAdultMode"
    [categoryFilterType]="categoryFilterType"
  ></app-list-page-filters>

  <app-ribbon
    *ngFor="let ribbon of ribbons"
    [pageProperties]="pageProperties"
    [allowedAdultMode]="allowedAdultMode"
    [ribbon]="ribbon"
    [registerOnObserver]="ribbonIntersectionObserver"
  ></app-ribbon>

  <div #placeholder></div>

  <div class="list-loading" [class.hidden]="itemsLeftFetching === 0 || !ready">
    <img alt="loading" src="./assets/theme/images/loading.svg" />
  </div>
  <div class="list-no-results" [class.hidden]="hasResults()">{{ noResultText }}</div>
</div>
