import { Injectable } from '@angular/core';
import { BootstrapConfig, Property } from '@atv-core/api/api/api-api.model';
import { ApiApiService } from '@atv-core/api/api/api-api.service';
import { AnonymousSettings, SettingsKeys } from './config.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import configData from './config.json';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private settings = new Map<string, string>();
  private translations = new Map<string, string>();
  private genres = new Map<string, string>();
  private parentalRatingTranslations = new Map<string, string>();
  private anonymousSettings: AnonymousSettings;
  private locale = '';
  private http: HttpClient;

  constructor(private apiService: ApiApiService) {
    this.updateData(configData, true);
  }

  public loadConfig(): Observable<BootstrapConfig> {
    return this.apiService.getBootstrapConfig().pipe(
      tap((config) => {
        this.updateData(config);
      })
    );
  }

  private updateData(data: BootstrapConfig, isLocal = false): void {
    this.locale = data.locale;
    if (isLocal || !environment.useLocalTranslation) {
      this.convert(data.appTranslations, this.translations);
    } else {
      console.log('ignore, using local translations');
    }
    this.convert(data.globalSettings, this.settings);
    this.convert(data.genreTranslations, this.genres);
    this.convert(data.parentalRatingTranslations, this.parentalRatingTranslations);
    this.anonymousSettings = data.anonymousConfig;
  }

  private convert(properties: Property[], map: Map<string, string>): void {
    map.clear();
    properties?.forEach((property) => map.set(property.name, property.value));
  }

  public getSettingString(settingsKey: SettingsKeys, fallback?: string): string {
    return this.settings.get(settingsKey) ?? fallback;
  }

  public getSettingBoolean(settingsKey: SettingsKeys, fallback?: boolean): boolean {
    const str = this.getSettingString(settingsKey)?.toLowerCase();

    return str === 'true' ? true : str === 'false' ? false : fallback;
  }

  public getSettingNumber(settingsKey: SettingsKeys, fallback?: number): number {
    const num = Number(this.getSettingString(settingsKey));

    return isNaN(num) ? fallback : num;
  }

  public getTranslation(translationKey: string, fallbackTranslationKey?: string): string {
    if (this.translations.get(translationKey) !== undefined) {
      return this.translations.get(translationKey);
    } else if (
      fallbackTranslationKey &&
      this.translations.get(fallbackTranslationKey) !== undefined
    ) {
      return this.translations.get(fallbackTranslationKey);
    }

    return `*${translationKey}*`;
  }

  public getGenre(genre: string): string {
    return this.genres.get(genre);
  }

  public getLocale(): string {
    return this.locale;
  }

  public getAnonymousSettings(): AnonymousSettings {
    return this.anonymousSettings;
  }
}
