import 'requestidlecallback-polyfill';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { CmsContentTypes } from '@atv-core/api/cms';
import { EpgProgramSummaryAsset, EpgScheduleSummaryAsset } from '@atv-core/api/epg';
import { AdultMode, AdultService } from '@atv-core/services/adult/adult.service';
import { BookmarkCacheService } from '@atv-core/services/cache/bookmark';
import { ChannelModel } from '@atv-core/services/cache/channel';
import { ChannelRightCacheService } from '@atv-core/services/cache/channelRights';
import { FavoriteCacheService } from '@atv-core/services/cache/favorite';
import { ReminderCacheService } from '@atv-core/services/cache/reminder/reminder-cache.service';
import { StbCacheService } from '@atv-core/services/cache/stb';
import { RecordingModel } from '@atv-core/services/cache/stb/recording.model';
import { DetailTranslationKeys, GuideTranslationKeys } from '@atv-bootstrap/services/config/config.model';
import { ConfigService } from '@atv-bootstrap/services/config/config.service';
import { DetailOverlayService } from '@atv-core/services/detail/detail-overlay.service';
import { MessagesService } from '@atv-core/services/messages/messages.service';
import { MiniDetailService } from '@atv-core/services/mini-detail/mini-detail.service';
import { SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { EpgUtilityService } from '@atv-core/utility/epg-utility/epg-utility.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { DetailRoutes } from '@atv-detail/atv-detail.model';
import { GuideFocusEvent } from '@atv-pages/guide-page/guide-page.component';
import { environment } from '@env/environment';
import { BehaviorSubject, forkJoin, fromEvent, Observable, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BookmarkContentTypes, FavoriteContentTypes, ReminderContentTypes } from './../../../atv-core/api/history/history-api.model';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleComponent implements AfterViewInit, OnDestroy {
  @Input() program: EpgProgramSummaryAsset;
  @Input() selectedDate: Date;
  @Input() widthPerHour: number;
  @Input() channel: ChannelModel;
  @Input() allowedAdultMode: AdultMode;
  @Input() scheduleItemIntersectionObserver: IntersectionObserver;
  @ViewChild('schedule') scheduleDiv: ElementRef<HTMLDivElement>;
  @ViewChild('title') titleDiv: ElementRef<HTMLDivElement>;
  @ViewChild('time') timeDiv: ElementRef<HTMLDivElement>;
  @ViewChild('icons') iconsDiv: ElementRef<HTMLDivElement>;
  @ViewChildren('iconImg') iconImages: QueryList<ElementRef<HTMLImageElement>>;
  @Input() guideFocusEvent?: EventEmitter<GuideFocusEvent>;
  @Input() guideScrollEvent?: BehaviorSubject<number>;
  scheduleWidth = 0;
  schedulePosition = 0;
  startTime: Date = undefined;
  endTime: Date = undefined;
  recording: RecordingModel = undefined;
  playIcon: string = undefined;
  recordingIcon: string = undefined;
  showReminder = false;
  tagsMatch = false;
  refUrl: string = undefined;
  finishingToday = false;
  matchesActiveFilter = false;
  public isSmartTv = SharedUtilityService.isSmartTv();
  public isEntry = false;
  private currentFocus: GuideFocusEvent = null;
  private leftKeySubscription: Subscription;
  private rightKeySubscription: Subscription;
  private scrollLeft = 0;
  private guideFocusSubscription: Subscription;
  private guideScrollSubscription: Subscription;
  private iconImagesSubscription: Subscription;

  constructor(
    private stbCache: StbCacheService,
    private channelRightService: ChannelRightCacheService,
    private router: Router,
    private config: ConfigService,
    private adultService: AdultService,
    private messagesService: MessagesService,
    private reminderCache: ReminderCacheService,
    private miniDetailService: MiniDetailService,
    private zone: NgZone,
    private detailService: DetailOverlayService,
    private spatialNavigationService: SpatialNavigationService,
    private epgUtility: EpgUtilityService,
    private favoriteCache: FavoriteCacheService,
    private bookmarkCache: BookmarkCacheService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  _schedule: EpgScheduleSummaryAsset;

  @Input()
  set schedule(value: EpgScheduleSummaryAsset) {
    this._schedule = value;

    let publishedStart: Date = SharedUtilityService.timeStringToDate(
      this._schedule.published.start,
    );
    let publishedEnd: Date = SharedUtilityService.timeStringToDate(this._schedule.published.end);

    this.startTime = new Date(publishedStart);
    this.endTime = new Date(publishedEnd);

    // do clipping
    const endOfDay = new Date(this.selectedDate);
    endOfDay.setHours(23, 59, 59);
    if (publishedStart < this.selectedDate) {
      publishedStart = new Date(this.selectedDate);
    }
    if (publishedEnd > endOfDay) {
      publishedEnd = endOfDay;
      this.finishingToday = false;
    } else {
      this.finishingToday = true;
    }

    const duration = (publishedEnd.getTime() - publishedStart.getTime()) / 3600000;
    const startHour = (publishedStart.getTime() - this.selectedDate.getTime()) / 3600000;

    this.scheduleWidth = duration * this.widthPerHour;
    this.schedulePosition = startHour * this.widthPerHour;
    if (this.isSmartTv) {
      this.alignTitleAndStartTime();
    }

    window.requestIdleCallback(() => {
      this.reminderCache
        .getReminder(ReminderContentTypes.SCHEDULE, this._schedule.id)
        .subscribe((result) => {
          if (result) {
            this.showReminder = true;
            this.cdr.detectChanges();
          }
        });

      this.stbCache
        .getRecordingForProgram(
          this._schedule.program,
          this.channel.id,
          this.adultService.showAdult(this.allowedAdultMode),
        )
        .subscribe(
          (recording) => {
            if (recording) {
              this.recording = recording;
              this.recordingIcon = recording.getIcon();
            } else {
              this.recording = undefined;
            }
            this.getPlayIcon();
          },
          () => {
            this.getPlayIcon();
          },
        );
    });

    if (this.isSmartTv) {
      const now = new Date();
      this.isEntry =
        now.getTime() > this.startTime.getTime() && now.getTime() <= this.endTime.getTime();
    }

    this.generateUrl();
    this.cdr.detectChanges();
  }

  @Input() set categoryTags(tags: string[]) {
    let filterMatch = false;
    if (this.program && this.program.tags && tags && tags.length !== 0) {
      filterMatch = tags.every((tag) => this.program.tags.includes(tag));
    }

    this.matchesActiveFilter = filterMatch;
  }

  ngAfterViewInit(): void {
    this.scheduleItemIntersectionObserver.observe(this.scheduleDiv.nativeElement);
    this.guideFocusSubscription = this.guideFocusEvent?.subscribe((e: GuideFocusEvent) => {
      this.currentFocus = e;
    });

    this.guideScrollSubscription = this.guideScrollEvent?.subscribe((offset: number) => {
      this.scrollLeft = offset;

      this.alignTitleAndStartTime();
    });

    this.iconImagesSubscription = this.iconImages.changes.subscribe(() => {
      this.alignTitleAndStartTime();
    });
  }

  public getProgramTitle(): string {
    if (this.program.adult && !this.adultService.showAdult(this.allowedAdultMode)) {
      return this.config.getTranslation(GuideTranslationKeys.guide_adult_title);
    }
    return this.program ? this.program.title : undefined;
  }

  scheduleClicked(event: Event): void {
    event.preventDefault();

    if (this.program && this.program.adult) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.zone.run(() => {
            this.navigate();
          });
        },
        errorCallback: () => {
          this.messagesService.showErrorMessage(this.config.getTranslation(DetailTranslationKeys.detail_adult_warning));
        },
      });
    } else {
      this.zone.run(() => {
        this.navigate();
      });
    }
  }

  public onFocus(): void {
    const scheduleLeft = this.scheduleDiv.nativeElement.offsetLeft;
    const scheduleRight = scheduleLeft + this.scheduleDiv.nativeElement.offsetWidth;

    const scrollParent = document.querySelector('.horizontal-scroller') as HTMLDivElement;
    const parentLeft = parseInt(scrollParent.getAttribute('offsetleft'), 10);
    const parentRight = parentLeft + scrollParent.clientWidth;

    this.doHorizontalScroll(scheduleLeft, scheduleRight, parentLeft, parentRight, scrollParent);

    this.emitCurrentOffset(scheduleLeft, scheduleRight, parentLeft, parentRight);

    const icons = [];
    if (this.recordingIcon) {
      icons.push(this.recordingIcon);
    }
    if (this.playIcon) {
      icons.push(this.playIcon);
    }
    this.miniDetailService.setDetail(
      forkJoin([this.getFavoriteIcon(), this.getProgressPercentage()]).pipe(
        map((result) => ({
          title: this.getProgramTitle(),
          favoriteIcon: result[0],
          icons,
          row1: this.epgUtility.getProgramShortInfoWithEpisode(this._schedule, this.program),
          row2: this.epgUtility.getChannelAndScheduleInfo(this.channel, this._schedule),
          progressPercentage: result[1],
          description: this.program.shortSynopsis,
          episodeTitle: this.program.episodeTitle,
        })),
      ),
    );
    this.spatialNavigationService.setLastScheduleElement(this.scheduleDiv.nativeElement);
    const lastActive = document.querySelector('.schedule.last-active');
    lastActive?.classList.remove('last-active');
    this.scheduleDiv.nativeElement.classList.add('last-active');
  }

  public focusOut(): void {
    this.miniDetailService.clearDetail();
    this.leftKeySubscription?.unsubscribe();
    this.leftKeySubscription = undefined;
    this.rightKeySubscription?.unsubscribe();
    this.rightKeySubscription = undefined;
  }

  private getPlayIcon(): void {
    this.channelRightService
      .getPlayIcon(this._schedule, this.channel, this.recording)
      .subscribe((result) => {
        this.playIcon = result;
        this.cdr.detectChanges();
      });
  }

  private generateUrl(): void {
    this.refUrl = `${SharedUtilityService.getFullDetailUrl(
      DetailRoutes.epgDetailRoute,
    )}/0/${CmsContentTypes.SCHEDULE.toLowerCase()}/${this._schedule.id}`;
  }

  private navigate(): void {
    if (this.isSmartTv) {
      this.detailService.openEpgDetail(
        0,
        CmsContentTypes.SCHEDULE.toLowerCase(),
        this._schedule.id,
      );
    } else {
      this.router.navigate([DetailRoutes.epgDetailRoute, 0, CmsContentTypes.SCHEDULE.toLowerCase(), this._schedule.id]);
    }
  }

  private getFavoriteIcon(): Observable<string> {
    return this.favoriteCache
      .isFavorite(FavoriteContentTypes.PROGRAM, this.program.id)
      .pipe(map((result) => SharedUtilityService.getFavoriteIcon(result)));
  }

  private getProgressPercentage(): Observable<number> {
    if (this.epgUtility.scheduleIsLive(this._schedule)) {
      return of(this.epgUtility.calculateProgressPercentage(this._schedule.published));
    }

    return this.bookmarkCache
      .getBookmark(
        BookmarkContentTypes.PROGRAM,
        this.program.id,
        this.adultService.showAdult(AdultMode.any),
      )
      .pipe(map((result) => (result ? (result.position / result.size) * 100 : undefined)));
  }

  private doHorizontalScroll(
    scheduleLeft: number,
    scheduleRight: number,
    parentLeft: number,
    parentRight: number,
    scroller: HTMLDivElement,
  ): void {
    const scrollMargin = 300; // amount of pixels that must at least be visible, otherwise do scroll
    const scrollSize = environment.atv_guide_width_per_hour;

    this.doScrollRight(
      scheduleLeft,
      scheduleRight,
      parentLeft,
      parentRight,
      scroller,
      scrollMargin,
      scrollSize,
    );

    this.doScrollLeft(
      scheduleLeft,
      scheduleRight,
      parentLeft,
      parentRight,
      scroller,
      scrollMargin,
      scrollSize,
    );
  }

  private doScrollRight(
    scheduleLeft: number,
    scheduleRight: number,
    parentLeft: number,
    parentRight: number,
    scroller: HTMLDivElement,
    scrollMargin: number,
    scrollSize: number,
  ): void {
    // schedule extends to the right
    if (scheduleRight > parentRight) {
      // scroll if visible area is too small
      if (scheduleLeft > parentRight - scrollMargin) {
        parentLeft += scrollSize;
        parentRight += scrollSize;
        scroller.setAttribute('offsetleft', `${parentLeft}`);
      }

      // if schedule still extends to the right, catch right key press
      if (scheduleRight > parentRight) {
        this.rightKeySubscription = fromEvent<KeyboardEvent>(
          this.scheduleDiv.nativeElement,
          'keydown',
        )
          .pipe(filter((e) => this.spatialNavigationService.keyCodeIsRight(e.keyCode)))
          .subscribe((e) => {
            e.cancelBubble = true;
            e.preventDefault();
            parentLeft += scrollSize;
            parentRight += scrollSize;
            this.doScroll(scroller, parentLeft, parentRight, scheduleLeft, scheduleRight);
          });
      }
    }
  }

  private doScrollLeft(
    scheduleLeft: number,
    scheduleRight: number,
    parentLeft: number,
    parentRight: number,
    scroller: HTMLDivElement,
    scrollMargin: number,
    scrollSize: number,
  ): void {
    // schedule extends to the left
    if (scheduleLeft < parentLeft) {
      // scroll if visible area is too small
      if (scheduleRight < parentLeft + scrollMargin) {
        parentLeft -= scrollSize;
        parentRight -= scrollSize;
        scroller.setAttribute('offsetleft', `${parentLeft}`);
      }

      // if schedule still extends to the left, catch left key press
      if (scheduleLeft < parentLeft) {
        this.leftKeySubscription = fromEvent<KeyboardEvent>(
          this.scheduleDiv.nativeElement,
          'keydown',
        )
          .pipe(filter((e) => this.spatialNavigationService.keyCodeIsLeft(e.keyCode)))
          .subscribe((e) => {
            e.cancelBubble = true;
            parentLeft -= scrollSize;
            parentRight -= scrollSize;
            this.doScroll(scroller, parentLeft, parentRight, scheduleLeft, scheduleRight);
          });
      }
    }
  }

  private doScroll(scroller: HTMLDivElement, parentLeft: number, parentRight: number, scheduleLeft: number, scheduleRight: number): void {
    scroller.setAttribute('offsetleft', `${parentLeft}`);
    this.emitCurrentOffset(scheduleLeft, scheduleRight, parentLeft, parentRight);
    this.rightKeySubscription?.unsubscribe();
    this.rightKeySubscription = undefined;
    this.leftKeySubscription?.unsubscribe();
    this.leftKeySubscription = undefined;
    this.doHorizontalScroll(scheduleLeft, scheduleRight, parentLeft, parentRight, scroller);
  }

  private emitCurrentOffset(
    scheduleLeft: number,
    scheduleRight: number,
    parentLeft: number,
    parentRight: number,
  ): void {
    let newOffset = null;
    const now = new Date();

    // calculate new offset when it has already been calculated before
    // or when the newly focused schedule is not live
    if (
      this.currentFocus?.currentOffsetLeft ||
      now.getTime() < this.startTime.getTime() ||
      now.getTime() >= this.endTime.getTime()
    ) {
      // center of (visible) schedule is new offset

      // do clipping
      let left = scheduleLeft;
      let right = scheduleRight;
      if (scheduleLeft < parentLeft) {
        left = parentLeft;
      }
      if (scheduleRight > parentRight) {
        right = parentRight;
      }

      newOffset = left + (right - left) / 2;
    }

    this.guideFocusEvent.emit({ channel: this.channel.id, currentOffsetLeft: newOffset });
  }

  private alignTitleAndStartTime(): void {
    if (!this.titleDiv || !this.timeDiv) {
      return;
    }

    if (
      this.schedulePosition < this.scrollLeft &&
      this.schedulePosition + this.scheduleWidth > this.scrollLeft
    ) {
      const offset = this.scrollLeft - this.schedulePosition - 30; // compensate for left margin

      let titleOffset = Math.min(
        offset,
        this.scheduleWidth - this.titleDiv.nativeElement.offsetWidth - 25,
      );
      titleOffset = Math.max(0, titleOffset);
      this.titleDiv.nativeElement.style.transform = `translateX(${titleOffset}px)`;

      let timeOffset = Math.min(
        offset,
        this.scheduleWidth - this.timeDiv.nativeElement.offsetWidth - (this.iconsDiv?.nativeElement.clientWidth ?? 0) - 25,
      );
      timeOffset = Math.max(0, timeOffset);
      this.timeDiv.nativeElement.style.transform = `translateX(${timeOffset}px)`;
    } else {
      if (
        this.titleDiv.nativeElement.style.transform &&
        this.titleDiv.nativeElement.style.transform !== 'translateX(0px)'
      ) {
        this.titleDiv.nativeElement.style.transform = 'translateX(0px)';
      }

      if (
        this.timeDiv.nativeElement.style.transform &&
        this.timeDiv.nativeElement.style.transform !== 'translateX(0px)'
      ) {
        this.timeDiv.nativeElement.style.transform = 'translateX(0px)';
      }
    }
  }

  ngOnDestroy(): void {
    this.scheduleItemIntersectionObserver.unobserve(this.scheduleDiv.nativeElement);
    this.guideFocusSubscription?.unsubscribe();
    this.guideScrollSubscription?.unsubscribe();
    this.iconImagesSubscription?.unsubscribe();
  }
}
