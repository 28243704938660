import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AtvSharedModule } from '../atv-shared/atv-shared.module';
import { ClientAreaPageComponent } from './client-area-page/client-area-page.component';
import { CmsPageFiltersComponent } from './cms-page/cms-page-filters/cms-page-filters.component';
import { CmsPageComponent } from './cms-page/cms-page.component';
import { CustomPageComponent } from './custom-page/custom-page.component';
import { GuidePageComponent } from './guide-page/guide-page.component';
import { ListPageComponent } from './list-page/list-page.component';
import { RecordingsPageComponent } from './recordings-page/recordings-page.component';
import { RemindersPageComponent } from './reminders-page/reminders-page.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { SearchRecordingFolderComponent } from './search-page/search-recording-folder/search-recording-folder.component';
import { HighlightPipe } from './search-page/searchbar/highlight.pipe';
import { SearchbarComponent } from './search-page/searchbar/searchbar.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';

@NgModule({
  declarations: [
    SearchPageComponent,
    SearchRecordingFolderComponent,
    ListPageComponent,
    GuidePageComponent,
    SettingsPageComponent,
    ClientAreaPageComponent,
    RemindersPageComponent,
    RecordingsPageComponent,
    CmsPageComponent,
    CmsPageFiltersComponent,
    SearchRecordingFolderComponent,
    CustomPageComponent,
    HighlightPipe,
    SearchbarComponent,
  ],
  imports: [CommonModule, AtvSharedModule, FormsModule],
})
export class AtvPagesModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: AtvPagesModule
  ) {
    if (parentModule) {
      throw new Error('AtvPagesModule is already loaded. Import it in the AppModule only!');
    }
  }
}
