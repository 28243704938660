import { Location } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DetailRoutes } from '@atv-detail/atv-detail.model';

import { SessionService } from './../session/session.service';

@Injectable({
  providedIn: 'root',
})
export class DetailOverlayService {
  public closeDetailEvent = new EventEmitter<void>();

  constructor(
    private location: Location,
    private router: Router,
    private sessionService: SessionService
  ) {}

  isOverlayOpen(): boolean {
    return this.router.url.startsWith('/detail/') || this.router.url.includes('detailOverlay');
  }

  closeDetail(): void {
    // if main router includes detail route
    if (
      // state &&
      // state.previousRoute &&
      // !state.previousRoute.includes('detailOverlay') &&
      this.router.url.includes('detailOverlay')
    ) {
      this.router.navigate([{ outlets: { detailOverlay: null } }]);
    } else {
      this.sessionService.goToPreviousRoute();
    }

    setTimeout(() => {
      this.closeDetailEvent.emit();
    }, 0);
  }

  openDetail(route: DetailRoutes, id: string): void {
    // if open detail from even and no active route or main router includes detail route
    if (this.router.url === '/' || this.router.url.startsWith(DetailRoutes.detailBaseRoute) || this.router.url.includes('custom/')) {
      this.router.navigate([route, id], {
        replaceUrl: this.router.url.includes(route),
      });
    } else {
      this.router
        .navigate([{ outlets: { detailOverlay: `${route}/${id}` } }], {
          replaceUrl: this.router.url.includes(route),
          state: { previousRoute: this.router.url },
        })
        .then(() => {
          setTimeout(() => {
            this.location.replaceState(`${route}/${id}`);
          }, 0);
        });
    }
  }

  openEpgDetail(isRecordingPage: number, type: string, id: string): void {
    // if open detail from event and no active route or main router includes detail route
    if (this.router.url === '/' || this.router.url.startsWith(DetailRoutes.detailBaseRoute)) {
      this.router.navigate(
        [DetailRoutes.epgDetailRoute, 0, type, id],
        {
          replaceUrl: this.router.url.includes(DetailRoutes.epgDetailRoute),
        },
      );
    } else {
      const url = `${DetailRoutes.epgDetailRoute}/${isRecordingPage}/${type}/${id}`;
      this.router
        .navigate(
          [
            {
              outlets: {
                detailOverlay: url,
              },
            },
          ],
          {
            replaceUrl: this.router.url.includes(DetailRoutes.epgDetailRoute),
            state: { previousRoute: this.router.url },
          }
        )
        .then(() => {
          setTimeout(() => {
            this.location.replaceState(url);
          }, 0);
        });
    }
  }
}
