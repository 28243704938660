import { DeviceStatus, ProgramSubscription, SeasonSubscription, SeriesSubscription, SubscriptionContainer } from '@atv-core/api/recording';
import { RecordingTranslationKeys } from '@atv-bootstrap/services/config';
import { Observable } from 'rxjs';

import { RecordingModel } from './recording.model';

export enum RecordingPageTypes {
  recorded = 'recorded',
  planned = 'planned',
  conflict = 'conflict',
  expires = 'expires',
}

export interface RecordingAvailableSpace {
  available: string;
  used: number;
  max: number;
}

export class StbCacheModel {
  cachePromise: Observable<any> = undefined;
  expirationTime = 0;
  reloadingCache = false;

  private translations;

  private recordings: RecordingModel[];
  private programSubscriptions: ProgramSubscription[];
  private seriesSubscriptions: SeriesSubscription[];
  private seasonSubscriptions: SeasonSubscription[];
  private status: DeviceStatus;

  constructor(neededTranslations) {
    this.translations = neededTranslations;

    this.recordings = [];
  }

  setRecordings(recordings: RecordingModel[]) {
    this.recordings = recordings;
  }

  setSubcriptions(subscriptions: SubscriptionContainer) {
    this.programSubscriptions = subscriptions.programSubscriptions.map(
      (sub) => new ProgramSubscription(sub)
    );
    this.seriesSubscriptions = subscriptions.seriesSubscriptions.map(
      (sub) => new SeriesSubscription(sub)
    );
    this.seasonSubscriptions = subscriptions.seasonSubscriptions.map(
      (sub) => new SeasonSubscription(sub)
    );
  }

  setStatus(status: DeviceStatus) {
    this.status = status;
  }

  getAvailableUsedMaxSpace(): RecordingAvailableSpace {
    let availableSpace = '';
    let usedSpace = 0;
    let maxSpace = 0;
    if (
      this.status &&
      this.status.npvr &&
      this.status.npvr.maximumHours !== undefined &&
      this.status.npvr.usedHours !== undefined
    ) {
      usedSpace = this.status.npvr.usedHours;
      maxSpace = this.status.npvr.maximumHours;
      let availableHours = this.status.npvr.maximumHours - this.status.npvr.usedHours;
      if (availableHours < 1) {
        const availableMinutes = Math.floor(60 * availableHours);
        availableSpace = this.translations[RecordingTranslationKeys.recordings_minutes_available]
          .replace('$1', availableMinutes)
          .replace('$2', this.status.npvr.maximumHours);
      } else {
        availableHours = Math.max(Math.floor(availableHours), 0);
        availableSpace = this.translations[RecordingTranslationKeys.recordings_hours_available]
          .replace('$1', availableHours)
          .replace('$2', this.status.npvr.maximumHours);
      }
    } else if (this.status.stb) {
      if (
        this.status.stb.maximumSize !== undefined &&
        this.status.stb.usedSize !== undefined &&
        this.status.stb.maximumSize !== 0
      ) {
        usedSpace = this.status.stb.usedSize;
        maxSpace = this.status.stb.maximumSize;

        // round to 2 dec
        const usedSpaceInText = (
          (this.status.stb.usedSize / this.status.stb.maximumSize) *
          100
        ).toFixed(2);

        availableSpace = this.translations[
          RecordingTranslationKeys.recordings_free_space_stb
        ].replace('$1', usedSpaceInText + '%');
      }
    }
    return {
      available: availableSpace,
      used: usedSpace,
      max: maxSpace,
    };
  }

  getProgramSubscription(programId, channelId) {
    if (this.programSubscriptions === undefined) {
      return undefined;
    }
    return this.programSubscriptions.find(
      (sub) => sub.identifier === programId && sub.channel === channelId
    );
  }

  getSeasonSubscription(seasonId, channelId) {
    if (this.seasonSubscriptions === undefined) {
      return undefined;
    }
    return this.seasonSubscriptions.find(
      (sub) => sub.identifier === seasonId && sub.channel === channelId
    );
  }

  getSeriesSubscription(seriesId, channelId) {
    if (this.seriesSubscriptions === undefined) {
      return undefined;
    }
    return this.seriesSubscriptions.find(
      (sub) => sub.identifier === seriesId && sub.channel === channelId
    );
  }

  public getRecordings(allowAdult: boolean): RecordingModel[] {
    if (allowAdult) {
      return this.recordings;
    } else {
      return this.recordings.filter((recording) => {
        return !recording.adult;
      });
    }
  }

  getRecordingsForType(type: RecordingPageTypes, showAdult: boolean): RecordingModel[] {
    switch (type) {
      case RecordingPageTypes.recorded:
        return this.getFinishedRecordings(showAdult);
      case RecordingPageTypes.planned:
        return this.getPlannedRecordings(showAdult);
      case RecordingPageTypes.conflict:
        return this.getConflictRecordings(showAdult);
      case RecordingPageTypes.expires:
        return this.getExpiredRecordings(showAdult);
      default:
        return [];
    }
  }
  private getSortedRecordingsOnExpirationTimeAcending() {
    return this.recordings.sort((a, b) => {
      if (parseInt(a.expiration) < parseInt(b.expiration)) {
        return -1;
      } else if (parseInt(a.expiration) > parseInt(b.expiration)) {
        return 1;
      } else {
        if (parseInt(a.plannedStart) < parseInt(b.plannedStart)) {
          return -1;
        } else if (parseInt(a.plannedStart) > parseInt(b.plannedStart)) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  }

  private getSortedRecordingsOnStartTimeAscending() {
    return this.recordings.sort((a, b) => {
      if (parseInt(a.plannedStart) < parseInt(b.plannedStart)) {
        return -1;
      } else if (parseInt(a.plannedStart) > parseInt(b.plannedStart)) {
        return 1;
      } else {
        if (parseInt(a.plannedEnd) < parseInt(b.plannedEnd)) {
          return -1;
        } else if (parseInt(a.plannedEnd) > parseInt(b.plannedEnd)) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  }

  private getSortedRecordingsOnEndTimeDescending() {
    return this.recordings.sort(function (a, b) {
      if (parseInt(a.plannedEnd) < parseInt(b.plannedEnd)) {
        return 1;
      } else if (parseInt(a.plannedEnd) > parseInt(b.plannedEnd)) {
        return -1;
      } else {
        if (parseInt(a.plannedStart) < parseInt(b.plannedStart)) {
          return 1;
        } else if (parseInt(a.plannedStart) > parseInt(b.plannedStart)) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  }

  public getPlannedRecordings(showAdult: boolean) {
    if (this.recordings.length === 0) {
      return [];
    }
    const resultRecordings = this.getSortedRecordingsOnStartTimeAscending().filter((recording) => {
      return recording.isPlanned();
    });
    return this.filterAdult(resultRecordings, showAdult);
  }

  public getFinishedRecordings(showAdult: boolean) {
    if (this.recordings.length === 0) {
      return [];
    }
    const resultRecordings = this.getSortedRecordingsOnEndTimeDescending().filter((recording) => {
      return recording.isRecorded() || recording.isInProgress();
    });
    return this.filterAdult(resultRecordings, showAdult);
  }

  public getConflictRecordings(showAdult: boolean) {
    if (this.recordings.length === 0) {
      return [];
    }

    const resultRecordings = this.getSortedRecordingsOnStartTimeAscending().filter((recording) => {
      return recording.hasConflict();
    });
    return this.filterAdult(resultRecordings, showAdult);
  }

  public getExpiredRecordings(showAdult: boolean) {
    if (this.recordings.length === 0) {
      return [];
    }
    const resultRecordings = this.getSortedRecordingsOnExpirationTimeAcending().filter(function (
      recording
    ) {
      return recording.isAboutToExpire();
    });

    return this.filterAdult(resultRecordings, showAdult);
  }

  private filterAdult(recordings: RecordingModel[], showAdult: boolean) {
    if (showAdult) {
      return recordings;
    } else {
      return recordings.filter((recording) => {
        return !recording.adult;
      });
    }
  }
}
