import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CardSize, CmsApiService, CmsGrid, CmsRibbon, CustomSearchRow, PageTypes, Properties, SearchPage } from '@atv-core/api/cms';
import { SearchElementList, SearchTypes } from '@atv-core/api/search/search-api.model';
import { SearchApiService } from '@atv-core/api/search/search-api.service';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { ChannelCacheService, ChannelModel } from '@atv-core/services/cache/channel';
import { ConfigService, ErrorTranslationKeys, SearchTranslationKeys } from '@atv-bootstrap/services/config';
import { DetailOverlayService } from '@atv-core/services/detail/detail-overlay.service';
import { PageData } from '@atv-core/services/menu-manager/menu-manager.service';
import { MessagesService } from '@atv-core/services/messages';
import { SessionService } from '@atv-core/services/session';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { JumpablePage } from '@atv-pages/jumpable-page';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { StbCacheService } from '@atv-core/services/cache/stb';
import { SearchAction, SearchbarComponent } from './searchbar/searchbar.component';
import { Location } from '@angular/common';
import { MiniDetailService } from '@atv-core/services/mini-detail/mini-detail.service';
import { ContentBackdropService } from '@atv-shared/content-backdrop/content-backdrop.service';
import { SearchContentProvider } from '@atv-shared/content-provider/providers/search-content-provider';
import { LogService } from '@atv-core/services/log/log.service';
import { PageContext } from '@atv-core/services/log/log.model';
import { SearchRecordingContentProvider } from '@atv-shared/content-provider/providers/search-recording-content-provider';
import { ContentProviderFactoryService } from '@atv-shared/content-provider/content-provider-factory.service';
import { ContentProvider } from '@atv-shared/content-provider/content-provider';

class SearchRow {
  replay = false;
  ribbon: CmsRibbon;
}

class SearchGrid {
  replay = false;
  grid: CmsGrid;
}

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
})
export class SearchPageComponent extends JumpablePage implements OnInit, OnDestroy {
  public searchPageLayout: SearchPage;
  public searchRows: SearchRow[];
  public searchGrid: SearchGrid;
  public channels: ChannelModel[];

  public noResults = false;
  public noResultsText = '';

  public allowedAdultMode: AdultMode = AdultMode.false;
  @ViewChild('searchbar') searchbar: SearchbarComponent;
  private searchRequestSubscription: Subscription;
  private closeDetailSubscription: Subscription;
  private scrollTopSubscription: Subscription;
  private returnSubscription: Subscription;

  constructor(
    private searchApi: SearchApiService,
    config: ConfigService,
    private channelCache: ChannelCacheService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    adultService: AdultService,
    sessionService: SessionService,
    private cmsApi: CmsApiService,
    private stbCache: StbCacheService,
    authorizationService: AuthorizationService,
    messageService: MessagesService,
    private spatialNavigationService: SpatialNavigationService,
    private detailService: DetailOverlayService,
    private elRef: ElementRef,
    private location: Location,
    private miniDetailService: MiniDetailService,
    private backdropService: ContentBackdropService,
    private log: LogService,
    private contentProviderFactory: ContentProviderFactoryService,
  ) {
    super(sessionService, authorizationService, adultService, messageService, config);
    this.noResultsText = this.config.getTranslation(ErrorTranslationKeys.error_search_no_results);
  }

  ngOnInit(): void {
    super.start();
    if (SharedUtilityService.isSmartTv()) {
      this.closeDetailSubscription = this.detailService.closeDetailEvent.subscribe(() => {
        this.spatialNavigationService.setFocus(
          this.spatialNavigationService.getLastPageContentSection(),
        );
      });

      this.scrollTopSubscription = this.spatialNavigationService.scrollTopEvent.subscribe(() => {
        const scroller: HTMLDivElement = document.querySelector('.smarttv-vertical-scroller');

        if (scroller) {
          scroller.style.transform = 'translateY(0px)';
        }
      });
    }
  }

  public loadPage(): void {
    if (!this.page) {
      console.error('page not loaded');
      return;
    }
    this.logPageView();

    if (this.page.type === PageTypes.SEARCH_PAGE) {
      // NEBINT-5337
      if (
        this.page.searchPage?.properties?.includes(Properties.ALLOW_ADULT) &&
        this.allowedAdultMode !== AdultMode.true
      ) {
        this.allowedAdultMode = AdultMode.any;
      }

      this.searchPageLayout = new SearchPage(this.page.searchPage);
      this.searchbar.checkSearch();
      this.stbCache.stbRecordingChange.subscribe(() => {
        this.searchbar.checkSearch();
      });
    }

    if (this.page.backgroundImage) {
      this.backdropService.addCustomBackdropImage(this.page.backgroundImage);
    }

    if (SharedUtilityService.isSmartTv()) {
      this.registerElements();

      if (this.isOnCustomPage) {
        this.spatialNavigationService.setFocus(NavigationSections.SEARCH_BAR);
      }
    }

    this.page = undefined;
  }

  getPageProperties(): Observable<Properties[]> {
    if (this.page) {
      return of(this.page.searchPage.properties);
    }

    this.pageId = (this.activatedRoute.snapshot.data as PageData).pageId;
    return this.cmsApi
      .getDynamicPage(
        this.pageId,
        this.sessionService.getEntitlementId(),
        this.adultService.getAdultMode(
          this.allowedAdultMode === AdultMode.true ? this.allowedAdultMode : AdultMode.any,
        ),
      )
      .pipe(
        tap((page) => {
          this.page = page;
        }),
        map((page) => page.searchPage?.properties),
      );
  }

  private logPageView(): void {
    this.log.pageView(
      new PageContext({
        pageLocale: this.config.getLocale(),
        pageTitle: this.config.getTranslation(SearchTranslationKeys.search_placeholder),
        pageURL: this.pageId,
      }),
    );
  }

  public clearSearchResults(): void {
    this.searchRows = [];
    this.searchGrid = undefined;
    this.noResults = true;
  }

  public search(search: SearchAction): void {
    if (!search.term || !this.searchPageLayout) {
      return;
    }
    this.updateSearchQueryParam(search.term);
    this.cancelSearchRequest();
    const searchTypes = this.searchPageLayout.getUniqueSearchTypes();

    this.searchRequestSubscription = this.searchApi
      .search(
        search.term,
        searchTypes,
        this.adultService.getAdultMode(this.allowedAdultMode),
        this.sessionService.getCatalogId(),
        true,
      )
      .subscribe((result) => {
        this.searchbar.setCompletions(result.completions);

        if (!result || !result.results || result.results.length === 0) {
          this.searchRows = [];
          this.searchGrid = undefined;
          this.noResults = true;

          return;
        }

        this.noResults = false;

        this.fetchChannelsObservable(searchTypes).subscribe(() => {
          this.createContent(result.results, search.term);
        });

        if (SharedUtilityService.isSmartTv()) {
          this.registerElements(search.fromCompletion);
        }
      });
  }

  public registerElements(focusOnCompletions = false): void {
    if (!SharedUtilityService.isSmartTv()) {
      return;
    }

    this.unregisterElements();

    const visibleRows = this.getVisibleRows();

    this.spatialNavigationService.register(NavigationSections.SEARCH_BAR, 'input#search, #autocompletions .completion', {
      defaultElement: '#autocompletions .completion',
      leaveFor: { down: visibleRows.length > 0 ? `@${NavigationSections.PAGE_CONTENT}#${visibleRows[0]}` : '', right: '' },
    });

    visibleRows.forEach((i, index) => {
      this.spatialNavigationService.register(
        `${NavigationSections.PAGE_CONTENT}#${i}`,
        `.results .row-${i} app-ribbon .ribbon-item a,
          .results .row-${i} app-grid .grid-item a,
          .cms-page-wrapper .row-${i} .epg-card.channel-card-special`,
        {
          straightOnly: true,
          restrict: 'self-only',
          enterTo: 'last-focused',
          leaveFor: {
            left: this.isOnCustomPage ? '' : `@${NavigationSections.MENU}`,
            up:
              index > 0
                ? `@${NavigationSections.PAGE_CONTENT}#${visibleRows[index - 1]}`
                : `@${NavigationSections.SEARCH_BAR}`,
            down:
              index < visibleRows.length
                ? `@${NavigationSections.PAGE_CONTENT}#${visibleRows[index + 1]}`
                : '',
          },
        },
      );
    });

    this.returnSubscription = fromEvent<KeyboardEvent>(this.elRef.nativeElement, 'keydown')
      .pipe(filter((event) => this.spatialNavigationService.keyCodeIsReturn(event.keyCode)))
      .subscribe(() => {
        if (this.isOnCustomPage) {
          this.location.back();
          this.miniDetailService.clearDetail(); // TODO remove this, focus on previous element when returning back to "main" view
        } else {
          this.spatialNavigationService.setFocus(NavigationSections.MENU);
        }
      });

    if (focusOnCompletions) {
      this.spatialNavigationService.setFocus(NavigationSections.SEARCH_BAR);
    }
  }

  public cancelSearchRequest(): void {
    if (this.searchRequestSubscription) {
      this.searchRequestSubscription.unsubscribe();
      this.searchRequestSubscription = undefined;
    }
  }

  private fetchChannelsObservable(searchTypes: Set<SearchTypes>): Observable<ChannelModel[]> {
    if (
      searchTypes.has(SearchTypes.LINEAR) ||
      searchTypes.has(SearchTypes.REPLAY) ||
      searchTypes.has(SearchTypes.RECORDING)
    ) {
      return this.channelCache.getChannels().pipe(
        tap((channels) => {
          this.channels = channels;
        }),
        catchError(() => {
          this.channels = [];
          return of([]);
        }),
      );
    } else {
      return of(undefined);
    }
  }

  private createContent(searchElements: SearchElementList[], term: string): void {
    if (searchElements?.length === 1) {
      this.createGrid(searchElements[0], term);
    } else {
      this.createRibbons(searchElements, term);
    }
  }

  private createRibbons(searchElements: SearchElementList[], term: string): void {
    this.searchRows = [];
    this.searchGrid = undefined;

    this.searchPageLayout.rows.forEach((row) => {
      const searchRow: SearchRow = {
        replay: row.type === SearchTypes.REPLAY,
        ribbon: this.createRibbon(searchElements, row, term),
      };

      if (searchRow && searchRow.ribbon) {
        this.searchRows.push(searchRow);
      }
    });
  }

  private createRibbon(searchElements: SearchElementList[], row: CustomSearchRow, term: string): CmsRibbon {
    const filteredSearchElements: SearchElementList = searchElements.find(
      (res) => res.type === row.type,
    );

    if (filteredSearchElements && filteredSearchElements.elements?.items?.length !== 0) {
      let contentProvider: ContentProvider;
      if (filteredSearchElements.type === SearchTypes.RECORDING) {
        contentProvider = new SearchRecordingContentProvider(
          this.stbCache,
          this.adultService.showAdult(this.allowedAdultMode),
          this.contentProviderFactory,
          filteredSearchElements.elements
        );
      } else {
        contentProvider = this.createSearchContentProvider(filteredSearchElements, term);
      }
      return {
        title: row.title,
        contentProvider,
        properties: row.properties,
        isOnDetailPage: false,
        cardSize: CardSize.NORMAL,
      };
    }

    return undefined;
  }

  private updateSearchQueryParam(searchTerm: string): void {
    if (!searchTerm) {
      return;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { q: encodeURI(searchTerm) },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    }).then(() => {
    });
  }

  private createGrid(searchElement: SearchElementList, term: string): void {
    this.searchRows = [];
    this.searchGrid = undefined;
    const row: CustomSearchRow = this.searchPageLayout.rows.find(
      (r) => r.type === searchElement.type,
    );
    if (row) {
      let contentProvider: ContentProvider;
      if (searchElement.type === SearchTypes.RECORDING) {
        contentProvider = new SearchRecordingContentProvider(
          this.stbCache,
          this.adultService.showAdult(this.allowedAdultMode),
          this.contentProviderFactory,
          searchElement.elements
        );
      } else {
        contentProvider = this.createSearchContentProvider(searchElement, term);
      }

      if (contentProvider) {
        this.searchGrid = {
          replay: row.type === SearchTypes.REPLAY,
          grid: new CmsGrid({
            title: row.title,
            contentProvider,
            properties: row.properties,
          }),
        };
      }
    }
  }

  private createSearchContentProvider(elements: SearchElementList, term: string): SearchContentProvider {
    return new SearchContentProvider(this.searchApi, this.adultService, this.sessionService, elements, term, this.allowedAdultMode);
  }

  private unregisterElements(): void {
    this.spatialNavigationService.unregister(NavigationSections.SEARCH_BAR);

    // remove grid, if present
    this.spatialNavigationService.unregister(`${NavigationSections.PAGE_CONTENT}#0`);

    if (this.searchRows) {
      for (let i = 0; i < this.searchRows.length; ++i) {
        this.spatialNavigationService.unregister(`${NavigationSections.PAGE_CONTENT}#${i}`);
      }
    }

    this.returnSubscription?.unsubscribe();
  }

  private getVisibleRows(): number[] {
    const rows = [];

    for (let i = 0; i < this.searchRows?.length; ++i) {
      if (
        !document.querySelector(
          `.result.row-${i} .ribbon-wrapper.hidden,
          .result.row-${i} .grid-wrapper.hidden,
          .result.row-${i} .ribbon.hidden`,
        )
      ) {
        rows.push(i);
      }
    }

    if (this.searchGrid) {
      rows.push(this.searchRows?.length ?? 0);
    }

    return rows;
  }

  ngOnDestroy(): void {
    super.stop();

    if (SharedUtilityService.isSmartTv()) {
      this.unregisterElements();
    }

    this.closeDetailSubscription?.unsubscribe();
    this.scrollTopSubscription?.unsubscribe();
    this.cancelSearchRequest();
  }
}
