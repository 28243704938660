import {
  EpgProgramDetailAsset,
  EpgScheduleSummaryAsset,
  EpgSeasonSummaryAsset,
  EpgSeriesSummaryAsset,
} from '@atv-core/api/epg/epg-api.model';
import { Stream2AssetResponse, Stream2ManifestResponse } from '@atv-core/api/ss/streaming-api.model';
import { VodDetail } from '@atv-core/api/vod';
import { ChannelModel } from '@atv-core/services/cache/channel';
import { RecordingModel } from '@atv-core/services/cache/stb/recording.model';
import { StreamType } from '@atv-core/utility/constants/shared';

export class PlayInfoModel {
  streamType: StreamType;
  streamAssetId: string;
  bookmarkPos: number;
  quoteId = undefined;
  isTrailer = false;
  isReservedStream = false;

  isAdult = false;
  isSeriesDetail = false;
  channelInformation: ChannelModel = undefined;
  programInformation: EpgProgramDetailAsset = undefined;
  seriesInformation: EpgSeriesSummaryAsset = undefined;
  seasonInformation: EpgSeasonSummaryAsset = undefined;
  scheduleInformation: EpgScheduleSummaryAsset = undefined;
  recordingInformation: RecordingModel = undefined;
  allSchedules: EpgScheduleSummaryAsset[] = undefined;

  vodInformation: VodDetail = undefined;

  streamAssetInfo: Stream2AssetResponse = undefined;
  activeManifest: Stream2ManifestResponse = undefined;

  constructor(streamType: StreamType, streamId: string, bookmark: number) {
    // this.detail = new PlayDetail(detail);
    this.streamType = streamType;
    this.streamAssetId = streamId;
    this.bookmarkPos = bookmark;
    this.recordingInformation = undefined;
    this.quoteId = undefined;
    this.isTrailer = false;
  }

  public setEpgInfo(
    channel: ChannelModel,
    isAdult: boolean,
    program: EpgProgramDetailAsset,
    series: EpgSeriesSummaryAsset,
    season: EpgSeasonSummaryAsset,
    recording: RecordingModel,
    schedule: EpgScheduleSummaryAsset,
    allSchedules: EpgScheduleSummaryAsset[],
    isSeriesDetail: boolean,
  ): void {
    this.channelInformation = channel;
    this.recordingInformation = recording;
    this.scheduleInformation = schedule;
    this.allSchedules = allSchedules;
    this.programInformation = program;
    this.seasonInformation = season;
    this.seriesInformation = series;
    this.isAdult = isAdult;
    this.isSeriesDetail = isSeriesDetail;
  }

  public setVodInfo(
    vodInfo: VodDetail,
    quoteId: string,
    reserved: boolean,
    isTrailer: boolean,
    isAdult: boolean,
  ): void {
    this.vodInformation = vodInfo;
    this.quoteId = quoteId;
    this.isReservedStream = reserved;
    this.isAdult = isAdult;
    this.isTrailer = isTrailer || false;
  }

  public streamIsVod(): boolean {
    return (
      this.streamType === StreamType.SVOD ||
      this.streamType === StreamType.TVOD ||
      this.streamType === StreamType.FVOD
    );
  }

  public streamIsLinear(): boolean {
    return this.streamType === StreamType.LINEAR;
  }
}
