import { AfterViewInit, Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ReminderCacheService } from '@atv-core/services/cache/reminder/reminder-cache.service';
import { PlatformType } from '@atv-core/utility/constants/shared';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { environment } from '@env/environment';
import { filter } from 'rxjs/operators';
import { MenuManagerService } from '@atv-core/services/menu-manager/menu-manager.service';
import { versionInfo } from './version-info';
import { PlayerService } from '@atv-core/services/player/player.service';
import { ConfigService } from '@atv-bootstrap/services/config';
import { SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  public isSmartTv = SharedUtilityService.isSmartTv();
  public isTizen = environment.platform === PlatformType.SMARTTV_TIZEN;
  private previousRoute: string;
  @ViewChild('playerTemplate', { read: ViewContainerRef }) playerVcRef: ViewContainerRef;
  @ViewChild('remotePlayerTemplate', { read: ViewContainerRef }) remotePlayerVcRef: ViewContainerRef;
  @ViewChild('detailOverlay') detailOverlay: ElementRef<HTMLDivElement>;

  constructor(
    private config: ConfigService,
    private menuManager: MenuManagerService, // DO NOT REMOVE (THIS INITIALIZES SERVICE)
    private reminderCache: ReminderCacheService, // DO NOT REMOVE (THIS INITIALIZES SERVICE),
    private router: Router,
    private playerService: PlayerService,
    private spatialNavigationService: SpatialNavigationService,
  ) {
    const version = document.createComment(`androme.tv version ${versionInfo.version}`);
    document.head.appendChild(version);

    // scroll to top when same route is loaded
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        if (e.urlAfterRedirects.includes('detailOverlay')) {
          document.body.classList.add('detail-overlay-is-open');
        } else {
          document.body.classList.remove('detail-overlay-is-open');
        }
        if (this.previousRoute === e.urlAfterRedirects && !this.isSmartTv) {
          if (e.urlAfterRedirects.includes('detailOverlay')) {
            const item = document.getElementById('detail-overlay-wrapper');
            if (item) {
              item.scrollTo(0, 0);
            }
          } else {
            window.scrollTo(0, 0);
          }
        }
        this.previousRoute = e.urlAfterRedirects;
      });
  }

  public ngAfterViewInit(): void {
    this.playerService.setPlayerComponentContainer(this.playerVcRef);
    this.playerService.setRemotePlayerComponentContainer(this.remotePlayerVcRef);

    if (this.isSmartTv) {
      document.onscroll = () => {
        document.scrollingElement.scrollTop = 0;
      };

      this.detailOverlay.nativeElement.onscroll = () => {
        this.detailOverlay.nativeElement.scrollTop = 0;
      };

      if (environment.platform === PlatformType.SMARTTV_TIZEN) {
        window.tizen?.tvinputdevice.registerKeyBatch([
          'MediaPlay',
          'MediaPause',
          'MediaStop',
          'MediaRewind',
          'MediaFastForward',
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          'ChannelUp',
          'ChannelDown',
          'Guide',
          'ChannelList',
          'Info',
        ]);
      } else if (environment.platform === PlatformType.SMARTTV_WEBOS) {
        window.addEventListener('mousewheel', (event: WheelEvent) => {
          if (event.deltaY > 0) {
            this.spatialNavigationService.move('down');
            event.cancelBubble = true;
            event.preventDefault();
          } else if (event.deltaY < 0) {
            this.spatialNavigationService.move('up');
            event.cancelBubble = true;
            event.preventDefault();
          }
        });
      }
    }
  }
}
