<div class="channel">
  <img
    *ngIf="userIsAuthorized"
    [src]="
      currentChannel?.isFavorite()
        ? './assets/theme/svg/favoritesicon_selected.svg'
        : './assets/theme/svg/favoritesicon_normal.svg'
    "
    class="channel-favorite"
    (click)="toggleFavorite()"
  />
  <div class="channel-info">
    <span class="channel-number">{{ channelNumber }}</span>
    <img
      *ngIf="currentChannel?.defaultLogo"
      class="channel-logo"
      [src]="getChannelLogo(currentChannel)"
      onerror="this.style.display = 'none'"
    />
    <span *ngIf="!currentChannel?.recordableIcon" class="filler"></span>
    <img
      *ngIf="currentChannel?.recordableIcon"
      class="channel-recordable-icon"
      [src]="currentChannel?.recordableIcon"
    />
  </div>
</div>
