<div class="popup-wrapper" [class.hidden]="!showSharePopup">
  <div class="popup">
    <div class="popup-header">
      <div class="popup-header-title">{{ sharePopupTitle }}</div>
      <div class="popup-close" (click)="closePopup()"></div>
    </div>
    <div class="popup-content">
      <div class="share-buttons" style="display: flex; justify-content: center; flex-wrap: wrap">
        <share-button
          theme="material-dark"
          *ngFor="let shareType of shareModel.shareTypes"
          [button]="shareType"
          [showText]="shareModel.showText"
          [url]="shareModel.url"
          [image]="shareModel.image"
        ></share-button>
      </div>
    </div>
  </div>
</div>
