import { PagePropertiesUtility } from '@atv-core/utility/page-properties-utility';
import { CardConfig } from '@atv-shared/generic-card/generic-card.model';
import { CardOrientation, CardSize, CmsContentTypes, Properties, Ribbon2Asset, Ribbon2Channel, Ribbon2Detail } from '@atv-core/api/cms';
import { ImageRecipe } from '@atv-core/utility/image/image-recipe';
import { environment } from '@env/environment';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { ContentBackdropService } from '@atv-shared/content-backdrop/content-backdrop.service';
import { Injectable } from '@angular/core';
import { ChannelModel } from '@atv-core/services/cache/channel';
import { ScheduleChannel } from '@atv-shared/generic-card/generic-card.component';
import { EpgScheduleDetailAsset, EpgScheduleSummaryAsset } from '@atv-core/api/epg';
import { StreamType } from '@atv-core/utility/constants/shared';
import { EpgUtilityService } from '@atv-core/utility/epg-utility/epg-utility.service';
import { CardModelFactory } from '@atv-shared/card-model/card-model-factory';
import { EpgCardModelFactory } from '@atv-shared/card-model/factories/epg-card-model-factory';
import { ConfigService, RibbonTranslationKeys } from '@atv-bootstrap/services/config';
import { SessionService } from '@atv-core/services/session';
import { VodCardModelFactory } from '@atv-shared/card-model/factories/vod-card-model-factory';
import { GenericCardModelFactory } from '@atv-shared/card-model/factories/generic-card-model-factory';
import { RecordingCardModelFactory } from '@atv-shared/card-model/factories/recording-card-model-factory';

@Injectable({
  providedIn: 'root',
})
export class CardModelFactoryService {

  constructor(
    private adultService: AdultService,
    private backdropService: ContentBackdropService,
    private epgUtility: EpgUtilityService,
    private config: ConfigService,
    private sessionService: SessionService,
  ) {
  }

  public createCardModelFactory(contentType: CmsContentTypes, cardConfig: CardConfig): CardModelFactory {
    switch (contentType) {
      case CmsContentTypes.CHANNEL:
      case CmsContentTypes.PROGRAM:
      case CmsContentTypes.SERIES:
      case CmsContentTypes.SCHEDULE:
        return new EpgCardModelFactory(this, this.config, this.sessionService, this.adultService, cardConfig);
      case CmsContentTypes.BUNDLE:
      case CmsContentTypes.VOD:
        return new VodCardModelFactory(this, this.config, cardConfig);
      case CmsContentTypes.ARTICLE:
      case CmsContentTypes.PAGE:
        return new GenericCardModelFactory(this, cardConfig);
      case CmsContentTypes.RECORDING:
        return new RecordingCardModelFactory(this, this.config, this.sessionService, cardConfig);
    }
  }

  public getScheduledInfo(item: Ribbon2Asset, cardConfig: CardConfig, channels?: ChannelModel[]): ScheduleChannel {
    let scheduleChannel: ScheduleChannel;
    if (item.channels && (this.showScheduleInfo(cardConfig) || this.useBestSchedule(cardConfig))) {
      const schedules = this.getValidSchedules(item.channels, channels, cardConfig);
      scheduleChannel = this.findScheduleAndChannel(schedules, channels, cardConfig);
      if (this.useBestSchedule(cardConfig) || item.asset.type === CmsContentTypes.SCHEDULE) {
        scheduleChannel.program = this.findProgramDetail(item, scheduleChannel.schedule);
      }
      return scheduleChannel;
    }
    return undefined;
  }

  public showInfo(cardConfig: CardConfig): boolean {
    return cardConfig.showInfo ?
      !PagePropertiesUtility.containsProperty(Properties.HIDE_INFO, cardConfig.ribbonProperties, cardConfig.pageProperties) :
      cardConfig.showInfo;
  }

  public showScheduleInfo(cardConfig: CardConfig): boolean {
    return PagePropertiesUtility.containsProperty(Properties.SHOW_SCHEDULE_INFO,
      cardConfig.ribbonProperties, cardConfig.pageProperties);
  }

  public useBestSchedule(cardConfig: CardConfig): boolean {
    return PagePropertiesUtility.containsProperty(Properties.USE_BEST_SCHEDULE,
      cardConfig.ribbonProperties, cardConfig.pageProperties);
  }

  public showEpisodeInfo(cardConfig: CardConfig): boolean {
    return PagePropertiesUtility.containsProperty(Properties.SHOW_EPISODE_INFO,
      cardConfig.ribbonProperties, cardConfig.pageProperties);
  }

  public isReplay(cardConfig: CardConfig): boolean {
    return PagePropertiesUtility.isReplay(cardConfig.ribbonProperties, cardConfig.pageProperties);
  }

  public getImageUrl(asset: Ribbon2Detail, recipe: ImageRecipe, cardOrientation: CardOrientation, allowedAdultMode: AdultMode): string {
    // TODO check usages and simplify (why not always prefixed?)
    if (!asset.image) {
      return this.getDefaultImage(cardOrientation);
    }

    const url = `${asset.image.startsWith(environment.imagePrefixUrl) ? '' : environment.imagePrefixUrl}${asset.image}`;
    return SharedUtilityService.getPosterImage(
      url,
      recipe,
      asset.adult && !this.adultService.showAdult(allowedAdultMode),
    );
  }

  public getImageRecipe(contentType: CmsContentTypes, cardOrientation: CardOrientation, cardSize: CardSize): ImageRecipe {
    if (contentType === CmsContentTypes.CHANNEL) {
      return ImageRecipe.CHANNEL_1;
    }

    if (cardOrientation === CardOrientation.LANDSCAPE) {
      switch (cardSize) {
        case CardSize.SMALL:
          return ImageRecipe.CARD_LANDSCAPE_SMALL_2;
        case CardSize.MEDIUM:
          return ImageRecipe.CARD_LANDSCAPE_MEDIUM_2;
        case CardSize.NORMAL:
          return ImageRecipe.CARD_LANDSCAPE_NORMAL_2;
        case CardSize.LARGE:
          return ImageRecipe.CARD_LANDSCAPE_LARGE_2;
        case CardSize.EXTRA_LARGE:
          return ImageRecipe.CARD_LANDSCAPE_EXTRA_LARGE_2;
      }
    } else {
      switch (cardSize) {
        case CardSize.SMALL:
          return ImageRecipe.CARD_PORTRAIT_SMALL_2;
        case CardSize.MEDIUM:
          return ImageRecipe.CARD_PORTRAIT_MEDIUM_2;
        case CardSize.NORMAL:
          return ImageRecipe.CARD_PORTRAIT_NORMAL_2;
        case CardSize.LARGE:
          return ImageRecipe.CARD_PORTRAIT_LARGE_2;
        case CardSize.EXTRA_LARGE:
          return ImageRecipe.CARD_PORTRAIT_EXTRA_LARGE_2;
      }
    }
  }

  public setBackdropImage(asset: Ribbon2Detail, allowedAdultMode: AdultMode): void {
    if (asset.adult) {
      return;
    }
    this.backdropService.addBackdropImage(
      this.getImageUrl(asset, ImageRecipe.DETAIL_BACKDROP_1, CardOrientation.LANDSCAPE, allowedAdultMode));
  }

  private getDefaultImage(cardOrientation: CardOrientation): string {
    return cardOrientation === CardOrientation.LANDSCAPE
      ? './assets/theme/images/default_landscape_poster.png'
      : './assets/theme/images/default_portrait_poster.png';
  }

  private getValidSchedules(ribbonChannels: Ribbon2Channel[], channels: ChannelModel[], cardConfig: CardConfig): EpgScheduleDetailAsset[] {
    const schedules: EpgScheduleDetailAsset[] = [];
    const fallbackSchedules: EpgScheduleDetailAsset[] = [];

    ribbonChannels.forEach((ribbonChannel) => {
      const currentChannel = channels.find((ch) => ch.id === ribbonChannel.id);

      if (
        (currentChannel && this.isReplay(cardConfig)
         && currentChannel.customerHasRight(StreamType.REPLAY)) || currentChannel.customerHasRight(StreamType.LINEAR)
      ) {
        ribbonChannel.schedules.forEach((schedule) => {
          schedules.push({
            id: schedule.id,
            program: schedule.programId,
            published: schedule.published,
            blackouts: schedule.blackouts,
            channel: currentChannel.id,
          });
        });
      } else {
        ribbonChannel.schedules.forEach((schedule) => {
          fallbackSchedules.push({
            id: schedule.id,
            program: schedule.programId,
            published: schedule.published,
            blackouts: schedule.blackouts,
            channel: currentChannel ? currentChannel.id : undefined,
          });
        });
      }
    });

    if (schedules.length === 0) {
      return fallbackSchedules;
    } else {
      return schedules;
    }
  }

  private findScheduleAndChannel(
    schedules: EpgScheduleDetailAsset[], channels: ChannelModel[], cardConfig: CardConfig,
  ): ScheduleChannel {
    const schedule = this.epgUtility.findClosestSchedule(schedules,
      this.isReplay(cardConfig), channels);

    let channel;
    if (schedule) {
      channel = channels.find((ch) => ch.id === schedule.channel);
    }

    return { schedule, channel };
  }

  private findProgramDetail(epg: Ribbon2Asset, schedule: EpgScheduleSummaryAsset): Ribbon2Detail {
    if (epg.asset.type === CmsContentTypes.PROGRAM) {
      return epg.asset;
    } else {
      return epg.resources.find((r) => r.type === CmsContentTypes.PROGRAM && schedule && r.id === schedule.program);
    }
  }

  public getEpgSeasonEpisodeString(program: Ribbon2Detail): string {
    const seasonText = program.season !== undefined
      ? this.config.getTranslation(RibbonTranslationKeys.ribbon_season).replace('$1', program.season.toString())
      : undefined;
    const episodeText = program.episode
      ? this.config.getTranslation(RibbonTranslationKeys.ribbon_episode).replace('$1', program.episode.toString())
      : undefined;
    if (seasonText && episodeText) {
      return seasonText + ' ' + episodeText;
    } else {
      if (seasonText) {
        return seasonText;
      } else if (episodeText) {
        return episodeText;
      } else if (program && program.episodeTitle) {
        return program.episodeTitle;
      }
    }
    return undefined;
  }
}
