import { PlatformType, StreamType } from '@atv-core/utility/constants/shared';

export class BlackoutFactory {
  private static cache = new Map<PlatformType | 'AIRPLAY', Map<StreamType, string>>();

  public static create(platform: PlatformType, streamType: StreamType): string {
    let platformCache = this.cache.get(platform);
    if (!platformCache) {
      platformCache = new Map<StreamType, string>();
      this.cache.set(platform, platformCache);
    }

    let result = platformCache.get(streamType);
    if (!result) {
      result = `${platform}.${streamType}`;
      platformCache.set(streamType, result);
    }

    return result;
  }
}
