<div class="popup-wrapper" *ngIf="showPopup">
  <div class="popup">
    <div class="popup-header">
      <div class="popup-header-title">{{ popupTitle }}</div>
      <div class="popup-close" (click)="closePopup()"></div>
    </div>
    <div class="popup-content">
      <div class="catalog-popup-content">
        <select class="catalog-selections" [(ngModel)]="catalogId" (change)="switchCatalog()">
          <option
            class="catalog-selection"
            *ngFor="let catalog of availableCatalogs"
            [ngValue]="catalog.id"
          >
            {{ catalog.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
