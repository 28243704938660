import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { MessageModel, MessageTypes } from '@atv-core/services/messages';
import { MessagesService } from '@atv-core/services/messages/messages.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styles: [],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }), // initial
        animate('0.3s ease', style({ transform: 'translateX(0%)', opacity: 1 })), // final
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }), // initial
        animate('0.3s ease', style({ transform: 'translateX(100%)', opacity: 0 })), // final
        // style({
        //   transform: 'translateY(0%)',
        //   opacity: 1,
        //   'padding-top': '0px',
        //   'padding-bottom': '0px'
        // }), // initial
        // animate(
        //   '0.3s ease',
        //   style({ transform: 'translateY(-100%)', opacity: 0, height: '0px', margin: '0px' })
        // ) // final
      ]),
    ]),
  ],
})
export class MessagesComponent implements OnInit {
  public MessageTypes = MessageTypes;
  public messages: MessageModel[] = [];

  private annimationFrame;

  public isSmartTv = SharedUtilityService.isSmartTv();

  constructor(private messagesService: MessagesService, private cdr: ChangeDetectorRef) {
    this.messagesService.newMessageEvent.subscribe((message: MessageModel) => {
      message.dissipateTime = performance.now() + 1000 * 10; // dissipate in 10 seconds
      this.messages.push(message);
      this.detectChanges();

      if (!this.annimationFrame) {
        this.annimationFrame = requestAnimationFrame(() => this.checkDissipation());
      }
    });
  }

  private detectChanges() {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  private checkDissipation() {
    const now = performance.now();

    let index = this.messages.findIndex(
      (message) => message.dissipate && message.dissipateTime < now
    );

    if (index >= 0) {
      this.removeMessage(index);
    }

    index = this.messages.findIndex((message) => message.dissipate);

    if (this.messages.length > 0 && index >= 0) {
      this.annimationFrame = requestAnimationFrame(() => this.checkDissipation());
    } else {
      cancelAnimationFrame(this.annimationFrame);
      this.annimationFrame = undefined;
    }
  }

  public removeMessage(index) {
    this.messages.splice(index, 1);
    this.detectChanges();
  }

  ngOnInit(): void {}
}
