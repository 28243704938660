import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { versionInfo } from 'src/app/version-info';

import { CustomDiscovery, DiscoveryResponse } from './index';
import { LocalStorageKeys } from '@atv-core/utility/constants/localStorageKeys';

@Injectable({
  providedIn: 'root',
})
export class DiscoveryService {
  public location = '';
  public environment = '';

  // create custom http client to avoid usage of interceptors
  // if interceptors are used before the config is loaded, services might be initialized before the config is loaded
  private http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  discover(): Observable<DiscoveryResponse> {
    localStorage.setItem(LocalStorageKeys.version_check_time, new Date().getTime().toString());
    this.http
      .get('./assets/version-check.json', { headers: { 'Cache-Control': 'no-cache' } })
      .subscribe((result: any) => {
        const previousVersion = localStorage.getItem(LocalStorageKeys.version_current);

        if (previousVersion && result.version && result.version !== previousVersion) {
          localStorage.setItem(LocalStorageKeys.version_current, result.version);
          window.location.reload();
        }
        if (!previousVersion) {
          localStorage.setItem(
            LocalStorageKeys.version_check_time,
            new Date().getTime().toString(),
          );
          localStorage.setItem(LocalStorageKeys.version_current, result.version);
        }
      });

    const discoveryUrlObservable = environment.discoveryUrl ? of(environment.discoveryUrl) :
      this.http.get<CustomDiscovery>('discovery.json').pipe(map(d => d.discoveryUrl));

    return discoveryUrlObservable.pipe(switchMap(url => this.http.get<DiscoveryResponse>(url, {
      params: { platform: environment.platform, appVersion: versionInfo.version },
    }))).pipe(
      tap((response) => {
        this.location = response.location;
        this.environment = response.enviroment;
      }),
    );
  }
}
