import { ChannelCatalog, EpgChannel, EpgChannelRight, EpgChannelRights } from '@atv-core/api/epg';
import { Platform, StreamResolutionType } from '@atv-core/utility/constants/shared';
import { environment } from '@env/environment';

export class ChannelModel implements EpgChannel {
  id: string;
  enabled?: boolean;
  dvbId?: string;
  name: string;
  shortDescription?: string;
  longDescription?: string;
  defaultLogo?: string;
  squareLogo?: string;
  audioOnly?: boolean;
  resolution: StreamResolutionType;
  minimumAge?: number;
  adult?: boolean;
  replayHours?: number;
  npvrRecordHours?: number;
  rights: EpgChannelRights[];
  catalogs?: ChannelCatalog[];

  // added functionality
  private channelOttRights: string[];
  private readonly stbRights: Set<string>;
  customerOttChannelRights: string[];
  private favorite = false;
  recordableIcon = '';

  constructor(channel: EpgChannel) {
    Object.keys(channel).map((key) => (this[key] = channel[key]));

    const ottRights = this.rights.find(
      (right) => right.deviceType === environment.platform,
    );
    this.channelOttRights = ottRights && ottRights.rights ? ottRights.rights : [];

    // set STB rights
    this.stbRights = new Set<string>();
    this.rights.filter(r => Platform.isStb(r.deviceType)).forEach(r => r.rights.forEach(s => this.stbRights.add(s)));

    this.customerOttChannelRights = [];
    this.replayHours = this.replayHours ?? 0;
  }

  public getChannelNumberForCatalog(catalogId: string): number {
    const catalog = this.catalogs.find((cat) => cat.id === catalogId);
    return catalog ? catalog.channelNumber : undefined;
  }

  public setFavorite(value: boolean): void {
    this.favorite = value;
  }

  public isFavorite(): boolean {
    return this.favorite;
  }

  public customerHasRight(right: string): boolean {
    return this.customerOttChannelRights.includes(right);
  }

  public hasOneOfOttRights(rights: string[]): boolean {
    return rights.some((right) => this.channelOttRights.includes(right));
  }

  public hasOneOfStbRights(rights: string[]): boolean {
    return rights.some((right) => this.stbRights.has(right));
  }

  public channelHasRightsForStreamTypes(rights: EpgChannelRight[], streamTypes: string[]): boolean {
    if (!rights || rights.length === 0) {
      return false;
    }
    // channel has one of streamType rights
    return rights.some((right) => {
      if (right.id === this.id) {
        return right.rights.some((r) => streamTypes.includes(r));
      }
    });
  }
}
