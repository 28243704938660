import { BannerAspectRatios, CustomCmsBanner } from '@atv-core/api/cms';

interface BannerStyle {
  aspectRatio: number;
  fontSize: string;
}

export class BannerModel {
  currentBannerIndex = 0;
  bannerLength = 0;

  imageStyle1 = { content: '', opacity: 0 };
  imageStyle2 = { content: '', opacity: 0 };

  srcSet1 = '';
  srcSet2 = '';

  lastWasStyle1 = false;
  bannersPlayed: number[] = [];
  visible = false;

  isNextBanner = true;
  bannerStyle = { aspectRatio: 7 / 2, fontSize: '10px' };

  textStyle1 = { opacity: 0 };
  textLines1 = { line1: '', line2: '', line3: '' };
  textStyle2 = { opacity: 0 };
  textLines2 = { line1: '', line2: '', line3: ''};
  maxTextLengths = {
    line1: 40,
    line2: 38,
    line3: 80
  };

  constructor(public customCmsBanners: CustomCmsBanner[]) {
    if (this.customCmsBanners.length > 0) {
      this.bannerStyle = this.getBannerAspectStyle(this.customCmsBanners[0].size);
    }
  }

  public prepareVisualModel(): void {
    this.imageStyle2['padding-top'] = `${this.getRatio(
      this.customCmsBanners[this.currentBannerIndex].size,
    )}%`;
    this.imageStyle1['padding-top'] = `${this.getRatio(
      this.customCmsBanners[this.currentBannerIndex].size,
    )}%`;
  }

  public showNextBanner(imageSet: string): void {
    this.bannerLength = this.customCmsBanners.length;

    if (this.lastWasStyle1 && imageSet && this.customCmsBanners.length > 1) {
      this.showBanner2(imageSet);
    } else if (imageSet) {
      this.showBanner1(imageSet);
    }
  }

  public getCurrentBanner(): CustomCmsBanner {
    if (!this.customCmsBanners || !this.customCmsBanners[this.currentBannerIndex]) {
      return undefined;
    } else {
      return this.customCmsBanners[this.currentBannerIndex];
    }
  }

  public showCurrentBannerImage(): void {
    if (this.lastWasStyle1) {
      this.imageStyle1.opacity = 1;
      this.textStyle1.opacity = 1;
    } else {
      this.imageStyle2.opacity = 1;
      this.textStyle2.opacity = 1;
    }
  }

  private showBanner1(imageSet: string): void {
    this.srcSet1 = imageSet;
    const currentBanner = this.getCurrentBanner();
    if (currentBanner.overlay) {
      this.textLines1.line1 = currentBanner.overlay.line1?.length > this.maxTextLengths.line1 ?
        currentBanner.overlay.line1.slice(0, this.maxTextLengths.line1).concat('...') :
        currentBanner.overlay.line1;
      this.textLines1.line2 = currentBanner.overlay.line2?.length > this.maxTextLengths.line2 ?
        currentBanner.overlay.line2.slice(0, this.maxTextLengths.line2).concat('...') :
        currentBanner.overlay.line2;
      this.textLines1.line3 = currentBanner.overlay.line3?.length > this.maxTextLengths.line3 ?
        currentBanner.overlay.line3.slice(0, this.maxTextLengths.line3).concat('...') :
        currentBanner.overlay.line3;
    }

    this.imageStyle1.opacity = 1;
    this.imageStyle2.opacity = 0;
    this.textStyle1.opacity = 1;
    this.textStyle2.opacity = 0;

    this.lastWasStyle1 = true;
  }

  private showBanner2(imageSet: string): void {
    this.srcSet2 = imageSet;
    const currentBanner = this.getCurrentBanner();
    if (currentBanner.overlay) {
      this.textLines2.line1 = currentBanner.overlay.line1?.length > this.maxTextLengths.line1 ?
        currentBanner.overlay.line1.slice(0, this.maxTextLengths.line1).concat('...') :
        currentBanner.overlay.line1;
      this.textLines2.line2 = currentBanner.overlay.line2?.length > this.maxTextLengths.line2 ?
        currentBanner.overlay.line2.slice(0, this.maxTextLengths.line2).concat('...') :
        currentBanner.overlay.line2;
      this.textLines2.line3 = currentBanner.overlay.line3?.length > this.maxTextLengths.line3 ?
        currentBanner.overlay.line3.slice(0, this.maxTextLengths.line3).concat('...') :
        currentBanner.overlay.line3;
    }

    this.imageStyle2.opacity = 1;
    this.imageStyle1.opacity = 0;
    this.textStyle2.opacity = 1;
    this.textStyle1.opacity = 0;

    this.lastWasStyle1 = false;
  }

  private getRatio(size: BannerAspectRatios): number {
    switch (size) {
      case BannerAspectRatios.ASPECT_RATIO_7_1:
        return (1 / 7) * 100;
      case BannerAspectRatios.ASPECT_RATIO_7_2:
        return (2 / 7) * 100;
      case BannerAspectRatios.ASPECT_RATIO_7_3:
        return (3 / 7) * 100;
    }
  }

  private getBannerAspectStyle(size: BannerAspectRatios): BannerStyle {
    switch (size) {
      case BannerAspectRatios.ASPECT_RATIO_7_1:
        return {
          aspectRatio: (7 / 1),
          fontSize: '10px'
        };
      case BannerAspectRatios.ASPECT_RATIO_7_2:
        return {
          aspectRatio: (7 / 2),
          fontSize: '16px'
        };
      case BannerAspectRatios.ASPECT_RATIO_7_3:
        return {
          aspectRatio: (7 / 3),
          fontSize: '16px'
        };
    }
  }
}
