import { Stb } from './stb';

export class StbList {
  private readonly stbs: Stb[];
  private readonly npvrStb?: Stb;

  constructor(stbs: Stb[] = [], npvr?: Stb) {
    this.stbs = stbs;

    this.npvrStb = npvr;
  }

  public getStbs(includeNpvr = true): Stb[] {
    if (includeNpvr && this.npvrStb) {
      return [...this.stbs, this.npvrStb];
    }

    return this.stbs;
  }

  public getRecordingStbs(includeNpvr = true): Stb[] {
    const stbs = this.stbs.filter(stb => stb.recording === 'LOCAL');

    if (includeNpvr && this.npvrStb) {
      stbs.push(this.npvrStb);
    }

    // TODO use localeCompare?
    return stbs.sort((stb1, stb2) => {
      if (stb1.alias < stb2.alias) {
        return -1;
      } else if (stb1.alias > stb2.alias) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  public getNpvrStb(): Stb {
    return this.npvrStb;
  }

  public hasNpvrBox(): boolean {

    return !!this.npvrStb;
  }

  public getStb(id: string, includeNpvr: boolean): Stb {
    return this.getRecordingStbs(includeNpvr).find(stb => stb.id === id);
  }

  public getDefaultStb(defaultStbId?: string): Stb {
    const stb = defaultStbId ? this.getStb(defaultStbId, true) : this.getNpvrStb();
    return stb ?? this.getRecordingStbs(true)[0];
  }
}
