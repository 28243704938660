import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdultMode } from '@atv-core/services/adult';
import { ExtraData } from '@atv-core/utility/constants/shared';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { DiscoveryService } from '../../../bootstrap/services/discovery/discovery.service';
import { SearchResponse, SearchTypes } from './search-api.model';

@Injectable({
  providedIn: 'root',
})
export class SearchApiService {
  constructor(
    private disc: DiscoveryService,
    private http: HttpClient
  ) {}

  public search(
    searchTerm: string,
    types: Set<SearchTypes>,
    adultMode: AdultMode,
    catalogId: string,
    completions: boolean,
    page = 0
  ): Observable<SearchResponse> {
    return this.http.get<SearchResponse>(`${this.disc.location}/search/v3/search`, {
      params: this.getSearchParams(searchTerm, types, adultMode, catalogId, completions, page),
    });
  }

  private getSearchParams(
    searchTerm: string,
    types: Set<SearchTypes>,
    adultMode: AdultMode,
    catalogId: string,
    completions: boolean,
    page: number,
  ) {
    let params = new HttpParams()
      .append('platform', environment.platform)
      .append('term', searchTerm)
      .append('catalogId', catalogId)
      .append('completions', completions ? 'true' : 'false')
      .append('extraData', SharedUtilityService.isSmartTv() ? ExtraData.MINI : ExtraData.NONE)
      .append('page', page);

    params = params.append('adult', adultMode);

    types.forEach((type) => {
      params = params.append('type', type);
    });

    return params;
  }
}
