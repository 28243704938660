<div class="button-wrapper" #buttonWrapperRef>
  <div class="button-title" (click)="showOptions()">
    {{ buttonVisual.buttonText }}
  </div>
  <div class="button-config" [class.show-button-config]="buttonVisual.showConfig" #buttonConfigRef>
    <div class="button-config-title" (click)="hideOptions()">
      {{ buttonVisual.buttonText }}
    </div>
    <div class="button-config-content">{{ buttonVisual.confirmationText }}</div>
    <div class="button-config-control">
      <div class="config-control-button" (click)="cancel()">
        {{ buttonVisual.cancelText }}
      </div>
      <div class="config-control-button" (click)="confirm()">{{ buttonVisual.confirmText }}</div>
    </div>
  </div>
</div>
