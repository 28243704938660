import { Location } from '@angular/common';
import { AfterViewInit, Component, ComponentFactory, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsApiService, PageTypes } from '@atv-core/api/cms';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { SessionService } from '@atv-core/services/session';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { CmsPageComponent } from '@atv-pages/cms-page/cms-page.component';
import { SearchPageComponent } from '@atv-pages/search-page/search-page.component';

import { JumpablePage } from '../jumpable-page';

@Component({
  selector: 'app-custom-page',
  templateUrl: './custom-page.component.html',
  styleUrls: ['./custom-page.component.scss'],
})
export class CustomPageComponent implements AfterViewInit {
  @ViewChild('template', { read: ViewContainerRef }) vcRef: ViewContainerRef;
  public isSmartTv = SharedUtilityService.isSmartTv();

  constructor(
    private cmsApi: CmsApiService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private adult: AdultService,
    private location: Location
  ) {}

  ngAfterViewInit(): void {
    // TODO page info should be given in action
    this.route.params.subscribe((params) => {
      const pageId: string = params.pageId;
      this.cmsApi
        .getDynamicPage(
          pageId,
          this.sessionService.getEntitlementId(),
          this.adult.getAdultMode(AdultMode.any)
        )
        .subscribe((page) => {
          let componentFactory: ComponentFactory<CmsPageComponent | SearchPageComponent>;
          if (page.type === PageTypes.CMS_PAGE) {
            componentFactory = this.componentFactoryResolver.resolveComponentFactory(
              CmsPageComponent
            );
          } else if (page.type === PageTypes.SEARCH_PAGE) {
            componentFactory = this.componentFactoryResolver.resolveComponentFactory(
              SearchPageComponent
            );
          }
          this.vcRef.clear();
          const componentRef = this.vcRef.createComponent<JumpablePage>(componentFactory);
          componentRef.instance.setPage(page, pageId);
        });
    });
  }

  public goBack(): void {
    this.location.back();
  }
}
