import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiApiService } from '@atv-core/api/api/api-api.service';
import { ConfigService, SettingsKeys } from '@atv-bootstrap/services/config';
import { SessionService } from '@atv-core/services/session/session.service';
import { LocalStorageKeys } from '@atv-core/utility/constants/localStorageKeys';
import { PlayOriginator, PlayState } from '@atv-core/utility/constants/shared';
import { ClientDetails } from '@atv-core/utility/static-services/client-detail.static-service';
import { environment } from '@env/environment';
import moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { versionInfo } from 'src/app/version-info';

import { CmsAction, CmsContentTypes } from './../../api/cms/cms-api.model';
import {
  AdvertisementCategorie,
  AdvertisementSubcategories,
  ArticleCategorie,
  ArticleSubcategories,
  AuthenticateCategorie,
  AuthenticateSubcategories,
  LogCategorie,
  LogSubcategories,
  PlayerCategorie,
  PlayerSubcategories,
  RecordingCategorie,
  RecordingSubcategories,
  StartupCategorie,
  StartupSubcategories,
  SwipeCategorie,
  SwipeSubcategories,
  TrackCategorie,
  TrackSubcategories,
} from './analytics-categories.model';
import {
  ApplicationInfo,
  ArticleLogInfo,
  Category,
  ClientEventEntry,
  ClientEvents,
  DeviceInfo,
  LogErrorInfo,
  PageContext,
  PlayerLogInfo,
  Property,
  RecordingLogInfo,
  SwipeLogInfo,
} from './log.model';
import { AdvertisementElement } from '../../../atv-player/advertisement/advertisement-element.model';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private analyticsFlushPeriod: number;
  private flushPeriodTimeout;
  private analyticsPostBatchSize: number;
  private maxNrOfFailedAnalytics: number;

  private deviceInfo: DeviceInfo;
  private applicationInfo: ApplicationInfo;

  private lastPageContext: PageContext;

  private playerStartTime: Date;

  constructor(
    private config: ConfigService,
    private sessionService: SessionService,
    private apiApi: ApiApiService
  ) {
    this.analyticsFlushPeriod = this.config.getSettingNumber(
      SettingsKeys.analyticsFlushPeriod,
      300000
    );
    this.analyticsPostBatchSize = this.config.getSettingNumber(
      SettingsKeys.analyticsPostBatchSize,
      50
    );
    this.maxNrOfFailedAnalytics = this.config.getSettingNumber(
      SettingsKeys.maxNrOfFailedAnalytics,
      10
    );
    this.startFlushPeriodTimeout();

    const clientDetails = ClientDetails.getDetails();
    this.deviceInfo = {
      deviceId: this.sessionService.getDeviceId(),
      model: `${clientDetails.browser} ${clientDetails.browserMajorVersion} (${clientDetails.browserVersion})`,
      os: clientDetails.os + clientDetails.osVersion,
      platform: environment.platform,
    };

    this.applicationInfo = {
      name: 'Web',
      version: versionInfo.version,
      build: versionInfo.buildTime,
    };
  }

  private startFlushPeriodTimeout() {
    this.flushPeriodTimeout = setTimeout(() => {
      this.sendBufferToServer();
    }, this.analyticsFlushPeriod);
  }

  private addToClientEventBuffer(
    categorie: Category,
    pageContext: PageContext,
    properties: Property[]
  ) {
    if (!this.sessionService.analyticsCategoryEnabled(categorie)) {
      return;
    }

    if (pageContext !== undefined) {
      this.lastPageContext = pageContext;
    } else {
      pageContext = this.lastPageContext;
    }

    properties = properties.filter((property) => {
      return property.value !== undefined && property.value !== '';
    });

    const clientEventsBuffer: ClientEventEntry[] = this.parseJSONString(
      localStorage.getItem(LocalStorageKeys.client_events_buffer)
    );

    const clientEvent = new ClientEventEntry(
      categorie,
      this.sessionService.getLoginContext(),
      pageContext,
      properties
    );

    clientEventsBuffer.push(clientEvent);
    localStorage.setItem(LocalStorageKeys.client_events_buffer, JSON.stringify(clientEventsBuffer));

    if (
      clientEventsBuffer.length >= this.analyticsPostBatchSize &&
      this.analyticsPostBatchSize !== undefined
    ) {
      this.sendBufferToServer();
    }
  }

  private parseJSONString(obj) {
    try {
      return JSON.parse(obj) || [];
    } catch (error) {
      return [];
    }
  }

  private sendBufferToServer() {
    if (this.flushPeriodTimeout) {
      clearTimeout(this.flushPeriodTimeout);
      this.flushPeriodTimeout = undefined;
    }

    const clientEventsBuffer: ClientEventEntry[] = this.parseJSONString(
      localStorage.getItem(LocalStorageKeys.client_events_buffer)
    );
    localStorage.setItem(LocalStorageKeys.client_events_buffer, JSON.stringify([]));

    this.processFailedLogging().subscribe((failedLogging) => {
      if (clientEventsBuffer.length !== 0) {
        const data: ClientEvents = {
          device: this.deviceInfo,
          app: this.applicationInfo,
          events: clientEventsBuffer,
        };

        this.apiApi.log(data).subscribe(
          () => {
            localStorage.setItem(LocalStorageKeys.client_events_failed_buffer, JSON.stringify([]));
          },
          (errorResponse: HttpErrorResponse) => {
            const properties: Property[] = [];
            if (errorResponse && errorResponse.error) {
              const logErrorInfo = new LogErrorInfo(errorResponse);
              properties.push(new Property('errorId', logErrorInfo.errorId));
              properties.push(new Property('errorCode', logErrorInfo.code));
              properties.push(new Property('errorMessage', logErrorInfo.message));
            }

            const clientEventEntry = new ClientEventEntry(
              new LogCategorie(LogSubcategories.LOG_LOGGING_ERROR),
              this.sessionService.getLoginContext(),
              undefined,
              properties
            );
            // add to current failed buffer
            clientEventsBuffer.push(clientEventEntry);
            failedLogging.push(clientEventsBuffer);
            if (failedLogging.length > this.maxNrOfFailedAnalytics) {
              failedLogging = failedLogging.slice(1);
            }
            localStorage.setItem(
              LocalStorageKeys.client_events_failed_buffer,
              JSON.stringify(failedLogging)
            );
          }
        );
      } else {
        localStorage.setItem(
          LocalStorageKeys.client_events_failed_buffer,
          JSON.stringify(failedLogging)
        );
      }

      this.startFlushPeriodTimeout();
    });
  }

  private sendBuffer(clientEvents: ClientEventEntry[]): Observable<ClientEventEntry[]> {
    const data: ClientEvents = {
      device: this.deviceInfo,
      app: this.applicationInfo,
      events: clientEvents,
    };
    return this.apiApi.log(data).pipe(
      map(() => {
        return [];
      }),
      catchError(() => {
        return of(clientEvents);
      })
    );
  }

  private processFailedLogging(): Observable<ClientEventEntry[][]> {
    let clientEventsFailedBuffer: ClientEventEntry[][] = this.parseJSONString(
      localStorage.getItem(LocalStorageKeys.client_events_failed_buffer)
    );

    const requests = [];
    clientEventsFailedBuffer.forEach((failedBatch) => {
      requests.push(this.sendBuffer(failedBatch));
    });

    if (requests.length === 0) {
      return of([]);
    }

    clientEventsFailedBuffer = [];

    return forkJoin(requests).pipe(
      map((failedBatches: ClientEventEntry[][]) => {
        failedBatches.forEach((failedBatch) => {
          if (failedBatch && failedBatch.length > 0) {
            clientEventsFailedBuffer.push(failedBatch);
          }
        });

        return clientEventsFailedBuffer;
      }),
      catchError(() => {
        return of([]);
      })
    );
  }

  public appOpen(pageContext: PageContext) {
    this.addToClientEventBuffer(
      new StartupCategorie(StartupSubcategories.APP_OPEN),
      pageContext,
      []
    );
  }

  public playStart(playerLogInfo: PlayerLogInfo) {
    this.playerStartTime = new Date();
    const properties = [];
    properties.push(new Property('id', playerLogInfo.id));
    properties.push(new Property('type', playerLogInfo.type));
    properties.push(new Property('name', playerLogInfo.name));
    properties.push(
      new Property(
        'season',
        playerLogInfo.seasonNumber ? playerLogInfo.seasonNumber.toFixed(0) : ''
      )
    );
    properties.push(
      new Property(
        'episode',
        playerLogInfo.episodeNumber ? playerLogInfo.episodeNumber.toFixed(0) : ''
      )
    );
    properties.push(new Property('scheduleId', playerLogInfo.scheduleId));
    properties.push(new Property('programId', playerLogInfo.programId));
    properties.push(new Property('streamId', playerLogInfo.streamId));
    properties.push(new Property('channelId', playerLogInfo.channelId));
    properties.push(new Property('channelName', playerLogInfo.channelName));
    this.addToClientEventBuffer(
      new PlayerCategorie(PlayerSubcategories.PLAY_START),
      undefined,
      properties
    );
  }

  public playPlaying(playerLogInfo: PlayerLogInfo) {
    const properties = [];

    properties.push(new Property('id', playerLogInfo.id));
    properties.push(new Property('type', playerLogInfo.type));
    properties.push(new Property('scheduleId', playerLogInfo.scheduleId));
    properties.push(new Property('programId', playerLogInfo.programId));
    properties.push(new Property('channelId', playerLogInfo.channelId));
    properties.push(new Property('channelName', playerLogInfo.channelName));
    properties.push(new Property('streamId', playerLogInfo.streamId));
    properties.push(new Property('ssId', playerLogInfo.ssId));
    properties.push(new Property('manifest', playerLogInfo.manifest));

    this.addToClientEventBuffer(
      new PlayerCategorie(PlayerSubcategories.PLAYING),
      undefined,
      properties
    );
  }

  public playStop(playerLogInfo: PlayerLogInfo) {
    const properties = [];
    properties.push(new Property('id', playerLogInfo.id));
    properties.push(new Property('type', playerLogInfo.type));
    properties.push(new Property('name', playerLogInfo.name));
    properties.push(
      new Property(
        'season',
        playerLogInfo.seasonNumber ? playerLogInfo.seasonNumber.toFixed(0) : ''
      )
    );
    properties.push(
      new Property(
        'episode',
        playerLogInfo.episodeNumber ? playerLogInfo.episodeNumber.toFixed(0) : ''
      )
    );
    properties.push(
      new Property(
        'time',
        this.playerStartTime
          ? (new Date().getTime() - this.playerStartTime.getTime()).toFixed(0)
          : ''
      )
    );
    properties.push(new Property('scheduleId', playerLogInfo.scheduleId));
    properties.push(new Property('programId', playerLogInfo.programId));
    properties.push(new Property('channelId', playerLogInfo.channelId));
    properties.push(new Property('channelName', playerLogInfo.channelName));
    properties.push(new Property('streamId', playerLogInfo.streamId));
    properties.push(new Property('ssId', playerLogInfo.ssId));
    properties.push(new Property('manifest', playerLogInfo.manifest));

    this.addToClientEventBuffer(
      new PlayerCategorie(PlayerSubcategories.PLAY_STOP),
      undefined,
      properties
    );
  }

  public playerKeepAliveError(playerLogInfo: PlayerLogInfo, error: LogErrorInfo) {
    const properties = [];
    properties.push(new Property('id', playerLogInfo.id));
    properties.push(new Property('type', playerLogInfo.type));
    properties.push(
      new Property(
        'time',
        this.playerStartTime
          ? (new Date().getTime() - this.playerStartTime.getTime()).toFixed(0)
          : ''
      )
    );
    properties.push(new Property('scheduleId', playerLogInfo.scheduleId));
    properties.push(new Property('programId', playerLogInfo.programId));
    properties.push(new Property('channelId', playerLogInfo.channelId));
    properties.push(new Property('channelName', playerLogInfo.channelName));
    properties.push(new Property('errorId', error ? error.errorId : ''));
    properties.push(new Property('errorCode', error ? error.code : ''));
    properties.push(new Property('errorMessage', error ? error.message : ''));
    properties.push(new Property('streamId', playerLogInfo.streamId));
    properties.push(new Property('ssId', playerLogInfo.ssId));
    properties.push(new Property('manifest', playerLogInfo.manifest));

    this.addToClientEventBuffer(
      new PlayerCategorie(PlayerSubcategories.KEEP_ALIVE_ERROR),
      undefined,
      properties
    );
  }

  public playState(
    playerLogInfo: PlayerLogInfo,
    playState: PlayState,
    playOriginator: PlayOriginator
  ) {
    const properties = [];

    properties.push(new Property('id', playerLogInfo.id));
    properties.push(new Property('type', playerLogInfo.type));
    properties.push(new Property('name', playerLogInfo.name));
    properties.push(
      new Property(
        'season',
        playerLogInfo.seasonNumber ? playerLogInfo.seasonNumber.toFixed(0) : ''
      )
    );
    properties.push(
      new Property(
        'episode',
        playerLogInfo.episodeNumber ? playerLogInfo.episodeNumber.toFixed(0) : ''
      )
    );
    properties.push(new Property('scheduleId', playerLogInfo.scheduleId));
    properties.push(new Property('programId', playerLogInfo.programId));
    properties.push(new Property('channelId', playerLogInfo.channelId));
    properties.push(new Property('channelName', playerLogInfo.channelName));
    properties.push(new Property('state', playState));
    properties.push(new Property('originator', playOriginator));
    properties.push(new Property('streamId', playerLogInfo.streamId));
    properties.push(new Property('ssId', playerLogInfo.ssId));
    properties.push(new Property('manifest', playerLogInfo.manifest));

    this.addToClientEventBuffer(
      new PlayerCategorie(PlayerSubcategories.PLAY_STATE),
      undefined,
      properties
    );
  }

  public playError(
    playerLogInfo: PlayerLogInfo,
    playOriginator: PlayOriginator,
    error: LogErrorInfo
  ) {
    const properties = [];
    const stackTraceMaximumLines = this.config.getSettingNumber(
      SettingsKeys.stackTraceMaximumLines,
      25
    );
    let stackTrace;
    try {
      stackTrace = Error().stack.split('\n').slice(0, stackTraceMaximumLines).join('\n');
    } catch {}

    properties.push(new Property('id', playerLogInfo.id));
    properties.push(new Property('type', playerLogInfo.type));
    properties.push(new Property('name', playerLogInfo.name));
    properties.push(
      new Property(
        'season',
        playerLogInfo.seasonNumber ? playerLogInfo.seasonNumber.toFixed(0) : ''
      )
    );
    properties.push(
      new Property(
        'episode',
        playerLogInfo.episodeNumber ? playerLogInfo.episodeNumber.toFixed(0) : ''
      )
    );
    properties.push(new Property('scheduleId', playerLogInfo.scheduleId));
    properties.push(new Property('programId', playerLogInfo.programId));
    properties.push(new Property('channelId', playerLogInfo.channelId));
    properties.push(new Property('channelName', playerLogInfo.channelName));
    properties.push(new Property('originator', playOriginator));
    properties.push(new Property('errorCode', error ? error.code : ''));
    properties.push(new Property('errorMessage', error ? error.message : ''));
    properties.push(new Property('errorId', error ? error.errorId : ''));
    properties.push(new Property('stackTrace', stackTrace || ''));
    properties.push(new Property('streamId', playerLogInfo.streamId));
    properties.push(new Property('ssId', playerLogInfo.ssId));
    properties.push(new Property('manifest', playerLogInfo.manifest));

    this.addToClientEventBuffer(
      new PlayerCategorie(PlayerSubcategories.PLAY_ERROR),
      undefined,
      properties
    );
  }

  public playWarning(playerLogInfo: PlayerLogInfo, error: LogErrorInfo) {
    const properties = [];

    properties.push(new Property('id', playerLogInfo.id));
    properties.push(new Property('type', playerLogInfo.type));
    properties.push(new Property('name', playerLogInfo.name));
    properties.push(
      new Property(
        'season',
        playerLogInfo.seasonNumber ? playerLogInfo.seasonNumber.toFixed(0) : ''
      )
    );
    properties.push(
      new Property(
        'episode',
        playerLogInfo.episodeNumber ? playerLogInfo.episodeNumber.toFixed(0) : ''
      )
    );
    properties.push(new Property('scheduleId', playerLogInfo.scheduleId));
    properties.push(new Property('programId', playerLogInfo.programId));
    properties.push(new Property('channelId', playerLogInfo.channelId));
    properties.push(new Property('channelName', playerLogInfo.channelName));
    properties.push(new Property('errorCode', error ? error.code : ''));
    properties.push(new Property('errorMessage', error ? error.message : ''));
    properties.push(new Property('errorId', error ? error.errorId : ''));
    properties.push(new Property('streamId', playerLogInfo.streamId));
    properties.push(new Property('ssId', playerLogInfo.ssId));
    properties.push(new Property('manifest', playerLogInfo.manifest));

    this.addToClientEventBuffer(
      new PlayerCategorie(PlayerSubcategories.PLAY_WARNING),
      undefined,
      properties
    );
  }

  public pageView(pageContext: PageContext) {
    this.addToClientEventBuffer(new TrackCategorie(TrackSubcategories.PAGE_VIEW), pageContext, []);
  }

  public loadDetail(pageContext: PageContext, id: string, type: CmsContentTypes) {
    const properties = [];
    properties.push(new Property('id', id));
    properties.push(new Property('type', type));

    this.addToClientEventBuffer(
      new TrackCategorie(TrackSubcategories.LOAD_DETAIL),
      pageContext,
      properties
    );
  }

  public login(pageContext) {
    this.addToClientEventBuffer(
      new AuthenticateCategorie(AuthenticateSubcategories.LOGIN),
      pageContext,
      []
    );
  }

  public logout(pageContext) {
    this.addToClientEventBuffer(
      new AuthenticateCategorie(AuthenticateSubcategories.LOGOUT),
      pageContext,
      []
    );
  }

  public authenticateState(success: boolean, httpStatusCode: string, error?: LogErrorInfo) {
    const properties = [];
    properties.push(new Property('success', success.toString()));
    if (error) {
      properties.push(new Property('errorCode', error ? error.code : ''));
      properties.push(new Property('errorMessage', error ? error.message : ''));
      properties.push(new Property('errorId', error ? error.errorId : ''));
    }
    properties.push(new Property('httpStatusCode', httpStatusCode));

    this.addToClientEventBuffer(
      new AuthenticateCategorie(AuthenticateSubcategories.STATE),
      undefined,
      properties
    );
  }

  public swipe(swipeLogInfo: SwipeLogInfo, error: LogErrorInfo) {
    const properties = [];
    properties.push(new Property('id', swipeLogInfo.id));
    properties.push(new Property('type', swipeLogInfo.type));
    properties.push(new Property('name', swipeLogInfo.name));
    properties.push(new Property('scheduleId', swipeLogInfo.scheduleId));
    properties.push(new Property('channelId', swipeLogInfo.channelId));
    properties.push(new Property('channelName', swipeLogInfo.channelName));
    properties.push(new Property('bookmark', swipeLogInfo.bookmark.toFixed(0)));
    properties.push(new Property('errorId', error ? error.errorId : ''));
    properties.push(new Property('errorCode', error ? error.code : ''));
    properties.push(new Property('errorMessage', error ? error.message : ''));

    this.addToClientEventBuffer(
      new SwipeCategorie(SwipeSubcategories.SWIPE),
      undefined,
      properties
    );
  }

  public recPlanned(recordingLogInfo: RecordingLogInfo, error: LogErrorInfo) {
    const properties = [];
    properties.push(new Property('programId', recordingLogInfo.programId));
    properties.push(new Property('seasonId', recordingLogInfo.seasonId));
    properties.push(new Property('seriesId', recordingLogInfo.seriesId));
    properties.push(new Property('name', recordingLogInfo.name));
    properties.push(new Property('channelId', recordingLogInfo.channelId));
    properties.push(new Property('channelName', recordingLogInfo.channelName));
    properties.push(new Property('deviceId', recordingLogInfo.deviceId));
    properties.push(new Property('type', recordingLogInfo.type));
    properties.push(new Property('beginBuffer', recordingLogInfo.beginBuffer));
    properties.push(new Property('endBuffer', recordingLogInfo.endBuffer));
    properties.push(new Property('deleteProtection', recordingLogInfo.deleteProtection));
    properties.push(new Property('episodes', recordingLogInfo.episodes));
    properties.push(new Property('errorId', error ? error.errorId : ''));
    properties.push(new Property('errorCode', error ? error.code : ''));
    properties.push(new Property('errorMessage', error ? error.message : ''));

    this.addToClientEventBuffer(
      new RecordingCategorie(RecordingSubcategories.REC_PLANNED),
      undefined,
      properties
    );
  }

  public recRemoved(recordingLogInfo: RecordingLogInfo, error: LogErrorInfo) {
    const properties = [];

    properties.push(new Property('recordingId', recordingLogInfo.recordingId));
    properties.push(new Property('programId', recordingLogInfo.programId));
    properties.push(new Property('scheduleId', recordingLogInfo.scheduleId));
    properties.push(new Property('seriesId', recordingLogInfo.seriesId));
    properties.push(new Property('name', recordingLogInfo.name));
    properties.push(new Property('channelId', recordingLogInfo.channelId));
    properties.push(new Property('channelName', recordingLogInfo.channelName));
    properties.push(new Property('episodes', recordingLogInfo.episodes));
    properties.push(new Property('deviceId', recordingLogInfo.deviceId));
    properties.push(new Property('type', recordingLogInfo.type));
    properties.push(new Property('errorId', error ? error.errorId : ''));
    properties.push(new Property('errorCode', error ? error.code : ''));
    properties.push(new Property('errorMessage', error ? error.message : ''));

    this.addToClientEventBuffer(
      new RecordingCategorie(RecordingSubcategories.REC_REMOVED),
      undefined,
      properties
    );
  }

  public recCancel(recordingLogInfo: RecordingLogInfo, error: LogErrorInfo) {
    const properties = [];

    properties.push(new Property('programId', recordingLogInfo.programId));
    properties.push(new Property('seasonId', recordingLogInfo.seasonId));
    properties.push(new Property('seriesId', recordingLogInfo.seriesId));
    properties.push(new Property('name', recordingLogInfo.name));
    properties.push(new Property('channelId', recordingLogInfo.channelId));
    properties.push(new Property('channelName', recordingLogInfo.channelName));
    properties.push(new Property('episodes', recordingLogInfo.episodes));
    properties.push(new Property('deviceId', recordingLogInfo.deviceId));
    properties.push(new Property('type', recordingLogInfo.type));
    properties.push(new Property('errorId', error ? error.errorId : ''));
    properties.push(new Property('errorCode', error ? error.code : ''));
    properties.push(new Property('errorMessage', error ? error.message : ''));

    this.addToClientEventBuffer(
      new RecordingCategorie(RecordingSubcategories.REC_CANCEL),
      undefined,
      properties
    );
  }

  public recChanged(recordingLogInfo: RecordingLogInfo, error: LogErrorInfo) {
    const properties = [];

    properties.push(new Property('recordingId', recordingLogInfo.recordingId));
    properties.push(new Property('subscriptionId', recordingLogInfo.subscriptionId));
    properties.push(new Property('programId', recordingLogInfo.programId));
    properties.push(new Property('seasonId', recordingLogInfo.seasonId));
    properties.push(new Property('seriesId', recordingLogInfo.seriesId));
    properties.push(new Property('name', recordingLogInfo.name));
    properties.push(new Property('scheduleId', recordingLogInfo.scheduleId));
    properties.push(new Property('channelId', recordingLogInfo.channelId));
    properties.push(new Property('channelName', recordingLogInfo.channelName));
    properties.push(new Property('deviceId', recordingLogInfo.deviceId));
    properties.push(new Property('type', recordingLogInfo.type));
    properties.push(new Property('beginBuffer', recordingLogInfo.beginBuffer));
    properties.push(new Property('endBuffer', recordingLogInfo.endBuffer));
    properties.push(new Property('deleteProtection', recordingLogInfo.deleteProtection));
    properties.push(new Property('episodes', recordingLogInfo.episodes));
    properties.push(new Property('errorId', error ? error.errorId : ''));
    properties.push(new Property('errorCode', error ? error.code : ''));
    properties.push(new Property('errorMessage', error ? error.message : ''));

    this.addToClientEventBuffer(
      new RecordingCategorie(RecordingSubcategories.REC_CHANGED),
      undefined,
      properties
    );
  }

  public articlePageView(articleLogInfo: ArticleLogInfo, pageContext: PageContext) {
    const properties = [];
    properties.push(new Property('articleId', articleLogInfo.articleId));
    properties.push(new Property('title', articleLogInfo.title));
    properties.push(new Property('curPage', articleLogInfo.curPage));
    properties.push(new Property('numPages', articleLogInfo.numPages));

    this.addToClientEventBuffer(
      new ArticleCategorie(ArticleSubcategories.PAGE_VIEW),
      pageContext,
      properties
    );
  }

  public articleAction(articleLogInfo: ArticleLogInfo, pageContext: PageContext) {
    const properties = [];
    properties.push(new Property('articleId', articleLogInfo.articleId));
    properties.push(new Property('label', articleLogInfo.label));
    properties.push(new Property('actionType', articleLogInfo.actionType));
    properties.push(new Property('actionId', articleLogInfo.actionId));
    properties.push(new Property('actionMethod', articleLogInfo.actionMethod));

    this.addToClientEventBuffer(
      new ArticleCategorie(ArticleSubcategories.ACTION),
      pageContext,
      properties
    );
  }

  public bannerAction(bannerLogInfo: CmsAction, pageContext: PageContext) {
    const properties = [];
    properties.push(new Property('actionType', bannerLogInfo.actionType));
    properties.push(new Property('actionId', bannerLogInfo.actionId));
    properties.push(new Property('actionMethod', bannerLogInfo.actionMethod));
    this.addToClientEventBuffer(
      new TrackCategorie(TrackSubcategories.BANNER),
      pageContext,
      properties
    );
  }

  public advertisementEnd(adLogInfo: AdvertisementElement, pageContext: PageContext) {
    const now = new Date();

    const properties = [];
    properties.push(new Property('type', adLogInfo.type));
    properties.push(new Property('campaignId', adLogInfo.campaignId));
    // properties.push(new Property('campaignReportingId', adLogInfo.campaingReportingId));
    properties.push(new Property('advertisementId', adLogInfo.advertisementId));
    properties.push(new Property('advertisementReportingId', adLogInfo.advertisementReportingId));
    properties.push(new Property('resourceType', adLogInfo.isBreakvertising() ? adLogInfo.image : adLogInfo.manifestUrl));
    properties.push(
      new Property('actionMethod', adLogInfo.action ? adLogInfo.action.method : undefined)
    );
    properties.push(
      new Property('actionType', adLogInfo.action ? adLogInfo.action.type : undefined)
    );
    properties.push(new Property('actionId', adLogInfo.action ? adLogInfo.action.id : undefined));
    properties.push(new Property('actionPerformed', adLogInfo.actionPerformed ? 'TRUE' : 'FALSE'));
    properties.push(
      new Property(
        'start',
        moment(adLogInfo.advertisementStartTime).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z'
      )
    );
    properties.push(new Property('end', moment(now).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z'));
    properties.push(
      new Property('duration', adLogInfo.adDuration ? adLogInfo.adDuration.toFixed(0) : undefined)
    );
    properties.push(
      new Property(
        'skippedAfter',
        adLogInfo.skippedAfter ? adLogInfo.skippedAfter.toFixed(0) : undefined
      )
    );
    properties.push(
      new Property('skipTime', adLogInfo.skipTime ? adLogInfo.skipTime.toFixed(0) : undefined)
    );

    this.addToClientEventBuffer(
      new AdvertisementCategorie(AdvertisementSubcategories.ADVERTISEMENT_END),
      pageContext,
      properties
    );
  }

  public advertisementError(
    adLogInfo: AdvertisementElement,
    error: LogErrorInfo,
    pageContext: PageContext
  ) {
    error.message = error.message ? error.message.slice(0, 255) : '';

    const properties = [];
    properties.push(new Property('type', adLogInfo.type));
    properties.push(new Property('campaignId', adLogInfo.campaignId));
    // properties.push(new Property('campaignReportingId', adLogInfo.campaingReportingId));
    properties.push(new Property('advertisementId', adLogInfo.advertisementId));
    properties.push(new Property('advertisementReportingId', adLogInfo.advertisementReportingId));
    properties.push(new Property('resourceType', adLogInfo.isBreakvertising() ? adLogInfo.image : adLogInfo.manifestUrl));
    properties.push(
      new Property('actionMethod', adLogInfo.action ? adLogInfo.action.method : undefined)
    );
    properties.push(
      new Property('actionType', adLogInfo.action ? adLogInfo.action.type : undefined)
    );
    properties.push(new Property('actionId', adLogInfo.action ? adLogInfo.action.id : undefined));
    properties.push(new Property('errorId', error ? error.errorId : ''));
    properties.push(new Property('errorCode', error ? error.code : ''));
    properties.push(new Property('errorMessage', error ? error.message : ''));

    this.addToClientEventBuffer(
      new AdvertisementCategorie(AdvertisementSubcategories.ADVERTISEMENT_ERROR),
      pageContext,
      properties
    );
  }
}
