<div class="filter-wrapper" #filterWrapperRef>
  <div class="filter-current-state-wrapper" (smartTvKeyDownEnter)="showOptions()">
    <div
      *ngIf="!isSmartTv"
      [class.hidden]="filterModel?.channelFilterOutputIndex === undefined"
      class="filter-remove"
      (click)="removeFilter($event)"
    ></div>
    <div class="filter-current-state" (click)="showOptions()">
      <div class="filter-current-state-title">{{ selectedChannelTitle }}</div>
      <div *ngIf="!isSmartTv" class="filter-show-options"></div>
    </div>
  </div>
  <div class="filter-options-wrapper" *ngIf="!isSmartTv && !optionsHidden" #optionWrapperRef>
    <div class="filter-options-header" (click)="hideOptions()">
      <div class="filter-options-header-title">{{ filterTitle }}</div>
      <div class="filter-options-close"></div>
    </div>
    <div class="filter-options">
      <div
        class="filter-option"
        [class.selected]="filterModel?.channelFilterOutputIndex === i"
        (click)="changeSelectedChannelIndex(i)"
        *ngFor="let channel of filterChannels; index as i"
      >
        <div
          class="filter-option-icon"
          [ngStyle]="{
            'background-image': 'url(' + getChannelImage(channel) + ')'
          }"
        ></div>
        <div class="filter-option-title">{{ channel.name }}</div>
      </div>
    </div>
  </div>
</div>

<div #overlayDiv *ngIf="isSmartTv && !optionsHidden" class="overlay">
  <div class="overlay-dialog">
    <div
      class="filter-option-smarttv"
      *ngFor="let channel of filterChannels; index as i"
      (smartTvKeyDownEnter)="changeSelectedChannelIndex(i)"
      (click)="changeSelectedChannelIndex(i)"
    >
      {{ channel.name }}
    </div>
  </div>
</div>
