import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild, ViewRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsAction, CmsActionMethod, CmsContentTypes, Ribbon2Asset } from '@atv-core/api/cms';
import { EpgApiService, EpgScheduleItem, EpgScheduleSummaryAsset } from '@atv-core/api/epg';
import { BookmarkContentTypes } from '@atv-core/api/history';
import { SearchRecordingElement } from '@atv-core/api/search/search-api.model';
import { AdultMode, AdultService } from '@atv-core/services/adult/adult.service';
import { BookmarkCacheService } from '@atv-core/services/cache/bookmark';
import { ChannelRightCacheService } from '@atv-core/services/cache/channelRights/channel-right-cache.service';
import { RecordingModel } from '@atv-core/services/cache/stb/recording.model';
import { StbCacheService } from '@atv-core/services/cache/stb/stb-cache.service';
import { CmsActionService } from '@atv-core/services/cms-action/cms-action.service';
import { DetailTranslationKeys, ErrorTranslationKeys, SettingsKeys } from '@atv-bootstrap/services/config';
import { ConfigService } from '@atv-bootstrap/services/config/config.service';
import { ContentBackdropService } from '@atv-shared/content-backdrop/content-backdrop.service';
import { MessagesService } from '@atv-core/services/messages/messages.service';
import { MiniDetailService } from '@atv-core/services/mini-detail/mini-detail.service';
import { SessionService } from '@atv-core/services/session';
import { EpgUtilityService } from '@atv-core/utility/epg-utility/epg-utility.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { DetailRoutes } from '@atv-detail/atv-detail.model';

import { DetailOverlayService } from '@atv-core/services/detail/detail-overlay.service';
import { RecordingCardMode, RecordingCardModel } from './recording-card.model';
import { ChannelCacheService } from '@atv-core/services/cache/channel';
import { ImageRecipe } from '@atv-core/utility/image/image-recipe';

class RecordingCardVisualModel {
  posterImage: string;
  detailId: string;
  channelImage: string;
  scheduleText: string;
  detailRow1Text: string;
  detailRow2Text: string;
  progressPercentage: number;
  recordingIcon: string;
  playIcon: string;
  folderNumber: number;
  recordingIsLocked: boolean;
  showPlayButton = false;
}

@Component({
  selector: 'app-recording-card',
  templateUrl: './recording-card.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingCardComponent implements OnDestroy {
  @Input()
  showProgress = false;

  @Input()
  allowedAdultMode: AdultMode;

  @Input()
  scrollElement: HTMLElement;

  @ViewChild('image') image: ElementRef<HTMLDivElement>;
  public recordingCardModel: RecordingCardModel;
  public recordingCardVisual = new RecordingCardVisualModel();
  // for nowo NEBINT-4944
  public useOldCardStyling = false;
  public recordingCardMode = RecordingCardMode.EPISODE_SCHEDULE;
  private isSearchCard = false;
  private firstRecording: RecordingModel;
  private intersectionObserver: IntersectionObserver = null;

  constructor(
    private config: ConfigService,
    private epgApi: EpgApiService,
    private epgUtility: EpgUtilityService,
    private channelRightCache: ChannelRightCacheService,
    private backdropService: ContentBackdropService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messagesService: MessagesService,
    private cmsActionService: CmsActionService,
    private bookmarkCache: BookmarkCacheService,
    private adultService: AdultService,
    private stbCache: StbCacheService,
    private cdr: ChangeDetectorRef,
    private sessionService: SessionService,
    private detailService: DetailOverlayService,
    private miniDetailService: MiniDetailService,
    private channelCacheService: ChannelCacheService,
  ) {
    this.useOldCardStyling = this.config.getSettingBoolean(SettingsKeys.useOldEpgCards, false);
    this.recordingCardMode = this.useOldCardStyling
      ? RecordingCardMode.CHANNEL_PROGRAM_OLD_STYLING
      : RecordingCardMode.TITLE_SCHEDULE;
  }

  @Input()
  set recordingCardInput(value: RecordingCardModel | SearchRecordingElement) {
    if (!value) {
      return;
    }

    if (value instanceof RecordingCardModel) {
      this.isSearchCard = false;
      this.recordingCardModel = value;
      this.initFromModel();
    } else if (value instanceof SearchRecordingElement) {
      this.isSearchCard = true;
      this.fetchRecordingModel(value);
    }
  }

  onclick(e: Event): void {
    if (e) {
      e.preventDefault();
    }
    if (!this.recordingCardModel) {
      return;
    }
    const recModel = this.recordingCardModel.getFirstRecording();
    if (!recModel) {
      this.messagesService.showErrorMessage(
        this.config.getTranslation(ErrorTranslationKeys.error_no_rights_detail_description),
      );
      return;
    }

    if (this.recordingCardModel.isFolder()) {
      if (this.isSearchCard && this.router.url.startsWith('/main/')) {
        this.router.navigate(['recordings', this.recordingCardModel.seriesId], {
          relativeTo: this.activatedRoute,
        });
      } else if (this.isSearchCard) {
        this.router.navigate(['recordings', this.recordingCardModel.seriesId]);
      } else if (recModel.device && recModel.series && recModel.channel) {
        this.router.navigate(
          [
            this.removePathParams(this.router.url),
            recModel.device,
            recModel.series,
            recModel.channel,
          ],
          {
            replaceUrl: window.location.pathname.includes(DetailRoutes.detailBaseRoute),
          },
        );
      }
    } else {
      this.showDetail(recModel);
    }
  }

  showDetail(recModel: RecordingModel): void {
    this.detailService.openEpgDetail(
      1,
      CmsContentTypes.RECORDING.toLocaleLowerCase(),
      recModel.identifier,
    );
  }

  doPlay(e: MouseEvent): void {
    e.preventDefault();
    e.stopImmediatePropagation();

    if (!this.recordingCardModel || !this.firstRecording) {
      return;
    }
    const action: CmsAction = {
      actionType: CmsContentTypes.RECORDING,
      actionId: this.firstRecording.identifier,
      actionMethod: CmsActionMethod.PLAY,
    };

    this.cmsActionService.doAction(action);
  }

  public onFocus(): void {
    this.miniDetailService.setDetail();
  }

  public focusOut(): void {
    this.miniDetailService.clearDetail();
  }

  private detectChanges(): void {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  private fetchRecordingModel(value: SearchRecordingElement): void {
    const searchItem: Ribbon2Asset = value.searchElement;
    if (searchItem.asset.type === CmsContentTypes.PROGRAM) {
      this.stbCache
        .getRecordingForProgram(
          searchItem.asset.id,
          undefined,
          this.adultService.showAdult(this.allowedAdultMode),
        )
        .subscribe((recording) => {
          if (recording) {
            this.recordingCardModel = new RecordingCardModel(
              recording,
              this.adultService.showAdult(this.allowedAdultMode),
              this.recordingCardMode,
              value.inFolderPage,
            );
            this.initFromModel();
          }
        });
    } else if (searchItem.asset.type === CmsContentTypes.SERIES) {
      this.stbCache
        .getRecordingsForSeries(
          searchItem.asset.id,
          this.adultService.showAdult(this.allowedAdultMode),
        )
        .subscribe((recordings) => {
          if (recordings && recordings.length > 0) {
            const rec = recordings.shift();
            const tmpCard = new RecordingCardModel(
              rec,
              this.adultService.showAdult(this.allowedAdultMode),
              this.recordingCardMode,
              value.inFolderPage,
            );

            recordings.forEach((recording) => {
              tmpCard.recordingModels.push(recording);
            });

            this.recordingCardModel = tmpCard;
            this.initFromModel();
          }
        });
    }
  }

  private initFromModel(): void {
    this.firstRecording = this.recordingCardModel.getFirstRecording();
    this.channelCacheService.getChannels().subscribe(channels => {
        this.recordingCardModel.addChannelInformation(channels);

        if (this.firstRecording) {
          this.epgApi.getSchedule(this.firstRecording.schedule).subscribe(
            (result: EpgScheduleItem) => {
              if (result && result.schedule) {
                this.recordingCardModel.addInformationFromSchedule(result.schedule);
                this.recordingCardModel.addInformationFromSeries(result.series);
                this.recordingCardModel.addInformationFromProgram(result.program);
                this.initRecordingCardVisual();
              } else {
                this.fallBackOnProgram();
              }
            },
            () => {
              this.fallBackOnProgram();
            },
          );
        }
      },
    );
  }

  private fallBackOnProgram(): void {
    const fromUntil = this.epgUtility.getEpgDaysFromUntil();
    this.epgApi
      .getProgram(this.firstRecording.program, fromUntil.from, fromUntil.until)
      .subscribe((result) => {
        this.recordingCardModel.addInformationFromProgram(result.program);
        this.recordingCardModel.addInformationFromSeries(result.series);
        this.initRecordingCardVisual();
      });
  }

  private initRecordingCardVisual(): void {
    const program =
      this.recordingCardModel && this.recordingCardModel.program
        ? this.recordingCardModel.program
        : undefined;

    this.getPlayIcons();

    if (program) {
      this.recordingCardVisual.posterImage = SharedUtilityService.getPosterImage(
        program.posterImage,
        ImageRecipe.EPG_CARD_3,
        program.adult && !this.adultService.showAdult(this.allowedAdultMode),
      );

      this.intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.length > 0 && entries[0].isIntersecting) {
          window.requestIdleCallback(() => {
            this.image.nativeElement.style.backgroundImage = `url(${this.recordingCardVisual.posterImage})`;
          });
          this.intersectionObserver.unobserve(this.image.nativeElement);
          this.intersectionObserver.disconnect();
          this.intersectionObserver = null;
        }
      });
      this.intersectionObserver.observe(this.image.nativeElement);

      window.requestIdleCallback(() => {
        this.backdropService.addBackdropImage(this.epgUtility.getDetailBackdropForProgram(program));
      });
    }
    this.recordingCardVisual.channelImage = this.recordingCardModel.getChannelImage();
    this.recordingCardVisual.detailRow1Text = this.getRow1Text();
    this.recordingCardVisual.detailRow2Text = this.getRow2Text();
    this.recordingCardVisual.scheduleText = this.recordingCardModel.getSchedule();
    this.recordingCardVisual.recordingIcon = this.recordingCardModel.getRecordingIcon();
    // this.rec;

    this.recordingCardVisual.recordingIsLocked = this.recordingCardModel.getRecordingIsLocked();

    if (this.firstRecording) {
      this.loadBookmarkFromCache();
      this.bookmarkCache.bookmarkUpdateEvent.subscribe(() => {
        this.loadBookmarkFromCache();
      });
    }

    this.recordingCardVisual.showPlayButton = this.showPlayButton();
    this.detectChanges();
  }

  private getPlayIcons(): void {
    if (!this.firstRecording) {
      return;
    }
    const schedule: EpgScheduleSummaryAsset = this.recordingCardModel.schedule
      ? this.recordingCardModel.schedule
      : {
        id: this.firstRecording.schedule,
        published: { start: this.firstRecording.start, end: this.firstRecording.end },
        program: this.firstRecording.program,
      };

    if (schedule && !this.recordingCardModel.isFolder()) {
      this.channelRightCache
        .getPlayIcon(
          schedule,
          this.recordingCardModel.getChannel(),
          this.firstRecording,
          undefined,
          true,
        )
        .subscribe((icon) => {
          this.recordingCardVisual.playIcon = icon;
          this.detectChanges();
        });
    }
  }

  private loadBookmarkFromCache(): void {
    if (this.showProgress) {
      if (
        this.recordingCardModel &&
        this.recordingCardModel.schedule &&
        this.epgUtility.scheduleIsLive(this.recordingCardModel.schedule)
      ) {
        this.recordingCardVisual.progressPercentage = this.epgUtility.calculateProgressPercentage(
          this.recordingCardModel.schedule.published);
      } else {
        this.bookmarkCache
          .getBookmark(
            BookmarkContentTypes.PROGRAM,
            this.firstRecording.program,
            this.adultService.showAdult(this.allowedAdultMode),
          )
          .subscribe((result) => {
            if (result) {
              this.recordingCardVisual.progressPercentage = (result.position / result.size) * 100;
              this.detectChanges();
            }
          });
      }
    }
  }

  private getRow1Text(): string {
    let res = '';
    if (
      (this.recordingCardModel.recordingCardMode ===
       RecordingCardMode.CHANNEL_EPISODE_OLD_STYLING ||
       this.recordingCardModel.recordingCardMode ===
       RecordingCardMode.CHANNEL_PROGRAM_OLD_STYLING) &&
      this.recordingCardModel.getChannel()
    ) {
      const channel = this.recordingCardModel.getChannel();
      const channelNumber = channel.getChannelNumberForCatalog(this.sessionService.getCatalogId());
      res = channelNumber && channel.name ? channelNumber + ' • ' + channel.name : channel.name;
    }

    if (this.recordingCardModel.recordingCardMode === RecordingCardMode.EPISODE_SCHEDULE) {
      res = this.getEpisodeInfo();
    }

    if (!res || this.recordingCardModel.recordingCardMode === RecordingCardMode.TITLE_SCHEDULE) {
      res = this.recordingCardModel.getTitle();
    }
    return res;
  }

  private getEpisodeInfo(): string {
    let res = '';

    if (this.recordingCardModel.inFolderPage && this.recordingCardModel.program) {
      const program = this.recordingCardModel.program;
      res = program.episodeTitle ? program.episodeTitle : '';
      const epPrefix = res
        ? this.config.getTranslation(DetailTranslationKeys.detail_episode_short) || ''
        : this.config.getTranslation(DetailTranslationKeys.detail_episode) || '';
      res =
        (program.episode ? epPrefix + ' ' + program.episode : '') +
        (res && program.episode ? ' • ' : '') +
        res;
    }

    return res;
  }

  private getRow2Text(): string {
    if (
      this.recordingCardModel.recordingCardMode === RecordingCardMode.CHANNEL_PROGRAM_OLD_STYLING
    ) {
      return this.recordingCardModel.getTitle();
    }

    if (
      this.recordingCardModel.recordingCardMode === RecordingCardMode.CHANNEL_EPISODE_OLD_STYLING
    ) {
      return this.getEpisodeInfo() || this.recordingCardModel.getTitle();
    }
    return this.recordingCardModel.getSchedule();
  }

  private removePathParams(url: string): string {
    const params = this.activatedRoute.snapshot.params;

    if (params) {
      const values = Object.values(params);
      if (values.every((value) => url.includes(value))) {
        values.forEach((value) => (url = url.replace('/' + value, '')));
      }
    }
    return url;
  }

  private showPlayButton(): boolean {
    if (
      !this.recordingCardModel ||
      !this.recordingCardModel.schedule ||
      !this.recordingCardModel.channels ||
      !this.firstRecording
    ) {
      return false;
    }

    const channel = this.recordingCardModel.channels.find(
      (ch) => ch.id === this.firstRecording.channel,
    );

    return this.epgUtility.canPlayRecording(
      this.firstRecording,
      this.recordingCardModel.schedule,
      channel,
    );
  }

  ngOnDestroy(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.unobserve(this.image.nativeElement);
      this.intersectionObserver.disconnect();
    }
  }
}
