export enum StreamType {
  LINEAR = 'LINEAR',
  REPLAY = 'REPLAY',
  NPVR = 'NPVR',
  SVOD = 'SVOD',
  TVOD = 'TVOD',
  FVOD = 'FVOD',
  DVBC = 'DVBC',
  RECORDING = 'RECORDING',
}

export enum PlatformType {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  WEB = 'WEB',
  STB = 'STB',
  CHROMECAST = 'CHROMECAST',
  ANDROIDTV = 'ANDROIDTV',
  ANDROIDTV_STB = 'ANDROIDTV_STB',
  GOOGLEHOME = 'GOOGLEHOME',
  TVOS = 'TVOS',
  SMARTTV_TIZEN = 'SMARTTV_TIZEN',
  SMARTTV_WEBOS = 'SMARTTV_WEBOS',
}

export class Platform {
  static isStb(platform: PlatformType): boolean {
    return platform === PlatformType.STB || platform === PlatformType.ANDROIDTV_STB;
  }
}

export enum ExtraData {
  NONE = 'NONE',
  MINI = 'MINI',
}

export enum FocusType {
  ANY = 'ANY',
  FUTURE = 'FUTURE',
  PAST = 'PAST',
}

export enum DeviceType {
  IPHONE = 'IPHONE',
  IPAD = 'IPAD',
  ANDROID_PHONE = 'ANDROID_PHONE',
  ANDROID_TABLET = 'ANDROID_TABLET',
  ANDROID_TV = 'ANDROID_TV',
  ANDROIDTV = 'ANDROIDTV',
  ANDROID_TV_STB = 'ANDROID_TV_STB',
  APPLE_TV = 'APPLE_TV',
  STB = 'STB',
  WEB = 'WEB',
  CHROMECAST = 'CHROMECAST',
  SMARTTV_TIZEN = 'SMARTTV_TIZEN',
  SMARTTV_WEBOS = 'SMARTTV_WEBOS'
}

export enum PlayState {
  playing = 'playing',
  error = 'error',
  paused = 'paused',
}

export enum PlayOriginator {
  startStream = 'StartStream',
  stopStream = 'StopStream',
  player = 'Player',
}

export enum StreamResolutionType {
  SD = 'SD',
  HD = 'HD',
  UHD = 'UHD',
  FOURK = '4K',
}

export enum DeviceSettings {
  adult_mode = 'adultmode',

  recording_episodes = 'recording_episodes',
  recording_before_time = 'recording_before_time',
  recording_after_time = 'recording_after_time',
  recording_delete_protection = 'recording_delete_protection',

  reminder_before_time = 'reminder_before_time',

  max_linear_stream_time = 'max_linear_stream_time',
}

export enum CustomerSettings {
  default_recording_device = 'default_recording_device',
  ott_adult_mode_allowed = 'androidtv_adult_mode_allowed',
}

export enum InfernoState {
  failed = 'FAILED',
  contentDeleted = 'CONTENT_DELETED',
  unresolved = 'UNRESOLVED',
  inProgressWithErrorNoSignal = 'IN_PROGRESS_WITH_ERROR_NO_SIGNAL',
  inProgressWithErrorWasBeingRecorded = 'IN_PROGRESS_WITH_ERROR_WAS_BEING_RECORDED',
  inProgressWithErrorWasBeingRecordedNoSignal = 'IN_PROGRESS_WITH_ERROR_WAS_BEING_RECORDED_NO_SIGNAL',
  pendingWithConflict = 'PENDING_WITH_CONFLICT',
  valueInProgressWithError = 'VALUE_IN_PROGRESS_WITH_ERROR',
}

export enum InfernoFailureReason {
  insufficient_resources = 'INSUFFICIENT_RESOURCES',
}
