import { ContentProvider } from '@atv-shared/content-provider/content-provider';
import { VodApiService } from '@atv-core/api/vod';
import { AdultMode } from '@atv-core/services/adult';
import { CardOrientation, Ribbon2Asset } from '@atv-core/api/cms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class VodContentProvider extends ContentProvider {
  private hasMore = true;
  private page = 0;

  constructor(
    private vodApi: VodApiService,
    private catalogId: string,
    private allowedAdultMode: AdultMode,
    private tags: string[],
    private productionYear?: number,
    private minOperatorScore?: number,
    private maxOperatorScore?: number,
  ) {
    super();

    this.orientation = CardOrientation.LANDSCAPE;
  }

  public getItems(): Observable<Ribbon2Asset[]> {
    return this.vodApi.getCmsVods(this.catalogId, this.allowedAdultMode, this.tags, this.page, this.productionYear,
      this.minOperatorScore, this.maxOperatorScore)
      .pipe(
        map(result => {
          this.hasMore = result.nextPage;
          this.page += 1;
          this.orientation = result.type;

          return result.items;
        }),
      );
  }

  public hasNext(): boolean {
    return this.hasMore;
  }

  public resetPaging(): void {
    this.hasMore = true;
    this.page = 0;
  }
}
