import { CardOrientation, CardSize, CmsAction, CmsContentTypes, Ribbon2Asset, Ribbon2Detail } from '@atv-core/api/cms';
import { CardConfig, CardModel, CardRowStyling } from '@atv-shared/generic-card/generic-card.model';
import { ScheduleChannel } from '@atv-shared/generic-card/generic-card.component';
import { ChannelModel } from '@atv-core/services/cache/channel';
import { EpgScheduleSummaryAsset } from '@atv-core/api/epg';
import { ImageRecipe, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { AdultService } from '@atv-core/services/adult';
import { ScheduleTime } from '@atv-core/api/history';
import { ConfigService, DetailTranslationKeys, GuideTranslationKeys } from '@atv-bootstrap/services/config';
import { SessionService } from '@atv-core/services/session';
import { CardModelFactoryService } from '@atv-shared/card-model/card-model-factory-service';
import { CardModelFactory } from '@atv-shared/card-model/card-model-factory';

export class EpgCardModelFactory extends CardModelFactory {

  constructor(
    private cardModelFactory: CardModelFactoryService,
    private config: ConfigService,
    private sessionService: SessionService,
    private adultService: AdultService,
    private cardConfig: CardConfig,
  ) {
    super();
  }

  public createCard(item: Ribbon2Asset, cardModel: CardModel, scheduleChannel?: ScheduleChannel): void {
    // TODO fix channel cards (TOGGLE and LOGO cards)
    const cardData = scheduleChannel?.program ? scheduleChannel.program : item.asset;
    cardModel.image = this.getEpgCardImage(cardData, cardModel.orientation, cardModel.size);
    this.cardModelFactory.setBackdropImage(item.asset, this.cardConfig.allowedAdultMode);
    cardModel.channelImage = this.setChannelLogo(scheduleChannel?.channel);

    cardModel.scheduleText = this.setScheduleLine(scheduleChannel?.schedule);

    cardModel.row1Styling = this.setRow1Style();
    cardModel.row1 = this.getEpgRow1(cardData, scheduleChannel?.channel);
    cardModel.row2 = this.getEpgRow2(cardData, scheduleChannel?.schedule);

    cardModel.action = this.getEpgAction(item, scheduleChannel?.schedule);
  }

  public getProgramTitle(title: string, adult: boolean): string {
    if (adult && !this.adultService.showAdult(this.cardConfig.allowedAdultMode)) {
      return this.config.getTranslation(GuideTranslationKeys.guide_adult_title);
    }
    return title;
  }

  private getEpgCardImage(asset: Ribbon2Detail, cardOrientation: CardOrientation, cardSize: CardSize): string {
    return this.cardModelFactory.getImageUrl(asset,
      this.cardModelFactory.getImageRecipe(asset.type, cardOrientation, cardSize), cardOrientation, this.cardConfig.allowedAdultMode);
  }

  private setChannelLogo(channel: ChannelModel): string {
    return channel?.defaultLogo ? ImageRecipeUtil.createImageUrl(channel.defaultLogo, ImageRecipe.CHANNEL_1) : undefined;
  }

  private setScheduleLine(schedule: EpgScheduleSummaryAsset): string {
    if (this.cardConfig.useOldEpgCardContent && this.cardModelFactory.showScheduleInfo(this.cardConfig)) {
      return this.getScheduleInfo(schedule.published);
    }
    return '';
  }

  private getScheduleInfo(schedule: ScheduleTime): string {
    if (schedule) {
      const start = SharedUtilityService.timeStringToMoment(schedule.start).format('DD/MM, HH:mm');
      const end = SharedUtilityService.timeStringToMoment(schedule.end).format('HH:mm');
      return start.toString() + ' - ' + end.toString();
    }

    return '';
  }

  private setRow1Style(): CardRowStyling[] {
    if (this.cardModelFactory.showScheduleInfo(this.cardConfig) || this.cardModelFactory.useBestSchedule(this.cardConfig)) {
      return [CardRowStyling.BOLD, CardRowStyling.PRIMARY_COLOR];
    }
    return [];
  }

  private getEpgRow1(asset: Ribbon2Detail, channel: ChannelModel): string {
    if (this.cardModelFactory.showInfo(this.cardConfig)) {
      if (this.cardConfig.useOldEpgCardContent && this.cardModelFactory.showScheduleInfo(this.cardConfig) && channel) {
        return this.getChannelString(channel);
      }

      if (this.cardModelFactory.useBestSchedule(this.cardConfig) && this.cardModelFactory.showEpisodeInfo(this.cardConfig)) {
        return this.getEpisodeProgramString(asset.episode, asset.title);
      }

      return this.getProgramTitle(asset.title, asset.adult);
    }
    return '';
    // TODO fallback on series title
  }

  private getEpgRow2(asset: Ribbon2Detail, schedule: EpgScheduleSummaryAsset): string {
    if (!this.cardModelFactory.showInfo(this.cardConfig)) {
      if (this.cardModelFactory.showEpisodeInfo(this.cardConfig)) {
        return this.cardModelFactory.getEpgSeasonEpisodeString(asset);
      }
      return '';
    }

    if (this.cardModelFactory.showScheduleInfo(this.cardConfig) && schedule) {
      if (this.cardConfig.useOldEpgCardContent) {
        return this.getEpisodeProgramString(asset.episode, asset.title);
      } else {
        return this.getScheduleInfo(schedule.published);
      }
    }

    if (this.cardModelFactory.showEpisodeInfo(this.cardConfig) && !this.cardModelFactory.useBestSchedule(this.cardConfig)) {
      return this.cardModelFactory.getEpgSeasonEpisodeString(asset);
    }
    return '';
  }

  private getEpisodeProgramString(episode: number, episodeTitle: string): string {
    let res = episodeTitle ?? '';
    const epPrefix = res
      ? this.config.getTranslation(DetailTranslationKeys.detail_episode_short) || ''
      : this.config.getTranslation(DetailTranslationKeys.detail_episode) || '';
    res = (episode ? epPrefix + ' ' + episode : '') + (res && episode ? ' • ' : '') + res;

    return res;
  }

  private getChannelString(channel: ChannelModel): string {
    const channelNumber = channel.getChannelNumberForCatalog(this.sessionService.getCatalogId());
    return channelNumber && channel.name ? channelNumber + ' • ' + channel.name : channel.name;
  }

  private getEpgAction(epg: Ribbon2Asset, schedule: EpgScheduleSummaryAsset): CmsAction {
    if (this.cardModelFactory.useBestSchedule(this.cardConfig) && schedule) {
      return {
        actionId: schedule.id,
        actionMethod: epg.method,
        actionType: CmsContentTypes.SCHEDULE,
      };
    }
    return {
      actionId: epg.asset.id,
      actionMethod: epg.method,
      actionType: epg.asset.type,
    };
  }
}
