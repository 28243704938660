import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DetailTranslationKeys, SettingsKeys, SettingsTranslationKeys } from '@atv-bootstrap/services/config/config.model';
import { ConfigService } from '@atv-bootstrap/services/config/config.service';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';

import { SubscriptionInfoModel } from './subscription-info.model';
import { SubscriptionSettingsModel, SubscriptionTypes } from './subscription-settings.model';

class SubscriptionVisualModel {
  stbTitle: string;
  typeTitle: string;
  beginBufferTitle: string;
  endBufferTitle: string;
  deleteProtectTitle: string;
}

export interface SubscriptionSettingSection {
  section: NavigationSections;
  selector: string;
}

@Component({
  selector: 'app-subscription-settings',
  templateUrl: './subscription-settings.component.html',
  styles: [],
})
export class SubscriptionSettingsComponent implements OnInit {
  @Input()
  settings: SubscriptionSettingsModel;

  @Input()
  info: SubscriptionInfoModel;

  @Input()
  leaveForDown: string;

  @Input()
  leaveForUp: string;

  @Output()
  deviceChange = new EventEmitter();

  subscriptionVisual = new SubscriptionVisualModel();

  subscriptionTypes = [];

  beginBufferOptions = [];
  endBufferOptions = [];

  public isSmartTv = SharedUtilityService.isSmartTv();
  public onText = '';
  public offText = '';

  constructor(
    private config: ConfigService,
    private spatialNavigationService: SpatialNavigationService,
  ) {
    this.subscriptionTypes = [
      {
        value: SubscriptionTypes.EPISODE,
        name: this.config.getTranslation(DetailTranslationKeys.detail_recording_this_episode),
      },
      {
        value: SubscriptionTypes.SEASON,
        name: this.config.getTranslation(DetailTranslationKeys.detail_recording_this_season),
      },
      {
        value: SubscriptionTypes.SERIES,
        name: this.config.getTranslation(DetailTranslationKeys.detail_recording_this_series),
      },
    ];

    const minutesStr = this.config.getTranslation(DetailTranslationKeys.detail_duration_min);

    const beginBuffers = this.config.getSettingString(SettingsKeys.recordingBeforeTimes).split(',');
    this.beginBufferOptions = beginBuffers.map((option) => {
      return { value: '' + parseInt(option, 10) * 60000, name: `${option} ${minutesStr}` };
    });

    const endBuffers = this.config.getSettingString(SettingsKeys.recordingAfterTimes).split(',');
    this.endBufferOptions = endBuffers.map((option) => {
      return { value: '' + parseInt(option, 10) * 60000, name: `${option} ${minutesStr}` };
    });

    this.subscriptionVisual.stbTitle = this.config.getTranslation(
      DetailTranslationKeys.detail_recording_stb
    );
    this.subscriptionVisual.typeTitle = this.config.getTranslation(
      DetailTranslationKeys.detail_recording_episodes
    );
    this.subscriptionVisual.beginBufferTitle = this.config.getTranslation(
      DetailTranslationKeys.detail_recording_change_rec_option_before
    );
    this.subscriptionVisual.endBufferTitle = this.config.getTranslation(
      DetailTranslationKeys.detail_recording_change_rec_option_after,
    );
    this.subscriptionVisual.deleteProtectTitle = this.config.getTranslation(
      DetailTranslationKeys.detail_recording_delete_protected,
    );
    this.onText = this.config.getTranslation(SettingsTranslationKeys.settings_toggle_on);
    this.offText = this.config.getTranslation(SettingsTranslationKeys.settings_toggle_off);
  }

  ngOnInit(): void {
  }

  public getNextSection(section: 'stb' | 'type' | 'begin-buffer' | 'end-buffer' | 'delete-protect'): string {
    const sections = this.getSections();
    const index = sections.findIndex(s => s === section);

    return index < 0 || index >= sections.length - 1 ? this.leaveForDown : `.${sections[index + 1]}.chip.selected`;
  }

  public inputKeyEvent(event: KeyboardEvent): void {
    // when 'done' on screen keyboard or enter is pressed
    if (this.spatialNavigationService.keyCodeIsEnter(event.keyCode)) {
      event.cancelBubble = true;
      event.preventDefault();
      this.spatialNavigationService.move('down');
    }
  }

  public getPreviousSection(section: 'stb' | 'type' | 'begin-buffer' | 'end-buffer' | 'delete-protect'): string {
    const sections = this.getSections();
    const index = sections.findIndex(s => s === section);

    return index < 1 ? this.leaveForUp : `.${sections[index - 1]}.chip.selected`;
  }

  public getFirstSection(): string {
    const sections = this.getSections();
    return sections?.length > 0 ? `.${sections[0]}.chip.selected` : undefined;
  }

  public getLastSection(): string {
    const sections = this.getSections();
    return sections?.length > 0 ? `.${sections[sections.length - 1]}.chip.selected` : undefined;
  }

  private getSections(): ('stb' | 'type' | 'begin-buffer' | 'end-buffer' | 'delete-protect')[] {
    const sections = [];

    if (this.info?.showStbs) {
      sections.push('stb');
    }

    if (this.info?.showSubscriptionTypes) {
      sections.push('type');
    }

    if (this.info?.showBeginBufferOptions) {
      sections.push('begin-buffer');
    }

    if (this.info?.showEndBufferOptions) {
      sections.push('end-buffer');
    }

    if (this.info?.showDeleteProtected) {
      sections.push('delete-protect');
    }

    return sections;
  }
}
