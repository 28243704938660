<div [class.smarttv]="isSmartTv" class="searchbar">
  <div id="searchField">
    <input
      #searchBarInput
      type="text"
      id="search"
      name="search"
      autocomplete="off"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
    <div class="searchbar-button"><img alt="" src="./assets/theme/svg/search_normal.svg" /></div>
  </div>

  <div id="autocompletions">
    <div
      *ngFor="let completion of completions"
      class="completion"
      [innerHTML]="completion.displayValue | highlight: searchBarInput.value"
      (click)="completionSelected(completion.fullValue)"
      (smartTvKeyDownEnter)="completionSelected(completion.fullValue)"
      tabindex="-1"
    ></div>
  </div>
</div>
