import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CmsContentTypes } from '@atv-core/api/cms/cms-api.model';
import {
  ChromecastTranslationKeys,
  ErrorTranslationKeys,
  PlayerTranslationKeys,
  RibbonTranslationKeys,
} from '@atv-bootstrap/services/config';
import { ConfigService } from '@atv-bootstrap/services/config/config.service';
import { MessagesService } from '@atv-core/services/messages';
import { SessionService } from '@atv-core/services/session';
import { StreamManagerService } from '@atv-core/services/stream-manager-service/stream-manager.service';
import { StreamType } from '@atv-core/utility/constants/shared';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { DetailRoutes } from '@atv-detail/atv-detail.model';
import { forkJoin, interval, Observable, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { PlayInfoModel } from '../model/play-info-model';
import { ValidQuotePriceResponse } from '@atv-core/api/pricing/pricing.model.service';
import { Stream2StartResponse } from '@atv-core/api/ss/streaming-api.model';
import { QuoteCacheService } from '@atv-core/services/cache/quote/quote-cache.service';
import { CustomDataModel } from './customData.model';
import { ImageRecipe, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';
import { PlayerService } from '@atv-core/services/player/player.service';

enum PlayingStates {
  NONE,
  BUFFERING,
  PLAYING,
  PAUSED,
}

interface CurrentAdvertisement {
  whenSkippable?: number;
  currentTime?: number;
}

class ChromeCastControllerVisualModel {
  castingToText = '';

  image = '';
  title = '';
  subtitle = '';

  isLive = false;
  playingState = PlayingStates.NONE;

  progressTime = '';

  allowSeeking = false;

  constructor() {
  }

  reset(): void {
    this.image = '';
    this.title = '';
    this.subtitle = '';
    this.isLive = false;
    this.playingState = PlayingStates.NONE;
    this.progressTime = '';
  }
}

@Component({
  selector: 'app-remote-player',
  templateUrl: './remote-player.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemotePlayerComponent implements OnDestroy {
  public visualModel = new ChromeCastControllerVisualModel();

  public PlayingStates = PlayingStates;

  public detailPageOpen = false;

  public currentAdvertisement: CurrentAdvertisement;
  private adIntervalSubscription: Subscription;

  constructor(
    private playerService: PlayerService,
    private cdr: ChangeDetectorRef,
    private messagesService: MessagesService,
    private config: ConfigService,
    private sessionService: SessionService,
    private streamManagerService: StreamManagerService,
    private router: Router,
    private quoteCache: QuoteCacheService,
  ) {
    // init cast api when casting is available and not a smart tv build
    this.detailPageOpen =
      this.router.url.includes(DetailRoutes.detailBaseRoute) ||
      this.router.url.includes('detailOverlay');
    this.setCastIconStyling();
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.detailPageOpen =
          e.url.includes(DetailRoutes.detailBaseRoute) || e.url.includes('detailOverlay');
        this.detectChanges();

        this.setCastIconStyling();
      }
    });
  }

  public seek(position: number): void {
    // Use the current session to get an up to date media status.
    if (!this.playerService.castSession || !this.playerService.remotePlayer) {
      return;
    }

    // Contains information about the playing media including currentTime.
    const mediaStatus = this.playerService.castSession.getMediaSession() as chrome.cast.media.Media;
    if (!mediaStatus) {
      return;
    }

    // mediaStatus also contains the mediaInfo containing metadata and other
    // information about the in progress content.
    const duration = this.playerService.seekbar.getDuration();

    this.playerService.remotePlayer.currentTime = (duration * position) / 100;
    this.playerService.remotePlayerController.seek();
  }

  public showRemotePlayer(): boolean {
    const shouldShow =
      this.playerService &&
      this.playerService.remotePlayerConnected &&
      this.visualModel &&
      this.visualModel.playingState !== PlayingStates.NONE &&
      this.router.url !== '/';

    if (shouldShow && !document.body.classList.contains('remote-atv-player-open')) {
      document.body.classList.add('remote-atv-player-open');
    } else if (!shouldShow && document.body.classList.contains('remote-atv-player-open')) {
      document.body.classList.remove('remote-atv-player-open');
    }

    return shouldShow;
  }

  public startStream(): void {
    this.streamManagerService.startStreamForChromecast().subscribe(
      (result) => {
        this.playStream(result);
      },
      (errorResponse: HttpErrorResponse) => {
        this.messagesService.showErrorMessage(
          this.config.getTranslation(
            errorResponse && errorResponse.error ? errorResponse.error.code : '',
            ErrorTranslationKeys.error_streaming,
          ),
        );
      },
    );
  }

  public skipAd(): void {
    const session = cast.framework.CastContext.getInstance().getCurrentSession();
    session.sendMessage('urn:x-cast:com.google.cast.media', {
      type: 'SKIP_AD',
      requestId: 1,
      mediaSessionId: session.getMediaSession().mediaSessionId,
    }).then(() => {
    });
  }

  public getSkipAdText(): string {
    if (this.currentAdvertisement.currentTime >= this.currentAdvertisement.whenSkippable) {
      return this.config.getTranslation(PlayerTranslationKeys.player_ad_skip_now_ott);
    }

    const seconds = Math.ceil(
      this.currentAdvertisement.whenSkippable - this.currentAdvertisement.currentTime,
    );

    return this.config
      .getTranslation(PlayerTranslationKeys.player_ad_skip_seconds_ott)
      .replace('$1', seconds.toString());
  }

  public stopCasting(): void {
    // End the session and pass 'true' to indicate
    // that receiver application should be stopped.
    if (this.playerService.castSession) {
      this.playerService.castSession.endSession(true);
    }
  }

  public skip(forward: boolean): void {
    let time = this.playerService.remotePlayer.currentTime;
    if (!time) {
      return;
    }

    if (forward) {
      time += 30;
    } else {
      time -= 30;
    }

    this.playerService.remotePlayer.currentTime = time;
    this.playerService.remotePlayerController.seek();
  }

  public playPauseToggle(): void {
    if (
      this.playerService.remotePlayerController &&
      (this.visualModel.playingState === PlayingStates.PAUSED ||
       this.visualModel.playingState === PlayingStates.PLAYING)
    ) {
      this.playerService.remotePlayerController.playOrPause();
    }
  }

  public playerConnectionChange(): void {
    this.playerService.remotePlayerConnected = this.playerService.remotePlayer.isConnected;
    this.visualModel.reset();

    // if chrome cast is connected
    if (this.playerService.remotePlayerConnected && cast.framework) {
      this.setupRemoterPlayer();
      if (this.playerService.playerVisibilitySubject.value) {
        this.startCurrentPlayingAssetForChromecast();
      }
    } else {
      this.visualModel.castingToText = '';
    }

    this.detectChanges();
  }

  public playAsset(playInfo: PlayInfoModel): void {
    this.playerService.currentPlayInfo = playInfo;

    if (playInfo.isReservedStream) {
      this.handleReservedStream();
    } else {
      this.startStream();
    }
  }

  private startCurrentPlayingAssetForChromecast(): void {
    this.playerService.closePlayerEvent.emit();
    this.playAsset(this.playerService.currentPlayInfo);
  }

  public remotePlayerChange(): void {
    // Use the current session to get an up to date media status.
    if (!this.playerService.castSession || !this.playerService.remotePlayer) {
      return;
    }

    // Contains information about the playing media including currentTime.
    const mediaStatus = this.playerService.castSession.getMediaSession();
    if (!mediaStatus) {
      return;
    }

    // mediaStatus also contains the mediaInfo containing metadata and other
    // information about the in progress content.
    const mediaInfo = mediaStatus.media as chrome.cast.media.MediaInfo;

    if (mediaInfo) {
      this.playerService.seekbar.setPosition(mediaStatus.getEstimatedTime());
      this.playerService.seekbar.setDuration(
        mediaInfo.duration >= 0
          ? mediaInfo.duration
          : mediaStatus.getEstimatedLiveSeekableRange()
          ? mediaStatus.getEstimatedLiveSeekableRange().end
          : 0,
      );
      this.visualModel.progressTime = `${SharedUtilityService.secondsToHHMMSS(
        this.playerService.seekbar.getPosition(),
      )} / ${SharedUtilityService.secondsToHHMMSS(this.playerService.seekbar.getDuration())}`;
    }

    this.detectChanges();
  }

  private mediaInfoChanged(): void {
    this.updateVisualModel();
    this.handleAds();
  }

  private setCastIconStyling(): void {
    const castIcon = document.getElementById('google-cast-launcher');
    if (this.detailPageOpen) {
      if (castIcon) {
        castIcon.classList.add('detail-open');
      }
    } else {
      if (castIcon) {
        castIcon.classList.remove('detail-open');
      }
    }
  }

  private handleReservedStream(): void {
    const stream: Stream2StartResponse = this.streamManagerService.transferReservedStreamToChromecast();
    this.playStream(stream);
  }

  private getChromecastStreamType(playInfo: PlayInfoModel): chrome.cast.media.StreamType {
    const now = new Date().getTime();
    return playInfo.streamIsLinear() ||
           (playInfo.streamType === StreamType.REPLAY &&
            SharedUtilityService.timeStringToMs(playInfo.scheduleInformation.published.end) > now)
      ? chrome.cast.media.StreamType.LIVE
      : chrome.cast.media.StreamType.BUFFERED;
  }

  private detectChanges(): void {
    // prevent change detection from firing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  private setupRemoterPlayer(): void {
    this.playerService.castSession = cast.framework.CastContext.getInstance().getCurrentSession();

    const castingTranslation = this.config.getTranslation(
      ChromecastTranslationKeys.casting_to_title_web,
    );
    const deviceName = this.playerService.getCastDeviceName();
    this.visualModel.castingToText =
      castingTranslation && deviceName ? castingTranslation.replace('$1', deviceName) : deviceName;

    // listen to media change events
    this.playerService.remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
      this.mediaInfoChanged.bind(this),
    );
    this.mediaInfoChanged();
  }

  private createMetaData(): chrome.cast.media.MovieMediaMetadata {
    const metaData = new chrome.cast.media.MovieMediaMetadata();
    const playInfo = this.playerService.currentPlayInfo;

    if (playInfo && playInfo.streamIsLinear()) {
      metaData.title = playInfo.channelInformation ? playInfo.channelInformation.name : '';
      metaData.images = [
        new chrome.cast.Image(ImageRecipeUtil.createImageUrl(playInfo.channelInformation.squareLogo, ImageRecipe.CHANNEL_SQUARE_1)),
      ];
    } else if (playInfo && playInfo.programInformation) {
      let result: string;
      metaData.title =
        playInfo && playInfo.programInformation ? playInfo.programInformation.title : '';

      if (
        playInfo &&
        playInfo.seasonInformation &&
        playInfo.seasonInformation.season &&
        playInfo.programInformation &&
        playInfo.programInformation.episode
      ) {
        result = this.config.getTranslation(RibbonTranslationKeys.ribbon_season_episode);
        result = result
          ? result
            .replace('$1', playInfo.seasonInformation.season.toString())
            .replace('$2', playInfo.programInformation.episode.toString())
          : '';
      } else if (playInfo.programInformation && playInfo.programInformation.episode) {
        result = this.config.getTranslation(RibbonTranslationKeys.ribbon_episode);
        result = result ? result.replace('$1', playInfo.programInformation.episode.toString()) : '';
      }

      if (result) {
        metaData.subtitle = result;
      }

      if (playInfo.programInformation.posterImage) {
        metaData.images = [
          new chrome.cast.Image(ImageRecipeUtil.createImageUrl(playInfo.programInformation.posterImage, ImageRecipe.EPG_CARD_3)),
        ];
      }
    } else if (playInfo.vodInformation) {
      metaData.title = playInfo.vodInformation.title;
      let result = '';

      if (playInfo.vodInformation.episode && playInfo.vodInformation.season) {
        result = this.config.getTranslation(RibbonTranslationKeys.ribbon_season_episode);
        result = result
          ? result
            .replace('$1', playInfo.vodInformation.season.toString())
            .replace('$2', playInfo.vodInformation.episode.toString())
          : '';
      } else if (playInfo.vodInformation.episode) {
        result = this.config.getTranslation(RibbonTranslationKeys.ribbon_episode);
        result = result ? result.replace('$1', playInfo.programInformation.episode.toString()) : '';
      }

      if (result) {
        metaData.subtitle = result;
      }

      if (playInfo.vodInformation.posterImage) {
        metaData.images = [
          new chrome.cast.Image(ImageRecipeUtil.createImageUrl(playInfo.vodInformation.posterImage, ImageRecipe.VOD_CARD_3)),
        ];
      }
    }

    return metaData;
  }

  private getAssetId(): string {
    const playInfo = this.playerService.currentPlayInfo;
    if (playInfo) {
      if (playInfo.streamIsVod() && playInfo.vodInformation) {
        return playInfo.vodInformation.id;
      } else if (playInfo.recordingInformation) {
        return playInfo.recordingInformation.identifier;
      } else if (playInfo.streamIsLinear() && playInfo.channelInformation) {
        return playInfo.channelInformation.id;
      } else if (playInfo.scheduleInformation) {
        return playInfo.scheduleInformation.id;
      }
    }

    return undefined;
  }

  private getAssetName(): string {
    const playInfo = this.playerService.currentPlayInfo;
    if (playInfo) {
      if (playInfo.streamIsVod() && playInfo.vodInformation) {
        return playInfo.vodInformation.title;
      } else if (playInfo.streamIsLinear() && playInfo.channelInformation) {
        return playInfo.channelInformation.name;
      } else if (playInfo.programInformation) {
        return playInfo.programInformation.title;
      } else if (playInfo.seriesInformation) {
        return playInfo.seriesInformation.title;
      }
    }

    return undefined;
  }

  private getBookmarkType(): CmsContentTypes {
    const playInfo = this.playerService.currentPlayInfo;

    switch (playInfo.streamType) {
      case StreamType.LINEAR:
        return CmsContentTypes.CHANNEL;
      case StreamType.REPLAY:
        return CmsContentTypes.PROGRAM;
      case StreamType.NPVR:
        return CmsContentTypes.RECORDING;
      case StreamType.FVOD:
        return CmsContentTypes.VOD;
      case StreamType.TVOD:
        return CmsContentTypes.VOD;
      case StreamType.SVOD:
        return CmsContentTypes.VOD;
      default:
        return undefined;
    }
  }

  private playStream(stream: Stream2StartResponse): void {
    const playInfo = this.playerService.currentPlayInfo;

    const mediaStatus = this.playerService.castSession.getMediaSession();

    const mediaInfo = new chrome.cast.media.MediaInfo(undefined, undefined);
    mediaInfo.metadata = this.createMetaData();
    mediaInfo.streamType = this.getChromecastStreamType(playInfo);

    let deviceId: string;
    if (
      mediaStatus &&
      mediaStatus.media &&
      mediaStatus.media.customData &&
      mediaStatus.media.customData.castDeviceId
    ) {
      deviceId = mediaStatus.media.customData.castDeviceId;
    }

    const requests: [Observable<string>, Observable<ValidQuotePriceResponse[]>] = [
      this.playerService.getRegisteredDeviceId(deviceId),
      of(null),
    ];

    // if vod request quotes for customer
    if (playInfo.streamIsVod()) {
      requests[1] = this.quoteCache.getQuotes(true).pipe(catchError(() => of(undefined)));
    }

    forkJoin(requests).subscribe((result) => {
      mediaInfo.customData = {
        streamType: playInfo.streamType,
        castDeviceId: result[0], //
      };

      const loadRequest = new chrome.cast.media.LoadRequest(mediaInfo);

      loadRequest.customData = this.createCustomData(stream, result[0], result[1]);
      loadRequest.autoplay = true;

      this.updateVisualModel();

      if (this.playerService.castSession) {
        this.playerService.castSession.loadMedia(loadRequest).then(
          () => {
            this.updateVisualModel();
          },
          () => {
            this.messagesService.showErrorMessage(
              this.config.getTranslation(ChromecastTranslationKeys.cast_general_failure),
            );
          },
        );
      }
    });
  }

  private handleAds(): void {
    if (
      !cast.framework.CastContext.getInstance().getCurrentSession() ||
      !cast.framework.CastContext.getInstance().getCurrentSession().getMediaSession()
    ) {
      this.stopAdInterval();
      return;
    }

    const mediaSession: any = cast.framework.CastContext.getInstance()
      .getCurrentSession()
      .getMediaSession();

    if (mediaSession.breakStatus && mediaSession.breakStatus.breakId) {
      if (!this.currentAdvertisement) {
        this.currentAdvertisement = { whenSkippable: mediaSession.breakStatus.whenSkippable };
      }

      if (!this.adIntervalSubscription) {
        this.adIntervalSubscription = interval(100).subscribe(() => {
          this.currentAdvertisement.currentTime = mediaSession.getEstimatedBreakClipTime();
          this.detectChanges();
        });
      }
    } else {
      if (this.currentAdvertisement) {
        this.currentAdvertisement = undefined;
      }

      this.stopAdInterval();
    }
  }

  private stopAdInterval(): void {
    if (this.adIntervalSubscription) {
      this.adIntervalSubscription.unsubscribe();
      this.adIntervalSubscription = undefined;
    }
  }

  private updateVisualModel(): void {
    // Use the current session to get an up to date media status.

    if (!this.playerService.castSession) {
      this.visualModel.playingState = PlayingStates.NONE;
      this.visualModel.reset();
      this.detectChanges();
      return;
    }

    // Contains information about the playing media including currentTime.
    const mediaStatus = this.playerService.castSession.getMediaSession() as chrome.cast.media.Media;
    if (!mediaStatus) {
      this.visualModel.playingState = PlayingStates.NONE;
      this.visualModel.reset();
      this.detectChanges();
      return;
    }

    // mediaStatus also contains the mediaInfo containing metadata and other
    // information about the in progress content.
    const mediaInfo = mediaStatus.media;

    if (mediaInfo.metadata) {
      this.visualModel.title = mediaInfo.metadata.title;
      this.visualModel.subtitle = mediaInfo.metadata.subtitle;
      this.visualModel.image =
        mediaInfo.metadata.images && mediaInfo.metadata.images.length > 0
          ? mediaInfo.metadata.images[0].url
          : undefined;
    }

    this.visualModel.isLive = (mediaInfo?.customData as any)?.streamType === StreamType.LINEAR;

    this.visualModel.allowSeeking =
      !this.visualModel.isLive &&
      mediaStatus.supportedMediaCommands?.includes(chrome.cast.media.MediaCommand.SEEK);

    // update atv-player controller state
    switch (mediaStatus.playerState) {
      case chrome.cast.media.PlayerState.PAUSED:
        this.visualModel.playingState = PlayingStates.PAUSED;
        break;
      case chrome.cast.media.PlayerState.PLAYING:
        this.visualModel.playingState = PlayingStates.PLAYING;
        break;
      case chrome.cast.media.PlayerState.BUFFERING:
        this.visualModel.playingState = PlayingStates.BUFFERING;
        break;
      default:
        this.visualModel.playingState = PlayingStates.NONE;
        this.visualModel.reset();
    }
    this.detectChanges();
  }

  private createCustomData(
    stream: Stream2StartResponse,
    deviceId: string,
    quotes: ValidQuotePriceResponse[],
  ): CustomDataModel {
    const playInfo = this.playerService.currentPlayInfo;

    return {
      customerId: this.sessionService.getCustomer(),

      assetId: this.getAssetId(),
      streamId: stream.sid,
      streamType: playInfo.streamType,
      trailer: playInfo.isTrailer,
      keepaliveInterval: stream.keepalive,
      resumeFromBookmark: !!playInfo.bookmarkPos,

      resourceId: playInfo.streamAssetId,
      quoteId: playInfo.quoteId,
      schedulePublishedStart:
        playInfo && playInfo.scheduleInformation && playInfo.scheduleInformation.published
          ? SharedUtilityService.timeStringToMs(playInfo.scheduleInformation.published.start)
          : undefined,
      schedulePublishedEnd:
        playInfo && playInfo.scheduleInformation && playInfo.scheduleInformation.published
          ? SharedUtilityService.timeStringToMs(playInfo.scheduleInformation.published.end)
          : undefined,

      bookmarkType: this.getBookmarkType(),

      quotes,
      entitlementId: this.sessionService.getEntitlementId(),

      logInfo: {
        userId: this.sessionService.getLoginContext().userId,
        profileId: this.sessionService.getLoginContext().profileId,
        catalogId: this.sessionService.getLoginContext().catalogId,
        deviceId,

        assetName: this.getAssetName(), // title of asset VOD/SERIES/PROGRAM
        scheduleId:
          playInfo && playInfo.scheduleInformation ? playInfo.scheduleInformation.id : undefined,
        programId:
          playInfo && playInfo.programInformation ? playInfo.programInformation.id : undefined,
        channelId:
          playInfo && playInfo.channelInformation ? playInfo.channelInformation.id : undefined,
        channelName:
          playInfo && playInfo.channelInformation ? playInfo.channelInformation.name : undefined,
        seasonNumber:
          playInfo && playInfo.seasonInformation ? playInfo.seasonInformation.season : undefined,
        episodeNumber:
          playInfo && playInfo.programInformation ? playInfo.programInformation.episode : undefined,
      },
    };
  }

  ngOnDestroy(): void {
    // stop casting / disconnect chromecast
    this.stopCasting();
    this.playerService.remotePlayerConnected = false;
  }
}
