import {
  QuoteTypes,
  StreamPriceResponse,
  ValidQuotePriceResponse,
  VodPriceBundleResponse,
  VodPriceResponse,
  VodUpsellProduct,
} from '@atv-core/api/pricing/pricing.model.service';
import { VodAssetStreamType } from '@atv-core/api/vod';
import { Stb } from '@atv-core/services/session/stb';
import { StreamResolutionType, StreamType } from '@atv-core/utility/constants/shared';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';

export class OttStream {
  stream: StreamPriceResponse;
  validQuotes: ValidQuotePriceResponse[] = [];

  public selectValidQuote() {
    // first quote that is usable
    return this.validQuotes[0];
  }

  public getStreamId() {
    return this.stream ? this.stream.streamId : undefined;
  }

  public getStreamType() {
    return this.stream ? this.stream.type : undefined;
  }

  constructor(stream: StreamPriceResponse, validQuotes?: ValidQuotePriceResponse[]) {
    this.stream = stream;
    this.validQuotes = validQuotes;
  }
}
class UpsellStb {
  stb: Stb;

  constructor(stb: Stb) {
    this.stb = stb;
  }
}

export class StbStream {
  stream: StreamPriceResponse;
  validQuotes: ValidQuotePriceResponse[] = [];

  stb: Stb;
  upsell: boolean = undefined;

  public getStreamType() {
    return this.stream ? this.stream.type : undefined;
  }

  constructor(
    stream: StreamPriceResponse,
    validQuotes: ValidQuotePriceResponse[],
    stb: Stb,
    upsell?: boolean
  ) {
    this.stream = stream;
    this.validQuotes = validQuotes;
    this.stb = stb;
    this.upsell = upsell;
  }
}

export class VodStreamRightsModel {
  [x: string]: any;
  vodId: string;

  stbs: Stb[] = [];
  ottPrice: VodPriceResponse;
  stbPrices: VodPriceResponse[];
  quotes: ValidQuotePriceResponse[];

  trailerStreams: StreamPriceResponse[] = [];
  watchableOttStreams: OttStream[] = []; // watchable because rented vod or vod is contained in bundel
  watchableStbStreams: StbStream[] = [];
  upsellStbs: UpsellStb[] = [];
  rentableOttStreams: OttStream[] = [];
  upsell: VodUpsellProduct;

  constructor(
    vodId: string,
    quotes: ValidQuotePriceResponse[],
    ottPrice: VodPriceResponse,
    stbPrices?: VodPriceResponse[],
    stbs?: Stb[]
  ) {
    this.vodId = vodId;
    this.quotes = quotes || [];
    this.ottPrice = ottPrice;
    this.stbPrices = stbPrices || [];
    this.stbs = stbs || [];

    this.calculateStreamRights();
  }

  public calculateStreamRightsForNewQuotes(quotes: ValidQuotePriceResponse[]) {
    this.trailerStreams = [];
    this.watchableOttStreams = [];
    this.watchableStbStreams = [];
    this.upsellStbs = [];
    this.rentableOttStreams = [];
    this.upsell = undefined;

    this.quotes = quotes;

    this.calculateStreamRights();
  }

  private calculateStreamRights() {
    this.checkForTrailerStreams(this.ottPrice);

    this.checkForPrice(this.quotes, this.ottPrice, true);

    this.stbPrices.forEach((stbPrice, index) => {
      this.checkForPrice(this.quotes, stbPrice, false, this.stbs[index]);
    });
  }

  public getWatchableStbStream(stbId: string): StbStream {
    // select correctWatchablestream (eg. highest resolution , longest validity)
    const stbStreams = this.watchableStbStreams.filter((str) => str.stb && str.stb.id === stbId);

    let stream: StbStream = stbStreams.find(
      (str) => str.stream.resolution === StreamResolutionType.FOURK,
    );

    if (stream) {
      return stream;
    }
    stream = stbStreams.find((str) => str.stream.resolution === StreamResolutionType.HD);
    if (stream) {
      return stream;
    }

    stream = stbStreams.find((str) => str.stream.resolution === StreamResolutionType.SD);
    return stream;
  }

  public ottStreamsLeftToRent() {
    return this.rentableOttStreams !== undefined && this.rentableOttStreams.length !== 0;
  }

  public isUpsellStb(stbId: string) {
    return this.upsellStbs.some((stbStream) => stbStream.stb && stbStream.stb.id === stbId);
  }

  public getWatchableOttStream(): OttStream {
    let stream: OttStream = this.watchableOttStreams.find(
      (str) => str.stream.resolution === StreamResolutionType.FOURK,
    );
    if (stream) {
      return stream;
    }
    stream = this.watchableOttStreams.find(
      (str) => str.stream.resolution === StreamResolutionType.HD,
    );
    if (stream) {
      return stream;
    }

    stream = this.watchableOttStreams.find(
      (str) => str.stream.resolution === StreamResolutionType.SD,
    );
    return stream;
  }

  public getUniqueStbsFromWatchabelStbStreamsAndUpsellStbsStreams(): Stb[] {
    const uniqueStbs = {};
    this.watchableStbStreams.forEach((stream) => {
      if (stream.stb) {
        uniqueStbs[stream.stb.id] = stream.stb;
      }
    });

    this.upsellStbs.forEach((stream) => {
      if (stream.stb) {
        uniqueStbs[stream.stb.id] = stream.stb;
      }
    });

    return Object.values(uniqueStbs);
  }

  public hasWatchableOttSVOD() {
    return (
      this.canWatch() &&
      this.watchableOttStreams.some(
        (ottStream) => ottStream.stream && ottStream.stream.type === StreamType.SVOD,
      )
    );
  }

  public hasWatchableOttTVOD() {
    return (
      this.canWatch() &&
      this.watchableOttStreams.some(
        (ottStream) => ottStream.stream && ottStream.stream.type === StreamType.TVOD,
      )
    );
  }

  public hasWatchableOttFVOD() {
    return (
      this.canWatch() &&
      this.watchableOttStreams.some(
        (ottStream) => ottStream.stream && ottStream.stream.type === StreamType.FVOD,
      )
    );
  }

  public hasTrailer(): boolean {
    return this.trailerStreams.length > 0;
  }

  public canRent(): boolean {
    return this.rentableOttStreams.length > 0;
  }

  public canWatch(): boolean {
    return (
      this.watchableOttStreams.length > 0 ||
      (!SharedUtilityService.isSmartTv() && (this.watchableStbStreams.length > 0 || this.upsellStbs.length > 0))
    );
  }

  public hasBundles(): boolean {
    return (
      (this.ottPrice.bundles && this.ottPrice.bundles.length > 0) ||
      this.stbPrices.some((stbPrice) => stbPrice.bundles && stbPrice.bundles.length > 0)
    );
  }

  public isWatchableBecauseOfBundle() {
    return (
      this.canWatch() &&
      this.watchableOttStreams.some((ottStream) =>
        ottStream.validQuotes.some((quote) => quote.quoteType === QuoteTypes.BUNDLE),
      )
    );
  }

  public hasWatchableStbSVOD() {
    return (
      this.canWatch() &&
      this.watchableStbStreams.some(
        (stbStream) => stbStream.stream && stbStream.stream.type === StreamType.SVOD,
      )
    );
  }

  private getValidQuotesForTVODS(
    allStreams: StreamPriceResponse[],
    stream: StreamPriceResponse,
    quotes: ValidQuotePriceResponse[],
    bundles: VodPriceBundleResponse[],
  ): ValidQuotePriceResponse[] {
    const now = new Date().getTime();
    // find tvod quote valid for current quality or quote quality higher than current stream quality
    const tvodQuotes = quotes.filter(
      (quote) =>
        quote.quoteType === QuoteTypes.TVOD &&
        quote.entryId === this.vodId &&
        (quote.assetId === stream.streamId ||
          allStreams.some(
            (otherStream) =>
              quote.assetId === otherStream.streamId &&
              SharedUtilityService.resolutionTypeToNumber(otherStream.resolution) >
                SharedUtilityService.resolutionTypeToNumber(stream.resolution)
          )) &&
        SharedUtilityService.timeStringToMs(quote.startTime) < now &&
        SharedUtilityService.timeStringToMs(quote.endTime) > now
    );

    let bundleQuotes = [];

    if (bundles) {
      bundles.forEach((bundle) => {
        if (bundle.resolutions) {
          // select filtered quotes for this bundle, for this stream
          const filteredQuotes = quotes.filter(
            (quote) =>
              quote.entryId === bundle.bundleId &&
              SharedUtilityService.timeStringToMs(quote.endTime) > now &&
              bundle.resolutions.some(
                (resolution) =>
                  quote.assetId === resolution.id &&
                  SharedUtilityService.resolutionTypeToNumber(resolution.resolution) >=
                    SharedUtilityService.resolutionTypeToNumber(stream.resolution)
              )
          );
          if (filteredQuotes) {
            bundleQuotes = bundleQuotes.concat(filteredQuotes);
          }
        }
      });
    }

    return tvodQuotes.concat(bundleQuotes);
  }

  private checkForPrice(
    quotes: ValidQuotePriceResponse[],
    price: VodPriceResponse,
    forOtt: boolean,
    stb?: Stb,
  ) {
    // Set upsell information
    if (!this.upsell || (price.upsell && price.upsell.preference > this.upsell.preference)) {
      this.upsell = price.upsell;
    }

    if (price.streams && price.streams.length > 0) {
      price.streams.forEach((stream) => {
        if (stream.streamType === VodAssetStreamType.FULL) {
          const validQuotes: ValidQuotePriceResponse[] = this.getValidQuotesForTVODS(
            price.streams,
            stream,
            quotes,
            price.bundles,
          );

          // stream is watchable if:
          if (
            // vod is free
            stream.type === StreamType.FVOD ||
            // or there is free higher quality
            price.streams.some(
              (str) =>
                str.streamType === VodAssetStreamType.FULL &&
                str.type === StreamType.FVOD &&
                SharedUtilityService.resolutionTypeToNumber(str.resolution) >
                SharedUtilityService.resolutionTypeToNumber(stream.resolution),
            ) ||
            // or it is an svod
            stream.type === StreamType.SVOD ||
            // or there is a valid quote
            validQuotes.length > 0 ||
            // or check for stb also is watchable is stb has upsell info
            (forOtt ? false : this.upsell !== undefined)
          ) {
            if (forOtt) {
              this.watchableOttStreams.push(new OttStream(stream, validQuotes));
            } else {
              this.watchableStbStreams.push(new StbStream(stream, validQuotes, stb));
            }

            // if on ott no valid quotes for steam and it is a TVOD, we can rent this stream
          } else if (forOtt && validQuotes.length === 0 && stream.type === StreamType.TVOD) {
            this.rentableOttStreams.push(new OttStream(stream));
          }
        }
      });
    }
    if (stb && price.upsell) {
      this.upsellStbs.push(new UpsellStb(stb));
    }

    this.rentableOttStreams.sort((a, b) => {
      if (
        SharedUtilityService.resolutionTypeToNumber(a.stream.resolution) >
        SharedUtilityService.resolutionTypeToNumber(b.stream.resolution)
      ) {
        return -1;
      } else if (
        SharedUtilityService.resolutionTypeToNumber(a.stream.resolution) <
        SharedUtilityService.resolutionTypeToNumber(b.stream.resolution)
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  private checkForTrailerStreams(ottPrice: VodPriceResponse) {
    if (ottPrice.streams) {
      ottPrice.streams.forEach((stream) => {
        if (
          stream.streamType === VodAssetStreamType.TRAILER &&
          (stream.type === StreamType.FVOD || stream.type === StreamType.SVOD)
        ) {
          this.trailerStreams.push(stream);
        }
      });
    }
  }

  public hasUpsell() {
    return this.canWatch() && (this.upsell !== undefined || this.upsellStbs.length > 0);
  }

  public getFullVodStreams(): StreamPriceResponse[] {
    let fullVodStreams: StreamPriceResponse[] = [];

    if (this.ottPrice && this.ottPrice.streams) {
      fullVodStreams = fullVodStreams.concat(
        this.ottPrice.streams.filter((stream) => stream.streamType === VodAssetStreamType.FULL)
      );
    }

    if (this.stbPrices) {
      this.stbPrices.forEach((stbPrice) => {
        if (stbPrice && stbPrice.streams) {
          fullVodStreams = fullVodStreams.concat(
            stbPrice.streams.filter((stream) => stream.streamType === VodAssetStreamType.FULL)
          );
        }
      });
    }

    return fullVodStreams;
  }

  public getLatestValiQuoteForType(quoteType: QuoteTypes): ValidQuotePriceResponse {
    const quotesOfType: ValidQuotePriceResponse[] = [];

    this.watchableOttStreams.forEach((ottStream) =>
      ottStream.validQuotes.forEach((quote) => {
        if (quote.quoteType === quoteType) {
          quotesOfType.push(quote);
        }
      })
    );
    return SharedUtilityService.getLastValidQuote(quotesOfType);
  }

  public getBundles(): VodPriceBundleResponse[] {
    let ottBundles: VodPriceBundleResponse[] = [],
      stbBundles: VodPriceBundleResponse[] = [];
    ottBundles = this.ottPrice && this.ottPrice.bundles ? this.ottPrice.bundles : [];
    this.stbPrices.forEach((stbPrice) =>
      stbPrice.bundles && stbPrice.bundles.length > 0
        ? (stbBundles = stbBundles.concat(stbPrice.bundles))
        : undefined
    );

    // filter unique stb
    stbBundles = stbBundles.filter(
      (stbBundle, index) =>
        index === stbBundles.findIndex((bundle) => bundle.bundleId === stbBundle.bundleId)
    );

    // filter unique between stb and ott
    return stbBundles
      .filter(
        (stbBundle) => !ottBundles.some((ottBundle) => ottBundle.bundleId === stbBundle.bundleId)
      )
      .concat(ottBundles);
  }

  public getBundle(bundleId): VodPriceBundleResponse {
    let bundle;
    if (this.ottPrice && this.ottPrice.bundles) {
      bundle = this.ottPrice.bundles.find((ottBundle) => ottBundle.bundleId === bundleId);
    }
    if (!bundle) {
      this.stbPrices.find((stbPrice) => {
        bundle = stbPrice.bundles.find((stbBundle) => stbBundle.bundleId === bundleId);
        if (bundle) {
          return true;
        } else {
          return false;
        }
      });
    }

    return bundle;
  }

  public getLatestValidQuoteForBundle(bundleId) {
    const quotesOfTypeForBundle: ValidQuotePriceResponse[] = [];

    this.watchableOttStreams.forEach((ottStream) =>
      ottStream.validQuotes.forEach((quote) => {
        if (quote.quoteType === QuoteTypes.BUNDLE && quote.entryId === bundleId) {
          quotesOfTypeForBundle.push(quote);
        }
      })
    );

    return SharedUtilityService.getLastValidQuote(quotesOfTypeForBundle);
  }
}
