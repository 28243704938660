import { CardOrientation, CardSize, CmsAction, Properties } from '@atv-core/api/cms/cms-api.model';
import { AdultMode } from '@atv-core/services/adult';

export class CardModel {
  orientation: CardOrientation;
  size: CardSize;
  image: string;
  channelImage: string;

  progressPercentage: number;
  scheduleText: string; // only for epg
  row1: string;
  row1Styling: CardRowStyling[] = [];
  row2: string;
  row2Styling: CardRowStyling[] = [];
  icons: string[] = [];
  action: CmsAction;
  favoriteIcon?: string;

  constructor(orientation: CardOrientation, size: CardSize) {
    this.orientation = orientation;
    this.size = size;
  }
}

export interface CardConfig {
  ribbonProperties: Properties[];
  pageProperties: Properties[] ;
  allowedAdultMode: AdultMode;
  useOldEpgCardContent: boolean;
  showInfo: boolean;
}

export enum CardRowStyling {
  BOLD = 'bold',
  PRIMARY_COLOR = 'primary-color',
  SMALL_FONT = 'small-font',
}
