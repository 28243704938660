import { ContentProvider } from '@atv-shared/content-provider/content-provider';
import { forkJoin, Observable } from 'rxjs';
import { CardOrientation, Ribbon2Asset } from '@atv-core/api/cms';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { ContentProviderFactoryService } from '@atv-shared/content-provider/content-provider-factory.service';
import { FavoriteModel, FavoriteQueryTypes } from '@atv-core/services/cache/favorite/favorite.model';
import { ChannelCardModes } from '@atv-core/api/epg';
import { FavoriteCacheService } from '@atv-core/services/cache/favorite';
import { FavoriteCacheType, FavoriteContentTypes } from '@atv-core/api/history/history-api.model';
import { switchMap, tap } from 'rxjs/operators';
import { ChannelCacheService } from '@atv-core/services/cache/channel';

export class FavoriteCacheContentProvider extends ContentProvider {
  private readonly type: FavoriteCacheType;
  private readonly queryType?: FavoriteQueryTypes;
  private readonly channelCardMode?: ChannelCardModes;
  private hasMoreItems = true;

  constructor(
    private url: string,
    private favoriteCacheService: FavoriteCacheService,
    private allowedAdultMode: AdultMode,
    private adultService: AdultService,
    private contentProviderFactory: ContentProviderFactoryService,
    private channelCacheService: ChannelCacheService,
  ) {
    super();

    this.url = url.toLowerCase();
    for (const t in FavoriteCacheType) {
      if (this.url.includes(t.toLowerCase())) {
        this.type = FavoriteCacheType[t];
        break;
      }
    }

    if (this.type === FavoriteCacheType.CHANNEL) {
      for (const t in ChannelCardModes) {
        if (this.url.includes(t.toLowerCase())) {
          this.channelCardMode = ChannelCardModes[t];
          break;
        }
      }

      if (!this.channelCardMode) {
        this.channelCardMode = ChannelCardModes.NONE;
      }
    } else if (this.type === FavoriteCacheType.EPG) {
      for (const t in FavoriteQueryTypes) {
        if (this.url.includes(t.toLowerCase())) {
          this.queryType = FavoriteQueryTypes[t];
          break;
        }
      }
    }

    this.orientation = this.type === FavoriteCacheType.VOD ? CardOrientation.PORTRAIT : CardOrientation.LANDSCAPE;
  }

  public getItems(): Observable<Ribbon2Asset[]> {
    // load favorites, then convert to Ribbon2Asset
    return this.channelCacheService.getChannels()
      .pipe(
        switchMap(channels => this.favoriteCacheService.getFavoritesForType(this.type, this.queryType, channels,
          this.adultService.showAdult(this.allowedAdultMode))
          .pipe(
            tap(() => this.hasMoreItems = false),
            switchMap(items => forkJoin(items.map(i => this.favoriteToAsset(i))))),
        ),
      );
  }

  public hasNext(): boolean {
    return this.hasMoreItems;
  }

  public resetPaging(): void {
    this.hasMoreItems = true;
  }

  private favoriteToAsset(favorite: FavoriteModel): Observable<Ribbon2Asset> {
    switch (favorite.type) {
      case FavoriteContentTypes.PROGRAM:
        return this.contentProviderFactory.programToRibbon2Asset(favorite.id);
      case FavoriteContentTypes.CHANNEL:
        // no need to fetch schedules if it's a favorite toggle or channel logo card
        return this.contentProviderFactory.channelToRibbon2Asset(favorite.id, this.channelCardMode === ChannelCardModes.NONE);
      case FavoriteContentTypes.SERIES:
        return this.contentProviderFactory.seriesToRibbon2Asset(favorite.id);
      case FavoriteContentTypes.VOD:
        return this.contentProviderFactory.vodToRibbon2Asset(favorite.id);
      default:
        throw new Error(`invalid favorite type ${favorite.type}`);
    }
  }

  public getChannelCardMode(): ChannelCardModes {
    return this.channelCardMode;
  }
}
