import { ChannelModel } from '@atv-core/services/cache/channel';

export enum FilterContentTypes {
  EPG = 'epg',
  VOD_SERIE = 'series',
  VOD_MOVIE = 'movies',
  ADULT = 'adult'
}

export enum ContentFilters {
  CHANNEL = 'channel',
  DAY = 'day',
  CATEGORY = 'tag',
  SCORE = 'score',
  YEAR = 'year'
}

export enum CategoryFilterModes {
  EPG,
  VOD
}

export class FilterModel {
  activeCmsFilters: ContentFilters[] = [];

  filterInputChannels: ChannelModel[] = undefined;
  filterDays: Date[] = [];
  filterScores = [1, 2, 3, 4];

  categoryFilterRoot: string[] = [];
  categoryFilterMode: CategoryFilterModes = CategoryFilterModes.EPG;

  channelFilterOutputIndex: number = undefined;
  dayFilterOutputIndex: number = undefined;

  categoryFilterOutputTags: string[] = undefined;
  currentCategoryFilterIsAdult: boolean = false;
  scoreFilterOutputValue: number = undefined;
  yearFilterOutputValue: number = undefined;

  public areFiltersActive(): boolean {
    if (
      this.activeCmsFilters.includes(ContentFilters.CHANNEL) &&
      this.channelFilterOutputIndex !== undefined
    ) {
      return true;
    }

    if (
      this.activeCmsFilters.includes(ContentFilters.DAY) &&
      this.dayFilterOutputIndex !== undefined
    ) {
      return true;
    }

    if (
      this.activeCmsFilters.includes(ContentFilters.CATEGORY) &&
      this.categoryFilterOutputTags !== undefined &&
      this.categoryFilterOutputTags.length > 0
    ) {
      return true;
    }

    if (
      this.activeCmsFilters.includes(ContentFilters.SCORE) &&
      this.scoreFilterOutputValue !== undefined
    ) {
      return true;
    }

    if (
      this.activeCmsFilters.includes(ContentFilters.YEAR) &&
      this.yearFilterOutputValue !== undefined
    ) {
      return true;
    }

    return false;
  }
}
