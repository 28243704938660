import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-content-backdrop',
  templateUrl: './content-backdrop.component.html',
  styleUrls: ['./content-backdrop.component.scss'],
})

export class ContentBackdropComponent {
  @ViewChild('image1') image1: ElementRef<HTMLImageElement>;
  @ViewChild('image2') image2: ElementRef<HTMLImageElement>;

  public defaultImageUrl = './assets/theme/images/default-content-backdrop.jpg';
  private currentUsedImageElement?: HTMLImageElement;
  private currentImage?: string;

  @Input()
  set image(image: string) {
    if (image === this.currentImage || !this.image1 || !this.image2) {
      return;
    }

    image = image || this.defaultImageUrl;

    const currentImageElement = this.currentUsedImageElement === this.image1.nativeElement ? this.image1.nativeElement :
      this.image2.nativeElement;
    const nextImageElement = this.currentUsedImageElement === this.image1.nativeElement ? this.image2.nativeElement :
      this.image1.nativeElement;

    // onload event does not fire when the image has already been downloaded before in the image element
    // to work around this, we use a new image element
    const img = new Image();
    img.onload = () => {
      nextImageElement.srcset = image;
      nextImageElement.style.opacity = '1';
      currentImageElement.style.opacity = '0';
      this.currentImage = image;
      this.currentUsedImageElement = nextImageElement;
      img.onload = undefined;
    };

    img.srcset = image;
  }

}
