export interface ReminderWorkerMessage {
  type: ReminderWorkerMessageTypes;
  items: ReminderWorkerMessageItem[];
}

export interface ReminderWorkerMessageItem {
  title: string;
  body: string;
  time: number;
}

export enum ReminderWorkerMessageTypes {
  RENEW,
  CLEAR,
  NOTIFY,
  ADD
}
