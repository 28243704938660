import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';

enum ErrorCodes {
  OAUTH_TOKEN_NOT_FOUND = 'OAUTH_TOKEN_NOT_FOUND',
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private activeHeadlessLogin: Observable<unknown>;

  constructor(private auth: AuthorizationService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((errorResponse) => {
        if (
          errorResponse instanceof HttpErrorResponse &&
          errorResponse.status === 401 &&
          this.auth.isAuthorized() &&
          errorResponse.error.code === ErrorCodes.OAUTH_TOKEN_NOT_FOUND
        ) {
          if (!this.activeHeadlessLogin) {
            this.activeHeadlessLogin = SharedUtilityService.isSmartTv() ? this.auth.tryRefreshTokenLogin() : this.auth.tryHeadlessLogin();
          }

          return this.activeHeadlessLogin.pipe(
            mergeMap(() => {
              this.activeHeadlessLogin = undefined;
              return next.handle(
                req.clone({
                  setHeaders: {
                    Authorization: this.auth.getAuthorization(),
                  },
                })
              );
            }),
            catchError(() => {
              if (!SharedUtilityService.isSmartTv()) {
                location.reload();
                this.activeHeadlessLogin = undefined;
              }
              throw errorResponse;
            })
          );
        } else {
          throw errorResponse;
        }
      })
    );
  }
}
