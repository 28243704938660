export enum LocalStorageKeys {
  client_events_buffer = 'client_events_buffer',
  client_events_failed_buffer = 'client_events_failed_buffer',

  preferred_video_rendition = 'preferred_video_rendition',
  preferred_audio_language = 'preferred_audio_language',
  preferred_audio_channels = 'preferred_audio_channels',
  preferred_text_track = 'preferred_text_track',
  player_volume = 'player_volume',
  player_muted = 'player_muted',
  player_last_channel = 'player_last_channel',

  ACTIVE_TAB_BEACON = 'ACTIVE_TAB_BEACON',

  version_current = 'version_current',
  version_check_time = 'version_check_time',

  anonymous_device_id = 'anonymous_device_id',

  oauth_refresh_token = 'oauth_refresh_token',
  oauth_refresh_token_expiration = 'oauth_refresh_token_expiration',
}
