import { ContentProvider } from '@atv-shared/content-provider/content-provider';
import { ReminderCacheService } from '@atv-core/services/cache/reminder/reminder-cache.service';
import { ContentProviderFactoryService } from '@atv-shared/content-provider/content-provider-factory.service';
import { CardOrientation, Ribbon2Asset } from '@atv-core/api/cms';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

export class ReminderCacheContentProvider extends ContentProvider {
  private hasMoreItems = true;

  constructor(
    private reminderCache: ReminderCacheService,
    private contentProviderFactory: ContentProviderFactoryService,
  ) {
    super();

    this.orientation = CardOrientation.LANDSCAPE;
  }

  public hasNext(): boolean {
    return this.hasMoreItems;
  }

  public resetPaging(): void {
    this.hasMoreItems = true;
  }

  public getItems(): Observable<Ribbon2Asset[]> {
    return this.reminderCache.getReminders().pipe(tap(() => this.hasMoreItems = false),
      switchMap(items => forkJoin(items.map(i => of(this.contentProviderFactory.scheduleToRibbon2Asset(i.schedule))))));
  }
}
