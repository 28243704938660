import { EventEmitter, Injectable } from '@angular/core';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { SettingsKeys } from '@atv-bootstrap/services/config';
import { ConfigService } from '@atv-bootstrap/services/config/config.service';
import { SessionService } from '@atv-core/services/session';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

import { PricingApiService } from './../../../api/pricing/pricing-api.service';
import { ValidQuotePriceResponse } from './../../../api/pricing/pricing.model.service';
import { CacheStorage } from './../cacheStorage';

@Injectable({
  providedIn: 'root',
})
export class QuoteCacheService {
  private reloadingCache = false;
  private cache = new CacheStorage();
  private cachePromise: Observable<any> = undefined;
  private expirationTime = 0;

  public quoteUpdateEvent = new EventEmitter<ValidQuotePriceResponse[]>();

  constructor(
    private config: ConfigService,
    private pricingApi: PricingApiService,
    private auth: AuthorizationService,
    private sessionService: SessionService
  ) {
    this.sessionService.clearAllCachesEvent.subscribe(() => {
      this.clearCache();
    });
  }

  private chechkCache(): void {
    if (new Date().getTime() > this.expirationTime) {
      this.clearCache();
    }

    this.cachePromise = this.cachePromise || this.fillCache();
  }

  private fillCache(): Observable<void> {
    if (!this.auth.isAuthorized()) {
      return of(undefined);
    }

    return this.pricingApi.getQuotes().pipe(
      switchMap((result) => {
        if (!result) {
          return of(undefined);
        }
        this.expirationTime =
          new Date().getTime() +
          this.config.getSettingNumber(SettingsKeys.quoteCacheExpiration, 120000);

        result.forEach((item) => {
          this.cache.put(item.quoteId, item);
        });

        this.reloadingCache = false;
        return of(undefined);
      }),
      catchError((e) => {
        this.expirationTime = 0;
        this.reloadingCache = false;
        throw e;
      }),
      shareReplay()
    );
  }

  public addQuote(quote: ValidQuotePriceResponse) {
    this.cache.put(quote.quoteId, quote);
    this.quoteUpdateEvent.emit(this.cache.values());
  }

  public getQuotes(reload = false): Observable<ValidQuotePriceResponse[]> {
    if (reload) {
      this.clearCache();
    }
    this.chechkCache();

    return this.cachePromise.pipe(
      map(() => {
        if (reload) {
          this.quoteUpdateEvent.emit(this.cache.values());
        }
        return this.cache.values();
      })
    );
  }

  private clearCache() {
    if (this.reloadingCache) {
      return;
    }
    this.cache = new CacheStorage();
    this.expirationTime = 0;
    this.cachePromise = undefined;
    this.reloadingCache = true;
  }
}
