import { Platform } from '@angular/cdk/platform';
import { CommonModule, Location } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CategoryFilterComponent } from '@atv-shared/filters/category-filter/category-filter.component';
import { ChannelFilterComponent } from '@atv-shared/filters/channel-filter/channel-filter.component';
import { DayFilterComponent } from '@atv-shared/filters/day-filter/day-filter.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { ListPageFiltersComponent } from '@atv-pages/list-page/list-page-filters/list-page-filters.component';
import { BannerComponent } from './banner/banner.component';
import { BundleButtonComponent } from './buttons/bundle-button/bundle-button.component';
import { CancelRecordingComponent } from './buttons/cancel-recording/cancel-recording.component';
import { CreateEditRecordingComponent } from './buttons/create-edit-recording/create-edit-recording.component';
import { DeleteProfileComponent } from './buttons/delete-profile/delete-profile.component';
import { DeleteRecordingComponent } from './buttons/delete-recording/delete-recording.component';
import { MoreSmarttvComponent } from './buttons/more-smarttv/more-smarttv.component';
import { RentButtonComponent } from './buttons/rent-button/rent-button.component';
import { TrailerButtonComponent } from './buttons/trailer-button/trailer-button.component';
import { WatchButtonComponent } from './buttons/watch-button/watch-button.component';
import { ContentBackdropComponent } from './content-backdrop/content-backdrop.component';
import { CenterOnFocusDirective } from './directives/center-on-focus/center-on-focus.directive';
import { SmartTvKeyDownEnterDirective } from './directives/smarttv-key-down-enter/smarttv-key-down-enter.directive';
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { ClearFilterComponent } from './filters/clear-filter/clear-filter.component';
import { GuideCategoryFilterComponent } from './filters/guide-category-filter/guide-category-filter.component';
import { ScoreFilterComponent } from './filters/score-filter/score-filter.component';
import { StbFilterComponent } from './filters/stb-filter/stb-filter.component';
import { YearFilterComponent } from './filters/year-filter/year-filter.component';
import { GenericCardComponent } from './generic-card/generic-card.component';
import { GridComponent } from './grid/grid.component';
import { ChannelScheduleComponent } from './guide/channel-schedule/channel-schedule.component';
import { ChannelComponent } from './guide/channel/channel.component';
import { DaySelectorElementComponent } from './guide/day-selector/day-selector-element/day-selector-element.component';
import { DaySelectorSmarttvComponent } from './guide/day-selector/day-selector-smarttv/day-selector-smarttv.component';
import { DaySelectorComponent } from './guide/day-selector/day-selector.component';
import { ScheduleComponent } from './guide/schedule/schedule.component';
import { TimelinePointComponent } from './guide/timeline/timeline-point/timeline-point.component';
import { TimelineComponent } from './guide/timeline/timeline.component';
import { MessagesComponent } from './message/messages.component';
import { MiniDetailComponent } from './mini-detail/mini-detail.component';
import { NumberEntryComponent } from './number-entry/number-entry.component';
import { AdultPopupComponent } from './popups/adult-popup/adult-popup.component';
import { AnonymousCatalogSelectPopupComponent } from './popups/anonymous-catalog-select-popup/anonymous-catalog-select-popup.component';
import { AuthPopupComponent } from './popups/auth-popup/auth-popup.component';
import { DeleteAllRecordingsPopupComponent } from './popups/delete-all-recordings-popup/delete-all-recordings-popup.component';
import { LogoutConfirmPopupComponent } from './popups/logout-confirm-popup/logout-confirm-popup.component';
import { ManageProfilesComponent } from './popups/profile-popup/manage-profiles/manage-profiles.component';
import { ProfileSelectionComponent } from './popups/profile-popup/profile-selection.component';
import { SwitchProfilesComponent } from './popups/profile-popup/switch-profiles/switch-profiles.component';
import { SharePopupComponent } from './popups/share-popup/share-popup.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RecordingCardComponent } from './recording-card/recording-card.component';
import { RibbonComponent } from './ribbon/ribbon.component';
import { SubscriptionSettingsComponent } from './settings/subscription-settings/subscription-settings.component';
import { TabsComponent } from './tabs/tabs.component';
import { ExitPopupComponent } from './exit-popup/exit-popup.component';

@NgModule({
  imports: [CommonModule, FormsModule, ShareButtonsModule, ShareIconsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [Platform, Location],
  declarations: [
    RibbonComponent,
    ProgressBarComponent,
    GenericCardComponent,
    BannerComponent,
    GridComponent,
    TabsComponent,
    ChannelFilterComponent,
    DayFilterComponent,
    CategoryFilterComponent,
    GuideCategoryFilterComponent,
    YearFilterComponent,
    ScoreFilterComponent,
    ContentBackdropComponent,
    RecordingCardComponent,
    StbFilterComponent,
    SubscriptionSettingsComponent,
    WatchButtonComponent,
    ChannelComponent,
    ChannelScheduleComponent,
    DaySelectorComponent,
    DaySelectorElementComponent,
    ScheduleComponent,
    TimelineComponent,
    TimelinePointComponent,
    DeleteRecordingComponent,
    CancelRecordingComponent,
    CreateEditRecordingComponent,
    RentButtonComponent,
    TrailerButtonComponent,
    BundleButtonComponent,
    DeleteAllRecordingsPopupComponent,
    AdultPopupComponent,
    TooltipDirective,
    ListPageFiltersComponent,
    AuthPopupComponent,
    ProfileSelectionComponent,
    AnonymousCatalogSelectPopupComponent,
    SwitchProfilesComponent,
    ManageProfilesComponent,
    DeleteProfileComponent,
    MessagesComponent,
    DeleteAllRecordingsPopupComponent,
    SharePopupComponent,
    LogoutConfirmPopupComponent,
    ClearFilterComponent,
    MoreSmarttvComponent,
    DaySelectorSmarttvComponent,
    SmartTvKeyDownEnterDirective,
    MiniDetailComponent,
    NumberEntryComponent,
    CenterOnFocusDirective,
    ExitPopupComponent,
    SharePopupComponent,

  ],
  exports: [
    RibbonComponent,
    ProgressBarComponent,
    GenericCardComponent,
    BannerComponent,
    GridComponent,
    TabsComponent,
    ChannelFilterComponent,
    DayFilterComponent,
    CategoryFilterComponent,
    GuideCategoryFilterComponent,
    YearFilterComponent,
    ScoreFilterComponent,
    ClearFilterComponent,
    ContentBackdropComponent,
    RecordingCardComponent,
    StbFilterComponent,
    SubscriptionSettingsComponent,
    WatchButtonComponent,
    ChannelComponent,
    ChannelScheduleComponent,
    DaySelectorComponent,
    DaySelectorElementComponent,
    ScheduleComponent,
    TimelineComponent,
    TimelinePointComponent,
    DeleteRecordingComponent,
    CancelRecordingComponent,
    CreateEditRecordingComponent,
    RentButtonComponent,
    TrailerButtonComponent,
    BundleButtonComponent,
    DeleteAllRecordingsPopupComponent,
    AdultPopupComponent,
    TooltipDirective,
    ListPageFiltersComponent,
    AuthPopupComponent,
    ProfileSelectionComponent,
    AnonymousCatalogSelectPopupComponent,
    SwitchProfilesComponent,
    ManageProfilesComponent,
    DeleteProfileComponent,
    MessagesComponent,
    DeleteAllRecordingsPopupComponent,
    SharePopupComponent,
    LogoutConfirmPopupComponent,
    MoreSmarttvComponent,
    DaySelectorSmarttvComponent,
    MiniDetailComponent,
    SmartTvKeyDownEnterDirective,
    NumberEntryComponent,
    ExitPopupComponent,
    CenterOnFocusDirective,
  ],
})
export class AtvSharedModule {
}
