import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DiscoveryService } from '@atv-bootstrap/services/discovery';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import {
  Stream2AssetRequest,
  Stream2AssetResponse,
  Stream2Bookmark,
  Stream2BreakvertisementResponse,
  Stream2KeepAliveRequest,
  Stream2ManifestResponse,
  Stream2Option,
  Stream2StartRequest,
  Stream2StartResponse,
  Stream2TerminateRequest,
} from './streaming-api.model';

@Injectable({
  providedIn: 'root',
})
export class StreamingApiService {
  constructor(private disc: DiscoveryService, private http: HttpClient) {}

  public sendTerminateBeacon(streamId: string, terminateInfo: Stream2TerminateRequest): void {
    navigator.sendBeacon(
      `${this.disc.location}/stream/v2/stream/${encodeURIComponent(streamId)}/terminate`,
      JSON.stringify(terminateInfo)
    );
  }

  public startStream(body: Stream2StartRequest): Observable<Stream2StartResponse> {
    const queryParam = new HttpParams().append('actionFilter', environment.actionFilter);
    return this.http.post<Stream2StartResponse>(`${this.disc.location}/stream/v2/stream`, body, {
      params: queryParam,
    });
  }

  public commitReservedStream(sid: string, mid: string, quoteId: string): Observable<void> {
    const body = {
      quoteId,
    };
    return this.http.put<void>(
      `${this.disc.location}/stream/v2/stream/${encodeURIComponent(sid)}/asset/${encodeURIComponent(mid)}/commit`,
      body,
    );
  }

  public updateStream(streamId: string, manifestId: string, options: Stream2Option): Observable<void> {
    const sId = encodeURIComponent(streamId);
    const mId = encodeURIComponent(manifestId);
    return this.http.put<void>(`${this.disc.location}/stream/v2/stream/${sId}/asset/${mId}/option`, options);
  }

  public deleteStream(sid: string): Observable<void> {
    return this.http.delete<void>(`${this.disc.location}/stream/v2/stream/${encodeURIComponent(sid)}`);
  }

  public getAsset(sid: string, body: Stream2AssetRequest): Observable<Stream2AssetResponse> {
    const queryParam = new HttpParams().append('actionFilter', environment.actionFilter);
    return this.http.post<Stream2AssetResponse>(
      `${this.disc.location}/stream/v2/stream/${encodeURIComponent(sid)}/asset`,
      body,
      { params: queryParam }
    );
  }

  public getManifest(sid: string, mid: string): Observable<Stream2ManifestResponse> {
    return this.http.get<Stream2ManifestResponse>(
      `${this.disc.location}/stream/v2/stream/${encodeURIComponent(sid)}/asset/${encodeURIComponent(mid)}/manifest`
    );
  }

  public keepAlive(sid: string, body: Stream2KeepAliveRequest): Observable<void> {
    return this.http.put<void>(`${this.disc.location}/stream/v2/stream/${encodeURIComponent(sid)}/keepalive`, body);
  }

  // public setBookmark(sid: string, mid: string, body: Stream2Bookmark): Observable<any> {
  //   return this.http.put<any>(
  //     `${this.disc.location}/stream/v2/stream/${sid}/asset/${mid}/bookmark`,
  //     body
  //   );
  // }

  public getBookmark(sid: string, mid: string): Observable<Stream2Bookmark> {
    return this.http.get<Stream2Bookmark>(
      `${this.disc.location}/stream/v2/stream/${encodeURIComponent(sid)}/asset/${encodeURIComponent(mid)}/bookmark`
    );
  }

  public getBreakvertising(
    sid: string,
    mid: string
  ): Observable<Stream2BreakvertisementResponse[]> {
    const queryParam = new HttpParams().append('actionFilter', environment.actionFilter);
    return this.http.get<Stream2BreakvertisementResponse[]>(
      `${this.disc.location}/stream/v2/stream/${encodeURIComponent(sid)}/asset/${encodeURIComponent(mid)}/break`,
      { params: queryParam }
    );
  }

  public addToWatchList(sid: string, mid: string): Observable<void> {
    return this.http.put<void>(
      `${this.disc.location}/stream/v2/stream/${encodeURIComponent(sid)}/asset/${encodeURIComponent(mid)}/watchlist`,
      {}
    );
  }
}
