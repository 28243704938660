<div class="list-page-filters">
  <app-day-filter
    #dayFilter
    [days]="listFilterModel?.filterDays"
    [filterModel]="listFilterModel"
    (filterReady)="filterReady()"
    (filterChange)="dayFilterChanged()"
  ></app-day-filter>
  <app-channel-filter
    #channelFilter
    [channels]="listFilterModel?.filterInputChannels"
    [filterModel]="listFilterModel"
    (filterReady)="filterReady()"
    (filterChange)="channelFilterChanged()"
  ></app-channel-filter>
  <app-category-filter
    #categoryFilter
    [filterModel]="listFilterModel"
    (filterReady)="filterReady()"
    (filterChange)="categoryFilterChanged()"
    [allowedAdultMode]="allowedAdultMode"
  ></app-category-filter>
  <app-clear-filter
    *ngIf="isSmartTv"
    [filtersActive]="listFilterModel && listFilterModel.areFiltersActive()"
    (clear)="clearAllFilters()"
  ></app-clear-filter>
</div>
