import { Component, OnDestroy, OnInit } from '@angular/core';
import { Properties } from '@atv-core/api/cms';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { Observable, Subscription } from 'rxjs';

import { AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { ConfigService, DetailTranslationKeys } from '../bootstrap/services/config';
import { MessagesService } from '@atv-core/services/messages';
import { SessionService } from '@atv-core/services/session';

@Component({ template: '' })
export abstract class ReloadablePageComponent implements OnInit, OnDestroy {
  private reloadSubscription: Subscription;

  constructor(
    protected sessionService: SessionService,
    protected authorizationService: AuthorizationService,
    protected adultService: AdultService,
    protected messagesService: MessagesService,
    protected config: ConfigService,
  ) {
  }

  private static scrollTop(): void {
    if (SharedUtilityService.isSmartTv()) {
      const verticalScroller = document.querySelector(
        '.smarttv-vertical-scroller',
      ) as HTMLDivElement;
      if (verticalScroller) {
        verticalScroller.style.transform = 'translateY(0px)';
      }
    }
  }

// TODO don't use observable, page info should be available since it is added to action info
  abstract getPageProperties(): Observable<Properties[]>;

  protected checkLoadPage(): void {
    this.getPageProperties().subscribe((properties) => {
      if (
        (properties?.includes(Properties.AUTH_REQUIRED) &&
         !(this.authorizationService.isAuthorized() && this.sessionService.activeUserInUse())) ||
        (properties?.includes(Properties.ADULT_REQUIRED) &&
         !this.authorizationService.isAuthorized())
      ) {
        this.authorizationService.showAuthPopup.emit();
        return;
      }

      if (properties?.includes(Properties.ADULT_REQUIRED)) {
        this.adultService.checkAdultMode({
          successCallback: () => {
            this.loadPage();
            ReloadablePageComponent.scrollTop();
          },
          errorCallback: () => {
            this.messagesService.showErrorMessage(
              this.config.getTranslation(DetailTranslationKeys.detail_adult_warning),
            );
          },
        });
        return;
      }

      this.loadPage();
      ReloadablePageComponent.scrollTop();
    });
  }

  abstract loadPage(): void;

  abstract ngOnInit(): void;

  abstract ngOnDestroy(): void;

  protected start(): void {
    this.reloadSubscription = this.sessionService.reloadCurrentPageEvent.subscribe(() => {
      this.checkLoadPage();
    });

    this.checkLoadPage();
  }

  protected stop(): void {
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }
  }
}
