<div class="ribbon-wrapper" [class.hidden]="hideRibbon" [class.initial-height]="setInitialHeight">
  <div class="ribbon-title" *ngIf="!hideTitle">
    <div class="ribbon-title-text">{{ ribbonVisualModel.title }}</div>
    <img
      *ngFor="let icon of ribbonVisualModel?.titleIcons"
      class="ribbon-title-icon"
      [class.hidden]="!icon.iconImage()"
      [class.ribbon-title-icon-clickable]="icon.iconClick !== undefined"
      [ngStyle]="{
        height: icon.iconHeight ? icon.iconHeight + 'px' : '17px',
        width: icon.iconHeight ? icon.iconHeight + 'px' : '17px'
      }"
      [src]="icon.iconImage()"
      (click)="icon.iconClick()"
      alt="ribbon icon"
    />
  </div>

  <div class="ribbon-content-message" [class.hidden]="!showContentMessage">
    {{ ribbonVisualModel.contentMessage }}
  </div>
  <div class="ribbon" [class.hidden]="showContentMessage">
    <div
      *ngIf="!isSmartTv"
      class="ribbon-nav-left ribbon-nav"
      [class.can-left]="ribbonVisualModel.canPrev"
    >
      <span (click)="prev()"></span>
    </div>
    <div class="ribbon-content">
      <div class="ribbon-items horizontal-scroller" #ribbonItems>
        <div class="ribbon-item" *ngFor="let item of visibleRibbonContents">
          <app-generic-card
            [isOnDetailPage]="ribbon.isOnDetailPage"
            [ribbonProperties]="ribbon.properties"
            [cardOrientation]="cardOrientation"
            [cardSize]="cardSize"
            [channelCardMode]="ribbonChannelCardMode"
            [allowedAdultMode]="allowedAdultMode"
            [asset]="item"
            [scrollElement]="ribbonItems"
            [isOnSearchPage]="isOnSearchPage"
          ></app-generic-card>
        </div>
      </div>
    </div>
    <div
      *ngIf="!isSmartTv"
      class="ribbon-nav-right ribbon-nav"
      [class.can-right]="ribbonVisualModel.canNext"
    >
      <span (click)="next()"></span>
    </div>
  </div>
</div>
