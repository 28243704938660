<div [class.smarttv-vertical-scroller]="isOnCustomPage">
  <app-searchbar
    #searchbar
    (cancelSearch)="cancelSearchRequest()"
    (clearSearchResults)="clearSearchResults()"
    (search)="search($event)"
  ></app-searchbar>
  <div class="results">
    <div *ngFor="let searchRow of searchRows;let i = index" class="result row-{{ i }}">
      <app-ribbon
        (visibilityChanged)="registerElements()"
        [allowedAdultMode]="allowedAdultMode"
        [pageProperties]="searchPageLayout.properties"
        [ribbon]="searchRow.ribbon"
        [isOnSearchPage]="true"
      ></app-ribbon>
    </div>
    <div *ngIf="searchGrid" class="result row-0">
      <app-grid
        (visibilityChanged)="registerElements()"
        [allowedAdultMode]="allowedAdultMode"
        [grid]="searchGrid.grid"
        [pageProperties]="searchPageLayout.properties"
        [isOnSearchPage]="true"
      ></app-grid>
    </div>
  </div>

  <div *ngIf="noResults" class="no-results">{{ noResultsText }}</div>
</div>
