<div
  #banner
  class="banner"
  [class.smarttv]="isSmartTv"
  [style]="bannerModel.bannerStyle"
  (click)="doAction()"
  (focus)="onFocus()"
  (focusout)="focusOut()"
  (smartTvKeyDownEnter)="doAction()"
  [appCenterOnFocus]="null"
>
  <video #videoPlayer [class.hidden]="!bannerModel.visible"></video>

  <div [class.hidden]="!bannerModel.visible" [ngStyle]="bannerModel.imageStyle1" class="banner-image">
    <img [srcset]="bannerModel.srcSet1" />
  </div>
  <div [class.hidden]="!bannerModel.visible" [ngStyle]="bannerModel.imageStyle2" class="banner-image">
    <img [srcset]="bannerModel.srcSet2" />
  </div>

  <div class="banner-text" [ngStyle]="bannerModel.textStyle1" [class.hidden]="!bannerModel.visible">
    <p class="banner-text-line1 primary-color">{{ bannerModel.textLines1.line1 }}</p>
    <p class="banner-text-line2">{{ bannerModel.textLines1.line2 }}</p>
    <p class="banner-text-line3">{{ bannerModel.textLines1.line3 }}</p>
  </div>

  <div class="banner-text" [ngStyle]="bannerModel.textStyle2" [class.hidden]="!bannerModel.visible">
    <p class="banner-text-line1 primary-color">{{ bannerModel.textLines2.line1 }}</p>
    <p class="banner-text-line2">{{ bannerModel.textLines2.line2 }}</p>
    <p class="banner-text-line3">{{ bannerModel.textLines2.line3 }}</p>
  </div>

  <div class="banner-position" *ngIf="bannerModel.bannerLength > 1" [class.hidden]="!bannerModel.visible">
    <div class="position-dots" (click)="$event.preventDefault(); $event.stopPropagation()">
      <div
        class="position-dot"
        *ngFor="let star of ' '.repeat(bannerModel.bannerLength).split(''); let x = index"
        [class.active]="x === bannerModel.currentBannerIndex"
        (click)="goToBanner(x)"
      ></div>
    </div>
  </div>
</div>
