import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DiscoveryService } from '@atv-bootstrap/services/discovery';
import { Observable } from 'rxjs';

import { DeleteRecordingsQueryParams, Recording, RecordingContainer, RecordingOptions, Subscription } from './recording-api.model';

@Injectable({
  providedIn: 'root',
})
export class RecordingApiService {
  constructor(private disc: DiscoveryService, private http: HttpClient) {}

  public getRecording(deviceId: string, entity: string): Observable<Recording> {
    return this.http.get<Recording>(
      `${this.disc.location}/recording/v1/device/${encodeURIComponent(deviceId)}/recording/${encodeURIComponent(entity)}`
    );
  }

  public getDeviceRecordingsAndSubScriptions(deviceId: string): Observable<RecordingContainer> {
    return this.http.get<RecordingContainer>(
      `${this.disc.location}/recording/v1/device/${encodeURIComponent(deviceId)}`
    );
  }

  public createProgramSubscription(
    deviceId: string,
    programId: string,
    channelId: string,
    recodingOptions: RecordingOptions
  ): Observable<any> {
    const dId = encodeURIComponent(deviceId);
    const pId = encodeURIComponent(programId);
    const cId = encodeURIComponent(channelId);
    return this.http.post<any>(
      `${this.disc.location}/recording/v1/device/${dId}/subscription/program/${pId}/channel/${cId}`,
      recodingOptions
    );
  }

  public createSeasonSubscription(
    deviceId: string,
    seasonId: string,
    channelId: string,
    recodingOptions: RecordingOptions
  ): Observable<any> {
    const dId = encodeURIComponent(deviceId);
    const sId = encodeURIComponent(seasonId);
    const cId = encodeURIComponent(channelId);
    return this.http.post<any>(
      `${this.disc.location}/recording/v1/device/${dId}/subscription/season/${sId}/channel/${cId}`,
      recodingOptions
    );
  }

  public createSeriesSubscription(
    deviceId: string,
    seriesId: string,
    channelId: string,
    recodingOptions: RecordingOptions
  ): Observable<any> {
    const dId = encodeURIComponent(deviceId);
    const sId = encodeURIComponent(seriesId);
    const cId = encodeURIComponent(channelId);
    return this.http.post<any>(
      `${this.disc.location}/recording/v1/device/${dId}/subscription/series/${sId}/channel/${cId}`,
      recodingOptions
    );
  }

  public editRecording(recording: Recording): Observable<any> {
    recording = new Recording(recording);
    const dId = encodeURIComponent(recording.device);
    const rId = encodeURIComponent(recording.identifier);
    return this.http.put<any>(
      `${this.disc.location}/recording/v1/device/${dId}/recording/${rId}`,
      recording
    );
  }

  public deleteRecording(recording: Recording): Observable<any> {
    const dId = encodeURIComponent(recording.device);
    const rId = encodeURIComponent(recording.identifier);
    return this.http.delete<any>(
      `${this.disc.location}/recording/v1/device/${dId}/recording/${rId}`
    );
  }

  public deleteRecordings(
    recording: Recording,
    queryParams: DeleteRecordingsQueryParams
  ): Observable<any> {
    let params = new HttpParams();
    Object.keys(queryParams).forEach((key) => {
      queryParams[key].forEach((value) => {
        params = params.append(key, value);
      });
    });

    return this.http.delete<any>(
      `${this.disc.location}/recording/v1/device/${encodeURIComponent(recording.device)}/recording`,
      { params }
    );
  }

  public editProgramSubscription(device: string, sub: Subscription): Observable<any> {
    const dId = encodeURIComponent(device);
    const sId = encodeURIComponent(sub.identifier);
    return this.http.put<any>(
      `${this.disc.location}/recording/v1/device/${dId}/subscription/program/${sId}`,
      sub
    );
  }

  public editSeasonSubscription(device: string, sub: Subscription): Observable<any> {
    const dId = encodeURIComponent(device);
    const sId = encodeURIComponent(sub.identifier);
    return this.http.put<any>(
      `${this.disc.location}/recording/v1/device/${dId}/subscription/season/${sId}`,
      sub
    );
  }

  public editSeriesSubscription(device: string, sub: Subscription): Observable<any> {
    const dId = encodeURIComponent(device);
    const sId = encodeURIComponent(sub.identifier);
    return this.http.put<any>(
      `${this.disc.location}/recording/v1/device/${dId}/subscription/series/${sId}`,
      sub
    );
  }

  public deleteProgramSubscription(recording: Recording): Observable<any> {
    const dId = encodeURIComponent(recording.device);
    const pId = encodeURIComponent(recording.program);
    const cId = encodeURIComponent(recording.channel);
    return this.http.delete<any>(
      `${this.disc.location}/recording/v1/device/${dId}/subscription/program/${pId}/channel/${cId}`
    );
  }

  public deleteSeasonSubscription(recording: Recording): Observable<any> {
    const dId = encodeURIComponent(recording.device);
    const sId = encodeURIComponent(recording.season);
    const cId = encodeURIComponent(recording.channel);
    return this.http.delete<any>(
      `${this.disc.location}/recording/v1/device/${dId}/subscription/season/${sId}/channel/${cId}`
    );
  }

  public deleteSeriesSubscription(recording: Recording): Observable<any> {
    const dId = encodeURIComponent(recording.device);
    const sId = encodeURIComponent(recording.series);
    const cId = encodeURIComponent(recording.channel);
    return this.http.delete<any>(
      `${this.disc.location}/recording/v1/device/${dId}/subscription/series/${sId}/channel/${cId}`
    );
  }
}
