import { PlayerEngine } from '@atv-player/engines/player-engine';
import { AudioTrack } from '@atv-player/model/audio-track';
import { Subtitle } from '@atv-player/model/subtitle';
import { VideoQuality } from '@atv-player/model/video-quality';
import { DrmInfo } from '@atv-player/model/drm-info';
import { VideoEvent } from '@atv-player/model/video-event';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SeekRange } from '@atv-player/model/seek-range';

export class ShakaPlayerEngine implements PlayerEngine {
  private readonly player: any;
  private readonly mediaElement: HTMLMediaElement;
  private selectedQuality?: VideoQuality;
  private selectedAudio?: AudioTrack;
  private readonly shaka: any;

  constructor(mediaElement: HTMLMediaElement, shaka: any) {
    this.player = new shaka.Player(mediaElement);
    this.mediaElement = mediaElement;
    this.shaka = shaka;
  }

  static create(videoElementSelector: string): Observable<ShakaPlayerEngine> {
    return from(import('shaka-player')).pipe(map((shaka) => {
      return new ShakaPlayerEngine(document.getElementById(videoElementSelector) as HTMLMediaElement, shaka);
    }));
  }

  static createWithElement(videoEl: HTMLMediaElement): Observable<ShakaPlayerEngine> {
    return from(import('shaka-player')).pipe(map((shaka) => {
      return new ShakaPlayerEngine(videoEl, shaka);
    }));
  }

  public load(
    sourceUrls: string[],
    startPosition: number,
    initialBitrate: number,
    isDrmProtected: boolean,
    useBroadpeakAnalytics: boolean,
    broadpeakAnalyticsDomain?: string,
    broadpeakAnalyticsServer?: string,
    drmInfo?: DrmInfo,
  ): Promise<unknown> {
    this.player.configure('abr.defaultBandwidthEstimate', initialBitrate);

    this.player.getNetworkingEngine().clearAllRequestFilters();
    this.player.getNetworkingEngine().clearAllResponseFilters();

    if (isDrmProtected) {
      const drmPrefix = drmInfo.environment === 'DRMtoday_STAGING' ? 'lic.staging' : 'lic';
      this.player.configure('drm.servers',
        { 'com.widevine.alpha': `https://${drmPrefix}.drmtoday.com/license-proxy-widevine/cenc/?assetId=${drmInfo.assetId}` });

      this.player.getNetworkingEngine().registerRequestFilter((type, request) => {
        if (type === this.shaka.net.NetworkingEngine.RequestType.LICENSE) {

          request.headers['dt-custom-data'] = btoa(
            JSON.stringify({
              userId: drmInfo.userId,
              sessionId: drmInfo.sessionId,
              merchant: drmInfo.merchant,
            }),
          );

          if (drmInfo.authToken) {
            request.headers['x-dt-auth-token'] = drmInfo.authToken;
          }
        }
      });

      this.player.getNetworkingEngine().registerResponseFilter((type, response) => {
        if (type === this.shaka.net.NetworkingEngine.RequestType.LICENSE) {
          const responseText = this.shaka.util.StringUtils.fromUTF8(response.data);
          if (responseText.indexOf('<AcquireLicenseResponse') < 0) {
            // If widevine response
            const wrapped = JSON.parse(responseText);
            response.data = this.shaka.util.Uint8ArrayUtils.fromBase64(wrapped.license);
          }
        }
      });
    }

    return this.player.load(sourceUrls, startPosition);
  }

  public canAirplay(): boolean {
    // TODO
    return false;
  }

  public destroy(): Promise<unknown> {
    return this.player.destroy();
  }

  public getAudioTracks(): AudioTrack[] {
    const result: AudioTrack[] = [];

    this.player.getVariantTracks().forEach(track => {
      const languageTrack = result.find(l => l.language === track.language);

      if (!languageTrack) {
        result.push({ id: `${track.audioId}`, language: track.language, label: track.label, channelsCount: track.channelsCount });
      } else if (languageTrack.channelsCount < track.channelsCount) {
        languageTrack.id = `${track.audioId}`;
        languageTrack.channelsCount = track.channelsCount;
      }
    });

    return result;
  }

  public getDuration(): number {
    return this.mediaElement.duration;
  }

  public getSeekRange(): SeekRange {
    return this.player.seekable[0]?.start;
  }

  public getSubtitles(): Subtitle[] {
    return this.player.getTextTracks()
      .map(t => ({ id: `${t.id}`, language: t.language, label: t.label, representationId: t.originalTextId }));
  }

  public getVideoQualities(): VideoQuality[] {
    const result: VideoQuality[] = [];


    this.player.getVariantTracks().forEach(track => {
      const sameHeightTrack = result.find(l => l.height === track.height);

      // for now, this just uses the first track that is encountered with this height
      // in the future this could take into account framerate and/or bandwidth
      if (!sameHeightTrack) {
        result.push({ id: `${track.videoId}`, height: track.height });
      }
    });

    return result;
  }

  public getVolume(): number {
    return this.mediaElement.volume;
  }

  public isLive(): boolean {
    return this.player.isLive();
  }

  public isMuted(): boolean {
    return this.mediaElement.muted;
  }

  public isPaused(): boolean {
    return this.mediaElement.paused;
  }

  public isPlayerAttached(): boolean {
    return !!this.player;
  }

  public off(event: VideoEvent, callback: EventListener): void {
    switch (event) {
      case VideoEvent.ENDED:
      case VideoEvent.PAUSE:
      case VideoEvent.PLAYING:
      case VideoEvent.TIMEUPDATE:
        this.mediaElement.removeEventListener(event, callback);
        break;
      default:
        this.player.removeEventListener(event, callback);
    }
  }

  public on(event: VideoEvent, callback: EventListener): void {
    // TODO error events keep getting emitted without the player stopping, handle more gracefully
    switch (event) {
      case VideoEvent.ENDED:
      case VideoEvent.PAUSE:
      case VideoEvent.PLAYING:
      case VideoEvent.TIMEUPDATE:
        this.mediaElement.addEventListener(event, callback);
        break;
      default:
        this.player.addEventListener(event, callback);
    }
  }

  public one(event: VideoEvent, callback: EventListener): void {
    switch (event) {
      case VideoEvent.ENDED:
      case VideoEvent.PAUSE:
      case VideoEvent.PLAYING:
      case VideoEvent.TIMEUPDATE:
        this.mediaElement.addEventListener(event, callback, { once: true });
        break;
      default:
        this.player.addEventListener(event, callback, { once: true });
    }
  }

  public pause(): void {
    this.mediaElement.pause();
  }

  public play(): Promise<unknown> {
    return this.mediaElement.play();
  }

  public selectAudioTrack(track: AudioTrack): void {
    this.selectedAudio = track;
    this.selectVariant();
  }

  public selectSubtitle(subtitle?: Subtitle): void {
    const track = subtitle ? this.player.getTextTracks().find(t => `${t.id}` === subtitle.id) : undefined;

    if (track) {
      this.player.selectTextTrack(track);
      this.player.setTextTrackVisibility(true);
    } else {
      this.player.setTextTrackVisibility(false);
    }
  }

  public selectVideoQuality(quality?: VideoQuality): void {
    if (!quality) {
      this.player.configure('abr.enabled', true);
      return;
    }

    this.selectedQuality = quality;
    this.player.configure('abr.enabled', false);

    this.selectVariant();
  }

  public setMuted(muted: boolean): void {
    this.mediaElement.muted = muted;
  }

  public setVolume(volume: number): void {
    this.mediaElement.volume = volume;
  }

  public showAirplayCastMenu(): void {
    // TODO
  }

  private selectVariant(): void {
    if (this.selectedAudio && this.selectedQuality) {
      this.player.selectVariantTrack(
        this.player.getVariantTracks().find(t => this.selectedAudio.id === `${t.audioId}` && this.selectedQuality.id === `${t.videoId}`));
    } else if (this.selectedAudio) {
      // only change audio language, don't touch quality/abr settings
      this.player.selectAudioLanguage(this.selectedAudio.language);
    } else {
      // only select quality, keep audio language the same as current
      const currentAudioId = this.player.getVariantTracks().find(t => t.active).audioId;

      this.player.selectVariantTrack(
        this.player.getVariantTracks().find(t => currentAudioId === `${t.audioId}` && this.selectedQuality.id === `${t.videoId}`));
    }
  }

  public release(): void {
  }

  // TODO
  public getCurrentTime(streamStartTime?: number): number {
    return 0;
  }

  // TODO
  public seekAbsolute(time: number): Promise<unknown> {
    return Promise.resolve(undefined);
  }

  // TODO
  public seekRelative(offset: number): Promise<unknown> {
    return Promise.resolve(undefined);
  }
}
