import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import {
  KeepAliveErrors,
  StreamAssetRequestOptions,
  StreamDrmTypes,
  StreamPackageTypes,
  StreamRequestGroups,
} from '@atv-core/api/api/api-api.model';
import {
  KeepAliveStatus,
  Stream2AssetRequest,
  Stream2AssetResponse,
  Stream2Option,
  Stream2StartRequest,
} from '@atv-core/api/ss/streaming-api.model';
import { ConfigService, SettingsKeys } from '@atv-bootstrap/services/config';
import { LogErrorInfo, PlayerLogInfo } from '@atv-core/services/log/log.model';
import { LogService } from '@atv-core/services/log/log.service';
import { PlayerService } from '../player/player.service';
import { SessionService } from '@atv-core/services/session';
import { DeviceSettings, PlatformType, PlayOriginator, StreamResolutionType, StreamType } from '@atv-core/utility/constants/shared';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { PlayerConfig } from '@atv-player/model/player-config.model';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, of, Subscription, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Stream2KeepAliveRequest, Stream2ManifestResponse, Stream2StartResponse } from '../../api/ss/streaming-api.model';
import { StreamingApiService } from '../../api/ss/streaming-api.service';
import { HistoryApiService } from '@atv-core/api/history';

interface ActiveStream {
  id: string;
  profileId: string;
  catalogId: string;
}

@Injectable({
  providedIn: 'root',
})
export class StreamManagerService {
  public keepAliveErrorEvent: EventEmitter<HttpErrorResponse> = new EventEmitter();
  private keepAliveIntervalSubscriptions = {};
  private streamKeepAliveErrors = {};
  private readonly maxKeepAliveErrors: number;
  private readonly mustRestartLinearStreams: boolean;
  private readonly defaultMaxLinearStreamTime: number;
  private readonly restartLinearStreamFraction: number;
  private sessionLifetimeEnd = new BehaviorSubject<boolean>(false);
  private sessionLifeTimeTimer?: Subscription;
  private activeStream: ActiveStream;
  private anonymousDeviceId = undefined;
  private reservedStream: Stream2StartResponse;
  private linearStreamStart: number;
  private isAutoRestartLinearStream = false;

  constructor(
    private config: ConfigService,
    private log: LogService,
    private streamingApi: StreamingApiService,
    private sessionService: SessionService,
    private playerService: PlayerService,
    private historyApiService: HistoryApiService,
  ) {
    this.maxKeepAliveErrors = this.config.getSettingNumber(SettingsKeys.maxKeepAliveErrors, 3);
    this.mustRestartLinearStreams =
      SharedUtilityService.isSmartTv() &&
      this.config.getSettingBoolean(SettingsKeys.restartLinearStreams, false);

    this.defaultMaxLinearStreamTime = this.config.getSettingNumber(SettingsKeys.defaultMaxLinearStreamTime, 0);
    this.restartLinearStreamFraction = this.config.getSettingNumber(SettingsKeys.restartLinearStreamFraction, 0.9);
  }

  public getActiveSid(): string {
    return this.activeStream?.id;
  }

  public getReservedSid(): string {
    return this.reservedStream?.sid;
  }

  public startStreamForChromecast(): Observable<Stream2StartResponse> {
    return this.playerService.getRegisteredDeviceId().pipe(
      switchMap((castDeviceId) => {
        const startStreamRequestObject: Stream2StartRequest = {
          profileId: this.sessionService.getActiveProfileId(),
          group: StreamRequestGroups.MAIN,
          device: castDeviceId,
          deviceType: PlatformType.CHROMECAST,
          drmType: StreamDrmTypes.WIDEVINE,
          packageType: StreamPackageTypes.DASH,
          catalogId: this.sessionService.getCatalogId(),
        };
        return this.streamingApi
          .startStream(startStreamRequestObject)
          .pipe(switchMap((result) => of(result)));
      }),
    );
  }

  public startReservedStreamForChromecast(
    playerLogInfo: PlayerLogInfo,
    type: StreamType,
    resourceId: string,
    reserve: boolean,
  ): Observable<Stream2AssetResponse> {
    return this.playerService.getRegisteredDeviceId().pipe(
      switchMap((castDeviceId) => {
        const startStreamRequestObject: Stream2StartRequest = {
          profileId: this.sessionService.getActiveProfileId(),
          group: StreamRequestGroups.MAIN,
          device: castDeviceId,
          deviceType: PlatformType.CHROMECAST,
          drmType: StreamDrmTypes.WIDEVINE,
          packageType: StreamPackageTypes.DASH,
          asset: this.createAssetRequestObject(type, resourceId, reserve, undefined, false, false),
          catalogId: this.sessionService.getCatalogId(),
        };
        return this.streamingApi.startStream(startStreamRequestObject).pipe(
          switchMap((result) => {
            if (this.reservedStream) {
              this.deleteReservedStream(playerLogInfo);
            }
            this.reservedStream = result;
            this.startKeepAliveInterval(result.sid, result.keepalive);
            return of(result.asset);
          }),
        );
      }),
    );
  }

  public transferReservedStreamToChromecast(): Stream2StartResponse {
    this.sendKeepAlive(this.reservedStream.sid);
    this.stopKeepAliveInterval(this.reservedStream.sid);
    this.sessionLifeTimeTimer?.unsubscribe();

    const stream = this.reservedStream;
    this.reservedStream = undefined;
    return stream;
  }

  public newStreamAndAsset(
    playerLogInfo: PlayerLogInfo,
    type: StreamType,
    resourceId: string,
    reserve: boolean,
    packageType: StreamPackageTypes,
    drmType: StreamDrmTypes,
    quoteId: string,
    group: StreamRequestGroups,
    resumeFromLive: boolean,
    isAirplayActive: boolean,
  ): Observable<Stream2AssetResponse> {
    const startStreamRequestObject: Stream2StartRequest = this.createStartStreamRequestObject(
      packageType,
      drmType,
      group,
      this.createAssetRequestObject(type, resourceId, reserve, quoteId, resumeFromLive, isAirplayActive),
    );

    if (playerLogInfo && !reserve) {
      this.log.playStart(playerLogInfo);
    }

    return this.streamingApi.startStream(startStreamRequestObject).pipe(
      tap((result) => {
        if (reserve) {
          if (this.reservedStream) {
            this.deleteReservedStream(playerLogInfo);
          }
          this.reservedStream = result;
          this.startKeepAliveInterval(result.sid, result.keepalive);
        } else {
          if (this.activeStream) {
            this.deleteActiveStream(playerLogInfo);
          }
          this.activeStream = {
            id: result.sid,
            catalogId: this.sessionService.getCatalogId(),
            profileId: this.sessionService.getActiveProfileId(),
          };
          this.startKeepAliveInterval(this.activeStream.id, result.keepalive);

          if (type === StreamType.LINEAR) {
            this.startStillWatchingTimer(result.asset.sessionLifetime);
          }
        }
      }),
      map((result) => result.asset),
    );
  }

  public getAssetForCurrentStream(
    playerLogInfo: PlayerLogInfo,
    type: StreamType,
    resourceId: string,
    reserve: boolean,
    quoteId: string,
    resumeFromLive: boolean,
    isAirplayActive: boolean,
  ): Observable<Stream2AssetResponse> {
    if (
      this.activeStream &&
      (this.activeStream.catalogId !== this.sessionService.getCatalogId() ||
       this.activeStream.profileId !== this.sessionService.getActiveProfileId() ||
       resumeFromLive)
    ) {
      const playerConfig = PlayerConfig.getInfo(type);

      return this.newStreamAndAsset(playerLogInfo, type, resourceId, reserve, playerConfig.packaging, playerConfig.encryption, quoteId,
        StreamRequestGroups.MAIN, resumeFromLive, isAirplayActive);
    }

    const assetRequestObject: Stream2AssetRequest = this.createAssetRequestObject(type, resourceId, reserve, quoteId, resumeFromLive,
      isAirplayActive);

    if (playerLogInfo) {
      this.log.playStart(playerLogInfo);
    }

    return this.streamingApi.getAsset(this.activeStream.id, assetRequestObject);
  }

  public startReservedStream(
    playerLogInfo: PlayerLogInfo,
    quoteId: string,
  ): Observable<Stream2AssetResponse> {
    this.streamKeepAliveErrors[this.reservedStream.sid] = 0;

    if (!this.reservedStream || !this.reservedStream.asset) {
      return;
    }

    if (playerLogInfo) {
      this.log.playStart(playerLogInfo);
    }

    return this.streamingApi
      .commitReservedStream(this.reservedStream.sid, this.reservedStream.asset.mid, quoteId)
      .pipe(
        map(() => {
          if (this.activeStream) {
            this.deleteActiveStream(playerLogInfo);
          }

          this.activeStream = {
            id: this.reservedStream.sid,
            catalogId: this.sessionService.getCatalogId(),
            profileId: this.sessionService.getActiveProfileId(),
          };
          const asset: Stream2AssetResponse = this.reservedStream.asset;
          this.reservedStream = undefined;
          return asset;
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          if (playerLogInfo) {
            this.log.playError(
              playerLogInfo,
              PlayOriginator.startStream,
              new LogErrorInfo(errorResponse),
            );
          }

          this.deleteReservedStream(playerLogInfo);

          throw errorResponse;
        }),
      );
  }

  private startKeepAliveInterval(id: string, keepalive: number): void {
    this.streamKeepAliveErrors[id] = 0;
    const keepAliveInterval =
      (keepalive
        ? keepalive
        : this.config.getSettingNumber(SettingsKeys.defaultKeepaliveTimeout, 300000)) * 0.85;
    this.stopKeepAliveInterval(id);

    this.keepAliveIntervalSubscriptions[id] = setInterval(() => {
      this.sendKeepAlive(id);
    }, keepAliveInterval);
  }

  public getManifest(mid: string): Observable<Stream2ManifestResponse> {
    if (this.activeStream) {
      return this.streamingApi.getManifest(this.activeStream.id, mid);
    }

    throw Error('no sid');
  }

  public deleteActiveStream(playerLogInfo: PlayerLogInfo): void {
    if (!this.activeStream) {
      return;
    }
    this.stopKeepAliveInterval(this.activeStream.id);
    this.deleteStream(this.activeStream.id, playerLogInfo);
    this.activeStream = undefined;

    // don't reset linearStreamStart when auto restarting
    if (this.isAutoRestartLinearStream) {
      this.isAutoRestartLinearStream = false;
    } else {
      this.linearStreamStart = undefined;
    }
    this.sessionLifeTimeTimer?.unsubscribe();
  }

  public deleteReservedStream(playerLogInfo: PlayerLogInfo): void {
    if (!this.reservedStream) {
      return;
    }
    this.stopKeepAliveInterval(this.reservedStream.sid);
    this.deleteStream(this.reservedStream.sid, playerLogInfo);
    this.reservedStream = undefined;
  }

  private sendKeepAlive(id: string): void {
    const keepAliveInfo: Stream2KeepAliveRequest = {
      status: KeepAliveStatus.IDLE,
    };
    this.streamingApi.keepAlive(id, keepAliveInfo).subscribe(
      () => {
      },
      (errorResponse: HttpErrorResponse) => {
        if (errorResponse && errorResponse.error.code === KeepAliveErrors.SS_STREAM_GONE) {
          this.handleKeepAliveError(id, errorResponse);
        } else {
          this.streamKeepAliveErrors[id]++;
          if (this.streamKeepAliveErrors[id] === this.maxKeepAliveErrors) {
            this.handleKeepAliveError(id, errorResponse);
          } else {
          }
        }
      },
    );
  }

  public onSessionLifetimeEnd(callback: (status: boolean) => void): Subscription {
    return this.sessionLifetimeEnd.subscribe(callback);
  }

  public updateStreamOptions(streamId: string, manifestId: string, isAirplayActive: boolean): Observable<void> {
    const options: Stream2Option = { options: [] };

    if (isAirplayActive) {
      options.options.push(StreamAssetRequestOptions.AIRPLAY_ACTIVE);
    }

    return this.streamingApi.updateStream(streamId, manifestId, options);
  }

  private createStartStreamRequestObject(
    packageType: StreamPackageTypes,
    drmType: StreamDrmTypes,
    group: StreamRequestGroups,
    assetObject?: Stream2AssetRequest,
  ): Stream2StartRequest {
    const startStreamRequest: Stream2StartRequest = {
      profileId: this.sessionService.getActiveProfileId(),
      group,
      packageType,
      deviceType: environment.platform,
      drmType,
      asset: assetObject,
      catalogId: this.sessionService.getCatalogId(),
    };

    if (this.sessionService.anonymousProfileIsActive()) {
      startStreamRequest.device = this.getAnonymousDeviceId();
    } else if (this.sessionService.getDeviceId()) {
      startStreamRequest.device = this.sessionService.getDeviceId();
    }

    return startStreamRequest;
  }

  private getAnonymousDeviceId(): string {
    if (!this.anonymousDeviceId) {
      this.anonymousDeviceId = SharedUtilityService.guid();
    }

    return this.anonymousDeviceId;
  }

  private stopKeepAliveInterval(id: string): void {
    if (this.keepAliveIntervalSubscriptions[id]) {
      clearInterval(this.keepAliveIntervalSubscriptions[id]);
      delete this.keepAliveIntervalSubscriptions[id];
    }
  }

  private createAssetRequestObject(
    type: StreamType,
    resourceId: string,
    reserve: boolean,
    quoteId: string,
    resumeFromLive: boolean,
    isAirplayActive: boolean,
  ): Stream2AssetRequest {
    let maxResolution =
      screen.width > 1920 && screen.height > 1080
        ? StreamResolutionType.FOURK
        : StreamResolutionType.HD;
    const globalSettingsMaxResolution =
      StreamResolutionType[
        this.config.getSettingString(SettingsKeys.maxResolution, StreamResolutionType.HD)
        ];

    if (globalSettingsMaxResolution !== StreamResolutionType.FOURK) {
      maxResolution = globalSettingsMaxResolution;
    }

    const assetObject: Stream2AssetRequest = {
      type,
      resource: resourceId,
      reserve,
      maxResolution,
    };

    const options: StreamAssetRequestOptions[] = [];

    if (resumeFromLive) {
      options.push(StreamAssetRequestOptions.RESUME_FROM_LIVE);
    }

    if (isAirplayActive) {
      options.push(StreamAssetRequestOptions.AIRPLAY_ACTIVE);
    }

    if (options.length > 0) {
      assetObject.options = options;
    }

    if (quoteId !== undefined && quoteId !== '' && !reserve) {
      assetObject.quoteId = quoteId;
    }

    return assetObject;
  }

  private deleteStream(sid: string, playerLogInfo: PlayerLogInfo): void {
    this.streamKeepAliveErrors[sid] = 0;
    delete this.streamKeepAliveErrors[sid];

    if (!sid) {
      return;
    }

    this.stopKeepAliveInterval(sid);
    this.sessionLifeTimeTimer?.unsubscribe();

    if (this.sessionLifetimeEnd.getValue()) {
      this.sessionLifetimeEnd.next(false);
    }

    if (playerLogInfo) {
      this.log.playStop(playerLogInfo);
    }

    this.streamingApi.deleteStream(sid).subscribe(
      () => {
      },
      (errorResponse: HttpErrorResponse) => {
        if (playerLogInfo) {
          this.log.playError(
            playerLogInfo,
            PlayOriginator.stopStream,
            new LogErrorInfo(errorResponse),
          );
        }
      },
    );
  }

  private handleKeepAliveError(id: string, errorResponse: HttpErrorResponse): void {
    this.stopKeepAliveInterval(id);
    this.keepAliveErrorEvent.emit(errorResponse);
  }

  private startStillWatchingTimer(sessionLifetime: number): void {
    if (!this.linearStreamStart) {
      this.linearStreamStart = new Date().getTime();
    }

    if (this.sessionLifeTimeTimer) {
      this.sessionLifeTimeTimer.unsubscribe();
    }

    if (this.sessionLifetimeEnd.getValue()) {
      this.sessionLifetimeEnd.next(false);
    }

    const maxStreamTimeObservable = this.mustRestartLinearStreams ? this.getMaxLinearStreamTime() : of(this.defaultMaxLinearStreamTime);

    maxStreamTimeObservable.subscribe(maxStreamTime => {
      // timeout on stream session lifetime end or max linear stream time passed
      const timeout = this.mustRestartLinearStreams ?
        Math.min(sessionLifetime * this.restartLinearStreamFraction, this.linearStreamStart + maxStreamTime - new Date().getTime()) :
        sessionLifetime * this.restartLinearStreamFraction;

      this.sessionLifeTimeTimer = timer(timeout).subscribe(() => {
        if (this.mustRestartLinearStreams) {
          // check if max linear stream time is not exceeded
          if (new Date().getTime() - this.linearStreamStart > maxStreamTime) {
            this.linearStreamStart = undefined;
            this.sessionLifetimeEnd.next(true);
          } else {
            this.isAutoRestartLinearStream = true;
            this.playerService.playAsset(this.playerService.currentPlayInfo, false);
          }
        } else {
          this.sessionLifetimeEnd.next(true);
        }
      });
    });
  }

  private getMaxLinearStreamTime(): Observable<number> {
    // get setting, if not available, fallback to default
    const deviceId = this.sessionService.getDeviceId();
    return this.historyApiService
      .getSettingForDevice(deviceId, DeviceSettings.max_linear_stream_time)
      .pipe(
        map((result) => parseInt(result.value, 10)),
        catchError(() => of(this.defaultMaxLinearStreamTime)),
      );
  }

}
