import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CardOrientation, CardSize, CmsContentTypes, Properties, Ribbon2Asset, Ribbon2Detail } from '@atv-core/api/cms';
import { ChannelCardModes, EpgScheduleSummaryAsset } from '@atv-core/api/epg';
import { BookmarkContentTypes, BookmarkUpdate, FavoriteContentTypes } from '@atv-core/api/history';
import { AdultMode } from '@atv-core/services/adult';
import { AdultService } from '@atv-core/services/adult/adult.service';
import { BookmarkCacheService } from '@atv-core/services/cache/bookmark/bookmark-cache.service';
import { ChannelCacheService, ChannelModel } from '@atv-core/services/cache/channel';
import { FavoriteCacheService } from '@atv-core/services/cache/favorite';
import { CmsActionService } from '@atv-core/services/cms-action/cms-action.service';
import { DetailTranslationKeys, SettingsKeys, TimeTranslationKeys } from '@atv-bootstrap/services/config';
import { ConfigService } from '@atv-bootstrap/services/config/config.service';
import { ListPageResolverService } from '@atv-core/services/list-page-resolver/list-page-resolver.service';
import { MiniDetailService } from '@atv-core/services/mini-detail/mini-detail.service';
import { EpgUtilityService } from '@atv-core/utility/epg-utility/epg-utility.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { ContentFilters } from '@atv-shared/filters/filters.model';
import { MiniDetailModel } from '@atv-shared/mini-detail/mini-detail.component';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ChannelRightCacheService } from '@atv-core/services/cache/channelRights';
import { StbCacheService } from '@atv-core/services/cache/stb';
import { CardConfig, CardModel } from './generic-card.model';
import { PagePropertiesUtility } from '@atv-core/utility/page-properties-utility';
import { RecordingCardModel } from '@atv-shared/recording-card/recording-card.model';
import { SearchRecordingElement } from '@atv-core/api/search/search-api.model';
import { CardModelFactoryService } from '@atv-shared/card-model/card-model-factory-service';
import { DetailRoutes } from '@atv-detail/atv-detail.model';

export interface ScheduleChannel {
  schedule: EpgScheduleSummaryAsset;
  channel: ChannelModel;
  program?: Ribbon2Detail;
}

@Component({
  selector: 'app-generic-card[cardSize]',
  templateUrl: './generic-card.component.html',
  styleUrls: ['./generic-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('card') cardRef: ElementRef<HTMLAnchorElement>;

  @Input() ribbonProperties: Properties[];
  @Input() pageProperties: Properties[];

  @Input() channelCardMode: ChannelCardModes;

  @Input() cardOrientation: CardOrientation = CardOrientation.LANDSCAPE;
  @Input() cardSize: CardSize;

  @Input() allowedAdultMode: AdultMode;
  @Input() isOnDetailPage = false;
  @Input() scrollElement: HTMLElement;
  @Input() recordingCardModel: RecordingCardModel | SearchRecordingElement;
  @Input() isOnSearchPage = false;

  // init enums to use in .html
  ChannelCardModes = ChannelCardModes;
  CardOrientation = CardOrientation;
  CardSize = CardSize;

  public cardModel: CardModel;
  public showImage = false;
  public showFullDetail = true;
  // for nowo NEBINT-4944
  public useOldEpgCardContent = false;
  private rootItem: Ribbon2Asset;
  private bookmarkModelBookmark: BookmarkUpdate;
  private intersectionObserver: IntersectionObserver;
  private stbRecordingChangeSubscription?: Subscription;
  // TODO refactor
  private miniDetailModel: Observable<MiniDetailModel>;

  private readonly showInfo: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private config: ConfigService,
    private bookmarkCache: BookmarkCacheService,
    private adultService: AdultService,
    private actionService: CmsActionService,
    private epgUtility: EpgUtilityService,
    private stbCache: StbCacheService,
    private channelRightService: ChannelRightCacheService,
    private router: Router,
    private favoriteCacheService: FavoriteCacheService,
    private miniDetailService: MiniDetailService,
    private listPageResolver: ListPageResolverService,
    private channelCacheService: ChannelCacheService,
    private cardModelFactory: CardModelFactoryService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.useOldEpgCardContent = this.config.getSettingBoolean(SettingsKeys.useOldEpgCards, false);
    this.showInfo = this.config.getSettingBoolean(SettingsKeys.showVodTitles, false);
  }

  @Input() set asset(value: Ribbon2Asset) {
    if (value.asset) {
      this.rootItem = value;
      this.cardModel = new CardModel(this.cardOrientation, this.cardSize);
      switch (value.asset.type) {
        case CmsContentTypes.CHANNEL:
        case CmsContentTypes.PROGRAM:
        case CmsContentTypes.SERIES:
        case CmsContentTypes.SCHEDULE:
        case CmsContentTypes.RECORDING:

          if (this.cardModelFactory.showScheduleInfo(this.getCardConfig()) || this.cardModelFactory.useBestSchedule(this.getCardConfig())) {
            this.channelCacheService.getChannels().subscribe(channels => {
              this.createEpgCard(value, channels);
              this.detectChanges();
            });
          } else {
            this.createEpgCard(value);
          }
          break;
        case CmsContentTypes.BUNDLE:
        case CmsContentTypes.VOD:
          this.createVodCard(value);
          break;
        case CmsContentTypes.ARTICLE:
        case CmsContentTypes.PAGE:
          this.createGenericCard(value);
          break;
      }

      this.detectChanges();
    } else {
      this.handleRecordingInput(value);
    }
  }

  public ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.cardRef) {
      const intersectionSubject = new Subject<boolean>();
      intersectionSubject
        .pipe(debounceTime(SharedUtilityService.isSmartTv() ? 500 : 0))
        .subscribe((isVisible) => {
          if (isVisible) {
            this.showImage = true;
            this.intersectionObserver.disconnect();
            this.intersectionObserver = null;
            this.detectChanges();
            intersectionSubject.unsubscribe();
          }
        });

      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          if (entries.length > 0) {
            intersectionSubject.next(entries[0].isIntersecting);
          }
        },
        {
          root: this.cardRef.nativeElement.closest('.horizontal-scroller')?.parentElement,
          rootMargin: '400px',
        },
      );
      this.intersectionObserver.observe(this.cardRef.nativeElement);
    }
  }

  public doAction(event: Event): void {
    event.preventDefault();

    if (this.rootItem.asset.type === CmsContentTypes.CHANNEL) {
      if (this.channelCardMode === ChannelCardModes.LOGO) {
        const filters = {};
        filters[ContentFilters.CHANNEL] = this.rootItem.asset.id;

        const route = this.listPageResolver.getListRouteForCurrentTopRoute();
        if (route) {
          this.router.navigate([route], {
            queryParams: filters,
          }).then();
        }
        return;
      } else if (this.channelCardMode === ChannelCardModes.TOGGLE) {
        this.toggleFavorite();
        return;
      }
    }

    if (this.rootItem.asset.type === CmsContentTypes.RECORDING && this.rootItem.resources.length > 1) {
      if (this.isOnSearchPage) {
        this.router.navigate(['recordings', this.rootItem.asset.id]).then();
      } else {this.router.navigate([
        this.removePathParams(this.router.url),
        this.rootItem.asset.id,
      ], {
        replaceUrl: window.location.pathname.includes(DetailRoutes.detailBaseRoute),
      }).then();}
      return;
    }

    if (this.cardModel && this.cardModel.action) {
      this.actionService.doAction(this.cardModel.action);
    }

    if (SharedUtilityService.isSmartTv()) {
      this.miniDetailService.clearDetail();
    }
  }

  public isMiniDetailEnabled(): boolean {
    // only show on smart tv
    // don't show if inside detail page and showMiniDetailInsideDetail is false
    return (
      SharedUtilityService.isSmartTv() &&
      (!this.isOnDetailPage ||
       this.config.getSettingBoolean(SettingsKeys.showMiniDetailInsideDetail, false))
    );
  }

  public showProgress(): boolean {
    return PagePropertiesUtility.containsProperty(Properties.SHOWPROGRESS, this.ribbonProperties, this.pageProperties);
  }

  public onFocus(): void {
    if (SharedUtilityService.isSmartTv() && this.miniDetailModel) {
      this.miniDetailService.setDetail(this.miniDetailModel);
    }
  }

  public onFocusOut(): void {
    if (SharedUtilityService.isSmartTv()) {
      this.miniDetailService.clearDetail();
    }
  }

  isRecordingFolder(): boolean {
    return (this.rootItem.asset.type === CmsContentTypes.RECORDING && this.rootItem.resources.length > 1);
  }

  getFolderNumber(): number {
    return this.rootItem.resources.length;
  }

  isChannelBackgroundImg(): boolean {
    return this.cardModel.action && this.cardModel.action.actionType === CmsContentTypes.CHANNEL;
  }

  public getVodShortInfo(
    year: number,
    duration: number,
    genres: string[],
    imdbScore: number,
  ): string {
    const fields = [];

    fields.push(year);
    fields.push(this.getDurationString(duration));
    fields.push(this.getImdbScoreString(imdbScore));
    fields.push(this.getGenresInfo(genres));

    return fields.filter((f) => f).join('  •  ');
  }

  private createVodCard(vod: Ribbon2Asset): void {
    const factory = this.cardModelFactory.createCardModelFactory(vod.asset.type, this.getCardConfig());
    factory.createCard(vod, this.cardModel);

    if (this.showProgress()) {
      this.setProgress(BookmarkContentTypes.VOD, vod.asset.id);
    }

    this.setMiniDetail(vod.asset);
  }

  private createEpgCard(epg: Ribbon2Asset, channels?: ChannelModel[]): void {
    // TODO fix channel cards (TOGGLE and LOGO cards)
    const scheduleChannel = this.cardModelFactory.getScheduledInfo(epg, this.getCardConfig(), channels);
    const factory = this.cardModelFactory.createCardModelFactory(epg.asset.type, this.getCardConfig());
    factory.createCard(epg, this.cardModel, scheduleChannel);

    const cardData = scheduleChannel?.program ? scheduleChannel?.program : epg.asset;
    this.setFavoriteIcon(cardData);

    if (scheduleChannel?.program &&
        !this.epgUtility.scheduleIsLive(scheduleChannel.schedule) &&
        this.showProgress()) {
      this.setProgress(BookmarkContentTypes.PROGRAM, scheduleChannel.program.id);
    }

    if (scheduleChannel?.program) {
      this.setRecordingInformation(scheduleChannel.program, scheduleChannel.schedule, scheduleChannel.channel);
    }

    this.setMiniDetail(cardData);
  }

  private createGenericCard(item: Ribbon2Asset): void {
    const factory = this.cardModelFactory.createCardModelFactory(item.asset.type, this.getCardConfig());
    factory.createCard(item, this.cardModel);
    this.setMiniDetail(item.asset);
  }

  private setProgress(type: BookmarkContentTypes, id: string): void {
    this.loadBookmarkFromCache(type, id);
    this.bookmarkCache.bookmarkUpdateEvent.subscribe(() => {
      this.loadBookmarkFromCache(type, id);
    });
  }

  private setFavoriteIcon(asset: Ribbon2Detail): void {
    if (this.channelCardMode === ChannelCardModes.TOGGLE && asset.type === CmsContentTypes.CHANNEL) {
      this.getFavoriteIcon(FavoriteContentTypes.CHANNEL, asset.id).subscribe(icon => this.cardModel.favoriteIcon = icon);
    }
  }

  private setRecordingInformation(
    program: Ribbon2Detail,
    schedule: EpgScheduleSummaryAsset,
    channel: ChannelModel,
  ): void {
    if (program && channel) {
      this.setProgressPercentageRecording(program, schedule);

      this.cardModel.icons = [];

      this.stbCache
        .getRecordingForProgram(program.id, channel.id, this.adultService.showAdult(AdultMode.any))
        .subscribe(
          (recording) => {
            if (recording) {
              this.cardModel.icons.push(recording.getIcon());
            }
            if (!this.isRecordingFolder()) {
              this.channelRightService
                .getPlayIcon(schedule, channel, recording)
                .subscribe((result) => {
                  this.cardModel.icons.push(result);
                  this.detectChanges();
                });
            }
            // TODO show play button
            // this.cardModel.showPlayButton = this.showPlayButton();
            this.detectChanges();
          },
          () => {
            this.detectChanges();
            this.channelRightService
              .getPlayIcon(schedule, channel, undefined)
              .subscribe((result) => {
                this.cardModel.icons.push(result);
                this.detectChanges();
              });
          },
        );
    }
  }

  private setProgressPercentageRecording(
    program: Ribbon2Detail,
    schedule: EpgScheduleSummaryAsset,
  ): void {
    if (this.showProgress() && this.bookmarkModelBookmark) {
      this.cardModel.progressPercentage =
        (this.bookmarkModelBookmark.position / this.bookmarkModelBookmark.size) * 100;
    } else if (program && schedule && this.epgUtility.scheduleIsLive(schedule)) {
      this.cardModel.progressPercentage = this.epgUtility.calculateProgressPercentage(schedule.published);
      this.detectChanges();
    }
  }

  private getCardConfig(): CardConfig {
    return {
      ribbonProperties: this.ribbonProperties,
      pageProperties: this.pageProperties,
      allowedAdultMode: this.allowedAdultMode,
      useOldEpgCardContent: this.useOldEpgCardContent,
      showInfo: this.showInfo,
    };
  }

  private removePathParams(url: string): string {
    const params = this.activatedRoute.snapshot.params;

    if (params) {
      const values = Object.values(params);
      if (values.every((value) => url.includes(value))) {
        values.forEach((value) => (url = url.replace('/' + value, '')));
      }
    }
    return url;
  }

  private loadBookmarkFromCache(type: BookmarkContentTypes, id: string): void {
    this.bookmarkCache
      .getBookmark(type, id, this.adultService.showAdult(this.allowedAdultMode))
      .subscribe((result) => {
        if (result && this.cardModel) {
          this.cardModel.progressPercentage = (result.position / result.size) * 100;
          this.detectChanges();
        }
      });
  }

  private toggleFavorite(): void {
    const favoriteContentType: FavoriteContentTypes = this.cmsToFavoriteContentType(this.rootItem.asset.type);

    this.favoriteCacheService
      .isFavorite(favoriteContentType, this.rootItem.asset.id)
      .subscribe((isFavorite) => {
        if (!isFavorite) {
          this.cardModel.favoriteIcon = './assets/theme/svg/favoritesicon_selected.svg';
          this.favoriteCacheService.setFavorite(favoriteContentType, this.rootItem.asset.id, this.rootItem.asset.adult);
        } else {
          this.cardModel.favoriteIcon = './assets/theme/svg/favoritesicon_normal.svg';
          this.favoriteCacheService.deleteFavorite(favoriteContentType, this.rootItem.asset.id);
        }
      });
  }

  private getFavoriteIcon(type: FavoriteContentTypes, id: string): Observable<string> {
    return this.favoriteCacheService
      .isFavorite(type, id)
      .pipe(map((result) => SharedUtilityService.getFavoriteIcon(result)));
  }

  private cmsToFavoriteContentType(type: CmsContentTypes): FavoriteContentTypes {
    switch (type) {
      case CmsContentTypes.VOD:
        return FavoriteContentTypes.VOD;
      case CmsContentTypes.PROGRAM:
        return FavoriteContentTypes.PROGRAM;
      case CmsContentTypes.SERIES:
        return FavoriteContentTypes.SERIES;
      case CmsContentTypes.CHANNEL:
        return FavoriteContentTypes.CHANNEL;
      default:
        throw new Error(`invalid favorite content type ${type}`);
    }
  }

  private detectChanges(): void {
    // prevent change detection from firing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  // -----------------------------------------------------------

  private setMiniDetail(asset: Ribbon2Detail): void {
    if (this.isMiniDetailEnabled()) {
      let favoriteType: FavoriteContentTypes;

      try {
        favoriteType = this.cmsToFavoriteContentType(asset.type);
      } catch (e) {
        favoriteType = undefined;
      }

      this.miniDetailModel = (favoriteType ? this.getFavoriteIcon(
        favoriteType,
        asset.id,
      ) : of(undefined))
        .pipe(
          map((result) => ({
            title: asset.title,
            favoriteIcon: result,
            icons: this.cardModel.icons,
            row1: asset.type === CmsContentTypes.VOD ?
              this.getVodShortInfo(asset.productionYear, asset.duration, asset.genres, asset.score) : this.cardModel.row1,
            row2: this.cardModel.row2,
            progressPercentage: this.cardModel.progressPercentage,
            description: asset.synopsis,
            episodeTitle: asset.episodeTitle,
          })),
        );
    }
  }

  private handleRecordingInput(recordingData): void {
    if (recordingData.type === CmsContentTypes.RECORDING ||
        (recordingData.asset && recordingData.asset.type === CmsContentTypes.RECORDING)
    ) {
      this.recordingCardModel = recordingData;
    }
  }

  // TODO move this function
  private getGenresInfo(genres: string[]): string {
    if (!genres) {
      return '';
    }

    return genres
      .map((g) => this.config.getGenre(g))
      .filter((g) => g)
      .join('  •  ');
  }

  // TODO move this function
  private getImdbScoreString(imdbScore: number): string {
    if (imdbScore == null) {
      return undefined;
    }

    return `${this.config.getTranslation(DetailTranslationKeys.detail_imdb_rating)} ${
      imdbScore / 10
    }/10`;
  }

  // TODO move this function
  private getDurationString(duration: number): string {
    return duration
      ? Math.trunc(duration / 60) +
        ' ' +
        this.config.getTranslation(TimeTranslationKeys.time_minutes_short)
      : undefined;
  }

  ngOnDestroy(): void {
    this.intersectionObserver?.disconnect();
    this.stbRecordingChangeSubscription?.unsubscribe();
  }
}
