export interface FavoriteElement {
  id?: string;
  type?: FavoriteContentTypes;
  adult?: boolean;
  schedules?: ScheduleTime[];
  lastUpdate: string;
}

export interface BookmarkElement {
  type: BookmarkContentTypes;
  id: string;
  series?: string;
  position?: number;
  size?: number;
  adult?: boolean;
  schedules?: ScheduleTime[];
  lastUpdate: string;
}

export interface BookmarkUpdate {
  series?: string;
  position?: number;
  size?: number;
}

export interface ScheduleTime {
  start: string;
  end: string;
}

export enum FavoriteContentTypes {
  VOD = 'VOD',
  PROGRAM = 'PROGRAM',
  SERIES = 'SERIES',
  CHANNEL = 'CHANNEL',
}

export enum FavoriteCacheType {
  CHANNEL = 'CHANNEL',
  EPG = 'EPG',
  VOD = 'VOD'
}

export enum BookmarkContentTypes {
  VOD = 'VOD',
  PROGRAM = 'PROGRAM',
}

export enum ReminderContentTypes {
  SCHEDULE = 'SCHEDULE',
}

export interface DeviceSettingResult {
  name?: string;
  value: string;
  lastUpdate: string;
}

export interface CustomerSettingResult {
  name?: string;
  value: string;
  lastUpdate: string;
}

export interface ReminderElement {
  type: ReminderContentTypes;
  id: string;
  minutes?: number;
}

export interface ReminderUpdate {
  minutes?: number;
}
