import { ContentProvider } from '@atv-shared/content-provider/content-provider';
import { Observable, of } from 'rxjs';
import { Ribbon2Asset } from '@atv-core/api/cms';
import { SearchApiService } from '@atv-core/api/search/search-api.service';
import { map } from 'rxjs/operators';
import { SearchElementList } from '@atv-core/api/search/search-api.model';
import { AdultService, AdultMode } from '@atv-core/services/adult';
import { SessionService } from '@atv-core/services/session';

export class SearchContentProvider extends ContentProvider {
  private hasMore = true;
  private page = 0;

  constructor(
    private searchApi: SearchApiService,
    private adultService: AdultService,
    private sessionService: SessionService,
    private searchElements: SearchElementList,
    private term: string,
    private allowedAdultMode: AdultMode,
  ) {
    super();

    this.orientation = this.searchElements.elements.type;
  }

  public getItems(): Observable<Ribbon2Asset[]> {
    if (this.page === 0) {
      this.hasMore = this.searchElements.elements.nextPage;
      this.page += 1;
      return of(this.searchElements.elements.items);
    } else {
      return this.searchApi.search(
        this.term,
        new Set([this.searchElements.type]),
        this.adultService.getAdultMode(this.allowedAdultMode),
        this.sessionService.getCatalogId(),
        false,
        this.page,
      ).pipe(
        map(result => {
          if (!result || !result.results || result.results.length === 0) {
            this.hasMore = false;
            return [];
          }
          this.hasMore = result.results[0].elements.nextPage;
          this.page += 1;

          return result.results[0].elements.items;
        })
      );
    }
  }

  public hasNext(): boolean {
    return this.hasMore;
  }

  public resetPaging(): void {
    this.hasMore = true;
    this.page = 0;
  }
}
