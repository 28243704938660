import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, ViewRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiApiService } from '@atv-core/api/api/api-api.service';
import { SessionPinRequest, SessionPinRequestTypes } from '@atv-core/api/pricing/pricing.model.service';
import { AdultPopupCallbacks, AdultService } from '@atv-core/services/adult';
import { ConfigService } from '@atv-bootstrap/services/config';
import {
  DetailTranslationKeys,
  ErrorTranslationKeys,
  MyStuffTranslationKeys,
  PopupTranslationKeys,
} from '@atv-bootstrap/services/config/config.model';
import { MessagesService } from '@atv-core/services/messages';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface AdultOptionsModel {
  value: number;
  text: string;
}

@Component({
  selector: 'app-adult-popup',
  templateUrl: './adult-popup.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdultPopupComponent implements OnInit {
  public adultPopupTitle = '';
  public confirmText = '';
  public pincodePlaceholder = '';

  public showAdultPopup = false;
  public adultOptionsText = '';

  public adultOptions: AdultOptionsModel[];
  public selectedNumberOfMinutesAdultMode: number;

  private currentPopupCallBacks: AdultPopupCallbacks;

  @ViewChild('pincodeInput', { static: false })
  private pincodeInput: ElementRef;

  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnSubscription: Subscription = null;
  private returnObservable: Observable<KeyboardEvent>;

  constructor(
    private config: ConfigService,
    private adultService: AdultService,
    private apiApi: ApiApiService,
    private messagesService: MessagesService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private spatialNavigationService: SpatialNavigationService,
    private elementRef: ElementRef
  ) {
    this.adultPopupTitle = this.config.getTranslation(
      MyStuffTranslationKeys.mystuff_adultmode_activate
    );

    this.adultOptionsText = this.config.getTranslation(
      PopupTranslationKeys.popup_pincode_stayinadultmode
    );

    this.confirmText = this.config.getTranslation(PopupTranslationKeys.popup_btn_ok);

    this.pincodePlaceholder = this.config.getTranslation(
      DetailTranslationKeys.detail_enter_pincode
    );

    this.adultService.openAdultPopupEvent.subscribe((callbacks) => {
      this.openPopup(callbacks);
    });

    this.adultOptions = [
      {
        value: 0,
        text: this.config.getTranslation(MyStuffTranslationKeys.mystuff_adultmode_never),
      },
      {
        value: 5,
        text: 5 + ' ' + this.config.getTranslation(MyStuffTranslationKeys.mystuff_minutes),
      },
      {
        value: 10,
        text: 10 + ' ' + this.config.getTranslation(MyStuffTranslationKeys.mystuff_minutes),
      },
      {
        value: 60,
        text: 1 + ' ' + this.config.getTranslation(MyStuffTranslationKeys.mystuff_hour),
      },
      {
        value: 120,
        text: 2 + ' ' + this.config.getTranslation(MyStuffTranslationKeys.mystuff_hours),
      },
    ];
  }

  private detectChanges(): void {
    // prevent change detection from fireing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    if (this.pincodeInput) {
      this.pincodeInput.nativeElement.focus();
    }

    if (this.isSmartTv) {
      this.returnObservable = fromEvent(
        document.getElementsByTagName('app-adult-popup')[0],
        'keyup'
      ).pipe(
        filter((event: KeyboardEvent) =>
          this.spatialNavigationService.keyCodeIsReturn(event.keyCode)
        )
      );
    }
  }

  public openPopup(callbacks: AdultPopupCallbacks): void {
    this.currentPopupCallBacks = callbacks;
    this.showAdultPopup = true;
    this.adultService.setAdultPopupStatus(true);
    this.detectChanges();

    setTimeout(() => {
      this.pincodeInput.nativeElement.value = '';
      if (!this.isSmartTv) {
        this.pincodeInput.nativeElement.focus();
      }
    }, 0);

    this.adultService.getNumberOfMinutesAdultMode().subscribe((result) => {
      const tmp = this.adultOptions.find((item) => item.value === result);
      if (tmp !== undefined) {
        this.selectedNumberOfMinutesAdultMode = tmp.value;
      } else {
        this.selectedNumberOfMinutesAdultMode = undefined;
      }
      this.detectChanges();

      if (this.isSmartTv) {
        this.registerElements();
      }
    });
  }

  public closePopup(): void {
    this.showAdultPopup = false;
    this.adultService.setAdultPopupStatus(false);
    if (this.isSmartTv) {
      this.unregisterElements();
    }

    this.detectChanges();

    if (this.currentPopupCallBacks && this.currentPopupCallBacks.errorCallback) {
      this.currentPopupCallBacks.errorCallback();
    }
    this.currentPopupCallBacks = undefined;

    if (window.location.pathname === '/') {
      this.router.navigateByUrl('/?error=login_failed');
    }

    if (this.returnSubscription) {
      this.returnSubscription.unsubscribe();
      this.returnSubscription = null;
    }
  }

  public tryPincode(value: string): void {
    const pincode: SessionPinRequest = {
      pinCode: value,
      type: SessionPinRequestTypes.ADULT,
    };
    this.apiApi.checkPincode(pincode).subscribe(
      () => {
        this.pinSuccess();
      },
      (errorResponse: HttpErrorResponse) => {
        this.messagesService.showErrorMessage(
          this.config.getTranslation(
            errorResponse && errorResponse.error ? errorResponse.error.code : '',
            ErrorTranslationKeys.AA_INVALID_PINCODE,
          ),
        );
      }
    );
  }

  public pinInputKeyEvent(event: KeyboardEvent): void {
    // when 'done' on screen keyboard or enter is pressed
    if (this.spatialNavigationService.keyCodeIsEnter(event.keyCode)) {
      event.cancelBubble = true;
      event.preventDefault();
      this.tryPincode(this.pincodeInput.nativeElement.value);
      this.pincodeInput.nativeElement.value = '';
    } else if (this.spatialNavigationService.keyCodeIsNumeric(event.keyCode)) {
      event.cancelBubble = true;
    }
  }

  @HostListener('click', ['$event'])
  onClicked(e: MouseEvent): void {
    e.stopPropagation();
  }

  public inputKeyEvent(event: KeyboardEvent): void {
    // when 'done' on screen keyboard or enter is pressed
    if (this.spatialNavigationService.keyCodeIsEnter(event.keyCode)) {
      event.cancelBubble = true;
      event.preventDefault();
      this.spatialNavigationService.move('down');
    }
  }

  private pinSuccess(): void {
    this.showAdultPopup = false;
    this.adultService.setAdultPopupStatus(false);
    if (this.isSmartTv) {
      this.unregisterElements();
    }
    this.detectChanges();

    this.adultService.setAdultModeForMinutes(this.selectedNumberOfMinutesAdultMode);

    if (this.currentPopupCallBacks && this.currentPopupCallBacks.successCallback) {
      this.currentPopupCallBacks.successCallback();
      this.currentPopupCallBacks = undefined;
    }
  }

  private registerElements(): void {
    this.unregisterElements();
    this.spatialNavigationService.register(
      NavigationSections.ADULT_POPUP_OPTIONS,
      '.overlay-dialog .chip',
      {
        restrict: 'self-only',
        leaveFor: { down: '@' + NavigationSections.ADULT_POPUP_PINCODE },
        defaultElement: '.overlay-dialog .chip.selected',
        enterTo: 'default-element',
      }
    );
    this.spatialNavigationService.register(
      NavigationSections.ADULT_POPUP_PINCODE,
      '.overlay-dialog #pwd',
      {
        restrict: 'self-only',
        leaveFor: {
          up: '@' + NavigationSections.ADULT_POPUP_OPTIONS,
        },
      }
    );
    this.spatialNavigationService.setFocus(NavigationSections.ADULT_POPUP_OPTIONS);

    this.returnSubscription = this.returnObservable.subscribe((event) => {
      event.cancelBubble = true;
      this.closePopup();
    });
  }

  private unregisterElements(): void {
    this.spatialNavigationService.unregister(NavigationSections.ADULT_POPUP_OPTIONS);
    this.spatialNavigationService.unregister(NavigationSections.ADULT_POPUP_PINCODE);
    this.spatialNavigationService.setFocus();

    if (this.returnSubscription) {
      this.returnSubscription.unsubscribe();
    }
  }
}
