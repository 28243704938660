import { StreamResolutionType, StreamType } from '@atv-core/utility/constants/shared';

import { VodAssetStreamType } from './../vod/vod-api.model';

export interface VodPriceResponse {
  streams?: StreamPriceResponse[];
  subscriptions?: SubscriptionResponse[];
  upsell?: VodUpsellProduct;
  bundles?: VodPriceBundleResponse[];
}

export interface StreamPriceResponse {
  streamId: string;
  streamType: VodAssetStreamType;
  resolution: StreamResolutionType;
  type: StreamType;
  price?: PriceResponse;
  validity?: number;
}

export interface VodPriceBundleResponse {
  bundleId: string;
  image?: string;
  title?: string;
  resolutions?: VodPriceBundleResolutionResponse[];
}

interface SubscriptionResponse {
  streamId: string;
  type: StreamType;
  products: string[];
}

export interface VodUpsellProduct {
  productId: string;
  preference?: number;
  name: string;
  image?: string;
}

export interface PriceResponse {
  originalPrice?: number;
  customerPrice?: number;
}
interface VodPriceBundleResolutionResponse {
  id: string;
  resolution: StreamResolutionType;
}

export interface ValidQuotePriceResponse {
  quoteId: string;
  quoteType: QuoteTypes;
  entryId: string;
  startTime: string;
  endTime: string;
  assetId: string;
}

export enum QuoteTypes {
  TVOD = 'TVOD',
  BUNDLE = 'BUNDLE',
}

export interface CommitQuote {
  assetId: string;
  pinRequest: SessionPinRequest;
}
export interface SessionPinRequest {
  pinCode: string;
  type: SessionPinRequestTypes;
}

export interface SessionChangePinRequest {
  type: SessionPinRequestTypes;
  oldPincode: string;
  newPincode: string;
}

export enum SessionPinRequestTypes {
  VOD = 'VOD',
  ADULT = 'ADULT',
}

export interface QuotePriceResponse {
  quoteId: string;
  quoteType: QuoteTypes;
  entryId: string;
  purchased?: boolean;
  startTime: string;
  endTime: string;
  assets?: AssetPriceResponse[];
}
export interface AssetPriceResponse {
  assetId: string;
  type: QuoteTypes;
  price?: PriceResponse;
  validity?: number;
}

export interface BundlePriceResponse {
  resolutions?: ResolutionPriceResponse[];
}

export interface ResolutionPriceResponse {
  resolutionId: string;
  resolution: StreamResolutionType;
  type: StreamType;
  price?: PriceResponse;
  validity?: number;
}

export interface CreateVodQuote3 {
  deviceId: string;
}

export interface CreateBundleQuote3 {
  deviceId: string;
}
