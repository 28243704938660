import { Injectable } from '@angular/core';
import { CmsAction, CmsActionMethod, CmsContentTypes } from '@atv-core/api/cms/cms-api.model';
import { BookmarkContentTypes } from '@atv-core/api/history';
import { PricingApiService } from '@atv-core/api/pricing/pricing-api.service';
import { VodApiService } from '@atv-core/api/vod';
import { AdultMode, AdultService } from '@atv-core/services/adult';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { BookmarkCacheService, BookmarkModel } from '@atv-core/services/cache/bookmark';
import { QuoteCacheService } from '@atv-core/services/cache/quote/quote-cache.service';
import { ConfigService, ErrorTranslationKeys } from '@atv-bootstrap/services/config';
import { MessagesService } from '@atv-core/services/messages';
import { PlayerService } from '../../player/player.service';
import { SessionService } from '@atv-core/services/session';
import { StreamType } from '@atv-core/utility/constants/shared';
import { VodDetailModel } from '@atv-detail/vod-detail/vod-detail.model';
import { VodStreamRightsModel } from '@atv-detail/vod-detail/vod-stream-rights.model';
import { PlayInfoModel } from '../../../../atv-player/model/play-info-model';
import { environment } from '@env/environment';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CmsActionHandler } from '../cms-action.model';

@Injectable({ providedIn: 'root' })
export class PlayVodAction extends CmsActionHandler {
  constructor(
    private vodApi: VodApiService,
    private quoteCache: QuoteCacheService,
    private pricingApi: PricingApiService,
    private sessionService: SessionService,
    private auth: AuthorizationService,
    messagesService: MessagesService,
    config: ConfigService,
    adultService: AdultService,
    playerService: PlayerService,
    private bookmarkCache: BookmarkCacheService
  ) {
    super(adultService, playerService, messagesService, config);
  }

  handlesMethod(method: CmsActionMethod): boolean {
    return method === CmsActionMethod.PLAY;
  }

  handlesType(type: CmsContentTypes): boolean {
    return type === CmsContentTypes.VOD;
  }

  handleAction(action: CmsAction): void {
    forkJoin([
      this.vodApi.getVodDetail(action.actionId),
      this.quoteCache.getQuotes(true),
      this.pricingApi.getVodPrice(
        action.actionId,
        environment.platform,
        this.sessionService.getEntitlementId()
      ),
    ]).subscribe((result) => {
      const vodDetail = new VodDetailModel(result[0]);
      const vodStreamRights = new VodStreamRightsModel(vodDetail.id, result[1], result[2]);

      if (!this.auth.isAuthorized() && !vodStreamRights.hasWatchableOttFVOD()) {
        this.auth.showAuthPopup.emit();
        return;
      }

      if (!vodStreamRights.hasWatchableOttTVOD() && vodDetail.isExpired()) {
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_vod_expired)
        );
        return;
      }

      const stream = vodStreamRights.getWatchableOttStream();

      if (!stream || !stream.getStreamType() || !stream.getStreamId()) {
        this.messagesService.showErrorMessage(
          this.config.getTranslation(ErrorTranslationKeys.error_no_play_rights_vod)
        );
        return;
      }

      this.bookmarkCache
        .getBookmarkNoCache(
          BookmarkContentTypes.VOD,
          vodDetail.id,
          this.adultService.showAdult(AdultMode.any)
        )
        .pipe(catchError(() => of(undefined as BookmarkModel)))
        .subscribe((bookmark) => {
          const bookmarkPosition = bookmark && !bookmark.isFullyWatched() ? bookmark.position : 0;
          const playInfo = new PlayInfoModel(
            stream.getStreamType(),
            stream.getStreamId(),
            bookmarkPosition
          );

          const quoteId =
            playInfo.streamType === StreamType.TVOD && stream.selectValidQuote()
              ? stream.selectValidQuote().quoteId
              : '';

          playInfo.setVodInfo(vodDetail, quoteId, false, false, vodDetail.adult);
          this.checkAdultAndPlay(playInfo);
        });
    });
  }
}
