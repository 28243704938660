import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';

import { MainModule } from './main/main.module';

// for angular 8 in aot build the lazy loaded modules have to be defined in the router module first
// when only defining them in a router.resetConfig the compiler will not compile the modules
const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('src/app/account-smarttv/account-smarttv.module').then((m) => m.AccountSmarttvModule),
  },
  {
    path: '**',
    loadChildren: () => import('src/app/home/home.module').then((m) => m.HomeModule),
  },
];

@NgModule({
  imports: [
    CommonModule,
    MainModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      enableTracing: false,
      preloadingStrategy: SharedUtilityService.isSmartTv() ? PreloadAllModules : NoPreloading,
    }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
