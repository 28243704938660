import { Ribbon2Asset } from '@atv-core/api/cms';
import { CardConfig, CardModel } from '@atv-shared/generic-card/generic-card.model';
import { ConfigService, RibbonTranslationKeys } from '@atv-bootstrap/services/config';
import { CardModelFactory } from '@atv-shared/card-model/card-model-factory';
import { CardModelFactoryService } from '@atv-shared/card-model/card-model-factory-service';

export class VodCardModelFactory extends CardModelFactory {

  constructor(private cardModelFactory: CardModelFactoryService, private config: ConfigService, private cardConfig: CardConfig) {
    super();
  }

  public createCard(item: Ribbon2Asset, cardModel: CardModel): void {
    cardModel.image = this.cardModelFactory.getImageUrl(item.asset,
      this.cardModelFactory.getImageRecipe(item.asset.type, cardModel.orientation, cardModel.size), cardModel.orientation,
      this.cardConfig.allowedAdultMode);
    this.cardModelFactory.setBackdropImage(item.asset, this.cardConfig.allowedAdultMode);

    cardModel.row1 = this.getVodRow1(item.asset.title);
    cardModel.row2 = this.getVodRow2(item.asset.season, item.asset.episode);

    cardModel.action = {
      actionId: item.asset.id,
      actionMethod: item.method,
      actionType: item.asset.type,
    };
  }

  private getVodRow1(title: string): string {
    if (!this.cardModelFactory.showInfo(this.cardConfig)) {
      return undefined;
    } else {
      return title;
    }
  }

  private getVodRow2(season: number, episode: number): string {
    if (this.cardModelFactory.showEpisodeInfo(this.cardConfig)) {
      return this.getSeasonEpisodeShortString(season, episode);
    } else {
      return undefined;
    }
  }

  private getSeasonEpisodeShortString(season: number, episode: number): string {
    let res: string;
    if (season && episode) {
      res = this.config
        .getTranslation(RibbonTranslationKeys.ribbon_season)
        .replace('$1', season.toString());

      res +=
        ' ' +
        this.config
          .getTranslation(RibbonTranslationKeys.ribbon_episode)
          .replace('$1', episode.toString());
    }

    return res;
  }
}
