export enum ImageRecipe {
  ARTICLE_WALLPAPER_2 = 'article-wallpaper-2', // TODO use imageset
  AVATAR_1 = 'avatar-1',
  AVATAR_2 = 'avatar-2',
  AVATAR_3 = 'avatar-3',
  BANNER_7_1_1 = 'banner-7x1-1',
  BANNER_7_1_2 = 'banner-7x1-2',
  BANNER_7_1_3 = 'banner-7x1-3',
  BANNER_7_1_4 = 'banner-7x1-4',
  BANNER_7_2_1 = 'banner-7x2-1',
  BANNER_7_2_2 = 'banner-7x2-2',
  BANNER_7_2_3 = 'banner-7x2-3',
  BANNER_7_2_4 = 'banner-7x2-4',
  BANNER_7_3_1 = 'banner-7x3-1',
  BANNER_7_3_2 = 'banner-7x3-2',
  BANNER_7_3_3 = 'banner-7x3-3',
  BANNER_7_3_4 = 'banner-7x3-4',
  BREAKVERTISING_1 = 'breakvertising-1',
  CHANNEL_1 = 'channel-1',
  CHANNEL_SQUARE_1 = 'channel-square-1',
  CUSTOM_BACKGROUND_1 = 'custom-background-1',
  CUSTOM_BACKGROUND_2 = 'custom-background-2',
  CUSTOM_BACKGROUND_3 = 'custom-background-3',
  DETAIL_BACKDROP_1 = 'detail-backdrop-1',
  EPG_CARD_3 = 'epg-card-3',
  UPSELL_1 = 'upsell-1',
  VOD_CARD_3 = 'vod-card-3',
  CARD_LANDSCAPE_SMALL_2 = 'card-landscape-small-2',
  CARD_LANDSCAPE_MEDIUM_2 = 'card-landscape-medium-2',
  CARD_LANDSCAPE_NORMAL_2 = 'card-landscape-normal-2',
  CARD_LANDSCAPE_LARGE_2 = 'card-landscape-large-2',
  CARD_LANDSCAPE_EXTRA_LARGE_2 = 'card-landscape-extra-large-2',
  CARD_PORTRAIT_SMALL_2 = 'card-portrait-small-2',
  CARD_PORTRAIT_MEDIUM_2 = 'card-portrait-medium-2',
  CARD_PORTRAIT_NORMAL_2 = 'card-portrait-normal-2',
  CARD_PORTRAIT_LARGE_2 = 'card-portrait-large-2',
  CARD_PORTRAIT_EXTRA_LARGE_2 = 'card-portrait-extra-large-2',
}

export enum ImageRecipeCategory {
  BANNER_7_1,
  BANNER_7_2,
  BANNER_7_3,
  CUSTOM_BACKGROUND
}

export interface ImageRecipeInfo {
  readonly recipe: ImageRecipe;
  readonly width: number;
}

export class ImageRecipeUtil {
  public static createImageUrl(url: string, recipe: ImageRecipe): string {
    return `${url}?form=${recipe}`;
  }

  public static getSourceSet(url: string, category: ImageRecipeCategory): string {
    return this.getImageRecipeSet(category)
      ?.map(recipe => `${ImageRecipeUtil.createImageUrl(url, recipe.recipe)} ${recipe.width}w`)
      .join(', ');
  }

  private static getImageRecipeSet(category: ImageRecipeCategory): ImageRecipeInfo[] {
    switch (category) {
      case ImageRecipeCategory.BANNER_7_1:
        return [
          { recipe: ImageRecipe.BANNER_7_1_1, width: 525 },
          { recipe: ImageRecipe.BANNER_7_1_2, width: 1280 },
          { recipe: ImageRecipe.BANNER_7_1_3, width: 2100 },
          { recipe: ImageRecipe.BANNER_7_1_4, width: 4096 },
        ];
      case ImageRecipeCategory.BANNER_7_2:
        return [
          { recipe: ImageRecipe.BANNER_7_2_1, width: 525 },
          { recipe: ImageRecipe.BANNER_7_2_2, width: 1280 },
          { recipe: ImageRecipe.BANNER_7_2_3, width: 2100 },
          { recipe: ImageRecipe.BANNER_7_2_4, width: 4096 },
        ];
      case ImageRecipeCategory.BANNER_7_3:
        return [
          { recipe: ImageRecipe.BANNER_7_3_1, width: 525 },
          { recipe: ImageRecipe.BANNER_7_3_2, width: 1280 },
          { recipe: ImageRecipe.BANNER_7_3_3, width: 2100 },
          { recipe: ImageRecipe.BANNER_7_3_4, width: 4096 },
        ];
      case ImageRecipeCategory.CUSTOM_BACKGROUND:
        return [
          { recipe: ImageRecipe.CUSTOM_BACKGROUND_1, width: 1280 },
          { recipe: ImageRecipe.CUSTOM_BACKGROUND_2, width: 1920 },
          { recipe: ImageRecipe.CUSTOM_BACKGROUND_3, width: 3840 },
        ];
      default:
        return;
    }
  }
}
