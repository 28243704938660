import { Injectable } from '@angular/core';
import { EpgApiService } from '@atv-core/api/epg';
import { AdultMode } from '@atv-core/services/adult/adult.service';
import { FavoriteCacheService } from '@atv-core/services/cache/favorite';
import { SessionService } from '@atv-core/services/session';
import { environment } from '@env/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, shareReplay, switchMap } from 'rxjs/operators';

import { AdultService } from './../../adult/adult.service';
import { ChannelModel } from './channel.model';

@Injectable({ providedIn: 'root' })
export class ChannelCacheService {
  private reloadingCache = false;
  private channelCache: ChannelModel[] = [];
  private cachePromise: Observable<any> = undefined;
  private cacheExpirationTime = 120000; // set channel cache on 2minutes;
  private expirationTime = 0;

  constructor(
    private epgApi: EpgApiService,
    private favoriteCache: FavoriteCacheService,
    private adulService: AdultService,
    private sessionService: SessionService
  ) {
    this.sessionService.clearAllCachesEvent.subscribe(() => {
      this.clearCache();
    });
  }

  private checkCache(): void {
    // cache not expired
    if (new Date().getTime() > this.expirationTime) {
      this.clearCache();
    }

    this.cachePromise = this.cachePromise || this.fillCache();
  }

  private fillCache(): Observable<any> {
    return forkJoin([
      this.epgApi.getChannels(),
      this.epgApi.getChannelRights(
        environment.platform,
        this.sessionService.getEntitlementId(),
        environment.allowAdult ? AdultMode.any : AdultMode.false
      ),
    ]).pipe(
      mergeMap((result) => {
        this.expirationTime = new Date().getTime() + this.cacheExpirationTime;

        this.channelCache = result[0].map((channel) => {
          const ch = new ChannelModel(channel);
          result[1].some((right) => {
            if (right.id === ch.id) {
              ch.customerOttChannelRights = right.rights;
              return true;
            }
          });
          return ch;
        });
        this.reloadingCache = false;
        return of([]);
      }),
      catchError((e) => {
        this.expirationTime = 0;
        this.reloadingCache = false;
        throw e;
      }),
      shareReplay() // prevent switchmap to execute multiple times
    );
  }

  public getChannels(): Observable<ChannelModel[]> {
    this.checkCache();

    return this.cachePromise.pipe(
      switchMap(() => {
        return this.prepareChannels().pipe(
          map((x) => {
            return this.channelCache;
          })
        );
      })
    );
  }

  private prepareChannels(): Observable<any> {
    // if favorites are fetched
    return this.favoriteCache.getFavoritesForChannel().pipe(
      map(
        (favorites) => {
          favorites.forEach((favorite) => {
            const channel = this.channelCache.find((c) => c.id === favorite.id);
            if (channel) {
              channel.setFavorite(!channel.adult || this.adulService.showAdult(AdultMode.any));
            }
          });

          this.channelCache.sort((a, b) => {
            // NEBINT-5582 Web - Channel sorting on guide
            const numberA =
              a.getChannelNumberForCatalog(this.sessionService.getCatalogId()) ||
              Number.MAX_SAFE_INTEGER;
            const numberB =
              b.getChannelNumberForCatalog(this.sessionService.getCatalogId()) ||
              Number.MAX_SAFE_INTEGER;

            if (a.isFavorite() === b.isFavorite()) {
              return numberA < numberB ? -1 : numberA > numberB ? 1 : 0;
            } else {
              return a.isFavorite() ? -1 : 1;
            }
          });
        },
        () => {
          this.channelCache.sort((a, b) => {
            // NEBINT-5582 Web - Channel sorting on guide
            const numberA =
              a.getChannelNumberForCatalog(this.sessionService.getCatalogId()) ||
              Number.MAX_SAFE_INTEGER;
            const numberB =
              b.getChannelNumberForCatalog(this.sessionService.getCatalogId()) ||
              Number.MAX_SAFE_INTEGER;

            if (a.isFavorite() === b.isFavorite()) {
              return numberA < numberB ? -1 : numberA > numberB ? 1 : 0;
            } else {
              return a.isFavorite() ? -1 : 1;
            }
          });
        }
      ),
      catchError((e) => {
        return of([]);
      })
    );
  }

  public clearCache() {
    if (this.reloadingCache) {
      return;
    }

    this.channelCache = [];
    this.expirationTime = 0;
    this.cachePromise = undefined;
    this.reloadingCache = true;
  }
}
