<div
  id="container"
  [class.open]="currentMiniDetail !== undefined"
  [class.is-on-detail-page]="isFullPage()">
  <div class="left">
    <div class="row0">
      <img
        *ngIf="currentMiniDetail?.favoriteIcon"
        class="favorite-icon"
        src="{{ currentMiniDetail?.favoriteIcon }}"
      />
      <div class="title">{{ currentMiniDetail?.title }}</div>
      <img class="icons" src="{{ icon }}" *ngFor="let icon of currentMiniDetail?.icons" />
    </div>
    <app-progress-bar
      class="progress"
      [class.hidden]="!currentMiniDetail || currentMiniDetail.progressPercentage === undefined"
      [progressPercentage]="currentMiniDetail?.progressPercentage"
      [isInMiniDetail]="true"
    ></app-progress-bar>
    <div class="row1">{{ currentMiniDetail?.row1 }}</div>
    <div class="row2">{{ currentMiniDetail?.row2 }}</div>
  </div>
  <div class="right">
    <div class="episode-title">{{ currentMiniDetail?.episodeTitle }}</div>
    <div>{{ currentMiniDetail?.description }}</div>
  </div>
</div>
