import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProfileElementData, SessionCastDeviceRegistration } from '@atv-core/api/aa/aa-api.model';
import { DiscoveryService } from '@atv-bootstrap/services/discovery';
import { Observable } from 'rxjs';

import { AvatarElement, Catalog, DeviceProfileElement, ProfileElement } from './aa-api.model';

@Injectable({
  providedIn: 'root',
})
export class AaApiService {
  constructor(private disc: DiscoveryService, private http: HttpClient) {}

  public getAllCustomerProfiles(): Observable<ProfileElement[]> {
    return this.http.get<ProfileElement[]>(`${this.disc.location}/aa/v1/profile`);
  }

  public getAllCatalogs(): Observable<Catalog[]> {
    return this.http.get<Catalog[]>(`${this.disc.location}/aa/v1/catalog`);
  }

  public getBaseProfileForDevice(deviceId: string): Observable<DeviceProfileElement> {
    return this.http.get<DeviceProfileElement>(
      `${this.disc.location}/aa/v1/device/${encodeURIComponent(deviceId)}/profile`
    );
  }

  public switchProfile(deviceId: string, profileId: string): Observable<DeviceProfileElement> {
    return this.http.put<DeviceProfileElement>(
      `${this.disc.location}/aa/v1/device/${encodeURIComponent(deviceId)}/profile/${encodeURIComponent(profileId)}`,
      {}
    );
  }

  public getProfile(profileId: string): Observable<ProfileElement> {
    return this.http.get<ProfileElement>(`${this.disc.location}/aa/v1/profile/${encodeURIComponent(profileId)}`);
  }

  public createProfile(profile: ProfileElementData): Observable<ProfileElement> {
    return this.http.post<ProfileElement>(`${this.disc.location}/aa/v1/profile`, profile);
  }

  public editProfile(profileId: string, profile: ProfileElementData): Observable<ProfileElement> {
    return this.http.put<ProfileElement>(
      `${this.disc.location}/aa/v1/profile/${encodeURIComponent(profileId)}`,
      profile
    );
  }

  public deleteProfile(profileId: string): Observable<any> {
    return this.http.delete<any>(`${this.disc.location}/aa/v1/profile/${encodeURIComponent(profileId)}`);
  }

  public getProfileForDevice(
    deviceId: string,
    profileId: string
  ): Observable<DeviceProfileElement> {
    return this.http.get<DeviceProfileElement>(
      `${this.disc.location}/aa/v1/device/${encodeURIComponent(deviceId)}/profile/${encodeURIComponent(profileId)}`
    );
  }

  public getAvatars(): Observable<AvatarElement[]> {
    return this.http.get<AvatarElement[]>(`${this.disc.location}/aa/v1/avatar`);
  }

  public registerChromecastDevice(
    body: SessionCastDeviceRegistration
  ): Observable<SessionCastDeviceRegistration> {
    return this.http.post<SessionCastDeviceRegistration>(
      `${this.disc.location}/aa/v1/cast-device`,
      body
    );
  }
}
