<div class="filter-wrapper" #filterWrapperRef>
  <div class="filter-current-state-wrapper" (smartTvKeyDownEnter)="showOptions()">
    <div
      *ngIf="!isSmartTv"
      [class.hidden]="filterModel?.yearFilterOutputValue === undefined"
      class="filter-remove"
      (click)="tryRemoveFilter($event)"
    ></div>
    <div class="filter-current-state" (click)="showOptions()">
      <div class="filter-current-state-title">{{ selectedYearTitle }}</div>
      <div *ngIf="!isSmartTv" class="filter-show-options"></div>
    </div>
  </div>
  <div class="filter-options-wrapper" *ngIf="!isSmartTv && !optionsHidden" #optionWrapperRef>
    <div class="filter-options-header" (click)="hideOptions()">
      <div class="filter-options-header-title">{{ filterTitle }}</div>
      <div class="filter-options-close"></div>
    </div>
    <div class="filter-years-loading" [class.hidden]="!fetchingYears">
      <img src="./assets/theme/images/loading.svg" />
    </div>
    <div class="filter-options" [class.hidden]="fetchingYears">
      <div
        class="filter-option"
        [class.selected]="filterModel?.yearFilterOutputValue === year"
        (click)="changeselectedYear(year)"
        *ngFor="let year of years"
      >
        <div class="filter-option-title">{{ year }}</div>
      </div>
    </div>
  </div>
</div>

<div #overlayDiv *ngIf="isSmartTv && !optionsHidden" class="overlay">
  <div class="overlay-dialog">
    <div
      class="filter-option-smarttv"
      *ngFor="let year of years"
      (smartTvKeyDownEnter)="changeselectedYear(year)"
      (click)="changeselectedYear(year)"
    >
      {{ year }}
    </div>
  </div>
</div>
