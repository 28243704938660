import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '@atv-core/utility/constants/localStorageKeys';
import { Observable } from 'rxjs';

@Injectable()
export class VersionCheckInterceptor implements HttpInterceptor {
  private previousVersionCheck: string;
  private checkInterval = 600000; // check at least every 10 minutes if new version is there
  constructor(private http: HttpClient) {
    this.previousVersionCheck = localStorage.getItem(LocalStorageKeys.version_check_time);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const now = new Date().getTime();

    if (
      !this.previousVersionCheck ||
      now - parseInt(this.previousVersionCheck) > this.checkInterval
    ) {
      this.previousVersionCheck = now.toString();
      localStorage.setItem(LocalStorageKeys.version_check_time, now.toString());

      this.http
        .get('./assets/version-check.json', { headers: { 'Cache-Control': 'no-cache' } })
        .subscribe((result: any) => {
          const previousVersion = localStorage.getItem(LocalStorageKeys.version_current);

          if (previousVersion && result.version && result.version !== previousVersion) {
            localStorage.setItem(LocalStorageKeys.version_current, result.version);
            window.location.reload();
          }
        });
    }

    return next.handle(req);
  }
}
