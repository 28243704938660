<a
  class="landscape-card"
  (click)="onclick($event)"
  (focus)="onFocus()"
  (focusout)="focusOut()"
  (smartTvKeyDownEnter)="onclick($event)"
  [appCenterOnFocus]="scrollElement"
>
  <div class="card-info">
    <div
      *ngIf="!recordingCardModel?.isFolder() && recordingCardVisual.showPlayButton"
      class="card-play-icon"
      (click)="doPlay($event)"
    >
      <img src="./assets/theme/svg/playable.svg" />
    </div>
    <div class="card-detail-channel">
      <div class="card-detail-channel-image" *ngIf="!useOldCardStyling">
        <img
          *ngIf="recordingCardVisual.channelImage"
          src="{{ recordingCardVisual.channelImage }}"
        />
      </div>
    </div>
    <div class="epg-card-info-image card-image" #image></div>
    <div
      class="card-info-schedule"
      *ngIf="useOldCardStyling && recordingCardVisual.scheduleText"
      [class.has-progress]="recordingCardVisual.progressPercentage !== undefined"
    >
      {{ recordingCardVisual.scheduleText }}
    </div>
    <app-progress-bar
      class="card-progress"
      [class.hidden]="recordingCardVisual.progressPercentage === undefined"
      [progressPercentage]="recordingCardVisual.progressPercentage"
    ></app-progress-bar>
  </div>

  <div class="card-detail">
    <div
      class="card-detail-row-group"
      [class.card-detail-row-group2]="recordingCardModel?.isFolder()"
    >
      <div class="card-row1">
        <div class="card-row1-text">{{ recordingCardVisual.detailRow1Text }}</div>
        <div class="row-icons old-styling" *ngIf="useOldCardStyling">
          <img
            *ngIf="recordingCardVisual.recordingIcon"
            src="{{ recordingCardVisual.recordingIcon }}"
          />
          <img
            *ngIf="recordingCardVisual.recordingIsLocked"
            src="./assets/theme/svg/recording_locked.svg"
          /><img *ngIf="recordingCardVisual.playIcon" src="{{ recordingCardVisual.playIcon }}" />
          <div
            *ngIf="useOldCardStyling && recordingCardModel?.isFolder()"
            class="recording-card-folder old-styling"
          >
            <span>{{ recordingCardModel?.getFolderNumber() }}</span>
          </div>
        </div>
      </div>

      <div class="card-row2">
        <div class="card-row2-text">{{ recordingCardVisual.detailRow2Text }}</div>
        <div class="row-icons" *ngIf="!useOldCardStyling">
          <img
            *ngIf="recordingCardVisual.recordingIcon"
            src="{{ recordingCardVisual.recordingIcon }}"
          />
          <img
            *ngIf="recordingCardVisual.recordingIsLocked"
            src="./assets/theme/svg/recording_locked.svg"
          /><img *ngIf="recordingCardVisual.playIcon"
                 src="{{ recordingCardVisual.playIcon }}" />
        </div>
      </div>
    </div>
    <div *ngIf="!useOldCardStyling && recordingCardModel?.isFolder()" class="recording-card-folder">
      <span>{{ recordingCardModel?.getFolderNumber() }}</span>
    </div>
  </div>
</a>
