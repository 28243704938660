import { CmsContentTypes } from '@atv-core/api/cms';
import { EpgProgramSummaryAsset, EpgScheduleSummaryAsset, EpgSeriesSummaryAsset } from '@atv-core/api/epg';
import { ChannelModel } from '@atv-core/services/cache/channel';
import { RecordingModel } from '@atv-core/services/cache/stb/recording.model';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { ImageRecipe, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';

export enum RecordingCardMode {
  TITLE_SCHEDULE,
  EPISODE_SCHEDULE,

  CHANNEL_EPISODE_OLD_STYLING,
  CHANNEL_PROGRAM_OLD_STYLING,
}

export class RecordingCardModel {
  inFolderPage: boolean;
  seriesId: string;
  channelId: string;
  recordingModels: RecordingModel[];
  recordingCardMode: RecordingCardMode;
  type: CmsContentTypes = CmsContentTypes.RECORDING;

  schedule: EpgScheduleSummaryAsset;
  series: EpgSeriesSummaryAsset;
  program: EpgProgramSummaryAsset;
  channels: ChannelModel[];

  adultModeActive: boolean;

  constructor(
    recordingModel: RecordingModel,
    adultActive: boolean,
    mode: RecordingCardMode,
    inFolderPage: boolean,
  ) {
    this.inFolderPage = inFolderPage;
    this.recordingModels = [];
    this.recordingModels.push(recordingModel);
    this.seriesId = recordingModel.series;
    this.channelId = recordingModel.channel;
    this.recordingCardMode = mode;

    this.adultModeActive = adultActive;
  }

  public isFolder(): boolean {
    return this.recordingModels.length > 1;
  }

  public getFolderNumber(): number {
    return Math.min(this.recordingModels.length, 99);
  }

  public getFirstRecording(): RecordingModel {
    if (this.recordingModels.length === 0) {
      return undefined;
    }

    return this.recordingModels[0];
  }

  addInformationFromProgram(program: EpgProgramSummaryAsset): void {
    this.program = program;
  }

  addInformationFromSeries(series: EpgSeriesSummaryAsset): void {
    this.series = series;
  }

  addChannelInformation(channels: ChannelModel[]): void {
    this.channels = channels;
  }

  addInformationFromSchedule(schedule: EpgScheduleSummaryAsset): void {
    this.schedule = schedule;
  }

  getChannel(): ChannelModel {
    const firstRecording = this.getFirstRecording();
    if (firstRecording && firstRecording.channel) {
      return this.channels.find((ch) => ch.id === firstRecording.channel);
    }
    return undefined;
  }

  getChannelImage(): string {
    const channel = this.getChannel();
    return channel?.defaultLogo ? ImageRecipeUtil.createImageUrl(channel.defaultLogo, ImageRecipe.CHANNEL_1) : '';
  }

  getTitle(): string {
    if (this.recordingModels.length > 1) {
      return this.series ? this.series.title : '';
    } else {
      return this.program ? this.program.title : '';
    }
  }

  getSchedule(): string {
    const firstRecording = this.getFirstRecording();
    if (this.schedule && this.schedule.published) {
      return this.makeStartEndString(this.schedule.published.start, this.schedule.published.end);
    } else if (firstRecording && firstRecording.start && firstRecording.end) {
      return this.makeStartEndString(firstRecording.start, firstRecording.end);
    }
    return '';
  }

  private makeStartEndString(scheduleStart: string, scheduleEnd: string): string {
    const start = SharedUtilityService.timeStringToMoment(scheduleStart).format('DD/MM, HH:mm');
    const end = SharedUtilityService.timeStringToMoment(scheduleEnd).format('HH:mm');
    const scheduleInfo = start.toString() + ' - ' + end.toString();
    return scheduleInfo;
  }

  getRecordingIcon(): string {
    const rec = this.getFirstRecording();
    if (!rec) {
      return '';
    }

    return rec.getIcon();
  }

  getRecordingIsLocked(): boolean {
    const rec = this.getFirstRecording();
    if (!rec) {
      return false;
    }
    return rec.deleteProtected;
  }
}
