import { Category } from './log.model';
export enum MainCategoryTypes {
  TRACK = 'TRACK',
  ADVERTISEMENT = 'ADVERTISEMENT',
  PLAYER = 'PLAYER',
  AUTHENTICATE = 'AUTHENTICATE',
  STARTUP = 'STARTUP',
  SWIPE = 'SWIPE',
  RECORDING = 'RECORDING',
  ARTICLE = 'ARTICLE',
  LOG = 'LOG'
}

export enum TrackSubcategories {
  PAGE_VIEW = 'PAGE_VIEW',
  LOAD_DETAIL = 'LOAD_DETAIL',
  BANNER = 'BANNER'
}

export class TrackCategorie implements Category {
  readonly type: MainCategoryTypes = MainCategoryTypes.TRACK;
  readonly subType: TrackSubcategories;
  constructor(subType: TrackSubcategories) {
    this.subType = subType;
  }
}

export enum AdvertisementSubcategories {
  ADVERTISEMENT_END = 'ADVERTISEMENT_END',
  ADVERTISEMENT_ERROR = 'ADVERTISEMENT_ERROR'
}
export class AdvertisementCategorie implements Category {
  readonly type: MainCategoryTypes = MainCategoryTypes.ADVERTISEMENT;
  readonly subType: AdvertisementSubcategories;
  constructor(subType: AdvertisementSubcategories) {
    this.subType = subType;
  }
}

export enum PlayerSubcategories {
  PLAY_START = 'PLAY_START',
  PLAYING = 'PLAYING',
  PLAY_STOP = 'PLAY_STOP',
  KEEP_ALIVE_ERROR = 'KEEP_ALIVE_ERROR',
  PLAY_STATE = 'PLAY_STATE',
  PLAY_ERROR = 'PLAY_ERROR',
  PLAY_WARNING = 'PLAY_WARNING'
}
export class PlayerCategorie implements Category {
  readonly type: MainCategoryTypes = MainCategoryTypes.PLAYER;
  readonly subType: PlayerSubcategories;
  constructor(subType: PlayerSubcategories) {
    this.subType = subType;
  }
}

export enum AuthenticateSubcategories {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  STATE = 'STATE'
}
export class AuthenticateCategorie implements Category {
  readonly type: MainCategoryTypes = MainCategoryTypes.AUTHENTICATE;
  readonly subType: AuthenticateSubcategories;
  constructor(subType: AuthenticateSubcategories) {
    this.subType = subType;
  }
}

export enum StartupSubcategories {
  APP_OPEN = 'APP_OPEN'
}
export class StartupCategorie implements Category {
  readonly type: MainCategoryTypes = MainCategoryTypes.STARTUP;
  readonly subType: StartupSubcategories;
  constructor(subType: StartupSubcategories) {
    this.subType = subType;
  }
}

export enum SwipeSubcategories {
  SWIPE = 'SWIPE'
}
export class SwipeCategorie implements Category {
  readonly type: MainCategoryTypes = MainCategoryTypes.SWIPE;
  readonly subType: SwipeSubcategories;
  constructor(subType: SwipeSubcategories) {
    this.subType = subType;
  }
}

export enum RecordingSubcategories {
  REC_PLANNED = 'REC_PLANNED',
  REC_REMOVED = 'REC_REMOVED',
  REC_CANCEL = 'REC_CANCEL',
  REC_CHANGED = 'REC_CHANGED'
}
export class RecordingCategorie implements Category {
  readonly type: MainCategoryTypes = MainCategoryTypes.RECORDING;
  readonly subType: RecordingSubcategories;

  constructor(subType: RecordingSubcategories) {
    this.subType = subType;
  }
}

export enum ArticleSubcategories {
  PAGE_VIEW = 'PAGE_VIEW',
  ACTION = 'ACTION'
}
export class ArticleCategorie implements Category {
  readonly type: MainCategoryTypes = MainCategoryTypes.ARTICLE;
  readonly subType: ArticleSubcategories;

  constructor(subType: ArticleSubcategories) {
    this.subType = subType;
  }
}

export enum LogSubcategories {
  LOG_LOGGING_ERROR = 'LOG_LOGGING_ERROR'
}
export class LogCategorie implements Category {
  readonly type: MainCategoryTypes = MainCategoryTypes.LOG;
  readonly subType: LogSubcategories;

  constructor(subType: LogSubcategories) {
    this.subType = subType;
  }
}
