import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdultMode } from '@atv-core/services/adult';
import { DiscoveryService } from '@atv-bootstrap/services/discovery';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';

import { Article, CustomPage, Menu, Ribbon2Container, UrlMapping } from '.';
import { ExtraData, FocusType } from './../../utility/constants/shared';

@Injectable({ providedIn: 'root' })
export class CmsApiService {
  constructor(private disc: DiscoveryService, private http: HttpClient) {
  }

  private static expandUrl(
    url: string,
    page: number,
    anonymousActive: boolean,
    entitlementId: string,
    catalogId: string,
    profileId: string,
    adultMode: AdultMode,
    focusType: FocusType
  ): string {
    const dp = new DatePipe('en-US');

    if (url.includes('${now:M1}')) {
      const date = dp.transform(new Date(), 'yyyy-MM-ddTHH:mm\'Z\'', 'UTC');
      url = url.replace(/\${now:M1}/g, encodeURIComponent(date));
    }
    if (url.includes('${now:M5}')) {
      const trunc = 1000 * 60 * 5;
      const truncedDate = new Date(Math.floor(new Date().getTime() / trunc) * trunc);
      const date = dp.transform(truncedDate, 'yyyy-MM-ddTHH:mm\'Z\'', 'UTC');
      url = url.replace(/\${now:M5}/g, encodeURIComponent(date));
    }
    if (url.includes('${now:M10}')) {
      const trunc = 1000 * 60 * 10;
      const truncedDate = new Date(Math.floor(new Date().getTime() / trunc) * trunc);
      const date = dp.transform(truncedDate, 'yyyy-MM-ddTHH:mm\'Z\'', 'UTC');
      url = url.replace(/\${now:M10}/g, encodeURIComponent(date));
    }
    if (url.includes('${adult}')) {
      url = url.replace(/\${adult}/g, encodeURIComponent(adultMode));
    }
    if (url.includes('${page}')) {
      url = url.replace(/\${page}/g, encodeURIComponent(page.toString()));
    } else if (page !== 0) {
      return undefined;
    }
    if (url.includes('${loggedIn}')) {
      const loggedIn = anonymousActive ? 'false' : 'true';
      url = url.replace(/\${loggedIn}/g, encodeURIComponent(loggedIn));
    }
    if (url.includes('${entitlementId}')) {
      url = url.replace(/\${entitlementId}/g, encodeURIComponent(entitlementId || ''));
    }
    if (url.includes('${catalogId}')) {
      url = url.replace(/\${catalogId}/g, encodeURIComponent(catalogId || ''));
    }
    if (url.includes('${profileId}')) {
      url = url.replace(/\${profileId}/g, encodeURIComponent(profileId || ''));
    }
    if (url.includes('${deviceType}')) {
      url = url.replace(/\${deviceType}/g, encodeURIComponent(environment.device || ''));
    }
    if (url.includes('${platformType}')) {
      url = url.replace(/\${platformType}/g, encodeURIComponent(environment.platform || ''));
    }
    if (url.includes('${extraData}')) {
      url = url.replace(
        /\${extraData}/g,
        encodeURIComponent(SharedUtilityService.isSmartTv() ? ExtraData.MINI : ExtraData.NONE),
      ); // for smarttv use MINI
    }
    if (url.includes('${actionFilter}')) {
      // see confluence for explenation 127

      url = url.replace(/\${actionFilter}/g, encodeURIComponent(environment.actionFilter));
    }
    if (url.includes('${focusType}')) {
      url = url.replace(/\${focusType}/g, encodeURIComponent(focusType));
    }
    return url;
  }

  public getMenu(catalogId: string): Observable<Menu> {
    const queryParams = new HttpParams()
      .append('deviceType', environment.device)
      .append('catalogId', catalogId);

    return this.http.get<Menu>(`${this.disc.location}/cms/v1/menu`, { params: queryParams });
  }

  public getUrlMapping(url: string, catalog: string): Observable<UrlMapping> {
    const queryParams = new HttpParams()
      .append('url', url)
      .append('actionFilter', environment.actionFilter)
      .append('catalogId', catalog);

    return this.http.get<UrlMapping>(`${this.disc.location}/cms/v1/urlmapping/open`, {
      params: queryParams,
    });
  }

  public getDynamicPage(
    pageId: string,
    entitlementId: string,
    adult: AdultMode
  ): Observable<CustomPage> {
    const queryParams = new HttpParams()
      .append('deviceType', environment.device)
      .append('entitlementId', entitlementId)
      .append('actionFilter', environment.actionFilter)
      .append('adult', adult);

    return this.http.get<CustomPage>(`${this.disc.location}/cms/v2/custom-page/${encodeURIComponent(pageId)}`, {
      params: queryParams,
    });
  }

  public getArticle(uuid: string, catalogId: string): Observable<Article> {
    const queryParams = new HttpParams()
      .append('catalogId', catalogId)
      .append('platform', environment.platform)
      .append('actionFilter', environment.actionFilter);
    return this.http.get<Article>(`${this.disc.location}/cms/v1/article/${encodeURIComponent(uuid)}`, {
      params: queryParams,
    });
  }

  public getContent(
    contentUrl: string,
    page: number,
    anonymousActive: boolean,
    entitlementId: string,
    catalogId: string,
    profileId: string,
    adultMode: AdultMode,
    focusType: FocusType
  ): Observable<Ribbon2Container> {
    contentUrl = CmsApiService.expandUrl(
      contentUrl,
      page,
      anonymousActive,
      entitlementId,
      catalogId,
      profileId,
      adultMode,
      focusType
    );
    if (contentUrl) {
      return this.http.get<Ribbon2Container>(contentUrl);
    } else {
      return of(undefined);
    }
  }
}
