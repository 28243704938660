export enum TopLevelMenuTypes {
  now = 'now',
  flashback = 'flashback',
  videoclub = 'videoclub',
  mytv = 'mytv',
  search = 'search'
}

export interface TopLevelMenuModel {
  text: string;
  route: string;
  icon: string;
  icon_selected: string;
  submenu: SubLevelMenuItem[];
}

export interface SubLevelMenuItem {
  text: string;
  route: string;
  icon?: string;
  icon_selected?: string;
}

export interface SublevelMenuModel {
  forTopLevel: string;
  forTopLevelTitle: string;
  subLevelMenuItems: SubLevelMenuItem[];
}
