import { Component, Input, OnInit } from '@angular/core';
import { FavoriteContentTypes } from '@atv-core/api/history';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { ChannelModel } from '@atv-core/services/cache/channel/channel.model';
import { FavoriteCacheService } from '@atv-core/services/cache/favorite/favorite-cache.service';
import { DetailTranslationKeys } from '@atv-bootstrap/services/config';
import { ConfigService } from '@atv-bootstrap/services/config/config.service';
import { MessagesService } from '@atv-core/services/messages';
import { SessionService } from '@atv-core/services/session';

import { AdultService } from '@atv-core/services/adult';
import { ImageRecipe, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
})
export class ChannelComponent implements OnInit {
  public currentChannel: ChannelModel;

  @Input('channel')
  set channel(channel: ChannelModel) {
    if (channel) {
      this.currentChannel = channel;
      this.channelNumber = channel.getChannelNumberForCatalog(this.sessionService.getCatalogId());
    }
  }

  public userIsAuthorized = false;

  channelNumber = undefined;

  constructor(
    private favoriteCacheService: FavoriteCacheService,
    private auth: AuthorizationService,
    private sessionService: SessionService,
    private messagesService: MessagesService,
    private config: ConfigService,
    private adultService: AdultService,
  ) {
    this.userIsAuthorized = this.auth.isAuthorized();
  }

  ngOnInit(): void {
  }

  toggleFavorite(): void {
    if (!this.userIsAuthorized) {
      return;
    }

    if (this.currentChannel.adult) {
      this.adultService.checkAdultMode({
        successCallback: () => {
          this.favoriteCacheService.setFavorite(
            FavoriteContentTypes.CHANNEL,
            this.currentChannel.id,
            this.currentChannel.adult,
          );

          this.currentChannel.setFavorite(true);
        },
        errorCallback: () => {
          this.messagesService.showErrorMessage(this.config.getTranslation(DetailTranslationKeys.detail_adult_warning));
        },
      });
    } else {
      this.doToggle();
    }
  }

  doToggle(): void {
    if (this.currentChannel.isFavorite()) {
      this.favoriteCacheService.deleteFavorite(
        FavoriteContentTypes.CHANNEL,
        this.currentChannel.id,
      );

      this.currentChannel.setFavorite(false);
    } else {
      this.favoriteCacheService.setFavorite(
        FavoriteContentTypes.CHANNEL,
        this.currentChannel.id,
        this.currentChannel.adult,
      );

      this.currentChannel.setFavorite(true);
    }
  }

  public getChannelLogo(channel: ChannelModel): string {
    return ImageRecipeUtil.createImageUrl(channel.defaultLogo, ImageRecipe.CHANNEL_1);
  }
}
