import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewRef,
} from '@angular/core';
import { ConfigService, RecordingTranslationKeys } from '@atv-bootstrap/services/config';
import { Stb } from '@atv-core/services/session/stb';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-stb-filter',
  templateUrl: './stb-filter.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StbFilterComponent implements AfterViewInit, OnDestroy {
  filterStbs: Stb[];

  @Input()
  set stbs(value) {
    if (value && value !== this.filterStbs) {
      this.filterStbs = value;
      this.selectedStbTitle =
        this.filterStbs.length > 0 && this.filterStbs[0] ? this.filterStbs[0].alias : '';
    }
  }

  @Input()
  set setSelectedStbWithId(id) {
    if (this.filterStbs && id) {
      const index = this.filterStbs.findIndex((stb) => stb && stb.id === id);
      if (index !== undefined && index >= 0) {
        this.changeSelectedStbIndex(index);
      }
    }
  }

  @Input()
  @Output()
  filterChange = new EventEmitter();

  private clickedInside = false;
  public selectedStbIndex;

  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnSubscription: Subscription = null;
  @ViewChildren('overlayDiv') overlayDiv: QueryList<ElementRef<HTMLDivElement>>;
  private overlayDivSubscription: Subscription;

  @ViewChild('filterWrapperRef', { static: true })
  filterWrapperRef: ElementRef;
  @ViewChild('optionWrapperRef')
  optionWrapperRef: ElementRef;

  selectedStbTitle = '';
  filterTitle = '';

  optionsHidden = true;

  constructor(
    private config: ConfigService,
    private cdr: ChangeDetectorRef,
    private spatialNavigationService: SpatialNavigationService,
  ) {
    this.filterTitle = this.config.getTranslation(
      RecordingTranslationKeys.recordings_btn_filter_stb,
    );
  }

  public ngAfterViewInit(): void {
    this.overlayDivSubscription = this.overlayDiv.changes.subscribe(changes => {
      if (changes.length === 1) {
        // move to body to avoid issues with parent divs who have a transformation set
        // TODO create overlay component, add it to body and use ng-template
        document.body.appendChild(changes.first.nativeElement);

        this.returnSubscription = fromEvent(changes.first.nativeElement, 'keyup').pipe(
          filter((event: KeyboardEvent) =>
            this.spatialNavigationService.keyCodeIsReturn(event.keyCode),
          ),
        ).subscribe((event) => {
          event.cancelBubble = true;
          event.preventDefault();
          this.spatialNavigationService.setFocus(NavigationSections.RECORDING_FILTERS);

          this.hideOptions();
        });
      }
    });
  }

  showOptions(): void {
    this.optionsHidden = false;
    this.detectChanges();
    if (!this.isSmartTv) {
      setTimeout(() => {
        this.detectWidthChange();
      }, 0);
    } else {
      window.setTimeout(() => {
        this.spatialNavigationService.register(
          NavigationSections.FILTER_OPTIONS,
          '.filter-option-smarttv',
          { restrict: 'self-only' },
        );
        this.spatialNavigationService.setFocus(NavigationSections.FILTER_OPTIONS);
      }, 100);
    }
  }

  hideOptions(): void {
    if (this.optionsHidden) {
      return;
    }
    this.optionsHidden = true;
    this.detectChanges();
    if (!this.isSmartTv) {
      setTimeout(() => {
        this.detectWidthChange();
      }, 0);
    } else {
      this.spatialNavigationService.unregister(NavigationSections.FILTER_OPTIONS);
      this.spatialNavigationService.setFocus(NavigationSections.RECORDING_FILTERS);
    }

    if (this.returnSubscription) {
      this.returnSubscription.unsubscribe();
      this.returnSubscription = null;
    }
  }

  detectWidthChange(): void {
    if (this.filterWrapperRef) {
      if (!this.optionWrapperRef) {
        this.filterWrapperRef.nativeElement.style.width = 'auto';
      } else {
        const buttonConfigEl = this.optionWrapperRef.nativeElement;
        const width = buttonConfigEl.getBoundingClientRect().width;
        if (width) {
          this.filterWrapperRef.nativeElement.style.width = width + 'px';
        } else {
          this.filterWrapperRef.nativeElement.style.width = 'auto';
        }
      }
    }
  }

  public changeSelectedStbIndex(index): void {
    if (this.selectedStbIndex === index) {
      this.hideOptions();
      return;
    }
    this.selectedStbIndex = index;

    if (this.filterStbs && this.filterStbs.length) {
      this.selectedStbTitle = this.filterStbs[this.selectedStbIndex].alias;
      this.filterChange.emit(this.selectedStbIndex);
    }
    this.hideOptions();
  }

  @HostListener('click', ['$event'])
  onClickedInside(): void {
    this.clickedInside = true;
  }

  @HostListener('document:click', ['$event'])
  onClickedOutside(): void {
    if (!this.clickedInside && !this.optionsHidden) {
      this.hideOptions();
    }
    this.clickedInside = false;
  }

  private detectChanges(): void {
    // prevent change detection from firing when view is destroyed
    if (this.cdr !== null && this.cdr !== undefined && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.returnSubscription?.unsubscribe();
    this.overlayDivSubscription?.unsubscribe();
  }
}
