import { HttpBackend, HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DiscoveryService } from '@atv-bootstrap/services/discovery';
import { ClientEvents } from '@atv-core/services/log/log.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { versionInfo } from 'src/app/version-info';

import { SessionPinRequest } from '../pricing/pricing.model.service';
import { SessionChangePinRequest } from './../pricing/pricing.model.service';
import {
  BootstrapConfig,
  LoginSessionV3,
  SwipeActionRequest,
  SwipeActionResponse,
  SwipeCommandResponse,
  TimeResponse,
} from './api-api.model';

@Injectable({
  providedIn: 'root',
})
export class ApiApiService {
  constructor(private disc: DiscoveryService, private http: HttpClient, private handler: HttpBackend) {
  }

  public getSession(): Observable<HttpResponse<LoginSessionV3>> {
    const params = new HttpParams().append('appVersion', versionInfo.version);
    return this.http.get<LoginSessionV3>(`${this.disc.location}/api/v3/session`, {
      observe: 'response',
      params,
    });
  }

  public swipe(deviceId: string, options: SwipeActionRequest): Observable<SwipeActionResponse> {
    return this.http.post<SwipeActionResponse>(
      `${this.disc.location}/v1/swipe/action/${encodeURIComponent(deviceId)}`,
      options,
    );
  }

  public pollSwipe(swipeId: string): Observable<SwipeCommandResponse> {
    return this.http.get<SwipeCommandResponse>(
      `${this.disc.location}/v1/swipe/response/${encodeURIComponent(swipeId)}`,
    );
  }

  public checkPincode(pincodeRequest: SessionPinRequest): Observable<void> {
    return this.http.post<void>(`${this.disc.location}/v1/pincode`, pincodeRequest);
  }

  public changePincode(pincodeRequest: SessionChangePinRequest): Observable<void> {
    return this.http.put<void>(`${this.disc.location}/v1/pincode`, pincodeRequest);
  }

  public log(clientEvents: ClientEvents): Observable<void> {
    return this.http.post<void>(`${this.disc.location}/v1/log`, clientEvents);
  }

  public getBootstrapConfig(): Observable<BootstrapConfig> {
    // create custom http client to avoid usage of interceptors
    // if interceptors are used before the config is loaded, services might be initialized before the config is loaded
    return new HttpClient(this.handler).get<BootstrapConfig>(
      `${this.disc.location}/v1/bootstrap/config/${encodeURIComponent(environment.platform)}`,
    );
  }

  public getServerTime(): Observable<TimeResponse> {
    return this.http.get<TimeResponse>(`${this.disc.location}/v1/time`);
  }
}
