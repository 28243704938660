import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
import { ImageRecipeCategory, ImageRecipeUtil } from '@atv-core/utility/image/image-recipe';

@Injectable({
  providedIn: 'root',
})
export class ContentBackdropService {
  public showGradientSubject = new BehaviorSubject(true);

  private excludePaths = ['/detail/'];

  private possibleBackdropImages: string[];
  private currentBackdropImage = '';
  private maxBackdropImages = 1;
  private backdropIsVisible: boolean;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.excludePaths.find((path) => event.url.includes(path))) {
          this.backdropIsVisible = false;
        } else {
          this.possibleBackdropImages = [];
          this.showGradientSubject.next(true);
          this.backdropIsVisible = true;
        }
      }
    });

    this.backdropIsVisible = false;
    this.possibleBackdropImages = [];
  }

  getBackDropImage(): string {
    return this.currentBackdropImage;
  }

  addBackdropImage(image: string): void {
    if (this.possibleBackdropImages.length > this.maxBackdropImages) {
      return;
    }

    this.possibleBackdropImages.push(image);

    if (this.possibleBackdropImages.length === this.maxBackdropImages) {
      const rand = Math.trunc(Math.random() * this.maxBackdropImages);
      this.currentBackdropImage = this.possibleBackdropImages[rand];
    }
  }

  addCustomBackdropImage(image: string): void {
    if (!image.includes('://')) {
      image = environment.imagePrefixUrl + image;
    }

    this.addBackdropImage(ImageRecipeUtil.getSourceSet(image, ImageRecipeCategory.CUSTOM_BACKGROUND));
    this.showGradientSubject.next(false);
  }

  isBackdropVisible(): boolean {
    return this.backdropIsVisible;
  }
}
