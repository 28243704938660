import { DeviceType, PlatformType, StreamType } from '@atv-core/utility/constants/shared';
import { PlayerEngines } from '@atv-player/engines/player-engine';

export const environment = {
  applicationName: 'AndromeTv nightly',
  nightlyBuild: true,
  showBuildTime: true,
  showBuildVersion: true,
  production: true,
  discoveryUrl: `https://dev.nebula.androme.com/api/v1/bootstrap/discover`,
  imagePrefixUrl: 'https://dev.nebula.androme.com/media/images',
  platform: PlatformType.WEB,
  device: DeviceType.WEB,
  allowAdult: true,
  useLocalTranslation: false,
  hasParentalIcons: true,

  oauth_client_id: 'androme.tv-web-2',
  oauth_redirect_uri: 'https://www4.dev.nebula.androme.com',
  oauth_headless_redirect_uri: 'https://www4.dev.nebula.androme.com/assets/oauth-headless.html',
  // TODO set correct auth_uri for env
  oauth_auth_uri: 'https://dev.nebula.androme.com/api/oauth/v1/auth/authorize',

  atv_feature_list: ['RECORDING', 'SHOW_SWIPABLE_ICONS', 'SHOW_RECORDABLE_ICONS'],

  atv_ott_rights: [StreamType.LINEAR, StreamType.NPVR],
  atv_stb_rights: [StreamType.LINEAR, StreamType.DVBC, StreamType.NPVR],
  atv_replay_ott_rights: [StreamType.REPLAY],
  atv_replay_stb_rights: [StreamType.REPLAY],

  drm_protected_enabled: true,
  drm_merchant: 'vpmedia_androme',
  drm_environment: 'DRMtoday_STAGING',
  player_change_buffer_setting: undefined, // change the atv-player buffer (set undefined to use default)
  player_change_suggested_presentation_delay: undefined, // change the atv-player buffer (set undefined to use default)
  broadpeakAnalyticsAllowed: false,
  drmStaging: true,
  chromecast_receiver_app_id: '99EBCAD3',

  ottAdultSettingEnabled: true,

  castLabsLicenseKey:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbHYiOmZhbHNlLCJ0eXBlIjoiV2ViIiwidXJscyI6WyJodHRwczovL3d3dzQuZGV2Lm5lYnVsYS5hbmRyb21lLmNvbSJdLCJraWQiOjE2NDV9.ks_VN7__LlPOzgCcaHwXw--2x8N5ZOPdyFjfeRE8TdZrgxM-SY-U8oNxQduCUS1-Ci_nO2pvvNs2bDQVXOcrS7M0TiVYo3gGLA5MvfJHxnf5Rcm06Bi2Vw0dFLox5YL1yINiFnz5Z-Aa5Tb_ZxyVlncKgeTMz4xN41LhaGHyOIIIF7qg_tiQBOqtmE-HDP6mgRj6AsYZi67S1xGKd3UZD-l_xK4kQYZ5EvNwYldo1_WafLB-BSMLSqCHT6viPfIYQXwmzxx8cPgWVwVJytDIPSNh4b0fHUKNmHNeoCohuFGlukMoAOn5lJZ8lsnn82-DUIam9Xsni3xoajS-aN5X-w',

  atv_guide_width_per_hour: 500,

  // https://confluence.in.androme.com/pages/viewpage.action?spaceKey=NEBULABACKEND&title=New+pages
  actionFilter: '639',
  smartTvOauthClientId: undefined,
  smartTvOauthClientSecret: undefined,
  playerEngine: PlayerEngines.CASTLABS,
};
