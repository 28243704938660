import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigService, ListTranslationKeys } from '@atv-bootstrap/services/config';
import { SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';

@Component({
  selector: 'app-clear-filter',
  templateUrl: './clear-filter.component.html',
  styleUrls: ['./clear-filter.component.scss'],
})
export class ClearFilterComponent implements OnInit {
  public clearFilters = '';

  @Input()
  public filtersActive: boolean;

  @Output()
  clear = new EventEmitter<void>();

  constructor(
    private config: ConfigService,
    private spatialNavigationService: SpatialNavigationService
  ) {
    this.clearFilters = this.config.getTranslation(ListTranslationKeys.lists_btn_clearfilters);
  }

  ngOnInit(): void {}

  clearPressed(): void {
    this.spatialNavigationService.move('left');
    this.clear.emit();
  }
}
