import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from '@atv-core/services/authorization/authorization.service';
import { CatalogTranslationKeys, ConfigService, PopupTranslationKeys } from '@atv-bootstrap/services/config';
import { NavigationSections, SpatialNavigationService } from '@atv-core/services/spatial-navigation/spatial-navigation.service';
import { SharedUtilityService } from '@atv-core/utility/shared/shared-utility';
import { fromEvent, merge, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-logout-confirm-popup',
  templateUrl: './logout-confirm-popup.component.html',
  styles: [],
})
export class LogoutConfirmPopupComponent implements OnInit, OnDestroy {
  public showLoginConfirmPopup = false;
  public popupTitle = '';
  public confirmButtonTitle = '';
  public cancelButtonTitle = '';
  public isSmartTv = SharedUtilityService.isSmartTv();
  private returnSubscription?: Subscription;

  public callback: () => void;

  @ViewChild('overlay') overlay: ElementRef<HTMLDivElement>;

  constructor(
    private config: ConfigService,
    private auth: AuthorizationService,
    private spatialNavigationService: SpatialNavigationService,
  ) {
    this.popupTitle = this.config.getTranslation(
      CatalogTranslationKeys.profile_logout_request_popup_title,
    );
    this.confirmButtonTitle = this.config.getTranslation(PopupTranslationKeys.popup_btn_confirm);
    this.cancelButtonTitle = this.config.getTranslation(CatalogTranslationKeys.profile_cancel_btn);

    this.auth.confirmLogoutPopup.subscribe((e: () => void) => {
      this.callback = e;
      this.showLoginConfirmPopup = true;

      if (this.isSmartTv) {
        window.setTimeout(() => {
          this.spatialNavigationService.unregister(NavigationSections.POPUP_BUTTONS);
          this.spatialNavigationService.register(
            NavigationSections.POPUP_BUTTONS,
            '.button-dialog .button-title',
            {
              restrict: 'self-only',
            },
          );
          this.spatialNavigationService.setFocus(NavigationSections.POPUP_BUTTONS);
          this.returnSubscription = merge<Event>(
            fromEvent<KeyboardEvent>(document.getElementsByTagName('app-logout-confirm-popup')[0], 'keyup')
              .pipe(filter(event => this.spatialNavigationService.keyCodeIsReturn(event.keyCode))),
            fromEvent<MouseEvent>(this.overlay.nativeElement, 'click').pipe(filter(event => event.target === this.overlay.nativeElement)),
          )
            .subscribe(event => {
              event.cancelBubble = true;
              this.closePopup();
            });
        }, 100);
      }
    });
  }

  ngOnInit(): void {
  }

  closePopup(): void {
    this.showLoginConfirmPopup = false;
    this.callback = undefined;

    if (this.isSmartTv) {
      this.clearSpatialNavigation();
    }
  }

  logout(): void {
    this.showLoginConfirmPopup = false;
    this.clearSpatialNavigation();
    this.auth.logoutClick();

    if (this.callback) {
      this.callback();
      this.callback = undefined;
    }
  }

  private clearSpatialNavigation(): void {
    if (this.isSmartTv) {
      this.spatialNavigationService.unregister(NavigationSections.POPUP_BUTTONS);
      this.spatialNavigationService.setFocus(NavigationSections.MENU);
    }

    this.returnSubscription?.unsubscribe();
    this.returnSubscription = null;
  }

  ngOnDestroy(): void {
    this.clearSpatialNavigation();
  }
}
